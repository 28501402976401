import { Text } from "components/atoms/text/Text";
import * as s from "./SpeedStyled";
import DB_ICON from "assets/icons/db.svg";
import CHEVRON_DOWN_BLUE from "assets/icons/chevron_down_blue.svg";
import INTERNET_ICON from "assets/icons/internet.svg";
import { useState } from "react";

type Props = {};

export default function SpeedTest(props: Props): JSX.Element {
  const [isTest, setIsTest] = useState<boolean>(false);

  const onChangeTest = () => {
    setIsTest(!isTest);
  };

  return (
    <s.Container>
      <s.Inner>
        <s.InternetSpeedTitle>
          <img src={INTERNET_ICON} alt="internet icon" />
          Internet Speed Test
        </s.InternetSpeedTitle>
        <s.Wrapper>
          <s.Row>
            <s.Circle onClick={onChangeTest}>
              {isTest ? (
                <h2>Test Again</h2>
              ) : (
                <div>
                  <h2 className="bold">Start</h2> <h3>Speed Test</h3>
                </div>
              )}
            </s.Circle>

            <p>
              <s.Info>
                <img src={DB_ICON} alt="db icon" />
                <p>
                  <s.Select>
                    <h5>Frontier</h5>
                    <img src={CHEVRON_DOWN_BLUE} alt="chevron down blue icon" />
                  </s.Select>
                  <p>Los Angeles, CA</p>
                </p>
              </s.Info>

              <s.SubContent>Monday June 22nd 2022 3:37 PM PST</s.SubContent>
              <s.SubContent>Your IP Address: 49.180.62.233</s.SubContent>
            </p>
          </s.Row>
        </s.Wrapper>
      </s.Inner>
    </s.Container>
  );
}
