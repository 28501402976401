import BigButton from "components/atoms/buttons/BigButton";
import PrimaryButton from "components/atoms/buttons/PrimaryButton";
import Input from "components/atoms/input/Input";
import PhoneInput from "components/atoms/input/phone/PhoneInput";
import { ModalInput } from "components/atoms/input/modal/ModalInput";
import Selects from "components/atoms/select/Select";
import { Text, ValidateWord } from "components/atoms/text/Text";
import Toggle from "components/atoms/toggle/Toggle";
import { COLORS } from "styles/colors";
import { Option } from "utils/options";
import * as s from "./MyProfileStyled";
import Camera from "components/atoms/text/labels/Camera";
import ChangeModal from "./modal/change";
import { UserDetail } from "api/interfaces/userInterface.interface";
import * as mediaQuery from "components/MediaQuery";
import ImageCrop from "components/atoms/image/crop/ImageCrop";
import UserProfileImage from "components/blocks/headernav/sub/UserProfileImage";
import PhotoMenu from "./photomenu/PhotoMenu";
import { UPDATE_TYPE } from "./MyProfileContainer";
import AddressInput from "components/atoms/input/address/AddressInput";
import DeactivateModal from "./modal/deactivate";
import DeleteConfirmModal from "components/templates/modal/small/confirm/delete";
import {
  Validate,
  ValidateMsg,
} from "api/interfaces/commonInterface.interface";
import { ThemeText } from "redux/reducers/theme/themeReducer";

export type Props = {
  isModal: boolean;
  isDeactiveModal: boolean;
  modalType: string;
  onModal: (type: string) => void;
  onDeactiveModal: () => void;
  originName: string;
  profile: UserDetail;
  onChangeIsCrop: (type?: string) => void;
  isCrop: boolean;
  isPhotoMenu: boolean;
  profileImageUrl: string;
  // newProfileImageUrl: string;
  originProfileImageUrl: string;
  accountLevel: string;
  // onChangeProfileImage: (value: any) => void;
  onFinishProfileImage: (croppedImage: File, originFile: File) => void;
  onChangeisPhotoMenu: () => void;
  onChangeProfile: (name: string, value: string | boolean) => void;
  onChangeAddress: (type: UPDATE_TYPE, value: string) => void;
  onClickSave: () => void;
  isValidated: Validate;
  isValidatedMsg: ValidateMsg;
  onDeleteProfileImage: () => void;
  onDeleteProfileImageConfirm: () => void;
  onConfirmDeactive: () => void;
  startPageOptions: Option[];
  isDeleteProfileImageConfirm: boolean;
  onChangeTheme: (theme: "light" | "dark") => void;
  tempTheme: ThemeText;
};

export function MyProfilePresenter({
  isModal,
  isDeactiveModal,
  modalType,
  onModal,
  originName,
  profile,
  onDeactiveModal,
  isCrop,
  isPhotoMenu,
  onChangeIsCrop,
  profileImageUrl,
  // newProfileImageUrl,
  originProfileImageUrl,
  accountLevel,
  // onChangeProfileImage,
  onFinishProfileImage,
  onChangeisPhotoMenu,
  onChangeProfile,
  onChangeAddress,
  onClickSave,
  isValidated,
  isValidatedMsg,
  onDeleteProfileImage,
  onDeleteProfileImageConfirm,
  onConfirmDeactive,
  startPageOptions,
  isDeleteProfileImageConfirm,
  onChangeTheme,
  tempTheme,
}: Props): JSX.Element {
  return (
    <>
      <s.Container>
        <mediaQuery.Default>
          <s.Title>
            <Text fontSize={24} bold>
              Profile
            </Text>
          </s.Title>
        </mediaQuery.Default>
        <s.Wrapper>
          <s.RowItem>
            <s.Item>
              <s.ProfileInner>
                <s.ProfileImageWrapper level={accountLevel}>
                  <s.Camera onClick={onChangeisPhotoMenu}>
                    <s.CameraInner>
                      {isPhotoMenu && (
                        <PhotoMenu
                          profileImageUrl={profileImageUrl}
                          onChangeIsCrop={onChangeIsCrop}
                          onDeleteProfileImage={onDeleteProfileImageConfirm}
                          // onChangeProfileImage={onChangeProfileImage}
                        />
                      )}

                      <Camera />
                    </s.CameraInner>
                  </s.Camera>
                  <s.ProfileImage>
                    <UserProfileImage />
                  </s.ProfileImage>
                </s.ProfileImageWrapper>
                <s.Name>
                  <Text bold>{originName}</Text>
                </s.Name>
                <div className="account-date-info">
                  <s.RowInput>
                    <Text fontSize={12} color={COLORS.BUTTONS1} bold>
                      Account Created
                    </Text>
                    <Text fontSize={12} color={COLORS.BUTTONS1}>
                      {profile.createAt}
                    </Text>
                  </s.RowInput>
                  <s.RowInput>
                    <Text fontSize={12} color={COLORS.BUTTONS1} bold>
                      Last Login
                    </Text>
                    <Text fontSize={12} color={COLORS.BUTTONS1}>
                      {profile.lastLoggedOn}
                    </Text>
                  </s.RowInput>
                </div>
              </s.ProfileInner>
            </s.Item>
            <s.Item>
              <s.RowInput>
                <ModalInput label="First Name" noBold>
                  <Input
                    //defaultValue={profile.name}
                    name="firstName"
                    maxLength={100}
                    value={profile.firstName}
                    onChange={(e) =>
                      onChangeProfile(e.target.name, e.target.value)
                    }
                    isValidated={isValidated.firstName}
                    placeholder="Enter first Name"
                  />
                  {isValidated.firstName && (
                    <ValidateWord>{isValidatedMsg.firstName}</ValidateWord>
                  )}
                </ModalInput>
                <ModalInput label="Last Name" noBold>
                  <Input
                    //defaultValue={profile.name}
                    name="lastName"
                    maxLength={100}
                    value={profile.lastName}
                    onChange={(e) =>
                      onChangeProfile(e.target.name, e.target.value)
                    }
                    isValidated={isValidated.lastName}
                    placeholder="Enter last Name"
                  />
                  {isValidated.lastName && (
                    <ValidateWord>{isValidatedMsg.lastName}</ValidateWord>
                  )}
                </ModalInput>
              </s.RowInput>
              <ModalInput label="Email" noBold>
                <s.RowInput>
                  {/* <Input disabled value={profile.email} /> */}
                  <Input disabled value={profile.email} />
                  {/* email change not permit (first of all , auth check not user email but userId)*/}
                  <div style={{ visibility: "hidden" }}>
                    <PrimaryButton
                      label="Change"
                      buttonType="cancel"
                      disabled={
                        profile.provider !== undefined &&
                        profile.provider.toUpperCase() !== "AUTH0"
                      }
                      onClickBtn={() => onModal("email")}
                    />
                  </div>
                </s.RowInput>
              </ModalInput>
              <ModalInput label="Password" noBold>
                <s.RowInput>
                  <Input disabled type="password" value="000000000000" />

                  <PrimaryButton
                    label="Change"
                    buttonType="cancel"
                    disabled={
                      profile.provider !== undefined &&
                      profile.provider.toUpperCase() !== "AUTH0"
                    }
                    onClickBtn={() => onModal("password")}
                  />
                </s.RowInput>
              </ModalInput>
              <ModalInput label="Phone Number" noBold>
                <s.RowInput>
                  <PhoneInput
                    placeholder="Enter your phone number"
                    selected={profile.phone}
                    disabled={true}
                  />

                  <PrimaryButton
                    label="Change"
                    buttonType="cancel"
                    // disabled={
                    //   profile.provider !== undefined &&
                    //   profile.provider.toUpperCase() !== "AUTH0"
                    // }
                    onClickBtn={() => onModal("phoneNumber")}
                  />
                </s.RowInput>
              </ModalInput>
              <ModalInput label="Address" noBold>
                <AddressInput
                  isLoaded={true}
                  value={profile.address}
                  onChange={(e) =>
                    onChangeAddress(
                      UPDATE_TYPE.USER_ADDR,
                      e.currentTarget.value
                    )
                  }
                  onChangeLocationSelect={(
                    address: google.maps.LatLngLiteral,
                    addressString?: string
                  ) => {
                    if (addressString !== undefined) {
                      onChangeProfile("address", addressString);
                    }
                  }}
                />
              </ModalInput>
            </s.Item>
          </s.RowItem>
          <s.Item className="company-item">
            <Text bold>Company</Text>
            <s.RowInput className="input-item">
              <ModalInput label="Name" noBold>
                <Input
                  name="companyName"
                  placeholder="Enter company name"
                  maxLength={100}
                  value={profile.companyName}
                  onChange={(e) =>
                    onChangeProfile(e.target.name, e.target.value)
                  }
                />
              </ModalInput>
              <ModalInput label="Department" noBold>
                <Input
                  name="department"
                  placeholder="Enter department"
                  maxLength={100}
                  value={profile.department}
                  onChange={(e) =>
                    onChangeProfile(e.target.name, e.target.value)
                  }
                />
              </ModalInput>
              <ModalInput label="Job Title" noBold>
                <Input
                  name="jobTitle"
                  placeholder="Enter job title"
                  maxLength={100}
                  value={profile.jobTitle}
                  onChange={(e) =>
                    onChangeProfile(e.target.name, e.target.value)
                  }
                />
              </ModalInput>
            </s.RowInput>
            <s.RowInput className="input-item">
              <ModalInput label="Address" noBold flex={2}>
                <AddressInput
                  isLoaded={true}
                  value={profile.companyAddress}
                  onChange={(e) =>
                    onChangeAddress(
                      UPDATE_TYPE.COMPANY_ADDR,
                      e.currentTarget.value
                    )
                  }
                  onChangeLocationSelect={(
                    address: google.maps.LatLngLiteral,
                    addressString?: string
                  ) => {
                    if (addressString !== undefined) {
                      onChangeProfile("companyAddress", addressString);
                    }
                  }}
                />
              </ModalInput>
              <ModalInput
                label="Phone"
                noBold
                isValidated={isValidated.companyPhoneNumber}
                validatedWord={isValidatedMsg.companyPhoneNumber}
                flex={1}
              >
                {/* <s.RowInput> */}
                <PhoneInput
                  placeholder="Enter company phone number"
                  selected={profile.companyPhoneNumber}
                  onChange={(phoneNumber) => {
                    onChangeProfile("companyPhoneNumber", phoneNumber);
                  }}
                />
                {/* </s.RowInput> */}
              </ModalInput>
            </s.RowInput>
          </s.Item>

          <s.Item>
            <s.RowInput>
              <ModalInput label="Start Page" flex={1} noBold>
                <Selects
                  options={startPageOptions}
                  value={startPageOptions.find(
                    (option) => option.value === profile.startPage
                  )}
                  onChange={(value) => {
                    onChangeProfile("startPage", value);
                  }}
                />
              </ModalInput>
              <ModalInput
                label="Two-Factor Authentication (2FA)"
                flex={2}
                noBold
              >
                <s.CheckWrapper>
                  <label>
                    <input
                      type="radio"
                      value="email"
                      name="use2faCode"
                      onChange={(e) =>
                        onChangeProfile(e.target.name, e.target.value)
                      }
                      checked={profile.use2faCode === "email"}
                    />{" "}
                    Email
                  </label>
                  <label>
                    <input
                      type="radio"
                      value="phone"
                      name="use2faCode"
                      onChange={(e) =>
                        onChangeProfile(e.target.name, e.target.value)
                      }
                      disabled={
                        profile.phone === undefined || profile.phone === ""
                      }
                      checked={profile.use2faCode === "phone"}
                    />{" "}
                    Phone
                  </label>
                </s.CheckWrapper>
              </ModalInput>
            </s.RowInput>
            <s.RowInput className="input-item">
              <ModalInput label="Receive alert emails" flex={1} noBold>
                <Toggle
                  isToggled={profile.notifyAlertMail}
                  checked={profile.notifyAlertMail}
                  onChange={(checked) => {
                    onChangeProfile("notifyAlertMail", checked);
                  }}
                />
              </ModalInput>
              <ModalInput label="Receive report emails" flex={1} noBold>
                <Toggle
                  isToggled={profile.notifyReportMail}
                  checked={profile.notifyReportMail}
                  onChange={(checked) => {
                    onChangeProfile("notifyReportMail", checked);
                  }}
                />
              </ModalInput>
              <ModalInput label="Receive push notifications" flex={1} noBold>
                <Toggle
                  isToggled={profile.notifyPush}
                  checked={profile.notifyPush}
                  onChange={(checked) => onChangeProfile("notifyPush", checked)}
                />
              </ModalInput>
            </s.RowInput>
            <s.RowInput>
              <ModalInput label="Receive SMS" flex={1} noBold>
                <Toggle
                  isToggled={profile.notifySms}
                  checked={profile.notifySms}
                  onChange={(checked) => {
                    onChangeProfile("notifySms", checked);
                  }}
                />
              </ModalInput>
              <ModalInput label="Newsletters" flex={1} noBold>
                <Toggle
                  isToggled={profile.newsLetter}
                  checked={profile.newsLetter}
                  // label={"Subscribed"}
                  label={profile.newsLetter ? "Subscribed" : "Subscribe"}
                  onChange={(checked) => {
                    onChangeProfile("newsLetter", checked);
                  }}
                />
              </ModalInput>
              <ModalInput label="Theme" flex={1} noBold>
                <Toggle
                  label={tempTheme === "light" ? "Light" : "Dark"}
                  isToggled={tempTheme === "light"}
                  // onChange={() => dispatch(setMode())}
                  onChange={(checked) =>
                    onChangeTheme(checked ? "light" : "dark")
                  }
                />
              </ModalInput>
            </s.RowInput>
          </s.Item>
          <s.ButtonWrapper>
            <BigButton
              buttonType="warning"
              label="Deactivate"
              onClickBtn={onDeactiveModal}
            />
            <BigButton label="Save Settings" onClickBtn={onClickSave} />
          </s.ButtonWrapper>
        </s.Wrapper>
      </s.Container>
      {isModal && (
        <ChangeModal
          email={profile.email}
          phone={profile.phone}
          userId={profile.userId}
          modalType={modalType}
          onModal={onModal}
        />
      )}
      {isDeactiveModal && (
        <DeactivateModal
          onDeactiveModal={onDeactiveModal}
          onConfirm={onConfirmDeactive}
        />
      )}
      {isCrop && (
        <ImageCrop
          // newUrl={newProfileImageUrl}
          originUrl={originProfileImageUrl}
          onModal={() => onChangeIsCrop("close")}
          onFinish={onFinishProfileImage}
          // onChange={onChangeProfileImage}
        />
      )}
      {isDeleteProfileImageConfirm && (
        <DeleteConfirmModal
          label="Profile Image"
          onDelete={onDeleteProfileImageConfirm}
          onConfirmDelete={onDeleteProfileImage}
        />
      )}
    </>
  );
}
