import * as s from "../../VideoShareStyled";
import { Text, ValidateWord } from "components/atoms/text/Text";
import { COLORS } from "styles/colors";
import SmallPopupTemplate from "components/templates/modal/small/SmallPopupTemplate";
import DateSelect from "components/atoms/select/DateSelect";
import TextArea from "components/atoms/input/TextArea";
import { VideoShareUserPost } from "api/interfaces/videoshareInterface.interface";
import EmailAdd from "components/atoms/select/email";
import PrimaryButton from "components/atoms/buttons/PrimaryButton";
import { forwardRef, useState } from "react";
import ToggleComponent from "components/atoms/toggle/Toggle";
import { MultiValue } from "react-select";
import { useIntl } from "react-intl";
import * as mediaQuery from "components/MediaQuery";
import CLOSE_ICON from "assets/icons/sidenav/x.svg";

type Props = {
  small?: boolean;
  index: number;
  state: VideoShareUserPost;
  isExpired: boolean;
  onChangeModal: (type: string, value: boolean) => void;
  onChangeValue: (e: any) => void;
  onChangeDateValue: (date: Date) => void;
  onAddUser: () => void;
  onSelectUser: (user: MultiValue<any>) => void;
  today: Date;
  //validateShareDate: Date[];
  isValid: boolean;
  isValidEmail: boolean;
  isShare: boolean;
  onChangeSharing: (checked: boolean) => void;
};

const AddVSPresenter = forwardRef<HTMLDivElement, Props>(
  (
    {
      small,
      index,
      state,
      isExpired,
      onChangeModal,
      onChangeValue,
      onChangeDateValue,
      onAddUser,
      onSelectUser,
      //validateShareDate,
      today,
      isValid,
      isValidEmail,
      isShare,
      onChangeSharing,
    }: Props,
    ref
  ): JSX.Element => {
    const intl = useIntl();

    return small ? (
      <s.ShareBubble index={index}>
        <mediaQuery.Mobile>
          <div
            className="close-button-add"
            onClick={() => onChangeModal("add", false)}
          >
            <img alt="close button" src={CLOSE_ICON} />
          </div>
        </mediaQuery.Mobile>
        <s.ShareTitle>
          <Text bold fontSize={18}>
            Sharing
          </Text>
          <ToggleComponent onChange={onChangeSharing} isToggled={isShare} />
        </s.ShareTitle>
        {isShare && (
          <s.ShareInner>
            <s.EmailSelectContainer>
              <EmailAdd
                value={state.user}
                onChange={onSelectUser}
                //placeholder="Select User or Enter Email Address"
                isVs
                isMulti

                // isSelectedArr={state.user}
              />
            </s.EmailSelectContainer>
            <s.AddCheck>
              <label>
                <input
                  type="checkbox"
                  name="canDownload"
                  checked={state.canDownload}
                  onChange={onChangeValue}
                />{" "}
                <Text fontSize={14}>Allow Download</Text>
              </label>
            </s.AddCheck>
            <s.AddCheck>
              <label>
                <input
                  name="isExpired"
                  type="checkbox"
                  checked={isExpired}
                  onChange={onChangeValue}
                />{" "}
                <Text fontSize={14}>Expiration</Text>
              </label>
              <p className="date-select">
                <DateSelect
                  right
                  disabled={!isExpired}
                  value={state.expires}
                  onChange={onChangeDateValue}
                  tileDisabled={({ date, view }) =>
                    view === "month" && date <= today
                  }
                  // tileDisabled={({ date, view }) =>
                  //   view === "month" &&
                  //   !validateShareDate.some(
                  //     (validDate) =>
                  //       date.getFullYear() === validDate.getFullYear() &&
                  //       date.getMonth() === validDate.getMonth() &&
                  //       date.getDate() === validDate.getDate()
                  //   )
                  // }
                />
              </p>
            </s.AddCheck>
            <s.SendWrapper>
              <PrimaryButton
                buttonType="cancel"
                label="Send"
                onClickBtn={onAddUser}
              />
            </s.SendWrapper>
          </s.ShareInner>
        )}
      </s.ShareBubble>
    ) : (
      <SmallPopupTemplate
        title="Share Video Clip"
        subTitle="Share this video with one or more recipients."
        onClick={onAddUser}
        onModal={() => onChangeModal("add", false)}
        confirmLabel="Send"
        ref={ref}
      >
        <s.EmailSelectContainer>
          <EmailAdd
            value={state.user}
            //placeholder="Select User or Enter Email Address"
            onChange={onSelectUser}
            isVs
            isMulti
            // isSelectedArr={state.user}
          />
        </s.EmailSelectContainer>

        <s.MessageOptional>
          <Text fontSize={18}>Message (Optional)</Text>
          <TextArea
            name="message"
            value={state.message}
            onChange={onChangeValue}
            placeholder="Enter your message here."
          />
          <s.AddCheck>
            <label>
              <input
                name="isExpired"
                type="checkbox"
                checked={isExpired}
                onChange={onChangeValue}
              />{" "}
              <Text>Expiration</Text>
            </label>
            <p className="date-select">
              <DateSelect
                disabled={!isExpired}
                value={state.expires}
                onChange={onChangeDateValue}
                tileDisabled={({ date, view }) =>
                  view === "month" && date <= today
                }
                right
              />
            </p>
          </s.AddCheck>
          <s.AddCheck>
            <label>
              <input
                type="checkbox"
                name="canDownload"
                checked={state.canDownload}
                onChange={onChangeValue}
              />{" "}
              <Text>Allow Download</Text>
            </label>
          </s.AddCheck>

          {!isValid && (
            <ValidateWord>
              {intl.formatMessage({
                id: "validateMsg.videoShare.err.emptyUser",
                defaultMessage:
                  "Select one or more users or enter email address.",
              })}
            </ValidateWord>
          )}
          {!isValidEmail && (
            <ValidateWord>
              {intl.formatMessage({
                id: "validateMsg.videoShare.err.invalidEmail",
                defaultMessage: "The entered email is invalid.",
              })}
            </ValidateWord>
          )}
        </s.MessageOptional>
      </SmallPopupTemplate>
    );
  }
);

export default AddVSPresenter;
