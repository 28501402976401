import styled from "styled-components";
import { COLORS } from "styles/colors";
import { FLEX } from "styles/flex";
import { calcRem } from "styles/theme";
import * as mediaQuery from "components/MediaQuery";

const Container = styled.div<{ type: string }>`
  border-radius: 2px;

  font-size: ${calcRem(14)};
  color: ${COLORS.WHITE};
  ${FLEX.FlexCenterCenter};
  background-color: ${COLORS.WHITE}40;

  ${({ type }) =>
    (type.includes("NVR") || type.includes("VMAX")) &&
    `
  background-color: ${COLORS.PRIMARY1};
`}
  ${({ type }) =>
    (type.toUpperCase().includes("SPECTRUM") ||
      type.toUpperCase().includes("Spectrum")) &&
    `
    background-color: #175B22;
  `};

  ${mediaQuery.isDefault} {
    min-width: 90px;
    height: 28px;
  }
  ${mediaQuery.isMobile} {
    padding: 6px 8px;
  }
`;

interface TypeProps {
  type: string;
}

function Type({ type }: TypeProps): JSX.Element {
  return (
    <Container type={type} className="recorder-type" data-tag="allowRowEvents">
      {type}
    </Container>
  );
}

export default Type;
