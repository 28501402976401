import { useEffect, useRef } from "react";
import { NodeApi, NodeRendererProps, Tree } from "react-arborist";
import { AiFillFile, AiFillFolder } from "react-icons/ai";
import { MdDeleteForever, MdArrowDropDown, MdArrowRight, MdEdit } from "react-icons/md";
import { SelectGroup, SelectedCategory } from "../VideoManagerContainer";
import { node } from "prop-types";

type Props = {
  data: any;
  selectedCategory:SelectedCategory;
  treeRef: React.MutableRefObject<any>;
  onClickCategory? :(category:SelectedCategory)=> void;
  onClickGroup?: (group: SelectGroup) => void;
  onDelete?:(group:SelectGroup)=> void;
};

export type Data = {
  id: string;
  name: string;
  position?: number;
  children?: Data[];
};


function VideoTree(props: Props): JSX.Element {
  const onRename = ({ id, name }: any) => {
    if (props.treeRef.current) {
      const node = props.treeRef.current.get(id);
      if (node) {
        node.data.name = name;
      }
    }
  };

  const onDelete = ({ ids }: any) => {};
  const onMove = ({ dragIds, parentId, index }: any) => {};

  const onSelectHandler = (nodes: NodeApi<Data>[]) => {

    if (nodes !== undefined && nodes[0]?.level === 0) {
      if (props.onClickCategory) {
        props.onClickCategory({
          categoryId : nodes[0].id,
          categoryName : nodes[0].data.name
        });
      }
      if (props.onClickGroup) {
        props.onClickGroup({
          category : "", 
          groupId : "",
          groupName : ""
        });
      }
    }

    if (nodes !== undefined && nodes[0]?.level === 1) {

      if (props.onClickCategory) {
        props.onClickCategory({
          categoryId : nodes[0].parent?.id as string,
          categoryName : nodes[0].parent?.data.name as string
        });
      }
      if (props.onClickGroup) {
        props.onClickGroup({
          category : nodes[0].parent?.id as string, 
          groupId : nodes[0].id,
          groupName : nodes[0].data.name
        });
      }
    }
  };

  useEffect(()=>{
    
    if(props.data){
      if(props.treeRef.current){

      }
    }
  },[props.data])

  const  Node = ({ node, style, dragHandle, tree }: NodeRendererProps<Data>)=>{
    return (
      <div
        className={`node-container ${node.state.isSelected ? "isSelected" : ""}`}
        style={style}
        ref={dragHandle}
      >
        <div
          className="node-content"
          onClick={() => node.isInternal && node.toggle()}
        >
          {node.level === 1 ? (
            <>
              <span className="arrow"></span>
              <span className="file-folder-icon">
                <AiFillFile color="#6bc7f6" />
              </span>
            </>
          ) : (
            <>
              <span className="arrow">
                {node.isOpen ? <MdArrowDropDown /> : <MdArrowRight />}
              </span>
              <span className="file-folder-icon">
                <AiFillFolder color="#f6cf60" />
              </span>
            </>
          )}
          <span className="node-text">
            {node.isEditing ? (
              <input
                type="text"
                defaultValue={node.data.name}
                onFocus={(e) => e.currentTarget.select()}
                onBlur={() => node.reset()}
                onKeyDown={(e) => {
                  if (e.key === "Escape") node.reset();
                  if (e.key === "Enter") node.submit(e.currentTarget.value);
                }}
                autoFocus
              />
            ) : (
              <span>{node.data.name}</span>
            )}
          </span>
        </div>
  
        {node.level === 1 && node.isSelected &&  (
          <div className="file-actions">
            <div className="folderFileActions">
              <button onClick={() => onClickDelete(node)} title="Edit">
                <MdEdit />
              </button>
            </div>
          </div>
        )}
      </div>
    );
  }

  const onClickDelete = (node: NodeApi<Data>) =>{
    if(props.onDelete && node.id){
      props.onDelete({
        category : node.parent?.id as string, 
        groupId : node.id,
        groupName : node.data.name
      });
    }
  }

  return (
    <Tree
      data={props.data}
      ref={props.treeRef}
      className={"tree"}
      rowClassName={"row"}
      indent={24}
      rowHeight={36}
      overscanCount={1}
      paddingTop={30}
      paddingBottom={10}
      padding={25 /* sets both */}
      onRename={onRename}
      onDelete={onDelete}
      onMove={onMove}
      onSelect={onSelectHandler}
      openByDefault={true}
      selection={props.selectedCategory.categoryId}
    >
      {Node}
    </Tree>
  );
}

export default VideoTree;
