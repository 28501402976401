import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface SideNavOpen {
  isOpen: boolean;
}

const initialState: SideNavOpen = {
  isOpen: false,
};

/**
 * SideBar의 메뉴를 클릭 시 선택된 메뉴를 전역으로 저장하는 Redux
 * 저장된 메뉴ITem는 상단의 메뉴바를 바꿔주는데 사용함.
 * Default는 상단의 DashBoard Item
 */
// TODO Account와 Recorder의 상단에 표시로직 구현되어야함. 다른메뉴에서는 비활성화
export const SideNavReducer = createSlice({
  name: "sidenavOpen",
  initialState,
  reducers: {
    onOpen(SideNavOpen, action: PayloadAction<boolean>) {
      SideNavOpen.isOpen = action.payload;
    },
  },
});

export const { onOpen } = SideNavReducer.actions;

export default SideNavReducer;
