import InnerContainer, { InnerPX } from "components/atoms/inner/InnerContainer";
import { Text } from "components/atoms/text/Text";
import styled from "styled-components";
import { COLORS } from "styles/colors";
import { FLEX } from "styles/flex";
import { calcRem } from "styles/theme";
import * as mediaQuery from "components/MediaQuery";
import { NOTICE_HEIGHT } from "components/element/NoticeBar";

export const Container = styled.div<{ noticeLength?: number }>`
  width: 100%;
  height: ${({ noticeLength }) =>
    noticeLength
      ? `
    calc(100vh - ${NOTICE_HEIGHT * noticeLength + 50}px);
  `
      : `calc(100vh - 50px)`};
  section:nth-child(2) {
    background-color: ${({ theme }) =>
      theme.value === "light" ? "#00000005" : "#0e172254"};
  }
  section:nth-child(3) {
    background-color: ${({ theme }) =>
      theme.value === "light" ? "#00000010" : "#0e1722"};
  }

  ${mediaQuery.isDefault} {
    display: grid;
    grid-template-columns: 2fr 3fr;
    ${({ theme }) =>
      theme.value === "light" &&
      `
        background-color: ${COLORS.WHITE};
      `}
    /* overflow: hidden; */

    section:nth-child(1) {
      grid-row: span 2;
    }
    section:nth-child(2),
    section:nth-child(3) {
      /* height: 100%;
      max-height: 100%; */
      /* min-height: 100%; */
      /* height: calc(100% / 2); */
    }
  }

  .border {
    border-bottom: 1px solid ${({ theme }) => theme.COLORS.BORDER};
  }
`;

export const Inner = styled(InnerContainer)<{ first?: boolean }>`
  height: 100%;

  ${mediaQuery.isDefault} {
    box-sizing: border-box;
    padding: 20px ${InnerPX}px;
  }
  ${mediaQuery.isMobile} {
    padding: 30px;
    overflow-y: unset;
  }
`;

export const Section = styled.section`
  /* width: 100%; */
  min-height: 0;
`;

export const TinyTitle = styled.div`
  ${FLEX.FlexStartCenter};
  gap: 30px;
  color: ${COLORS.WHITE};
`;

export const ButtonWrapper = styled.div`
  ${FLEX.FlexStartCenter};
  gap: 10px;

  > button {
    ${({ theme }) =>
      theme.value === "light" &&
      `
      background-color: ${theme.COLORS.BORDER}
    `}
  }
`;

export const BigTitle = styled.h3`
  font-size: ${calcRem(24)};
  font-weight: 500;
  color: ${({ theme }) => theme.COLORS.WHITE};
`;

export const MiddleTitle = styled.h5`
  font-size: ${calcRem(20)};
  font-weight: 500;
  color: ${({ theme }) => theme.COLORS.WHITE};

  ${FLEX.FlexStartCenter};
  gap: 30px;
`;

export const GlobalWrapper = styled.ul`
  ${FLEX.FlexStartStart};
  flex-direction: column;
`;

export const GlobalItem = styled.li<{ noBorder?: boolean }>`
  width: 100%;
  padding: 24px 0;
  border-bottom: 1px solid ${({ theme }) => theme.COLORS.BORDER};
  ${FLEX.FlexBetweenCenter};

  ${({ noBorder }) =>
    noBorder &&
    `
    border-width: 0;
  `}
`;

export const CountWrapper = styled.ul`
  ${FLEX.FlexStartStart};
  flex-direction: column;
  flex: 1;
  gap: 8px;
`;

export const GloablTitle = styled(CountWrapper)`
  .global-subtitle {
    color: ${({ theme }) => `${theme.COLORS.WHITE}70`};
  }

  ${mediaQuery.isMobile} {
    flex: 2;
  }
`;

export const GlobalCount = styled.li`
  ${FLEX.FlexStartCenter};
  gap: 10px;
`;
export const StaticIcon = styled.div`
  width: 30px;
  ${FLEX.FlexCenterCenter};

  ${mediaQuery.isMobile} {
    > img {
      height: 16px;
    }
  }
`;
export const UserLoginWrapper = styled.div`
  width: 100%;
  height: 50%;
  flex: 1;
`;
export const UserLoginTitle = styled.div`
  ${FLEX.FlexBetweenCenter};
  width: 100%;
  padding-bottom: 15px;

  .select {
    width: 40%;
  }
`;

export const UserLoginChartWrapper = styled.div`
  width: 100%;
  ${mediaQuery.isDefault} {
    height: 80%;
    max-height: 410px;
    padding-top: 10px;
    padding-left: 10px;
  }
  ${mediaQuery.isMobile} {
    height: 300px;
  }
`;

export const ClientChartWrapper = styled(UserLoginChartWrapper)`
  /* height: 100%;
  min-height: 80%; */
  min-height: 410px;

  .chart-empty {
    height: 100%;
  }
`;

export const SystemContainer = styled.div`
  ${FLEX.FlexBetweenStart};
  flex: 1;
  ${mediaQuery.isDefault} {
    padding-top: 24px;
    height: 80%;
  }

  ${mediaQuery.isMobile} {
    width: 100%;
    flex-direction: column;
  }
`;
export const SystemWrapper = styled.div`
  /* width: 50%; */
  flex: 1;

  ${mediaQuery.isDefault} {
    height: calc(100% - 30px);
  }

  ${mediaQuery.isMobile} {
    width: 100%;
    margin-top: 20px;
  }
`;
export const SystemInner = styled.ul`
  ${FLEX.FlexStartStart};
  flex-direction: column;
  width: 100%;
  height: 100%;
  margin-top: 10px;
  flex: 1;

  > li {
    width: 100%;
    flex: 1;
    max-height: 25%;
  }
`;

export const SystemItem = styled.li`
  ${FLEX.FlexBetweenCenter};
  padding: 8px 0;
`;

export const SystemText = styled.p<{ value?: boolean }>`
  color: ${({ theme }) => `${theme.COLORS.WHITE}70`};
  flex: 3;

  ${({ value }) =>
    value &&
    `
    flex: 1;
  color: ${COLORS.BUTTONS2};
  `}
`;
export const Border = styled.p`
  width: 1px;
  height: 100%;
  background-color: ${({ theme }) => theme.COLORS.BORDER};
  margin: 0 40px;
`;

export const OptionWrapper = styled.div`
  ${mediaQuery.isDefault} {
    ${FLEX.FlexStartCenter};
    gap: 25px;
  }
  ${mediaQuery.isMobile} {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 6px;
  }
`;
