import { Serie } from "@nivo/line";
import { ProfileAccountInfo } from "api/interfaces/accountInterface.interface";
import {
  Interval,
  RemoteAccessData,
  RemoteAccessInfoData,
  ReportHealthDetailRemote,
  ReportHealthDetailUser,
} from "api/interfaces/reportInterface.interface";
import { getReportDetailRemoteAccess } from "api/reportAPI";
import LineInterpolationChart from "components/atoms/chart/LineInterpolationChart";
import ReportSelect from "components/atoms/select/modal/ReportSelect";
import { Text } from "components/atoms/text/Text";
import NoData from "components/templates/nopage/NoData";
import { useState } from "react";
import { useQuery } from "react-query";
import { useAppSelector } from "redux/hooks";
import styled from "styled-components";
import { FLEX } from "styles/flex";
import { intervalOptions } from "utils/options";
import { getChartTime } from "utils/timeUtil";
import * as mediaQuery from "components/MediaQuery";

type Props = {
  reportId: string;
  recorderId: string;
  data: ReportHealthDetailRemote;
  userData: ReportHealthDetailUser; // myDW Access User Data
};

export default function RemoteAccess({
  data,
  userData,
  reportId,
  recorderId,
}: Props): JSX.Element {
  const [intervalValue, setIntervalValue] = useState<Interval>("DAILY");
  const changeToDataFormat = (remoteData: RemoteAccessData[]) => {
    let tempRemoteData: Serie[] = remoteData?.map(
      (remote: RemoteAccessData) => {
        let remoteName: string = remote.name
          ? remote.name.includes("@")
            ? remote.name.split("@")[0]
            : remote.name
          : "unknown";
        let remoteCount = remote.infos.map((info: RemoteAccessInfoData) => {
          let timeStamp = getChartTime(
            info.timestamp,
            selectedAccount,
            intervalValue
          );

          return {
            x: info.timestamp,
            y: info.count,
          };
        });

        return {
          id: remoteName,
          color: `rgb(${Math.floor(Math.random() * 256)}, ${Math.floor(
            Math.random() * 210
          )}, ${Math.floor(Math.random() * 256)})`,
          data: remoteCount,
        };
      }
    );
    return tempRemoteData;
  };

  const selectedAccount: ProfileAccountInfo = useAppSelector(
    (state) => state.accountSettings
  );
  const [chartData, setChartData] = useState<Serie[]>(
    changeToDataFormat(data.users)
  );

  const onChangeIntervalOption = (e: any) => {
    setIntervalValue(e);
  };

  const healthReportRemoteAccessQuery = useQuery(
    ["remoteAccess", intervalValue],
    () =>
      getReportDetailRemoteAccess({
        reportId: reportId,
        recorderId: recorderId,
        accountId: selectedAccount.accountId,
        periodicalType: intervalValue,
      }),
    {
      retry: 0,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      onSuccess: (res: any) => {
        if (res.result) {
          setChartData(changeToDataFormat(res.result.users));
        } else setChartData([]);
      },
      onError: (e: any) => {},
    }
  );

  return (
    <Inner>
      <p className="select-wrapper">
        <ReportSelect
          options={intervalOptions}
          value={intervalOptions.filter(function (option) {
            return option.value === intervalValue;
          })}
          onChange={onChangeIntervalOption}
        />
      </p>
      <Chart>
        {chartData.length === 0 ? (
          <NoData />
        ) : (
          <LineInterpolationChart data={chartData} />
        )}
      </Chart>
      {/* <UserTable>
        <Text className="title" bold fontSize={18}>
          myDW Access
        </Text>
        <Users data={userData.users} />
      </UserTable> */}
    </Inner>
  );
}
const Inner = styled.div`
  width: 100%;
  height: 100%;
  padding: 15px 0;
  box-sizing: border-box;
  ${FLEX.FlexBetweenStart};
  flex-direction: column;
  flex: 1;

  .select-wrapper {
    margin-bottom: 10px;
  }

  ${mediaQuery.isMobile} {
    height: 500px;
  }
`;

const Chart = styled.div`
  width: 98%;
  height: 90%;
  min-height: 90%;
  max-height: 90%;
  display: flex;
  flex: 1;
  color: ${({ theme }) => theme.COLORS.WHITE};

  ${mediaQuery.isDefault} {
    height: 36%;
  }

  ${mediaQuery.isMobile} {
    height: 260px;
    min-height: 260px;
  }
`;

const UserTable = styled.div`
  width: 100%;
  flex: 1.2;
`;
