import styled from "styled-components";
import { FLEX } from "styles/flex";
import { changeLevelToABB } from "utils/functions";
import { COLORS, LEVELS } from "../../../styles/colors";
import { calcRem } from "styles/theme";

interface LevelProps {
  level: string;
}

const LevelCircle = styled.span<{ level: string }>`
  border-radius: 50px;
  color: ${COLORS.WHITE};
  font-size: ${calcRem(12)};
  font-weight: 500;
  width: 26px;
  height: 26px;
  max-width: 26px;
  max-height: 26px;
  min-width: 26px;
  min-height: 26px;

  box-sizing: border-box;
  ${FLEX.FlexCenterCenter};

  ${({ level }) =>
    level === "" ? null : ` background-color: ${LEVELS[level]} `}
`;

export function Level({ level }: LevelProps): JSX.Element {
  return (
    <LevelCircle level={changeLevelToABB(level)} className="level-circle">
      {changeLevelToABB(level)}
    </LevelCircle>
  );
}
