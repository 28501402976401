import styled from "styled-components";
import { COLORS } from "styles/colors";
import { FLEX } from "styles/flex";
import { calcRem } from "styles/theme";
import * as mediaQuery from "components/MediaQuery";

const Container = styled.div<{ type: string }>`
  border-radius: 2px;

  font-size: ${calcRem(14)};
  color: ${COLORS.WHITE};
  ${FLEX.FlexCenterCenter};
  background-color: ${COLORS.WHITE}40;

  ${({ type }) =>
    (type.toUpperCase().includes("OPEN")) &&
    `
    background-color: ${COLORS.LIGHTGRAY};
  `}
  ${({ type }) =>
    (type.toUpperCase().includes("PROGRESS")) &&
    `
    background-color: ${COLORS.BUTTONS2};
  `};

  ${({ type }) =>
  (type.toUpperCase().includes("CLOSE")) &&
  `
    background-color: #0BA775;
  `};

  ${mediaQuery.isDefault} {
    width: 78px;
    height: 28px;
  }
  ${mediaQuery.isMobile} {
    padding: 6px 8px;
  }
`;

interface TypeProps {
  type: string;
}

function TechStatus({ type }: TypeProps): JSX.Element {
  return (
    <Container type={type} className="recorder-type">
      {type}
    </Container>
  );
}

export default TechStatus;
