import { useState, useEffect, useRef, useCallback } from "react";
import { interpolateRgb } from "d3-interpolate";
import { mapOptions } from "./GoogleMapConfig";
import {
  DashboardAlertData,
  DashboardData,
  DashboardFilter,
  DashboardFilterAlertResponse,
  DashboardFilterRequest,
  DashboardFilterResult,
  DashboardFilterStatus,
  KeywordSearch,
  KeywordSearchRecorder,
} from "api/interfaces/dashboardInterface.interface";
import MARKWE_ALERT from "assets/icons/map/marker_alert.svg";
import MARKWE_ASSIGN from "assets/icons/map/marker_assign.svg";
import MARKWE_HEALTHY from "assets/icons/map/marker_healthy.svg";
import MARKWE_WARNING from "assets/icons/map/marker_warning.svg";
import MARKWE_CRITICAL from "assets/icons/map/marker_critical.svg";
import { MAP_STATUS_COLORS } from "styles/colors";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import {
  Cluster,
  ClusterStats,
  MarkerClusterer,
  SuperClusterAlgorithm,
  onClusterClickHandler,
} from "@googlemaps/markerclusterer";
import {
  MapFilter,
  MapFilterKeyword,
  setKeyword,
} from "redux/reducers/map/mapFilter";
import HoverInfoView from "./sub/infoView/HoverInfoView";
import ReactDomServer from "react-dom/server";
import { GoogleMapPresenter } from "./GoogleMapPresenter";

import { ProfileAccountInfo } from "api/interfaces/accountInterface.interface";
import { postFilterSearchRecorders } from "api/dashboardAPI";
import {
  setDashboardData,
  setDashboardDataInit,
} from "redux/reducers/map/dashboardData";
import { chain, compact, set } from "lodash";
import { InfoWindow } from "@react-google-maps/api";
import { useQuery } from "react-query";
import { useQueryClient } from "react-query";
import { Marker } from "@googlemaps/markerclusterer/dist/marker-utils";
import { MultiValue } from "react-select";
import { More } from "./sub/subheader/SubHeader";
import {
  AlertDetail,
  SelectedAlertView,
} from "api/interfaces/alertInterface.interface";
import { filteringRecorder } from "utils/DashboardUtil";
import { changeAlertReasonToCategory } from "utils/AlertUtil";
import { Theme } from "redux/reducers/theme/themeReducer";
import { useMediaQuery } from "react-responsive";
import * as mediaQuery from "components/MediaQuery";

export interface clusterAlert {
  recorderCount: number | 0;
  // status: number | 0;
  warningCount: number | 0;
  alertCount: number | 0;
  assignCount: number | 0;
  criticalCount: number | 0;
  alertType: AlertDetail;
  // thumbnail: string | undefined;
}

export type CustomMarker = google.maps.Marker & {
  systems?: DashboardData[];
};

const determineMarker = (
  // status: number,
  // warningCount: number,
  // assignCount: number,
  alertCount: number,
  criticalCount: number
) => {
  let markerIcon = MARKWE_HEALTHY;

  // if (warningCount > 0) {
  //   markerIcon = MARKWE_WARNING;
  // }

  // if (assignCount > 0) {
  //   markerIcon = MARKWE_ASSIGN;
  // }

  if (alertCount > 0) {
    markerIcon = MARKWE_ALERT;
  }
  // TODO CRITICAL MARKER ICON 변경 필요.
  if (criticalCount > 0) {
    markerIcon = MARKWE_CRITICAL;
  }
  return markerIcon;
};

const determineLableColor = (
  // status: number,
  // warningCount: number,
  // assignCount: number,
  alertCount: number,
  criticalCount: number
) => {
  let labelcolor = MAP_STATUS_COLORS.HEALTHY;

  // if (warningCount > 0) {
  //   labelcolor = MAP_STATUS_COLORS.WARNING;
  // }

  // if (assignCount > 0) {
  //   labelcolor = MAP_STATUS_COLORS.ASSIGNED;
  // }

  if (alertCount > 0) {
    labelcolor = MAP_STATUS_COLORS.ALERT;
  }
  if (criticalCount > 0) {
    labelcolor = MAP_STATUS_COLORS.CRITICAL;
  }
  return labelcolor;
};

export default function MapView(): JSX.Element {
  const selectedAccount: ProfileAccountInfo = useAppSelector(
    (state) => state.accountSettings
  );
  const dispatch = useAppDispatch();
  const queryClient = useQueryClient();
  const mapFilter: MapFilter = useAppSelector((state) => state.mapFilter);
  const isNotMobile = useMediaQuery({ minWidth: mediaQuery.tabletMin });

  const theme: Theme = useAppSelector((state) => state.theme);

  const [isCollapsed, setIsCollapsed] = useState<boolean>(false);

  // 로드뷰인지 확인
  const mapRef = useRef(null);
  const infoWindowRef = useRef<InfoWindow>(null);

  const [isRoadview, setIsRoadview] = useState(true);
  const [markers, setMarkers] = useState<google.maps.Marker[]>([]);
  // const [markerCluster, setMarkerCluster] = useState<MarkerClusterer>();
  const [activeMarker, setActiveMarker] = useState<CustomMarker>();

  const [recorderMarker, setRecorderMarker] = useState<DashboardData[]>([]);

  // var mapInstance: google.maps.Map;
  const mapInstance = useRef<google.maps.Map>();
  const markerCluster = useRef<MarkerClusterer>();
  const rowRecorderList = useRef<DashboardFilterResult[]>();
  const isSearchMove = useRef<boolean>(false);
  const initDraw = useRef<boolean>(true);

  const dualModeRef = useRef<any>();

  // const [keyword, setKeyword] = useState<string>("");
  const [selectedAlert, setSelectedAlert] = useState<SelectedAlertView | null>(
    null
  );

  const [more, setMore] = useState<More>({
    types: false,
    recorders: false,
    alerts: false,
    recorderGroup: false,
  });
  const onSelectMoreFilter = (e: any) => {
    let {
      target: { checked, name },
    } = e;

    setMore({
      ...more,
      [name]: checked,
    });
  };

  const onDeleteMoreFilter = (name: string) => {
    setMore({
      ...more,
      [name]: false,
    });
  };

  // let closeInfoWindowWithTimeout = null;

  const closeInfoWindowWithTimeout = useRef<NodeJS.Timeout>();

  const circleRenderer = {
    palette: interpolateRgb("red", "blue"),
    render: function (
      { count, position, markers }: Cluster,
      stats: ClusterStats
    ): google.maps.Marker {
      let color;
      let totalRecorder = 0;
      let calculateAlertStatus: clusterAlert = {
        recorderCount: 0,
        warningCount: 0,
        alertCount: 0,
        assignCount: 0,
        criticalCount: 0,
        alertType: {
          system: 0,
          disk: 0,
          video: 0,
        },
        // thumbnail: undefined,
      };

      if (markers != null) {
        calculateAlertStatus = markers.reduce<clusterAlert>(
          (previousValue, currentValue: Marker, currentIndex) => {
            const subRecorder: DashboardData[] = (
              currentValue as google.maps.Marker
            ).get("systems");

            let recorderCnt = 0;
            let warningCnt = 0;
            let alertCnt = 0;
            let assignCnt = 0;
            let criticalCnt = 0;
            let alertTypeCount = {
              system: 0,
              disk: 0,
              video: 0,
            };

            if (subRecorder !== undefined && subRecorder.length > 0) {
              subRecorder.forEach((value, index) => {
                // console.log(value);
                if (
                  value.visible === undefined ||
                  (value.visible !== undefined && value.visible === true)
                ) {
                  recorderCnt += 1;
                  warningCnt +=
                    value.warning !== undefined ? value.warning.length : 0;
                  alertCnt +=
                    value.alert !== undefined ? value.alert.length : 0;
                  assignCnt +=
                    value.assign !== undefined ? value.assign.length : 0;
                  criticalCnt +=
                    value.critical !== undefined ? value.critical.length : 0;
                  alertTypeCount.system += value.system;
                  alertTypeCount.disk += value.disk;
                  alertTypeCount.video += value.video;
                }
              });
            }

            return {
              recorderCount: previousValue.recorderCount + recorderCnt,
              alertCount: previousValue.alertCount + alertCnt,
              warningCount: previousValue.warningCount + warningCnt,
              assignCount: previousValue.assignCount + assignCnt,
              criticalCount: previousValue.criticalCount + criticalCnt,
              alertType: {
                system: previousValue.alertType.system + alertTypeCount.system,
                disk: previousValue.alertType.disk + alertTypeCount.disk,
                video: previousValue.alertType.video + alertTypeCount.video,
              },
            };
          },
          {
            recorderCount: 0,
            alertCount: 0,
            warningCount: 0,
            assignCount: 0,
            criticalCount: 0,
            alertType: {
              system: 0,
              disk: 0,
              video: 0,
            },
          }
        );

        color = determineLableColor(
          // calculateAlertStatus?.status,
          // calculateAlertStatus?.warningCount,
          // calculateAlertStatus?.assignCount,
          calculateAlertStatus?.alertCount,
          calculateAlertStatus?.criticalCount
        );
        totalRecorder = calculateAlertStatus.recorderCount;
      }

      if (count !== undefined && count === 0) {
        return new google.maps.Marker({
          position,
          visible: false,
          // label: {
          //   text: String(count),
          //   color: "rgba(255,255,255,0.9)",
          //   fontSize: "12px",
          // },
          // adjust zIndex to be above other markers
          zIndex: Number(google.maps.Marker.MAX_ZINDEX) + count,
        });
      } else {
        // console.log("*********");
        const svg = window.btoa(
          unescape(
            encodeURIComponent(
              `
          <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="118.001" height="50.925" viewBox="0 0 118.001 50.925">
            <defs>
              <filter id="합치기_70" x="0" y="0" width="118.001" height="50.925" filterUnits="userSpaceOnUse">
                <feOffset dy="3" input="SourceAlpha"/>
                <feGaussianBlur stdDeviation="3" result="blur"/>
                <feFlood flood-opacity="0.161"/>
                <feComposite operator="in" in2="blur"/>
                <feComposite in="SourceGraphic"/>
              </filter>
            </defs>
            <g transform="matrix(1, 0, 0, 1, 0, 0)" filter="url(#합치기_70)">
              <path id="합치기_70-2" data-name="합치기 70" d="M446.624-5927.593,443.1-5934H407a10,10,0,0,1-10-10v-6a10,10,0,0,1,10-10h80a10,10,0,0,1,10,10v6a10,10,0,0,1-10,10H451.9l-3.524,6.406a.989.989,0,0,1-.876.519A.989.989,0,0,1,446.624-5927.593Z" transform="translate(-388 5966)" fill="${color}"/>
                <text x="60" y="20" fill="white" font-size="12" font-weight="bold" text-anchor="middle" alignment-baseline="middle" font-family="Roboto, sans-serif">
                      ${totalRecorder > 999 ? "999+" : totalRecorder} SYSTEMS
                      </text>
              </g>
          </svg>

      
        `
            )
          )
        );
        const returnMarker = new google.maps.Marker({
          position,
          icon: {
            // url: createDivAsImage(totalRecorder, "red"),
            url: `data:image/svg+xml;base64,${svg}`,
          },
          // label: {
          //   text: String(count),
          //   color: "rgba(255,255,255,0.9)",
          //   fontSize: "12px",
          // },
          // adjust zIndex to be above other markers
          zIndex: Number(google.maps.Marker.MAX_ZINDEX) + count,
        });

        const activeWindow = new google.maps.InfoWindow();
        activeWindow.setOptions({
          pixelOffset: new google.maps.Size(0, -25),
        });

        // convert react component to HTML STRING
        activeWindow.setContent(
          ReactDomServer.renderToString(
            <HoverInfoView
              theme={theme}
              count={totalRecorder}
              calculateAlertStatus={calculateAlertStatus}
            />
          )
        );

        // map clusterer marker
        isNotMobile &&
          returnMarker.addListener("mouseover", (e: any) => {
            // const mouseEvent = e.domEvent as MouseEvent;
            activeWindow.open({ anchor: returnMarker });
          });
        isNotMobile &&
          returnMarker.addListener("mouseout", () => {
            activeWindow.close();
          });

        // create marker using svg icon
        return returnMarker;
      }
    },
  };

  /**
   * isSearchMove (Search As Moved) 에 따른 dashboarddata 변경
   */
  const adjustMap = useCallback(
    (recorderData?: DashboardFilterResult[]) => {
      // console.log(isSearchMove);
      if (markerCluster === undefined || markerCluster.current === undefined) {
        return;
      }

      if (
        rowRecorderList.current === undefined ||
        rowRecorderList.current.length === 0
      ) {
        dispatch(setDashboardDataInit());
        return;
      }

      if (isSearchMove.current) {
        const filterRecorder = rowRecorderList.current
          .filter((recorder: DashboardFilterResult) => {
            return mapInstance.current
              ?.getBounds()
              ?.contains(
                new google.maps.LatLng(
                  recorder.location.latitude,
                  recorder.location.longitude
                )
              );
          })
          .map((rec) => filteringRecorder(rec));
        dispatch(setDashboardData(filterRecorder));
      } else {
        dispatch(
          setDashboardData(
            rowRecorderList.current.map((rec) => filteringRecorder(rec))
          )
        );
      }
    },
    [dispatch, isSearchMove, mapFilter, rowRecorderList]
  );

  const handleActiveMarker = useCallback(
    (marker: google.maps.Marker) => {
      if (marker === undefined || marker === null) {
        return;
      }

      if (activeMarker === undefined) {
        setActiveMarker(marker);
      } else {
        if (
          marker
            .getPosition()
            ?.equals(activeMarker.getPosition() as google.maps.LatLng)
        ) {
          return;
        } else {
          setActiveMarker(marker);
        }
      }
    },
    [activeMarker]
  );

  const closeActiveMarker = useCallback(() => {
    console.log("closeActiveMarker");
    setActiveMarker(undefined);
    if (mapInstance.current !== undefined) {
      mapInstance.current.getDiv().focus();
    }
  }, []);

  const defaultOnClusterClickHandler: onClusterClickHandler = (
    _: google.maps.MapMouseEvent,
    cluster: Cluster,
    map: google.maps.Map
  ): void => {
    if (cluster.bounds !== undefined) {
      mapInstance.current?.fitBounds(cluster.bounds, 100);
    }
  };
  const initMap = () => {
    mapInstance.current = new google.maps.Map(mapRef.current!, mapOptions);

    if (mapInstance.current !== undefined) {
      if (window.innerWidth > 3000) {
        mapInstance.current.setZoom(6);
      }
    }

    markerCluster.current = new MarkerClusterer({
      map: mapInstance.current,
      algorithm: new SuperClusterAlgorithm({}),
      renderer: circleRenderer,
      onClusterClick: defaultOnClusterClickHandler,
    });

    mapInstance.current.addListener("zoom_changed", () => {
      closeActiveMarker();
      adjustMap();
    });

    mapInstance.current.addListener("bounds_changed", () => {
      setTimeout(adjustMap, 200);
    });

    mapInstance.current.addListener("click", () => closeActiveMarker());

    !isNotMobile &&
      mapInstance.current.addListener("touchstart", () => closeActiveMarker());
  };

  //BUG [RND-349] filter 적용 : filterAlarmVisible
  const recorderGroupByLocation = (recorders: DashboardData[]) => {
    let result = chain(recorders)
      .groupBy((recorder) => `${recorder.latitude}-${recorder.longitude}`)
      .map((value, key) => {
        // mergedSystemId 과 위경도로 그룹화함
        const { latitude, longitude, mergedSystemName } = value[0];

        const systemGrouped = chain(value)
          .groupBy((recorder) => recorder.mergedSystemId)
          .map((group, mergedSystemId) => {
            const summary = group.reduce(
              (acc, recorder) => {
                acc.healthyCount += recorder.status ? 1 : 0;
                acc.system += recorder.system;
                acc.disk += recorder.disk;
                acc.video += recorder.video;
                acc.warningCount.push(...recorder.warning);
                acc.alertCount.push(...recorder.alert);
                acc.assignCount.push(...recorder.assign);
                acc.criticalCount.push(...recorder.critical);
                return acc;
              },
              {
                healthyCount: 0,
                system: 0,
                disk: 0,
                video: 0,
                warningCount: [] as DashboardAlertData[],
                alertCount: [] as DashboardAlertData[],
                assignCount: [] as DashboardAlertData[],
                criticalCount: [] as DashboardAlertData[],
              }
            );
            // console.log(value, group, "group");
            return {
              ...group[0],
              status: summary.healthyCount,
              system: summary.system,
              disk: summary.disk,
              video: summary.video,
              warning: summary.warningCount,
              alert: summary.alertCount,
              assign: summary.assignCount,
              critical: summary.criticalCount,
              recorders: group,
            };
          })
          .value();

        // console.log(
        //   systemGrouped,
        //   "groupedByMergedSystemId"
        // );

        return {
          name: mergedSystemName,
          latitude,
          longitude,
          systems: systemGrouped,
        };
      })
      .value();

    // console.log(result, "results");

    // convert the object into an array of objects
    return result;
  };

  //BUG [RND-305] MouseOve시에 여백에 따라 팝업 위치 변경.
  const calculateInfoPosition = (
    mouseEvent: MouseEvent,
    tempMarker: google.maps.Marker
  ) => {
    const screenX = window.innerWidth;
    const screenY = window.innerHeight;
    const markerX = mouseEvent.clientX;
    const markerY = mouseEvent.clientY;

    const rightPad = markerX;
    const leftPad = screenX - markerX;

    const topPad = markerY;
    const bottomPad = screenY - markerY;

    let pos = new google.maps.Size(0, -25);

    const recorders: DashboardData[] = tempMarker.get("recorder");
    const isMulti =
      recorders !== undefined && recorders.length !== undefined
        ? recorders.length
        : 2;

    if (rightPad > leftPad) {
      if (topPad > bottomPad) {
        pos = new google.maps.Size(-100, -25); //close
      } else {
        if (isMulti <= 1) {
          pos = new google.maps.Size(-125, 200);
        } else {
          const calcHeight = 20 + (isMulti - 2) * 20;
          pos = new google.maps.Size(-125, calcHeight > 200 ? 200 : calcHeight);
        }
      }
    } else {
      if (topPad > bottomPad) {
        pos = new google.maps.Size(100, -25);
      } else {
        if (isMulti <= 1) {
          pos = new google.maps.Size(100, 200);
        } else {
          const calcHeight = 20 + (isMulti - 2) * 20;
          pos = new google.maps.Size(125, calcHeight > 200 ? 200 : calcHeight);
        }
      }
    }
    return pos;
  };

  // 선택한 지도 marker 왼쪽 Alert View 표시 이벤트
  const onClickMarkerAlert = useCallback(
    (tempMarker: CustomMarker) => {
      if (tempMarker.systems !== undefined && tempMarker.systems.length === 1) {
        // console.log(tempMarker.systems[0], "tmepmarker systems");
        let tempSelectedAlert = rowRecorderList.current?.find((row) => {
          if (tempMarker.systems !== undefined)
            return row.recorderId === tempMarker.systems[0].recorderId;
        });

        tempSelectedAlert
          ? setSelectedAlert({
              type: tempSelectedAlert.type,
              recorderId: tempSelectedAlert.recorderId,
              recorderName: tempSelectedAlert.name,
              mergedSystemName: tempSelectedAlert.mergedSystemName,
              mergedSystemId: tempSelectedAlert.mergedSystemId,
              cloudSystemId: tempSelectedAlert.cloudSystemId,
              location: tempSelectedAlert.location.location,
            })
          : setSelectedAlert(null);
        !isNotMobile && closeActiveMarker();
      }
    },
    [rowRecorderList, selectedAlert]
  );

  const refreshMap = () => {
    if (recorderMarker === undefined) {
      return;
    }

    const groupByLocation = recorderGroupByLocation(recorderMarker);

    //TODO Alert의 변경사항이 없는 경우 갱신안하도록 하는것이 Best, 현재는 전체를 새로 그리도록 되어있음.
    // if (markerCluster.current !== undefined) {
    //   markerCluster.current.clearMarkers(false);
    // }
    let deleteMarker: google.maps.Marker[] = [];
    let tempMarkers: google.maps.Marker[] = [];
    // console.log(groupByLocation, "groupByLocation");

    groupByLocation.forEach((data, index) => {
      // console.log("data", data);
      if (data.latitude === undefined || data.longitude === undefined) {
        return;
      }

      let alertCount = 0;
      let criticalCount = 0;

      data.systems.reduce((acc, cur) => {
        alertCount += cur.alert.length;
        criticalCount += cur.critical.length;
        return acc;
      }, {});
      // console.log("systems", data.systems, alertCount, criticalCount);
      const matchMarker = markers.find((marker, index) => {
        const location = marker.getPosition();
        if (
          location &&
          location.equals(new google.maps.LatLng(data.latitude, data.longitude))
        ) {
          return true;
        } else {
          return false;
        }
      });

      // console.log("data", data);
      // console.log("matchMarker", matchMarker);
      if (matchMarker) {
        matchMarker.setIcon({
          url: determineMarker(
            // data.status !== undefined ? data.status : 0,
            // data.warning !== undefined ? data.warning.length : 0,
            // data.assign !== undefined ? data.assign.length : 0,
            alertCount,
            criticalCount
          ),
          scaledSize: new google.maps.Size(25, 25),
          labelOrigin: new google.maps.Point(10, -10),
        });
        matchMarker.setLabel({
          className: "google-map-marker-label",
          text:
            data.systems !== undefined && data.systems.length > 1
              ? `${data.systems.length} SYSTEMS`
              : data.name,
          color: determineLableColor(
            // data.status !== undefined ? data.status : 0,
            // data.warning !== undefined ? data.warning.length : 0,
            // data.assign !== undefined ? data.assign.length : 0,
            alertCount,
            criticalCount
          ),
        });

        matchMarker.set("systems", data.systems);
        matchMarker.set("alertCount", alertCount);
        // matchMarker.set(
        //   "warningCount",
        //   data.warning !== undefined ? data.warning.length : 0
        // );
        // matchMarker.set(
        //   "assignCount",
        //   data.assign !== undefined ? data.assign.length : 0
        // );
        matchMarker.set("criticalCount", criticalCount);
        tempMarkers.push(matchMarker);
      } else {
        const tempMarker: CustomMarker = new google.maps.Marker({
          position: {
            lat: data.latitude,
            lng: data.longitude,
          },
          icon: {
            url: determineMarker(
              // data.status !== undefined ? data.status : 0,
              // data.warning !== undefined ? data.warning.length : 0,
              // data.assign !== undefined ? data.assign.length : 0,
              alertCount,
              criticalCount
            ),
            scaledSize: new google.maps.Size(25, 25),
            labelOrigin: new google.maps.Point(10, -10),
          },
          label: {
            className: "google-map-marker-label",
            text:
              data.systems !== undefined && data.systems.length > 1
                ? `${data.systems.length} SYSTEMS`
                : data.name,
            color: determineLableColor(
              // data.status !== undefined ? data.status : 0,
              // data.warning !== undefined ? data.warning.length : 0,
              // data.assign !== undefined ? data.assign.length : 0,
              alertCount,
              criticalCount
            ),
          },
          // map,
        });
        tempMarker.set("systems", data.systems);
        tempMarker.set("alertCount", alertCount);

        // tempMarker.set(
        //   "warningCount",
        //   data.warning !== undefined ? data.warning.length : 0
        // );
        // tempMarker.set(
        //   "assignCount",
        //   data.assign !== undefined ? data.assign.length : 0
        // );
        tempMarker.set("criticalCount", criticalCount);
        tempMarker.set("id", index);

        // system 여러개일때 (모바일은 클릭 / 웹은 마우스오버)
        tempMarker.addListener(
          isNotMobile ? "mouseover" : "click",
          (e: any) => {
            console.log(77);
            // console.log("mouseover detect="+tempMarker.getLabel()?.toString());

            if (closeInfoWindowWithTimeout.current !== undefined) {
              clearTimeout(closeInfoWindowWithTimeout.current);
              closeActiveMarker();
            }
            const mouseEvent = e.domEvent as MouseEvent;
            const pos = calculateInfoPosition(mouseEvent, tempMarker);
            tempMarker.set("popupPosition", pos);
            tempMarker.set("isPopup", true);
            handleActiveMarker(tempMarker);
          }
        );

        isNotMobile &&
          tempMarker.addListener("click", () => {
            // 1개의 시스템인 marker 를 클릭했을 때 왼쪽 Alert View 보여주기 위함
            onClickMarkerAlert(tempMarker);
          });

        tempMarkers.push(tempMarker);
      }
    });

    if (tempMarkers !== undefined && tempMarkers.length > 0) {
      const compactMarker = compact(tempMarkers);
      markerCluster.current?.clearMarkers();
      markerCluster.current?.addMarkers(compactMarker, false);
    }

    // console.log(tempMarkers, groupByLocation, "tempMarkers");

    const mergedMarker = [...markers, ...tempMarkers];
    const settingMarker = mergedMarker.filter((target) => {
      const foundRecorder = groupByLocation.some((data) =>
        target
          .getPosition()
          ?.equals(new google.maps.LatLng(data.latitude, data.longitude))
      );
      if (!foundRecorder) {
        deleteMarker.push(target);
      }
      return foundRecorder;
    });

    if (deleteMarker !== undefined && deleteMarker.length > 0) {
      markerCluster.current?.removeMarkers(deleteMarker, false);
    }

    setMarkers(settingMarker);
  };

  const smoothZoom2 = (max: number, currentZoom: number) => {
    if (currentZoom >= max) {
      return;
    } else {
      if (mapInstance.current !== undefined) {
        let z = google.maps.event.addListenerOnce(
          mapInstance.current,
          "zoom_changed",
          function (event: any) {
            google.maps.event.removeListener(z);
            smoothZoom2(max, currentZoom + 1);
          }
        );
        setTimeout(function () {
          if (mapInstance.current) {
            mapInstance.current.setZoom(currentZoom);
          }
        }, 80);
      }
    }
  };

  const smoothZoom = (
    max: number,
    cnt: number,
    loc?: google.maps.LatLng,
    bounds?: google.maps.LatLngBounds
  ) => {
    if (mapInstance.current !== undefined) {
      const currentZoom = cnt || (mapInstance.current.getZoom() as number);

      if (currentZoom < max) {
        google.maps.event.addListenerOnce(
          mapInstance.current,
          "zoom_changed",
          function (event: any) {
            // google.maps.event.removeListener(z);
            // console.log("currentZoom=" + currentZoom);
            if (loc !== undefined && mapInstance.current !== undefined) {
              mapInstance.current.setCenter(loc);
            }
            smoothZoom(max, cnt + (max > currentZoom ? 1 : -1), loc);
          }
        );

        setTimeout(function () {
          // console.log("smoothZoom setTimeout=" + currentZoom);
          if (mapInstance.current) {
            mapInstance.current.setZoom(cnt + (max > currentZoom ? 1 : -1));
          }
        }, 200);
      }
    }
  };

  const smoothZoomOut = (min: number, cnt: number) => {
    if (cnt <= min) {
      setTimeout(function () {
        if (mapInstance.current) {
          mapInstance.current.setZoom(min);
        }
      }, 10);
      return;
    } else {
      if (mapInstance.current !== undefined) {
        let z = google.maps.event.addListenerOnce(
          mapInstance.current,
          "idle",
          function (event: any) {
            //google.maps.event.removeListener(z);
            smoothZoomOut(min, cnt - 3);
          }
        );
        // if (mapInstance.current) {
        //   mapInstance.current.setZoom(cnt);
        // };
        setTimeout(function () {
          if (mapInstance.current) {
            mapInstance.current.setZoom(cnt);
          }
        }, 80);
      }
    }
  };

  useEffect(() => {
    initMap();

    return () => {
      dispatch(setDashboardDataInit());
    };
  }, []);

  const onChangeCollapsed = () => {
    setIsCollapsed(!isCollapsed);
  };

  const onZoomPlus = useCallback(() => {
    if (mapInstance.current !== undefined) {
      const currentZoomLevel: number | undefined =
        mapInstance.current.getZoom();
      if (currentZoomLevel !== undefined && currentZoomLevel < 19) {
        mapInstance.current.setZoom(currentZoomLevel + 1);
      }
    }
  }, []);

  const onZoomOut = useCallback(() => {
    if (mapInstance.current !== undefined) {
      const currentZoomLevel: number | undefined =
        mapInstance.current.getZoom();
      if (currentZoomLevel !== undefined && currentZoomLevel > 4) {
        mapInstance.current.setZoom(currentZoomLevel + -1);
      }
    }
  }, []);

  const onZoomFit = useCallback(() => {
    if (mapInstance.current !== undefined) {
      console.log(markers, "markers");
      let bound = new google.maps.LatLngBounds();
      if (markers !== undefined) {
        markers.forEach((marker) => {
          if (marker !== undefined) {
            const loc = marker.getPosition();
            if (loc !== undefined && loc !== null) {
              bound.extend(loc);
            }
          }
        });

        if (markers.length === 0) {
          mapInstance.current.setCenter(mapOptions.center);
          mapInstance.current.setZoom(5);
        } else {
          if (markers.length === 1) {
            //mapInstance.current.setCenter(bound.getCenter());
            //mapInstance.current.setZoom(7);
            //smoothZoom(5, mapInstance.current.getZoom() as number);
            mapInstance.current.fitBounds(bound, 0);
          } else {
            mapInstance.current.fitBounds(bound, 0);
          }
        }
      }
    }
  }, [markers]);

  const onChangeView = useCallback(() => {
    if (mapInstance.current !== undefined) {
      if (isRoadview) {
        mapInstance.current.setMapTypeId(google.maps.MapTypeId.SATELLITE);
      } else {
        mapInstance.current.setMapTypeId(google.maps.MapTypeId.ROADMAP);
      }
      setIsRoadview(!isRoadview);
    }
  }, [isRoadview]);

  const [isViewRecorderList, setIsViewRecorderList] = useState<boolean>(false);
  const [isFetching, setIsFetching] = useState<boolean>(false);

  const onSelectRecorder = (rec: DashboardData) => {
    // console.log(rec, "rec");
    setSelectedAlert({
      type: rec.type,
      recorderId: rec.recorderId,
      recorderName: rec.name,
      mergedSystemName: rec.mergedSystemName,
      mergedSystemId: rec.mergedSystemId,
      cloudSystemId: rec.cloudSystemId,
      location: rec.location,
      // model: rec.model,
    });
    !isNotMobile && closeActiveMarker();
  };

  const onClickRecorderKeyword = (rec: KeywordSearchRecorder) => {
    setSelectedAlert({
      ...rec,
      location: rec.location.location,
    });
  };

  const onCloseRecorderView = () => {
    setSelectedAlert(null);
  };
  const onCloseRecorderList = () => {
    onResetSearchInput();
    setIsViewRecorderList(false);
  };

  useEffect(() => {
    if (!isViewRecorderList) {
      onCloseRecorderView();
    }
  }, [isViewRecorderList]);

  const filteringToPayload = (filter: DashboardFilter, more: More) => {
    let tempFilters: DashboardFilterRequest = { ...filter };
    let tempMores = Object.entries(more)
      .filter((mo) => mo[1] === true)
      .map((r) => r[0]);

    if (
      tempFilters.types &&
      Object.values(tempFilters.types).every((type) => !type)
    ) {
      delete tempFilters["types"];
    }
    if (
      tempFilters.status &&
      Object.values(tempFilters.status).every((status) => !status)
    ) {
      delete tempFilters["status"];
    }
    if (tempFilters.alerts) {
      let tempAlert = Object.values(tempFilters.alerts)
        .map((f) => Object.values(f).every((i) => !i))
        .some((a) => !a);

      if (!tempAlert) {
        delete tempFilters["alerts"];
      }
    }

    // more filter
    tempFilters = Object.fromEntries(
      Object.entries(tempFilters).filter(([key]) => {
        const commonConditions =
          key === "status" ||
          (isCollapsed && key === "alerts") ||
          tempMores.includes(key);

        if (selectedAccount.accountLevel === "EU") {
          return commonConditions || key === "recorders";
        } else {
          return commonConditions || key === "accounts";
        }
      })
    );

    // console.log(tempFilters, "???");
    return tempFilters;
  };

  const postSearchFilter = useQuery(
    ["searchMapFilterResult", selectedAccount, mapFilter, more, isSearchMove],
    () => {
      let tempMapFilter = { ...filteringToPayload(mapFilter.filters, more) };

      let payload: any = {
        keyword: mapFilter.keyword,
        ...tempMapFilter,
      };

      // console.log(payload);
      return postFilterSearchRecorders({
        accountId: selectedAccount.accountId,
        payload: payload,
      });
    },
    {
      retry: 0,
      refetchOnWindowFocus: false,
      // refetchOnMount: false,
      refetchInterval: 10000,
      onSuccess: (res: DashboardFilterAlertResponse) => {
        // console.log("mutationSearchFilter ------", res);
        if (res.error !== 0 || res.result === undefined) {
          setRecorderMarker([]);
        } else {
          rowRecorderList.current = res.result;
          const loadingRecorder: DashboardData[] = res.result
            .map((recorder: DashboardFilterResult) => {
              return filteringRecorder(recorder);
            })
            .filter((recorder: any) => recorder.location !== "");

          setRecorderMarker(loadingRecorder);

          // console.log(res.result, "res result");
          if (initDraw.current) {
            if (mapInstance.current !== undefined) {
              let bound = new google.maps.LatLngBounds();
              if (loadingRecorder !== undefined) {
                loadingRecorder.forEach((marker: any) => {
                  if (marker !== undefined) {
                    const loc = new google.maps.LatLng(
                      marker.latitude,
                      marker.longitude
                    );
                    if (loc !== undefined && loc !== null) {
                      bound.extend(loc);
                    }
                  }
                });

                if (loadingRecorder.length > 0) {
                  if (loadingRecorder.length === 1) {
                    //mapInstance.current.setCenter(bound.getCenter());
                    //mapInstance.current.setZoom(7);
                    mapInstance.current.fitBounds(bound, 10);
                  } else {
                    mapInstance.current.fitBounds(bound, 10);
                  }
                }

                initDraw.current = false;
              }
            }
          }

          if (dualModeRef.current !== undefined) {
            dualModeRef.current.updateDualModeData();
          }

          if (isSearchMove.current) {
            adjustMap();
          } else {
            dispatch(setDashboardData(loadingRecorder));
          }
        }
      },
      onError: (e: any) => {
        if (e.response?.data !== undefined) {
          if (e.response?.data.error === 5001) {
          }
        }
      },
      onSettled: () => {
        setIsFetching(false);
      },
    }
  );

  useEffect(() => {
    refreshMap();
  }, [recorderMarker]);

  const onMouseEnter = () => {
    if (closeInfoWindowWithTimeout.current !== undefined) {
      clearTimeout(closeInfoWindowWithTimeout.current);
    }
  };

  const onMouseLeave = useCallback(() => {
    // console.log("onmouseleave");
    setActiveMarker(undefined);
    // activeMarker && activeMarker.set("isPopup", false);
  }, [activeMarker]);

  const onResetSearchInput = () => {
    dispatch(
      setKeyword({
        type: "",
        keyword: "",
      })
    );
  };

  const onSearch = (keyword: MapFilterKeyword) => {
    dispatch(setKeyword(keyword));
    setIsFetching(true);
    queryClient.invalidateQueries("searchMapFilterResult");
    setIsViewRecorderList(true);
    setSelectedAlert(null);
  };

  const onClickRecorder = (id: string) => {
    // console.log(id, "onClickRecorder");
    if (mapInstance.current) {
      const selectedRecorder = rowRecorderList.current?.find(
        (recorder) => recorder.recorderId === id
      );
      // console.log(selectedRecorder, "selectedRecorder");
      if (selectedRecorder !== undefined) {
        mapInstance.current.setCenter(
          new google.maps.LatLng(
            selectedRecorder.location.latitude,
            selectedRecorder.location.longitude
          )
        );
        smoothZoom(17, mapInstance.current.getZoom() as number);
        //smoothlyAnimatePanTo(mapInstance.current, new google.maps.LatLng(selectedRecorder.location.latitude,selectedRecorder.location.longitude));
      }
    }
  };

  const onChangeSearchMove = useCallback(
    (checked: boolean) => {
      isSearchMove.current = checked;
      adjustMap();
    },
    [adjustMap]
  );

  useEffect(() => {
    adjustMap();
  }, [mapFilter, isSearchMove, rowRecorderList]);

  return (
    <GoogleMapPresenter
      isCollapsed={isCollapsed}
      isRoadview={isRoadview}
      isViewRecorderList={isViewRecorderList}
      onChangeCollapsed={onChangeCollapsed}
      onZoomPlus={onZoomPlus}
      onZoomOut={onZoomOut}
      onZoomFit={onZoomFit}
      onChangeView={onChangeView}
      mapRef={mapRef}
      infoWindowRef={infoWindowRef}
      activeMarker={activeMarker}
      closeActiveMarker={closeActiveMarker}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      onClickRecorder={onClickRecorder}
      onChangeSearchMove={onChangeSearchMove}
      onSearch={onSearch}
      onResetSearchInput={onResetSearchInput}
      onCloseRecorderList={onCloseRecorderList}
      onSelectRecorder={onSelectRecorder}
      onClickRecorderKeyword={onClickRecorderKeyword}
      more={more}
      onSelectMoreFilter={onSelectMoreFilter}
      onDeleteMoreFilter={onDeleteMoreFilter}
      selectedAlert={selectedAlert}
      onCloseRecorderView={onCloseRecorderView}
      isFetching={isFetching}
      onClickMarkerAlert={onClickMarkerAlert}
    />
  );
}
