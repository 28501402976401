import CLOUD_ICON from "assets/icons/cloud.svg";
import { IconBaseProps } from "react-icons";
import { IoMdCloudOutline } from "react-icons/io";
import { useAppSelector } from "redux/hooks";
import { COLORS, LIGHT_THEME_COLORS } from "styles/colors";

function Cloud(props: IconBaseProps): JSX.Element {
  const themeMode = useAppSelector((state) => state.theme.theme);
  const color = COLORS.BUTTONS2;
  // themeMode === "light" ? COLORS.BUTTONS2 : LIGHT_THEME_COLORS.BUTTONS2;
  return <IoMdCloudOutline color={color} size={24} {...props} />;
}

export default Cloud;
