import styled from "styled-components";
import { COLORS } from "styles/colors";
import { FLEX } from "styles/flex";
import FEATHER_SHIELD_ICON from "assets/icons/feather_shield.svg";
import { calcRem } from "styles/theme";

interface VerifyTitleProps {
  title: string;
  desc: string;
  descTitle?: string;
}
function VerifyTitle(props: VerifyTitleProps): JSX.Element {
  return (
    <TitleContainer>
      <BigTitle>
        <img src={FEATHER_SHIELD_ICON} width="18" alt="Feather Shield Icon" />
        {props.title}
      </BigTitle>
      <Desc>
        {props.descTitle && <strong>{props.descTitle}</strong>}
        <span>{props.desc}</span>
      </Desc>
    </TitleContainer>
  );
}

const TitleContainer = styled.section`
  ${FLEX.FlexCenterCenter};
  flex-direction: column;
`;

const BigTitle = styled.h2`
  font-size: ${calcRem(20)};
  font-weight: 500;
  color: ${({ theme }) => theme.COLORS.WHITE};
  ${FLEX.FlexCenterCenter};
  gap: 10px;
`;

const Desc = styled.p`
  color: ${({ theme }) =>
    theme.value === "light" ? COLORS.BLACK : COLORS.BUTTONS1};
  text-align: center;
  font-size: ${calcRem(12)};
  display: flex;
  flex-direction: column;
  gap: 3px;
  padding: 5px 0 8px;
`;

export default VerifyTitle;
