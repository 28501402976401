import { useNavigate } from "react-router-dom";
import * as s from "./MissingStyled";
import MISSING_ICON from "assets/icons/missing.svg";
import CHEVRON_DOWN from "assets/icons/chevron_down_white.svg";
import CHEVRON_UP from "assets/icons/chevron_up_white.svg";
import { useCallback, useEffect, useRef, useState } from "react";
import MissingList from "./MissingList";
import { ProfileAccountInfo } from "api/interfaces/accountInterface.interface";
import {
  BasePagination,
  CustomError,
  SORT_DIRECTION,
} from "api/interfaces/commonInterface.interface";
import { getMissingRecorderList } from "api/recorderAPI";
import { useQuery } from "react-query";
import { useAppSelector } from "redux/hooks";
import { IoChevronDownOutline, IoChevronUp } from "react-icons/io5";
import * as mediaQuery from "components/MediaQuery";
import { useMediaQuery } from "react-responsive";
import { Popover } from "antd";

type Props = {};

export interface recorderDataRow {
  id: string;
  label: string;
}

const Missing = (props: Props): JSX.Element => {
  const navigate = useNavigate();
  const isMobile = useMediaQuery({ maxWidth: mediaQuery.mobileMax });

  const itemRef = useRef<any>(null);
  const [isOpen, setIsOpen] = useState(false);

  const selectedAccount: ProfileAccountInfo = useAppSelector(
    (state) => state.accountSettings
  );
  const [missingRecorder, setMissingRecorder] = useState<recorderDataRow[]>([]);
  const [recorderQueryInfo, setRecorderQueryInfo] = useState<BasePagination>({
    total: 0,
    pageNum: 0,
    pageLimit: 1000,
    keyword: "",
    sortType: "",
    sortDirection: SORT_DIRECTION.ASC,
  });

  const { error, refetch } = useQuery(
    ["missingRecorder", recorderQueryInfo],
    () =>
      getMissingRecorderList({
        payload: recorderQueryInfo,
        accountId: selectedAccount.accountId,
      }),
    {
      retry: 0,
      refetchInterval: 60000,
      onSuccess: (res: any) => {
        if (res.error === 0 && res.result !== undefined) {
          try {
            setMissingRecorder(
              res.result.map((recorder: any, index: number) => {
                return {
                  key: index,
                  id: recorder.recorderId,
                  label: recorder.name,
                };
              })
            );
          } catch (error) {}
        }
      },
      onError: (e: CustomError) => {
        if (e.response?.data !== undefined) {
          if (e.response?.data.error === 5001) {
            setMissingRecorder([]);
          }
        }
      },
    }
  );

  const displayCount = useCallback(() => {
    if (missingRecorder.length > 9) {
      return "9+";
    } else {
      return missingRecorder.length;
    }
  }, [missingRecorder]);

  const isModalClose = () => {
    setIsOpen(false);
  };

  const refreshData = () => {
    refetch();
  };

  return (
    <>
      {( missingRecorder.length > 0 || true) && (
        <s.Container length={missingRecorder.length}>
          <Popover
            content={
              <MissingList
                recorder={missingRecorder}
                isModalClose={isModalClose}
                itemRef={itemRef}
                refreshData={refreshData}
              />
            }
            placement={isMobile ? "topRight" : "bottomRight"}
            trigger={"click"}
            arrow={false}
            open={isOpen}
            onOpenChange={(open: boolean) => {
              setIsOpen(open);
            }}
          >
            <s.StatusItem ref={itemRef}>
              <s.MissingIcon>
                <img src={MISSING_ICON} alt="missing icon" />
              </s.MissingIcon>
              <span>
                {/* {missingAddress.legnth} */}
                {displayCount()}
              </span>
              {isMobile ? "Missing" : "Missing addresses"}
              <button>
                {isOpen ? <IoChevronUp /> : <IoChevronDownOutline />}
              </button>
            </s.StatusItem>
          </Popover>
          {/* {isOpen && (
            <MissingList
              recorder={missingRecorder}
              isModalClose={isModalClose}
              itemRef={itemRef}
              refreshData={refreshData}
            />
          )} */}
        </s.Container>
      )}
    </>
  );
};

export default Missing;
