import * as s from "../SystemSpecStyled";
import { ReactComponent as INTERNETSVG } from "assets/icons/internet.svg";
import { ReactComponent as CLIENTSVG } from "assets/icons/monitor.svg";
import { ReactComponent as DDNSSVG } from "assets/icons/ddns_server.svg";
import { ReactComponent as PORTSVG } from "assets/icons/port.svg";
import { ReactComponent as GATEWAYSVG } from "assets/icons/gateway.svg";
import { VmaxSystemStatus } from "api/interfaces/reportInterface.interface";
import { useLocation } from "react-router-dom";

type Props = {
  vmaxStatus: VmaxSystemStatus | undefined;
};

export default function NetworkGraph(props: Props): JSX.Element {
  const location = useLocation();
  const isMap = location.pathname.includes("map");

  const ddns = props.vmaxStatus?.network.ddns as boolean;
  const gateway = props.vmaxStatus?.network.gateway as boolean;
  const recorder = props.vmaxStatus?.network.recorder as boolean;

  // console.log(props.vmaxStatus);

  return (
    <s.GraphWrapper
      isMap={isMap}
      ddns={ddns}
      gateway={gateway}
      recorder={recorder}
    >
      <div className="connect-box">
        <s.Item isMap={isMap} status={ddns}>
          <DDNSSVG />
          DDNS SERVER
        </s.Item>
        <div className="line network ddns"></div>
        <s.Item className="center" isMap={isMap} status={true}>
          <INTERNETSVG />
          INTERNET
        </s.Item>
        <div className="line network "></div>
        <s.Item isMap={isMap} status={true}>
          <CLIENTSVG />
          CLIENT
        </s.Item>
      </div>

      {/* GraphWrapper - gateway  */}
      <div className="line gateway client">
        <div>
          {/* <span>DDNS SERVER</span>
          <span>mydwddns.net</span> */}
        </div>
        <div>
          <span>
            channel: {props.vmaxStatus?.network.client.channel.connectedSize}/
            {props.vmaxStatus?.network.client.channel.max}
          </span>
          <span>
            connected:{" "}
            {props.vmaxStatus?.network.client.connection.connectedSize}/
            {props.vmaxStatus?.network.client.connection.max}
          </span>
          {/* <span>RTSP: 0/27</span> */}
        </div>
      </div>

      <s.GateWay status={gateway}>
        <GATEWAYSVG />
        <div className="ip-address">
          <span>GATEWAY</span>
          <span>{props.vmaxStatus?.network.gatewayIp}</span>
        </div>
      </s.GateWay>

      {/* GraphWrapper - recorder  */}
      <div className="line gateway port">
        <div>{/* <span>TRANSMIT DATA: 2.8M BPS</span> */}</div>
      </div>

      <s.PortWrapper status={recorder}>
        <div className="camera-info">
          <span className="ip-address">
            NVR {props.vmaxStatus?.network.recorderIp}
          </span>
          {props.vmaxStatus?.system?.temperature !== undefined && (
            <span>
              Temperature: HDD {props.vmaxStatus?.system.temperature}℉
            </span>
          )}
        </div>
        {props.vmaxStatus?.system?.poe !== undefined && (
          <s.PortBox>
            PoE{" "}
            {props.vmaxStatus?.system?.poe
              .reduce((sum, poe) => {
                return sum + poe.watt;
              }, 0)
              .toFixed(2)}{" "}
            watt
            <s.PortList>
              {props.vmaxStatus?.system?.poe.map((port, idx) => {
                return (
                  <s.Port
                    enable={port.enable}
                    online={port.online}
                    key={`vmax-status-${idx}`}
                  >
                    <span>{port.order}</span>
                    <PORTSVG />
                    <span>{port.watt}W</span>
                  </s.Port>
                );
              })}
            </s.PortList>
          </s.PortBox>
        )}
      </s.PortWrapper>
    </s.GraphWrapper>
  );
}
