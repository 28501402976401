import { ForwardedRef, forwardRef, useCallback, useRef, useState } from "react";
import * as s from "styles/ModalCommonStyled";
import SearchInput from "components/atoms/input/search/SearchInput";
import * as DATA from "pages/alert/temp/data";
import ModalSubMenu from "components/blocks/modal/menu/ModalSubMenu";
import { SearchUserList } from "./SearchUserList";
import { SearchUserGroup } from "./SearchUserGroup";
import DebounceSearchInput from "components/atoms/input/search/DebounceSearchInput";
import { SearchUserListAlert } from "./SearchUserListAlert";

export type SearchUserProps = {
  accountId: string;
  placeholder?: string;
  selectedUserId?: string[];
  selectedUserGroupId?: string[];
  selectedUserIdEmail?: {
    userId: string;
    enableEmail: boolean;
    enablePush: boolean;
  }[];
  selectedUserGroupIdEmail?: {
    userGroupId: string;
    enableEmail: boolean;
    enablePush: boolean;
  }[];
  isTab?: boolean;
  onSelectedRowsChangeUser?: (selectedRows: string[]) => void;
  onSelectedRowsChangeEmail?: (selectedRows: string[]) => void;
  onSelectedRowsChangeUserGroup?: (selectedRows: string[]) => void;
  isSupportEmail?: boolean;
  userGroupId?: string;
  userRef?: ForwardedRef<any>;
  groupRef?: ForwardedRef<any>;
};

export const SearchUsers = forwardRef(
  (props: SearchUserProps, ref: ForwardedRef<any>): JSX.Element => {
    const [searchText, setSearchText] = useState<string>("");

    const onClickSearch = (keyword: string) => {
      setSearchText(keyword);
    };

    // step 2 - recipients sub menu
    const recipientsMenus = [
      {
        label: "Add Users",
        value: true,
        placeholder: "Search Users",
      },
      {
        label: "Add User Groups",
        value: false,
        placeholder: "Search Groups",
      },
    ];
    const [currentMenu, setCurrentMenu] = useState(0);
    const onChangeMenu = (index: number) => {
      setCurrentMenu(index);
    };

    const onChangeSearchText = useCallback((value: string) => {
      setSearchText(value);
    }, []);

    //FIXME EDIT시 기존에 체크된 User정보를 테이블에 적용하는 로직 필요.
    return (
      <>
        {props.isTab ? (
          <s.ModalTabSearchWrapper>
            <ModalSubMenu
              menus={recipientsMenus}
              currentMenu={currentMenu}
              onChange={onChangeMenu}
            />
            <div className="search-box">
              <DebounceSearchInput
                placeholder={recipientsMenus[currentMenu].placeholder}
                onClickSearch={onClickSearch}
                onChange={onChangeSearchText}
              />
            </div>
          </s.ModalTabSearchWrapper>
        ) : (
          <DebounceSearchInput
            placeholder={recipientsMenus[currentMenu].placeholder}
            onClickSearch={onClickSearch}
            onChange={onChangeSearchText}
          />
        )}
        <s.ModalTableContainer>
          <div style={{ display: currentMenu === 0 ? "block" : "none" }}>
            { props.isSupportEmail ? <SearchUserListAlert
              accountId={props.accountId}
              keyword={searchText}
              onSelectedRowsChangeUser={props.onSelectedRowsChangeUser}
              onSelectedRowsChangeEmail={props.onSelectedRowsChangeEmail}
              selectedUserId={props.selectedUserId}
              isSupportEmail={props.isSupportEmail}
              userGroupId={props.userGroupId}
              selectedUserIdEmail={props.selectedUserIdEmail}
              ref={props.userRef}
            />:<SearchUserList
              accountId={props.accountId}
              keyword={searchText}
              onSelectedRowsChangeUser={props.onSelectedRowsChangeUser}
              onSelectedRowsChangeEmail={props.onSelectedRowsChangeEmail}
              selectedUserId={props.selectedUserId}
              isSupportEmail={props.isSupportEmail}
              userGroupId={props.userGroupId}
              selectedUserIdEmail={props.selectedUserIdEmail}
              ref={props.userRef}
            /> }
          </div>
          <div style={{ display: currentMenu === 1 ? "block" : "none" }}>
            <SearchUserGroup
              accountId={props.accountId}
              keyword={searchText}
              onSelectedRowsChangeUserGroup={
                props.onSelectedRowsChangeUserGroup
              }
              selectedUserGroupId={props.selectedUserGroupId}
              selectedUserGroupIdEmail={props.selectedUserGroupIdEmail}
              isSupportEmail={props.isSupportEmail}
              ref={props.groupRef}
            />
          </div>
        </s.ModalTableContainer>
      </>
    );
  }
);
