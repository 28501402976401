import { ResponsiveLine, LineProps, Serie } from "@nivo/line";
import { COLORS } from "styles/colors";
import LineChartTooltip from "./LineChartTooltip";
import { useAppSelector } from "redux/hooks";
import React, { useEffect, useState } from "react";
import { BoxLegendSvg } from "@nivo/legends";
import { JSX } from "react/jsx-runtime";
import { getChartColorSchema } from "utils/functions";
import * as mediaQuery from "components/MediaQuery";
import { getAxisBottomValue } from "pages/dashboard/dashboard/WidgetContainer";

export interface dwLineProps extends LineProps {
  fixedMax?: number;
}

const BarLegend = ({ data, height, legends, width }: any) => (
  <React.Fragment>
    {legends.map((legend: any) => (
      <BoxLegendSvg
        {...legend}
        containerHeight={height}
        containerWidth={width}
      ></BoxLegendSvg>
    ))}
  </React.Fragment>
);

export default function LineInterpolationChart(
  props: dwLineProps
): JSX.Element {
  const theme = useAppSelector((state) => state.theme.theme);
  const color = theme === "light" ? COLORS.BLACK : COLORS.WHITE;

  const valuesToShow = props.data[0]?.data.map((v, i, array) =>
    i % 3 === 0 ? v.x : ""
  );

  return (
    <div style={{ width: "95%" }}>
      <mediaQuery.Default>
        <ResponsiveLine
          {...props}
          margin={{ top: 30, right: 50, bottom: 60, left: 60 }}
          xScale={{ type: "point" }}
          yScale={{
            type: "linear",
            min: 0,
            max: props.fixedMax ? props.fixedMax : "auto",
            // stacked: true,
            reverse: false,
          }}
          curve="monotoneX"
          // yFormat=" >-.2f"
          axisTop={null}
          axisRight={null}
          axisBottom={{
            ...props.axisBottom,
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 45,
            legendOffset: 36,
            legendPosition: "middle",
          }}
          axisLeft={{
            ...props.axisLeft,
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: "count",
            legendOffset: -40,
            legendPosition: "middle",
            format: (e) => Math.floor(e) === e && e,
          }}
          colors={{
            scheme: "category10",
          }}
          lineWidth={3}
          tooltip={LineChartTooltip}
          enableGridX={false}
          enablePoints={props.data[0].data?.length <= 1 ? true : false}
          pointSize={10}
          pointColor={{ from: "color" }}
          pointLabelYOffset={-12}
          useMesh={true}
          layers={[
            "grid",
            "markers",
            "axes",
            "areas",
            "crosshair",
            "lines",
            "points",
            "slices",
            "mesh",
            BarLegend,
          ]}
          legends={[
            {
              //data: props.data.map((item:any)=> item),
              data: props.data.map((item: any, index: number) => ({
                color: getChartColorSchema()[index],
                id: item.id,
                label:
                  item.id?.length > 16 ? item.id.slice(0, 15) + "..." : item.id,
              })),
              anchor: "top-right",
              direction: "row",
              justify: false,
              translateX: -100,
              translateY: -30,
              itemsSpacing: 20,
              itemDirection: "left-to-right",
              itemTextColor: color,
              itemWidth: 100,
              itemHeight: 20,
              itemOpacity: 1,
              symbolSize: 12,
              symbolShape: "square",
              symbolBorderColor: "rgba(0, 0, 0, .5)",
              effects: [
                {
                  on: "hover",
                  style: {
                    itemBackground: "rgba(0, 0, 0, .03)",
                  },
                },
              ],
            },
          ]}
          theme={{
            crosshair: {
              line: {
                strokeWidth: 0.5,
                stroke: `${color}`,
              },
            },
            axis: {
              ticks: {
                line: {
                  stroke: `${color}70`,
                },
                text: {
                  // fill: COLORS.BUTTONS1,
                  fill: `${color}70`,
                },
              },
            },
            grid: {
              line: {
                // stroke: COLORS.BORDER,
                stroke: `${color}30`,
                strokeWidth: 0.5,
              },
            },
          }}
        />
      </mediaQuery.Default>
      <mediaQuery.Mobile>
        <ResponsiveLine
          {...props}
          margin={{
            top: props.data.length * 30,
            right: 50,
            bottom: 60,
            left: 20,
          }}
          xScale={{ type: "point" }}
          yScale={{
            type: "linear",
            min: 0,
            max: props.fixedMax ? props.fixedMax : "auto",
            // stacked: true,
            reverse: false,
          }}
          curve="monotoneX"
          // yFormat=" >-.2f"
          axisTop={null}
          axisRight={null}
          axisBottom={{
            ...props.axisBottom,
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 45,
            legendOffset: 36,
            legendPosition: "middle",
            format: (v) => (valuesToShow.find((vts) => vts === v) ? v : ""),
          }}
          axisLeft={{
            ...props.axisLeft,
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: "count",
            legendOffset: -40,
            legendPosition: "middle",
            format: (e) => Math.floor(e) === e && e,
          }}
          colors={{
            scheme: "category10",
          }}
          lineWidth={3}
          tooltip={LineChartTooltip}
          enableGridX={false}
          enablePoints={props.data[0].data?.length <= 1 ? true : false}
          pointSize={10}
          pointColor={{ from: "color" }}
          pointLabelYOffset={-12}
          useMesh={true}
          layers={[
            "grid",
            "markers",
            "axes",
            "areas",
            "crosshair",
            "lines",
            "points",
            "slices",
            "mesh",
            BarLegend,
          ]}
          legends={[
            {
              //data: props.data.map((item:any)=> item),
              data: props.data.map((item: any, index: number) => ({
                color: getChartColorSchema()[index],
                id: item.id,
                label:
                  item.id?.length > 16 ? item.id.slice(0, 15) + "..." : item.id,
              })),
              anchor: "top-right",
              direction: "column",
              justify: false,
              translateX: -30,
              translateY: -25 * props.data.length,
              itemsSpacing: 5,
              itemDirection: "left-to-right",
              itemTextColor: color,
              itemWidth: 100,
              itemHeight: 20,
              itemOpacity: 1,
              symbolSize: 12,
              symbolShape: "square",
              symbolBorderColor: "rgba(0, 0, 0, .5)",
              effects: [
                {
                  on: "hover",
                  style: {
                    itemBackground: "rgba(0, 0, 0, .03)",
                  },
                },
              ],
            },
          ]}
          theme={{
            axis: {
              ticks: {
                line: {
                  stroke: `${color}70`,
                },
                text: {
                  // fill: COLORS.BUTTONS1,
                  fill: `${color}70`,
                },
              },
            },
            grid: {
              line: {
                // stroke: COLORS.BORDER,
                stroke: `${color}30`,
                strokeWidth: 0.5,
              },
            },
          }}
        />
      </mediaQuery.Mobile>
    </div>
  );
}
