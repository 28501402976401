import BigButton from "components/atoms/buttons/BigButton";
import { BigButtonBox, TcWrapper } from "./AboutStyled";
import { Text } from "components/atoms/text/Text";
import { useReactToPrint } from "react-to-print";
import { useRef } from "react";

export default function TermOfCondition(): JSX.Element {
  const componentRef = useRef<any>(null);

  const onClickPrintBtn = useReactToPrint({
    content: () => componentRef.current,
    copyStyles: true,
    documentTitle: "Term Of Service",
  });

  return (
    <>
      <TcWrapper ref={componentRef}>
          <div>
            <Text bold>Products:</Text> The company reserves the right from time
            to time, to add or discontinue any product from those offered for
            sale by the company or to modify the specifications of any product
            without notice to the integrator/distributor.
          </div>
          <div>
            <Text bold>Default:</Text> If the integrator/distributor fails to
            make payment in full for the products within the time period set
            forth above or within the time period expressly agreed upon in
            writing by the parties, such failure to pay on time constitutes a
            material breach of contract by the integrator/distributor permitting
            the company to suspend delivery under any accepted order or other
            contacts between the integrator/distributor and the company. The
            integrator/distributor shall pay to the company an added service
            charge of 1 % per month (or the legal maximum allowed in the
            dealer/distributor’s state) on all delinquent invoices or portion
            thereof until paid. In the event that the company is required to
            take legal action, the integrator/distributor shall pay all
            collection fees and/or attorney’s fees plus any court costs.
          </div>
          <div>
            <Text bold>Guarantor:</Text> The company may from time to time
            require a third-party guarantor to guarantee payment of the
            company’s invoices submitted to the integrator/distributor under the
            terms of an accepted order. The guarantor shall agree to pay
            outstanding invoices within the agreed payment terms for the
            accepted order and in addition a service charge of 1 % per month or
            the legal maximum allowed (in the dealer/distributor’s state) on all
            delinquent invoices or portion thereof until paid. In the event that
            the company is required to take legal action, including the use of a
            collection agency to collect past due amounts, the guarantor shall
            pay all collection fees and/or attorney’s fees plus any court costs.
          </div>
          <div>
            <Text bold>Returns:</Text> Authorizations and instructions for the
            return of any product must be obtained by the integrator/distributor
            before returning any product for any reason. The product must be
            returned with complete identification, freight prepaid and in
            accordance with the company’s most current return policies and
            procedures or it will not be accepted. For a copy of the most
            current returns policies and procedures call Digital Watchdog at
            813-888-9555. All items returned for credit are subject to
            inspection upon receipt and a restocking charge. In no event will
            the company be responsible for any product returned without proper
            authorization or identification. For more information, click{" "}
            <a href="https://digital-watchdog.com/page/rma-landing-page/" target="_blank" rel="noreferrer">
              here
            </a>
            .
          </div>
          <div>
            <Text bold>Installation and Service:</Text> Advice, Assistance, or
            Training: The company assumes no obligation or liability for any
            advice, technical assistance, or training provided by the company to
            the integrator/distributor with respect to products, or for any
            results occurring as a result of the application of such advice,
            technical assistance, or training and the integrator/distributor
            shall have sole responsibility for selection and specification of
            the products appropriate for the end use of such products and for
            the proper installation and servicing of such products.
          </div>
          <div>
            <Text bold>Waiver:</Text> Waiver by the company of any breach of any
            of the terms and conditions shall not be construed as a waiver of
            any other breach, and the failure by the Company to exercise any
            right arising from any default of integrator/distributor hereunder
            shall not be deemed to be a waiver of such right, which may be
            exercised at any subsequent time.
          </div>
          <div>
            <Text bold>Patents:</Text> The company shall indemnify and save
            integrator/distributor harmless from any judgment for damages and/or
            costs which may be rendered against integrator/distributor in any
            suit brought against integrator/distributor on account of the
            infringement of any U.S. patent by any product supplied by the
            company providing that the integrator/distributor promptly notifies
            the company of the commencement of any suit and authorizes the
            company to settle or defend such suit as the company may see fit and
            provided further that integrator/distributor renders every
            reasonable assistance which the company may require in defending
            such suit.
          </div>
          <div>
            <Text bold>Assignment:</Text> Integrator/distributor shall not
            assign its rights or delegate its duties, responsibilities, or
            obligations arising under any accepted order, in whole or in part
            without the prior written consent of the company. Any actual or
            attempted assignment without the company’s prior written consent
            shall entitle the company to cancel such accepted order upon written
            notice to the integrator/distributor.
          </div>
          <div>
            <Text bold>Termination:</Text> The Company may terminate any
            accepted order or part thereof for any reason at the company’s
            convenience upon written notice to the integrator/distributor.
            Integrator/ distributor hereby waives all claims for consequential
            damages resulting from or relating to such termination, including,
            without limitation, any lost products or loss of anticipated profits
            and to accept as its sole remedy for termination the reasonable
            additional costs of obtaining substitute goods of the same quantity
            and quality as the products provided that in no event shall such
            costs exceed the price of the accepted order or part thereof so
            terminated as stated on the company’s acknowledgment. Any claim for
            adjustment not submitted within sixty (60) days from the date of
            such termination shall be deemed to have been waived by the
            integrator/distributor.
          </div>
          <div>
            <Text bold>Governing Law:</Text> These terms and conditions and each
            accepted order shall be governed by the law of the State of Florida
            without regard to Florida principles or rules of conflicts of laws
            that might require the application of the law of another
            jurisdiction.
          </div>
          <div>
            <Text>Warranty:</Text> Kaltech Enterprises DBA Digital Watchdog
            (referred to as “the Warrantor”) warrants products against defects
            in materials or workmanship as{" "}
            <a href="https://digital-watchdog.com/page/rma-landing-page/" target="_blank" rel="noreferrer">
              follows
            </a>
            . To obtain warranty or out of warranty service, please contact a
            Technical Support Representative at 1-866-446-3595 Opt 5 Monday
            through Friday from 9:00 AM to 5:00 PM PST. A purchase receipt or
            other proof of the date of the original purchase is required before
            warranty service is rendered. This warranty only covers failures due
            to defects in materials and workmanship which arise during normal
            use. This warranty does not cover damage which occurs in shipment or
            failures which are caused by products not supplied by the Warrantor
            or failures which result from accident, misuse, abuse, neglect,
            mishandling, misapplication, alteration, modification, faulty
            installation, set-up adjustments, improper antenna, inadequate
            signal pickup, maladjustment of consumer controls, improper
            operation, power line surge, improper voltage supply, lightning
            damage, rental use of the product or service by anyone other than a
            Digital Video Recorder authorized repair facility or damage that is
            attributable to acts of God.
          </div>
          <div>
            <Text bold> Limits and Exclusions:</Text> There are no express
            warranties except as listed above. The Warrantor will not be liable
            for incidental or consequential damages (including, without
            limitation, damage to recording media) resulting from the use of
            these products, or arising out of any breach of the warranty. All
            express and implied warranties, including the warranties of
            merchantability and fitness for a particular purpose, are limited to
            the applicable warranty period set forth above.
          </div>
          <div>
            Some states do not allow the exclusion or limitation of incidental
            or consequential damages or limitations on how long an implied
            warranty lasts, so the above exclusions or limitations may not apply
            to you. This warranty gives you specific legal rights and you may
            also have other rights that vary from state to state. If the problem
            is not handled to your satisfaction, then write to the Address
            above.
          </div>
          <div>
            Service calls that do not involve defective materials or workmanship
            as determined by the Warrantor, in its sole discretion, are not
            covered. Costs of such service calls are the responsibility of the
            purchaser.
          </div>
      </TcWrapper>
      {/* <BigButtonBox>
        <BigButton label={"Print"} add onClickBtn={onClickPrintBtn} />
      </BigButtonBox> */}
    </>
  );
}
