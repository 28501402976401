import SearchInput from "components/atoms/input/search/SearchInput";
import { Text, UnderlineText } from "components/atoms/text/Text";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import * as s from "../FilterStyled";
import styled from "styled-components";
import TinyButton from "components/atoms/buttons/TinyButton";
import { COLORS } from "styles/colors";
import {
  DashboardFilterAccount,
  DashboardFilterAccountAndUser,
  DashboardFilterAccountAndUserForTree,
} from "api/interfaces/dashboardInterface.interface";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import {
  flatten,
  flattenArray,
  matchStringSearchKeyword,
  renameArrayKeys,
} from "utils/functions";
import { getFilterAccountUsers } from "api/dashboardAPI";
import { ProfileAccountInfo } from "api/interfaces/accountInterface.interface";
import { useQuery } from "react-query";
import { useQueryClient } from "react-query";
import { MapFilter, setAccounts } from "redux/reducers/map/mapFilter";
import { Tree, TreeDataNode } from "antd";
import { TreeProps } from "antd/lib";
import { EventDataNode } from "antd/lib/tree";
import * as mediaQuery from "components/MediaQuery";

type Props = {
  onApplyAccount: () => void;
  onChangeFiltering: (name: string, value: boolean) => void;
  onChangeFilterName: (changeFilterName: string) => void;
};
// 키 이름을 변경할 매핑
const keyMapping = {
  id: "key",
  parentId: "parentId",
  name: "title",
  level: "level",
  parentLevel: "parentLevel",
  userGroups: "userGroups",
  isSelected: "isSelected",
  subRows: "children",
};

// accountFilter Type으로 변경
const changeAccountFilter = (item: DashboardFilterAccountAndUser[]) => {
  // console.log(item);
  if (item && item.length > 0) {
    return item.map((select: DashboardFilterAccountAndUser) => {
      return {
        isSelected: select.isSelected ? select.isSelected : true,
        accountId: select.id,
        level: select.level,
        // userGroups: tempUserGroups,
      };
    });
  } else return [];
};
function getRepresentativeNames(
  tree: TreeDataNode[],
  selection: React.Key[]
): string[] {
  // console.log(tree, selection);
  let selectedNames: string[] = [];
  const topLevelNode = tree.find((node) => selection.includes(node.key));
  if (topLevelNode) {
    selectedNames.push(topLevelNode.title as string);
  } else {
    if (tree[0].children) {
      tree[0].children.forEach((node: TreeDataNode) => {
        if (node.children) {
          // 상위 노드가 선택되었는지 확인
          const isParentSelected = node.children.every((child) =>
            selection.includes(child.key)
          );

          if (isParentSelected) {
            selectedNames.push(node.title as string);
          }
          // 상위노드 선택X 하위 선택됐다면
          else {
            node.children.forEach((nodeChild) => {
              if (selection.includes(nodeChild.key))
                selectedNames.push(nodeChild.title as string);
            });
          }
        } else {
          if (selection.includes(node.key))
            selectedNames.push(node.title as string);
        }
      });
    }
  }

  // console.log(selectedNames, "selectedNames");
  return selectedNames;
}

const AccountFilter = (props: Props): JSX.Element => {
  const dispatch = useAppDispatch();
  const queryClient = useQueryClient();
  const selectedAccount: ProfileAccountInfo = useAppSelector(
    (state) => state.accountSettings
  );
  const filter: MapFilter = useAppSelector((state) => state.mapFilter);

  const [checkedKeys, setCheckedKeys] = useState<React.Key[]>([]);
  const [expandedKeys, setExpandedKeys] = useState<React.Key[]>([]);
  const [searchInput, setSearchInput] = useState<string>("");

  const [treeData, setTreeData] = useState<TreeDataNode[]>([]);

  const resultData = useRef<any>();
  const accountList = useRef<
    {
      isSelected: true;
      accountId: string;
      level: string;
    }[]
  >();
  const onChangeSearch = useCallback(
    (e: any) => {
      setSearchInput(e);
      setTreeData(
        renameArrayKeys(filterDashboardTree(resultData.current, e), keyMapping)
      );
      if (resultData.current.length !== checkedKeys.length) {
        setCheckedKeys(
          checkedKeys.filter((item) => item !== selectedAccount.accountId)
        );
      }
    },
    [checkedKeys]
  );

  const onResetFilter = () => {
    setSearchInput("");
    queryClient.invalidateQueries("searchMapFilterResult");
    setCheckedKeys([]);
  };

  const onApply = useCallback(() => {
    props.onApplyAccount();
  }, []);

  const onCheck: TreeProps["onCheck"] = (checkedkeys, info) => {
    setCheckedKeys(checkedkeys as React.Key[]);
  };

  const onExpand = (
    expandedKeys: React.Key[],
    info: {
      node: EventDataNode<TreeDataNode>;
      expanded: boolean;
      nativeEvent: MouseEvent;
    }
  ) => {
    setExpandedKeys(expandedKeys);
  };
  // account list 구하기
  const getAccountUser = useQuery(
    ["getAccountUser", selectedAccount],
    () =>
      getFilterAccountUsers({
        accountId: selectedAccount.accountId,
      }),
    {
      retry: 0,
      staleTime: 60000, // 1분
      refetchOnWindowFocus: false,
      onSuccess: (res: any) => {
        resultData.current = res.result ? [res.result] : [];
        accountList.current = changeAccountFilter(
          flattenArray(resultData.current)
        );
        setTreeData(
          renameArrayKeys(
            filterDashboardTree(resultData.current, searchInput),
            keyMapping
          )
        );
        setExpandedKeys([selectedAccount.accountId]);
      },
      onError: (e: any) => {
        console.log(e, "error");
      },
    }
  );

  useEffect(() => {
    const resultData = getAccountUser.data?.result
      ? [getAccountUser.data?.result]
      : [];

    setTreeData(
      renameArrayKeys(filterDashboardTree(resultData, searchInput), keyMapping)
    );
    setExpandedKeys([selectedAccount.accountId]);
  }, []);

  // 기존 accountList
  // const resultData = getAccountUser.data?.result
  //   ? [getAccountUser.data?.result]
  //   : [];
  // filter 에 저장하기 위한 list
  //const accountList = changeAccountFilter(flattenArray(resultData));

  const titleRendering = (nodeData: any) => {
    return (
      <s.AccountFilterItem level={nodeData.level}>
        {nodeData.title}
      </s.AccountFilterItem>
    );
  };

  useEffect(() => {
    // console.log(checkedKeys, accountList, "checkedKeys ");

    let selectedAccount = accountList.current?.map((account) => {
      if (
        checkedKeys.find((row: React.Key) => {
          return row === account.accountId;
        })
      ) {
        return {
          ...account,
          isSelected: true,
        };
      } else {
        return {
          ...account,
          isSelected: false,
        };
      }
    });

    let isFilter = checkedKeys.length === 0;
    props.onChangeFiltering("accounts", !isFilter);

    if (isFilter) {
      // console.log("111");
      props.onChangeFilterName("Organization");
      dispatch(setAccounts([]));
    } else {
      // console.log("222");
      let tempFilterName: string = "Organization";
      let tempRepresentativeNamesArray = getRepresentativeNames(
        treeData,
        checkedKeys
      );

      tempFilterName =
        tempRepresentativeNamesArray.length > 1
          ? `${tempRepresentativeNamesArray.length} Organizations`
          : tempRepresentativeNamesArray[0];

      props.onChangeFilterName(tempFilterName);
      dispatch(setAccounts(selectedAccount!));
    }
  }, [checkedKeys]);

  const filterDashboardTree = (
    tree: DashboardFilterAccountAndUser[],
    searchString: string
  ): DashboardFilterAccountAndUser[] => {
    const tempAccountTree = tree.reduce((filteredTree, node) => {
      const newNode: DashboardFilterAccountAndUser = {
        ...node,
        subRows: node.subRows
          ? filterDashboardTree(node.subRows, searchString)
          : undefined,
      };

      if (
        matchStringSearchKeyword(newNode.name, searchString) ||
        (newNode.subRows && newNode.subRows.length > 0)
      ) {
        filteredTree.push(newNode);
      }

      return filteredTree;
    }, [] as DashboardFilterAccountAndUser[]);

    return tempAccountTree;
  };
  // const treeData: TreeDataNode[] = useMemo(() => {
  //   console.log("TreeDataNode");
  //   return renameArrayKeys(
  //     filterDashboardTree(resultData, searchInput),
  //     keyMapping
  //   );
  // }, [searchInput, resultData, selectedAccount]);

  useEffect(() => {
    setSearchInput("");
    setCheckedKeys([]);
  }, [filter.keyword]);

  return (
    <Wrapper>
      <s.Container>
        <s.Title>
          <Text fontSize={14} bold>
            Organizations
          </Text>
          <SearchInput
            map
            onClickSearch={onChangeSearch}
            keyword={searchInput}
          />
        </s.Title>
        <s.AccountContents>
          <Tree
            //key={Math.random()}
            height={280}
            checkable
            defaultExpandAll
            autoExpandParent={true}
            selectable={false}
            onCheck={onCheck}
            onExpand={onExpand}
            checkedKeys={checkedKeys}
            expandedKeys={expandedKeys}
            titleRender={titleRendering}
            treeData={treeData}
            // treeData={initialAccountList}
          />
        </s.AccountContents>
        <s.Reset>
          <UnderlineText
            color={COLORS.LIGHTGRAY}
            fontSize={12}
            onClick={onResetFilter}
          >
            Clear
          </UnderlineText>
          <TinyButton label="Done" onClickBtn={onApply} />
        </s.Reset>
      </s.Container>
    </Wrapper>
  );
};

export default AccountFilter;

const Wrapper = styled.div`
  position: relative;

  ${mediaQuery.isDefault} {
    min-width: 280px;
    max-width: 280px;
  }
`;
