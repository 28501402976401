import styled from "styled-components";
import { FLEX } from "../../../styles/flex";
import * as mediaQuery from "components/MediaQuery";

export const StatusContainer = styled.ul`
  ${FLEX.FlexStartCenter};
  gap: 14px;

  ${mediaQuery.isDefault} {
    align-items: baseline;
  }

  ${mediaQuery.isMobile} {
    padding: 6px 0;
  }
`;

export const StatusItem = styled.li`
  ${FLEX.FlexStartCenter};
  align-items: baseline;
  /* flex: 1; */
  gap: 6px;
`;

export const Label = styled.label`
  ${FLEX.FlexStartCenter};
  gap: 6px;
`;
export const StatusImage = styled.img`
  width: 20px;
`;
export const BubbleContainer = styled.div`
  position: absolute;
  z-index: 299;

  top: 62px;
  left: 50%;
  transform: translate(-50%, 0);
`;
export const BubbleWarningContents = styled.p`
  ${FLEX.FlexStartStart};
  flex-direction: column;
  strong {
    padding-bottom: 4px;
  }
`;
export const WarningItem = styled.span`
  ${FLEX.FlexStartCenter};
  gap: 5px;
  img {
    width: 13px;
  }
  padding: 4px 0;
`;
