import styled from "styled-components";
import { CheckInput } from "styles/ModalCommonStyled";
import { FLEX } from "styles/flex";
import { calcRem } from "styles/theme";

export const Row = styled.div`
  ${FLEX.FlexStartCenter};
  gap: 15px;
`;
export const NameLabel = styled(Row)`
  /* gap: 7px; */
  cursor: pointer;
`;

export const ButtonWrapper = styled(Row)<{ disabled?: boolean }>`
  ${({ disabled }) =>
    disabled &&
    `
    opacity: 0.25;
  `}
`;

export const Color = styled.p<{ color: string }>`
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: ${({ color }) => color};
`;

export const NumberInput = styled.div`
  max-width: 80px;
`;

export const MessageInput = styled(CheckInput)`
  color: ${({ theme }) => theme.COLORS.WHITE};
`;
