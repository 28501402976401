import { Table } from "components/atoms/table/Table";
import { SortOrder, TableColumn } from "react-data-table-component";
import { TableTemplate } from "components/templates/default/table/TableTemplate";
import AddModal from "./add";
import ViewModal from "./view";
import ModalSubMenu, { Menus } from "components/blocks/modal/menu/ModalSubMenu";
import { AddEmail, Email } from "api/interfaces/emailInterface.interface";
import { useLocation, useNavigate } from "react-router-dom";
import EmailRecipient from "./recipients/EmailRecipient";
import { BasePagination } from "api/interfaces/commonInterface.interface";
import Status, { StatusType } from "components/blocks/status/Status";
import { ClickSearch } from "components/atoms/input/search/SearchInput";

export type Props = {
  isAddModal: boolean;
  isEditModal: boolean;
  isViewModal: boolean;
  onModal: (type?: string) => void;
  columns: TableColumn<Email>[];
  scheduledColumns: TableColumn<Email>[];
  data: Array<Email>;
  currentMenu: number;
  emailMenus: Array<Menus>;
  selectedEmail: Email | undefined;
  onChangeCurrentMenu: (index: number) => void;
  onClickSendEmail: (email: AddEmail, emailId?: string) => void;
  onRowClicked: (row: Email) => void;
  onChangePage: (page: number, totalRows: number) => void;
  handleSort: (
    selectedColumn: TableColumn<any>,
    sortDirection: SortOrder
  ) => void;
  paginationInfo: BasePagination;
  status: StatusType;
  onClickSearch: ClickSearch;
  tablePending : boolean;
};

export function EmailPresenter({
  isAddModal,
  isEditModal,
  isViewModal,
  onModal,
  columns,
  scheduledColumns,
  data,
  emailMenus,
  currentMenu,
  onChangeCurrentMenu,
  onClickSendEmail,
  selectedEmail,
  onRowClicked,
  handleSort,
  onChangePage,
  paginationInfo,
  status,
  onClickSearch,
  tablePending
}: Props): JSX.Element {
  const navigate = useNavigate();
  const location = useLocation();
  const isRecipient = location.pathname.includes("/recipients");

  const titleComponent = isRecipient ? (
    [
      {
        label: "Sent Emails",
        onClick: () => navigate(-1),
      },
      {
        label: selectedEmail?.subject,
      },
    ]
  ) : (
    <ModalSubMenu
      menus={emailMenus}
      currentMenu={currentMenu}
      onChange={onChangeCurrentMenu}
      subTitle={true}
    />
  );

  return (
    <>
      {!isRecipient ? (
        <TableTemplate
          onClickBtn={() => onModal("add")}
          buttonLabel="New Email"
          detail={isRecipient}
          onClickSearch={onClickSearch}
          keyword={paginationInfo.keyword}
          titleComponent={titleComponent}
          centerChildren={<Status status={status} />}
        >
          {currentMenu === 0 && (
            <Table
              columns={columns}
              data={data}
              onRowClicked={onRowClicked}
              paginationPerPage={paginationInfo.pageLimit}
              paginationTotalRows={status.totalValue}
              onChangePage={onChangePage}
              handleSort={handleSort}
              defaultSortFieldId={"sendTime"}
              progressPending={tablePending}
            />
          )}

          {currentMenu === 1 && (
            <Table
              columns={scheduledColumns}
              data={data}
              onRowClicked={onRowClicked}
              paginationPerPage={paginationInfo.pageLimit}
              paginationTotalRows={status.totalValue}
              onChangePage={onChangePage}
              handleSort={handleSort}
              defaultSortFieldId={"scheduleTime"}
              progressPending={tablePending}
            />
          )}
        </TableTemplate>
      ) : (
        <EmailRecipient titleComponent={titleComponent} />
      )}
      {isAddModal && (
        <AddModal
          onModal={() => onModal("add")}
          onClickSendEmail={onClickSendEmail}
        />
      )}
      {isEditModal && selectedEmail !== undefined && (
        <AddModal
          isEdit
          onModal={() => onModal("edit")}
          onClickSendEmail={onClickSendEmail}
          selectedEmail={selectedEmail}
        />
      )}
      {isViewModal && selectedEmail !== undefined && (
        <ViewModal
          onModal={() => onModal("view")}
          selectedEmail={selectedEmail}
        />
      )}
    </>
  );
}
