import styled from "styled-components";
import { COLORS, LEVELS } from "styles/colors";
import { FLEX } from "styles/flex";
import * as mediaQuery from "components/MediaQuery";
import { calcRem } from "styles/theme";
import { InnerPX } from "components/atoms/inner/InnerContainer";
import { HEADER_HEIGHT } from "../HeaderNavBarStyled";

const collapsedSidebarWidth = 250;
const discollapsedSidebarWidth = 100;

export const PopupWrapper = styled.ul`
  position: absolute;
  right: 0;
  z-index: 995;
  background-color: ${COLORS.WHITE};
  border: 1px solid #abb5c5;
  border-radius: 5px;
  padding: 7px 14px;

  ${mediaQuery.isDefault} {
    right: ${InnerPX}px;
    top: ${HEADER_HEIGHT - 5}px;
  }

  ${mediaQuery.isMobile} {
    width: 100%;
    margin-top: 10px;
  }
`;

export const PopupMenu = styled.li`
  font-size: ${calcRem(14)};
  font-weight: 400;
  padding: 8px 0;
  padding-right: 8px;
  ${FLEX.FlexStartCenter};
  gap: 8px;
  cursor: pointer;
  white-space: nowrap;
`;

export const AccountId = styled(PopupMenu)`
  color: ${COLORS.BORDER};
  font-size: ${calcRem(12)};
  ${FLEX.FlexBetweenCenter};
  gap: 5px;
  padding: 4px 0 8px;
  cursor: unset;

  span:nth-child(2) {
    font-weight: 500;
    background-color: ${COLORS.LIGHTGRAY}40;
    padding: 4px;
    ${FLEX.FlexStartCenter};

    button {
      padding: 0;
      ${FLEX.FlexCenterCenter};
    }
  }
`;

export const Line = styled.div`
  width: 100%;
  border-bottom: 1px solid ${COLORS.BUTTONS1};
  margin: 6px 0;
`;
