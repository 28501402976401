import * as s from "./ButtonStyled";
import PlusCircleIcon from "assets/icons/plus_in_circle.svg";
import MapIcon from "assets/icons/map.svg";

type TinyButtonProps = {
  buttonType?: string;
  add?: boolean;
  map?: boolean;
  label: string;
  icon?: any;
  onClickBtn: () => void;
  disabled?: boolean;
};

function TinyButton({
  add,
  map,
  label,
  icon,
  onClickBtn,
  buttonType,
  disabled,
}: TinyButtonProps): JSX.Element {
  return (
    <s.TinyButton
      buttonType={buttonType ? buttonType : "primary"}
      onClick={onClickBtn}
      disabled={disabled}
    >
      <span className="tiny-button-icon">{icon && icon}</span>
      {add && <img alt="add button" src={PlusCircleIcon} />}
      {map && <img alt="map button" src={MapIcon} />}
      <span>{label}</span>
    </s.TinyButton>
  );
}

export default TinyButton;
