import { ProfileAccountInfo } from "api/interfaces/accountInterface.interface";
import { useCallback, useEffect, useRef, useState } from "react";
import { useAppSelector } from "redux/hooks";
import {
  GetVideoShareListResponse,
  VideoBasePagination,
  VideoShareResult,
} from "api/interfaces/videoshareInterface.interface";
import { useQuery } from "react-query";
import { getVideoShareAllList, getVideoShareHashTag } from "api/videoshareAPI";
import { VideoShareFilter } from "api/interfaces/videoshareInterface.interface";
import {
  CustomError,
  SORT_DIRECTION,
} from "api/interfaces/commonInterface.interface";
import { getStorageUsage } from "api/storageAPI";
import { StorageProps } from "components/blocks/storage/StorageLabel";
import { Location, useLocation, useNavigate } from "react-router-dom";
import VideoSharePresenter from "./VideoSharePresenter";
import { useIntl } from "react-intl";
import { notify } from "components/atoms/notification/Notification";
import { isNull } from "lodash";
import { useInfiniteQuery } from "react-query";

export default function VideoShareContainer(): JSX.Element {
  const intl = useIntl();
  const location: Location = useLocation();
  const navigate = useNavigate();

  const selectedAccount: ProfileAccountInfo = useAppSelector(
    (state) => state.accountSettings
  );
  const [isStatusFilter, setIsStatusFilter] = useState<VideoShareFilter>({
    Open: true,
    Ongoing: true,
    Closed: true,
  });

  const [storageUsage, setStorageUsage] = useState<StorageProps>({
    totalStorage: 0,
    usedStorage: 0,
  });

  const [tagFilters, setTagFilters] = useState<string[]>([]);

  const [selectedTag, setSelectedTag] = useState<string[]>([]);

  const [isSharePopup, setIsSharePopup] = useState<string>("");

  const [videoShareQueryInfo, setVideoShareQueryInfo] =
    useState<VideoBasePagination>({
      total: 0,
      pageNum: 0,
      // pageLimit: 5,
      pageLimit: 30,
      keyword: "",
      sortType: "date",
      sortDirection: SORT_DIRECTION.DESC,
      tags: null,
    });

  const joinTags = (tags: string[]) => {
    return (tags || []).join(", ");
  };

  const onChangeStatusFilter = (value: string) => {
    setIsStatusFilter((statFilter) => {
      return {
        ...statFilter,
        [value]: !statFilter[value],
      };
    });
  };

  const hashtagQuery = useQuery(
    ["videoShareHashTag", videoShareQueryInfo.keyword, selectedAccount],
    () =>
      getVideoShareHashTag({
        accountId: selectedAccount.accountId,
        search: videoShareQueryInfo.keyword as string,
      }),
    {
      retry: 0,
      refetchOnWindowFocus: false,
      onSuccess: (res: any) => {
        if (res.error === 0 && res.result !== undefined) {
          setSelectedTag([]);
          setTagFilters(
            res.result.filter(
              (r: string) => r !== "" && r !== undefined && r !== " "
            )
          );
          const changeParam = new URLSearchParams(location.search);
          if (!isNull(changeParam.get("tags"))) {
            setSelectedTag((changeParam.get("tags") as string).split(","));
          }
        }
      },
      onError: (e: any) => {
        console.log(e, "error");
      },
    }
  );

  const storageQuery = useQuery(
    ["storageStatus"],
    () =>
      getStorageUsage({
        accountId: selectedAccount.accountId,
      }),
    {
      retry: 0,
      refetchOnWindowFocus: false,
      onSuccess: (res: any) => {
        // console.log(res);
        if (res.error === 0 && res.result !== undefined) {
          setStorageUsage({
            totalStorage: res.result.totalStorage,
            usedStorage: res.result.usedStorage,
          });
        }
      },
      onError: (e: any) => {
        console.log(e, "error");
      },
    }
  );

  const fetchVideos = async ({ pageParam = 0 }: { pageParam?: number }) => {
    const response = await getVideoShareAllList({
      accountId: selectedAccount.accountId,
      payload: {
        ...videoShareQueryInfo,
        pageNum: pageParam,
      },
    });
    return response;
  };

  const {
    data,
    refetch,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    status,
    error,
  } = useInfiniteQuery(
    ["videoShare", videoShareQueryInfo, selectedAccount],
    fetchVideos,
    {
      getNextPageParam: (lastPage: GetVideoShareListResponse) => {
        // Assuming `lastPage.page.total` gives you the total count of items
        const { total, pageNum, pageLimit } = lastPage.page;
        // console.log(lastPage, total, "lastPage");
        const nextPage = pageNum + 1;
        return (pageNum + 1) * pageLimit < total ? nextPage : undefined;
      },
      retry: 0,
      refetchInterval: 10000,
      // totalSize 변환 없으면 refetch 하지말기
      refetchOnWindowFocus: false,
      onSuccess: (res: GetVideoShareListResponse) => {},

      onError: (e: CustomError) => {
        if (e.response?.data.error !== 5001) {
          notify(
            "error",
            intl.formatMessage({
              id: "label.videoshare.notify.search.error",
              defaultMessage: "Video share search error.",
            })
          );
        }
        // setVideoList([]);
        // setVideoTotal(0);
      },
    }
  );

  const videoList = data
    ? data.pages.flatMap((page: GetVideoShareListResponse) => page.result)
    : [];
  const videoTotal = data?.pages[0]?.page?.total || 0;

  //BUG
  const onClickSearch = useCallback(
    (keyword: string) => {
      if (videoShareQueryInfo.keyword !== keyword) {
        setVideoShareQueryInfo((info: VideoBasePagination) => {
          return {
            ...info,
            keyword: keyword,
          };
        });
      }
    },
    [videoShareQueryInfo]
  );

  const onClickTag = useCallback(
    (item: string) => {
      if (
        item === "All" ||
        (selectedTag.includes(item) && selectedTag.length === 1)
      ) {
        setSelectedTag([]);
        return;
      }

      if (selectedTag.includes(item)) {
        setSelectedTag((orders) =>
          selectedTag.filter((order) => order !== item)
        );
        return;
      }

      if (selectedTag.includes("All")) {
        setSelectedTag((orders) =>
          selectedTag.filter((order) => order !== "All")
        );
      }

      setSelectedTag((orders) => [...orders, item]);
    },
    [selectedTag]
  );

  const onClickShare = useCallback((videoId: string, isOpen: boolean) => {
    setIsSharePopup(isOpen ? videoId : "");
  }, []);

  const handleScroll = () => {
    if (
      window.innerHeight + window.scrollY >= document.body.offsetHeight &&
      hasNextPage &&
      !isFetchingNextPage
    ) {
      fetchNextPage();
    }
  };

  useEffect(() => {
    setVideoShareQueryInfo((info: VideoBasePagination) => {
      return {
        ...info,
        tags: joinTags(selectedTag),
      };
    });
  }, [selectedTag]);

  useEffect(() => {
    const container = document.getElementById("inner-container");
    if (container) {
      container.addEventListener("scroll", handleScroll);
      return () => container.removeEventListener("scroll", handleScroll);
    }
  }, [hasNextPage, isFetchingNextPage]);

  useEffect(() => {
    fetchNextPage();
  }, [fetchNextPage]);

  return (
    <VideoSharePresenter
      videoList={videoList}
      videoTotal={videoTotal}
      isStatusFilter={isStatusFilter}
      onChangeStatusFilter={onChangeStatusFilter}
      tagFilters={tagFilters}
      selectedTag={selectedTag}
      listQueryInfo={videoShareQueryInfo}
      onClickSearch={onClickSearch}
      onClickTag={onClickTag}
      isSharePopup={isSharePopup}
      onClickShare={onClickShare}
      storageUsage={storageUsage}
      selectedAccount={selectedAccount}
      status={status}
    />
  );
}
