import React from "react";
import { Table } from "antd";
import type { TableColumnsType, TablePaginationConfig, TableProps } from "antd";
import {
  FilterValue,
  RefTable,
  SortOrder,
  SorterResult,
} from "antd/es/table/interface";
import styled from "styled-components";
import { calcRem } from "styles/theme";
import PaginationComponent from "components/blocks/pagination";
import {
  BasePagination,
  SORT_DIRECTION,
} from "api/interfaces/commonInterface.interface";
import { PaginationChangePage } from "react-data-table-component/dist/src/DataTable/types";
import AntdPagination from "components/blocks/pagination/AntdPagination";
import { COLORS, LIGHT_THEME_COLORS } from "styles/colors";
import NoData from "components/templates/nopage/NoData";

type PaginationProps = {
  queryInfo: BasePagination;
  onChangePage: PaginationChangePage;
};

export const changeSortOrderString = (order: SortOrder) => {
  switch (order) {
    case "ascend":
      return SORT_DIRECTION.ASC;
    case "descend":
      return SORT_DIRECTION.DESC;
    default:
      return SORT_DIRECTION.ASC;
  }
};

export const changeSortOrderString2 = (order: SORT_DIRECTION) => {
  switch (order) {
    case SORT_DIRECTION.ASC:
      return "ascend";
    case SORT_DIRECTION.DESC:
      return "descend";
    default:
      return "ascend";
  }
};

export const DashboardCardDetailTable = (
  props: TableProps<any>
): JSX.Element => (
  <DashboardCardDetailTableWrapper>
    <Table
      pagination={false}
      bordered={false}
      locale={{
        emptyText: "",
      }}
      {...props}
    />
  </DashboardCardDetailTableWrapper>
);

export const DashboardChartDetailTable = (
  props: TableProps<any> & PaginationProps
): JSX.Element => {
  return (
    <DashboardChartTableWrapper>
      <div className="table-wrapper">
        <Table
          showSorterTooltip
          bordered={false}
          pagination={false}
          sticky={true}
          {...(props as TableProps<any>)}
        />
      </div>
      {props.dataSource?.length !== 0 && (
        <div className="pagination-wrapper">
          <AntdPagination
            rowsPerPage={props.queryInfo.pageLimit}
            rowCount={props.queryInfo.total}
            onChangePage={props.onChangePage}
            currentPage={props.queryInfo.pageNum}
            onChangeRowsPerPage={() => {
              return false;
            }}
          />
        </div>
      )}
    </DashboardChartTableWrapper>
  );
};

const Wrapper = styled.div`
  width: 100%;
  height: 100%;

  .ant-table-wrapper {
    width: 100%;
    height: 100%;
  }
  .ant-table {
    font-size: ${calcRem(13.5)};
    color: ${({ theme }) => theme.COLORS.WHITE};
  }
  .ant-empty-normal {
    margin-block: 0;
  }

  .ant-table-filter-dropdown {
    .ant-dropdown-menu {
      padding: 0;
    }
    .ant-dropdown-menu-item {
      padding: 4px;
    }
  }

  .ant-dropdown-menu-title-content {
    color: ${COLORS.BLACK} !important;
  }

  .ant-table-filter-trigger,
  .ant-table-column-sorter {
    color: unset !important;
  }

  .ant-table-filter-trigger.active {
    color: ${({ theme }) => theme.COLORS.SELECTED} !important;
  }
`;

const DashboardCardDetailTableWrapper = styled(Wrapper)`
  .ant-table-row,
  .ant-table-cell,
  td {
    border: none !important;
  }

  .ant-table-cell {
    padding: 2px 0 !important;
  }

  tbody {
    border-bottom: 1px solid #7883a1;
  }
`;

const DashboardChartTableWrapper = styled(Wrapper)`
  /* border: 1px solid red; */
  display: flex;
  flex-direction: column;

  .table-wrapper {
    flex: 1 1 auto;
  }

  .pagination-wrapper {
    flex: 0 0 auto;
  }

  .ant-table-thead th {
    padding: 4px 16px !important;
    background-color: ${({ theme }) =>
      theme.value === "light"
        ? LIGHT_THEME_COLORS.LIGHTGRAY
        : COLORS.PRIMARY3} !important;

    .ant-table-column-title {
      font-weight: 500 !important;
    }
  }
  .ant-table-cell {
    padding: 6px 16px !important;
  }
  tbody {
    tr {
      &:last-child {
        > td {
          border-bottom: none;
        }
      }
    }
  }

  .no-data {
    margin: 40px 0;
  }
`;
