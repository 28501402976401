import React, { Fragment, useState } from "react";
import {
  FilterFn,
  useReactTable,
  getCoreRowModel,
  getPaginationRowModel,
  getGroupedRowModel,
  getExpandedRowModel,
  ColumnDef,
  flexRender,
} from "@tanstack/react-table";
import { rankItem, rankings } from "@tanstack/match-sorter-utils";
import * as s from "../BillingPaymentStyled";
import { UnderlineText } from "components/atoms/text/Text";
import { COLORS } from "styles/colors";
import {
  BillingAccount,
  BillingLicenseByAccount,
} from "api/interfaces/licenseInterface.interface";

export interface Props {
  data: BillingAccount;
  onChangeSort: (type: number) => void;
  onDetail: (accountId: string) => void;
}

const RankItemOptions = {
  threshold: rankings.CONTAINS,
};
const fuzzyFilter: FilterFn<any> = (row, columnId, value, addMeta) => {
  // Rank the item

  const itemRank = rankItem(row.getValue(columnId), value, RankItemOptions);

  // Store the itemRank info
  addMeta({
    itemRank,
  });

  // Return if the item should be filtered in/out
  return itemRank.passed;
};

export default function PaymentAccountTable(props: Props): JSX.Element {
  const columns = React.useMemo<ColumnDef<BillingLicenseByAccount>[]>(
    () => [
      {
        accessorKey: "partNumber",
        header: "PART NUMBER",
        cell: ({ getValue }) => getValue(),
      },
      {
        accessorKey: "description",
        header: "DESCRIPTION",
        cell: ({ getValue }) => getValue(),
      },
      {
        accessorKey: "qty",
        header: "QTY",
        cell: ({ getValue }) => getValue(),
      },
      {
        accessorKey: "status",
        header: "STATUS",
        cell: ({ row }) => {
          if (row.index === 0) {
            return (
              <s.StatusCell>
                <li>{row.getValue("status")}</li>
                <li>
                  <UnderlineText
                    color={COLORS.SELECTED}
                    fontSize={14}
                    onClick={() => props.onDetail(props.data.accountId)}
                  >
                    Details
                  </UnderlineText>
                </li>
              </s.StatusCell>
            );
          }
        },
      },
    ],
    []
  );

  const table = useReactTable({
    data: props.data.licenses,
    columns,
    filterFns: {
      fuzzy: fuzzyFilter,
    },
    getGroupedRowModel: getGroupedRowModel(),
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
    // debugTable: true,
  });

  const rows = table.getRowModel().rows;
  return (
    <s.TableTitleBox>
      <s.TableTitle>
        {props.data.companyLogo !== undefined && (
          <img src={props.data.companyLogo} alt="company logo" />
        )}
        <span className="bold">{props.data.name}</span>
        {props.data.accountId !== undefined &&
          props.data.accountId !== null &&
          props.data.accountId !== "" && (
            <li>
              <UnderlineText
                color={COLORS.SELECTED}
                fontSize={14}
                onClick={() => props.onDetail(props.data.accountId)}
              >
                Details
              </UnderlineText>
            </li>
          )}
      </s.TableTitle>
      <s.TableWrapper>
        <table>
          <thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <th key={header.id} style={{ width: header.getSize() }}>
                    {header.isPlaceholder
                      ? null
                      : flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody>
            {rows.map((row) => {
              // console.log(row);
              return (
                <s.TBodyTR key={row.id}>
                  {row.getVisibleCells().map((cell) => {
                    return (
                      // Otherwise, just render the regular cell

                      <td key={cell.id}>
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext()
                        )}
                      </td>
                    );
                  })}
                </s.TBodyTR>
              );
            })}
          </tbody>
        </table>
      </s.TableWrapper>
    </s.TableTitleBox>
  );
}
