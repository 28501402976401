import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import type { MenuProps } from "api/interfaces/menuInterface.interface";
import { dwMenuData } from "utils/MenuUtil";

const initialState: MenuProps = {
  menuData: [
    {
      name: "Dashboard",
      routeName: "/dashbaord",
      svg: null,
      isRole: null,
      children: [],
    },
  ],
};

/**
 * SideBar의 메뉴를 클릭 시 선택된 메뉴를 전역으로 저장하는 Redux
 * 저장된 메뉴ITem는 상단의 메뉴바를 바꿔주는데 사용함.
 * Default는 상단의 DashBoard Item
 */
export const menuSlice = createSlice({
  name: "menuSelect",
  initialState,
  reducers: {
    setMenu(MenuProps, action: PayloadAction<string>) {
      let selectMenu = dwMenuData.filter(
        (menu) => menu.name === action.payload
      );
      MenuProps.menuData = selectMenu;
    },
  },
});

export const { setMenu } = menuSlice.actions;

export default menuSlice;
