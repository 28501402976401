import WARNING_ORANGE_ICON from "assets/icons/triangle_warning_orange.svg";
import WARNING_RED_ICON from "assets/icons/triangle_warning_red.svg";
import * as s from "../BillingStyled";

type Props = {
  suspendDays: number;
};

export default function SuspendLabel({ suspendDays }: Props): JSX.Element {
  const isCritical = suspendDays >= 30;

  return (
    <s.SuspendLabel isCritical={isCritical}>
      <img src={isCritical ? WARNING_RED_ICON : WARNING_ORANGE_ICON} />
      {suspendDays} Days
    </s.SuspendLabel>
  );
}
