import { InstallReportTable, ReportTable, Table } from "components/atoms/table/Table";
import { SortOrder, TableColumn } from "react-data-table-component";
import { TableTemplate } from "components/templates/default/table/TableTemplate";
import {
  ReportInventoryCameraRow,
  ReportInventoryRecorderRow,
} from "api/interfaces/reportInterface.interface";
import * as s from "./InventoryStyled";
import ModalSubMenu from "components/blocks/modal/menu/ModalSubMenu";
import BigButton from "components/atoms/buttons/BigButton";
import EXPORT_ICON from "assets/icons/export.svg";
import SEND_EMAIL_ICON from "assets/icons/send.svg";
import { useNavigate } from "react-router-dom";
import * as mediaQuery from "components/MediaQuery";
import {
  StatusLabel,
  StatusName,
  StatusValue,
} from "components/blocks/status/Status";
import { TotalCountInterface } from "./InventoryContainer";
import { ClickSearch } from "components/atoms/input/search/SearchInput";
import ExportMail from "./export/ExportMail";
import { ProfileAccountInfo } from "api/interfaces/accountInterface.interface";
import { menuNavigation } from "utils/MenuUtil";
import { BasePagination } from "api/interfaces/commonInterface.interface";

type Props = {
  title: string | undefined;
  cameraData: ReportInventoryCameraRow[];
  recorderData: ReportInventoryRecorderRow[];
  cameraColumns: TableColumn<ReportInventoryCameraRow>[];
  recorderColumns: TableColumn<ReportInventoryRecorderRow>[];
  recorderMobileColumns: TableColumn<ReportInventoryRecorderRow>[];
  currentMenu: number;
  totalDataCount: TotalCountInterface;
  paginationPerPage: number;
  onChange: (index: number) => void;
  excelDownload: () => void;
  excelEmail: () => void;
  onChangePage: (page: number, totalRows: number) => void;
  onClickSearch: ClickSearch;
  handleSort: (
    selectedColumn: TableColumn<any>,
    sortDirection: SortOrder
  ) => void;
  reportId: string;
  isExportEmail: boolean;
  onModalExportMail: () => void;
  recorderTablePending: boolean;
  cameraTablePending: boolean;
  selectedAccount: ProfileAccountInfo;
  recorderPaginationInfo:BasePagination;
  cameraPaginationInfo:BasePagination;
};

export default function InventoryPresenter(props: Props): JSX.Element {
  const navigate = useNavigate();
  const menus = [
    {
      label: "Recorders",
    },
    {
      label: "Cameras",
    },
  ];

  const buttons = (
    <s.ButtonWrapper>
      {/* <CSVLink
        data={currentData.data}
        // onClick={() => {
        //   if (confirm("Do you export data?")) {
        //     return true;
        //   } else {
        //     return false;
        //   }
        // }}
        filename={currentData.fileName}
      > */}
      <BigButton
        label="Export"
        buttonType="cancel"
        onClickBtn={props.excelDownload}
        icon={EXPORT_ICON}
      />
      {/* </CSVLink> */}
      <BigButton
        label="Send Email"
        buttonType="cancel"
        onClickBtn={props.excelEmail}
        icon={SEND_EMAIL_ICON}
      />
    </s.ButtonWrapper>
  );

  const titleComponent = [
    {
      label: "Report List",
      onClick: () =>
        menuNavigation(props.selectedAccount, navigate, "/reports"),
      //navigate(-1),
    },
    {
      label: props.title,
    },
  ];

  return (
    <TableTemplate
      detail
      buttons={buttons}
      titleComponent={titleComponent}
      onClickSearch={props.onClickSearch}
    >
      <s.Container>
        <s.Header>
          <ModalSubMenu
            menus={menus}
            currentMenu={props.currentMenu}
            onChange={props.onChange}
            blue
          />
          <mediaQuery.Default>
            <s.Status>
              <StatusLabel>
                <StatusName>Recorders</StatusName>
                <StatusValue>{props.totalDataCount.recorder}</StatusValue>
              </StatusLabel>
              <StatusLabel>
                <StatusName>Cameras</StatusName>
                <StatusValue>{props.totalDataCount.camera}</StatusValue>
              </StatusLabel>
            </s.Status>
          </mediaQuery.Default>
        </s.Header>
        <s.TableContainer>
          <mediaQuery.Default>
            {props.currentMenu === 0 && (
              <InstallReportTable
                columns={props.recorderColumns}
                data={props.recorderData}
                paginationTotalRows={props.totalDataCount.recorder}
                paginationPerPage={props.paginationPerPage}
                onChangePage={props.onChangePage}
                handleSort={props.handleSort}
                defaultSortFieldId={"installedDate"}
                defaultSortAsc={false}
                progressPending={props.recorderTablePending}
                selectedPage={props.recorderPaginationInfo.pageNum}
              />
            )}
            {props.currentMenu === 1 && (
              <InstallReportTable
                columns={props.cameraColumns}
                data={props.cameraData}
                paginationTotalRows={props.totalDataCount.camera}
                paginationPerPage={props.paginationPerPage}
                onChangePage={props.onChangePage}
                handleSort={props.handleSort}
                defaultSortFieldId={"recorderName"}
                progressPending={props.cameraTablePending}
                selectedPage={props.cameraPaginationInfo.pageNum}                
              />
            )}
          </mediaQuery.Default>
          <mediaQuery.Mobile>
            {props.currentMenu === 0 && (
              <InstallReportTable
                columns={props.recorderMobileColumns}
                data={props.recorderData}
                paginationTotalRows={props.totalDataCount.recorder}
                paginationPerPage={props.paginationPerPage}
                onChangePage={props.onChangePage}
                handleSort={props.handleSort}
                defaultSortFieldId={"installedDate"}
                defaultSortAsc={false}
                progressPending={props.recorderTablePending}
                selectedPage={props.recorderPaginationInfo.pageNum}
              />
            )}
            {props.currentMenu === 1 && (
              <InstallReportTable
                columns={props.cameraColumns}
                data={props.cameraData}
                paginationTotalRows={props.totalDataCount.camera}
                paginationPerPage={props.paginationPerPage}
                onChangePage={props.onChangePage}
                handleSort={props.handleSort}
                defaultSortFieldId={"recorderName"}
                progressPending={props.cameraTablePending}
                selectedPage={props.cameraPaginationInfo.pageNum}
              />
            )}
          </mediaQuery.Mobile>
        </s.TableContainer>
      </s.Container>
      {props.isExportEmail && (
        <ExportMail
          reportId={props.reportId}
          title={props.title}
          onModal={props.onModalExportMail}
          recorderPaginationInfo={props.recorderPaginationInfo}
          cameraPaginationInfo={props.cameraPaginationInfo}
        />
      )}
    </TableTemplate>
  );
}
