import styled from "styled-components";
import { COLORS } from "styles/colors";
import { FLEX } from "styles/flex";
import { calcRem } from "styles/theme";
import { DashboardData } from "api/interfaces/dashboardInterface.interface";
import { ProfileAccountInfo } from "api/interfaces/accountInterface.interface";
import { useAppSelector } from "redux/hooks";
import RecorderListInfoItem from "./RecorderListInfoItem";

interface InfoViewProps {
  systems: DashboardData[];
  onMouseEnter?: React.MouseEventHandler<HTMLDivElement> | undefined;
  onMouseLeave?: React.MouseEventHandler<HTMLDivElement> | undefined;
  onSelectRecorder: (system: DashboardData) => void;
}

export default function RecorderListInfoView(
  props: InfoViewProps
): JSX.Element {
  return (
    <Wrapper
      onMouseEnter={props.onMouseEnter}
      onMouseLeave={props.onMouseLeave}

      //   (e) => {
      //   // console.log("onMouseLeave 2");
      //   // props.onMouseLeave(e);
      // }}
    >
      <Info>
        <ul>
          {props.systems.map((system: DashboardData, index) => {
            return (
              <RecorderListInfoItem
                key={`recorder-list-${index}`}
                system={system}
                onSelectRecorder={props.onSelectRecorder}
              />
            );
          })}
        </ul>
      </Info>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  background-color: ${({ theme }) =>
    theme.value === "light" ? COLORS.WHITE : "#0d1119"};
  /* border-radius: 3px; */
  padding: 10px;
  min-width: max-content;
  width: max-content;
`;

const Info = styled.div`
  ${FLEX.FlexBetweenStart};
  flex-wrap: stretch;
  flex-direction: column;
  height: 100%;
  flex: 3;
  max-width: 200px;

  > ul {
    ${FLEX.FlexStartStart};
    flex-direction: column;
    gap: 4px;
    flex: 3;
    /* margin-bottom: 16px; */
    width: 100%;
    max-height: 200px;
    overflow-y: auto;
    padding: 0 8px;
  }
`;
