import styled from "styled-components";
import { COLORS } from "styles/colors";
import { FLEX } from "styles/flex";
import { PiCameraRotate } from "react-icons/pi";

type ThumbnailAvailableProps = {
  isRecorderList: boolean | undefined;
};

function ThumbnailAvailable(props: ThumbnailAvailableProps): JSX.Element {
  return (
    <Container>
      <PiCameraRotate size={24} />
      {!props.isRecorderList && <p>Thumbnail Not Available</p>}
    </Container>
  );
}

const Container = styled.div`
  ${FLEX.FlexCenterCenter};
  flex-direction: column;
  gap: 4px;
  width: 100%;
  height: 100%;

  * {
    color: ${COLORS.WARNING};
  }
  p {
    font-weight: 500;
  }
`;

export default ThumbnailAvailable;
