import { isEmpty } from "lodash";
import { createTime24for30min } from "./functions";
import { getTimeZoneOptions } from "./timeUtil";

export interface Option {
  readonly value: string;
  readonly label: string;
  readonly isDisabled?: boolean;
}

export const intervalOptions: Option[] = [
  { value: "DAILY", label: "Daily" },
  { value: "WEEKLY", label: "Weekly" },
  { value: "MONTHLY", label: "Monthly" },
];

export const addIntervalOptions: Option[] = [
  { value: "WEEK", label: "Weekly" },
  { value: "MONTH", label: "Monthly" },
];

export const intervalMonthOptions: Option[] = [
  { value: "1", label: "1 Month" },
  { value: "3", label: "3 Months" },
  { value: "6", label: "6 Months" },
  // { value: "9", label: "9 Months" },
];

export const accountLevelOptions: Option[] = [
  // { value: "DW", label: "DW", isDisabled: true },
  { value: "CP", label: "CP" },
  { value: "EU", label: "EU" },
];

export const accountLevelOptionsSubCp: Option[] = [
  // { value: "DW", label: "DW", isDisabled: true },
  // { value: "CP", label: "CP", isDisabled: true },
  { value: "EU", label: "EU" },
];

export const timezoneOptions2: Option[] = [
  { value: "PDT", label: "Pacific Daylight Time (PDT)" },
];

export const dateFormatOptions: Option[] = [
  { value: "yyyy/MM/dd", label: "YYYY/MM/dd(2023/03/23)" },
  { value: "yyyy-MM-dd", label: "YYYY-MM-dd(2023-03-23)" },
  { value: "MM/dd/yyyy", label: "MM/dd/YYYY(03/23/2023)" },
  { value: "MM-dd-yyyy", label: "MM-dd-YYYY(03-23-2023)" },
  { value: "dd/MM/yyyy", label: "dd/MM/YYYY(23/03/2023)" },
  { value: "dd-MM-yyyy", label: "dd-MM-YYYY(23-03-2023)" },
];

export const timeFormatOptions: Option[] = [
  { value: "HH:mm:ss", label: "HH:MM:SS(13:12:13)" },
  { value: "hh:mm:ss a", label: "hh:MM:SS(1:12:13 PM)" },
  { value: "HH:mm", label: "HH:MM(13:32)" },
  { value: "hh:mm a", label: "hh:MM(1:32 PM)" },
];

export const timezoneOptions: Option[] = getTimeZoneOptions();

export const widgetChartOptions: Option[] = [
  { value: "list", label: "List" },
  { value: "graph", label: "Graph" },
];

export const pricingOptions: Option[] = [
  { value: "won", label: "won" },
  { value: "dollar", label: "$" },
];

export const videoShareStatusOptions: Option[] = [
  { value: "open", label: "Open" },
  { value: "ongoing", label: "Ongoing" },
  { value: "closed", label: "Closed" },
];

export const userGroupOptions: Option[] = [
  { value: "strongpeople", label: "Strong People" },
  { value: "developers", label: "Developers" },
  { value: "warehouse", label: "Warehouse Employees" },
  { value: "nonviewers", label: "Non-viewers" },
];

export const timeUnitsOptions: Option[] = [
  { value: "MINUTE", label: "Minutes" },
  { value: "HOUR", label: "Hour(s)" },
];

export const timeUnitsOptionsDayHour: Option[] = [
  { value: "HOUR", label: "Hour(s)" },
  { value: "DAY", label: "Day(s)" },
];

export const alertStatusOptions: Option[] = [
  { value: "Active", label: "Active" },
  // { value: "ASSIGNED", label: "Assigned" },
  { value: "Closed", label: "Closed" },
];

export const alertTypeOptions: Option[] = [
  { value: "notresponse", label: "Not Responding" },
  { value: "videoloss", label: "Video Loss [CH4]" },
];

export const settingStartPageOptions: Option[] = [
  { value: "MapView", label: "Map" },
  { value: "ChartView", label: "Widgets" },
  { value: "AdminView", label: "Stats" },
  // { value: "AccountList", label: "Account List" },
  // { value: "RecorderList", label: "Recorder List" },
];

export const recipentsOptions: Option[] = [
  { value: "ALL", label: "All" },
  { value: "USER", label: "User" },
  // { value: "GROUP", label: "Group" },
  { value: "NEWSLETTER", label: "Newsletter" },
];

export const deviceOptions: Option[] = [
  { value: "all", label: "All Devices" },
  { value: "Linux", label: "Linux" },
  { value: "Windows", label: "Windows" },
  { value: "Mac", label: "Mac" },
  { value: "Android", label: "Android" },
  { value: "iOS", label: "iOS" },
];

export const responseSpeedOptions: Option[] = [
  { value: "all", label: "All Alerts" },
  { value: "video", label: "Video" },
  { value: "system", label: "System" },
  { value: "disk", label: "Disk" },
  // { value: "recording", label: "Recording" },
];

// 초기 등록 시간 Options
export const timeOptions: Option[] = createTime24for30min();

// help i am a option
export const iAmAOptions: Option[] = [
  { value: "consumerEU", label: "Consumer End-User" },
  { value: "corporateEU", label: "Corporate End-User" },
  { value: "distributor", label: "Distributor" },
  { value: "installer", label: "Integrator/Installer" },
];

// map status options
export const mapStatusOptions: Option[] = [
  { value: "all", label: "All" },
  { value: "healthy", label: "Healthy" },
  // { value: "warningChosen", label: "Warning" },
  { value: "alert", label: "Alert" },
  { value: "critical", label: "Critical" },
  // { value: "assignedChosen", label: "Assigned" },
];

// map status options
export const recorderLevelOptions: Option[] = [
  { value: "Administrator", label: "Administrator" },
  { value: "Advanced Viewer", label: "Advanced Viewer" },
  { value: "Viewer", label: "Viewer" },
  { value: "Live Viewer", label: "Live Viewer" },
  { value: "Custom", label: "Custom" },
];

// remove license why
export const whyRemoveLicenseOptions: Option[] = [
  { value: "1", label: "The licenses were too expensive" },
  { value: "2", label: "I want to buy licenses for another recorder instead" },
  { value: "3", label: "I have too many licenses on this recorder" },
  {
    value: "4",
    label: "I'm not satisfied with the features that come with these licenses",
  },
  { value: "5", label: "Other (please specify)" },
];

export const monthOptions: Option[] = [
  {
    value: "1",
    label: "January",
  },
  {
    value: "2",
    label: "Feburary",
  },
  {
    value: "3",
    label: "March",
  },
  {
    value: "4",
    label: "April",
  },
  {
    value: "5",
    label: "May",
  },
  {
    value: "6",
    label: "June",
  },
  {
    value: "7",
    label: "July",
  },
  {
    value: "8",
    label: "August",
  },
  {
    value: "9",
    label: "September",
  },
  {
    value: "10",
    label: "October",
  },
  {
    value: "11",
    label: "November",
  },
  {
    value: "12",
    label: "December",
  },
];

export const yearOptions = () => {
  let tempArr = [];
  for (let i = new Date().getFullYear(); i >= 2020; i--) {
    tempArr.push({
      value: String(i),
      label: String(i),
    });
  }

  return tempArr;
};

export const videoGuideOptions: Option[] = [
  {
    value: "about",
    label: "About myDW",
  },
  // {
  //   value: "account",
  //   label: "User Organization",
  // },
  // {
  //   value: "recorders",
  //   label: "Recorders",
  // },
  // {
  //   value: "report",
  //   label: "Report",
  // },
  // {
  //   value: "alert",
  //   label: "Alert",
  // },
  // {
  //   value: "equipment",
  //   label: "Support Equipment",
  // },
];

//TechSummit
export const techStatusOptions: Option[] = [
  { value: "open", label: "Open" },
  { value: "inProgress", label: "In-Progress" },
  { value: "closed", label: "Closed" },
];

export const splitFullName = (name: string, isLastName?: true) => {
  if (!name.trim()) return ""; // 빈 문자열 처리

  const nameParts = name.split(" ");
  if (nameParts.length === 1) return name; // 공백 없는 경우

  const lastName = nameParts.pop()!; // 마지막 단어를 lastName으로 설정
  const firstName = nameParts.join(" "); // 나머지 단어들을 firstName으로 설정

  return isLastName ? lastName : firstName;
};
