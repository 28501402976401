import React, { Fragment, useState } from "react";
import {
  Column,
  Table,
  FilterFn,
  GroupingState,
  useReactTable,
  getCoreRowModel,
  getPaginationRowModel,
  getGroupedRowModel,
  getExpandedRowModel,
  ColumnDef,
  flexRender,
  VisibilityState,
} from "@tanstack/react-table";
import { rankItem, rankings } from "@tanstack/match-sorter-utils";
import * as s from "../BillingPaymentStyled";
import { UnderlineText } from "components/atoms/text/Text";
import STARNDARD_ICON from "assets/icons/standard-license.svg";
import PREMIUM_ICON from "assets/icons/premium-license.svg";
import STORAGE_ICON from "assets/icons/storage-license.svg";
import CHEVRON_DOWN_ICON from "assets/icons/chevron_down_white.svg";
import CHEVRON_UP_ICON from "assets/icons/chevron_up_white.svg";
import { COLORS } from "styles/colors";
import {
  BillingAccountByLicense,
  BillingLicenses,
} from "api/interfaces/licenseInterface.interface";

export interface Props {
  data: BillingLicenses;
  onChangeSort: (type: number) => void;
  onDetail: (accountId: string) => void;
  onCollapsePartItem: () => void;
}

const RankItemOptions = {
  threshold: rankings.CONTAINS,
};
const fuzzyFilter: FilterFn<any> = (row, columnId, value, addMeta) => {
  // Rank the item

  const itemRank = rankItem(row.getValue(columnId), value, RankItemOptions);

  // Store the itemRank info
  addMeta({
    itemRank,
  });

  // Return if the item should be filtered in/out
  return itemRank.passed;
};

export default function PaymentPartTable(props: Props): JSX.Element {
  const columns = React.useMemo<ColumnDef<BillingAccountByLicense>[]>(
    () => [
      {
        accessorKey: "name",
        header: "END USER",
        cell: ({ getValue }) => getValue(),
      },
      {
        accessorKey: "qty",
        header: "END USER QTY",
        cell: ({ getValue }) => getValue(),
      },

      {
        accessorKey: "status",
        header: "STATUS",
        cell: ({ row }) => {
          return (
            <s.StatusCell>
              <li>{row.getValue("status")}</li>
              {row.getValue("accountId") !== undefined &&
                row.getValue("accountId") !== null &&
                row.getValue("accountId") !== "" && (
                  <li>
                    <UnderlineText
                      color={COLORS.SELECTED}
                      fontSize={14}
                      onClick={() => props.onDetail(row.getValue("accountId"))}
                    >
                      Details
                    </UnderlineText>
                  </li>
                )}
            </s.StatusCell>
          );
        },
      },
      {
        accessorKey: "accountId",
        cell: () => "",
      },
    ],
    []
  );
  const [columnVisibility] = useState<VisibilityState>({
    accountId: false,
  });
  const table = useReactTable({
    data: props.data.accounts,
    columns,
    filterFns: {
      fuzzy: fuzzyFilter,
    },
    state: {
      columnVisibility,
    },
    getGroupedRowModel: getGroupedRowModel(),
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
    // debugTable: true,
  });

  const rows = table.getRowModel().rows;
  return (
    <s.TableTitleBox>
      <s.TableTitleItem isCollapsed={props.data.isCollapsed}>
        <s.TableTitle>
          <button
            className="collapse-button"
            onClick={props.onCollapsePartItem}
          >
            {props.data.isCollapsed ? (
              <img src={CHEVRON_DOWN_ICON} alt={`CHEVRON_DOWN_ICON`} />
            ) : (
              <img src={CHEVRON_UP_ICON} alt={`CHEVRON_UP_ICON`} />
            )}
          </button>
          {props.data.type === "STORAGE" && (
            <img src={STORAGE_ICON} alt={`storage icon`} />
          )}
          {props.data.type === "STANDARD" && (
            <img src={STARNDARD_ICON} alt={`standard icon`} />
          )}
          {props.data.type === "PREMIUM" && (
            <img src={PREMIUM_ICON} alt={`premium icon`} />
          )}
          <span>
            <span className="bold">{props.data.description}</span> (
            {props.data.partNumber})
          </span>
        </s.TableTitle>
        {props.data.isCollapsed && (
          <s.TableTitle>
            <span className="bold">TOTAL QTY:</span>
            <span>{props.data.totalQty}</span>
          </s.TableTitle>
        )}
      </s.TableTitleItem>
      {!props.data.isCollapsed && (
        <s.TableWrapper>
          <table>
            <thead>
              {table.getHeaderGroups().map((headerGroup) => (
                <tr key={headerGroup.id}>
                  {headerGroup.headers.map((header) => (
                    <th key={header.id} style={{ width: header.getSize() }}>
                      {header.isPlaceholder
                        ? null
                        : flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                          )}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody>
              {rows.map((row) => {
                // console.log(row);
                return (
                  <s.TBodyTR key={row.id}>
                    {row.getVisibleCells().map((cell) => {
                      return (
                        // Otherwise, just render the regular cell

                        <td key={cell.id}>
                          {flexRender(
                            cell.column.columnDef.cell,
                            cell.getContext()
                          )}
                        </td>
                      );
                    })}
                  </s.TBodyTR>
                );
              })}
            </tbody>
          </table>
        </s.TableWrapper>
      )}
    </s.TableTitleBox>
  );
}
