import {
  AccountInfo,
  LEVEL_TYPE,
} from "api/interfaces/accountInterface.interface";
import { LicenseAccountList } from "api/interfaces/licenseInterface.interface";

export const getLevelFullName = (type: LEVEL_TYPE): string => {
  switch (type) {
    case LEVEL_TYPE.DW:
      return "DW Admin";
    case LEVEL_TYPE.CP:
      return "Channel Partner";
    case LEVEL_TYPE.EU:
      return "End User";
    default:
      return "unknown";
  }
};

export type Account = Omit<AccountInfo, "levelFullName"> & {
  levelFullName: string;
};

export const getAccountListMappger = (
  data: AccountInfo[] | LicenseAccountList[]
): Account[] => {
  return Array.from(data).map((item) => {
    return {
      ...item,
      levelFullName: getLevelFullName(item.level),
    };
  });
};
