import { ProfileAccountInfo } from "api/interfaces/accountInterface.interface";
import { ModalInput } from "./ModalInput";
import AdditionalAccountBubble from "components/blocks/bubble/AdditionalAccountBubble";
import { useAppSelector } from "redux/hooks";

interface ModalInputProps {
  essential?: boolean;
  placeholder?: string;
  children?: any;
  flex?: number;
  half?: boolean;
  noBold?: boolean;
  isValidated?: boolean;
  validatedWord?: string;
  isBubble?: boolean;
  bubbleContents?: any;
}

export default function AdditionalAccountIdModalInput(
  props: ModalInputProps
): JSX.Element {
  const selectedAccount: ProfileAccountInfo = useAppSelector(
    (state) => state.accountSettings
  );
  return (
    <ModalInput
      {...props}
      label="Additional Account ID"
      isBubble={selectedAccount.accountLevel === "DW" ? false : true}
      bubbleContents={<AdditionalAccountBubble />}
    >
      {props.children}
    </ModalInput>
  );
}
