import Select, { StylesConfig } from "react-select";
import { COLORS } from "styles/colors";
import { Option } from "../util";
import TRIANGLE_DOWN from "assets/icons/triangle_down.svg";
import { useAppSelector } from "redux/hooks";

type OptionsType = Array<Option>;

type CommonProps = {
  // clearValue: () => void,
  // getStyles: (string, any) => {},
  // getValue: () => ValueType,
  // hasValue: boolean,
  isMulti?: boolean;
  options: OptionsType;
  // selectOption: OptionType => void,
  // selectProps: any,
  // setValue: (ValueType, ActionTypes) => void,
  // emotion: any,
  value?: any;
  isDisabled?: boolean;
  onChange?: (value: string) => void;
  isAlertView?: boolean;
  placeholder?: string;
};
const customLightStyles: StylesConfig<Option> = {
  control: (styles, { isDisabled }) => {
    const backgroundColor = COLORS.WHITE;
    const paddingRight = "8px";
    // const minWidth = "120px";
    const maxWidth = "150px";
    const width = "100%";
    const border = "1px solid";
    const borderColor = COLORS.BORDER;
    // const color = COLORS.BORDER;

    return {
      ...styles,
      backgroundColor,
      paddingRight,
      // minWidth,
      // maxWidth,
      width,
      border,
      borderColor,
      // color,
    };
  },
  container: (styles) => ({ ...styles, width: "100%" }),
  placeholder: (styles) => ({ ...styles, color: "#abb5c5" }),
  singleValue: (styles) => ({ ...styles, color: COLORS.BLACK }),
  // dropdownIndicator: (styles) => ({ ...styles, color: COLORS.BUTTONS1 }),
  indicatorSeparator: () => ({}),
};
const customStyles: StylesConfig<Option> = {
  control: (styles, { isDisabled }) => {
    const backgroundColor = COLORS.PRIMARY3;
    const paddingRight = "8px";
    // const minWidth = "120px";
    const maxWidth = "150px";
    const width = "100%";
    const border = "none";
    const color = "#abb5c5";

    return {
      ...styles,
      backgroundColor,
      paddingRight,
      // minWidth,
      // maxWidth,
      width,
      border,
      color,
    };
  },
  container: (styles) => ({ ...styles, width: "100%" }),
  placeholder: (styles) => ({ ...styles, color: "#abb5c5" }),
  singleValue: (styles) => ({ ...styles, color: "#abb5c5" }),
  // dropdownIndicator: (styles) => ({ ...styles, color: COLORS.BUTTONS1 }),
  indicatorSeparator: () => ({}),
};
const DropdownIndicator = () => <img src={TRIANGLE_DOWN} alt="TRIANGLE_DOWN" />;

const ReportSelect = (props: CommonProps) => {
  const themeMode = useAppSelector((state) => state.theme.theme);

  return (
    <Select
      {...props}
      styles={themeMode === "light" ? customLightStyles : customStyles}
      components={{ DropdownIndicator }}
      placeholder={props.placeholder ? props.placeholder : "Select"}
      onChange={({ value }) => {
        if (props.onChange) {
          props.onChange(value);
        }
      }}
    />
  );
};

export default ReportSelect;
