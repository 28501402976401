import DefaultThumbnail from "components/atoms/image/DefaultThumbnail";
import { CenterlineText } from "components/atoms/text/Text";
import styled from "styled-components";
import { COLORS } from "styles/colors";
import { FLEX } from "styles/flex";
import { calcRem } from "styles/theme";
import * as mediaQuery from "components/MediaQuery";

export const Container = styled.section`
  width: 100%;
  /* height: 100%; */
  background-color: ${({ theme }) =>
    theme.value === "light" ? theme.COLORS.PRIMARY3 : "#0c1724"};
  padding: 20px 30px;
  border-radius: 3px;
  /* overflow-y: auto; */

  ${mediaQuery.isMobile} {
    padding: 10px;

    > ul {
      width: 100%;
    }
  }
`;

export const Header = styled.div`
  ${FLEX.FlexBetweenCenter};
  background-color: ${({ theme }) => theme.COLORS.PRIMARY3};
  padding: 10px 15px;
  border-radius: 3px;
  margin-bottom: 20px;
`;

export const HeaderInfo = styled.div`
  ${FLEX.FlexStartCenter};

  ${mediaQuery.isDefault} {
    gap: 40px;
  }
  ${mediaQuery.isMobile} {
    width: 100%;
    justify-content: space-between;

    * {
      font-size: ${calcRem(13)};
    }
    > div {
      flex: 1;
    }
  }
`;

export const CvvLabel = styled.span`
  padding: 6px 10px;
  background-color: #ff001d;
  border-radius: 3px;
  font-weight: 500;
  ${FLEX.FlexCenterCenter};
  gap: 4px;
  font-size: ${calcRem(14)};

  img {
    width: 14px;
  }
`;

export const ContentHeader = styled.div`
  ${FLEX.FlexBetweenCenter};
  padding-bottom: 30px;

  p {
    flex: 1;
    ${FLEX.FlexEndCenter};
  }

  ${mediaQuery.isMobile} {
    ${FLEX.FlexStartStart};
    flex-direction: column;
    width: 100%;
    gap: 6px;
    p {
      margin-top: 10px;
    }
  }
`;

export const ContentInfo = styled.ul`
  flex: 1;
  ${FLEX.FlexStartCenter};
  gap: 60px;

  ${mediaQuery.isMobile} {
    ${FLEX.FlexBetweenCenter};
    width: 100%;
    justify-content: flex-start;
    gap: 10%;
  }
`;
export const ContentInfoItem = styled.li`
  ${FLEX.FlexStartStart};
  flex-direction: column;
  gap: 8px;

  .content-info-item-title {
    font-weight: 400;
    color: ${({ theme }) => `${theme.COLORS.WHITE}90`};
  }
  strong {
    color: ${COLORS.RED};
  }
  ${mediaQuery.isMobile} {
    flex: 1;
    gap: 4px;

    * {
      font-size: ${calcRem(15)};
    }
  }
`;

/**
 * Camera ----
 */

export const CameraContainer = styled.ul``;

export const CameraHeaderItem = styled(ContentInfoItem)`
  width: 100%;
  flex: 1;
  max-width: 100%;
  overflow: hidden;

  > div {
    width: 100%;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  ${mediaQuery.isMobile} {
    gap: 10px;
    flex-direction: row;
  }

  .cvv-header-title {
    color: ${({ theme }) => `${theme.COLORS.WHITE}94`};
  }
`;

export const CameraItem = styled.li`
  width: 100%;
  margin-bottom: 30px;
`;

export const CameraTitle = styled.div`
  margin-bottom: 10px;

  .cvv-label {
    margin-left: 8px;
  }
`;

export const CameraWrapper = styled.ul`
  ${FLEX.FlexBetweenStart};
  gap: 18px;
  flex: 1;
  width: 100%;
  height: 100%;

  ${mediaQuery.isMobile} {
    flex-direction: column;
  }
`;

export const CameraThumbnail = styled.li`
  ${FLEX.FlexStartStart};
  flex-direction: column;
  flex: 1;
  height: 100%;
  width: 25%;
  cursor: pointer;

  .camera-title {
    width: 100%;
    ${FLEX.FlexStartCenter};
    margin-top: 6px;
    gap: 10px;
  }

  > div {
    width: 100%;
  }
  ${mediaQuery.isMobile} {
    width: 100%;
  }
`;

export const CvvThumbnailWrapper = styled.div`
  position: relative;
`;
export const ThumbnailImage = styled(DefaultThumbnail)`
  margin-bottom: 10px;
`;

export const ErrorBorder = styled.div`
  box-shadow: 0 0 0 2px ${COLORS.RED} inset;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  z-index: 55;
`;

export const ErrorWrapper = styled.div`
  ${FLEX.FlexEndCenter};
  gap: 6px;
  position: absolute;
  z-index: 33;
  right: 6px;
  top: 6px;
`;

/**
 * CVV Modal
 */
export const ModalHeader = styled.div`
  width: 100%;
  position: relative;

  button {
    position: absolute;
    top: -10px;
    right: 0;
  }

  ${mediaQuery.isMobile} {
    > button {
      right: 0;
      width: 22px;
    }
    > button img {
      width: 100%;
    }
  }
`;

export const ModalInfo = styled.ul`
  ${FLEX.FlexStartCenter};
  gap: 50px;

  ${mediaQuery.isMobile} {
    align-items: flex-start;
    flex-direction: column;
    gap: 10px;
  }
`;

export const CvvContainer = styled.section<{ isError: boolean }>`
  width: 100%;
  border-radius: 9px;
  overflow: hidden;
  margin-top: 16px;

  ${({ isError }) =>
    isError &&
    `  
    border: 6px solid #ff001d;
  `}
`;

export const CvvHeader = styled.div`
  padding: 14px 6px;
  background-color: #ff001d;
  color: ${({ theme }) => theme.COLORS.WHITE};
  ${FLEX.FlexStartCenter};
  gap: 8px;

  img {
    width: 24px;
  }
`;

export const CvvImage = styled.img`
  width: 100%;
  height: 100%;
  background-size: cover;
`;

export const HeaderRight = styled.div`
  ${FLEX.FlexEndCenter};
  flex: 1;
  gap: 10px;

  .select-wrapper {
    width: 180px;
  }
  .search-input {
    width: 250px;
  }

  ${mediaQuery.isMobile} {
    width: 100%;
    justify-content: space-between;
    margin-bottom: 10px;

    .select-wrapper {
      width: 50%;
    }
  }
`;

export const MobileCameraTitle = styled.div`
  ${FLEX.FlexBetweenCenter};
  width: 100%;

  > div {
    width: max-content;
  }
`;
