import styled from "styled-components";
import * as mediaQuery from "components/MediaQuery";
import { FLEX } from "styles/flex";
import { COLORS } from "styles/colors";

export const Grid = styled.div`
  ${mediaQuery.isDefault} {
    display: grid;
    grid-template-columns: 300px auto;
    // grid-template-rows: repeat(1, auto);
    /* height: 500px; */
    gap: 10px;
    //margin-top: 30px;
    padding-bottom: 40px;
    height: calc(100% - 95px);
  }

  ${mediaQuery.isMobile} {
    width: 100%;
    ${FLEX.FlexStartStart};
    flex-direction: column;
    gap: 26px;
    margin-top: 20px;
  }
`;

export const LeftAside = styled.div`
  width: 100%;
  height: 100%;
  gap: 10px;

  /* overflow: hidden; */
  ${mediaQuery.isDefault} {
    display: flex;
    align-items: flex-end;
    flex-direction: column;
  }

  ${mediaQuery.isMobile} {
    ${FLEX.FlexStartStart};
    flex-direction: column;
    gap: 20px;
    width: 100%;
  }
`;

export const TreeContainer = styled.div`
  display: flex;
  height: 100vh;
  flex: 1;
  min-width: 0;
  background-color: ${({ theme }) =>
    theme.value === "light" ? theme.COLORS.PRIMARY3 : `rgb(37, 46, 68)`};
  border-radius: 10px;
  color: ${({ theme }) => theme.COLORS.WHITE};

  .tree {
    //border-radius: 16px;
    //height: 100%;
    //background: #dddd;
  }

  .node-container,
  .node-content {
    display: flex;
    height: 100%;
    align-items: center;
    width: 100%;
  }

  .node-content {
    cursor: pointer;
  }

  .node-content span.arrow {
    width: 20px;
    font-size: 20px;
    display: flex;
  }

  .node-content span.file-folder-icon {
    margin-right: 7px;
    display: flex;
    align-items: center;
    font-size: 20px;
  }

  .node-content span.node-text {
    flex: 1;
  }

  .node-content input[type="text"],
  .search-input {
    padding: 0px 10px;
    outline: none;
    color: white;
    border-radius: 2px;
  }

  .node-content input[type="text"] {
    width: 97%;
    border: 1px solid #878787;
    background: transparent;
    height: 22px;
  }

  .search-input {
    width: 100%;
    height: 30px;
    background: #2d2c2c;
    border: none;
    margin: 1rem 0;
  }

  .search-input:focus {
    border: 1px solid #878787;
  }

  .file-actions {
    height: 100%;
    display: flex;
  }

  .file-actions button {
    cursor: pointer;
  }

  .folderFileActions {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-right: 10px;
  }

  .folderFileActions button {
    display: flex;
    align-items: center;
    color: #999999;
    background-color: inherit;
    border: none;
    font-size: 16px;
    height: 100%;
    width: 24px;
  }

  // .node-container .file-actions .folderFileActions {
  //   visibility: hidden;
  // }
  .node-container:hover .file-actions .folderFileActions {
    visibility: visible;
  }

  // [role="treeitem"]:hover {
  //   background-color: #343434;
  //   color: white;
  // }

  [role="treeitem"]:focus-within {
    background-color: rgba(52, 52, 52, 0.4);
    outline: none;
  }

  /* Selected node */

  .node-container.isSelected {
    background: ${COLORS.LIGHTGRAY};
    color: white;
    border-radius: 0;
  }

  /* Right side content */
  .content {
    flex: 1;
    padding: 32px;
  }

  .content ul {
    list-style: none;
  }

  .content ul li:nth-child(2),
  .content ul li:nth-child(3) {
    margin-bottom: 1rem;
  }

  .content ul li:nth-child(3) {
    color: white;
    font-weight: bold;
  }

  /* Dropdown arrow */
  .node span:nth-child(1) {
    width: 20px;
    display: flex;
    font-size: 20px;
  }

  /* Icon */
  .node span:nth-child(2) {
    margin-right: 18px;
    display: flex;
    align-items: center;
    font-size: 20px;
  }
`;
export const RightAside = styled.div`
  width: 100%;
  height: 100%;
  gap: 10px;

  ${mediaQuery.isDefault} {
    display: flex;
    align-items: flex-end;
    flex-direction: column;
  }

  ${mediaQuery.isMobile} {
    width: 100%;
  }
`;

export const Row = styled.div`
  ${FLEX.FlexStartCenter};
  gap: 8px;
`;
