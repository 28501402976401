import { AlertList2 } from "api/interfaces/alertInterface.interface";
import * as s from "../AlertViewStyled";
import { AlertProbableCause } from "utils/AlertUtil";
import { useLocation, useNavigate } from "react-router-dom";
import { getRecorderTimeFormat } from "utils/timeUtil";
import { ProfileAccountInfo } from "api/interfaces/accountInterface.interface";
import { useAppSelector } from "redux/hooks";
import { FiEdit } from "react-icons/fi";

interface Props {
  data: AlertList2;
}

const alertInfoStyled = s.AlertInfoStyled;

const AlertInfo = (props: Props) => {
  // console.log(props.data, "alert info");
  const selectedAccount: ProfileAccountInfo = useAppSelector(
    (state) => state.accountSettings
  );
  const location = useLocation();
  const navigate = useNavigate();
  const isMap = location.pathname.includes("map");
  // console.log(props.data, "aLERT INFO ========");
  return (
    <alertInfoStyled.AlertInfoWrapper isMap={isMap}>
      {!isMap && (
        <alertInfoStyled.AlertInfoItem className="causes column-box">
          <div className="subtitle">Probable Causes</div>
          <ul>
            {AlertProbableCause[
              props.data.type as keyof typeof AlertProbableCause
            ] !== undefined &&
              AlertProbableCause[
                props.data.type as keyof typeof AlertProbableCause
              ].map((cause, index) => <li key={index}>{cause}</li>)}
          </ul>
        </alertInfoStyled.AlertInfoItem>
      )}

      <alertInfoStyled.AlertInfoItem className="column-box center ">
        <div className="column-box">
          <div className="subtitle">Event Date</div>
          <div>
            {getRecorderTimeFormat(
              props.data.eventDate,
              selectedAccount,
              props.data.timezone,
              true
            )}
          </div>
        </div>
        <div className="column-box">
          <div className="subtitle">Alert Triggered</div>
          <div>
            {getRecorderTimeFormat(
              props.data.alertTrigger,
              selectedAccount,
              props.data.timezone,
              true
            )}
          </div>
        </div>
      </alertInfoStyled.AlertInfoItem>
      <alertInfoStyled.AlertInfoItem className="column-box" isMap={isMap}>
        <div className="subtitle">
          Related Alert Rules
          <s.Edit onClick={() => navigate("/alert/rules")}>
            <FiEdit />
          </s.Edit>
        </div>
        <ul>
          {props.data.rules.map((rule: any, index: number) => {
            return (
              <li key={index}>
                <span className="ruleName">{rule.alertRuleName}</span>
              </li>
            );
          })}
        </ul>
      </alertInfoStyled.AlertInfoItem>
    </alertInfoStyled.AlertInfoWrapper>
  );
};

export default AlertInfo;
