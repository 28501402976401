import { ChangeEvent } from "react";
import styled from "styled-components";
import { COLORS } from "styles/colors";
export const EditorStyle = styled.div`
  width: 100%;
  border-radius: 4px;
  overflow: hidden;

  .quill {
    height: 100%;
    width: 100%;
    background-color: ${COLORS.WHITE};
  }
  .ql-container {
    height: calc(100% - 42px);
  }
  .quill > .ql-container > .ql-editor.ql-blank::before {
    font-size: 1rem;
    color: #abb5c5;
    font-style: normal;
  }
`;

export interface EditorProps {
  placeholder?: string;
}
