import { PointTooltipProps } from "@nivo/line";
import styled from "styled-components";
import { COLORS } from "styles/colors";
import { calcRem } from "styles/theme";

export default function LineChartTooltip(
  props: PointTooltipProps
): JSX.Element {
  // console.log(props.point);

  const yFormat =
    props.point.serieId === "CPU" || props.point.serieId === "Memory"
      ? `${props.point.data.yFormatted}%`
      : props.point.data.yFormatted;
  return (
    <Wrapper>
      {/* <span
        style={{
          display: "block",
          width: "12px",
          height: "12px",
          background: props.point.color,
        }}
      /> */}
      <span>
        {props.point.data.xFormatted} : {yFormat} 
        <p></p>
        {props.point.serieId !== "user-login" && props.point.serieId}
      </span>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  background-color: ${COLORS.WHITE};
  color : ${COLORS.BLACK};
  padding: 4px;
  font-size: ${calcRem(13)};
  border-radius: 4px;

  > span {
    font-weight: 500;
  }
`;
