import * as s from "../SignupStyled";
import * as cs from "../../SignCommonStyled";
import { UserInfo, Validate } from "../SignupContainer";
import { useIntl } from "react-intl";
import { useEffect, useState } from "react";
import Input from "components/atoms/input/Input";
import { ValidateWord } from "components/atoms/text/Text";
import { checkPasswordNumberRegEx, checkPasswordRegEx } from "utils/regEx";
import PasswordBubble from "components/blocks/bubble/PasswordBubble";
import { UPDATE_TYPE } from "../SignupPresenter";

interface Props {
  checkedEmail: string;
  onChangeUserInfo: (type: UPDATE_TYPE, data: string | boolean) => void;
  userInfo: UserInfo;
  onblurConfirmPassword: () => void;
  isValidConfirm: boolean;
  isValidPassword: boolean;
  onClickPassword: () => void;
  onClickBackLogin: () => void;
  isValidated: Validate;

  passwordRef: any;
  confirmPasswordRef: any;
  validateMsg: string;

  isExpiredToken: boolean;
}

export default function ResetPassword(props: Props): JSX.Element {
  const {
    checkedEmail,
    onChangeUserInfo,
    userInfo,
    onblurConfirmPassword,
    isValidConfirm,
    isValidPassword,
    onClickPassword,
    onClickBackLogin,
    isValidated,
    passwordRef,
    confirmPasswordRef,
    validateMsg,
    isExpiredToken,
  } = props;
  const intl = useIntl();
  const [isFocus, setIsFocus] = useState<boolean>(false);

  useEffect(() => {
    if (
      !(userInfo.password.length < 8) &&
      checkPasswordRegEx(userInfo.password) &&
      checkPasswordNumberRegEx(userInfo.password)
    ) {
      let timer = setTimeout(() => {
        setIsFocus(false);
      }, 800);
      return () => {
        clearTimeout(timer);
      };
    } else {
      //setIsFocus(true);
    }
  }, [userInfo.password]);

  return (
    <>
      <cs.EmailBadge>{checkedEmail}</cs.EmailBadge>

      <cs.InputWrapper>
        {!isExpiredToken && (
          <>
            <s.PasswordInputInner>
              <Input
                id="dw_signup_input_password"
                ref={passwordRef}
                type="password"
                autoComplete="new-password"
                className="input-field"
                placeholder="Password"
                value={userInfo.password}
                onChange={(e) =>
                  onChangeUserInfo(UPDATE_TYPE.PASSWORD, e.target.value)
                }
                isValidated={isValidated.password}
                onFocus={() => setIsFocus(true)}
                onBlur={() => setIsFocus(false)}
              />
              {isFocus && (
                <s.PasswordBubble>
                  <PasswordBubble password={userInfo.password} />
                </s.PasswordBubble>
              )}
            </s.PasswordInputInner>

            <s.PasswordInputInner>
              <Input
                id="dw_signup_input_confirmPassword"
                ref={confirmPasswordRef}
                type="password"
                autoComplete="new-password"
                className="input-field"
                placeholder="Confirm Password"
                value={userInfo.confirmPassword}
                onChange={(e) =>
                  onChangeUserInfo(UPDATE_TYPE.CONFIRM_PASSWORD, e.target.value)
                }
                onBlur={(e) => onblurConfirmPassword()}
                isValidated={isValidConfirm}
                autoFocus={isValidConfirm}
              />
            </s.PasswordInputInner>
          </>
        )}
        {isValidConfirm && (
          <cs.Bottom>
            <ValidateWord>
              {intl.formatMessage({
                id: "validateMsg.signup.passwordMismatch",
                defaultMessage: "The password you entered does not match.",
              })}
            </ValidateWord>
          </cs.Bottom>
        )}
        {isValidPassword && (
          <cs.Bottom>
            <ValidateWord>
              {intl.formatMessage({
                id: "validateMsg.reset.fail",
                defaultMessage: "Password reset unsuccessful.",
              })}
            </ValidateWord>
          </cs.Bottom>
        )}
        <cs.Bottom>
          <ValidateWord>{validateMsg}</ValidateWord>
        </cs.Bottom>
      </cs.InputWrapper>

      <div className="submit">
        {!isExpiredToken && (
          <cs.BigButton
            id="dw_signup_button_resetPassword"
            cancel={isExpiredToken}
            onClick={() => {
              if (!isExpiredToken) {
                onClickPassword();
              }
            }}
          >
            {intl.formatMessage({
              id: "reset_password",
              defaultMessage: "Reset Password",
            })}
          </cs.BigButton>
        )}
        {isExpiredToken && (
          <cs.BigButton
            id="dw_signup_button_forward_login"
            type="button"
            onClick={onClickBackLogin}
          >
            {intl.formatMessage({
              id: "email_sent_back",
              defaultMessage: "Return to Login Screen",
            })}
          </cs.BigButton>
        )}
      </div>
    </>
  );
}
