import { Text } from "components/atoms/text/Text";
import styled, { keyframes } from "styled-components";
import { COLORS } from "styles/colors";
import { FLEX } from "styles/flex";
import { motion } from "framer-motion";
import * as mediaQuery from "components/MediaQuery";

export const Container = styled(motion.div)`
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: ${({ theme }) =>
    theme.value === "light" ? COLORS.WHITE : theme.COLORS.PRIMARY3};
  z-index: 666;
  color: ${COLORS.WHITE};
  /* border-top-right-radius: 6px;
  border-bottom-right-radius: 6px; */
  border-radius: 6px;
  display: flex;
  flex: 1;
  flex-direction: column;

  .button-wrapper {
    width: 100%;
    ${FLEX.FlexEndCenter};
    /* margin-bottom: 20px; */
    padding: 10px;

    img {
      width: 15px;
    }
  }

  ${mediaQuery.isMobile} {
    height: 100%;
  }
`;

export const Inner = styled.div`
  /* height: calc(100% - 42px); */
  flex: 1;
  height: 100%;
`;
