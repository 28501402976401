import { UnderlineText } from "components/atoms/text/Text";
import * as s from "./SignupStyled";
import BigPopupTemplate from "components/templates/modal/big/BigPopupTemplate";

interface Props {
  onOpenEULAModal: () => void;
}

export function EULAModal(props: Props): JSX.Element {
  const { onOpenEULAModal } = props;
  return (
    <BigPopupTemplate
      title="myDW End User License Agreement"
      subTitle=""
      onModal={onOpenEULAModal}
    >
      <s.ModalInner>
        MYDW SOFTWARE END USER LICENSE AGREEMENT <br />
        <br />
        IMPORTANT – PLEASE READ CAREFULLY: This End User License Agreement (the
        “EULA”) is a legally enforceable contract between You (either an
        individual or an entity) and Digital Watchdog. (the “Company”) regarding
        the use of the Company’s software programs accompanying or provided
        under this Agreement together with the SDK made available by the
        Company, associated media, corresponding Documentation, technical
        configurations, and technical data (collectively, the “Software”).
        “Documentation” means the user’s guide and other documentation
        (including print and online), if any, provided to you with the Software.
        <br />
        <br />
        BY CLICKING “I ACCEPT” BUTTON BELOW OR BY DOWNLOADING, INSTALLING OR
        OTHERWISE USING THE SOFTWARE, YOU CONSENT TO BE LEGALLY BOUND BY ALL THE
        TERMS AND CONDITIONS OF THE AGREEMENT. IF YOU ARE ENTERING INTO THE
        AGREEMENT ON BEHALF OF A COMPANY OR OTHER LEGAL ENTITY, YOU REPRESENT
        THAT YOU HAVE THE AUTHORITY TO BIND SUCH ENTITY AND ITS AFFILIATES TO
        THE AGREEMENT, IN WHICH CASE THE TERMS "YOU" OR "YOUR" SHALL REFER TO
        SUCH ENTITY AND ITS AFFILIATES. IF YOU DO NOT HAVE SUCH AUTHORITY, OR IF
        YOU DO NOT AGREE WITH ANY OF THE TERMS OF THE AGREEMENT, YOU MUST NOT
        ACCEPT THE AGREEMENT AND MAY NOT USE THE SOFTWARE.
        <br />
        <br />
        In addition, certain third party programs or code (“Third Party
        Software”) are being provided to you under the terms and conditions of a
        separate end user license accompanying such Third Party Software (“Third
        Party Software Licenses”), which will be provided to you by the Company
        as part of the Documentation or may be made available for viewing by the
        Company at the website or webpage at
        <UnderlineText
          className="button"
          onClick={() =>
            window.open(
              "https://support.digital-watchdog.com/kb/article/912-open-source-software-disclosure-for-mydw-and-mydw-web-portal"
            )
          }
        >
          https://support.digital-watchdog.com/kb/article/912-open-source-software-disclosure-for-mydw-and-mydw-web-portal
        </UnderlineText>
        &nbsp;the “Third Party Site”). If required by any Third Party Software
        License, the Company will make such Third Party Software, and the
        Company’s modifications to that Third Party Software, available to you
        on the Third Party Site. The Company may be required or wish to provide
        you with certain notices or information about Third Party Software or
        Third Party Software Licenses and you acknowledge that such notices and
        information may be provided to you by the posting of such notices and
        information on the Third Party Site. BY INSTALLING, COPYING OR OTHERWISE
        USING THE SOFTWARE, YOU AGREE TO BE BOUND BY THE TERMS OF THIS AGREEMENT
        AS WELL AS THE TERMS OF THIRD PARTY SOFTWARE LICENSES. IF YOU DO NOT
        AGREE TO ALL SUCH TERMS, DO NOT INSTALL OR USE THE SOFTWARE.
        <br />
        <br />
        1. License Grant. Subject to the terms and conditions of this Agreement,
        the Company grants you a non-exclusive, non-transferable,
        non-sublicensable, limited license to install and use the Software, in
        object code version only, for your own use subject to the restrictions
        contained in this Agreement.
        <br />
        2. Backup Copies. You may make one backup copy of the Software, provided
        your backup copy is not installed or used on any computer and further
        provided that all such copies shall bear the original and unmodified
        copyright, patent and other intellectual property markings that appear
        on or in the Software. You may not transfer the rights to a backup copy
        unless you transfer all rights in the Software as provided in this
        Agreement.
        <br />
        3. Ownership. You acknowledge that the Software and any copies that you
        are authorized by the Company to make are the intellectual property of,
        and are owned by, the Company and its licensors. The structure, sequence
        and organization of the Software are the valuable trade secrets and
        confidential information of the Company and its licensors. The Software
        is protected by copyright, including without limitation by United States
        copyright law, international treaty provisions and applicable laws in
        the country in which it is used. You acknowledge that the Company, or
        its licensors, retain the ownership of all patents, copyrights, trade
        secrets, trademarks and other intellectual property rights pertaining to
        the Software. You will take no actions which adversely affect the
        Company’s intellectual property rights in the Software. This Agreement
        is a license and not an agreement for sale. No title to, or ownership
        of, the Software, or any intellectual property rights subsisting
        therein, is transferred to you.
        <br />
        4. Restrictions. The Software is licensed solely for your own use. The
        Company and its licensors reserve all rights not expressly granted to
        you in this Agreement. Without limiting the generality of the foregoing,
        you shall not nor shall you permit any other party to: (i) make copies
        of the Software except as expressly set forth in this Agreement, or (ii)
        disassemble, decompile or translate any part of the Software, or
        otherwise attempt to reconstruct or discover the source code of the
        Software, except and only to the extent that such activity is expressly
        permitted by applicable law notwithstanding this limitation, or (iii)
        modify or create derivative works based upon the Software, or (iv)
        externally distribute, sublicense, resell, encumber or otherwise
        transfer the Software, or (v) rent, lease, lend, or use the Software, or
        any part thereof, for timesharing or bureau use, or (vi) allow a third
        party to copy, access, or use the Software (except as expressly provided
        in this Agreement), or (vii) alter or remove any copyright, trademark or
        other proprietary notice which may appear on the Software, (viii) take
        any action that would cause the Software to be placed in the public
        domain or become subject to open source license agreement, or (ix) use
        the Software in any manner that violates any statute, law, rule,
        regulation, directive, guideline, bylaw whether presently in force or
        may be implemented by federal, state or local authorities.
        <br />
        5. Paid Features. The Software is made available to you at no charge or
        fees. However, the Company may offer certain premium features to you
        upon payment of a license fees determined by the Company in its sole
        discretion (“Paid Features”). In order to use Paid Features, you must
        agree to pay the license fees plus any applicable taxes through one of
        the payment methods accepted by the Company. Upon receipt of the license
        fees, the Company will either provide you a license key to activate the
        Paid Features or allow you to download the Software with the Paid
        Features. Activation of Paid Features may also be regulated through the
        use of transferrable license keys, and such license keys may only be
        transferred within your organization subject to the limitations listed
        in your purchase order or other documentation describing the Software
        and/or license rights obtained in the Software. You expressly
        acknowledge and agree that this Agreement will govern your use of the
        Paid Features.
        <br />
        6. Updates. Except as otherwise expressly provided herein, this
        Agreement will govern any updates and upgrades to the Software that may
        be provided to you in accordance with the Company’s then-current
        maintenance and support policies, unless such updates and upgrades are
        provided under a separate license agreement. You may use such updates or
        upgrades only in conjunction with your then-existing the Software
        licensed under this Agreement. The Software and all updates and upgrades
        are licensed as a single product and such updates and upgrades may not
        be separated from the Software to exceed the scope of your original
        license. Any other software that may be provided with the Software that
        is associated with a separate end-user license agreement is licensed to
        you under the terms of that separate license agreement.
        <br />
        7. No Support. Except as expressly required by applicable law, nothing
        in this Agreement entitles you to any support, maintenance or new
        versions of the Software. You may contact the Company to determine the
        availability of support, maintenance, and new versions, and the fees
        terms and conditions that would apply.
        <br />
        8. Disclaimer of Warranties. THE SOFTWARE IS PROVIDED “AS IS,” WITHOUT
        WARRANTY OR REPRESENTATION OF ANY KIND, WHETHER EXPRESS, IMPLIED, OR
        STATUTORY, INCLUDING WITHOUT LIMITATION, ANY IMPLIED WARRANTIES OF
        NON-INFRINGEMENT, MERCHANTABILITY, AND FITNESS FOR A PARTICULAR PURPOSE.
        NEITHER THE COMPANY NOR ITS LICENSORS REPRESENT OR WARRANT THAT THE
        SOFTWARE WILL SATISFY YOUR REQUIREMENTS OR THAT IT IS WITHOUT DEFECT OR
        ERROR OR THAT THE OPERATION THEREOF WILL BE UNINTERRUPTED OR ERROR FREE.
        The Company does not warrant that functions contained in the Software
        will meet your requirements or that the operation of the Software will
        be error-free or uninterrupted. The Company is not responsible for
        problems caused by changes in, or modifications to, the operating
        characteristics of any computer hardware or operating system for which
        the Software or any upgrade or update is procured, nor is the Company
        responsible for problems which occur as a result of the use of the
        Software in conjunction with software of third parties or with hardware,
        which does not meet the Company’s specific requirements. Some
        jurisdictions do not allow certain disclaimers or limitations of
        warranties, so some of these may not apply to you.
        <br />
        9. Limitation of Liability.TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE
        LAW EVEN IF A REMEDY FAILS ITS ESSENTIAL PURPOSE, IN NO EVENT SHALL THE
        COMPANY OR ITS LICENSORS BE LIABLE FOR ANY SPECIAL, INCIDENTAL, INDIRECT
        OR CONSEQUENTIAL DAMAGES WHATSOEVER (INCLUDING, WITHOUT LIMITATION,
        DAMAGES FOR LOSS OF BUSINESS PROFITS, BUSINESS INTERRUPTION, LOSS OF
        BUSINESS INFORMATION, OR ANY OTHER PECUNIARY LOSS) ARISING OUT OF THE
        USE OF OR INABILITY TO USE THE SOFTWARE OR THE PROVISION OF OR FAILURE
        TO PROVIDE SUPPORT SERVICES, EVEN IF THE COMPANY HAS BEEN ADVISED OF THE
        POSSIBILITY OF SUCH DAMAGES. IN ANY CASE, THE COMPANY’S ENTIRE LIABILITY
        TO YOU OR ANY OTHER PARTY FOR LOSS OR DAMAGE RESULTING FROM ANY CLAIMS,
        DEMANDS OR ACTIONS ARISING OUT OF THIS AGREEMENT SHALL NOT EXCEED THE
        FEE ACTUALLY PAID BY YOU TO THE COMPANY FOR THE SOFTWARE, IF ANY,
        NOTWITHSTANDING ANY FAILURE OF ESSENTIAL PURPOSE OF ANY LIMITED REMEDY.
        Because some states and jurisdictions do not allow the exclusion or
        limitation of liability, the above limitation may not apply to you. In
        such states and jurisdictions, the Company’s liability shall be limited
        to the greatest extent permitted by law.
        <br />
        10. Sole Remedy and Allocation of Risk. YOUR SOLE AND EXCLUSIVE REMEDY
        AND THE COMPANY’S SOLE AND EXCLUSIVE LIABILITY ARE SET FORTH IN THIS
        AGREEMENT. This Agreement defines a mutually agreed-upon allocation of
        risk.
        <br />
        11. Third Party Software Licenses. Third Party Software included with
        the Software is licensed under the terms of Third Party Software
        Licenses. You understand and agree that, although provided to you by the
        Company with the Software, your use of Third Party Software shall be and
        is governed by such Third Party Software Licenses. You understand and
        acknowledge that Third Party Software is being provided “AS IS” WITHOUT
        ANY WARRANTY, INCLUDING BUT NOT LIMITED TO, THE IMPLIED WARRANTIES OF
        MERCHANTABILITY OR FITNESS FOR A PARTICULAR PURPOSE. If there is a
        conflict between this Agreement and any Third Party Software License
        with respect to Third Party Software, the provisions of such Third Party
        Software License shall prevail.
        <br />
        12. Export Restrictions. You agree to comply with all export and
        re-export restrictions and regulations (“Export Restrictions”) imposed
        by the United States or any other country in which you conduct business.
        Without limiting the generality of the foregoing, and regardless of any
        disclosure made by you to the Company regarding an ultimate destination
        of the Software, you represent and warrant that you understand the U.S.
        law currently prohibits the export or re-export, directly or indirectly
        (including via remote access) of U.S. origin products and technology to
        certain proscribed countries (including Cuba, Iran, Sudan, North Korea
        and Syria and/or any other country that may become subject to an embargo
        by the United States), entities, organizations and individuals, without
        prior authorization from the U.S. Government. You will not commit any
        act or omission that will result in a breach of any such Export
        Restrictions. Your breach of this clause shall constitute cause for
        immediate termination of this Agreement.
        <br />
        13. High Risk Activities. The Software is not designed, manufactured or
        intended for use or resale as on-line control equipment in hazardous
        environments requiring fail-safe performance, such as in the operation
        of nuclear facilities, aircraft navigation or communication systems, air
        traffic control, direct life support machines, or weapons systems, in
        which the failure of the Software could lead directly to death, personal
        injury, or severe physical or environmental damage (“High Risk
        Activities”). The Company specifically disclaims any express or implied
        warranty of fitness for High Risk Activities.
        <br />
        14. Consent to Collect Data. You consent to the Company collecting and
        using technical data and information from the installed application. The
        collected information includes, but is not limited to, the version of
        the Software, Software configuration, devices added or connected to the
        Software, total session time, Internet/network usage, hardware
        specifications, and image data from the CVV (Camera View Verification)
        feature. Image data collected from users who use the CVV feature will be
        used to train and improve our existing AI, enhancing CVV's ability to
        detect obstructions and scenery changes more effectively. The
        information is gathered to improve Software usage, updates, devices, and
        configuration. You can disable data collection, including image data
        from the CVV feature, from the Software if you do not want the data to
        be sent.
        <br />
        15. Termination. The licenses granted herein shall automatically
        terminate without notice if you fail to comply with any material
        provision of this Agreement. In such event, you must immediately
        uninstall and return the Software, including all Documentation and
        original media, to the Company. This shall not limit or affect any
        remedy available to the Company for your breach of this Agreement.
        <br />
        16. Miscellaneous. (a) If you are a unit or agency of the United States
        Government, the following applies: The Software is provided with
        RESTRICTED RIGHTS. Use, duplication or disclosure by the Government is
        subject to restrictions as set forth in Subparagraphs (a) through (d) of
        the Commercial Computer-Restricted Rights clause at FAR 52.227-19 when
        applicable, or in Subparagraph 252.227-7013 (c)(1)(ii) of the Rights in
        Technical Data and Computer Software at DFARS, and in similar clauses in
        the NASA FAR Supplement. Contractor/manufacturer is Digital Watchdog.
        (b) This Agreement shall be governed by the laws of the State of
        California, exclusive of its choice of law rules. Any dispute between
        you and the Company regarding this Agreement will be subject to the
        exclusive jurisdiction of the state and federal courts sitting in the
        County of Los Angeles in the State of California. In any such action,
        the prevailing party will be entitled to costs and attorneys’ fees. (c)
        You may not assign, sublicense, or transfer this Agreement, the
        Software, or any rights or obligations hereunder without prior written
        consent of the Company. Any such attempted assignment, sublicense, or
        transfer will be null and void. The Company may terminate this Agreement
        in the event of any such attempted assignment, sublicense, or transfer.
        (d) This Agreement is in the English language only, and will only be
        provided in the English language, which language will be controlling in
        all respects, and all versions hereof in any other language will not be
        binding on the parties hereto. All communications and notices to be made
        or given pursuant to this Agreement must be in the English language. (e)
        The Agreement constitutes the final and complete understanding between
        you and the Company with respect to the subject matter of this
        Agreement. Any modifications or waivers of this Agreement must be in
        writing and signed by both parties hereto. (f) If any provision in this
        Agreement is invalid or unenforceable or contrary to applicable law,
        such provision shall be construed, limited, or altered, as necessary, to
        eliminate the invalidity or unenforceability or the conflict with
        applicable law, and all other provisions of this Agreement shall remain
        in effect. (g) Failure or delay on the part of the Company to exercise
        any right, power, privilege, or remedy will not constitute a waiver of,
        or bar the later exercise of, that or any other right, power, privilege,
        or remedy of the Company. (h) No rule of strict construction shall apply
        against or in favor of either party in the construction and
        interpretation of this Agreement.
      </s.ModalInner>
    </BigPopupTemplate>
  );
}
