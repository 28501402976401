import styled from "styled-components";
import { FLEX } from "styles/flex";
import { ModalInput } from "../input/modal/ModalInput";
import DaySelect from "./DaySelect";
import TimeSelect from "./TimeSelect";
import DELETE_X_ICON from "assets/icons/delete_x.svg";
import { Fragment, useCallback, useEffect, useRef, useState } from "react";
import { COLORS } from "styles/colors";
import { calcRem } from "styles/theme";
import * as mediaQuery from "components/MediaQuery";
import * as s from "styles/ModalCommonStyled";
import { timeOptions } from "utils/options";

export interface TimeDaySelectIF {
  timeString: string;
  days: boolean[];
}

export interface ScheduleItem extends TimeDaySelectIF {
  id: number;
}

type Props = {
  selected: TimeDaySelectIF[];
  onChangeDayOfWeek?: (selectDayOfWeek: TimeDaySelectIF[]) => void;
};

const TimeDaySelect = (props: Props) => {
  const nextID = useRef<number>(1);
  const [selectedTimeList, setSelectedTimeList] = useState<string[]>([]);
  //const [disableTime, setDisableTime] = useState<Set<string>>(new Set())
  const [scheduleItems, setScheduleItems] = useState<ScheduleItem[]>([
    {
      id: 1,
      timeString: "09:00",
      days: [true, true, true, true, true, true, true],
    },
  ]);

  const [checkTimeOptions, setCheckTimeOptions] = useState(timeOptions);

  useEffect(() => {
    let checkTimeOptions: any[] = [];
    checkTimeOptions = timeOptions.map((opt) => {
      if (scheduleItems.find((item) => item.timeString === opt.value))
        return {
          ...opt,
          isDisabled: true,
        };
      else return opt;
    });

    setCheckTimeOptions(checkTimeOptions);
  }, [scheduleItems]);

  useEffect(() => {
    if (props.selected) {
      const selectTime = new Set<string>();
      let insertScheduleItme = Array.of<ScheduleItem>();
      props.selected.forEach((data) => {
        insertScheduleItme.push({
          id: nextID.current,
          timeString: data.timeString,
          days: data.days,
        });
        selectTime.add(data.timeString);
        nextID.current += 1;
      });
      setScheduleItems(insertScheduleItme);
      //setDisableTime(selectTime);
    }
  }, [props.selected]);

  const addScheduleItem = useCallback(() => {
    if (scheduleItems.length >= 7) {
      return;
    }

    let tempCheckArr = checkTimeOptions.filter((opt) => {
      let maxSchedule = scheduleItems.sort((a: ScheduleItem, b: ScheduleItem) =>
        b.timeString > a.timeString ? -1 : 1
      )[scheduleItems.length - 1];

      if (
        maxSchedule.timeString ===
        checkTimeOptions[checkTimeOptions.length - 1].value
      ) {
        return !opt.isDisabled;
      } else return opt.value > maxSchedule.timeString && !opt.isDisabled;
    });

    let timeString = tempCheckArr[0].value;

    const insert = {
      id: nextID.current,
      timeString: timeString,
      days: [true, true, true, true, true, true, true],
    };
    nextID.current += 1;
    const newSchedule = [...scheduleItems, insert];
    setScheduleItems(newSchedule);
    if (props.onChangeDayOfWeek) {
      props.onChangeDayOfWeek(newSchedule as TimeDaySelectIF[]);
    }
  }, [props, scheduleItems, checkTimeOptions]);

  const deleteScheduleItem = useCallback(
    (index: number) => {
      // 인덱스 값을 받아서
      const filteredItem = scheduleItems.filter((item) => item.id !== index);
      setScheduleItems(filteredItem);
      if (props.onChangeDayOfWeek) {
        props.onChangeDayOfWeek(filteredItem as TimeDaySelectIF[]);
      }
    },
    [props, scheduleItems]
  );

  const onChangeTime = useCallback(
    (value: string, id: number) => {
      const nextList = [...scheduleItems];
      const updateItem = nextList.find((item) => item.id === id);
      if (updateItem !== undefined) {
        updateItem.timeString = value;
        setScheduleItems(nextList);
      }
      if (props.onChangeDayOfWeek) {
        props.onChangeDayOfWeek(scheduleItems as TimeDaySelectIF[]);
      }
    },
    [props, scheduleItems]
  );

  const onChangeDayOfWeek = useCallback(
    (value: boolean[], id: number) => {
      const nextList = [...scheduleItems];
      const updateItem = nextList.find((item) => item.id === id);
      if (updateItem !== undefined) {
        updateItem.days = value;
        setScheduleItems(nextList);
      }

      if (props.onChangeDayOfWeek) {
        props.onChangeDayOfWeek(scheduleItems as TimeDaySelectIF[]);
      }
    },
    [props, scheduleItems]
  );

  return (
    <>
      <mediaQuery.Default>
        <Row>
          <TimeWrapper>
            <ModalInput label="Time" />
          </TimeWrapper>
          <DayWrapper>
            <ModalInput label="Day" />
          </DayWrapper>
        </Row>
        {scheduleItems
          .sort((a: ScheduleItem, b: ScheduleItem) =>
            b.timeString > a.timeString ? -1 : 1
          )
          .map((item: ScheduleItem, index: number) => {
            return (
              <Row>
                <TimeWrapper>
                  <TimeSelect
                    onChange={(value) => onChangeTime(value, item.id)}
                    defaultValue={item.timeString}
                    value={item.timeString}
                    options={checkTimeOptions}
                    // controlShouldRenderValue={true}
                  />
                </TimeWrapper>
                <DayWrapper>
                  <p className="day-wrapper">
                    <DaySelect
                      selectedDays={item.days}
                      onChangeDayOfWeek={(selectDayOfWeek) =>
                        onChangeDayOfWeek(selectDayOfWeek, item.id)
                      }
                    />
                    {index > 0 ? (
                      <DeleteButton>
                        <img
                          src={DELETE_X_ICON}
                          alt="delete x icon"
                          onClick={() => deleteScheduleItem(item.id)}
                        />
                      </DeleteButton>
                    ) : (
                      <Fragment />
                    )}
                  </p>
                </DayWrapper>
              </Row>
            );
          })}
      </mediaQuery.Default>
      <mediaQuery.Mobile>
        <Row>
          <ModalInput label="Time & Day" />
        </Row>
        {scheduleItems.map((item, index) => (
          <Fragment>
            <s.Row>
              <DayWrapperMobile>
                <p className="day-wrapper">
                  <TimeSelect
                    onChange={(value) => onChangeTime(value, item.id)}
                    value={item.timeString}
                  />
                  {index > 0 ? (
                    <img
                      src={DELETE_X_ICON}
                      alt="delete x icon"
                      onClick={() => deleteScheduleItem(item.id)}
                    />
                  ) : (
                    <Fragment />
                  )}
                </p>
              </DayWrapperMobile>
            </s.Row>
            <s.Row>
              <DaySelect
                selectedDays={item.days}
                onChangeDayOfWeek={(selectDayOfWeek) =>
                  onChangeDayOfWeek(selectDayOfWeek, item.id)
                }
              />
            </s.Row>
          </Fragment>
        ))}
      </mediaQuery.Mobile>
      {scheduleItems.length < 7 && (
        <AddButton
          onClick={addScheduleItem}
          disabled={scheduleItems.length >= 7}
        >
          + Add
        </AddButton>
      )}
    </>
  );
};

const Row = styled.ul`
  ${FLEX.FlexBetweenStart};
  gap: 14px;
  width: 100%;
  position: relative;
  margin-top: 5px;
`;

const TimeWrapper = styled.li`
  flex: 1;
`;

const DayWrapper = styled.li`
  flex: 3;
  .day-wrapper {
    width: 100%;
    position: relative;
  }
`;

const DayWrapperMobile = styled.li`
  flex: 1;
  .day-wrapper {
    display: flex;
    width: 100%;
    position: relative;
  }
`;

const DeleteButton = styled.button`
  position: absolute;
  right: -23px;
  top: 50%;
  transform: translateY(-50%);
  width: 14px;
  height: 14px;

  > img {
    width: 100%;
    height: 100%;
  }
`;

const AddButton = styled.button`
  border: 1px dashed ${COLORS.BORDER};
  background-color: ${({ theme }) => theme.COLORS.WHITE}10;
  width: 100%;
  padding: 10px;
  margin-top: 5px;
  color: ${({ theme }) => theme.COLORS.WHITE};
  font-size: ${calcRem(14)};
`;

export default TimeDaySelect;
