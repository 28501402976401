import { AxiosResponse } from "axios";
import { AxiosInstance } from "./axiosInstance";
import {
  CameraWithRecorderRequest,
  CameraWithRecorderResponse,
  GetCaemraListResposne,
  GetCameraRequest,
} from "./interfaces/cameraInterface.interface";
import qs from "qs";

const API_PATH = "/camera";

export const getCameratList = async ({
  payload,
  accountId,
}: {
  payload: GetCameraRequest;
  accountId: string;
}): Promise<GetCaemraListResposne> => {
  try {
    const res: AxiosResponse<GetCaemraListResposne> = await AxiosInstance.get(
      `${API_PATH}/list/${accountId}?${qs.stringify(payload)}`
    );
    return res.data;
  } catch (e) {
    console.error(e);
    throw new Error();
  }
};

export const getCameratListRecorderList = async ({
  payload,
  accountId,
}: {
  payload: string[];
  accountId: string;
}): Promise<void> => {
  try {
    await AxiosInstance.post(`${API_PATH}/list/${accountId}`, payload);
  } catch (e) {
    console.error(e);
    throw new Error();
  }
};

export const getCameraListWithRecorderList = async ({
  payload,
  accountId,
}: {
  payload: CameraWithRecorderRequest;
  accountId: string;
}): Promise<CameraWithRecorderResponse> => {
  try {
    const res: AxiosResponse<CameraWithRecorderResponse> =
      await AxiosInstance.post(
        `${API_PATH}/totalList/${accountId}`,
        payload
      );
    return res.data;
  } catch (e) {
    console.error(e);
    throw new Error();
  }
};