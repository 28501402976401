import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { useAuth } from "../Auth";

function ProtectedRoute({
  redirect = "/login",
  children,
}: {
  redirect?: string;
  children?: JSX.Element;
}) {
  const auth = useAuth();

  if (!auth.user) {
    return <Navigate to={redirect} replace />;
  }
  return children ? children : <Outlet />;
}

export default ProtectedRoute;
