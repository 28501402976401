import styled from "styled-components";
import { COLORS } from "styles/colors";
import Input from "../Input";

// interface UploadInputProps {
//   placeholder?: string;
//   onUpload: (e?: any) => void;
//   ref?: any;
// }

// const UploadInput = ({
//   placeholder,
//   onUpload,
//   ref,
// }: UploadInputProps): JSX.Element => {
//   return (
//     <FileInput
//       ref={ref}
//       onChange={onUpload}
//       type="file"
//       placeholder={placeholder ? placeholder : ""}
//     />
//   );
// };

const FileInput = styled(Input)`
  background-color: ${COLORS.WHITE};
  cursor: pointer;
  padding-top: 9px;
  padding-bottom: 9px;
  /* display: none; */

  ::file-selector-button {
    display: none;
  }
`;

export default FileInput;
