import * as mediaQuery from "components/MediaQuery";
import { useEffect, useState } from "react";
import * as s from "../FilterStyled";
import styled from "styled-components";
import { FLEX } from "styles/flex";
import { calcRem } from "styles/theme";
import MARKWE_ALERT from "assets/icons/map/marker_alert.svg";
import MARKWE_HEALTHY from "assets/icons/map/marker_healthy.svg";
import MARKWE_CRITICAL from "assets/icons/map/marker_critical.svg";
import MARKWE_WARNING from "assets/icons/map/marker_warning.svg";
import MARKWE_ASSIGN from "assets/icons/map/marker_assign.svg";
import { COLORS, MAP_STATUS_COLORS } from "styles/colors";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import {
  MapFilter,
  mapFilterInitialState,
  setStatus,
} from "redux/reducers/map/mapFilter";
import {
  DashboardFilter,
  DashboardFilterStatus,
} from "api/interfaces/dashboardInterface.interface";
import { Text, UnderlineText } from "components/atoms/text/Text";
import TinyButton from "components/atoms/buttons/TinyButton";

type Props = {
  onApply: () => void;
  onChangeFiltering: (name: string, value: boolean) => void;
};

type List = {
  label: string;
  // value: "healthy" | "alert" | "critical" | "warning" | "assigned";
  value: "healthy" | "alert" | "critical";
  icon: any;
};

export const healthAlertStatus = [
  {
    label: "Healthy",
    value: "healthy",
    color: MAP_STATUS_COLORS.HEALTHY,
  },
  {
    label: "Alert",
    value: "alert",
    color: MAP_STATUS_COLORS.ALERT,
  },
];

export const alertStatus = [
  {
    label: "All",
    value: "all",
    color: "#171F37",
  },
  {
    label: "Healthy",
    value: "healthy",
    color: MAP_STATUS_COLORS.HEALTHY,
  },
  // {
  //   label: "Warning",
  //   value: "warning",
  //   color: MAP_STATUS_COLORS.WARNING,
  // },
  {
    label: "Alert",
    value: "alert",
    color: MAP_STATUS_COLORS.ALERT,
  },
  {
    label: "Critical",
    value: "critical",
    color: MAP_STATUS_COLORS.CRITICAL,
  },
  // {
  //   label: "Assigned",
  //   value: "assigned",
  //   color: MAP_STATUS_COLORS.ASSIGNED,
  // },
];
const statusList: List[] = [
  {
    label: "Healthy",
    value: "healthy",
    icon: MARKWE_HEALTHY,
  },
  {
    label: "Alert",
    value: "alert",
    icon: MARKWE_ALERT,
  },
  {
    label: "Critical",
    value: "critical",
    icon: MARKWE_CRITICAL,
  },
  // {
  //   label: "Warning",
  //   value: "warning",
  //   icon: MARKWE_WARNING,
  // },
  // {
  //   label: "Assigned",
  //   value: "assigned",
  //   icon: MARKWE_ASSIGN,
  // },
];

const StatusFilter = (props: Props): JSX.Element => {
  const dispatch = useAppDispatch();
  const mapFilter: DashboardFilter = useAppSelector(
    (state) => state.mapFilter.filters
  );

  const [status, setStatusFilter] = useState<DashboardFilterStatus>(
    mapFilter.status
  );

  const onChange = (e: any) => {
    let {
      target: { checked, name },
    } = e;

    setStatusFilter({
      ...status,
      [name]: checked,
    });
  };

  useEffect(() => {
    if (status) {
      dispatch(setStatus(status));
    }
  }, [status]);

  useEffect(() => {
    if (mapFilter.status) {
      setStatusFilter(mapFilter.status);

      if (Object.values(mapFilter.status).every((status) => !status)) {
        props.onChangeFiltering("status", false);
      } else {
        props.onChangeFiltering("status", true);
      }
    }
  }, [mapFilter.status]);

  const onResetFilter = () => {
    dispatch(setStatus(mapFilterInitialState.filters.status));
    setStatusFilter(mapFilterInitialState.filters.status);
  };

  return (
    <s.Container>
      <mediaQuery.Mobile>
        <s.Title>
          <Text fontSize={14} bold>
            Status
          </Text>
        </s.Title>
      </mediaQuery.Mobile>
      <Wrapper>
        {statusList.map((stat: List) => {
          return (
            <Item key={stat.label}>
              <label>
                <input
                  type="checkbox"
                  checked={status[stat.value]}
                  name={stat.value}
                  onChange={onChange}
                />
                <img src={stat.icon} alt={`${stat.label} icon`} />
                {stat.label}
              </label>
            </Item>
          );
        })}
      </Wrapper>
      <mediaQuery.Default>
        <s.Reset>
          <UnderlineText
            color={COLORS.LIGHTGRAY}
            fontSize={12}
            onClick={onResetFilter}
          >
            Clear
          </UnderlineText>
          <TinyButton label="Done" onClickBtn={props.onApply} />
        </s.Reset>
      </mediaQuery.Default>
    </s.Container>
  );
};

export default StatusFilter;

const Wrapper = styled.ul`
  position: relative;
  ${FLEX.FlexStartStart};
  flex-direction: column;
  gap: 8px;

  ${mediaQuery.isMobile} {
    margin-top: 10px;
  }
`;

const Item = styled.li`
  color: ${({ theme }) => theme.COLORS.WHITE};
  ${FLEX.FlexStartCenter};
  /* gap: 6px; */
  font-size: ${calcRem(13)};

  img {
    height: 20px;
  }
`;
export const Circle = styled.p<{ bgColor: string }>`
  border-radius: 50%;
  background-color: ${({ bgColor }) => bgColor};
  border: 1px solid ${COLORS.WHITE};
  width: 15px;
  height: 15px;
`;
