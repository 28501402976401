import { TableTemplate } from "components/templates/default/table/TableTemplate";
import PaginationComponent from "components/blocks/pagination";
import * as s from "./ThumbnailStyled";
import PrimaryButton from "components/atoms/buttons/PrimaryButton";

import { Text, UnderlineText } from "components/atoms/text/Text";
import { COLORS } from "styles/colors";
import StorageLabel, {
  StorageProps,
} from "components/blocks/storage/StorageLabel";
import BigPopupNoHeaderTemplate from "components/templates/modal/big/BigPopupNoHeaderTemplate";
import CLOSE_ICON from "assets/icons/sidenav/x.svg";
import { ModalInput } from "components/atoms/input/modal/ModalInput";
import ThumbnailFilter from "./ThumbnailFilter";
import TimeSelect from "components/atoms/select/TimeSelect";
import DateSelect from "components/atoms/select/DateSelect";
import { useNavigate } from "react-router-dom";
import DefaultThumbnail from "components/atoms/image/DefaultThumbnail";
import { StorageWrapper } from "components/templates/default/table/TableTemplateStyled";
import ThumbnailItem from "./ThumbnailItem";
import {
  DateTime,
  ThumbnailData,
  ThumbnailFilterViewItem,
  ThumbnailViewItem,
} from "api/interfaces/reportInterface.interface";
import { BasePagination } from "api/interfaces/commonInterface.interface";
import { Option } from "utils/options";
import { getRecorderTimeFormat } from "utils/timeUtil";
import { ProfileAccountInfo } from "api/interfaces/accountInterface.interface";
import { menuNavigation } from "utils/MenuUtil";
import Selects from "components/atoms/select/Select";

type Props = {
  title: string | undefined;
  dateTime: DateTime[];
  listQueryInfo: BasePagination;
  thumbnailFilterQueryInfo: BasePagination;
  recorderList: Option[];
  cameraList: Option[];
  filterDateList: string[];
  isOpenFilter: boolean;
  isModal: boolean;
  isFilterApply: boolean;
  onChangeOpenFilter: () => void;
  onChangeModal: (
    popupData?: ThumbnailData,
    recorderName?: string,
    cameraName?: string
  ) => void;
  onChangeIsFilter: () => void;
  onSearch: () => void;
  onChangePage: (page: number, totalRows: number) => void;
  onChangePageFilterTable: (page: number, totalRows: number) => void;
  selectFilterRecorder: string;
  selectFilterCamera: string;
  selectFilterDate: string;
  onChangeFilterRecorder: (value: string) => void;
  onChangeFilterCamera: (value: string) => void;
  onChangeFilterDate: (date: Date) => void;
  listTotalCount: number;
  onChangeSearchTime: (value: string) => void;
  onChangeSearchDate: (date: Date) => void;
  selectedDate: string;
  selectedTime: string;
  selectedTimeOption: Option[];
  thumbnailList: ThumbnailViewItem[];
  thumbnailFilterList: ThumbnailFilterViewItem[];
  markedDay: string[];
  selectedAccount: ProfileAccountInfo;
  modalData?: ThumbnailViewItem;
  validateFilterDate: boolean;
  storageUsage: StorageProps;
};

export default function ThumbnailPresenter(props: Props): JSX.Element {
  const navigate = useNavigate();

  const sub = (
    <StorageWrapper>
      <StorageLabel
        totalStorage={props.storageUsage.totalStorage}
        usedStorage={props.storageUsage.usedStorage}
      />
    </StorageWrapper>
  );

  const titleComponent = [
    {
      label: "Report List",
      onClick: () =>
        menuNavigation(props.selectedAccount, navigate, "/reports"),
      //navigate("/reports"),
    },
    {
      label: props.title,
    },
  ];

  return (
    <>
      <TableTemplate
        detail
        noSearch
        buttons={sub}
        titleComponent={titleComponent}
      >
        <s.Header>
          <s.HeaderSelect>
            {!props.isFilterApply ? (
              <>
                <div className="select-wrapper">
                  <DateSelect
                    value={props.selectedDate}
                    markDays={props.markedDay}
                    onChange={props.onChangeSearchDate}
                  />
                </div>
                <div className="select-wrapper">
                  <TimeSelect
                    options={props.selectedTimeOption}
                    defaultValue={
                      props.selectedTimeOption.length > 0 &&
                      props.selectedTimeOption[0]
                    }
                    value={
                      props.selectedTime !== undefined &&
                      props.selectedTime !== ""
                        ? props.selectedTimeOption.find(
                            (data) => data.value === props.selectedTime
                          )
                        : ""
                    }
                    onChange={props.onChangeSearchTime}
                  />
                </div>
              </>
            ) : (
              <Text fontSize={18} bold>
                {props.thumbnailFilterList !== undefined &&
                props.thumbnailFilterList.length > 0
                  ? props.thumbnailFilterList[0].recorderName
                  : ""}
              </Text>
            )}
            {/* 
            <mediaQuery.Default>
              {props.isFilterApply && (
                <Text fontSize={18} bold>
                  {props.thumbnailFilterList !== undefined &&
                  props.thumbnailFilterList.length > 0
                    ? props.thumbnailFilterList[0].recorderName
                    : ""}
                </Text>
              )}
            </mediaQuery.Default> */}
          </s.HeaderSelect>

          <s.RightHeader>
            {/* <mediaQuery.Default> */}
            {props.isFilterApply && (
              <UnderlineText
                color={COLORS.BUTTONS1}
                onClick={props.onChangeIsFilter}
              >
                Clear Filter
              </UnderlineText>
            )}
            {/* </mediaQuery.Default> */}
            <PrimaryButton
              buttonType="cancel"
              label="Filter"
              onClickBtn={props.onChangeOpenFilter}
            />
            {props.isOpenFilter && (
              <s.FilterContainer>
                <ModalInput label="Recorder">
                  <Selects
                    options={props.recorderList}
                    value={
                      props.selectFilterRecorder !== undefined &&
                      props.selectFilterRecorder !== ""
                        ? props.recorderList.filter((option) => {
                            return option.value === props.selectFilterRecorder;
                          })
                        : props.recorderList[0]
                    }
                    onChange={props.onChangeFilterRecorder}
                  />
                </ModalInput>
                <ModalInput label="Camera">
                  <Selects
                    options={props.cameraList}
                    value={
                      props.selectFilterCamera !== undefined &&
                      props.selectFilterCamera !== ""
                        ? props.cameraList.filter((option) => {
                            return option.value === props.selectFilterCamera;
                          })
                        : props.cameraList[0]
                    }
                    onChange={props.onChangeFilterCamera}
                  />
                </ModalInput>
                <ModalInput label="Date">
                  <DateSelect
                    markDays={props.filterDateList}
                    value={props.selectFilterDate}
                    onChange={props.onChangeFilterDate}
                    disabled={!props.validateFilterDate}
                  />
                </ModalInput>
                <PrimaryButton label="Search" onClickBtn={props.onSearch} />
              </s.FilterContainer>
            )}
          </s.RightHeader>
        </s.Header>
        {props.isFilterApply ? (
          <>
            <s.FilterGridWrapper>
              {props.thumbnailFilterList.map((item, index) => (
                <>
                  <ThumbnailFilter
                    channelName={item.cameraName}
                    recorderName={item.recorderName}
                    data={item.thumbnails}
                    selectedAccount={props.selectedAccount}
                    onChangeModal={props.onChangeModal}
                  />
                </>
              ))}
            </s.FilterGridWrapper>

            {props.thumbnailFilterQueryInfo.total !== 0 && (
              <PaginationComponent
                rowsPerPage={props.thumbnailFilterQueryInfo.pageLimit}
                rowCount={props.thumbnailFilterQueryInfo.total}
                onChangePage={props.onChangePageFilterTable}
                currentPage={props.thumbnailFilterQueryInfo.pageNum + 1}
                onChangeRowsPerPage={() => {
                  return false;
                }}
              />
            )}
          </>
        ) : (
          <>
            <s.Grid>
              {props.thumbnailList.map((item, index) => (
                <ThumbnailItem
                  index={index}
                  item={item as ThumbnailData}
                  recorderName={item.recorderName}
                  cameraName={item.cameraName}
                  selectedAccount={props.selectedAccount}
                  onChangeModal={props.onChangeModal}
                />
              ))}
            </s.Grid>
            {props.listQueryInfo.total !== 0 && (
              <PaginationComponent
                rowsPerPage={props.listQueryInfo.pageLimit}
                rowCount={props.listQueryInfo.total}
                onChangePage={props.onChangePage}
                currentPage={props.listQueryInfo.pageNum + 1}
                onChangeRowsPerPage={() => {
                  return false;
                }}
              />
            )}
          </>
        )}
      </TableTemplate>
      {props.isModal && props.modalData !== undefined && (
        <BigPopupNoHeaderTemplate>
          <s.ModalHeader>
            <s.ModalInfo>
              <s.ContentInfoItem>
                <Text color={`${COLORS.WHITE}50`}>Recorders</Text>
                <Text bold>{props.modalData.recorderName}</Text>
              </s.ContentInfoItem>

              <s.ContentInfoItem>
                <Text color={`${COLORS.WHITE}50`}>Camera</Text>
                <Text bold>{props.modalData.cameraName}</Text>
              </s.ContentInfoItem>

              <s.ContentInfoItem>
                <Text color={`${COLORS.WHITE}50`}>Date / Time</Text>

                <Text bold>
                  {getRecorderTimeFormat(
                    props.modalData.timestamp,
                    props.selectedAccount,
                    props.modalData.timezone,
                    true,
                    true
                  )}
                </Text>
                {/* {changeTimeStampFormat(
                              item.timestamp,
                              item.timezone
                            )} */}
              </s.ContentInfoItem>
            </s.ModalInfo>

            <button onClick={() => props.onChangeModal()}>
              <img alt="close button" src={CLOSE_ICON} />
            </button>
          </s.ModalHeader>
          <s.CvvContainer>
            <DefaultThumbnail>
              <img src={props.modalData.url} alt="thumbnail_image" />
            </DefaultThumbnail>
          </s.CvvContainer>
        </BigPopupNoHeaderTemplate>
      )}
    </>
  );
}
