import axios from "axios";

export const ACCESS_TOKEN="access";
export const REFRESH_TOKEN="refresh";

const API_VERSION = "/v2";

const authToken = {
  access: typeof window === 'undefined' && typeof global !== 'undefined' ? null : sessionStorage.getItem('accessToken'),
  refresh:
    typeof window === 'undefined' && typeof global !== 'undefined' ? null : sessionStorage.getItem('refreshToken'),
};

export const redirectToLoginPage = () => {
  const isDev = window.location.hostname === 'localhost';
  window.location.href = isDev ? 'http://localhost:3000/login' : `${process.env.REACT_APP_BASE_URL}/login`;
};

export const AxiosInstance = axios.create({
  baseURL: `${process.env.REACT_APP_BASE_URL}/api${API_VERSION}`,
  timeout: 100000,
});