import { useNavigate } from "react-router-dom";
import { OperationPresenter } from "./OperationPresenter";
import { useIntl } from "react-intl";
import { useAuth } from "components/Auth";
import { useAppSelector } from "redux/hooks";
import { ProfileAccountInfo } from "api/interfaces/accountInterface.interface";
import { useCallback, useState } from "react";
import {
  BasePagination,
  SORT_DIRECTION,
} from "api/interfaces/commonInterface.interface";
import { Media, SortOrder, TableColumn } from "react-data-table-component";
import { useQuery } from "react-query";
import { notify } from "components/atoms/notification/Notification";
import { OperationLog } from "api/interfaces/managerInterface.interface";

export default function OperationContainer(): JSX.Element {
  const navigate = useNavigate();
  const intl = useIntl();
  const auth = useAuth();

  const selectedAccount: ProfileAccountInfo = useAppSelector(
    (state) => state.accountSettings
  );

  const [opertaionList, setOperationList] = useState<OperationLog[]>([]);

  const columns: TableColumn<OperationLog>[] = [
    {
      name: "Time",
      grow: 1,
      selector: (row) => row.createAt,
      sortable: true,
      sortField: "createAt",
    },
    {
      name: "Menu",
      grow: 1,
      cell: (row) => row.menu,
    },
    {
      name: "Action",
      sortable: true,
      grow: 1,
      // minWidth: "160px",
      selector: (row) => row.action,
    },
    {
      name: "User",
      grow: 1,
      cell: (row) => row.userName,
      center: true,
    },
    {
      name: "Organization",
      grow: 1,
      selector: (row) => row.accountName,
      center: true,
    },
    {
      name: "Recorder",
      grow: 1,
      cell: (row) => row.recorderName,
      center: true,
    },
    {
      name: "Result",
      grow: 1,
      selector: (row) => row.result,
    },
    {
      name: "ErrorCode",
      grow: 1,
      selector: (row) => row.errorCode,
    },
  ];

  const [operationLogQueryInfo, setOperationLogQueryInfo] =
    useState<BasePagination>({
      total: 0,
      pageNum: 0,
      pageLimit: 20,
      keyword: "",
      sortType: "createdAt",
      sortDirection: SORT_DIRECTION.DESC,
    });

  // const { error, refetch } = useQuery(
  //   ["alertRule", operationLogQueryInfo],
  //   () =>
  //     getAlertRuleList({
  //       accountId: selectedAccount.accountId,
  //       payload: alertRuleQueryInfo,
  //     }),
  //   {
  //     retry: 0,
  //     refetchOnWindowFocus: false,
  //     onSuccess: (res: any) => {
  //       if (res.error !== 0 && res.result === undefined) {
  //         setAlertRules({ totalEnable: 0 });
  //         setAlertList([]);
  //         setTotalCount(0);
  //       } else {
  //         setAlertRules({ totalEnable: res.page.total });
  //         setAlertList(res.result);
  //         setTotalCount(res.page.total);
  //       }
  //     },
  //     onError: (err: any) => {
  //       notify(
  //         "error",
  //         intl.formatMessage({
  //           id: "label.alert.notify.list.error",
  //           defaultMessage: "Alert search error.",
  //         })
  //       );
  //     },
  //   }
  // );
  const onClickSearch = useCallback((keyword: string) => {
    setOperationLogQueryInfo((query) => {
      return {
        ...query,
        keyword,
      };
    });
    //refetch();
  }, []);

  const onChangePage = useCallback(
    (page: number, totalRows: number) => {
      if (page !== operationLogQueryInfo.pageNum + 1) {
        setOperationLogQueryInfo((info) => {
          return { ...info, pageNum: (page - 1) as number };
        });
      }
    },
    [operationLogQueryInfo.pageNum]
  );

  const handleSort = async (
    selectedColumn: TableColumn<any>,
    sortDirection: SortOrder
  ) => {
    if (selectedColumn.sortField !== undefined) {
      setOperationLogQueryInfo((info) => {
        return {
          ...info,
          sortType: selectedColumn.sortField as string,
          sortDirection: sortDirection.toUpperCase() as SORT_DIRECTION,
        };
      });
    }
  };
  return (
    <OperationPresenter
      data={opertaionList}
      columns={columns}
      onChangePage={onChangePage}
      handleSort={handleSort}
      onClickSearch={onClickSearch}
      paginationInfo={operationLogQueryInfo}
    />
  );
}
