import { useCallback, useState } from "react";
import { BillingPaymentPresenter } from "./BillingPaymentPresenter";
import { Location, useLocation, useNavigate } from "react-router-dom";
import { useQuery } from "react-query";
import {
  getLicenseBilling,
  getLicenseBillingCommon,
} from "api/licenseAPI";
import {
  BillingCommon,
  BillingLicenses,
  GetBilling,
} from "api/interfaces/licenseInterface.interface";
import { monthOptions, yearOptions } from "utils/options";
import { notify } from "components/atoms/notification/Notification";
import { useIntl } from "react-intl";
import useApiError from "hook/useApiError";

export interface Date {
  [key: string]: string;
}

export default function BillingPaymentContainer(): JSX.Element {
  const intl = useIntl();
  const location:Location = useLocation();
  const navigate = useNavigate();
  const { handleError } = useApiError();
  
  const accountId = location.state.accountId;

  const [date, setDate] = useState<Date>({
    year: String(new Date().getFullYear()),
    month: String(new Date().getMonth() + 1),
  });
  const [sortBy, setSortBy] = useState<number>(0);
  const [isCollapsed, setIsCollapsed] = useState<boolean>(false);
  const [commonData, setCommonData] = useState<BillingCommon>({
    company: {
      companyLogo: "",
      name: "",
      address: "",
    },
    licensing: {
      issued: "",
      dueDate: "",
      accountNumber: "",
      mydwNumber: "",
    },
    contactInfo: {
      email: "",
      name: "",
      phone: "",
      address: "",
    },
    customers: [
      {
        po: "",
        payment: "",
      },
    ],
  });
  const [data, setData] = useState<GetBilling>({});
  const [pending, setPending] = useState<boolean>(true);

  

  const onChangeDate = (e: any) => {
    let {
      target: { name, value },
    } = e;

    setDate({
      ...date,
      [name]: value,
    });
  };

  const onMoveDate = useCallback(
    (name: "year" | "month", direction: "right" | "left") => {
      if (direction === "right") {
        if (
          name === "month" &&
          date.month === monthOptions[monthOptions.length - 1].value
        )
          return;

        if (name === "year" && date.year === yearOptions()[0].value) return;

        setDate({
          ...date,
          [name]: String(Number(date[name]) + 1),
        });
      }
      if (direction === "left") {
        if (name === "month" && date.month === monthOptions[0].value) return;

        if (
          name === "year" &&
          date.year === yearOptions()[yearOptions().length - 1].value
        )
          return;
        setDate({
          ...date,
          [name]: String(Number(date[name]) - 1),
        });
        return;
      }
    },
    [date]
  );

  const onChangeSort = (type: number) => {
    setSortBy(type);
  };

  const onDetail = (detailAccountId: string) => {
    if (
      detailAccountId === undefined ||
      detailAccountId === null ||
      detailAccountId === ""
    ) {
      notify("error", intl.formatMessage({
        id: "label.billing.notify.search.account.fail",
        defaultMessage: "Organization detail search error.",
      }));
      return;
    } else {
      navigate("/billing/payment/detail", {
        state: {
          accountId: accountId,
          detailAccountId: detailAccountId,
        },
      });
    }
  };

  const onCollapsePartItem = (idx: number) => {
    if (data.licenses) {
      if (idx === -1) {
        setIsCollapsed(!isCollapsed);
        setData({
          ...data,
          licenses: data.licenses.map((license) => {
            return { ...license, isCollapsed: !license.isCollapsed };
          }),
        });
      } else {
        setData({
          ...data,
          licenses: data.licenses.map((license, index) => {
            if (idx === index) {
              return {
                ...license,
                isCollapsed: !license.isCollapsed,
              };
            } else return license;
          }),
        });
      }
    } else return;
  };

  const getBilling = useQuery(
    ["billing", accountId, date, sortBy],
    () => {
      const sortType = sortBy === 0 ? "PART" : "ORGANIZATION";
      return getLicenseBilling({
        accountId: accountId,
        payload: {
          year: date.year,
          month: date.month,
          sortType: sortType,
        },
      });
    },
    {
      retry: 0,
      refetchOnWindowFocus: false,
      onSuccess: (res: any) => {
        if (res.result) {
          if (sortBy === 0) {
            if (res.result.length > 0) {
              setData({
                ...res.result,
                licenses: res.result.licenses.map((r: BillingLicenses) => {
                  return {
                    ...r,
                    isCollapsed: true,
                  };
                }),
              });
            }

            return;
          }
          setData(res.result);
        }
      },
      onError: (e: any) => { },
      onSettled: (e:any) =>{
        setPending(false);
      }
    }
  );

  const getBillingCommon = useQuery(
    ["billing_common", accountId],
    () => {
      return getLicenseBillingCommon({
        accountId: accountId,
      });
    },
    {
      retry: 0,
      refetchOnWindowFocus: false,
      onSuccess: (res: any) => {
        if (res.result) {
          // console.log(res.result, "getBillingCommon");
          setCommonData(res.result);
        }
      },
      onError: (e: any) => {},
      onSettled: (e:any) =>{
        setPending(false);
      }
    }
  );

  return (
    <BillingPaymentPresenter
      accountId={accountId}
      sortBy={sortBy}
      data={data}
      date={date}
      commonData={commonData}
      isCollapsed={isCollapsed}
      onChangeSort={onChangeSort}
      onChangeDate={onChangeDate}
      onMoveDate={onMoveDate}
      onDetail={onDetail}
      onCollapsePartItem={onCollapsePartItem}
      initialPending={pending}
    />
  );
}
