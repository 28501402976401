import { Text } from "components/atoms/text/Text";
import styled from "styled-components";
import { COLORS } from "styles/colors";
import { FLEX } from "styles/flex";
import { calcRem } from "styles/theme";
import * as mediaQuery from "components/MediaQuery";

export const Item = styled.div`
  width: 100%;
  background-color: #0c1724;
  border-radius: 6px;
  padding: 15px;
  height: 60%;
  /* flex: 3; */

  &:first-child {
    /* flex: 2; */
    height: 40%;
  }
`;

export const TitleWrapper = styled.div`
  ${FLEX.FlexBetweenCenter};
  border-bottom: 1px solid ${COLORS.BORDER};
  padding-bottom: 10px;
  width: 100%;
  height: 35px;
`;

export const FlexBox = styled.div`
  flex: 1;
`;

export const Title = styled(FlexBox)`
  ${FLEX.FlexStartCenter};
  gap: 15px;
`;

export const ChartContainer = styled.div`
  width: 50%;
  padding: 20px;
`;

// Alerts

export const FeedWrapper = styled.ul<{
  titleHeight?: number;
  noScroll?: boolean;
}>`
  width: 100%;
  height: 100%;

  ${({ noScroll }) =>
    !noScroll &&
    `
      overflow-y: auto;
    `};
`;

export const Scroll = styled.ul`
  width: 100%;
  height: 100%;
`;

export const LivefeedScroll = styled(Scroll)`
  display: grid;
  height: max-content;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px;
`;

export const LivefeedWrapper = styled(LivefeedScroll)<{
  columns: number;
}>`
  /* flex: 2; */
  grid-template-columns: repeat(${({ columns }) => columns}, 1fr);
  grid-template-rows: repeat(2, 1fr);
  padding: 10px 0;

  ${mediaQuery.isTablet} {
    grid-template-columns: repeat(2, 1fr);
  }
`;

export const AlertItem = styled.li`
  padding: 15px 0;
  border-bottom: 1px solid ${COLORS.BORDER};

  &:last-child {
    border-width: 0;
  }
`;

export const AlertTitle = styled.div`
  ${FLEX.FlexStartCenter};
  gap: 15px;
  padding-bottom: 15px;
`;

export const AlertContents = styled.div`
  ${FLEX.FlexBetweenCenter};
  height: 60px;
  gap: 10px;

  > img {
    flex: 1;
    height: 100%;
    background-color: white;
  }
`;
export const AlertDesc = styled.div`
  flex: 3;
  height: 100%;
  ${FLEX.FlexStartStart};
  gap: 4px;
  flex-direction: column;
`;

export const AlertStatus = styled.span`
  ${FLEX.FlexBetweenCenter}
  padding: 3px 14px;
  width: 90%;
  background-color: ${COLORS.BUTTONS1};
  position: relative;

  div {
    z-index: 1;
  }
`;

export const AlertStatusWrapper = styled.div`
  ${FLEX.FlexBetweenCenter};
  gap: 10px;
  width: 100%;
`;

export const AlertStatusBar = styled.span<{
  bgColor: string;
  percentage: number;
}>`
  width: ${({ percentage }) => `${percentage}%`};
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: ${({ bgColor }) => bgColor};
`;

export const IconWrapper = styled.ul`
  ${FLEX.FlexStartCenter};
  gap: 8px;
  /* flex: 1; */

  button {
    position: relative;
  }
  button > img {
    height: 14px;
  }
`;

//live feed

export const LivefeedItem = styled.li`
  background-color: ${({ theme }) => theme.COLORS.PRIMARY2};
  white-space: nowrap;
  overflow-x: hidden;
  max-width: 100%;
  width: 100%;
  box-sizing: border-box;
  text-overflow: ellipsis;
`;

export const LivefeedImage = styled.div<{ columns: number }>`
  width: 100%;
  /* ${({ columns }) => (columns >= 4 ? `height: 80px` : `height: 110px`)}; */
  /* background-size: cover; */
`;

export const LivefeedColumn = styled.div`
  ${FLEX.FlexStartStart};
  flex-direction: column;
  gap: 6px;
  flex: 1;
  width: 100%;
  overflow: hidden;
`;

export const LiveRow = styled.div`
  ${FLEX.FlexStartStart};
  gap: 6px;
  padding: 6px;
  width: 100%;
`;

export const Circle = styled.p<{ status: boolean }>`
  width: 10px;
  height: 10px;
  border-radius: 50%;

  background-color: ${({ status }) => (status ? COLORS.GREEN : COLORS.RED)};
  /* flex: 1; */
`;

export const Texts = styled(Text)``;

export const OptionMenu = styled.button`
  font-size: ${calcRem(14)};
  color: ${COLORS.BLACK};
  padding-bottom: 5px;
`;

export const OptionInner = styled.div`
  ${FLEX.FlexStartStart};
  flex-direction: column;
  padding: 5px 0;

  .title {
    padding-bottom: 5px;
  }

  .hide {
    ${FLEX.FlexStartCenter};
    gap: 3px;
  }
`;

export const Search = styled.div`
  max-width: 180px;
  input {
    padding: 6px;
  }
`;
