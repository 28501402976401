import styled from "styled-components";
import { COLORS, MAP_STATUS_COLORS } from "styles/colors";
import { FLEX } from "styles/flex";
import { calcRem } from "styles/theme";

export const Row = styled.div`
  ${FLEX.FlexStartCenter};
  gap: 15px;
`;
export const NameLabel = styled(Row)`
  gap: 8px;
  cursor: pointer;
`;

export const ButtonWrapper = styled(Row)<{ disabled?: boolean }>`
  ${({ disabled }) =>
    disabled &&
    `
    opacity: 0.25;
  `}
`;

export const LicenseLabel = styled(Row)<{ isRequested?: boolean }>`
  gap: 4px;
  font-size: ${calcRem(12)};
  white-space: nowrap;
  color: ${({ isRequested, theme }) =>
    isRequested ? theme.COLORS.SELECTED : COLORS.BUTTONS2};
`;

export const SuspendLabel = styled(LicenseLabel)<{ isCritical: boolean }>`
  color: ${({ isCritical }) =>
    isCritical ? COLORS.RED : MAP_STATUS_COLORS.WARNING};
  > img {
    width: 15px;
  }
`;
