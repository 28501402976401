import { ReportTable } from "components/atoms/table/Table";
import {
  RecorderHealthDetailRow,
  RecorderHealthRow,
} from "api/interfaces/reportInterface.interface";
import { Media, TableColumn } from "react-data-table-component";
import TableButton from "components/atoms/buttons/TableButton";
import {
  ReportHealthDetailRecorder,
  ReportHealthDetailRecorderAlert,
} from "api/interfaces/reportInterface.interface";
import { useAppSelector } from "redux/hooks";
import { ProfileAccountInfo } from "api/interfaces/accountInterface.interface";
import { getRecorderTimeFormat } from "utils/timeUtil";
import AlertDetailModal from "components/templates/modal/alert";
import { useState } from "react";
import { SelectedAlertView } from "api/interfaces/alertInterface.interface";
import {
  changeAlertReasonToCategory,
  filteringDiskAlertBoolean,
} from "utils/AlertUtil";
import { RecorderDetail } from "../../HealthDetail";

type Props = {
  data: ReportHealthDetailRecorder;
  recorderInfo: RecorderDetail;
};

export default function Health({ data, recorderInfo }: Props): JSX.Element {
  const selectedAccount: ProfileAccountInfo = useAppSelector(
    (state) => state.accountSettings
  );

  const [isModal, setIsModal] = useState<boolean>(false);
  const onModal = () => {
    setIsModal(!isModal);
  };

  const columns: TableColumn<ReportHealthDetailRecorderAlert>[] = [
    {
      name: "Type",
      grow: 1,
      selector: (row) => row.type,
    },
    {
      name: "Alert",
      grow: 1.5,
      selector: (row) =>
        row.alertReason !== "Video Loss"
          ? row.alertReason
          : `${row.alertReason} [${row.cameraName}]`,
      // ? `${row.alertReason} [${row.cameraName}]`
      // : `${row.alertReason} [CH${row.cameraChannel + 1})]`,
    },
    {
      name: "Status",
      grow: 1,
      selector: (row) => row.status,
    },
    {
      id: "eventDate",
      name: "Event Time",
      grow: 2,
      selector: (row) =>
        row.eventDate !== undefined
          ? getRecorderTimeFormat(
              row.eventDate,
              selectedAccount,
              row.timezone,
              true
            )
          : "",
      hide: Media.SM,
    },
    {
      name: "Alert Trigger Time",
      grow: 2,
      selector: (row) =>
        row.alertTrigger !== undefined
          ? getRecorderTimeFormat(
              row.alertTrigger,
              selectedAccount,
              row.timezone,
              true
            )
          : "",
      hide: Media.SM,
    },
    {
      name: "Rule",
      grow: 1.5,
      selector: (row) => row.alertName,
      hide: Media.SM,
    },
    // 마지막 버튼
    {
      name: "",
      maxWidth: "80px",
      cell: (row) => (
        <TableButton label="View" onClickBtn={() => onRowClick(row)} />
      ),
      right: true,
    },
  ];

  const [selectedAlert, setSelectedAlert] = useState<SelectedAlertView>();

  const onRowClick = (row: ReportHealthDetailRecorderAlert) => {
    setIsModal(true);
    setSelectedAlert({
      type: recorderInfo.type,
      recorderId: recorderInfo.recorderId,
      recorderName: recorderInfo.recorderName,
      mergedSystemName: recorderInfo.mergedSystemName,
      mergedSystemId: recorderInfo.mergedSystemId,
      alertType: row.alertReason,
      camera:
        row.alertReason === "Video Loss"
          ? {
              channel: row.cameraChannel,
              cameraId: row.cameraId,
              cameraName: row.cameraName,
            }
          : undefined,
      storageName: filteringDiskAlertBoolean({ type: row.alertReason })
        ? row.storageName
        : undefined,
      category: changeAlertReasonToCategory(row.alertReason),
    });
  };
  return (
    <>
      <ReportTable
        data={data.alerts}
        columns={columns}
        paginationPerPage={10}
        onRowClicked={onRowClick}
      />
      {isModal && selectedAlert !== undefined && (
        <AlertDetailModal onModal={onModal} selectedAlert={selectedAlert} />
      )}
    </>
  );
}
