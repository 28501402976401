import { ReportHealthDetailCamera } from "api/interfaces/reportInterface.interface";
import * as s from "../CommonStyled";
import LivefeedItem from "./LivefeedItem";

type DualProps = {
  titleHeight?: number;
  columns: number;
  noScroll?: boolean;
  // data: DualModeLivefeedType;
  data?: ReportHealthDetailCamera;
};

const Livefeed = (props: DualProps): JSX.Element => {
  // camera 시동 상태 색 변환 필요할 시 사용
  // const statusToFontColor = (status: string) => {
  //   if (status === "resolved") return COLORS.PRIMARY2;
  //   else return COLORS.WHITE;
  // };
  return (
    <s.FeedWrapper
      titleHeight={props.titleHeight}
      noScroll={props.noScroll ? props.noScroll : false}
    >
      {/* 한 페이지 당 8개씩 pagination */}
      {
        props.data?.cameras !== undefined && (
          <s.LivefeedWrapper columns={props.columns}>
            {props.data.cameras.map((data, index) => (
              <LivefeedItem key={index} data={data} columns={props.columns} />
            ))}
          </s.LivefeedWrapper>
        )
        // :(
        //   <s.LivefeedWrapper columns={props.columns}>
        //     {/* {livefeedData().map((data, index) => (
        //       <LivefeedItem key={index} data={data} columns={props.columns} />
        //     ))} */}
        //   </s.LivefeedWrapper>
        // )
      }
    </s.FeedWrapper>
  );
};

export default Livefeed;
