import { useCallback, useEffect, useState } from "react";
import ThumbnailPresenter from "./ThumbnailPresenter";
import {
  DateTime,
  ReportDataRow,
  ThumbnailData,
  ThumbnailFilterQueryString,
  ThumbnailFilterViewItem,
  ThumbnailQueryString,
  ThumbnailRecorderList,
  ThumbnailViewItem,
} from "api/interfaces/reportInterface.interface";
import { useQuery, useQueryClient } from "react-query";
import {
  getReportInfoThumbnail,
  getThumbnailCameraList,
  getThumbnailDateList,
  getThumbnailDateTime,
  getThumbnailFilterView,
  getThumbnailRecorderList,
  getThumbnailView,
} from "api/reportAPI";
import {
  BasePagination,
  SORT_DIRECTION,
} from "api/interfaces/commonInterface.interface";
import { useAppSelector } from "redux/hooks";
import { ProfileAccountInfo } from "api/interfaces/accountInterface.interface";
import { Option } from "utils/options";
import { convertDateToString, convertDateToStringOrigin } from "utils/timeUtil";
import { getStorageUsage } from "api/storageAPI";
import { StorageProps } from "components/blocks/storage/StorageLabel";
import { useLocation, useParams } from "react-router-dom";
import { useIntl } from "react-intl";

type Props = {
  state: ReportDataRow | undefined;
};

export default function ThumbnailContainer(props: Props): JSX.Element {
  const intl = useIntl();
  const queryClient = useQueryClient();
  const selectedAccount: ProfileAccountInfo = useAppSelector(
    (state) => state.accountSettings
  );

  const location = useLocation();
  const params = useParams();
  // 필터 적용했는지 확인하는 함수
  const [isFilterApply, setIsFilterApply] = useState<boolean>(false);
  // 필터 open함수
  const [isOpenFilter, setIsOpenFilter] = useState(false);
  const [isModal, setIsModal] = useState(false);

  // const titleTemp =
  //   location.state !== null
  //     ? location.state.title
  //     : props.state
  //     ? props.state.title
  //     : "";
  // const idTemp =
  //   location.state !== null
  //     ? location.state.id
  //     : props.state
  //     ? props.state.id
  //     : "";
  // const [id, setId] = useState<string>(idTemp);
  // const [title, setTitle] = useState<string>(titleTemp);
  const reportId = params.reportId as string;
  const [reportTitle, setReportTitle] = useState<string>("");


  const [dateTime, setDateTime] = useState<DateTime[]>([]);
  const [selectedDate, setSelectedDate] = useState<string>("");
  const [selectedTimeOption, setSelectedTimeOption] = useState<Option[]>([]);
  const [selectedTime, setSelectedTime] = useState<string>("");
  const [markedDay, setMarkedDay] = useState<string[]>([]);
  const [modalData, setModalData] = useState<ThumbnailViewItem>();

  // useEffect(()=>{
  //   if(location.state){
  //     if(location.state.id){
  //       setId(location.state.id);
  //     }
  //     if(location.state.title){
  //       setTitle(location.state.title);
  //     }
  //   }
  // },[location])
  const onChangeOpenFilter = () => {
    setIsOpenFilter(!isOpenFilter);
  };

  const onChangeModal = (
    popupData?: ThumbnailData,
    recorderName?: string,
    cameraName?: string
  ) => {
    setIsOpenFilter(false);
    if (popupData && recorderName && cameraName) {
      setModalData({
        id: popupData.id,
        timestamp: popupData.timestamp,
        timezone: popupData.timezone,
        url: popupData.url,
        cameraName: cameraName,
        recorderName: recorderName,
        errorType: popupData.errorType,
      });
    }
    setIsModal(!isModal);
  };

  // filter 적용해제
  const onChangeIsFilter = () => {
    setIsFilterApply(!isFilterApply);
  };

  const reporThumbnailDetailInfo = useQuery(
    ["reportCvvDetailInfo", reportId],
    () =>
      getReportInfoThumbnail({
        accountId: selectedAccount.accountId,
        reportId: reportId,
      }),
    {
      retry: 0,
      refetchOnWindowFocus: false,
      onSuccess: (res: any) => {
        if (res.result !== undefined && res.error === 0) {
          setReportTitle(res.result.preferences?.title);
        }else{
          setReportTitle("N/A")
        }
      },
      onError: (e: any) => {
        setReportTitle("N/A")
      }
    }
  );

  const [storageUsage, setStorageUsage] = useState<StorageProps>({
    totalStorage: 0,
    usedStorage: 0,
  });
  const storageQuery = useQuery(
    [" storageStatus", selectedAccount.accountId],
    () =>
      getStorageUsage({
        accountId: selectedAccount.accountId,
      }),
    {
      retry: 0,
      refetchOnWindowFocus: false,
      onSuccess: (res: any) => {
        console.log(res);
        if (res.error === 0 && res.result !== undefined) {
          setStorageUsage({
            totalStorage: res.result.totalStorage,
            usedStorage: res.result.usedStorage,
          });
        }
      },
      onError: (e: any) => {
        console.log(e, "error");
      },
    }
  );

  const requestThumbnailDateTime = useQuery(
    ["requestThumbnailDateTime", reportId],
    () =>
      getThumbnailDateTime({
        reportId: reportId,
      }),
    {
      retry: 0,
      enabled: !isOpenFilter && !isFilterApply && reportId !== undefined,
      refetchOnWindowFocus: false,
      onSuccess: (res: any) => {
        if (res.error !== 0) {
          setDateTime([]);
          setSelectedDate(convertDateToString(new Date()));
          setSelectedTimeOption([]);
          return;
        }

        if (res.result !== undefined && res.result.length > 0) {
          setDateTime(res.result);
          const sortedResult = res.result.sort(function (a: any, b: any) {
            return new Date(b.date).getTime() - new Date(a.date).getTime();
          });
          setDateTime(sortedResult);
          setSelectedDate(sortedResult[0].date);
          setSelectedTime(
            sortedResult[0].time[sortedResult[0].time.length - 1]
          );
          setSelectedTimeOption(
            sortedResult[0].time
              .map((time: any) => {
                return {
                  label: time,
                  value: time,
                };
              })
              .sort((a: Option, b: Option) => a.value.localeCompare(b.value))
          );
          setMarkedDay(sortedResult.map((data: any) => data.date));
          if (
            sortedResult[0].time !== undefined &&
            sortedResult[0].time.length > 0
          ) {
            setThumbnailQueryInfo((info) => {
              return {
                ...info,
                type: "search",
                date: sortedResult[0].date,
                time: sortedResult[0].time[sortedResult[0].time.length - 1],
              };
            });
          }
        } else {
          setDateTime([]);
          setSelectedDate(convertDateToString(new Date()));
          setSelectedTimeOption([]);
        }
      },
      onError: (e: any) => {
        setDateTime([]);
      },
    }
  );

  const [thumbnailList, setThumbnailList] = useState<ThumbnailViewItem[]>([]);
  const [listTotalCount, setListTotalCount] = useState<number>(0);
  const [thumbnailQueryInfo, setThumbnailQueryInfo] =
    useState<ThumbnailQueryString>({
      total: 0,
      pageNum: 0,
      pageLimit: 21,
      keyword: "",
      sortType: "",
      sortDirection: SORT_DIRECTION.ASC,
      date: "",
      time: "",
    });

  const requestThumbnailData = useQuery(
    ["requestThumbnailData", thumbnailQueryInfo],
    () =>
      getThumbnailView({
        reportId: reportId,
        payload: thumbnailQueryInfo,
      }),
    {
      retry: 0,
      enabled: thumbnailQueryInfo.time !== "" && !isFilterApply && reportId !== undefined,
      refetchOnWindowFocus: false,
      onSuccess: (res: any) => {
        if (res.error !== 0 || res.result === undefined) {
          setThumbnailList([]);
          setListTotalCount(0);
          setThumbnailQueryInfo((info) => {
            return { ...info, total: 0, pageNum: 0 };
          });
          return;
        }
        setThumbnailList(res.result);
        setListTotalCount(res.page.total);
        setThumbnailQueryInfo((info) => {
          return {
            ...info,
            total: res.page.total as number,
            pageNum: res.page.pageNum as number,
          };
        });
      },
      onError: (e: any) => {
        setThumbnailList([]);
        setListTotalCount(0);
        setThumbnailQueryInfo((info) => {
          return { ...info, total: 0, pageNum: 0 };
        });
      },
    }
  );

  const onChangeSearchTime = useCallback(
    (time: string) => {
      setThumbnailQueryInfo((info) => {
        return {
          ...info,
          date: selectedDate,
          time: time,
        };
      });
      setSelectedTime(time);
    },
    [selectedDate]
  );

  useEffect(() => {
    setThumbnailQueryInfo((info) => {
      return {
        ...info,
        date: selectedDate,
        time: selectedTime,
      };
    });
  }, [selectedTime, selectedDate]);

  const onChangeSearchDate = useCallback(
    (date: Date) => {
      if (isFilterApply) {
        return;
      }
      //BUG [RND-409] 날짜포맷변경(UTC로 변경되니 Locale로 바로추출)
      const selectDate = convertDateToStringOrigin(date);

      setSelectedDate(selectDate);
      const findTimes = dateTime.find((data) => data.date === selectDate);

      if (findTimes) {
        let sortTimes = findTimes.time
          .map((time) => {
            return {
              label: time,
              value: time,
            };
          })
          .sort((a: Option, b: Option) => a.value.localeCompare(b.value));
        setSelectedTimeOption(sortTimes);
        setSelectedTime(sortTimes[0].value);
      } else {
        setSelectedTimeOption([]);
        setSelectedTime("");
      }
    },
    [dateTime]
  );

  const [thumbnailFilterList, setThumbnailFilterList] = useState<
    ThumbnailFilterViewItem[]
  >([]);
  const [thumbnailFilterQueryInfo, setThumbnailFilterQueryInfo] =
    useState<ThumbnailFilterQueryString>({
      total: 0,
      pageNum: 0,
      pageLimit: 10,
      keyword: "",
      sortType: "camera_id",
      sortDirection: SORT_DIRECTION.ASC,
      recorderId: "",
      cameraId: "",
      channel: 0,
      date: "",
    });
  const requestThumbnailFilterData = useQuery(
    ["requestThumbnailFilterData", thumbnailFilterQueryInfo],
    () =>
      getThumbnailFilterView({
        reportId: reportId,
        payload: thumbnailFilterQueryInfo,
      }),
    {
      retry: 0,
      enabled: thumbnailFilterQueryInfo.date !== "",
      refetchOnWindowFocus: false,
      onSuccess: (res: any) => {
        if (res.error !== 0 || res.result === undefined) {
          setThumbnailFilterList([]);
          setThumbnailFilterQueryInfo((info) => {
            return { ...info, total: 0 as number, pageNum: 0 };
          });
          return;
        }
        setThumbnailFilterList(res.result);
        setThumbnailFilterQueryInfo((info) => {
          return {
            ...info,
            total: res.page.total as number,
            pageNum: res.page.pageNum as number,
          };
        });
      },
      onError: (e: any) => {
        setThumbnailFilterList([]);
        setThumbnailFilterQueryInfo((info) => {
          return { ...info, total: 0 as number, pageNum: 0 };
        });
      },
    }
  );

  const [recorderList, setRecorderList] = useState<Option[]>([]);
  const [selectFilterRecorder, setSelectFilterRecorder] = useState<string>("");

  const requestRecorderList = useQuery(
    ["requestRecorderList"],
    () =>
      getThumbnailRecorderList({
        accountId: selectedAccount.accountId,
        reportId: reportId,
      }),
    {
      retry: 0,
      enabled: isOpenFilter,
      refetchOnWindowFocus: false,
      onSuccess: (res: any) => {
        if (res.error !== 0 || res.result === undefined) {
          setRecorderList([]);
          return;
        }
        setRecorderList(
          res.result.map((data: any) => {
            return {
              label: data.name,
              value: data.id,
            };
          })
        );
        if (selectFilterRecorder === undefined || selectFilterRecorder === "") {
          setSelectFilterRecorder(res.result[0].id);
        }
      },
      onError: (e: any) => {
        setRecorderList([]);
      },
    }
  );

  const [cameraList, setCameraList] = useState<Option[]>([]);
  const [selectFilterCamera, setSelectFilterCamera] = useState<string>("");
  const requestCameraList = useQuery(
    ["requestCameraList", selectFilterRecorder],
    () =>
      getThumbnailCameraList({
        accountId: selectedAccount.accountId,
        reportId: reportId,
        recorderId: selectFilterRecorder,
      }),
    {
      retry: 0,
      enabled: isOpenFilter && selectFilterRecorder !== undefined,
      refetchOnWindowFocus: false,
      onSuccess: (res: any) => {
        if (res.error !== 0 || res.result === undefined) {
          setCameraList([]);
          return;
        }
        let cameraList: Option[] = res.result.map((data: any) => {
          return {
            label: data.name,
            value: data.id + "/" + data.channel,
          };
        });
        cameraList = [
          {
            label: "ALL",
            value: "ALL/-1",
          },
          ...cameraList,
        ];
        setCameraList(cameraList);

        //BUG [RND-373]  camera select  bug
        if (selectFilterCamera === undefined || selectFilterCamera === "") {
          setSelectFilterCamera(cameraList[0].value);
        } else {
          const findCamera = cameraList.find((camera, index) => {
            return camera.value === selectFilterCamera;
          });
          if (findCamera === undefined) {
            setSelectFilterCamera(cameraList[0].value);
          }
        }
      },
      onError: (e: any) => {
        setCameraList([]);
      },
    }
  );

  const [filterDateList, setFilterDateList] = useState<string[]>([]);
  const [selectFilterDate, setSelectFilterDate] = useState<string>(
    convertDateToString(new Date())
  );
  const [validateFilterDate, setValidateFilterDate] = useState<boolean>(true);

  const requestFilterDateList = useQuery(
    ["requestFilterDateList", selectFilterCamera],
    () =>
      getThumbnailDateList({
        accountId: selectedAccount.accountId,
        reportId: reportId,
        recorderId: selectFilterRecorder,
        cameraId: selectFilterCamera,
      }),
    {
      retry: 0,
      enabled: isOpenFilter && selectFilterCamera !== undefined,
      refetchOnWindowFocus: false,
      onSuccess: (res: any) => {
        if (res.error !== 0 || res.result === undefined) {
          setFilterDateList([]);
          setSelectFilterDate(convertDateToString(new Date()));
          setValidateFilterDate(false);
          return;
        }
        setFilterDateList(res.result);
        setValidateFilterDate(true);
        if (selectFilterDate === undefined || selectFilterDate === "") {
          setSelectFilterDate(res.result[0]);
        }
      },
      onError: (e: any) => {
        setFilterDateList([]);
        setSelectFilterDate(convertDateToString(new Date()));
        setValidateFilterDate(false);
      },
    }
  );

  const onSearch = useCallback(() => {
    setIsOpenFilter(false);
    setIsFilterApply(true);
    const splitCamera: number = selectFilterCamera.lastIndexOf("/");
    let cameraChannel = -1;
    try {
      cameraChannel = Number(selectFilterCamera.substring(splitCamera + 1));
    } catch {}

    const channel = selectFilterCamera.substring(splitCamera + 1);

    setThumbnailFilterQueryInfo((info) => {
      return {
        ...info,
        pageNum : 0,
        recorderId: selectFilterRecorder,
        cameraId:
          splitCamera !== -1
            ? selectFilterCamera.substring(0, splitCamera)
            : selectFilterCamera,
        channel: cameraChannel,
        date: selectFilterDate,
      };
    });
    queryClient.invalidateQueries("requestThumbnailFilterData");
  }, [selectFilterCamera, selectFilterDate, selectFilterRecorder]);

  const onChangeFilterRecorder = useCallback((value: string) => {
    setSelectFilterRecorder(value);
  }, []);

  const onChangeFilterCamera = useCallback((value: string) => {
    setSelectFilterCamera(value);
  }, []);

  const onChangeFilterDate = useCallback((value: Date) => {
    setSelectFilterDate(convertDateToString(value));
  }, []);

  const onChangePage = useCallback(
    (page: number, totalRows: number) => {
      if (page !== thumbnailQueryInfo.pageNum + 1) {
        setThumbnailQueryInfo((info) => {
          return { ...info, pageNum: (page - 1) as number };
        });
      }
    },
    [thumbnailQueryInfo.pageNum]
  );

  const onChangePageFilterTable = useCallback(
    (page: number, totalRows: number) => {
      if (page !== thumbnailFilterQueryInfo.pageNum + 1) {
        setThumbnailFilterQueryInfo((info) => {
          return { ...info, pageNum: (page - 1) as number };
        });
      }
    },
    [thumbnailFilterQueryInfo.pageNum]
  );

  // useEffect(() => {
  //   console.log(isFilterApply);
  // }, [isFilterApply]);

  return (
    <ThumbnailPresenter
      title={reportTitle}
      dateTime={dateTime}
      listQueryInfo={thumbnailQueryInfo as BasePagination}
      thumbnailFilterQueryInfo={thumbnailFilterQueryInfo as BasePagination}
      recorderList={recorderList}
      cameraList={cameraList}
      filterDateList={filterDateList}
      isOpenFilter={isOpenFilter}
      onChangeOpenFilter={onChangeOpenFilter}
      onChangeModal={onChangeModal}
      onChangeIsFilter={onChangeIsFilter}
      onChangeSearchTime={onChangeSearchTime}
      onChangeSearchDate={onChangeSearchDate}
      onSearch={onSearch}
      isModal={isModal}
      isFilterApply={isFilterApply}
      onChangePage={onChangePage}
      onChangePageFilterTable={onChangePageFilterTable}
      selectFilterRecorder={selectFilterRecorder}
      selectFilterCamera={selectFilterCamera}
      onChangeFilterRecorder={onChangeFilterRecorder}
      onChangeFilterCamera={onChangeFilterCamera}
      onChangeFilterDate={onChangeFilterDate}
      listTotalCount={listTotalCount}
      selectedDate={selectedDate}
      selectedTime={selectedTime}
      selectFilterDate={selectFilterDate}
      selectedTimeOption={selectedTimeOption}
      thumbnailList={thumbnailList}
      thumbnailFilterList={thumbnailFilterList}
      markedDay={markedDay}
      selectedAccount={selectedAccount}
      modalData={modalData}
      validateFilterDate={validateFilterDate}
      storageUsage={storageUsage}
    />
  );
}
