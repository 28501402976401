import { ProfileAccountInfo } from "api/interfaces/accountInterface.interface";
import spacetime, { Spacetime } from "spacetime";
import soft from "timezone-soft";

import { Option } from "./options";
import allTimezones from "./timezone-list";
import moment, { Moment } from "moment";
import { Interval } from "api/interfaces/reportInterface.interface";
import { isEmpty, trim } from "lodash";
import { isNumber } from "./functions";

export interface ITimezoneOption {
  value: string;
  label: string;
  abbrev?: string;
  offset?: number;
}

interface timezoneOptions extends Option {
  offset: number;
}

export type ITimezone = ITimezoneOption | string;

// export const transeCalendarPickDay = (
//   date: Date,
//   timezone: string | undefined
// ) => {
//   return spacetime(date, timezone).toNativeDate();
// };

// export const convertDateString = (
//   dateStr: string,
//   info: ProfileAccountInfo,
//   zone?: string
// ) => {
//   let resultDate = "";
//   try {
//     const dd = spacetime(dateStr);
//     resultDate = dd.unixFmt(`${info.dateFormat} ${info.timeFormat}`);
//     if (zone) {
//       const tzStrings = soft(zone);
//       if (tzStrings === undefined || tzStrings.length === 0) {
//         resultDate = resultDate + ` ${zone}`;
//       } else {
//         const zoneTime = spacetime(dateStr, tzStrings[0].iana);
//         resultDate =
//           resultDate + ` ${getTimezoneDisplay(zoneTime, tzStrings[0].iana)}`;
//       }
//     }
//   } catch (error) {}
//   return resultDate;
// };
// export const getTodayTimeZoneDate = (timezone: string | undefined) => {
//   const date =
//     timezone !== undefined ? spacetime.now(timezone) : spacetime.now();
//   return date.toNativeDate();
// };

export const getTimeZoneOptions = () => {
  return Object.entries(allTimezones)
    .reduce<timezoneOptions[]>((selectOptions, zone) => {
      const now = spacetime.now(zone[0]);
      const tz = now.timezone();
      const tzStrings = soft(zone[0]);

      let label = "";
      let abbr = now.isDST()
        ? tzStrings[0].daylight?.abbr
        : tzStrings[0].standard?.abbr;

      const min = tz.current.offset * 60;
      const hr =
        `${(min / 60) ^ 0}:` + (min % 60 === 0 ? "00" : Math.abs(min % 60));
      const prefix = `(GMT${hr.includes("-") ? hr : `+${hr}`}) ${zone[1]}`;

      label = `${prefix} ${abbr?.length < 5 ? `(${abbr})` : ""}`;

      selectOptions.push({
        value: tz.name,
        label: label,
        offset: tz.current.offset,
      });

      return selectOptions;
    }, [])
    .sort((a: timezoneOptions, b: timezoneOptions) => a.offset - b.offset);
};

const getTimezoneDisplay = (now: Spacetime, timezone: string) => {
  if (timezone === undefined || isEmpty(timezone)) {
    return "";
  }
  const tzStrings = soft(timezone);

  let abbr = now.timezone().current.isDST
    ? tzStrings[0].daylight?.abbr
    : tzStrings[0].standard?.abbr;

  return abbr;
};

export const getTimezoneAbbr = (timezone: string) => {
  if (timezone === undefined || isEmpty(timezone)) {
    return "";
  }
  const tzStrings = soft(timezone);
  const now = spacetime.now(timezone);

  let abbr = now.isDST()
    ? tzStrings[0].daylight?.abbr
    : tzStrings[0].standard?.abbr;

  return abbr;
};

export const getAccountTimeDateFormat = (
  time: string,
  info: ProfileAccountInfo
) => {
  let d;
  if (time.includes("+")) {
    d = spacetime(time);
  } else {
    d = spacetime(time + "Z");
  }
  try {
    d = d.goto(info.timezone);
  } catch {}

  return d.toNativeDate();
};
// TODO Account정보에 타임존및 Format정보수정 후 인자값 변경.
export const getAccountTimeFormat = (
  time: string,
  info: ProfileAccountInfo,
  displayZone?: boolean
) => {
  let d;
  if (time === undefined) {
    return "";
  }
  if (time.includes("+")) {
    d = spacetime(time);
  } else {
    d = spacetime(time + "Z");
  }
  try {
    d = d.goto(info.timezone);
  } catch {}

  return displayZone !== undefined
    ? d.unixFmt(`${info.dateFormat} ${info.timeFormat}`) +
        ` ${getTimezoneDisplay(d, info.timezone)}`
    : d.unixFmt(`${info.dateFormat} ${info.timeFormat}`);
  // return displayZone !== undefined ?  d.unixFmt(`${info.dateFormat} ${info.timeFormat} Z`) : d.unixFmt(`${info.dateFormat} ${info.timeFormat}`);
};

export const getAccountTimeFormatDate = (
  time: string,
  info: ProfileAccountInfo,
  displayZone?: boolean
) => {
  let d;
  if (time === undefined) {
    return "";
  }
  if (time.includes("+") || time.includes("Z")) {
    d = spacetime(time, info.timezone);
  } else if (time.length === 10 || time.length === 8) {
    //시분초가 있는 경우인데 UTC표식이 없는 경우
    d = spacetime(time, info.timezone);
  } else {
    d = spacetime(time + "Z", info.timezone);
  }
  try {
    d = d.goto(info.timezone);
  } catch {}
  return displayZone !== undefined
    ? d.unixFmt(`${info.dateFormat}`) +
        ` ${getTimezoneDisplay(d, info.timezone)}`
    : d.unixFmt(`${info.dateFormat}`);
  // return displayZone !== undefined ?  d.unixFmt(`${info.dateFormat} ${info.timeFormat} Z`) : d.unixFmt(`${info.dateFormat} ${info.timeFormat}`);
};

export const getRecorderTimeFormat = (
  time: string,
  info: ProfileAccountInfo,
  timezone: string,
  displayZone?: boolean,
  isThumbnail?: boolean
) => {
  let d;
  let convertTimezone;

  if (time === undefined) {
    return "";
  }

  const tzStrings = soft(timezone);
  if (tzStrings !== undefined && tzStrings.length !== 0) {
    timezone = tzStrings[0].iana;
  } else {
    if (timezone.includes(",")) {
      const tzStrings2 = soft(timezone.split(`,`)[0]);
      if (tzStrings2 !== undefined && tzStrings2.length !== 0) {
        timezone = tzStrings2[0].iana;
      } else {
        timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      }
    } else {
      timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    }
  }

  if (time.includes("+") || time.includes("Z")) {
    d = spacetime(time);
  } else {
    d = spacetime(time, timezone);
  }

  try {
    if (!isThumbnail) {     
      d = d.goto(timezone);
    }else{
      const format = d.unixFmt(`yyyy-MM-dd'T'HH:mm:ss`);
      d = spacetime(format, timezone);
    }
  } catch {}

  return displayZone !== undefined
    ? d.unixFmt(`${info.dateFormat} ${info.timeFormat}`) +
        ` ${getTimezoneDisplay(d, timezone)}`
    : d.unixFmt(`${info.dateFormat} ${info.timeFormat}`);
  // return displayZone !== undefined ?  d.unixFmt(`${info.dateFormat} ${info.timeFormat} Z`) : d.unixFmt(`${info.dateFormat} ${info.timeFormat}`);
};

export const convertEpoch = (unit: number, info: ProfileAccountInfo) => {
  let displayTime = "";
  if (!unit) {
    return displayTime;
  }
  try {
    const d = spacetime(unit);
    displayTime = d.unixFmt(`${info.dateFormat} ${info.timeFormat}`);
  } catch {
    displayTime = "";
  }

  return displayTime;
};

export const getCurrentMilliSecond = () => {
  const now = spacetime.now();
  return now.epoch;
};

/**
 *
 * @param time string
 * @param info redux account info
 * @param interval daily | weekly | monthly
 * @returns
 */
export const getChartTime = (
  time: string,
  info: ProfileAccountInfo,
  interval: Interval
) => {
  let d;
  if (time === undefined) {
    return "";
  }
  if (time.includes("+")) {
    d = spacetime(time);
  } else {
    d = spacetime(time + "Z");
  }
  try {
    d = d.goto(info.timezone);
  } catch {}

  if (interval === "MONTHLY") {
    let temp = info.dateFormat.split("MM");
    let tempFormat = temp[0].concat("MM");

    return d.unixFmt("yyyy/MM");
  } else return d.unixFmt("yyyy/MM/dd");
};

const padTo2Digits = (num: number) => {
  return num.toString().padStart(2, "0");
};

export const convertMsToTime = (milliseconds: number) => {
  let seconds = Math.floor(milliseconds / 1000);
  let minutes = Math.floor(seconds / 60);
  let hours = Math.floor(minutes / 60);

  seconds = seconds % 60;
  minutes = minutes % 60;

  // 👇️ If you don't want to roll hours over, e.g. 24 to 00
  // 👇️ comment (or remove) the line below
  // commenting next line gets you `24:00:00` instead of `00:00:00`
  // or `36:15:31` instead of `12:15:31`, etc.
  hours = hours % 24;

  return `${padTo2Digits(hours)}:${padTo2Digits(minutes)}:${padTo2Digits(
    seconds
  )}`;
};

export const convertVideoStartMs = (
  startMs: string,
  timezone?: string,
  selectedAccount?: ProfileAccountInfo
) => {
  if (timezone === undefined || isEmpty(timezone)) {
    timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  } else {
    const tzStrings = soft(timezone);
    if (tzStrings !== undefined && tzStrings.length !== 0) {
      timezone = tzStrings[0].iana;
    } else {
      if (timezone.includes(",")) {
        const tzStrings2 = soft(timezone.split(`,`)[0]);
        if (tzStrings2 !== undefined && tzStrings2.length !== 0) {
          timezone = tzStrings2[0].iana;
        } else {
          timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        }
      } else {
        timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      }
    }
  }
  let timestamp = 0;
  if (startMs !== undefined && !isEmpty(startMs)) {
    try {
      timestamp = Number(startMs);
      if (startMs.length < 13) {
        timestamp = timestamp * 1000;
      }
    } catch (error) {}
  }
  const s = spacetime(Number(timestamp), timezone);
  return selectedAccount !== undefined
    ? s.unixFmt(`${selectedAccount.dateFormat} ${selectedAccount.timeFormat}`) +
        ` ${getTimezoneDisplay(s, timezone)}`
    : s.unixFmt("yyyy-MM-dd hh:mm a") + ` ${getTimezoneDisplay(s, timezone)}`;
};

export const convertVideoStartMsVtt = (
  startMs: string,
  timezone?: string,
  selectedAccount?: ProfileAccountInfo
) => {
  const removal = trim(startMs);
  if (timezone === undefined || isEmpty(timezone)) {
    timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  } else {
    const tzStrings = soft(timezone);
    if (tzStrings !== undefined && tzStrings.length !== 0) {
      timezone = tzStrings[0].iana;
    } else {
      if (timezone.includes(",")) {
        const tzStrings2 = soft(timezone.split(`,`)[0]);
        if (tzStrings2 !== undefined && tzStrings2.length !== 0) {
          timezone = tzStrings2[0].iana;
        } else {
          timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        }
      } else {
        timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      }
    }
  }

  const dateFormat = new Date(removal);
  const s = spacetime(dateFormat, timezone);
  return selectedAccount !== undefined
    ? s.unixFmt(`${selectedAccount.dateFormat} ${selectedAccount.timeFormat}`) +
        ` ${getTimezoneDisplay(s, timezone)}`
    : s.unixFmt("yyyy-MM-dd hh:mm a") + ` ${getTimezoneDisplay(s, timezone)}`;
};

export const secondsToDays = (seconds: number) => {
  var days = Math.floor(seconds / 60 / 60 / 24);

  return days;
};

export const secondsToTime = (seconds: number) => {
  var minutes = Math.floor(seconds / 60),
    hours = Math.floor(seconds / 60 / 60),
    days = secondsToDays(seconds);

  // console.log(seconds, minutes, hours, days, "secondsToTime");
  if (days >= 1) return `${days} DAY(S)`;
  if (days < 1 && hours >= 1) return `${hours} HOUR(S)`;
  if (hours < 1 && minutes >= 0) {
    if (minutes === 0) {
      return `1 MINUTE(S)`;
    } else {
      return `${minutes} MINUTE(S)`;
    }
  }
  return undefined;
};

export const convertDateToString = (date: Date) => {
  const d = spacetime(date);
  return d.unixFmt("yyyy-MM-dd");
};

export const changeTimeDaySelect = (date: Date, info: ProfileAccountInfo) => {
  const d = spacetime(date);
  return d.unixFmt(`${info.dateFormat}`);
};

export const convertDateToStringOrigin = (date: Date) => {
  if (date !== undefined) {
    const year = date.getFullYear(); // 2023
    const month = (date.getMonth() + 1).toString().padStart(2, "0"); // 06
    const day = date.getDate().toString().padStart(2, "0"); // 18
    const dateString = year + "-" + month + "-" + day; // 2023-06-18
    return dateString;
  } else {
    return "";
  }
};

export const generateTimeToFileName = () => {
  const date = new Date();
  const getYear = date.getFullYear();
  const getMonth = (date.getMonth() + 1).toString().padStart(2, "0");
  const getDay = date.getDate().toString().padStart(2, "0");
  const getHour = date.getHours().toString().padStart(2, "0");
  const getMin = date.getMinutes().toString().padStart(2, "0");
  const getSec = date.getSeconds().toString().padStart(2, "0");
  return getYear + getMonth + getDay + "_" + getHour + getMin + getSec;
};

export const generateEpochToFileName = (
  startMs: string | undefined,
  timezone: string
) => {
  if (startMs !== undefined && !isEmpty(startMs)) {
    if (isNumber(startMs)) {
      // const date = new Date(Number(startMs)/1000);
      // const getYear = date.getFullYear();
      // const getMonth = (date.getMonth() + 1).toString().padStart(2, "0");
      // const getDay = date.getDate().toString().padStart(2, "0");
      // const getHour = date.getHours().toString().padStart(2, "0");
      // const getMin = date.getMinutes().toString().padStart(2, "0");
      // const getSec = date.getSeconds().toString().padStart(2, "0");

      //RND-902 Time format of Download File fix(2-digit)
      if (timezone === undefined || isEmpty(timezone)) {
        timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      } else {
        const tzStrings = soft(timezone);
        if (tzStrings !== undefined && tzStrings.length !== 0) {
          timezone = tzStrings[0].iana;
        } else {
          if (timezone.includes(",")) {
            const tzStrings2 = soft(timezone.split(`,`)[0]);
            if (tzStrings2 !== undefined && tzStrings2.length !== 0) {
              timezone = tzStrings2[0].iana;
            } else {
              timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
            }
          } else {
            timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
          }
        }
      }

      const s = spacetime(Number(startMs), timezone);
      return (
        s.unixFmt("yyyyMMdd_HHmmss") + `_${getTimezoneDisplay(s, timezone)}`
      );
      //return getYear + getMonth + getDay + "_" + getHour + getMin + getSec;
    } else {
      const removal = trim(startMs);
      if (timezone === undefined || isEmpty(timezone)) {
        timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      } else {
        const tzStrings = soft(timezone);
        timezone = tzStrings[0].iana;
      }

      const dateFormat = new Date(removal);
      const s = spacetime(dateFormat, timezone);
      return (
        s.unixFmt("yyyyMMdd_HHmmss") + `_${getTimezoneDisplay(s, timezone)}`
      );
    }
  } else {
    const date = new Date();
    const getYear = date.getFullYear();
    const getMonth = (date.getMonth() + 1).toString().padStart(2, "0");
    const getDay = date.getDate().toString().padStart(2, "0");
    const getHour = date.getHours().toString().padStart(2, "0");
    const getMin = date.getMinutes().toString().padStart(2, "0");
    const getSec = date.getSeconds().toString().padStart(2, "0");
    return getYear + getMonth + getDay + "_" + getHour + getMin + getSec;
  }
};

export const getNextDayString = (days?: number) => {
  let today = new Date();
  if (days) {
    today.setDate(today.getDate() + days);
  } else {
    today.setDate(today.getDate() + 1);
  }
  return convertDateToStringOrigin(today);
};

export const getCookieExpireTime = (hour: number) => {
  var now = new Date();
  var time = now.getTime();
  var expireTime = time + hour * 60 * 60 * 1000;
  now.setTime(expireTime);
  return now;
};

export const excelDateFormat = () => {
  return moment(new Date()).format("MM-DD-YYYY-HH:mm:ss");
};

export const convertVideoShareTime = (date: string, timezone: string) => {
  if (date !== undefined) {
    if (timezone === undefined || isEmpty(timezone)) {
      timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    } else {
      const tzStrings = soft(timezone);
      if (tzStrings !== undefined && tzStrings.length !== 0) {
        timezone = tzStrings[0].iana;
      } else {
        if (timezone.includes(",")) {
          const tzStrings2 = soft(timezone.split(`,`)[0]);
          if (tzStrings2 !== undefined && tzStrings2.length !== 0) {
            timezone = tzStrings2[0].iana;
          } else {
            timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
          }
        } else {
          timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        }
      }
    }
    let settingDate = spacetime(date, timezone).goto(timezone);
    // const convertDate = settingDate.d;
    // convertDate.setFullYear(settingDate.year(), settingDate.month(),settingDate.date() );
    // convertDate.setHours(0);
    // convertDate.setMinutes(0);
    // convertDate.setSeconds(0);

    return settingDate.unixFmt("yyyy-MM-dd");
  } else {
    const convertDate = new Date();
    convertDate.setHours(0);
    convertDate.setMinutes(0);
    convertDate.setSeconds(0);
    return convertDateToStringOrigin(convertDate);
  }
};

export const setVideoShareExpire = (date: string, timezone: string) => {
  if (timezone === undefined || isEmpty(timezone)) {
    timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  } else {
    const tzStrings = soft(timezone);
    if (tzStrings !== undefined && tzStrings.length !== 0) {
      timezone = tzStrings[0].iana;
    } else {
      if (timezone.includes(",")) {
        const tzStrings2 = soft(timezone.split(`,`)[0]);
        if (tzStrings2 !== undefined && tzStrings2.length !== 0) {
          timezone = tzStrings2[0].iana;
        } else {
          timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        }
      } else {
        timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      }
    }
  }
  let settingDate = spacetime(date);
  settingDate.goto(timezone);
  settingDate = settingDate.hour(23);
  settingDate = settingDate.minute(59);
  settingDate = settingDate.second(59);
  settingDate = settingDate.millisecond(0);
  // convertDate.setFullYear(settingDate.year(), settingDate.month(),settingDate.date() );
  // convertDate.setHours(23);
  // convertDate.setMinutes(59);
  // convertDate.setSeconds(59);
  // convertDate.setMilliseconds(0);
  return settingDate.iso();
};
