import { CenterlineText } from "components/atoms/text/Text";
import styled from "styled-components";
import { COLORS } from "styles/colors";
import { FLEX } from "styles/flex";
import { calcRem } from "styles/theme";
import * as mediaQuery from "components/MediaQuery";

export const Container = styled.div`
  width: 100%;
  background-color: white;

  // @media all {
  //   .page-break {
  //     display: none;
  //   }
  // }

  // @media print {
  //   html, body {
  //     height: initial !important;
  //     overflow: initial !important;
  //     -webkit-print-color-adjust: exact;
  //   }
  // }

  // @media print {
  //   .page-break {
  //     margin-top: 1rem;
  //     display: block;
  //     page-break-before: always;
  //   }
  // }

  @media screen {
    display: none !important;
  }

  @media print {
    size: letter;
    .no-print {
      display: none !important;
    }
    @page {
      size: auto;
    }
  }

  .left-aside {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
  }

  .right-wrapper {
    display: grid;
    grid-template-columns: repeat(2, 1fr);

    padding-bottom: 10px;
    margin-bottom: 20px;
    border-bottom: 1px solid #828b9b;
  }

  .right-wrapper li {
    ${FLEX.FlexStartStart};
    padding: 8px 0;
    flex: 1;
    min-width: 0;

    .content {
      flex: 4;
      max-width: 100%;
      white-space: pre-wrap;
    }
    * {
      color: ${COLORS.BLACK};
    }

    gap: 6px;
    div {
      font-size: ${calcRem(14)};
    }
  }
`;

export const Header = styled.ul`
  ${FLEX.FlexCenterCenter};
  background-color: ${COLORS.PRIMARY1};
  padding: 30px;
  color: ${COLORS.WHITE};
  font-weight: 700;
  font-size: ${calcRem(24)};

  > span {
    color: ${COLORS.COMPANY};
    font-weight: 800;
    padding-left: 10px;
  }
`;

export const Inner = styled.div`
  padding: 30px;
`;

export const PreviewTitle = styled.div`
  font-size: ${calcRem(20)};
  font-weight: 500;
`;

export const CameraItem = styled.div<{ isBreak: boolean }>`
  /* overflow: hidden; */
  border-radius: 0 0 4px 4px;
  background-color: #dddddd;
  margin-top: 1rem;
  width: 100%;

  // @media print {
  //   html, body {
  //     height: initial !important;
  //     overflow: initial !important;
  //     -webkit-print-color-adjust: exact;
  //   }
  // }

  ${({ isBreak }) =>
    isBreak &&
    `
    @media all {
      display: none;
    }
      
    @media print {
      // margin-top: 1rem;
      display: block;
      page-break-after: always;
    }
  `}
`;

export const CameraContent = styled.ul`
  ${FLEX.FlexCenterCenter};
  flex-direction: column;
  padding: 10px 0;
  flex: 1;
`;

export const CameraContentRow = styled.li`
  ${FLEX.FlexStartCenter};
  flex: 1;
  width: 90%;

  * {
    color: ${COLORS.BLACK};
  }

  > div {
    flex: 3;
  }
  .title {
    flex: 1;
  }

  &:nth-child(2) {
    margin: 10px 0;
  }
`;

export const BottomInfo = styled.div`
  * {
    color: ${COLORS.BLACK};
    font-size: ${calcRem(14)};
  }
  li {
    flex: 1;
    ${FLEX.FlexStartStart};
    gap: 20px;
    width: 100%;
    border-bottom: 1px solid ${COLORS.BLACK};
    padding: 6px;
    padding-top: 14px;

    &:last-child {
      border: none;
    }
  }
  .title {
    flex: 1;
    font-weight: 500;
  }

  .content {
    flex: 4;
    max-width: 100%;
    white-space: pre-wrap;
  }
`;

export const BottomWrapper = styled.div`
  ${FLEX.FlexBetweenStart};
  flex: 1;
  width: 100%;
  gap: 50px;
  border-top: 1px solid ${COLORS.BLACK};
  margin-top: 50px;
  padding: 30px 0 60px;

  > ul {
    width: 100%;
    flex: 1;
    ${FLEX.FlexStartStart};
    flex-direction: column;
  }
`;

export const Footer = styled.footer`
  width: 100%;
  > ul {
    width: 100%;
    ${FLEX.FlexStartCenter};
    gap: 10px;

    > li {
      border: none;

      &:last-child {
        flex: 2;
      }
    }
  }
`;
