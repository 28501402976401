import * as mediaQuery from "components/MediaQuery";
import * as s from "../AccountStyled";
import { IoMdCopy } from "react-icons/io";
import { TiStarFullOutline } from "react-icons/ti";

import { Account } from "api/mappers/accountMapper";
import { BsChevronCompactRight, BsChevronCompactLeft } from "react-icons/bs";
import { SwitchAccount } from "./SwitchAccount";

import Carousel, { ButtonGroupProps } from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { COLORS } from "styles/colors";
import { handleCopyClipBoard } from "utils/functions";
import { Fragment, useRef, useState } from "react";
import * as OsDetect from "react-device-detect";

import { Swiper, SwiperClass, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Virtual } from "swiper/modules";
import { ReactComponent as AccountSVG } from "assets/icons/sidenav/account.svg";

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { postAccountFavorite } from "api/accountAPI";
import { useMutation } from "react-query";
import { useQueryClient } from "react-query";

type Props = {
  account: SwitchAccount;
  onClickAccount: (acc: Account) => void;
};

const levelToString = (level: string) => {
  switch (level) {
    case "EU":
      return "End Users";
    case "CP":
      return "Channel Partners";
    case "SUB_CP":
      return "Sub Channel Partners";
    case "DW":
      return "Digital Watchdog";
    default:
      return level;
  }
};

export default function SwitchAccountList(props: Props): JSX.Element {
  const queryClient = useQueryClient();

  const { account, onClickAccount } = props;
  const dragState = useRef<boolean>(false);

  const mutationUpdateFavorite = useMutation(postAccountFavorite, {
    onSuccess: (res: any) => {
      queryClient.invalidateQueries("accountSwitch");
    },
    onError: (err: any) => {},
  });

  const onChangeFavorite = (account: Account) => {
    mutationUpdateFavorite.mutate({
      accountId: account.accountId,
      isFavorite: !account.isFavorite,
    });
  };

  return (
    <s.AccountGroup>
      <div className="title">{levelToString(account.level)}</div>
      <div
        key={`account-group-${account.level}`}
        className="account-group-wrapper"
      >
        <Swiper
          className={account.level}
          modules={[Navigation, Virtual, Pagination]}
          observer={true}
          observeParents={true}
          slidesPerView={4}
          spaceBetween={10}
          navigation
          virtual
          breakpoints={{
            1024: {
              slidesPerView: 4,
            },
            768: {
              slidesPerView: 3,
            },
            640: {
              slidesPerView: 2,
            },
            320: {
              slidesPerView: 1,
            },
          }}
        >
          {account.accounts.map((acc: Account, index) => {
            return (
              <SwiperSlide key={`acc-slide-${index}`}>
                <s.AccountItem
                  key={`account-item-${acc.accountNumber}`}
                  level={acc.level}
                  onMouseDown={(e) => {
                    // console.log("onMouseDown");
                    dragState.current = false;
                  }}
                  onMouseMove={(e) => {
                    // console.log("onMouseMove");
                    dragState.current = true;
                  }}
                  onMouseUp={(e) => {
                    // console.log("onMouseUp");
                    if (!dragState.current) {
                      // console.log("onClick");
                      onClickAccount(acc);
                    }
                  }}
                  onClick={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                    onClickAccount(acc);
                  }}
                >
                  <s.AccountItemTop>
                    <div className="account-info">
                      <s.AccountNumber>
                        <div className="org">
                          <AccountSVG />
                        </div>
                        {acc.accountNumber}
                        <button
                          onClick={(e) => {
                            e.stopPropagation();
                            handleCopyClipBoard(acc.accountNumber);
                          }}
                        >
                          <IoMdCopy />
                        </button>
                      </s.AccountNumber>
                      <span className="org-name">{acc.name}</span>

                      {/* <span className="group">Administrator</span> */}
                    </div>
                    <button
                      onClick={(e) => {
                        e.stopPropagation();
                        onChangeFavorite(acc);
                      }}
                    >
                      <TiStarFullOutline
                        color={acc.isFavorite ? "#FFE600" : "#FFFFFF60"}
                        size={18}
                      />
                    </button>
                  </s.AccountItemTop>
                  <mediaQuery.Default>
                    <s.AccountItemBottom>
                      {acc.level !== "EU" && (
                        <span>Organizations: {acc.hasAccounts}</span>
                      )}
                      <span>Recorders: {acc.hasRecorders}</span>
                    </s.AccountItemBottom>
                  </mediaQuery.Default>
                </s.AccountItem>
              </SwiperSlide>
            );
          })}
        </Swiper>
      </div>
    </s.AccountGroup>
  );
}
