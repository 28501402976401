import { AxiosResponse } from "axios";
import { AxiosInstance } from "./axiosInstance";
import { GetLicenseListResponse, GetVersionResponse } from "./interfaces/helpInterface.interface";
import { BasePagination } from "./interfaces/commonInterface.interface";
import qs from "qs";
import { CRUDRecorderResponse } from "./interfaces/recorderInterface.interface";

const API_PATH = "/help";
// Get storage status
export const getLicenseList = async ({
  payload,
  }: {
    payload: BasePagination;
  }): Promise<GetLicenseListResponse> => {
    try {
      const { data }: AxiosResponse<GetLicenseListResponse> =
        await AxiosInstance.get(
          `${API_PATH}/about?${qs.stringify(
            payload
          )}`
        );
      return data;
    } catch (e) {
      console.error(e);
      throw new Error();
    }
  };

  export const postIssue = async ({
    form,
    accountId,
  }: {
    form: FormData;
    accountId: string;
  }): Promise<CRUDRecorderResponse> => {
    try {
      const res: AxiosResponse<CRUDRecorderResponse> = await AxiosInstance.post(
        `${API_PATH}/about/submitIssue/${accountId}`,
        form
      );
      return res.data;
    } catch (e) {
      console.error(e);
      throw new Error();
    }
  };

  export const getVersion = async (): Promise<GetVersionResponse> => {
      try {
        const { data }: AxiosResponse<GetVersionResponse> =
          await AxiosInstance.get(
            `/about/version`
          );
        return data;
      } catch (e) {
        console.error(e);
        throw new Error();
      }
    };
  