import { Account } from "api/mappers/accountMapper";
import imageCompression from "browser-image-compression";
import { useAuth } from "components/Auth";
import { useCallback, useState } from "react";
import { EditModalPresenter, UPDATE_TYPE } from "./EditModalPresenter";
import { useIntl } from "react-intl";

interface Props {
  onModal: () => void;
  accountInfo: Account;
  onClickDelete: (accountId: string) => void;
  onClickSave: (account: Account, file: File) => void;
  isUpdateLoading: boolean;
}

export function EditModalContainer(props: Props): JSX.Element {
  const auth = useAuth();
  const intl = useIntl();
  const { onClickDelete, accountInfo, onClickSave } = props;
  // 토글 상태
  const [isToggled, setIsToggled] = useState(true);
  const [isDeleteConfirm, setIsDeleteConfirm] = useState(false);

  // 토글 onchange 함수
  const onChange = () => {
    setIsToggled(!isToggled);
  };

  const [logoImageFile, setLogoImageFile] = useState<File>();
  const [logoImageUrl, setLogoImageUrl] = useState<string>(
    accountInfo.companyLogo
  );

  const [editAccountInfo, setEditAccountInfo] = useState<Account>(accountInfo);

  const onChangeAccountInfo = useCallback(
    (type: UPDATE_TYPE, value: number | string | boolean) => {
      if (type === UPDATE_TYPE.NAME) {
        setEditAccountInfo((info) => {
          return { ...info, name: value as string };
        });
      } else if (type === UPDATE_TYPE.DATE_FORMAT) {
        setEditAccountInfo((info) => {
          return { ...info, dateFormat: value as string };
        });
      } else if (type === UPDATE_TYPE.TIME_FORMAT) {
        setEditAccountInfo((info) => {
          return { ...info, timeFormat: value as string };
        });
      } else if (type === UPDATE_TYPE.TIMEZONE) {
        setEditAccountInfo((info) => {
          return { ...info, timezone: value as string };
        });
      } else if (type === UPDATE_TYPE.TOW_FACTOR_AUTH) {
        setEditAccountInfo((info) => {
          return { ...info, use2faAuth: value as boolean };
        });
      } else if (type === UPDATE_TYPE.ADDITIONAL_ACCOUNT_NUMBER) {
        setEditAccountInfo((info) => {
          return { ...info, accountAdditionalNumber: value as string };
        });
      } else if (type === UPDATE_TYPE.DESC) {
        setEditAccountInfo((info) => {
          return { ...info, description: value as string };
        });
      }
    },
    []
  );

  const onClickDeleteConfirm = useCallback(() => {
    setIsDeleteConfirm(!isDeleteConfirm);
  }, []);

  const onClickDeactivate = useCallback((result: boolean) => {
    if (!result) {
      setIsDeleteConfirm(false);
    }
    if (result) {
      onClickDelete(editAccountInfo.accountId);
    }
  }, []);

  const logoHandleChange = async (e: any) => {
    let file = e.target.files[0]; // 입력받은 file객체

    // 이미지 resize 옵션 설정 (최대 width을 100px로 지정)
    const options = {
      maxSizeMB: 2,
      //maxWidthOrHeight: 60,
    };
    if (file.type !== "image/png") {
      return;
    }
    try {
      const compressedFile = await imageCompression(file, options);
      setLogoImageFile(compressedFile);
      // resize된 이미지의 url을 받아 fileUrl에 저장
      const promise = imageCompression.getDataUrlFromFile(compressedFile);
      promise.then((result) => {
        setLogoImageUrl(result);
      });
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <EditModalPresenter
      isToggled={isToggled}
      onChange={onChange}
      onModal={props.onModal}
      onClickDelete={onClickDeactivate}
      accountInfo={editAccountInfo}
      onClickSave={() => onClickSave(editAccountInfo, logoImageFile as File)}
      onChangeAccountInfo={onChangeAccountInfo}
      isDeleteConfirm={isDeleteConfirm}
      user={auth.user?.email as string}
      onClickDeleteConfirm={onClickDeleteConfirm}
      logoHandleChange={logoHandleChange}
      logoImage={logoImageUrl}
      isUpdateLoading={props.isUpdateLoading}
    />
  );
}
