import * as s from "./ButtonStyled";
import PlusCircleIcon from "assets/icons/plus_in_circle.svg";
import MapIcon from "assets/icons/map.svg";
import Spinner from "../loader/Spinner";
import { useCallback, useRef, useState } from "react";

type PrimaryButtonProps = {
  buttonType?: string;
  add?: boolean;
  map?: boolean;
  label: string;
  icon?: any;
  onClickBtn: () => void;
  disabled?:boolean;
  loading?: boolean;
};

function PrimaryButton({
  add,
  map,
  label,
  icon,
  onClickBtn,
  buttonType,
  disabled
}: PrimaryButtonProps): JSX.Element {

  const [lastClick, setLastClick] = useState<number>(0);
  const handleClick = useCallback(
    (e: React.MouseEvent) => {
      const currentTime = new Date().getTime();
      const timeDiff = currentTime - lastClick;      

      if (timeDiff < 1000) {
        e.preventDefault();
        return;
      }
      setLastClick(currentTime);
     
      onClickBtn();
    },
    [lastClick, onClickBtn]
  );
  return (    
    <s.Container
      disabled={disabled}
      buttonType={buttonType ? buttonType : "primary"}
      onClick={handleClick}
    >
        {add && <img alt="add button" src={PlusCircleIcon} />}
        {map && <img alt="map button" src={MapIcon} />}
        <s.ButtonLabel>{label}</s.ButtonLabel>
        {icon && icon}
    </s.Container>
  );
}

export default PrimaryButton;
