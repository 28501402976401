import React, {
  FormEvent,
  ForwardedRef,
  HTMLProps,
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
} from "react";
import { useState } from "react";
import { useQuery } from "react-query";
import {
  BasePagination,
  SORT_DIRECTION,
} from "api/interfaces/commonInterface.interface";
import { getUserList, getUserTotalList } from "api/userAPI";
import { ColumnDef, Row, RowSelectionState } from "@tanstack/react-table";
import { IndeterminateCheckbox, VGroupTable } from "./comp/VGroupTable";
import { keys } from "lodash";
import { CheckInput } from "styles/ModalCommonStyled";
import { Checkbox } from "antd";

export type SearchUserProps = {
  accountId: string;
  keyword: string;
  selectedUserId?: string[];
  onSelectedRowsChangeUser?: (selectedRows: string[]) => void;
  onSelectedRowsChangeEmail?: (selectedRows: string[]) => void;
  isSupportEmail?: boolean;
  userGroupId?: string;
  selectedUserIdEmail?: {
    userId: string;
    enableEmail: boolean;
    enablePush: boolean;
  }[];
  ref?: ForwardedRef<any>;
};

export interface DataRow {
  enablePush: boolean;
  enableEmail: boolean;
  id: string;
  name: string;
  email: string;
}

export const SearchUserListAlert = forwardRef(
  (props: SearchUserProps, ref: ForwardedRef<any>): JSX.Element => {
    const [users, setUsers] = useState<DataRow[]>([]);
    const [totalRows, setTotalRows] = useState<number>(0);
    const [selectedCombine, setSelectedCombine] = useState<RowSelectionState>(
      {}
    );

    const [userQueryInfo, setUserQueryInfo] = useState<BasePagination>({
      total: 0,
      pageNum: 0,
      pageLimit: 2000,
      keyword: props.keyword as string,
      sortType: "name",
      sortDirection: SORT_DIRECTION.ASC,
    });

    const checkOnlyGroup = (
      userGroups: {
        name: string;
        groupId: string;
        enableDelete: boolean;
        description: string;
      }[]
    ) => {
      if (userGroups.length !== 1) {
        return false;
      } else {
        if (
          props.userGroupId !== undefined &&
          userGroups[0].groupId === props.userGroupId
        ) {
          return true;
        } else {
          return false;
        }
      }
    };

    const { data, error, refetch } = useQuery(
      ["user", userQueryInfo, props.selectedUserIdEmail],
      () =>
        getUserTotalList({
          payload: userQueryInfo,
          accountId: props.accountId,
        }),
      {
        retry: 0,
        refetchOnWindowFocus: false,
        onSuccess: (res: any) => {
          if (res.result === undefined) {
            return;
          }
          setUsers(
            res.result.map((user: any) => {
              return {
                id: user.userId,
                name: user.name,
                email: user.email,
                enableEmail:
                  props.selectedUserIdEmail !== undefined
                    ? (props.selectedUserIdEmail.find(
                        (temp) => temp.userId === user.userId
                      )?.enableEmail as boolean)
                    : false,
                enablePush:
                  props.selectedUserIdEmail !== undefined
                    ? (props.selectedUserIdEmail.find(
                        (temp) => temp.userId === user.userId
                      )?.enablePush as boolean)
                    : false,
                disabled:
                  user.userGroupCount > 1
                    ? false
                    : checkOnlyGroup(user.userGroups),
              };
            })
          );
          //setTotalRows(res.page.total);
          let tmpCombine = {};
          res.result.forEach((user: any, index: number) => {
            if (props.selectedUserId?.includes(user.userId)) {
              Object.assign(tmpCombine, { [user.userId]: true });
            }

            if (props.selectedUserIdEmail !== undefined) {
              let selectId = props.selectedUserIdEmail.find(
                (temp) => temp.userId === user.userId
              );
              if (selectId !== undefined) {
                Object.assign(tmpCombine, { [user.userId]: true });
              }
            }
          });
          setSelectedCombine(tmpCombine);
        },
        onError: (e: any) => {},
      }
    );

    // useEffect(() => {
    //   let tmpCombine = {};
    //   const tmpData = [...users];
    //   tmpData.forEach((user, index: number) => {
    //     if (props.selectedUserId?.includes(user.id)) {
    //       Object.assign(tmpCombine, { [user.id]: true });
    //     }

    //     if (props.selectedUserIdEmail !== undefined) {
    //       let selectId = props.selectedUserIdEmail.find(
    //         (temp) => temp.userId === user.id
    //       );
    //       if (selectId !== undefined) {
    //         user.enableEmail = selectId.enableEmail;
    //         user.enablePush = selectId.enablePush;
    //       }else{
    //         user.enableEmail = false;
    //         user.enablePush = true;
    //       }
    //     }
    //   });

    //   setSelectedCombine(tmpCombine);
    //   setUsers(tmpData);
    // }, [props.selectedUserId, props.selectedUserIdEmail]);

    const headerCheckStatus = useCallback(
      (data: Row<any>[], type: string) => {
        let status: string = "NONE";
        switch (type) {
          case "EMAIL":
            const checkEmailCount = data.filter(
              (row) => row.original.enableEmail === true
            ).length;
            if (checkEmailCount > 0 && checkEmailCount < users.length) {
              status = "INTER";
            } else if (checkEmailCount === users.length) {
              status = "ALL";
            }
            break;
          case "PUSH":
            const checkPushCount = data.filter(
              (row) => row.original.enablePush === true
            ).length;
            if (checkPushCount > 0 && checkPushCount < users.length) {
              status = "INTER";
            } else if (checkPushCount === users.length) {
              status = "ALL";
            }
            break;
          default:
            break;
        }
        return status;
      },
      [users.length]
    );

    const emailHeaderToggleHandler = useCallback(
      (checked: boolean) => {
        const tmpData = [...users];
        tmpData.forEach((data) => {
          data.enableEmail = checked;
        });
        setUsers(tmpData);
      },
      [users]
    );

    const pushHeaderToggleHandler = useCallback(
      (checked: boolean) => {
        const tmpData = [...users];
        tmpData.forEach((data) => {
          data.enablePush = checked;
        });
        setUsers(tmpData);
      },
      [users]
    );

    const columns = React.useMemo<ColumnDef<any>[]>(
      () => [
        {
          accessorKey: "name",
          size: 100,
          header: ({ table }) => <>Name</>,
          cell: ({ row, getValue }) => (
            <div
              className="input-name"
              style={{
                paddingLeft: `${row.depth * 2}rem`,
              }}
            >
              <>
                {" "}
                {row.getCanExpand() ? (
                  <button
                    {...{
                      onClick: row.getToggleExpandedHandler(),
                      style: { cursor: "pointer" },
                    }}
                  >
                    {row.getIsExpanded() ? "▼" : "▶"}
                  </button>
                ) : (
                  " "
                )}{" "}
                {getValue()}
              </>
            </div>
          ),
          footer: (props) => props.column.id,
        },
        {
          accessorKey: "email",
          header: () => "Email",
          cell: ({ row, cell, getValue }) => getValue(),
          size: 200,
        },
        {
          accessorKey: "enableEmail",
          header: ({ table }) => (
            <>
              <IndeterminateCheckbox
                {...{
                  checked:
                    headerCheckStatus(table.getRowModel().rows, "EMAIL") ===
                    "ALL"
                      ? true
                      : false,
                  indeterminate:
                    headerCheckStatus(table.getRowModel().rows, "EMAIL") ===
                    "INTER"
                      ? true
                      : false,
                  onChange: (e) =>
                    emailHeaderToggleHandler(e.currentTarget.checked),
                  // onClick: onClickCheckBox
                }}
              />{" "}
              Email
            </>
          ),
          cell: ({ row }) => (
            <div
              style={{
                paddingLeft: `${row.depth * 2}rem`,
              }}
            >
              <input
                key={row.original.id + "_email"}
                type="checkbox"
                checked={row.original.enableEmail}
                onChange={(e) => {
                  const tmpData = [...users];
                  tmpData[row.index].enableEmail =
                    !tmpData[row.index].enableEmail;
                  setUsers(tmpData);
                }}
              />{" "}
            </div>
          ),
          size: 70,
          enableHiding: true,
        },
        {
          accessorKey: "enablePush",
          header: ({ table }) => (
            <>
              <IndeterminateCheckbox
                {...{
                  checked:
                    headerCheckStatus(table.getRowModel().rows, "PUSH") ===
                    "ALL"
                      ? true
                      : false,
                  indeterminate:
                    headerCheckStatus(table.getRowModel().rows, "PUSH") ===
                    "INTER"
                      ? true
                      : false,
                  onChange: (e) =>
                    pushHeaderToggleHandler(e.currentTarget.checked),
                  // onClick: onClickCheckBox
                }}
              />{" "}
              Push
            </>
          ),
          cell: ({ row, cell, getValue }) => (
            <div
              style={{
                paddingLeft: `${row.depth * 2}rem`,
              }}
            >
              <input
                key={row.original.id + "_push"}
                type="checkbox"
                checked={row.original.enablePush}
                onChange={(e) => {
                  const tmpData = [...users];
                  tmpData[row.index].enablePush =
                    !tmpData[row.index].enablePush;
                  setUsers(tmpData);
                }}
              />{" "}
            </div>
          ),
          size: 70,
          enableHiding: true,
        },
      ],
      [
        emailHeaderToggleHandler,
        headerCheckStatus,
        pushHeaderToggleHandler,
        users,
      ]
    );

    const handleChange = useCallback((row: RowSelectionState) => {
      // You can set state or dispatch with something like Redux so we can use the retrieved data
      let checkedRecorder: string[] = [];
      const selectKeys = Object.keys(row);

      if (selectKeys.length > 0) {
        selectKeys.forEach((select) => {
          if (!select.endsWith("merged")) {
            checkedRecorder.push(select);
          }
        });
      }

      if (props.onSelectedRowsChangeUser) {
        props.onSelectedRowsChangeUser(checkedRecorder);
      }
    }, []);

    useImperativeHandle(ref, () => ({
      getCheckedListAll() {
        const selectedList = users.filter(
          (user) => user.enableEmail === true || user.enablePush === true
        );
        return selectedList;
      },
    }));

    //FIXME EDIT시 기존에 체크된 User정보를 테이블에 적용하는 로직 필요.
    return (
      <>
        <VGroupTable
          columns={columns}
          data={users}
          search={props.keyword}
          selectedRow={selectedCombine}
          onSelectedRowsChange={handleChange}
        />
      </>
    );
  }
);
