import { useState } from "react";
import AddTimlapsePresenter from "./AddTimlapsePresenter";

type Props = {
  onModal: () => void;
};

export default function AddTimlapseContainer(props: Props): JSX.Element {
  const [page, setPage] = useState<number>(0);
  const steps = [
    {
      label: "Recorders",
    },
    {
      label: "Camera",
    },
    {
      label: "Interval",
    },
    {
      label: "Preferences",
    },
  ];

  const onChangePage = (name: string, index?: number) => {
    if (name === "next") {
      setPage(page + 1);
    }
    if (name === "previous") {
      setPage(page - 1);
    }
    if (name === "save") {
      props.onModal();
    }
    if (name === "step" && index !== undefined) {
      console.log(name, index);
      setPage(index);
    }
  };

  return (
    <AddTimlapsePresenter
      steps={steps}
      page={page}
      onChangePage={onChangePage}
    />
  );
}
