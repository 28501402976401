import styled from "styled-components";
import { COLORS, LEVELS } from "styles/colors";
import { FLEX } from "styles/flex";
import { calcRem } from "styles/theme";

export const Row = styled.div<{ status?: boolean }>`
  ${FLEX.FlexStartCenter};
  gap: 8px;
  cursor: pointer;

  ${({ status }) =>
    status &&
    `
    color: ${COLORS.SELECTED}
  `}

  img {
    width: 18px;
  }
`;

export const AccountBox = styled.div`
  ${FLEX.FlexStartStart};
  flex-direction: column;
  gap: 5px;
  padding: 6px 0;

  .account-id {
    padding: 1px 3px;
    border-radius: 5px;
    background-color: ${LEVELS.EU};
    font-size: ${calcRem(12)};
    color: ${COLORS.WHITE};
    ${FLEX.FlexStartCenter};
    gap: 4px;
  }
`;

export const RecorderRow = styled.div<{ status?: boolean }>`
  color: ${({ theme, status }) =>
    !status ? COLORS.LIGHTGRAY : theme.COLORS.WHITE};
  ${FLEX.FlexStartCenter};
  gap: 4px;

  .bubble-container {
    position: relative;
    ${FLEX.FlexCenterCenter};
    color: ${({ theme, status }) =>
      !status ? COLORS.LIGHTGRAY : theme.COLORS.WHITE};
  }
`;
