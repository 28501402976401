import { notification } from 'antd';
import { NotificationPlacement } from 'antd/es/notification/interface';
export type NotificationType = 'success' | 'info' | 'warning' | 'error';

export const notify = (type : NotificationType, title :string, placement?:NotificationPlacement) => {
    notification[type]({
        message: title,
        placement : placement !== undefined ? placement : "topRight"
    });
  
}