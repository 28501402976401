import { Text } from "components/atoms/text/Text";
import styled from "styled-components";
import { COLORS } from "styles/colors";
import { FLEX } from "styles/flex";
import NoDataIcon from "assets/images/no_data_icon.png";
import NoDataLightIcon from "assets/images/no_data_icon_light.png";
import { useAppSelector } from "redux/hooks";
import * as mediaQuery from "components/MediaQuery";

export default function NoDataChart(): JSX.Element {
  const themeMode = useAppSelector((state) => state.theme.theme);
  return (
    <Wrapper>
      {themeMode === "light" ? (
        <img src={NoDataLightIcon} alt="no_data_icon" />
      ) : (
        <img src={NoDataIcon} alt="no_data_icon" />
      )}
      <Text bold fontSize={14}>
        There is no data to display
      </Text>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  ${FLEX.FlexCenterCenter};
  flex-direction: column;
  padding: 30px 0;
  gap: 6px;

  img {
    width: 70px;
    max-width: 70px !important;
  }

  * {
    color: ${COLORS.BORDER};
  }

  ${mediaQuery.isMobile} {
    img {
      width: 50px;
      max-width: 50px !important;
    }
  }
`;
