import { useState, useCallback, useEffect } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";

import { DataRow, UsersPresenter } from "./../UsersPresenter";
import * as s from "./../UsersStyled";
import { Media, SortOrder, TableColumn } from "react-data-table-component";
import TableButton from "components/atoms/buttons/TableButton";

import { getUserDetailAccount, getUserList, updateUser } from "api/userAPI";
import {
  BasePagination,
  CustomError,
  SORT_DIRECTION,
} from "api/interfaces/commonInterface.interface";
import {
  AddUserDto,
  Group,
  UserDto,
} from "api/interfaces/userInterface.interface";
import { createUser, deleteUser } from "api/userAPI";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import { ProfileAccountInfo } from "api/interfaces/accountInterface.interface";
import { notify } from "components/atoms/notification/Notification";
import RBACWrapper from "components/blocks/function/RBACWrapper";
import { FaRegUser } from "react-icons/fa";
import { resendUser } from "api/authAPI";
import { getAccountInfo, isCheckPermission } from "utils/AccountUtil";
import { setAccountSetting } from "redux/reducers/account/accountSlice";
import { useIntl } from "react-intl";
import useApiError from "hook/useApiError";
import { useLocation, useNavigate } from "react-router-dom";
import { isEmpty, isNull } from "lodash";
import { queryNavigate } from "utils/MenuUtil";
import { getAccountTimeFormat } from "utils/timeUtil";

export function UsersContainer(): JSX.Element {
  const intl = useIntl();
  const location = useLocation();
  const navigate = useNavigate();

  const menuType: string = "user";
  const queryClient = useQueryClient();
  const selectedAccount: ProfileAccountInfo = useAppSelector(
    (state) => state.accountSettings
  );

  const dispatch = useAppDispatch();
  const { handleError } = useApiError();

  const [isAddModal, setIsAddModal] = useState(false);
  const [isEditModal, setIsEditModal] = useState(false);

  const [isSwitchModal, setIsSwitchModal] = useState<boolean>(false);

  const [status, setStatus] = useState({
    totalTitle: "Total Users",
    totalValue: 0,
  });
  const [isRowClickEditable, setIsRowClickEditable] = useState<boolean>(
    isCheckPermission("manageUsersAndGroups", selectedAccount)
  );
  useEffect(() => {
    if (selectedAccount) {
      setIsRowClickEditable(
        isCheckPermission("manageUsersAndGroups", selectedAccount)
      );
    }
  }, [selectedAccount]);

  useEffect(() => {
    const changeParam = new URLSearchParams(location.search);
    if (changeParam.size > 0) {
      setUserQueryInfo((info) => {
        return {
          ...info,
          pageNum: isNull(changeParam.get("pageNum"))
            ? 0
            : Number(changeParam.get("pageNum")),
          pageLimit: isNull(changeParam.get("pageLimit"))
            ? 20
            : Number(changeParam.get("pageLimit")),
          keyword: isNull(changeParam.get("keyword"))
            ? ""
            : (changeParam.get("keyword") as string),
          sortType: isNull(changeParam.get("sortType"))
            ? "name"
            : (changeParam.get("sortType") as string),
          sortDirection: isNull(changeParam.get("sortDirection"))
            ? SORT_DIRECTION.ASC
            : (changeParam.get("sortDirection") as SORT_DIRECTION),
        };
      });
    } else {
      setUserQueryInfo((info) => {
        return {
          ...info,
          pageNum: 0,
          pageLimit: 20,
          keyword: "",
          sortType: "name",
          sortDirection: SORT_DIRECTION.ASC,
        };
      });
    }
  }, [location]);

  const [selectedEdit, setSelectedEdit] = useState<UserDto | Group>();
  const [totalUsers, setTotalUsers] = useState<UserDto[]>([]);
  const [currUser, setCurrUser] = useState<UserDto>();
  const [pending, setPending] = useState<boolean>(true);

  const [users, setUsers] = useState<DataRow[]>([]);
  const [userQueryInfo, setUserQueryInfo] = useState<BasePagination>({
    total: 0,
    pageNum: 0,
    pageLimit: 20,
    keyword: "",
    sortType: "name",
    sortDirection: SORT_DIRECTION.ASC,
  });

  // user roles
  const columns: TableColumn<DataRow>[] = [
    {
      id: "name",
      name: "Name",
      grow: 2,

      cell: (row) => {
        let isAdmin = row.groupName?.includes("Administrator");
        return (
          <s.Row isAdmin={isAdmin}>
            <div>
              <FaRegUser size={16} />
            </div>
            {row.isActivate
              ? row.name
              : isEmpty(row.name)
              ? "Invited User"
              : row.name}
          </s.Row>
        );
      },

      sortable: true,
      sortField: "name",
    },
    {
      name: "Email",
      grow: 4,
      allowOverflow: true,
      cell: (row) => (row.email !== undefined ? row.email : ""),
      hide: Media.MD,
    },
    {
      name: "Group",
      grow: 2,
      minWidth: "105px",
      cell: (row) => row.groupName!.join(","),
      // sortable: true,
      // sortField: "group",
    },
    {
      name: "Invited",
      grow: 2,
      selector: (row) =>
        row.invitedDate !== undefined
          ? getAccountTimeFormat(row.invitedDate, selectedAccount, true)
          : "",
      hide: Media.MD,
      // sortable: true,
      // sortField: "invitedDate",
    },
    {
      name: "Invited By",
      grow: 4,
      cell: (row) =>
        row.invitedEmail !== undefined
          ? `${row.invitedBy} (${row.invitedEmail})`
          : row.isActivate
          ? "Organization Creator"
          : "",
      // sortable: true,
      // sortField: "invitedBy",
    },
    {
      name: "Description",
      maxWidth: "600px",
      grow: 2,
      selector: (row) => row.description,
      sortable: true,
      sortField: "description",
      hide: Media.MD,
    },
    {
      name: "",
      minWidth: "80px",
      width: "80px",
      cell: (row) => (
        <s.Row>
          {!row.isActivate && (
            <RBACWrapper requiredPermissions="manageUsersAndGroups">
              <TableButton
                label="Resend"
                buttonType="view"
                onClickBtn={() => onResend(row)}
              />
            </RBACWrapper>
          )}
        </s.Row>
      ),
      //hide: Media.MD,
      right: true,
    },
    // 마지막 버튼
    {
      name: "",
      minWidth: "80px",
      width: "80px",
      cell: (row) => (
        <s.Row>
          {row.enableEdit && (
            <RBACWrapper requiredPermissions="manageUsersAndGroups">
              <s.Row>
                <span className="row-click-button">
                  <TableButton
                    label="Edit"
                    onClickBtn={() => onRowClicked(row)}
                  />
                </span>
              </s.Row>
            </RBACWrapper>
          )}
        </s.Row>
      ),
      right: true,
    },
  ];

  const mutationResendUser = useMutation(resendUser, {
    onSuccess: () => {
      notify(
        "success",
        intl.formatMessage({
          id: "label.user.notify.resend.success",
          defaultMessage: "Invite email sent successfully.",
        })
      );
    },
    onError: () => {
      notify(
        "error",
        intl.formatMessage({
          id: "label.user.notify.resend.fail",
          defaultMessage: "Failed to send invite email.",
        })
      );
    },
  });

  const onResend = (row: DataRow) => {
    mutationResendUser.mutate({
      accountId: selectedAccount.accountId,
      userId: row.id,
    });
  };

  const onRowClicked = (row: DataRow) => {
    if (isCheckPermission("manageUsersAndGroups", selectedAccount)) {
      const selectUser = Array.from(totalUsers).find(
        (user) => user.userId === row.id
      );
      setCurrUser(selectUser);
      onModal("edit");
    }
  };

  const onModal = (type?: string) => {
    if (type === "add") {
      if (isAddModal) {
        queryClient.invalidateQueries("user");
      }
      setIsAddModal(!isAddModal);
    }
    if (type === "edit") {
      if (isEditModal) {
        queryClient.invalidateQueries("user");
      }
      setIsEditModal(!isEditModal);
    }

    if (type === "deActive") {
      if (isEditModal) {
        queryClient.invalidateQueries("user");
      }
      setIsEditModal(!isEditModal);
      setIsSwitchModal(true);
    }
  };

  const onChangeSwitch = () => {
    setIsSwitchModal(false);
  };

  const { error, refetch } = useQuery(
    ["user", userQueryInfo],
    () =>
      getUserList({
        payload: userQueryInfo,
        accountId: selectedAccount.accountId,
      }),
    {
      retry: 0,
      refetchOnWindowFocus: false,
      onSuccess: (res: any) => {
        if (res.error !== 0 || res.result === undefined) {
          setUsers([]);
          setStatus((info) => {
            return { ...info, totalValue: 0 };
          });
          return;
        }
        setUsers(
          res.result.map((user: any) => {
            return {
              id: user.userId,
              name: user.name,
              email: user.email,
              groupName:
                user.userGroups !== undefined
                  ? user.userGroups.map((group: any) => group.name)
                  : [],
              description: user.description,
              enableEdit: user.enableEdit,
              isActivate: user.isActivate,
              createAt: user.createAt,
              invitedDate: user.invitedDate,
              invitedBy: user.invitedBy,
              invitedEmail: user.invitedEmail,
              raw: user,
            };
          })
        );

        setTotalUsers(res.result);

        setStatus({
          totalTitle: "Total Users",
          totalValue: res.page.total,
        });
      },
      onError: (e: any) => {
        notify(
          "error",
          intl.formatMessage({
            id: "label.user.notify.list.fail",
            defaultMessage: "User search error.",
          })
        );
        setUsers([]);
        setStatus((info) => {
          return { ...info, totalValue: 0 };
        });
      },
      onSettled: (e: any) => {
        setPending(false);
      },
    }
  );

  const mutationDeleteUser = useMutation(deleteUser, {
    onSuccess: () => {
      notify(
        "success",
        intl.formatMessage({
          id: "label.user.notify.delete.success",
          defaultMessage: "User deleted successfully.",
        })
      );
      queryClient.invalidateQueries("user");
    },
    onError: (err: CustomError) => {
      handleError(
        err,
        intl.formatMessage({
          id: "label.user.notify.delete.fail",
          defaultMessage: "Failed to delete user.",
        })
      );
    },
  });

  const mutationCreateUser = useMutation(createUser, {
    onSuccess: () => {
      notify(
        "success",
        intl.formatMessage({
          id: "label.user.notify.add.success",
          defaultMessage: "User added successfully.",
        })
      );
      queryClient.invalidateQueries("user");
      setIsAddModal(false);
    },
    onError: (err: CustomError) => {
      handleError(
        err,
        intl.formatMessage({
          id: "label.user.notify.add.fail",
          defaultMessage: "Failed to add user.",
        })
      );
    },
  });

  const mutationUpdateUser = useMutation(updateUser, {
    onSuccess: () => {
      notify(
        "success",
        intl.formatMessage({
          id: "label.user.notify.update.success",
          defaultMessage: "User updated successfully.",
        })
      );
      getAccountInfo(selectedAccount.accountId).then((res: any) => {
        dispatch(setAccountSetting(res));
      });
      queryClient.invalidateQueries("user");
      setIsEditModal(false);
    },
    onError: (err: CustomError) => {
      handleError(
        err,
        intl.formatMessage({
          id: "label.user.notify.update.fail",
          defaultMessage: "Failed to update user.",
        })
      );
    },
  });

  const onClickSave = useCallback(
    (user: UserDto | AddUserDto, update?: boolean) => {
      if (update) {
        mutationUpdateUser.mutate({
          accountId: selectedAccount.accountId,
          userId: user.userId,
          payload: user as AddUserDto,
        });
      } else {
        mutationCreateUser.mutate({
          accountId: selectedAccount.accountId,
          payload: user,
        });
      }
    },
    [mutationCreateUser, mutationUpdateUser, selectedAccount.accountId]
  );

  const onClickDeactive = useCallback(
    (userId: string) => {
      mutationDeleteUser.mutate({
        accountId: selectedAccount.accountId,
        userId: userId,
      });
    },
    [mutationDeleteUser, selectedAccount.accountId]
  );

  const onChangePage = useCallback(
    (page: number, totalRows: number) => {
      console.log(page, totalRows);
      if (page !== userQueryInfo.pageNum + 1) {
        queryNavigate(
          navigate,
          location.pathname,
          {
            pageLimit: userQueryInfo.pageLimit,
            pageNum: page - 1,
            keyword: userQueryInfo.keyword as string,
            sortType: userQueryInfo.sortType,
            sortDirection: userQueryInfo.sortDirection as SORT_DIRECTION,
          },
          selectedAccount
        );
        setPending(true);
        // setUserQueryInfo((info) => {
        //   return { ...info, pageNum: (page - 1) as number };
        // });
      }
    },
    [
      location.pathname,
      navigate,
      selectedAccount,
      userQueryInfo.keyword,
      userQueryInfo.pageLimit,
      userQueryInfo.pageNum,
      userQueryInfo.sortDirection,
      userQueryInfo.sortType,
    ]
  );

  const onClickSearch = useCallback(
    (keyword: string) => {
      if (userQueryInfo.keyword !== keyword) {
        queryNavigate(
          navigate,
          location.pathname,
          {
            pageLimit: userQueryInfo.pageLimit,
            pageNum: 0,
            keyword: keyword,
            sortType: userQueryInfo.sortType,
            sortDirection: userQueryInfo.sortDirection as SORT_DIRECTION,
          },
          selectedAccount
        );
      } else {
        refetch();
      }
      setPending(true);
      // setUserQueryInfo((query) => {
      //   return {
      //     ...query,
      //     keyword,
      //   };
      // });
      // refetch();
    },
    [
      location.pathname,
      navigate,
      refetch,
      selectedAccount,
      userQueryInfo.keyword,
      userQueryInfo.pageLimit,
      userQueryInfo.sortDirection,
      userQueryInfo.sortType,
    ]
  );

  const handleSort = async (
    selectedColumn: TableColumn<any>,
    sortDirection: SortOrder
  ) => {
    if (selectedColumn.sortField !== undefined) {
      setUserQueryInfo((info) => {
        return {
          ...info,
          sortType: selectedColumn.sortField as string,
          sortDirection: sortDirection.toUpperCase() as SORT_DIRECTION,
        };
      });
      //setPending(true);
    }
  };

  return (
    <UsersPresenter
      menuType={menuType}
      columns={columns}
      data={users}
      selectedUser={currUser}
      onModal={onModal}
      isAddModal={isAddModal}
      isEditModal={isEditModal}
      status={status}
      onClickSave={onClickSave}
      onChangePage={onChangePage}
      selectedEdit={selectedEdit}
      onClickSearch={onClickSearch}
      onRowClicked={onRowClicked}
      handleSort={handleSort}
      isRowClickEditable={isRowClickEditable}
      initialPending={pending}
      paginationInfo={userQueryInfo}
      isSwitchModal={isSwitchModal}
      onChangeSwitch={onChangeSwitch}
      isCreateLoading={mutationCreateUser.isLoading}
      isUpdateLoading={mutationUpdateUser.isLoading}
    />
  );
}
