import { Verify } from "crypto";
import styled from "styled-components";
import { COLORS } from "styles/colors";
import { FLEX } from "styles/flex";
import { getOnlyNumber } from "utils/validHelper";
import { KeyboardEvent } from "react";
import React from "react";
import OTPInput from "./OTPInput";
import "./OTPInput/OTPInput.css";

interface VerfiyCodeProps {
  verifyCode: string;
  onChange: (value: string) => void;
  onClickOtpEnter?: ()=> void;
}

function VerfiyCode(props: VerfiyCodeProps): JSX.Element {
  return (
    <Container>
      <OTPInput
        isNumberInput={true}
        autoFocus
        length={6}
        className={Container}
        inputClassName="otpInput"
        onChangeOTP={props.onChange}
        onClickOtpEnter={props.onClickOtpEnter}
      />
      {/* {Array.from(Array(6).fill("")).map((code, index) => {
        return (
          <Input
            id={`verifyCode${index}`}
            type="text"
            className="input-field input-field-2fa"
            maxLength={1}
            value={code}
            key={`${code}${index}`}
            // onChange={(e) => {
            //   props.onChange(index, getOnlyNumber(e.target.value));
            //   if(index !== code.length-1){
            //     document.getElementById(`verifyCode${index+1}`)?.focus();
            //   }
            // }}
          />
        );
      })} */}
    </Container>
  );
}

const Container = styled.section`
  ${FLEX.FlexCenterCenter};
  gap: 10px;
  flex: 1;
  padding: 10px 0;
  width: 100%;
`;

const Input = styled.input`
  padding: 16px 0;
  text-align: center;
  border-radius: 4px;
  width: 42px;
  flex: 1;
`;

export default VerfiyCode;
