import * as s from "styles/ModalCommonStyled";
import BigPopupTemplate from "components/templates/modal/big/BigPopupTemplate";
import BigButton from "components/atoms/buttons/BigButton";
import { ModalInput } from "components/atoms/input/modal/ModalInput";
import Input from "components/atoms/input/Input";
import { Text, ValidateWord } from "components/atoms/text/Text";
import WARNING_CIRCLE_ICON from "assets/icons/circle_warning.svg";
import WARNING_TRIANGLE_ICON from "assets/icons/triangle_warning.svg";
import DOCUMENT_ICON from "assets/icons/document.svg";
import { BACKGROUND_COLORS } from "styles/colors";
import { MessageInput, NumberInput } from "../NoticeStyled";
import { ICON_TYPE, Notice } from "api/interfaces/noticeInterface.interface";
import TextArea from "components/atoms/input/TextArea";
import { ValidateMsg } from "api/interfaces/commonInterface.interface";
import ToggleComponent from "components/atoms/toggle/Toggle";
import { PiWarningCircleBold } from "react-icons/pi";
import { PiWarningBold } from "react-icons/pi";
import { IoDocumentTextOutline } from "react-icons/io5";

interface Props {
  isEdit?: boolean;
  onModal: (type?: string) => void;
  onChange: (e: any) => void;
  notice: Notice;
  onClickSave: (notice: Notice) => void;
  onDelete: () => void;
  onUpdate: () => void;
  validateMsg?: ValidateMsg;
  isPermanently: boolean;
  onChangePermanent: (e: any) => void;
}

/**
 * @refactor
 * @todo ReactNode와 string을 함께 받으면 불필요한 타입체크가 많이 필요함 as string
 * @todo 프레젠터에서 표시 할 타입만 enum으로 정의해서 export하고, 아이콘에 대한 핸들링은 Presenter
 * @todo 컨테이너는 타입이 중요하지 어떤 아이콘을 표시할지는 Presente에서 고민하는게 어떨까요?
 */
export function AddModalPresenter(props: Props): JSX.Element {
  const messageIcons = [
    {
      label: "None",
      value: ICON_TYPE.NONE,
    },
    {
      // label: <img src={WARNING_CIRCLE_ICON} />,
      value: ICON_TYPE.INFO,
      label: <PiWarningCircleBold size={20} />,
    },
    {
      // label: <img src={WARNING_TRIANGLE_ICON} />,
      value: ICON_TYPE.WARNING,
      label: <PiWarningBold size={20} />,
    },
    {
      // label: <img src={DOCUMENT_ICON} />,
      value: ICON_TYPE.DOCUMENT,
      label: <IoDocumentTextOutline size={20} />,
    },
  ];
  const bgColorIcons = BACKGROUND_COLORS.map((color) => {
    return {
      label: Object.keys(color)[0],
      value: Object.values(color)[0],
    };
  });
  return (
    <BigPopupTemplate
      title={props.isEdit ? "Edit Notice" : "Add Notice"}
      onModal={props.onModal}
    >
      <s.AddWrapper>
        <ModalInput label="Notice Title" essential>
          <Input
            placeholder="Enter Title"
            name="subject"
            onChange={props.onChange}
            value={props.notice.subject}
          />
        </ModalInput>
        <s.ValidateWrapper>
          <ValidateWord>{props.validateMsg?.title}</ValidateWord>
        </s.ValidateWrapper>
        <ModalInput label="Message" essential>
          <TextArea
            value={props.notice.message}
            name="message"
            onChange={props.onChange}
            placeholder="Write a message to display on your notice."
          />
        </ModalInput>
        <s.ValidateWrapper>
          <ValidateWord>{props.validateMsg?.message}</ValidateWord>
        </s.ValidateWrapper>
        <ModalInput label="Message Icon">
          <s.RadioButtonContainer>
            {messageIcons.map((icon, index) => (
              <MessageInput key={index}>
                <input
                  type="radio"
                  name="icon"
                  value={icon.value}
                  onChange={props.onChange}
                  checked={icon.value === props.notice.icon}
                />
                {icon.label}
              </MessageInput>
            ))}
          </s.RadioButtonContainer>
        </ModalInput>
        <ModalInput label="Background Color">
          <s.RadioButtonContainer>
            {bgColorIcons.map((icon, idx) => (
              <s.CheckInput key={idx}>
                <input
                  type="radio"
                  name="color"
                  value={icon.label.toUpperCase()}
                  onChange={props.onChange}
                  checked={
                    icon.label.toUpperCase() === props.notice.color ||
                    icon.value.toUpperCase() === props.notice.color
                  }
                />
                <Text color={icon.value}>{icon.label}</Text>
              </s.CheckInput>
            ))}
          </s.RadioButtonContainer>
        </ModalInput>
        <ModalInput label="Display for:">
          <s.DisplayWrapper>
            <s.CheckInput>
              <input
                type="radio"
                name="permanent"
                onChange={props.onChangePermanent}
                value={"true"}
                checked={props.isPermanently}
              />
              Permanently
            </s.CheckInput>
            <s.CheckInput className="period-option">
              <input
                type="radio"
                name="permanent"
                onChange={props.onChangePermanent}
                value={"false"}
                checked={!props.isPermanently}
              />
              Period
              <NumberInput>
                <Input
                  name="period"
                  onChange={props.onChange}
                  value={props.notice.period}
                  disabled={props.isPermanently}
                />
              </NumberInput>
              Hour(s) when enabled.
            </s.CheckInput>
          </s.DisplayWrapper>
        </ModalInput>
        <s.ButtonWrapper>
          {props.isEdit && (
            <BigButton
              label="Delete"
              buttonType="warning"
              onClickBtn={props.onDelete}
            />
          )}
          <BigButton
            label="Save"
            onClickBtn={() => {
              props.isEdit ? props.onUpdate() : props.onClickSave(props.notice);
            }}
          />
        </s.ButtonWrapper>
      </s.AddWrapper>
    </BigPopupTemplate>
  );
}
