import { useState } from "react";
import * as s from "styles/ModalCommonStyled";
import StepperComponent, { StepType } from "components/blocks/stepper/Stepper";
import ModalSubMenu from "components/blocks/modal/menu/ModalSubMenu";
import SearchInput from "components/atoms/input/search/SearchInput";
import * as DATA from "pages/alert/temp/data";
import BigButton from "components/atoms/buttons/BigButton";
import { ModalInput } from "components/atoms/input/modal/ModalInput";
import Input from "components/atoms/input/Input";
import { TableColumn } from "react-data-table-component";
import TextArea from "components/atoms/input/TextArea";
import { intervalOptions } from "utils/options";
import { Text } from "components/atoms/text/Text";
import { COLORS } from "styles/colors";

import TimeSelect from "components/atoms/select/TimeSelect";
import DateSelect from "components/atoms/select/DateSelect";
import DefaultThumbnail from "components/atoms/image/DefaultThumbnail";
import Selects from "components/atoms/select/Select";

type Props = {
  steps: Array<StepType>;
  page: number;
  onChangePage: (name: string, index?: number) => void;
};
const addCvvListData = () => {
  let temp = [];

  for (let i = 0; i < 9; i++) {
    temp.push({
      cameraName: `Camera Channel Name ${i + 1}`,
      url: "staging/{ae310607-6bd9-931e-ba28-689bd8da3048}/{8236e03f-2449-486c-8e3d-9b3a64eb8251}/0/{01660dea-2363-be2b-69c2-985643494729}/80x85za7zocz3ylqulek495e7ekwhq.jpg",
    });
  }
  return temp;
};

export default function AddTimlapsePresenter(props: Props): JSX.Element {
  const [currentMenu, setCurrentMenu] = useState(0);
  const onChangeMenu = (index: number) => {
    setCurrentMenu(index);
  };
  const addUserColumns: TableColumn<DATA.AddUserData>[] = [
    {
      name: "Name",
      selector: (row) => row.name,
    },
    {
      name: "Email",
      selector: (row) => row.email,
    },
  ];
  const addUserGroupColumns: TableColumn<DATA.AddUserGroupData>[] = [
    {
      name: "Name",
      selector: (row) => row.name,
    },
    {
      name: "Users",
      selector: (row) => row.users,
      center: true,
    },
    {
      name: "Email",
      cell: (row) => <input type="checkbox" checked={row.emailCheck} />,
    },
    {
      name: "Push",
      cell: (row) => <input type="checkbox" checked={row.pushCheck} />,
    },
  ];
  // step 0 - recorders sub menu
  const recorderMenus = [
    {
      label: "Add Recorders",
      value: true,
      placeholder: "Search for Individual Recorders",
      columns: DATA.addRecorderColumns,
      data: DATA.addRecorderData,
    },
    {
      label: "Add Recorder Groups",
      value: false,
      placeholder: "Search for Recorder Groups",
      columns: DATA.addRecorderGroupColumns,
      data: DATA.addRecorderGroupData,
    },
  ];

  // step 1 - recipients sub menu
  const recipientsMenus = [
    {
      label: "Add Users",
      value: true,
      placeholder: "Search users or add new ones via email.",
      columns: addUserColumns,
      data: DATA.addUserData,
    },
    {
      label: "Add User Groups",
      value: false,
      placeholder: "Search Groups",
      columns: addUserGroupColumns,
      data: DATA.addUserGroupsData,
    },
  ];

  const pages = [
    // recorders
    <s.ModalInnerHalf>
      <s.CheckInput>
        <input type="checkbox" /> Apply to all current and future recorders.
      </s.CheckInput>
      <ModalSubMenu
        menus={recorderMenus}
        currentMenu={currentMenu}
        onChange={onChangeMenu}
      />
      <SearchInput placeholder={recorderMenus[currentMenu].placeholder} />
      {/* <s.ModalTableContainer>
        <ModalTable
          columns={recorderMenus[currentMenu].columns}
          data={recorderMenus[currentMenu].data}
        />
      </s.ModalTableContainer> */}
    </s.ModalInnerHalf>,
    // Camera
    <s.ModalInnerHalf>
      <s.CvvReportStatus>
        <span>
          <Text bold color={COLORS.BUTTONS1}>
            Recorders:
          </Text>
          <Text>7/10</Text>
        </span>
        <span>
          <Text bold color={COLORS.BUTTONS1}>
            Cameras:
          </Text>
          <Text>65</Text>
        </span>
      </s.CvvReportStatus>

      <s.CvvAddContainer>
        {/* Recorder 한 묶음 div -> 추후 배열로 맵핑 */}
        <div>
          <s.CvvListTitle>
            <label>
              <input type="checkbox" />{" "}
              <Text bold>Cerritos Office Parking Lot</Text>
            </label>
          </s.CvvListTitle>
          <s.CvvListWrapper>
            {addCvvListData().map((item, index) => (
              <s.CvvListItem key={index}>
                <s.CvvCameraThumbnail>
                  <DefaultThumbnail>
                    <img src={item.url} alt="cvv camera thumbnail" />
                  </DefaultThumbnail>
                  <input type="checkbox" />
                  <s.CvvCameraThumbnailTitle>
                    {item.cameraName}
                  </s.CvvCameraThumbnailTitle>
                </s.CvvCameraThumbnail>
              </s.CvvListItem>
            ))}
          </s.CvvListWrapper>
        </div>
      </s.CvvAddContainer>
    </s.ModalInnerHalf>,
    //Interval
    <s.ModalTableContainer>
      <s.InvervalTop>
        <label className="camera-name">
          <input type="checkbox" />{" "}
          <Text>Use one frame interval for all cameras</Text>
        </label>
        <s.TimelapseIntervalFrame isDisabled>
          <Text>Frame Interval:&nbsp;</Text>
          <s.TimelapseIntervalTopItem>
            <span>
              <Selects options={intervalOptions} />
            </span>
            {/* daily, weekly, monthly 에 따라 선택 옵션들이 달라져야함 */}
            <span>
              <TimeSelect />
            </span>
          </s.TimelapseIntervalTopItem>
        </s.TimelapseIntervalFrame>
      </s.InvervalTop>
      <s.CvvAddContainer>
        <div>
          <s.CvvListTitle>
            <Text bold>Cerritos Office Parking Lot</Text>
          </s.CvvListTitle>
          <s.TimelapseWrapper>
            {addCvvListData().map((item, index) => (
              <s.TimelapseIntervalItem key={index}>
                <Text className="camera-name">{item.cameraName}</Text>
                <s.TimelapseIntervalFrame>
                  <Text>Frame Interval:&nbsp;</Text>
                  <s.TimelapseIntervalTopItem>
                    <span>
                      <Selects options={intervalOptions} />
                    </span>
                    {/* daily, weekly, monthly 에 따라 선택 옵션들이 달라져야함 */}
                    <span>
                      <TimeSelect />
                    </span>
                  </s.TimelapseIntervalTopItem>
                  {/* <span>
                    <DateSelect />
                  </span> */}
                </s.TimelapseIntervalFrame>
              </s.TimelapseIntervalItem>
            ))}
          </s.TimelapseWrapper>
        </div>
      </s.CvvAddContainer>
    </s.ModalTableContainer>,
    // preferences
    <s.ModalInnerHalf>
      <ModalInput label="Report Title" essential>
        <Input placeholder="Enter a title for this report" />
      </ModalInput>
      <ModalInput label="Host">
        <Input
          disabled
          value="Steven Armstrong (sarmstron@digital-watchdog.com)"
        />
      </ModalInput>
      <ModalInput label="Description">
        <TextArea placeholder="Enter Description" />
      </ModalInput>
    </s.ModalInnerHalf>,
  ];

  return (
    <>
      <StepperComponent
        activeStep={props.page}
        steps={props.steps}
        onClick={props.onChangePage}
      />
      {pages[props.page]}
      <s.ButtonWrapper>
        {props.page !== 0 && (
          <BigButton
            buttonType="cancel"
            label="Previous"
            onClickBtn={() => props.onChangePage("previous")}
          />
        )}

        {props.page === pages.length - 1 ? (
          <BigButton
            label="Save"
            onClickBtn={() => props.onChangePage("save")}
          />
        ) : (
          <BigButton
            buttonType="cancel"
            label="Next"
            onClickBtn={() => props.onChangePage("next")}
          />
        )}
      </s.ButtonWrapper>
    </>
  );
}
