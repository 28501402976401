import * as s from "./TableItemStyled";
import { FiMenu } from "react-icons/fi";
import TableItemList, { TableItemName } from "./TableItemList";
import { useEffect, useRef, useState } from "react";
import { Layout } from "react-grid-layout";
import { ReactComponent as AlertSVG } from "assets/icons/sidenav/alerts.svg";
import InfoBubble from "components/blocks/bubble/InfoBubble";
import { BubbleContainer } from "pages/setting/SettingStyled";

interface Props {
  layout: Layout;
  chartLayout: Layout[];
  onDeleteWidget: (key: TableItemName) => void;
  onLayoutChange: (layout: Layout[]) => void;
  isError?: boolean;
}

const keyTitle: { [key in TableItemName]: string } = {
  installation: "Device Installation History",
  revenue: "Monthly Revenue History",
  recorder: "Recorders",
  alertFeed: "Alert Feed",
  alertList: "Alert List",
  alertHistory: "Alert History",
  cvvDetection: "CVV Detections",
  inventory: "Inventory",
  storage: "Cloud Storage Use",
  loginHistory: "Login History",
};

export default function TableItemHeader(props: Props): JSX.Element {
  const listRef = useRef<any>(null);
  const [isOpenList, setIsOpenList] = useState<boolean>(false);
  const [isHover, setIsHover] = useState<boolean>(false);

  useEffect(() => {
    const onClick = (event: any) => {
      if (listRef.current && !listRef.current.contains(event.target)) {
        setIsOpenList(false);
      }
    };

    document.body.addEventListener("click", onClick);

    return function cleanup() {
      window.removeEventListener("click", onClick);
    };
  }, [listRef]);

  return (
    <s.Title>
      <h1 className="bold title-name">
        {keyTitle[props.layout.i as TableItemName]}
        {/* {props.isError && ( */}
        {/* <div
          className="table-item-alert-button"
          onMouseEnter={() => setIsHover(true)}
          onMouseLeave={() => setIsHover(false)}
        >
          <AlertSVG className="table-item-alert-svg" />
          {isHover && (
            <div className="bubble-container">
              <InfoBubble>Server Error</InfoBubble>
            </div>
          )}
        </div> */}
        {/* )} */}
      </h1>
      <div className="title-list-wrapper" ref={listRef}>
        <span className="title-list" onClick={() => setIsOpenList(!isOpenList)}>
          <FiMenu size={24} />
        </span>
        {isOpenList && (
          <TableItemList
            layout={props.layout}
            chartLayout={props.chartLayout}
            onDeleteWidget={props.onDeleteWidget}
            onLayoutChange={props.onLayoutChange}
          />
        )}
      </div>
    </s.Title>
  );
}
