import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  ProfileAccountInfo,
  ProfileAccountInfoFromServer,
} from "api/interfaces/accountInterface.interface";
import { LEVEL_TYPE } from "api/interfaces/commonInterface.interface";
import { UserPermission } from "api/interfaces/userInterface.interface";
import { PURGE } from "redux-persist";
import { AppThunk } from "redux/store";
import { isHiddenGroup } from "utils/AccountUtil";

const initialState: ProfileAccountInfo = {
  accountId: "",
  accountName: "",
  accountLevel: LEVEL_TYPE.INTL,
  accountNumber: "",
  accountAdditionalNumber: "",
  parentAccountId: "",
  parentLevel: "",
  timezone: "",
  dateFormat: "",
  timeFormat: "",
  companyName: "",
  companyLocation: "",
  companyLogo: "",
  description: "",
  isAdmin: false,
  isManager: false,
  checkedUserAdmin: false,
  use2faAuth: false,
  userPermission: {
    enableAllPermissions: false,
    manageAlertRules: false,
    viewAlerts: false,
    manageUsersAndGroups: false,
    viewUsersAndGroups: false,
    manageReportRules: false,
    viewReports: false,
    manageRecordersAndGroups: false,
    viewRecordersAndGroups: false,
    manageAccount: false,
    viewAccount: false,
    view: false,
    manageBillingAndLicenses: false,
  },
  isHidden: false,
  isSuspended: false,
  isFavorite: false,
};

// 사용자 로그인 후 Account 정보와 선택정보를 전역으로 관리.

export const accountSlice = createSlice({
  name: "accountSettings",
  initialState,
  reducers: {
    setAccountSetting(
      state,
      action: PayloadAction<ProfileAccountInfoFromServer>
    ) {
      let tmpInfo = {
        ...action.payload,
        isAdmin: checkGroup(action.payload.userPermission, "administrator"),
        isManager: checkGroup(action.payload.userPermission, "manager"),
        checkedUserAdmin: checkedUserAdmin(action.payload.userPermission),
        userPermission: checkPermission(action.payload.userPermission),
        isHidden: isHiddenGroup(action.payload.userPermission),
        // isFavorite: isHiddenGroup(action.payload.isFavorite),
      };

      const newState: ProfileAccountInfo = Object.assign({}, tmpInfo);
      return newState;
    },
    updateAccountThumbnail(state, action: PayloadAction<string>) {
      state.companyLogo = action.payload;
    },
    purgeAccount(state, action) {
      return initialState;
    },
  },
});

export const { setAccountSetting, purgeAccount } = accountSlice.actions;

export default accountSlice;

const checkGroup = (permission: UserPermission[], group: string) => {
  const check = permission.filter(
    (permit) =>
      (!permit.enableDelete && permit.groupName?.toLowerCase() === group) ||
      permit.enableAllPermissions
  );
  return check.length !== 0 ? true : false;
};

const checkedUserAdmin = (permission: UserPermission[]) => {
  const check = permission.filter(
    (permit) => permit.enableAllPermissions === true
  );
  return check.length !== 0 ? true : false;
};

const checkPermission = (array: UserPermission[]) => {
  const UserPermission = {
    enableAllPermissions:
      array !== undefined && array.length > 0
        ? array.reduce(function (sum, permission) {
            return sum + (permission.enableAllPermissions ? 1 : 0);
          }, 0) > 0
          ? true
          : false
        : true,
    manageAlertRules:
      array !== undefined && array.length > 0
        ? array.reduce(function (sum, permission) {
            return sum + (permission.manageAlertRules ? 1 : 0);
          }, 0) > 0
          ? true
          : false
        : true,
    viewAlerts:
      array !== undefined && array.length > 0
        ? array.reduce(function (sum, permission) {
            return sum + (permission.viewAlerts ? 1 : 0);
          }, 0) > 0
          ? true
          : false
        : true,
    manageUsersAndGroups:
      array !== undefined && array.length > 0
        ? array.reduce(function (sum, permission) {
            return sum + (permission.manageUsersAndGroups ? 1 : 0);
          }, 0) > 0
          ? true
          : false
        : true,
    viewUsersAndGroups:
      array !== undefined && array.length > 0
        ? array.reduce(function (sum, permission) {
            return sum + (permission.viewUsersAndGroups ? 1 : 0);
          }, 0) > 0
          ? true
          : false
        : true,
    manageReportRules:
      array !== undefined && array.length > 0
        ? array.reduce(function (sum, permission) {
            return sum + (permission.manageReportRules ? 1 : 0);
          }, 0) > 0
          ? true
          : false
        : true,
    viewReports:
      array !== undefined && array.length > 0
        ? array.reduce(function (sum, permission) {
            return sum + (permission.viewReports ? 1 : 0);
          }, 0) > 0
          ? true
          : false
        : true,
    manageRecordersAndGroups:
      array !== undefined && array.length > 0
        ? array.reduce(function (sum, permission) {
            return sum + (permission.manageRecordersAndGroups ? 1 : 0);
          }, 0) > 0
          ? true
          : false
        : true,
    viewRecordersAndGroups:
      array !== undefined && array.length > 0
        ? array.reduce(function (sum, permission) {
            return sum + (permission.viewRecordersAndGroups ? 1 : 0);
          }, 0) > 0
          ? true
          : false
        : true,
    manageAccount:
      array !== undefined && array.length > 0
        ? array.reduce(function (sum, permission) {
            return sum + (permission.manageAccount ? 1 : 0);
          }, 0) > 0
          ? true
          : false
        : true,
    viewAccount:
      array !== undefined && array.length > 0
        ? array.reduce(function (sum, permission) {
            return sum + (permission.viewAccount ? 1 : 0);
          }, 0) > 0
          ? true
          : false
        : true,
    view:
      array !== undefined && array.length > 0
        ? array.reduce(function (sum, permission) {
            return sum + (permission.view ? 1 : 0);
          }, 0) > 0
          ? true
          : false
        : true,
    manageBillingAndLicenses:
      array !== undefined && array.length > 0
        ? array.reduce(function (sum, permission) {
            return sum + (permission.manageBillingAndLicenses ? 1 : 0);
          }, 0) > 0
          ? true
          : false
        : true,
  };
  return UserPermission;
};
