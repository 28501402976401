import React, { useState } from "react";
import {
  FilterFn,
  useReactTable,
  getCoreRowModel,
  ColumnDef,
  flexRender,
} from "@tanstack/react-table";
import { rankItem, rankings } from "@tanstack/match-sorter-utils";
import * as s from "../BillingPaymentStyled";
import { BillingCustomers } from "api/interfaces/licenseInterface.interface";

export type Props = {
  data: BillingCustomers[];
};

const RankItemOptions = {
  threshold: rankings.CONTAINS,
};
const fuzzyFilter: FilterFn<any> = (row, columnId, value, addMeta) => {
  // Rank the item

  const itemRank = rankItem(row.getValue(columnId), value, RankItemOptions);

  // Store the itemRank info
  addMeta({
    itemRank,
  });

  // Return if the item should be filtered in/out
  return itemRank.passed;
};

export default function PaymentCustomerTable(props: Props): JSX.Element {
  const columns = React.useMemo<ColumnDef<BillingCustomers>[]>(
    () => [
      {
        accessorKey: "po",
        header: "CUSTOMER P.O.",
        cell: ({ getValue }) => getValue(),
      },
      {
        accessorKey: "payment",
        header: "PAYMENT DETAILS",
        cell: ({ getValue }) => getValue(),
      },
    ],
    []
  );
  const table = useReactTable({
    data: props.data,
    columns,
    filterFns: {
      fuzzy: fuzzyFilter,
    },

    getCoreRowModel: getCoreRowModel(),
    // debugTable: true,
  });

  const rows = table.getRowModel().rows;
  return (
    <s.TableWrapper>
      <table>
        <thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <th key={header.id} style={{ width: header.getSize() }}>
                  {header.isPlaceholder
                    ? null
                    : flexRender(
                        header.column.columnDef.header,
                        header.getContext()
                      )}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody>
          {rows.map((row) => {
            // console.log(row);
            return (
              <s.TBodyTR key={row.id}>
                {row.getVisibleCells().map((cell) => {
                  return (
                    <td key={cell.id}>
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </td>
                  );
                })}
              </s.TBodyTR>
            );
          })}
        </tbody>
      </table>
    </s.TableWrapper>
  );
}
