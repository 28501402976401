import * as s from "./LeftAsideStyled";
import X from "assets/icons/x_map.svg";
import * as mediaQuery from "components/MediaQuery";
import { useMediaQuery } from "react-responsive";

type DualProps = {
  children: React.ReactNode;
  isCollapsed: boolean;
  onClose: () => void;
};

const LeftAside = (props: DualProps): JSX.Element => {
  const isMobile = useMediaQuery({ maxWidth: mediaQuery.mobileMax });

  const variants = {
    open: { opacity: 1, left: "10px" },
    closed: { opacity: 0, left: "-350px" },
  };

  const mobileVariants = {
    open: { opacity: 1, top: "0" },
    closed: { opacity: 0, top: "-350px" },
  };

  return (
    <s.Container
      initial={"closed"}
      animate={props.isCollapsed ? "open" : "closed"}
      variants={isMobile ? mobileVariants : variants}
    >
      <mediaQuery.Default>
        <div className="button-wrapper">
          <button onClick={props.onClose}>
            <img src={X} />
          </button>
        </div>
      </mediaQuery.Default>

      <s.Inner>{props.children}</s.Inner>
    </s.Container>
  );
};

export default LeftAside;
