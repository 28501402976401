/**
 * @author ydoni
 * @description Additional Account ID bubble
 */

import InfoBubble from "./InfoBubble";
import { ProfileAccountInfo } from "api/interfaces/accountInterface.interface";
import { useAppSelector } from "redux/hooks";

type Props = {};

function AdditionalAccountBubble({}: Props): JSX.Element {
  const selectedAccount: ProfileAccountInfo = useAppSelector(
    (state) => state.accountSettings
  );
  const text =
    selectedAccount.accountLevel === "CP"
      ? `
        If this organization has a DW CPP (Channel Partner Program) number
        provided to them <br />
        you may enter that number here so that you can view it on myDW. <br />
        If you are not sure what the number is. <br />
        Please contact your accounting administrator or Digital Watchdog.
      `
      : `
          If this organization has an additional ID number
            <br />
          provided by a third party (such as Customer ID, CRM ID, group ID, etc.)
            <br />
          You may enter that number here so that you may view it on myDW.
        `;
  return (
    <InfoBubble>
      <div
        dangerouslySetInnerHTML={{ __html: text }}
        style={{ textAlign: "left" }}
      />
    </InfoBubble>
  );
}

export default AdditionalAccountBubble;
