import {
  NavigateFunction,
  NavigateOptions,
  To,
  createSearchParams,
  useNavigate,
} from "react-router-dom";
import { ReactComponent as AccountSVG } from "assets/icons/sidenav/account.svg";
import { ReactComponent as RecorderSVG } from "assets/icons/sidenav/recorder.svg";
import { ReactComponent as AlertSVG } from "assets/icons/sidenav/alerts.svg";
import { ReactComponent as VideoSVG } from "assets/icons/sidenav/videoshare.svg";
import { ReactComponent as BillingSVG } from "assets/icons/sidenav/billing.svg";
import { ReactComponent as ReportSVG } from "assets/icons/sidenav/reports.svg";
import { ReactComponent as UserSVG } from "assets/icons/sidenav/users.svg";
import { ReactComponent as ManageSVG } from "assets/icons/sidenav/settings.svg";
import accountSVG from "assets/icons/sidenav/account.svg";
import alertSVG from "assets/icons/sidenav/alerts.svg";
import videoSVG from "assets/icons/sidenav/videoshare.svg";
import billingSVG from "assets/icons/sidenav/billing.svg";
import helpSVG from "assets/icons/sidenav/help.svg";
import reportSVG from "assets/icons/sidenav/reports.svg";
import settingSVG from "assets/icons/sidenav/settings.svg";
import userSVG from "assets/icons/sidenav/users.svg";
import manageSVG from "assets/icons/sidenav/settings.svg";
import { useAppDispatch } from "redux/hooks";
import { setMenu } from "redux/reducers/menu/menuSlice";
import { Dispatch } from "react";
import { AnyAction } from "@reduxjs/toolkit";
import { RoutesProps } from "api/interfaces/menuInterface.interface";
import {
  BasePagination,
  DefaultQueryParam,
  LEVEL_TYPE,
} from "api/interfaces/commonInterface.interface";
import { pickBy, replace } from "lodash";
import {
  ProfileAccountInfo,
  ProfileAccountInfoFromServer,
} from "api/interfaces/accountInterface.interface";
import {
  checkGroup,
  checkPermission,
  checkTrialShowMenu,
  isCheckPermission,
  isHiddenGroup,
} from "./AccountUtil";

export interface MenuList {
  svg: any;
  label: string;
  routeName: string;
  includeRouteName: string;
  hidden: boolean;
  disabled?: boolean;
  permission: string;
}

export const initializeMenuList: MenuList[] = [
  {
    svg: <AccountSVG />,
    label: "Organization",
    routeName: "/account",
    includeRouteName: "/account",
    hidden: false,
    disabled: false,
    permission: "viewAccount",
  },
  {
    svg: <RecorderSVG />,
    label: "Recorders",
    routeName: "/recorders/list",
    includeRouteName: "/recorders",
    hidden: false,
    disabled: false,
    permission: "viewRecordersAndGroups",
  },
  {
    svg: <AlertSVG />,
    label: "Alert",
    routeName: "/alert/active",
    includeRouteName: "/alert",
    hidden: false,
    disabled: false,
    permission: "viewAlerts",
  },
  {
    svg: <VideoSVG />,
    label: "Video Share",
    routeName: "/videoShare",
    includeRouteName: "/videoShare",
    hidden: false,
    disabled: false,
    permission: "view",
  },
  {
    svg: <ReportSVG />,
    label: "Reports",
    routeName: "/reports",
    includeRouteName: "/reports",
    hidden: false,
    permission: "viewReports",
  },
  {
    svg: <UserSVG />,
    label: "Users",
    routeName: "/users/list",
    includeRouteName: "/users",
    hidden: false,
    disabled: false,
    permission: "viewUsersAndGroups",
  },
  {
    svg: <BillingSVG />,
    label: "Billing",
    routeName: "/billing/customeraccount",
    includeRouteName: "/billing",
    hidden: false,
    disabled: false,
    permission: "manageBillingAndLicenses",
  },
  {
    svg: <ManageSVG />,
    label: "Management",
    routeName: "/manager/guideSetting",
    includeRouteName: "/manager",
    hidden: false,
    disabled: false,
    permission: "viewAccount",
  },
];

export const dwMenuData: Array<RoutesProps> = [
  {
    svg: null,
    name: "Dashboard",
    routeName: "/dashboard",
    isRole: "rw",
    children: [
      {
        svg: null,
        isRole: "rw",
        name: "Map",
        routeName: "/dashboard/map",
        ishidden: false,
      },

      {
        svg: null,
        isRole: "rw",
        name: "Stats",
        routeName: "/dashboard/admin",
        ishidden: false,
      },
      {
        svg: null,
        isRole: "rw",
        name: "Widgets",
        routeName: "/dashboard/chart",
        ishidden: false,
      },
    ],
    ishidden: true,
  },
  {
    svg: accountSVG,
    name: "Organization",
    routeName: "/account",
    isRole: "viewAccount",
    ishidden: false,
    children: [],
  },
  {
    svg: accountSVG,
    name: "Recorders",
    routeName: "/recorders",
    isRole: "viewRecordersAndGroups",
    ishidden: true,
    children: [
      {
        svg: null,
        isRole: "viewRecordersAndGroups",
        name: "Recorders",
        title: "Recorder List",
        routeName: "/recorders/list",
        ishidden: false,
      },
      {
        svg: null,
        isRole: "viewRecordersAndGroups",
        name: "Recorder Groups",
        title: "Recorder Groups",
        routeName: "/recorders/groups",
        ishidden: false,
      },
    ],
  },
  {
    svg: alertSVG,
    name: "Alert",
    isRole: "viewAlerts",
    routeName: "/alert/active",
    ishidden: false,
    children: [
      {
        svg: null,
        isRole: "viewAlerts",
        name: "Alerts",
        title: "Alert",
        routeName: "/alert/active",
        ishidden: false,
      },
      {
        svg: null,
        isRole: "viewAlerts",
        name: "Alert History",
        title: "Alert History",
        routeName: "/alert/history",
        ishidden: false,
      },
      {
        svg: null,
        isRole: "viewAlerts",
        name: "Rules",
        title: "Rules",
        routeName: "/alert/rules",
        ishidden: false,
      },
    ],
  },
  {
    svg: videoSVG,
    name: "Video Share",
    routeName: "/videoShare",
    isRole: "rw",
    ishidden: false,
    children: [],
  },
  {
    svg: reportSVG,
    name: "Reports",
    routeName: "/reports",
    isRole: "rw",
    ishidden: false,
    children: [
      {
        svg: null,
        isRole: "rw",
        name: "",
        title: "Report List",
        routeName: "/reports",
        ishidden: false,
      },
    ],
  },
  {
    svg: userSVG,
    name: "Users",
    routeName: "/users",
    isRole: "viewUsersAndGroups",
    ishidden: false,
    children: [
      {
        svg: null,
        isRole: "viewUsersAndGroups",
        name: "Users",
        title: "User List",
        routeName: "/users/list",
        ishidden: false,
      },
      {
        svg: null,
        isRole: "viewUsersAndGroups",
        name: "User Groups",
        title: "User Groups",
        routeName: "/users/groups",
        ishidden: false,
      },
      // {
      //   svg: null,
      //   isRole: "rw",
      //   name: "Roles",
      //   title: "User Roles",
      //   routeName: "/users/roles",
      //   ishidden: false,
      // },
    ],
  },
  {
    svg: billingSVG,
    name: "Billing",
    routeName: "/billing",
    isRole: "rw",
    ishidden: false,
    children: [],
    subRouteName: ["/billing/license"],
  },
  {
    svg: settingSVG,
    name: "Settings",
    routeName: "/settings",
    isRole: "rw",
    ishidden: false,
    children: [
      {
        svg: null,
        isRole: "rw",
        name: "My Profile",
        routeName: "/settings/myaccount",
        ishidden: false,
      },
      {
        svg: null,
        isRole: "rw",
        name: "My Organization",
        routeName: "/settings/adminAccount",
        ishidden: false,
      },
      {
        svg: null,
        isRole: "rw",
        name: "Licenses",
        routeName: "/settings/license",
        ishidden: false,
      },
    ],
  },
  {
    svg: helpSVG,
    name: "Help",
    routeName: "/help/videoguides",
    isRole: "rw",
    ishidden: false,
    children: [
      {
        svg: null,
        isRole: "rw",
        name: "Video Guides",
        title: "Video Guides",
        routeName: "/help/videoguides",
        ishidden: false,
      },
      {
        svg: null,
        isRole: "rw",
        name: "Tech Support",
        title: "Tech Support",
        routeName: "/help/techsupport",
        ishidden: false,
      },
      // {
      //   svg: null,
      //   isRole: "rw",
      //   name: "Setup Guide",
      //   title: "Setup Guide",
      //   routeName: "/help/setupguide",
      //   ishidden: false,
      // },
      // {
      //   svg: null,
      //   isRole: "rw",
      //   name: "Speed Test",
      //   title: "Speed Test",
      //   routeName: "/help/speedtest",
      //   ishidden: false,
      // },
      {
        svg: null,
        isRole: "rw",
        name: "About",
        title: "About",
        routeName: "/help/about",
        ishidden: false,
      },
    ],
  },
  {
    svg: settingSVG,
    name: "Management",
    routeName: "/manager/guideSetting",
    isRole: "rw",
    ishidden: false,
    children: [
      {
        svg: null,
        isRole: "rw",
        name: "Video Guide Setting",
        title: "Video Guide Setting",
        routeName: "/manager/guideSetting",
        ishidden: false,
      },
      {
        svg: null,
        isRole: "rw",
        name: "Tech Support",
        title: "Tech Support",
        routeName: "/manager/techSummit",
        ishidden: false,
      },
      // {
      //   svg: null,
      //   isRole: "rw",
      //   name: "Operation Log",
      //   title: "Operation Log",
      //   routeName: "/manager/operationLog",
      //   ishidden: false,
      // },
      {
        svg: null,
        isRole: "rw",
        name: "Site Notices",
        routeName: "/manager/sitenotices",
        ishidden: false,
      },
      {
        svg: null,
        isRole: "rw",
        name: "Emails",
        routeName: "/manager/emails",
        ishidden: false,
      },
    ],
  },
];

export const GoPageNav = (
  navigate: NavigateFunction,
  dispatch: Dispatch<AnyAction>,
  to: string,
  options?: NavigateOptions
) => {
  const selectMenu = dwMenuData.find((menu) => {
    return (
      menu.routeName === to ||
      (menu.routeName !== undefined && to.startsWith(menu.routeName)) ||
      (menu.subRouteName !== undefined && menu.subRouteName.includes(to)) ||
      menu.children.find((subMenu) => subMenu.routeName === to) !== undefined
    );
  });

  if (selectMenu !== undefined) {
    navigate(to, options);
    dispatch(setMenu(selectMenu.name));
    return true;
  } else {
    return false;
  }
};

export const getSubMenu = (to: string) => {
  const selectMenu = dwMenuData.find((menu) => {
    return (
      menu.routeName === to ||
      to.startsWith(menu.routeName as string) ||
      (menu.routeName !== undefined && to.startsWith(menu.routeName)) ||
      (menu.subRouteName !== undefined && menu.subRouteName.includes(to)) ||
      menu.children.find(
        (subMenu) =>
          subMenu.routeName === to || to.startsWith(subMenu.routeName as string)
      ) !== undefined
    );
  });

  if (selectMenu) {
    return selectMenu;
  } else {
    return undefined;
  }
};

export const queryNavigate = (
  navigate: NavigateFunction,
  to: string,
  queryInfo: DefaultQueryParam,
  selectAccount?: ProfileAccountInfo
) => {
  let realQuery = Object.entries(queryInfo)
    .filter((data) => data[1] !== null && data[1] !== undefined)
    .map((e) => {
      if (e[1] !== undefined && e[1] !== null) {
        return e[0].concat("=").concat(encodeURIComponent(e[1]));
      } else {
        return "";
      }
    })
    .join("&");
  //navigate(`${to}?${realQuery}`);
  if (selectAccount !== undefined) {
    realQuery = `account=${selectAccount.accountId}&${realQuery}`;
  }

  navigate({
    pathname: to,
    search: `?${realQuery}`,
  });
  //window.history.pushState(null, "", `${to}?${encodeURIComponent(realQuery)}`);
};

export const menuNavigation = (
  selectedAccount: ProfileAccountInfo | ProfileAccountInfoFromServer | string,
  navigate: NavigateFunction,
  to: string,
  replace?: boolean,
  state?: any,
  queryString?: Object
) => {
  let realQuery =
    queryString !== undefined
      ? Object.entries(queryString)
          .map((e) => e.join("="))
          .join("&")
      : undefined;
  if (state !== undefined) {
    navigate(
      {
        pathname: to,
        search:
          realQuery !== undefined
            ? `?${createSearchParams({
                account:
                  typeof selectedAccount === `string`
                    ? selectedAccount
                    : selectedAccount.accountId,
              })}&${realQuery}`
            : `?${createSearchParams({
                account:
                  typeof selectedAccount === `string`
                    ? selectedAccount
                    : selectedAccount.accountId,
              })}`,
      },
      {
        state: state,
        replace: replace !== undefined ? replace : false,
      }
    );
  } else {
    navigate(
      {
        pathname: to,
        search:
          realQuery !== undefined
            ? `?${createSearchParams({
                account:
                  typeof selectedAccount === `string`
                    ? selectedAccount
                    : selectedAccount.accountId,
              })}&${realQuery}`
            : `?${createSearchParams({
                account:
                  typeof selectedAccount === `string`
                    ? selectedAccount
                    : selectedAccount.accountId,
              })}`,
      },
      {
        replace: replace !== undefined ? replace : false,
      }
    );
  }
};

export const checkMenuRole = (
  routeName: string,
  childRoute: string,
  selectedAccount: ProfileAccountInfoFromServer
) => {
  const matchedUrl = initializeMenuList.find((menu) =>
    routeName.startsWith(menu.includeRouteName)
  );
  const selectMenu = dwMenuData.find((menu) => {
    return (
      menu.routeName === routeName ||
      (menu.routeName !== undefined && routeName.startsWith(menu.routeName)) ||
      (menu.subRouteName !== undefined &&
        menu.subRouteName.includes(routeName)) ||
      menu.children.find((subMenu) => subMenu.routeName === routeName) !==
        undefined
    );
  });

  const isAdmin = checkGroup(selectedAccount.userPermission, "administrator");
  const isHidden = isHiddenGroup(selectedAccount.userPermission);
  const userPermission = checkPermission(selectedAccount.userPermission);

  if (
    selectMenu?.routeName === "/dashboard" ||
    selectMenu?.routeName === "/help/videoguides"
  ) {
    return true;
    // if(routeName === "/dashboard/admin"){
    //   if(isAdmin){
    //     return true;
    //   }else{
    //     return false;
    //   }
    // }
    // if (
    //   (routeName === "/dashboard/map" ||
    //     routeName === "/dashboard/chart") &&
    //   selectedAccount.accountLevel === LEVEL_TYPE.DW
    // ){
    //   return false;
    // }else{
    //   return true;
    // }
  }
  if (selectMenu?.routeName === "/settings") {
    if (childRoute === "/settings/adminAccount") {
      if (isAdmin) {
        return true;
      } else {
        return false;
      }
    } else if (childRoute === "/settings/license") {
      if (selectedAccount.accountLevel !== LEVEL_TYPE.EU || !isAdmin) {
        return false;
      } else {
        return true;
      }
    } else {
      return true;
    }
  }

  if (matchedUrl) {
    //Dashboard Check
    if (matchedUrl.routeName.startsWith("/dashboard")) {
      if (matchedUrl.routeName === "/dashboard/admin") {
        if (isAdmin) {
          return true;
        } else {
          return false;
        }
      }
      if (
        (matchedUrl.routeName === "/dashboard/chart" &&
          (selectedAccount.accountLevel === LEVEL_TYPE.DW || !isAdmin)) ||
        (matchedUrl.routeName === "/dashboard/map" &&
          selectedAccount.accountLevel === LEVEL_TYPE.DW)
      ) {
        return false;
      } else {
        return true;
      }
    }

    if (selectedAccount.accountLevel === LEVEL_TYPE.EU) {
      if (checkTrialShowMenu(selectedAccount)) {
        if (
          matchedUrl.routeName.includes("/reports") ||
          matchedUrl.routeName.includes("/videoShare") ||
          matchedUrl.routeName.includes("/billing") ||
          matchedUrl.routeName.includes("/alert")
        ) {
          return false;
        } else return true;
      } else {
        if (
          matchedUrl.routeName.includes("/account") ||
          matchedUrl.routeName.includes("/recorder")
        ) {
          return true;
        } else if (matchedUrl.routeName.includes("/videoShare")) {
          return !isHidden ? userPermission.view : false;
        } else return true;
      }
    } else {
      if (
        matchedUrl.routeName.includes("/recorder") ||
        matchedUrl.routeName.includes("/videoShare")
      ) {
        return false;
      } else if (matchedUrl.routeName.includes("/manager")) {
        if (selectedAccount.accountLevel === LEVEL_TYPE.DW) {
          return true;
        } else {
          return false;
        }
      } else if (
        matchedUrl.routeName.includes("/alert") ||
        matchedUrl.routeName.includes("/reports")
      ) {
        if (selectedAccount.accountLevel !== LEVEL_TYPE.DW) {
          return true;
        } else {
          return false;
        }
      } else {
        return true;
      }
    }
  } else {
    return false;
  }
};
