import {
  ExpandedState,
  FilterFn,
  flexRender,
  getCoreRowModel,
  getExpandedRowModel,
  getFilteredRowModel,
  getSortedRowModel,
  Row,
  RowSelectionState,
  SortingState,
  useReactTable,
} from "@tanstack/react-table";
import { useVirtual } from "react-virtual";
import React, { HTMLProps, useEffect } from "react";
import { Fragment, useState } from "react";
import * as s from "./VGroupTableStyled";

import { RankingInfo, rankItem, rankings } from "@tanstack/match-sorter-utils";

type TableProps = {
  columns: any[];
  data: Array<any>;
  onRowClicked?: (row: any, event: any) => void;
  onSelectedRowsChange?: (row: RowSelectionState) => void;
  onChangePage?: (page: number, totalRows: number) => void;
  selectedRow?: RowSelectionState;
  totalRows?: number;
  paginationPerPage?: number;
  selectableRowsSingle?: boolean;
  disabled?: boolean;
  search?: string;
  isSingleMode?: boolean;
  isSubSelection?: boolean;
};

declare module "@tanstack/table-core" {
  interface FilterFns {
    fuzzy: FilterFn<unknown>;
  }
  interface FilterMeta {
    itemRank: RankingInfo;
  }
}

const RankItemOptions = {
  threshold: rankings.CONTAINS,
};
const fuzzyFilter: FilterFn<any> = (row, columnId, value, addMeta) => {
  if (row.getParentRow() !== undefined) {
    let itemRank = rankItem(
      row.getParentRow()?.getValue(columnId),
      value,
      RankItemOptions
    );
    itemRank.rankedValue = row.getValue(columnId);
    addMeta({
      itemRank,
    });
    return itemRank.passed;
  } else {
    const itemRank = rankItem(row.getValue(columnId), value, RankItemOptions);
    addMeta({
      itemRank,
    });
    return itemRank.passed;
  }
};

export function VGroupTable({
  data,
  columns,
  search,
  selectedRow,
  onSelectedRowsChange,
  isSingleMode,
  isSubSelection,
}: TableProps): JSX.Element {
  const [globalFilter, setGlobalFilter] = React.useState("");
  const [rowSelection, setRowSelection] = React.useState<RowSelectionState>({});
  const [sorting, setSorting] = React.useState<SortingState>([]);
  const [expanded, setExpanded] = React.useState<ExpandedState>({});
  const [selectedRowId, setSelectedRowId] = useState(null);
  const selectedRowIds = [];
  if (selectedRowId) {
    selectedRowIds.push(selectedRowId);
  }
  useEffect(() => {
    setGlobalFilter(String(search));
  }, [search]);

  const table = useReactTable({
    data,
    columns,
    filterFns: {
      fuzzy: fuzzyFilter,
    },
    state: {
      rowSelection,
      sorting,
      expanded,
      globalFilter,
    },

    onGlobalFilterChange: setGlobalFilter,
    globalFilterFn: fuzzyFilter,
    getFilteredRowModel: getFilteredRowModel(),
    onExpandedChange: setExpanded,
    getSubRows: (row) => row.subRows,
    getRowId: (row) =>
      isSubSelection !== undefined && isSubSelection
        ? row.subRows !== undefined
          ? row.id + ".merged"
          : row.id
        : row.id,
    // row.subRows !== undefined ? row.id + ".merged" : row.id,
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
    enableSubRowSelection:
      isSubSelection !== undefined && isSubSelection ? true : false,
    onRowSelectionChange: setRowSelection,
    filterFromLeafRows: true,
    paginateExpandedRows: true,
    enableMultiRowSelection:
      isSingleMode !== undefined && isSingleMode ? false : true,

    debugTable: false,
  });

  useEffect(() => {
    // console.log({rowSelection});
    if (onSelectedRowsChange !== undefined) {
      onSelectedRowsChange(rowSelection);
    }
  }, [rowSelection]);

  useEffect(() => {
    if (selectedRow !== undefined && Object.keys(selectedRow).length !== 0) {
      setRowSelection(selectedRow as RowSelectionState);
    }
  }, [selectedRow]);

  const tableContainerRef = React.useRef<HTMLDivElement>(null);

  const { rows } = table.getRowModel();
  const rowVirtualizer = useVirtual({
    parentRef: tableContainerRef,
    size: rows.length,
    overscan: 30,
  });
  const { virtualItems: virtualRows, totalSize } = rowVirtualizer;

  const paddingTop = virtualRows.length > 0 ? virtualRows?.[0]?.start || 0 : 0;
  const paddingBottom =
    virtualRows.length > 0
      ? totalSize - (virtualRows?.[virtualRows.length - 1]?.end || 0)
      : 0;

  return (
    <Fragment>
      <s.VGroupContainer ref={tableContainerRef}>
        <table>
          <thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => {
                  return (
                    <th
                      key={header.id}
                      colSpan={header.colSpan}
                      style={{ width: header.column.columnDef.size }}
                    >
                      {header.isPlaceholder ? null : (
                        <div className="th-row">
                          {flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                          )}
                        </div>
                      )}
                    </th>
                  );
                })}
              </tr>
            ))}
          </thead>
          <tbody>
            {paddingTop > 0 && (
              <tr>
                <td style={{ height: `${paddingTop}px` }} />
              </tr>
            )}
            {virtualRows.map((virtualRow) => {
              const row = rows[virtualRow.index] as Row<any>;
              return (
                <tr key={row.id}>
                  {row.getVisibleCells().map((cell) => {
                    return (
                      <td key={cell.id}>
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext()
                        )}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
            {paddingBottom > 0 && (
              <tr>
                <td style={{ height: `${paddingBottom}px` }} />
              </tr>
            )}
          </tbody>
        </table>
      </s.VGroupContainer>
    </Fragment>
  );
}

export function IndeterminateCheckbox({
  indeterminate,
  className = "",
  ...rest
}: { indeterminate?: boolean } & HTMLProps<HTMLInputElement>) {
  const ref = React.useRef<HTMLInputElement>(null!);

  React.useEffect(() => {
    if (typeof indeterminate === "boolean") {
      ref.current.indeterminate = !rest.checked && indeterminate;
    }
  }, [ref, indeterminate, rest.checked]);

  return (
    <input
      type="checkbox"
      ref={ref}
      className={className + " cursor-pointer"}
      {...rest}
    />
  );
}

export function IndeterminateRadio({
  indeterminate,
  onChangeIndeterminateRadio,
  className = "",
  ...rest
}: {
  indeterminate?: boolean;
  onChangeIndeterminateRadio: React.ChangeEventHandler<HTMLInputElement>;
} & HTMLProps<HTMLInputElement>) {
  const ref = React.useRef<HTMLInputElement>(null!);

  // React.useEffect(() => {
  //   if (typeof indeterminate === "boolean") {
  //     ref.current.indeterminate = !rest.checked && indeterminate;
  //   }
  // }, [ref, indeterminate, rest.checked]);

  return (
    <input
      type="radio"
      ref={ref}
      {...rest}
      onChange={onChangeIndeterminateRadio}
      // style={{
      //   width: "12px",
      //   height: "12px",
      // }}
    />
  );
}
