import styled, { DefaultTheme } from "styled-components";
import { COLORS } from "styles/colors";
import { FLEX } from "styles/flex";
import { calcRem } from "styles/theme";
import * as mediaQuery from "components/MediaQuery";

interface Props {
  buttonType?: string;
  table?: boolean;
  disabled?: boolean;
}

const DefaultButton = styled.button`
  padding: 8px 10px;
  border-radius: 3px;

  ${FLEX.FlexCenterCenter};
  gap: 7px;
  cursor: pointer;
  color: ${({ theme }) => theme.COLORS.WHITE} !important;

  &:focus,
  &:hover {
    filter: brightness(120%) saturate(120%);
  }
`;

export const Container = styled(DefaultButton)<Props>`
  ${(props) =>
    props.buttonType === "cancel"
      ? `
      background-color: ${COLORS.BUTTONS1};
    `
      : `
        background-color: ${props.theme.COLORS.BUTTONS2};
    `};

  ${(props) =>
    props.buttonType === "warning" &&
    `
      background-color: ${COLORS.RED};
    `};
  ${(props) =>
    props.buttonType === "view" &&
    `
      background-color: ${COLORS.PROGRESSBAR};
    `};
  ${(props) =>
    props.disabled &&
    `
    opacity: 0.25;
  `}
  ${mediaQuery.isMobile} {
    padding: 6px;
  }
`;

export const TinyButton = styled(Container)`
  padding: 4px 6px;
  gap: 3px;

  > span {
    font-size: ${calcRem(12)};
    color: ${COLORS.WHITE};
    font-weight: 500;
    ${FLEX.FlexCenterCenter};
  }
  .tiny-button-icon {
    color: ${COLORS.WHITE};
  }

  .tiny-button-icon > img {
    height: 10px;
  }
`;

export const BigButton = styled(Container)<Props>`
  /* flex: 1; */
  white-space: nowrap;
  padding: 8px;
  box-sizing: border-box;
  &:focus,
  &:hover {
    filter: brightness(120%) saturate(120%);
  }

  ${mediaQuery.isDefault} {
    min-width: 130px;
    padding: 10px;
  }
  ${mediaQuery.isMobile} {
    min-width: 70px;
    min-width: max-content;
    width: 100%;
  }

  > img {
    width: 14px;
  }
`;

export const ButtonLabel = styled.span<Props>`
  color: ${COLORS.WHITE};
  font-weight: 500;
  ${FLEX.FlexCenterCenter};
  gap: 3px;
  font-size: ${calcRem(15)};

  ${({ table }) =>
    table &&
    `
font-size: ${calcRem(14)};
`}
`;

export const MobileTableButtonIconWrapper = styled.button`
  /* padding: 5px; */
  color: ${({ theme }) => theme.COLORS.WHITE};
`;
