import ModalTemplate from "components/templates/modal/ModalTemplate";
import * as s from "./VideoGuideStyled";
import CLOSE_ICON from "assets/icons/cross.svg";
import ReactPlayer from "react-player/youtube";

type Props = {
  videoId: string;
  onChangeModal: () => void;
};

export default function VideoGuidesPlay(props: Props): JSX.Element {
  // console.log(props.videoId);
  return (
    <ModalTemplate>
      <s.ModalContainer>
        <button className="close-button" onClick={() => props.onChangeModal()}>
          <img src={CLOSE_ICON} alt="close icon" />
        </button>
        <s.VideoPlay>
          <ReactPlayer
            url={props.videoId}
            controls={true}
            config={opts}
            width={"70vw"}
            height={"70vh"}
          />
        </s.VideoPlay>
      </s.ModalContainer>
    </ModalTemplate>
  );
}

const opts = {
  // width: "100%",
  // height: "100%",
  playerVars: {
    autoplay: 0, //자동재생 O
    rel: 0, //관련 동영상 표시하지 않음 (근데 별로 쓸모 없는듯..)
    modestbranding: 1, // 컨트롤 바에 youtube 로고를 표시하지 않음
  },
};
