import { ResponsiveLine, LineProps, PointTooltipProps } from "@nivo/line";
import { COLORS } from "styles/colors";
import LineChartTooltip from "./LineChartTooltip";
import { useAppSelector } from "redux/hooks";

export default function LineChart(props: LineProps): JSX.Element {
  const themeMode = useAppSelector((state) => state.theme.theme);
  const color = themeMode === "light" ? COLORS.BLACK : COLORS.WHITE;
  return (
    // <div style={{width:"95%", height:"100%"}}>
    <ResponsiveLine
      margin={{ top: 30, right: 45, bottom: 80, left: 30 }}
      xScale={{ type: "point" }}
      yScale={{
        type: "linear",
        min: 0,
        max: "auto",
        stacked: true,
        reverse: false,
      }}
      // yFormat=" >-.2f"
      axisTop={null}
      axisRight={null}
      axisBottom={{
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 45,

        legendOffset: 36,
        legendPosition: "middle",
      }}
      axisLeft={{
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        legend: "count",
        legendOffset: -40,
        legendPosition: "middle",
        format: (e) => Math.floor(e) === e && e,
      }}
      colors={{
        datum: "color",
      }}
      lineWidth={1}
      tooltip={LineChartTooltip}
      enableGridX={false}
      pointSize={5}
      pointColor={COLORS.COMPANY}
      pointLabelYOffset={-12}
      useMesh={true}
      legends={[]}
      theme={{
        crosshair: {
          line: {
            strokeWidth: 0.5,
            stroke: `${color}`,
          },
        },
        axis: {
          ticks: {
            line: {
              stroke: `${color}70`,
            },
            text: {
              // fill: COLORS.BUTTONS1,
              fill: `${color}70`,
            },
          },
        },
        grid: {
          line: {
            // stroke: COLORS.BORDER,
            stroke: `${color}30`,
            strokeWidth: 0.8,
            strokeDasharray: "4 4",
          },
        },
      }}
      {...props}
    />
    // </div>
  );
}
