import AlertDetailModal from "components/templates/modal/alert";
import { SelectedAlertView } from "api/interfaces/alertInterface.interface";
type Props = {
  selectedAlert: SelectedAlertView;
};

const RecorderDetailAlert = (props: Props): JSX.Element => {
  return (
    <AlertDetailModal
      onModal={() => null}
      selectedAlert={props.selectedAlert}
    />
  );
};

export default RecorderDetailAlert;
