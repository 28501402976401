import styled from "styled-components";
import { FLEX } from "../../../styles/flex";
import * as mediaQuery from "components/MediaQuery";
import { calcRem } from "styles/theme";
import { COLORS } from "styles/colors";

export const SubHeader = styled.div`
  width: 100%;
  padding: 30px 0 5px;
`;

export const HeaderContainer = styled.div`
  ${mediaQuery.isDefault} {
    ${FLEX.FlexBetweenCenter};
    gap: 20px;
    padding: 8px 0;
  }
  ${mediaQuery.isMobile} {
    ${FLEX.FlexStartStart};
    flex-direction: column;
    gap: 10px;
  }
  .storage-label-mobile {
    width: 100%;
  }
  .storage-label-default {
    min-width: 400px;
  }
`;

export const TopWrapper = styled.div`
  ${FLEX.FlexBetweenCenter};
`;

export const Title = styled.div`
  /* flex: 1;
  width: 100%; */

  /* ${mediaQuery.isMobile} {
    overflow: hidden;
  } */
`;

export const TitleLeft = styled.div`
  ${FLEX.FlexStartCenter};
  align-items: baseline;
  gap: 50px;
  ${mediaQuery.isDefault} {
    flex: 1;
  }
`;

export const RightAside = styled.ul`
  ${mediaQuery.isDefault} {
    flex: 2 1 auto;
    ${FLEX.FlexEndCenter};
    /* max-width: 40%; */
    gap: 8px;
  }
  ${mediaQuery.isMobile} {
    width: 100%;
    ${FLEX.FlexBetweenCenter};
    gap: 7px;
  }
`;

const CommonRow = `
${FLEX.FlexStartCenter};
gap: 10px;
`;

export const MobileRow = styled.div`
  ${CommonRow};
  justify-content: space-between;
  flex: 1;
  width: 100%;
`;

export const Row = styled.button`
  ${CommonRow};
`;

export const CenterAside = styled.div`
  gap: 50px;

  ${mediaQuery.isMobile} {
    flex: 1;
    ${FLEX.FlexStartCenter};
  }
  ${mediaQuery.isDefault} {
    ${FLEX.FlexStartCenter};
    gap: 20px;
    flex: 1 0 auto;
    /* flex: 2;
    padding: 20px 0; */
  }
`;

export const SearchInputWrapper = styled.div`
  ${mediaQuery.isMobile} {
    width: 100%;
    /* max-width: 75%; */
    flex: 6;
  }
  ${mediaQuery.isDefault} {
    max-width: 250px;
  }
`;

export const TitleWrapper = styled.ul`
  ${FLEX.FlexStartCenter};
  align-items: baseline;

  gap: 4px;

  .chevron-right {
    color: ${(props) => props.theme.COLORS.STATUS};
    ${FLEX.FlexCenterEnd};
    align-items: baseline;
  }

  ${mediaQuery.isMobile} {
    flex: 2;
    width: 100%;
  }
`;

export const TitleCrumb = styled.li<{ index: number }>`
  font-size: ${calcRem(16)};
  font-weight: normal;
  cursor: pointer;

  color: ${(props) => props.theme.COLORS.STATUS};
  white-space: nowrap;

  ${({ index, theme }) =>
    index === 0 &&
    `
      font-weight: 400;
      font-size: ${calcRem(24)};
      color: ${theme.COLORS.WHITE};
  `}

  &:hover {
    text-decoration: underline;
  }

  ${mediaQuery.isMobile} {
    ${({ index }) =>
      index > 0 &&
      `
      overflow: hidden;
      text-overflow: ellipsis;
    `}
  }
`;

export const FirstTitle = styled.span`
  font-weight: 400;
  font-size: ${calcRem(24)};
  color: ${(props) => props.theme.COLORS.WHITE};
  white-space: nowrap;
`;

export const BigButtonBox = styled.div`
  ${FLEX.FlexEndCenter};
  /* max-width: 160px; */
  flex: 1;

  ${mediaQuery.isMobile} {
    flex-basis: 80px;
  }
`;

export const Back = styled.div`
  ${FLEX.FlexEndCenter};
  /* gap: 6px; */
  cursor: pointer;

  > img {
    width: 5px;
  }
`;
