import styled from "styled-components";
import { FLEX } from "styles/flex";
import * as mediaQuery from "components/MediaQuery";
import { COLORS, INDECREASECOLORS } from "styles/colors";
import { calcRem } from "styles/theme";
import { IncreaseString } from "./CardItem";

export const Item = styled.li`
  width: 100%;
  height: 100%;
  color: ${({ theme }) => theme.COLORS.WHITE};
  ${FLEX.FlexStartStart};
  flex: 1;
  flex-direction: column;
  gap: 10px;
  height: 200px;

  .bold {
    font-weight: 500;
    font-size: ${calcRem(20)};
  }

  .content {
    width: 100%;
    background-color: ${({ theme }) =>
      theme.value === "light" ? COLORS.WHITE : COLORS.PRIMARY3};
    padding: 18px;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    height: 100%;
  }
`;

export const Top = styled.div`
  flex: 4;
  gap: 8px;
  ${FLEX.FlexStartStart};
  flex-direction: column;
  position: relative;
`;

export const Bottom = styled.div`
  flex: 1;
  ${FLEX.FlexCenterCenter};
  width: 100%;
`;
export const Title = styled.div<{ isDetail?: boolean }>`
  width: 100%;
  ${FLEX.FlexBetweenCenter};
  flex: 0 0 auto;
  cursor: move;

  .name {
    flex: 1;
    ${FLEX.FlexStartCenter}
  }

  .detail {
    font-size: ${calcRem(13)};
    /* color: ${({ theme }) => theme.COLORS.WHITE}80; */
  }

  ${({ isDetail, theme }) =>
    isDetail &&
    `
    padding-bottom: 6px;
    border-bottom: 1px solid #7883A1;
  `}
`;

export const Increase = styled.div<{ isIncrease: IncreaseString }>`
  ${FLEX.FlexStartCenter};
  gap: 4px;
  color: ${({ isIncrease }) => INDECREASECOLORS[isIncrease]};

  .number {
    font-size: ${calcRem(28)};
  }
`;

export const BottomInner = styled.ul`
  ${FLEX.FlexAroundCenter};
  width: 100%;
`;
export const DetaiItem = styled.li`
  flex: 1;
  text-align: center;

  * {
    font-weight: 500;
  }

  .detail-title {
  }
`;

export const ChartWrapper = styled.div`
  width: 100%;
  height: 40%;
  position: absolute;
  bottom: 0;
  left: 0;
`;

export const DetailWrapper = styled.div`
  /* border-bottom: 1px solid #7883a1; */
  display: flex;
  flex: 1 1 auto;
  width: 100%;
  height: 100%;

  .total-count {
    font-weight: 500;
    text-align: center;
    font-size: ${calcRem(18)};
    color: ${COLORS.BUTTONS2};
  }
  .total-count.alert {
    color: ${COLORS.RED};
  }
`;

export const ColumnTitle = styled.div`
  ${FLEX.FlexStartCenter};
  gap: 6px;
  font-weight: 500;
  white-space: nowrap;

  .level-circle {
    width: 22px;
    height: 22px;
    max-width: 22px;
    max-height: 22px;
    min-width: 22px;
    min-height: 22px;
    font-size: ${calcRem(10)};
  }
`;
