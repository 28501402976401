import styled from "styled-components";
import { Stepper, Step } from "react-form-stepper";
import { COLORS } from "styles/colors";
import { calcRem } from "styles/theme";
import * as mediaQuery from "components/MediaQuery";
import { FLEX } from "styles/flex";

export type StepType = {
  label: string;
};

interface StepperProps {
  steps?: Array<StepType>;
  activeStep?: number;
  onClick?: any;
}
interface ConnectorStyleInterface {
  disabledColor: string;
  activeColor: string;
  completedColor: string;
  size: React.ReactText;
  stepSize: React.ReactText;
  style: string;
}

interface StepStyleInterface {
  activeBgColor: string;
  activeTextColor: string;
  completedBgColor: string;
  completedTextColor: string;
  inactiveBgColor: string;
  inactiveTextColor: string;
  size: string | number;
  circleFontSize: string | number;
  labelFontSize: string | number;
  borderRadius: string | number;
  fontWeight: string | number;
}

/**
 * @description props 로 받아와야할것들 -----------
 * activeStep : 현재 진행중인 단계 index
 * steps : 단계
 */
const tempSteps = [
  { label: "Connection" },
  { label: "Other Information" },
  // { label: "Step 3" },
];
// -------------------------------------------

function StepperComponent(props: StepperProps): JSX.Element {
  const steps = props.steps ? props.steps : tempSteps;
  const activeStep = props.activeStep ? props.activeStep : 0;
  const ConnectorStyleProps: ConnectorStyleInterface = {
    disabledColor: "#707070",
    activeColor: "#707070",
    completedColor: COLORS.COMPANY,
    size: 5,
    stepSize: "1.7em",
    style: "solid",
  };

  const StepStyleDTO: StepStyleInterface = {
    activeBgColor: "#707070",
    activeTextColor: "#707070",
    completedBgColor: COLORS.COMPANY,
    completedTextColor: "#aaa",
    inactiveBgColor: "#707070",
    inactiveTextColor: "#707070",
    size: "24px",
    circleFontSize: "0.9rem",
    labelFontSize: "20px",
    borderRadius: "50%",
    fontWeight: "normal",
  };
  return (
    <StepperContainer>
      <Stepper
        activeStep={activeStep}
        connectorStateColors={true}
        connectorStyleConfig={ConnectorStyleProps}
        styleConfig={StepStyleDTO}
      >
        {steps.map((step, index) => (
          <Step
            key={index}
            label={step.label}
            active={activeStep < index}
            completed={activeStep >= index}
            className="LabelCenter"
            onClick={
              props.onClick
                ? () => props.onClick("step", index)
                : () => console.log(index, "????")
            }
          >
            {/* active Step 현재 진행중인 단계가 지나면 주황색상 원 표시 위함 */}
            <Circle active={activeStep > index} />
            <Label active={activeStep >= index}>{step.label}</Label>
          </Step>
        ))}
      </Stepper>
    </StepperContainer>
  );
}

const StepperContainer = styled.div`
  width: 120%;
  padding: 35px 0;

  // 기존 라벨
  #RFS-Label {
    display: none;
  }

  // Stepper 전체
  #RFS-StepperContainer {
    padding: 0;
  }

  // 연결 선 위치 조절 (사이즈 조절할때마다 같이 조절해줘야함)
  #RFS-ConnectorContainer {
    top: calc(1.7em-4px);
    left: calc((-50% + 1.7em) - 10px);
    right: calc((50% + 1.7em) - 10px);
  }

  // 연결 부분 padding 제거
  #RFS-StepContainer {
    padding: 0;
  }

  // 라벨 위치 조절 위해 position relative
  .LabelCenter {
    position: relative;
  }

  ${mediaQuery.isMobile} {
    width: 100%;
  }
`;

// active Step 현재 진행중인 단계 지나면 원 bgcolor 주황색
const Circle = styled.p<{ active: boolean }>`
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background-color: ${({ active }) => (active ? COLORS.COMPANY : COLORS.WHITE)};
`;

const Label = styled.span<{ active: boolean }>`
  position: absolute;
  left: 50%;
  top: -105%;
  font-size: ${calcRem(16)};
  text-align: center;
  transform: translateX(-50%);
  white-space: nowrap;

  color: ${({ active }) => (active ? COLORS.COMPANY : "#707070")};

  ${mediaQuery.isMobile} {
    font-size: ${calcRem(13)};
    white-space: normal;
    top: -120%;
    /* border: 1px solid red; */
  }
`;

export default StepperComponent;
