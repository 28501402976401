import React, {
  Dispatch,
  SetStateAction,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import * as s from "../FilterStyled";
import { Table } from "antd";
import type { TableColumnsType, TableProps } from "antd";
import {
  DashboardFilterRecorderData,
  DashboardFilterRecorderResult,
} from "api/interfaces/dashboardInterface.interface";
import Type from "components/atoms/text/labels/Type";
import { matchStringSearchKeyword } from "utils/functions";
import { mobileMax } from "components/MediaQuery";
import { useMediaQuery } from "react-responsive";

type Props = {
  searchInput: string;
  recorderData: DashboardFilterRecorderData[];
  recorderRowSelection: React.Key[];
  setRecorderRowSelection: Dispatch<SetStateAction<React.Key[]>>;
};

export function RecorderFilterTable(props: Props) {
  const isMobile = useMediaQuery({ maxWidth: mobileMax });
  const onSelectChange = (selectedKeys: React.Key[]) => {
    // console.log("selectedRowKeys changed: ", selectedKeys);
    props.setRecorderRowSelection(selectedKeys);
  };
  const rowSelection = {
    selectedRowKeys: props.recorderRowSelection,
    onChange: onSelectChange,
    hideSelectAll: true,
  };
  const columns: TableColumnsType<DashboardFilterRecorderData> = [
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
      render: (text) => (
        <span className="left">
          <Type type={text} />
        </span>
      ),
    },
    {
      title: "Title",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Location",
      dataIndex: "locationName",
      key: "locationName",
      hidden: isMobile,
    },
  ];

  const data: DashboardFilterRecorderData[] = useMemo(() => {
    return props.recorderData.filter((rec) => {
      return (
        matchStringSearchKeyword(rec.type, props.searchInput) ||
        matchStringSearchKeyword(rec.name, props.searchInput) ||
        matchStringSearchKeyword(
          rec.locationName ? rec.locationName : "",
          props.searchInput
        )
      );
    });
  }, [props.searchInput, props.recorderData]);

  return (
    <s.RecorderTableContainer>
      <Table
        rowKey="recorderId"
        columns={columns}
        dataSource={data}
        pagination={false}
        rowSelection={{
          type: "checkbox",
          ...rowSelection,
        }}
      />
    </s.RecorderTableContainer>
  );
}
