import React, { useRef, useState } from "react";
import ReactQuill, { ReactQuillProps, Quill } from "react-quill";
import "react-quill/dist/quill.snow.css";
import styled from "styled-components";
import { EditorStyle, EditorProps } from "./Common";
import { ImageActions } from "@xeger/quill-image-actions";
import { ImageFormats } from "@xeger/quill-image-formats";

Quill.register("modules/imageActions", ImageActions);
Quill.register("modules/imageFormats", ImageFormats);

export const EditorContainer = styled(EditorStyle)`
  /* height: 400px; */
  .ql-editor {
    min-height: 250px;
    max-height: 250px;   
  }

`;

const modules = {
  imageActions: {},
  imageFormats: {},
  
  toolbar: [
    // container에 등록되는 순서대로 tool 배치
    // container: [
    [{ font: [] }], // font 설정
    [{ header: [1, 2, 3, 4, 5, 6, false] }], // header 설정
    ["bold", "italic", "underline", "strike", "blockquote", "code-block"], // 굵기, 기울기, 밑줄 등 부가 tool 설정
    [{ list: "ordered" }, { list: "bullet" }],
    [{ indent: "-1" }, { indent: "+1" }],
    [{ align: [] }, { color: [] }, { background: [] }], // 정렬, 글씨 색깔, 글씨 배경색 설정
    ["link", "image"],
    ["clean"], // toolbar 설정 초기화 설정
    // ],
  ],
};

const formats = [
  "align",
  "background",
  "blockquote",
  "bold",
  "code-block",
  "color",
  "float",
  "font",
  "header",
  "height",
  "image",
  "italic",
  "link",
  "list",
  "indent",
  "script",
  "strike",
  "size",
  "underline",
  "width",
];

function EmailEditor(props: ReactQuillProps): JSX.Element {
  const [value, setValue] = useState("");

  return (
    <EditorContainer id="email-editor-container">
      <ReactQuill        
        theme="snow"
        bounds={"#email-editor-container"}        
        modules={modules}
        formats={formats}
        value={value}
        onChange={setValue}
        {...props}
      />
    </EditorContainer>
  );
}

export default EmailEditor;
