import { GoogleMap, MarkerF, useGoogleMap } from "@react-google-maps/api";
import {
  ForwardedRef,
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import styles from "./AddressInput.module.css";
import { useAppSelector } from "redux/hooks";

const options = {
  maxZoom: 18,
  minZoom: 3,
  zoom: 3, //RND-446
  zoomControl: false,
  mapTypeControl: false,
  streetViewControl: false,
  disableDefaultUI: false,
  gestureHandling: "greedy",
  panControl: false,
  fullscreenControl: false,
  clickableIcons: false,
  restriction: {
    latLngBounds: {
      north: 85,
      south: -85,
      west: -180,
      east: 180,
    },
    strictBounds: false,
  },
};

interface AddressMapProps {
  isLoaded: boolean;
  selected: google.maps.LatLngLiteral;
  onMarkerChange?: (
    address: google.maps.LatLngLiteral,
    addressString: string
  ) => void;
  ref?: ForwardedRef<any>;
}

const AddressMap = forwardRef(
  (
    { isLoaded, selected, onMarkerChange }: AddressMapProps,
    ref: ForwardedRef<any>
  ): JSX.Element => {
    const theme = useAppSelector((state) => state.theme.theme);
    const [map, setMap] = useState<google.maps.Map>();

    const [position, setPosition] = useState(selected);
    const [showMarker, setShowMarker] = useState<boolean>(true);
    const geocoder = new google.maps.Geocoder();

    const onMarkerDragEnd = (e: google.maps.MapMouseEvent) => {
      if (e.latLng !== null) {
        setPosition({ lat: e.latLng.lat(), lng: e.latLng.lng() });
        geocoder.geocode(
          {
            location: e.latLng,
          },
          function (responses) {
            if (responses && responses.length > 0) {
              console.log(responses[0].formatted_address);
              if (onMarkerChange) {
                onMarkerChange(
                  {
                    lat: responses[0].geometry.location.lat(),
                    lng: responses[0].geometry.location.lng(),
                  },
                  responses[0].formatted_address
                );
              }
            } else {
            }
          }
        );
      }
    };

    useImperativeHandle(ref, () => ({
      updateInputLocation(location: google.maps.LatLngLiteral) {
        console.log("marker setPosition : " + { location });
        setPosition(location);
        if (map !== undefined) {
          map.setCenter(location);
        }
        setShowMarker(true);
      },
      showMarker(show:boolean) {
        setShowMarker(show);
      }
    }));

    const handleClickMap = (e: google.maps.MapMouseEvent) => {
      if (e.latLng !== null) {
        setPosition({ lat: e.latLng.lat(), lng: e.latLng.lng() });
        geocoder.geocode(
          {
            location: e.latLng,
          },
          function (responses) {
            if (responses && responses.length > 0) {
              console.log(responses[0].formatted_address);
              if (onMarkerChange) {
                onMarkerChange(
                  {
                    lat: responses[0].geometry.location.lat(),
                    lng: responses[0].geometry.location.lng(),
                  },
                  responses[0].formatted_address
                );
              }
            } else {
            }
          }
        );
      }
    };

    return (
      <>
        <GoogleMap
          zoom={17}
          center={position}
          mapContainerClassName={styles.map_container}
          // options={getMapOption(theme,true)}
          options={options}
          mapTypeId={google.maps.MapTypeId.ROADMAP}
          onLoad={(map) => setMap(map)}
          onClick={(e) => handleClickMap(e)}          
        >
          <MarkerF            
            visible={showMarker}
            position={position}
            draggable={true}
            onDragEnd={onMarkerDragEnd}
          />
        </GoogleMap>
      </>
    );
  }
);

export default AddressMap;
