import { AxiosResponse } from "axios";
import { AxiosInstance } from "./axiosInstance";
import {
  CreateNoticeRequest,
  GetNoticeRequest,
  GetNoticeResponse,
  Notice,
  NoticeInfo,
  UpdateNoticeRequest,
} from "./interfaces/noticeInterface.interface";
import { GET_NOTICE_LIST } from "./mocks/noticeMock";
import qs from "qs";

const API_PATH = "/setting/notice";

export const deleteNotice = async ({
  accountId,
  noticeId,
}: {
  accountId: string;
  noticeId: string;
}): Promise<void> => {
  try {
    await AxiosInstance.delete(`${API_PATH}/delete/${accountId}/${noticeId}`);
  } catch (e) {
    console.error(e);
    throw new Error();
  }
};

export const getNotices = async ({
  payload,
}: {
  payload: GetNoticeRequest & { isDisplay?: boolean };
}): Promise<GetNoticeResponse> => {
  try {
    const res: AxiosResponse<GetNoticeResponse> = await AxiosInstance.get(
      // `${API_PATH}/list?${qs.stringify(payload)}&isDisplay=true`
      `${API_PATH}/list?${qs.stringify(payload)}`
    );
    return res.data;
  } catch (e) {
    console.error(e);
    throw new Error();
  }
};

export const postNotice = async ({
  payload,
  accountId,
}: {
  payload: CreateNoticeRequest;
  accountId: string;
}): Promise<void> => {
  try {
    await AxiosInstance.post(`${API_PATH}/create/${accountId}`, payload);
  } catch (e) {
    console.error(e);
    throw new Error();
  }
};

export const putNotice = async ({
  payload,
  accountId,
  noticeId,
}: {
  payload: UpdateNoticeRequest;
  accountId: string;
  noticeId: string;
}): Promise<void> => {
  try {
    await AxiosInstance.put(
      `${API_PATH}/update/${accountId}/${noticeId}`,
      payload
    );
  } catch (e) {
    console.error(e);
    throw new Error();
  }
};

export const putDisplayNotice = async ({
  isDisplay,
  accountId,
  noticeId,
}: {
  isDisplay: boolean;
  accountId: string;
  noticeId: string;
}): Promise<void> => {
  try {
    await AxiosInstance.put(
      `${API_PATH}/update/display/${accountId}/${noticeId}?isDisplay=${isDisplay}`
    );
  } catch (e) {
    console.error(e);
    throw e;
  }
};
