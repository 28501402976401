import TextArea from "components/atoms/input/TextArea";
import BigPopupTemplate from "components/templates/modal/big/BigPopupTemplate";
import * as s from "styles/ModalCommonStyled";
import { AddWrapper } from "styles/ModalCommonStyled";
import { Text, ValidateWord } from "components/atoms/text/Text";
import BigButton from "components/atoms/buttons/BigButton";
import { ModalInput } from "components/atoms/input/modal/ModalInput";
import { ChangeEvent, useCallback, useRef, useState } from "react";
import { isEmpty } from "lodash";
import { TaWrapper, UploadWrapper } from "./AboutStyled";
import MapUpload from "components/atoms/input/upload/map/MapUpload";
import { useMutation } from "react-query";
import { notify } from "components/atoms/notification/Notification";
import { postIssue } from "api/helpAPI";
import { useAppSelector } from "redux/hooks";
import { useIntl } from "react-intl";

interface Props {
  onModal: () => void;
  fileInfo?: string;
}
export function IssueReport(props: Props): JSX.Element {

  const intl = useIntl();
  
  const imgRef = useRef<any>();
  const [text, setText] = useState<string>("");
  const [imgFile, setImgFile] = useState<any>("");
  const [uploadFile, setUploadFile] = useState<File>();
  const [validateMsg, setValidateMsg] = useState<string>("");

  const accountId: string = useAppSelector(
    (state) => state.accountSettings.accountId
  );
  
  const saveImgFile = (e: any) => {
    let files = e.target.files[0];
    setUploadFile(files);
    const reader = new FileReader();
    reader.readAsDataURL(files);
    return new Promise((resolve: any) => {
      reader.onload = () => {
        setImgFile(reader.result);
        resolve();
      };
    });
  };

  const onDeleteImgFile = () => {
    imgRef.current.value = "";
    setImgFile("");
  };

  const mutationIssue = useMutation(postIssue, {
    onSuccess: () => {
      notify("success", intl.formatMessage({
        id: "label.about.notify.issue.send.success",
        defaultMessage: "Issue reported successfully.",
      }));
      props.onModal();
    },
    onError: () => {
      notify("error", intl.formatMessage({
        id: "label.about.notify.issue.send.fail",
        defaultMessage: "Failed to report issue.",
      }));
    },
  });

  const onClickSave = useCallback(()=> {
    if(isEmpty(text)){
      setValidateMsg(
        intl.formatMessage({
          id: "validateMsg.help.about.emptyIssue",
          defaultMessage:
            "Please enter issue details.",
        })
      );
      return;
    }else{
      setValidateMsg("");
    }
    const formData = new FormData();
    if (imgFile !== undefined && imgFile !== "" && uploadFile !== undefined) {
      formData.append("file", uploadFile as File);
    }
    const request = {
               issue : text
     
    };
    const json = JSON.stringify(request);
    const blob = new Blob([json], { type: "application/json" });
    formData.append("issue", blob);
    mutationIssue.mutate({
      form: formData, // Account -> UpdateRequest
      accountId: accountId
    });
  },[accountId, imgFile, intl, mutationIssue, text, uploadFile])

  return (
    <BigPopupTemplate title="Report An Issue" onModal={props.onModal}>
      <AddWrapper>
        <s.Row>
        <ModalInput label="Describe the issue in detail" essential>
          <TaWrapper>
            <TextArea placeholder="Enter your message here."  onChange={(e) => setText(e.target.value)}/>
          </TaWrapper>
          <ValidateWord>{validateMsg}</ValidateWord>
          </ModalInput>
        </s.Row>
        <s.Row>
          <UploadWrapper>
            {/* <Text>Upload File</Text> */}
            <MapUpload onUpload={saveImgFile} onDelete={onDeleteImgFile} ref={imgRef}/>
            {/* <input
              type="file"
              id="logoUPload"
              accept="image/png"
              onChange={logoHandleChange}
            /> */}
          </UploadWrapper>
        </s.Row>
      </AddWrapper>
      <s.ButtonWrapper>
        <BigButton
          id="dw_about_issue_cancel"
          buttonType="cancel"
          label="Cancel"
          onClickBtn={props.onModal}
        />
        <BigButton
          id="dw_about_issue_save"
          label="Save"
          onClickBtn={onClickSave}
        />
      </s.ButtonWrapper>
    </BigPopupTemplate>
  );
}
