import styled from "styled-components";
import { COLORS } from "styles/colors";
import { FLEX } from "styles/flex";
import { calcRem } from "styles/theme";
import * as mediaQuery from "components/MediaQuery";
import { noScrollBar } from "styles/commonStyles";

export const Header = styled.div`
  ${FLEX.FlexBetweenCenter};
  background-color: ${({ theme }) => theme.COLORS.PRIMARY3};
  border-radius: 4px;
  padding: 15px 20px;

  ${mediaQuery.isMobile} {
    padding: 10px;
  }
`;

export const HeaderSelect = styled.div`
  flex: 2;
  ${FLEX.FlexStartCenter};
  gap: 15px;
  .select-wrapper {
    width: 150px;
    max-width: 250px;
  }
  ${mediaQuery.isMobile} {
    gap: 6px;
    .select-wrapper {
      width: 120px;
      max-width: 140px;
      /* padding: 4px 6px; */
    }
    .select-wrapper > img {
      width: 20px;
    }
  }

  /* max-width: 40%; */
`;

export const RightHeader = styled.div`
  ${FLEX.FlexEndCenter};
  flex: 1;
  gap: 20px;
  position: relative;

  ${mediaQuery.isDefault} {
    > button {
      padding: 10px 20px;
    }
  }
`;

export const GridContainer = styled.div`
  /* min-height: calc(100vh - 240px); */
`;

const minHeight = `min-height: calc(100vh - 360px)`;

export const Grid = styled.ul`
  ${minHeight};
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  gap: 30px;
  margin: 30px 0 50px;

  ${mediaQuery.isTablet} {
    grid-template-columns: repeat(5, 1fr);
  }
  ${mediaQuery.isMobile} {
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
  }
`;

export const ThumbnailItem = styled.li`
  overflow: hidden;
  cursor: pointer;
  max-width: 100%;
  min-width: 0;
  text-overflow: ellipsis;

  .title {
    width: 100%;
    margin: 10px 0 6px;
  }

  .thumbnail-content {
    color: ${({ theme }) => `${theme.COLORS.WHITE}75`};
  }
`;

/**
 * Thumbnail Modal
 */
export const ModalHeader = styled.div`
  width: 100%;
  position: relative;

  > button {
    position: absolute;
    top: -10px;
    right: 0;
  }

  ${mediaQuery.isMobile} {
    > button {
      right: 0;
      width: 22px;
    }
    > button img {
      width: 100%;
    }
  }
`;

export const ModalInfo = styled.ul`
  ${FLEX.FlexStartCenter};
  gap: 50px;

  ${mediaQuery.isMobile} {
    flex-direction: column;
    ${FLEX.FlexStartStart};
    gap: 10px;
  }
`;

export const ContentInfoItem = styled.li`
  ${FLEX.FlexStartStart};
  flex-direction: column;
  gap: 8px;

  ${mediaQuery.isMobile} {
    flex-direction: row;
  }
`;

export const CvvContainer = styled.section`
  width: 100%;
  border-radius: 9px;
  overflow: hidden;
  margin-top: 30px;
`;

export const CvvHeader = styled.div`
  padding: 14px 6px;
  background-color: #ff001d;
  color: ${COLORS.WHITE};
  ${FLEX.FlexStartCenter};
  gap: 8px;

  img {
    width: 24px;
  }
`;

export const FilterContainer = styled.div`
  border-radius: 8px;
  background-color: ${({ theme }) => theme.COLORS.SIDEBARBG};
  padding: 10px 20px;
  position: absolute;
  z-index: 88;
  top: 40px;
  right: 0;
  min-width: 300px;
  ${FLEX.FlexCenterCenter};
  flex-direction: column;
  ${({ theme }) =>
    theme.value === "light" &&
    `
    border: 1px solid ${theme.COLORS.BORDER};
  `}
`;

/**
 * ThumbnailFilter
 */

export const FilterGridWrapper = styled.div`
  padding: 20px 0;
  ${minHeight};
`;

export const FilterChannelName = styled.p`
  padding: 18px 15px;
  background-color: #0c1724;
  font-weight: 500;
  color: ${COLORS.WHITE};
  margin-bottom: 20px;
`;

export const FilterGrid = styled.ul`
  ${mediaQuery.isDefault} {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 30px;
  }
`;

export const ThumbnailDiv = styled.div`
  position: relative;
  width: 100%;

  > button {
    position: absolute;
    top: 40%;
    transform: translateY(-50%);
    height: 30px;

    > img {
      height: 100%;
    }
  }

  .button-left {
    left: -30px;
  }
  .button-right {
    right: -30px;
  }

  ${mediaQuery.isMobile} {
    margin-top: 30px;
  }
`;

export const ThumbnailWrapper = styled.ul`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  gap: 30px;
  overflow: hidden;
  overflow-x: auto;
  padding: 16px 0;

  ${mediaQuery.isTablet} {
    grid-template-columns: repeat(5, 1fr);
  }
  ${mediaQuery.isMobile} {
    grid-template-columns: repeat(2, 1fr);
  }
`;
