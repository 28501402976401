import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import {
  DashboardFilterAlert,
  DashboardFilterStatus,
  DashboardFilterAccount,
  DashboardFilterType,
  DashboardFilter,
} from "api/interfaces/dashboardInterface.interface";

export interface MapFilterKeyword {
  type: string;
  keyword: string;
}
export interface MapFilter {
  keyword: MapFilterKeyword;
  filters: DashboardFilter;
}

export const mapFilterInitialState: MapFilter = {
  keyword: {
    type: "",
    keyword: "",
  },
  filters: {
    status: {
      healthy: false,
      alert: false,
      critical: false,
    },
    accounts: [],
    recorders: [],
    types: {
      spectrum: false,
      nvr: false,
      dvr: false,
    },
    alerts: {
      systemAlerts: {
        isChecked: false,
        notResponding: false,
        abnormalRestart: false,
        systemOverheated: false,
      },
      diskAlerts: {
        isChecked: false,
        hardwareFailure: false,
        diskOverheated: false,
        badSector: false,
        storageStatus: false,
        storageNearFull: false,
        reindexing: false,
        reservedSpaceNotClearing: false,
      },
      videoAlerts: {
        isChecked: false,
        videoLoss: false,
        recording: false,
      },
    },
  },
};

export const mapFilterSlice = createSlice({
  name: "mapFilter",
  initialState: mapFilterInitialState,
  reducers: {
    setInitialState(state) {
      return { ...state, filters: { ...mapFilterInitialState.filters } };
    },
    setKeyword(state, action: PayloadAction<MapFilterKeyword>) {
      return {
        keyword: action.payload,
        filters: {
          ...mapFilterInitialState.filters,
        },
      };
    },
    setStatus(state, action: PayloadAction<DashboardFilterStatus>) {
      return {
        ...state,
        filters: {
          ...state.filters,
          status: action.payload,
        },
      };
    },
    setAccounts(state, action: PayloadAction<DashboardFilterAccount[]>) {
      return {
        ...state,
        filters: {
          ...state.filters,
          accounts: action.payload,
        },
      };
    },
    setRecorders(state, action: PayloadAction<string[]>) {
      return {
        ...state,
        filters: {
          ...state.filters,
          recorders: action.payload,
        },
      };
    },
    setTypes(state, action: PayloadAction<DashboardFilterType>) {
      return {
        ...state,
        filters: {
          ...state.filters,
          types: action.payload,
        },
      };
    },

    setAlerts(state, action: PayloadAction<DashboardFilterAlert>) {
      return {
        ...state,
        filters: {
          ...state.filters,
          alerts: action.payload,
        },
      };
    },
  },
});

export const {
  setInitialState,
  setKeyword,
  setStatus,
  setAccounts,
  setRecorders,
  setTypes,
  setAlerts,
} = mapFilterSlice.actions;

export default mapFilterSlice;
