import { Media, TableColumn } from "react-data-table-component";

export interface AddRecordersData {
  name: string;
  cameras: number;
  location: string;
}
export interface AddGRecorderGroupData {
  group: string;
  recorders: number;
  description: string;
}
export interface AddUserData {
  name: string;
  email: string;
  emailCheck: boolean;
  pushCheck: boolean;
}

export interface AddUserGroupData {
  name: string;
  users: number;
  emailCheck: boolean;
  pushCheck: boolean;
}

export const addRecorderColumns: TableColumn<AddRecordersData>[] = [
  {
    name: "Name",
    cell: (row) => row.name,
    sortable: true,
  },
  {
    name: "Cameras",
    selector: (row) => row.cameras,
    center: true,
  },
  {
    name: "Location",
    selector: (row) => row.location,
    hide: Media.SM,
  },
];

export const addRecorderGroupColumns: TableColumn<AddGRecorderGroupData>[] = [
  {
    name: "Group",
    selector: (row) => row.group,
  },
  {
    name: "Recorders",
    selector: (row) => row.recorders,
    center: true,
  },
  {
    name: "Description",
    selector: (row) => row.description,
    hide: Media.SM,
  },
];

export const addUserColumns: TableColumn<AddUserData>[] = [
  {
    name: "Name",
    selector: (row) => row.name,
  },
  {
    name: "Email",
    selector: (row) => row.email,
  },
  {
    name: "Email",
    // selector: (row) => row.emailCheck,
  },
  {
    name: "Push",
    selector: (row) => row.pushCheck,
  },
];

export const addUserGroupColumns: TableColumn<AddUserGroupData>[] = [
  {
    name: "Name",
    selector: (row) => row.name,
  },
  {
    name: "Users",
    selector: (row) => row.users,
    center: true,
  },
  {
    name: "Email",
    selector: (row) => row.emailCheck,
  },
  {
    name: "Email",
    selector: (row) => row.pushCheck,
  },
];

//---------------------------
// temp data in add rule  add Recorder
export const addRecorderData: Array<AddRecordersData> = [
  {
    name: "Cerritos Office Parking Lot",
    cameras: 7,
    location: "Cerritos CA, USA",
  },
  {
    name: "Cerritos Office Warehouse",
    cameras: 7,
    location: "Cerritos CA, USA",
  },
  {
    name: "Cerritos Office Interior",
    cameras: 7,
    location: "Cerritos CA, USA",
  },
  {
    name: "LA Office Parking Lot",
    cameras: 7,
    location: "Los Angeles CA, USA",
  },
  {
    name: "LA Office Warehouse",
    cameras: 7,
    location: "Los Angeles CA, USA",
  },
  {
    name: "LA Office Interior",
    cameras: 7,
    location: "Los Angeles CA, USA",
  },
  {
    name: "LA Office Interior 2",
    cameras: 7,
    location: "Los Angeles CA, USA",
  },
];

// temp data in add rule  add Recorder
export const addRecorderGroupData: Array<AddGRecorderGroupData> = [
  {
    group: "Cerritos Office",
    recorders: 7,
    description: "Cerritos CA location for dsfdsdfsfdf",
  },
  {
    group: "Los Angeles Office",
    recorders: 7,
    description: "Los Angeles CA Location dsfdsdfsfdf",
  },
  {
    group: "Seoul Office",
    recorders: 7,
    description: "Seoul, Korea location dsfdsdfsfdf",
  },
];

export const addUserData: Array<AddUserData> = [
  {
    name: "Tara Strong",
    email: "larmstrong@digital-watchdog.com",
    emailCheck: true,
    pushCheck: false,
  },
  {
    name: "Louis Armstrong",
    email: "larmstrong@digital-watchdog.com",
    emailCheck: false,
    pushCheck: false,
  },
  {
    name: "Neil Armstrong",
    email: "larmstrong@digital-watchdog.com",
    emailCheck: false,
    pushCheck: false,
  },
  {
    name: "Gabe Newell",
    email: "larmstrong@digital-watchdog.com",
    emailCheck: false,
    pushCheck: false,
  },
  {
    name: "Satoru Iwata",
    email: "larmstrong@digital-watchdog.com",
    emailCheck: false,
    pushCheck: false,
  },
];

export const addUserGroupsData: Array<AddUserGroupData> = [
  {
    name: "Strong People",
    users: 3,
    emailCheck: false,
    pushCheck: false,
  },
  {
    name: "Developers",
    users: 4,
    emailCheck: false,
    pushCheck: false,
  },
  {
    name: "Warehouse Employees",
    users: 15,
    emailCheck: false,
    pushCheck: false,
  },
  {
    name: "Non-Viewers",
    users: 3,
    emailCheck: false,
    pushCheck: false,
  },
];
export interface DataRow {
  title: string;
  createdBy: string;
  system: number;
  disk: number;
  video: number;
  status: boolean | string; // 임의로 설정
  createdDate: string; // Date 형식으로 추후 변경 필요
  enable: boolean;
  subAccount?: boolean;
}
export const data: Array<DataRow> = [
  {
    title: "West Coast Alerts",
    createdBy: "Steven Armstrong",
    system: 1,
    disk: 1,
    video: 1,
    status: false,
    createdDate: "04/18/2022",
    enable: true,
  },
  {
    title: "East Coast Alerts",
    createdBy: "Steven Armstrong",
    system: 1,
    disk: 1,
    video: 1,
    status: true,
    createdDate: "04/18/2022",
    enable: true,
  },
  {
    title: "Location Security Alerts",
    createdBy: "Steven Armstrong",
    system: 1,
    disk: 1,
    video: 1,
    status: false,
    createdDate: "04/18/2022",
    enable: true,
  },
  {
    title: "Location Security Alerts 2",
    createdBy: "Steven Armstrong",
    system: 1,
    disk: 1,
    video: 1,
    status: false,
    createdDate: "04/18/2022",
    enable: true,
  },
];
