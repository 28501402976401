import styled, { keyframes } from "styled-components";
import * as mediaQuery from "components/MediaQuery";
import { COLORS } from "styles/colors";
import { FLEX } from "styles/flex";
import { calcRem } from "styles/theme";
import { motion } from "framer-motion";
import { UnderlineText } from "components/atoms/text/Text";
import { NOTICE_HEIGHT } from "components/element/NoticeBar";

export const Container = styled.div<{ noticeLength: number }>`
  width: 100%;
  /* height: ${({ noticeLength }) => `
    calc(100vh - ${NOTICE_HEIGHT * noticeLength}px)`}; */
  height: calc(100vh - 50px);
  overflow: hidden;

  position: relative;

  #map {
    .gm-style-iw-d {
      overflow: unset !important;
      max-height: unset;
    }
    .gm-style .gm-style-iw-c {
      padding: 0 !important;
      top: 34px;
      background-color: #0d1119;
    }
    .gm-style .gm-style-iw-tc,
    .gm-style-iw-chr {
      display: none;
    }

    .google-map-marker-label {
      background-color: rgba(255, 255, 255, 0.8);
      /* background-color: rgba(23, 31, 55, 0.8); */
      margin-bottom: 8px;
      padding: 2px 4px;
      border-radius: 2px;
    }
  }
`;

export const MapView = styled.div`
  /* min-height: 100%; */
  width: 100%;
  height: 100%;

  ${mediaQuery.isMobile} {
    position: relative;
  }
`;

export const MapInner = styled.div`
  width: 100%;
  height: calc(100% - 60px);
  position: absolute;
  bottom: 0;

  /* > div {
    height: 100%;
  } */

  .left-wrapper {
    position: relative;
    height: calc(100% - 20px);
  }
`;

export const MobileInfo = styled.div`
  position: absolute;
  top: 90px;
  width: 100%;
  height: calc(100% - 150px);
  ${FLEX.FlexCenterCenter};

  .map-info-inner {
    width: 90%;
    height: 100%;
    position: relative;
  }
`;

const FloatStyle = `
  position: absolute;
  z-index: 888;
`;

const SearchWidth = 320;
const commonPadding = 15;

export const TopFloat = styled.div`
  ${FloatStyle};
  top: ${commonPadding}px;
  left: ${SearchWidth + commonPadding + 10}px;
  width: max-content;
  max-width: calc(100% - ${SearchWidth + 220 + 80}px);
`;

export const TopInner = styled.div`
  ${FLEX.FlexStartCenter};
  position: relative;
  width: 100%;
`;

export const TopWrapper = styled.div`
  ${FLEX.FlexStartCenter};
  width: 100%;
  overflow-x: hidden;
  padding-right: 15px;

  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none;
  ::-webkit-scrollbar {
    display: none;
  }
`;

export const MobileTopFloat = styled.section`
  ${FloatStyle};
  ${mediaQuery.isMobile} {
    width: 100vw;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
  }
`;

export const MobileBottom = styled.div`
  ${FloatStyle};
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
`;

export const BottomFloat = styled.div`
  ${FLEX.FlexBetweenCenter};
  width: 100%;
  padding: 0 ${commonPadding}px;
`;

export const ShowButton = styled.button`
  background-color: #242d43;
  border-radius: 5px;
  width: 100%;
  padding: 4px;
  ${FLEX.FlexCenterCenter};

  color: ${({ theme }) => theme.COLORS.SELECTED};
`;

export const MapCollapseWrapper = styled.div`
  ${FLEX.FlexCenterCenter};
  width: 100%;
  background-color: #1b2332;
`;

export const ControlContainer = styled.div`
  ${FLEX.FlexEndCenter};
  flex-direction: column;
  /* height: 100%; */
  position: absolute;
  bottom: ${commonPadding}px;
  right: ${commonPadding}px;
  z-index: 889;
  /* padding: 10px 0; */
`;

export const CollapsedButton = styled.button`
  ${FLEX.FlexCenterCenter};
  background-color: #1b2332;
  padding: 4px;
  border-radius: 4px;
  color: ${({ theme }) => theme.COLORS.SELECTED};
  > img {
    height: 12px;
  }
`;

export const WidgetContainer = styled(motion.div)`
  width: 360px;
  position: absolute;

  max-height: calc(100% - 200px);
  right: -383px;
  z-index: 889;
`;

export const MobileInfoWindow = styled.div`
  ${FLEX.FlexCenterCenter};
  position: absolute;
  z-index: 888;
  bottom: 0;
  left: 50%;
  bottom: 50%;
  transform: translate(-50%, 0%);
  /* width: 100%;
  height: 100%; */
`;
