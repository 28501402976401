/**
 * @description 공통적으로 사용할 styles
 */

// 스크롤 디자인 없애고 싶을 때
export const noScrollBar = `
-ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  ::-webkit-scrollbar {
    display: none; /* Chrome, Safari, Opera*/
  }
`;

// export const commonCustomScrollStyle = `
//   ::-webkit-scrollbar {
//     width: 10px; // 스크롤바의 너비
//   }

//   ::-webkit-scrollbar-thumb {
//     height: 30%; // 스크롤바의 길이
//     background: #232B44; //스크롤바의 색상

//     border-radius: 7px;
//   }

//   ::-webkit-scrollbar-track {
//     background: #1D274054; // 스크롤바 뒷 배경 색상
//   }
// `;

/**
 * @name z-index
 *
 * 999 : Date Select
 * 998 : Modal
 * 997 : Cookie bar
 * 996 : Notice bar
 * 995 : headernavbar - user popup
 * 994 : sidebar  - User Popup
 * 993 : sidebar
 * 991 : sidebar container
 * 990 : mobile health open menu
 *
 * 900 : headernavbar
 * 899 : missing list
 * 891 : Map - WidgetContainer
 * 890 : map sub header
 * 889 : Map - ControlContainer
 * 888 : map float style
 * 777 : Map - Subheader Filter Float
 * 666 : left aside view - recorder list
 * 600 : Detail - hover Bubble
 *
 * 299 : status bubble / check include
 * 299 : Online / Offline - hover Bubble
 * 100 : Calendar
 *
 * 99 : map dual mode edit button / report health status bubble
 * 88 : map dual mode alert item label / report thumbnail filter
 *
 * 22 : Status Storage
 * 22 : Status Percentage
 * 21 : Stoarge bar
 *
 *
 *
 * 1 : Info Bubble after
 */
