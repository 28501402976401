import { getChartLayout, postChartLayout } from "api/dashboardAPI";
import {
  DashbaordAlertSendMessage,
  DashboarAlertLatest,
} from "api/interfaces/dashboardInterface.interface";
import { useCallback, useEffect, useState } from "react";
import { ItemCallback, Layout } from "react-grid-layout";
import { useMutation, useQuery, useQueryClient } from "react-query";

import WidgetPresenter from "./WidgetPresenter";
import { useProSidebar } from "react-pro-sidebar";
import {
  GetWidgetRecorderResponse,
  StatResultAccountData,
  WidgetCardData,
  WidgetCardNoMap,
  WidgetStats,
} from "api/interfaces/widgetInterface.interface";
import {
  LEVEL_TYPE,
  ProfileAccountInfo,
} from "api/interfaces/accountInterface.interface";
import { useAppSelector } from "redux/hooks";
import {
  getWidgetCountAccount,
  getWidgetCountAlert,
  getWidgetCountCamera,
  getWidgetCountRecorder,
} from "api/widgetAPI";
import { CardItemName } from "./item/card/CardItem";
import { TableItemName } from "./item/table/TableItemList";
import { barKeyName } from "components/atoms/chart/BarChart";
import { COLORS } from "styles/colors";
import { Datum } from "@nivo/line";
import { BarDatum } from "@nivo/bar";
import { Level } from "components/atoms/level/Level";
import { getLevelFullName } from "api/mappers/accountMapper";
import { ColumnText } from "./item/table/TableItemStyled";
import { ColumnType } from "antd/es/table";
import { SorterResult, SortOrder } from "antd/es/table/interface";
import {
  BasePagination,
  SORT_DIRECTION,
} from "api/interfaces/commonInterface.interface";
import { changeSortOrderString } from "components/atoms/table/AntdTable";

export type AssignModalType = {
  [key: string]: boolean;
};
export const cardInitialLayout: Layout[] = [
  { i: "recorderCount", x: 0, y: 0, w: 1, h: 1, minW: 2, minH: 1 },
  { i: "cameraCount", x: 1, y: 0, w: 1, h: 1, minW: 2, minH: 1 },
  { i: "alertCount", x: 2, y: 0, w: 1, h: 1, minW: 2, minH: 1 },
  { i: "organizationCount", x: 3, y: 0, w: 1, h: 1, minW: 2, minH: 1 },
];
export const chartInitialLayout: Layout[] = [
  // { i: "installation", x: 0, y: 0, w: 1, h: 1, minW: 1, minH: 1 },
  // { i: "revenue", x: 0, y: 0, w: 1, h: 1, minW: 1, minH: 1 },
  // { i: "alertList", x: 1, y: 0, w: 1, h: 1, minW: 1, minH: 1 },
  { i: "storage", x: 0, y: 0, w: 1, h: 1, minW: 1, minH: 1 },
  { i: "recorder", x: 1, y: 0, w: 1, h: 1, minW: 1, minH: 1 },
  { i: "loginHistory", x: 1, y: 1, w: 1, h: 1, minW: 1, minH: 1 },
  { i: "alertFeed", x: 0, y: 1, w: 1, h: 1, minW: 1, minH: 1 },
  // { i: "alertHistory", x: 0, y: 0, w: 1, h: 1, minW: 1, minH: 1 },
  // { i: "cvvDetection", x: 1, y: 0, w: 1, h: 1, minW: 1, minH: 1 },
  // { i: "inventory", x: 1, y: 0, w: 1, h: 1, minW: 1, minH: 1 },
];

const initialCardData: WidgetCardNoMap = {
  count: 0,
  details: [],
  stats: [],
};

export const commonPaginationPerPage = 6;
export const pageMaxLimit = (height: number) => {
  if (height >= 2) {
    return commonPaginationPerPage * height >= 99
      ? 99
      : commonPaginationPerPage * height;
  } else {
    return commonPaginationPerPage;
  }
};

export const changeStatsToBarDatum = (data: WidgetStats[] | undefined) => {
  let tempData: BarDatum[] = data
    ? data.map((stat) => {
        return {
          key: stat.date,
          indexName: stat.date,
          [barKeyName]: stat.count,
          [`${barKeyName}Color`]: COLORS.PROGRESSBAR,
        };
      })
    : [];
  return tempData;
};

export const changeStatsToLine = (data: WidgetStats[] | undefined) => {
  let tempData: Datum[] = data
    ? data.map((stat) => {
        return {
          x: stat.date,
          y: stat.count,
        };
      })
    : [];
  return tempData;
};

// 데이터의 최대값을 구합니다
const getMaxValue = (data: BarDatum[]) => {
  return Math.max(...data.map((d) => Number(d.barChartKey)));
};

// 최대값을 기반으로 적절한 눈금 값을 생성합니다.
const getTickValues = (maxValue: number) => {
  // 적절한 단위를 선택합니다
  const unit = Math.pow(10, Math.floor(Math.log10(maxValue))); // 데이터의 규모에 따라 단위 결정
  const step = Math.ceil(maxValue / unit); // 눈금 간격을 설정
  return Array.from({ length: step + 1 }, (_, i) => i * unit);
};

export const calTickValues = (data: BarDatum[]) => {
  return data.length === 0 ? undefined : getTickValues(getMaxValue(data));
};

export const getAxisBottomValue = (data: BarDatum[], layoutWidth: number) => {
  return data.map((d, idx) =>
    layoutWidth > 1
      ? d.indexName
      : data.length > 6 && idx % 2 !== 0
      ? ""
      : d.indexName
  );
};

export const getMaxYValue = (data: Datum[]): number => {
  // y 값이 null이 아닌 요소들로 배열을 만든 후, 최대값을 계산
  const validYValues = data
    .map((d) => d.barChartKey) // y 값만 추출
    .filter((y) => y !== null) as number[]; // null이 아닌 값들만 필터링

  // 유효한 y 값이 없으면 null 반환
  // if (validYValues.length === 0) {
  //   return 0;
  // }

  // Math.max를 사용하여 최대값 반환
  return validYValues.length === 0 || Math.max(...validYValues) === 0
    ? 10
    : Math.max(...validYValues);
};

// organization common column
export const commonOrgColumn = (
  selectedAccount: ProfileAccountInfo
): ColumnType<any> => {
  return {
    title: "Organization",
    key: "accountName",
    dataIndex: "accountName",
    width: 120,
    hidden: selectedAccount.accountLevel === LEVEL_TYPE.EU,
    render: (value) => (
      <ColumnText>
        <Level level={getLevelFullName(LEVEL_TYPE.EU)} /> {value}
      </ColumnText>
    ),
  };
};

export const changeSortDirection = (
  sorter: SorterResult<any>,
  queryInfo: BasePagination
) => {
  console.log(
    sorter.columnKey,
    sorter.order,
    queryInfo.sortType,
    queryInfo.sortDirection
  );
  let tempDirection: SortOrder = "ascend";
  if (sorter.order === undefined) {
    if (queryInfo.sortDirection === SORT_DIRECTION.ASC) {
      tempDirection = "descend";
    } else {
      tempDirection = "ascend";
    }
  } else {
    tempDirection = sorter.order;
  }
  return changeSortOrderString(tempDirection);
};

export default function WidgetContainer(): JSX.Element {
  const selectedAccount: ProfileAccountInfo = useAppSelector(
    (state) => state.accountSettings
  );
  const { collapsed } = useProSidebar();
  const queryClient = useQueryClient();

  const [cardLayout, setCardLayout] = useState<Layout[] | undefined>();
  const [chartLayout, setChartLayout] = useState<Layout[] | undefined>();

  const [isAddModal, setIsAddModal] = useState<boolean>(false);

  const [isDetailView, setIsDetailView] = useState<CardItemName | null>(null);

  const [rowHeight, setRowHeight] = useState<number>(150);

  const [cardData, setCardData] = useState<WidgetCardData>({
    recorderCount: { ...initialCardData },
    cameraCount: { ...initialCardData },
    alertCount: { ...initialCardData },
    organizationCount: { ...initialCardData },
  });

  // Alerts Widget - assign mdoal
  const [isAssignModal, setIsAssignModal] = useState<AssignModalType>({
    phone: false,
    email: false,
  });

  const [assignInfo, setAssignInfo] = useState<{ [key: string]: string }>({
    phone: "",
    email: "",
  });

  const [selectedAlertItem, setSelectedAlertItem] =
    useState<DashbaordAlertSendMessage>({
      alertRuleId: "",
      recorderId: "",
    });

  const onAssignModal = (type: string, value?: string) => {
    if (value !== undefined) {
      setAssignInfo({
        ...assignInfo,
        [type]: value,
      });
    }

    setIsAssignModal({
      ...isAssignModal,
      [type]: !isAssignModal[type],
    });
  };

  const onSelectItem = (item: DashboarAlertLatest) => {
    setSelectedAlertItem({
      alertRuleId: item.alertRuleId,
      recorderId: item.recorderId,
    });
  };

  // 창 크기에 따라 widget 높이를 다르게 설정해야 함.
  const changeRowHeight = () => {
    if (window.innerWidth >= 1800) {
      setRowHeight(200);
    } else {
      setRowHeight(150);
    }
  };

  useEffect(() => {
    changeRowHeight();
    window.addEventListener("resize", changeRowHeight);
    return () => {
      window.removeEventListener("resize", changeRowHeight);
    };
  }, []);

  // side bar collapse 할 때 size 조절
  useEffect(() => {
    setTimeout(() => {
      window.dispatchEvent(new Event("resize"));
    }, 200);
  }, [collapsed]);

  const mutationUpdateLayout = useMutation(postChartLayout, {
    onSuccess: () => {
      queryClient.invalidateQueries("dashboardLayout");
    },
    onError: () => {
      console.log("erre");
    },
  });

  const onUpdateLayout = (
    card: Layout[] | undefined,
    chart: Layout[] | undefined
  ) => {
    // console.log("??");
    if (card !== undefined && chart !== undefined) {
      let tempLayouts = [...card, ...chart];
      mutationUpdateLayout.mutate({
        payload: tempLayouts,
      });
    }
  };

  const calEmptySpace = (layout: Layout[]) => {
    // 각 열에서의 최대 y 값을 담는 배열 초기화
    const colHeights = new Array(2).fill(0);

    // 각 열의 가장 큰 y 값 계산
    layout.forEach((lay) => {
      const bottomY = lay.y + lay.h;
      colHeights[lay.x] = Math.max(colHeights[lay.x], bottomY);
    });

    return colHeights;
  };

  const onAddLayout = useCallback(
    (type?: string, newItems?: string[]) => {
      if (type === "close") {
        setIsAddModal(false);
      } else {
        setIsAddModal(!isAddModal);
      }
      if (
        newItems !== undefined &&
        chartLayout !== undefined &&
        cardLayout !== undefined
      ) {
        let tmpLayout = [...chartLayout];

        // 각 열의 최대 y 좌표 배열을 계산
        const colHeights = calEmptySpace(chartLayout);
        newItems.forEach((item, index) => {
          // 가장 작은 y 값을 가진 열을 찾아 그 열에 배치
          const minColIndex = colHeights.indexOf(Math.min(...colHeights));
          const tmp: Layout = {
            i: item,
            x: minColIndex, // 한 줄에 2개씩 배치
            // y: maxY + Math.floor(index / 2), // 새로운 줄로 넘어갈 때 y 값을 증가
            y: colHeights[minColIndex], // 새로운 줄로 넘어갈 때 y 값을 증가
            w: 1,
            h: 1,
            minW: 1,
            minH: 1,
          };

          // 해당 열의 y 값을 아이템 높이만큼 증가
          colHeights[minColIndex] += tmp.h;

          tmpLayout.push(tmp);
        });
        // setChartLayout(tmpLayout);
        onUpdateLayout(cardLayout, tmpLayout);
      }
    },
    [isAddModal, chartLayout, cardLayout]
  );

  const { error, data, isLoading } = useQuery(
    ["dashboardLayout"],
    () => getChartLayout(),
    {
      retry: 0,
      refetchOnWindowFocus: false,
      onSuccess: (res: any) => {
        if (res.result !== undefined) {
          // console.log(res.result, "dashboardLayout");
          let tempCardLayout = res.result.filter((d: Layout) =>
            d.i.includes("Count")
          );
          let tempChartLayout = res.result.filter(
            (d: Layout) => !d.i.includes("Count")
          );
          setCardLayout(tempCardLayout);
          setChartLayout(tempChartLayout);
        } else {
          setCardLayout(cardInitialLayout);
          setChartLayout(chartInitialLayout);
        }
      },
      onError: (e: any) => {
        setCardLayout(cardInitialLayout);
        setChartLayout(chartInitialLayout);
      },
    }
  );

  // ----------- Card QUERY
  const getCardRecorder = useQuery(
    ["getWidgetCountRecorder", selectedAccount],
    () => getWidgetCountRecorder({ accountId: selectedAccount.accountId }),
    {
      retry: 0,
      refetchOnWindowFocus: false,
      onSuccess: (res: GetWidgetRecorderResponse) => {
        if (res.result) {
          // console.log(res.result, "getWidgetCountRecorder");
          setCardData((cur) => {
            return {
              ...cur,
              recorderCount: {
                ...res.result,
                stats: changeStatsToLine(res.result.stats as WidgetStats[]),
              },
            };
          });
        } else {
          setCardData((cur) => {
            return {
              ...cur,
              recorderCount: { ...initialCardData },
            };
          });
        }
      },
      onError: (e: any) => {
        console.log(e, "error");
        setCardData((cur) => {
          return {
            ...cur,
            recorderCount: { ...initialCardData },
          };
        });
      },
    }
  );

  const getCardCamera = useQuery(
    ["getWidgetCountCamera", selectedAccount],
    () => getWidgetCountCamera({ accountId: selectedAccount.accountId }),
    {
      retry: 0,
      refetchOnWindowFocus: false,
      onSuccess: (res: GetWidgetRecorderResponse) => {
        if (res.result) {
          // console.log(res.result, "getWidgetCountCamera");
          setCardData((cur) => {
            return {
              ...cur,
              cameraCount: {
                ...res.result,
                stats: changeStatsToLine(res.result.stats as WidgetStats[]),
              },
            };
          });
        } else {
          setCardData((cur) => {
            return {
              ...cur,
              cameraCount: { ...initialCardData },
            };
          });
        }
      },
      onError: (e: any) => {
        console.log(e, "error");
        setCardData((cur) => {
          return {
            ...cur,
            cameraCount: { ...initialCardData },
          };
        });
      },
    }
  );

  const getCardAlert = useQuery(
    ["getWidgetCountAlert", selectedAccount],
    () => getWidgetCountAlert({ accountId: selectedAccount.accountId }),
    {
      retry: 0,
      refetchOnWindowFocus: false,
      onSuccess: (res: GetWidgetRecorderResponse) => {
        if (res.result) {
          // console.log(res.result, "getWidgetCountAlert");
          setCardData((cur) => {
            return {
              ...cur,
              alertCount: {
                ...res.result,
                stats: changeStatsToLine(res.result.stats as WidgetStats[]),
              },
            };
          });
        } else {
          setCardData((cur) => {
            return {
              ...cur,
              alertCount: { ...initialCardData },
            };
          });
        }
      },
      onError: (e: any) => {
        console.log(e, "error");
        setCardData((cur) => {
          return {
            ...cur,
            alertCount: { ...initialCardData },
          };
        });
      },
    }
  );

  const getCardAccount = useQuery(
    ["getWidgetCountAccount", selectedAccount],
    () => getWidgetCountAccount({ accountId: selectedAccount.accountId }),
    {
      retry: 0,
      refetchOnWindowFocus: false,
      onSuccess: (res: GetWidgetRecorderResponse) => {
        if (res.result) {
          // console.log(res.result, "getWidgetCountAccount");
          setCardData((cur) => {
            let tempStats: Datum[] = res.result.stats
              ? (res.result.stats as StatResultAccountData[]).map(
                  (stat: StatResultAccountData) => {
                    return {
                      x: stat.date,
                      y: stat.cpCount + stat.euCount,
                    };
                  }
                )
              : [];
            return {
              ...cur,
              organizationCount: {
                ...res.result,
                stats: tempStats,
              },
            };
          });
        } else {
          setCardData((cur) => {
            return {
              ...cur,
              organizationCount: { ...initialCardData },
            };
          });
        }
      },
      onError: (e: any) => {
        console.log(e, "error");
        setCardData((cur) => {
          return {
            ...cur,
            organizationCount: { ...initialCardData },
          };
        });
      },
    }
  );

  // -----------------------------

  const onCardLayoutChange = (layout: Layout[]) => {
    onUpdateLayout(layout, chartLayout);
  };

  const onTableLayoutChange = (layout: Layout[]) => {
    onUpdateLayout(cardLayout, layout);
  };

  const onDragStop: ItemCallback = (
    newLayout,
    oldItem,
    newItem,
    placeholder,
    e,
    element
  ) => {
    // if (oldItem.y !== 0) {

    // } else {

    // }

    const updatedLayout: Layout[] = newLayout.map((item) => {
      return { ...item, y: 0 };
    });
    // console.log("onDragStop", updatedLayout);
    onCardLayoutChange(updatedLayout);
    // onUpdateLayout(updatedLayout, chartLayout);
  };

  const onDeleteWidget = (key: TableItemName) => {
    let filterLayout = chartLayout?.filter((lay) => lay.i !== key);

    onUpdateLayout(cardLayout, filterLayout);
  };

  const onViewDetailCard = (key: CardItemName) => {
    if (isDetailView === key) {
      setIsDetailView(null);
    } else {
      setIsDetailView(key);
    }
  };

  return (
    <WidgetPresenter
      cardLayout={cardLayout}
      chartLayout={chartLayout}
      isAddModal={isAddModal}
      onAddLayout={onAddLayout}
      onCardLayoutChange={onCardLayoutChange}
      onTableLayoutChange={onTableLayoutChange}
      onDeleteWidget={onDeleteWidget}
      isLoading={isLoading}
      rowHeight={rowHeight}
      isAssignModal={isAssignModal}
      onAssignModal={onAssignModal}
      assignInfo={assignInfo}
      selectedAlertItem={selectedAlertItem}
      onSelectItem={onSelectItem}
      cardData={cardData}
      onViewDetailCard={onViewDetailCard}
      isDetailView={isDetailView}
      onDragStop={onDragStop}
    />
  );
}
