import { useEffect, useState } from "react";
import * as s from "styles/ModalCommonStyled";

import { ModalInput } from "components/atoms/input/modal/ModalInput";
import Input from "components/atoms/input/Input";
import TimeSelect from "components/atoms/select/TimeSelect";
import { addIntervalOptions } from "utils/options";
import { Text } from "components/atoms/text/Text";

import DaySelect from "components/atoms/select/DaySelect";
import { SearchUsers } from "components/element/SearchUsers";
import { REPORT_DETECT_TIME } from "api/interfaces/commonInterface.interface";
import { ProfileAccountInfo } from "api/interfaces/accountInterface.interface";
import { useAppSelector } from "redux/hooks";
import { NotifyStep } from "api/interfaces/reportInterface.interface";
import { getTimezoneAbbr } from "utils/timeUtil";
import Selects from "components/atoms/select/Select";

export enum NOTIFICATION_TYPE {
  SEND_TIME,
  NIGHT_TIME,
  INTERVAL,
  WEEKLY_VALUE,
  MONTHLY_VALUE,
  IS_END_OF_MONTH,
  USER,
  USER_GROUP,
}
type Props = {
  accountId: string;
  onChange: (
    type: NOTIFICATION_TYPE,
    value: string | number | boolean | string[] | boolean[]
  ) => void;
  isEndOfMonth: boolean;
  data?: NotifyStep;
  isCvv?: boolean;
};

export default function AddNotification(props: Props): JSX.Element {
  const [interval, setInterval] = useState(true);
  const selectedAccount: ProfileAccountInfo = useAppSelector(
    (state) => state.accountSettings
  );
  // const min:number = 1;
  // const max:number = 31;
  // const [dayValue, setDayValue] = useState<number>(1);

  useEffect(() => {
    if (props.data?.intervalType === "WEEK") {
      setInterval(true);
    } else {
      setInterval(false);
    }
  }, [props.data]);
  return (
    <>
      <s.RowModalInput>
        <ModalInput label={props.isCvv ? "Time(Day)" : "Send Time"} half>
          <s.RowModalInput>
            {/* Time Select */}
            <s.SelectBox>
              <TimeSelect
                value={props.data?.dayTime}
                onChange={(value) =>
                  props.onChange(NOTIFICATION_TYPE.SEND_TIME, value)
                }
              />
            </s.SelectBox>
            <Text fontSize={14}>
              [ {getTimezoneAbbr(selectedAccount.timezone)} ]
            </Text>
          </s.RowModalInput>
        </ModalInput>

        {props.isCvv && (
          <ModalInput label="Time(Night)" half>
            <s.RowModalInput>
              {/* Time Select */}
              <s.SelectBox>
                <TimeSelect
                  value={{
                    value: props.data?.nightTime,
                    label: props.data?.nightTime,
                  }}
                  onChange={(value) =>
                    props.onChange(NOTIFICATION_TYPE.NIGHT_TIME, value)
                  }
                />
              </s.SelectBox>
              <Text fontSize={14}>
                [ {getTimezoneAbbr(selectedAccount.timezone)} ]
              </Text>
            </s.RowModalInput>
          </ModalInput>
        )}
      </s.RowModalInput>
      <ModalInput label="Period">
        {/* period Select */}
        <s.CheckInput>
          <s.SelectBox>
            <Selects
              defaultValue={addIntervalOptions[0]}
              value={
                props.data?.intervalType !== undefined
                  ? addIntervalOptions.filter(function (option) {
                      return option.value === props.data?.intervalType;
                    })
                  : addIntervalOptions[0]
              }
              options={addIntervalOptions}
              onChange={(value) => {
                if (value === "WEEK") {
                  setInterval(true);
                  props.onChange(
                    NOTIFICATION_TYPE.INTERVAL,
                    REPORT_DETECT_TIME.WEEK
                  );
                } else {
                  setInterval(false);
                  props.onChange(
                    NOTIFICATION_TYPE.INTERVAL,
                    REPORT_DETECT_TIME.MONTH
                  );
                }
              }}
            />
          </s.SelectBox>
          {!interval && (
            <Text>
              Day{" "}
              <Input
                className="period-month"
                defaultValue={1}
                min={1}
                max={31}
                size={100}
                value={props.data?.monthlyValue}
                onChange={(e) => {
                  props.onChange(
                    NOTIFICATION_TYPE.MONTHLY_VALUE,
                    e.currentTarget.value
                  );
                }}
                disabled={props.isEndOfMonth}
              />
              of Every Month
            </Text>
          )}
        </s.CheckInput>
        {!interval && (
          <label>
            <input
              type="checkbox"
              checked={props.data?.isEndOfMonth}
              onChange={(e) => {
                props.onChange(
                  NOTIFICATION_TYPE.IS_END_OF_MONTH,
                  e.target.checked
                );
              }}
            />
            <Text>End of the month</Text>
          </label>
        )}
        {interval && (
          <DaySelect
            selectedDays={props.data?.weeklyValue}
            onChangeDayOfWeek={(value: boolean[]) => {
              props.onChange(NOTIFICATION_TYPE.WEEKLY_VALUE, value);
            }}
          />
        )}
      </ModalInput>
      <SearchUsers
        isTab
        accountId={props.accountId}
        selectedUserId={
          props.data?.users !== undefined
            ? props.data?.users.map((user) => user.userId)
            : []
        }
        selectedUserGroupId={
          props.data?.userGroups !== undefined
            ? props.data?.userGroups.map((group) => group.userGroupId)
            : []
        }
        onSelectedRowsChangeUser={(value: string[]) => {
          props.onChange(NOTIFICATION_TYPE.USER, value);
        }}
        onSelectedRowsChangeUserGroup={(value: string[]) => {
          props.onChange(NOTIFICATION_TYPE.USER_GROUP, value);
        }}
      />
    </>
  );
}
