import { useCallback, useState } from "react";
import * as s from "./ButtonStyled";
import PlusCircleIcon from "assets/icons/plus_in_circle.svg";

type BigButtonProps = {
  id?: string;
  add?: boolean;
  label: string;
  buttonType?: string;
  onClickBtn: () => void;
  disabled?: boolean;
  icon?: any;
  delay?: boolean
};

function BigButton({
  id,
  add,
  label,
  buttonType,
  onClickBtn,
  disabled,
  icon,
  delay
}: BigButtonProps): JSX.Element {
  /**
   * @description buttonType
   * cancel : 회색
   * warning : 빨간
   * 기본: 파란색
   */
  const [lastClick, setLastClick] = useState<number>(0);
  const handleClick = useCallback(
    (e: React.MouseEvent) => {
      const currentTime = new Date().getTime();
      const timeDiff = currentTime - lastClick;

      
      if(delay){
        if (timeDiff < 2000) {
          e.preventDefault();
          return;
        }
        setLastClick(currentTime);
      }
      onClickBtn();
    },
    [delay, lastClick, onClickBtn]
  );

  return (
    <s.BigButton
      id={id}
      buttonType={buttonType ? buttonType : "primary"}
      onClick={handleClick}
      disabled={disabled}
    >
      {add && <img alt="add button" src={PlusCircleIcon} />}
      {icon && <img alt="add button" src={icon} />}
      <s.ButtonLabel>{label}</s.ButtonLabel>
    </s.BigButton>
  );
}

export default BigButton;
