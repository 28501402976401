import styled from "styled-components";
import { COLORS } from "styles/colors";
import { FLEX } from "styles/flex";

export const Title = styled.div`
  ${FLEX.FlexStartStart};
  flex-direction: column;
  gap: 4px;
`;

export const Inner = styled.div`
  padding: 30px 0;
  ${FLEX.FlexStartStart};
  flex-direction: column;
  gap: 20px;
  width: 100%;
`;

export const Label = styled.label`
  color: ${({ theme }) => theme.COLORS.WHITE};
  width: 100%;
`;

export const ButtonWrapper = styled.div`
  ${FLEX.FlexCenterCenter};
  gap: 10px;
`;
