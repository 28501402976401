import styled from "styled-components";
import { NoticeInfo } from "api/interfaces/noticeInterface.interface";
import { NoticeBar } from "./NoticeBar";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import { getCookie, setCookie } from "cookies/cookie";
import { deleteNotice } from "redux/reducers/notices/noticeReducer";

export function Notice(): JSX.Element {
  const dispatch = useAppDispatch();
  const notices: NoticeInfo[] = useAppSelector((state) => state.notice.notices);

  const noticeCookieOptions = {
    path: "/",
  };

  const onCloseNotice = (notice: NoticeInfo) => {
    // console.log(notice);
    if (!notice.preview) {
      setCookie({
        name: `notice-${notice.noticeId}`,
        value: 1,
        options: {
          ...noticeCookieOptions,
          expires: new Date(notice.enableEndTime),
        },
      });
      dispatch(deleteNotice(notice.noticeId));
    }
  };

  return (
    <NoticeWrapper>
      {notices.map((notice: NoticeInfo, index: number) => {
        return (
          !notice.disabled && (
            <NoticeBar
              key={index}
              message={notice.message}
              color={notice.color}
              type={notice.icon}
              onClickClose={() => onCloseNotice(notice)}
            />
          )
        );
      })}
    </NoticeWrapper>
  );
}
const NoticeWrapper = styled.div`
  position: sticky;
  top: 0;
  z-index: 996;
`;
