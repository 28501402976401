import { AlertType } from "api/interfaces/alertInterface.interface";
import { TableColumn } from "react-data-table-component";

export const AlertTypeFilter = [
  {
    label: "Health",
    value: "HEALTH",
  },
  {
    label: "Inventory",
    value: "INVENTORY",
  },
];

export const AlertRecorerTypeFilter = [
  {
    label: "SPECTRUM",
    value: "SPECTRUM",
  },
  {
    label: "VMAX-IP",
    value: "NVR",
  },
  {
    label: "VMAX-A1",
    value: "DVR",
  },
];

export const AlertCategoryTypeFilter = [
  {
    label: "System",
    value: "System",
  },
  {
    label: "Disk",
    value: "Disk",
  },
  {
    label: "Video",
    value: "Video",
  },
];

export const AlertStatusTypeFilter = [
  {
    label: "Active",
    value: "ACTIVE",
  },
  {
    label: "Assign",
    value: "ASSIGNED",
  },
  {
    label: "Closed",
    value: "CLOSED",
  },
];

export const ActiveAlertStatusTypeFilter = [
  {
    label: "Active",
    value: "ACTIVE",
  },
  {
    label: "Assign",
    value: "ASSIGNED",
  },
];

export const AlertProbableCause = {
  "Video Loss": [
    "- The camera cable is unplugged or defective.",
    "- Camera hardware issue.",
    "- PoE switch is offline or defective.",
    "- Camera is powered off.",
  ],
  Recording: [
    "- Failed to access all HDDs",
    "- Hardware issue",
    "- Recording software issue",
  ],
  "System Not Responding": [
    "- Power interruption",
    "- Network issue",
    "- Hardware issue",
  ],
  "Abnormal Restart": [
    "- Power interruption",
    "- Software issue(Watchdog)",
    "- Hardware issue",
  ],
  "CPU Overheated": ["- Hardware issue", "- Environmental issue"],
  "Hardware failure": ["- HDD physical damage", "- Hardware issue"],
  "Disk Overheated": ["- HDD physical damage", "- Hardware issue"],
  "BAD Sector": ["- HDD physical damage", "- Hardware issue"],
  "Storage status being checked": [
    "- HDD physical damage",
    "- Hardware issue",
    "- Invalid setup",
    "- One or more storage devices are being checked by media server",
  ],
  Reindexing: [
    "- HDD physical damage",
    "- Hardware issue",
    "- Invalid setup",
    "- Reindexing of one or more storage devices are taking more than 24 Hours.",
  ],
  "Storage space near full": [
    "- HDD physical damage",
    "- Hardware issue",
    "- Invalid setup",
    "- One or more storage devices are almost full",
  ],
  "Reserved space not clearing for over": [
    "- HDD physical damage",
    "- Hardware issue",
    "- Invalid setup",
    "- Recording retention is not working.",
  ],
};

export const changeAlertReasonToCategory = (alertReason: string) => {
  if (alertReason === "Video Loss" || alertReason === "Recording")
    return "Video";
  else if (
    alertReason === "Abnormal Restart" ||
    alertReason === "System Not Responding" ||
    alertReason === "CPU Overheated"
  )
    return "System";
  else return "Disk";
};

export const filteringDiskAlertBoolean = ({
  category,
  type,
}: {
  category?: string;
  type: string;
}) => {
  if (category !== undefined)
    return (
      changeAlertReasonToCategory(type) === "Disk" &&
      type !== "Hardware Failure"
    );
  else return category === "Disk" && type !== "Hardware Failure";
};

export const cameraUniqueValue = ({
  cameraId,
  channel,
}: {
  cameraId: string;
  channel: number;
}) => {
  return `${cameraId}:${channel}`;
};

export const displayAlert = (row: any): string => {
  let display: string = "";
  const alertTypeStr =
    row.alertTypeString !== undefined ? row.alertTypeString : row.alertType;
  if (!alertTypeStr) {
    return "";
  }

  switch (alertTypeStr) {
    case "Video Loss":
      display = alertTypeStr.concat(
        row.cameraName !== undefined && row.cameraName === ""
          ? ` [ ${row.channel} ]`
          : ` [ ${row.cameraName} ]`
      );
      break;
    case "Storage space near full":
      display = alertTypeStr.concat(
        row.storageName !== undefined && row.storageName !== ""
          ? ` [ ${row.storageName} ]`
          : ``
      );
      break;
    default:
      display = alertTypeStr;
      break;
  }

  return display;
};

export const AlertTypeEnumKeys = {
  //SYSTEM
  SYSTEM_NOT_RESPONDING: "System Not Responding",
  ABNORMAL_RESTART: "Abnormal Restart",
  CPU_OVERHEATED: "CPU Overheated",
  //DISK
  HARDWARE_FAILURE: "Hardware failure",
  DISK_OVERHEATED: "Disk Overheated",
  BAD_SECTOR: "BAD Sector",
  STORAGE_STATUS_BEING_CHECKED: "Storage status being checked",
  REINDEXING: "Reindexing",
  STORAGE_SPACE_NEAR_FULL: "Storage space near full",
  RESERVED_SPACE_NOT_CLEAR: "Reserved space not clearing for over",
  //VIDEO
  VIDEO_LOSS: "Video Loss",
  RECORDING: "Recording",
} as const;

export type AlertTypeEnum =
  typeof AlertTypeEnumKeys[keyof typeof AlertTypeEnumKeys];
