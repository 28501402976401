import styled from "styled-components";
import { COLORS } from "styles/colors";
import { FLEX } from "styles/flex";
import * as mediaQuery from "components/MediaQuery";

export const Row = styled.div`
  ${FLEX.FlexStartCenter};
  gap: 7px;
`;
export const NameLabel = styled(Row)`
  /* gap: 7px; */
  cursor: pointer;
  padding-bottom: 3px;
`;

export const ButtonWrapper = styled(Row)<{ disabled?: boolean }>`
  ${({ disabled }) =>
    disabled &&
    `
    opacity: 0.25;
  `}
`;

export const TitleContainer = styled.div`
  width: 100%;
  padding: 17px 0 4px;
`;
export const TitleWrapper = styled.div`
  ${FLEX.FlexBetweenCenter};
  width: 100%;
`;

export const Label = styled.label`
  ${FLEX.FlexStartCenter};
  align-items: flex-end;
  gap: 4px;

  > img {
    height: 15px;
  }
`;

export const SubTitleWrapper = styled.div`
  ${FLEX.FlexStartEnd};
  gap: 7px;
  width: 100%;

  #selected-merged-name {
    max-width: 140px;
  }
  /* ${mediaQuery.isMobile} { */
  > div {
    overflow: hidden;
    text-overflow: ellipsis;
  }
  /* } */
`;

export const LicenseInfo = styled.ul`
  ${FLEX.FlexStartEnd};

  gap: 10px;

  .trial-type {
    font-weight: 500;
    color: ${COLORS.BUTTONS2};
    margin-bottom: 1px;
  }

  button {
    color: ${({ theme }) => theme.COLORS.WHITE};
  }
`;

export const LicenseInfoItem = styled.li<{ isLicense?: boolean }>`
  color: ${({ theme }) => theme.COLORS.WHITE};

  .license-title {
    display: inline;
  }

  ${({ isLicense, theme }) =>
    isLicense &&
    `
    text-decoration: underline;
    text-decoration-color: ${theme.COLORS.STATUS};
    cursor: pointer
  `}
`;
