import styled from "styled-components";
import { COLORS } from "styles/colors";
import { FLEX } from "styles/flex";
import { calcRem } from "styles/theme";
import * as as from "pages/dashboard/stats/StatsStyled";
import * as mediaQuery from "components/MediaQuery";

export const Container = styled(as.Container)`
  section:nth-child(1) {
    grid-row: 1;
  }
  section:nth-child(2) {
    ${mediaQuery.isDefault} {
      height: 100%;
    }
    background-color: ${({ theme }) =>
      theme.value === "light" ? theme.COLORS.PRIMARY3 : "#0e1722"};
    display: flex;
    flex: 1;
  }
`;
export const Section = styled.section`
  width: 100%;
`;

export const Inner = styled(as.Inner)`
  width: 100%;
  height: 100%;
  ${mediaQuery.isMobile} {
    padding: 20px;
    /* height: 100%; */
    overflow: unset;
  }
`;

export const Title = styled.p`
  width: 100%;
  padding: 20px 0;
  border-bottom: 1px solid ${COLORS.BUTTONS1};
  color: ${({ theme }) => theme.COLORS.WHITE};
  font-weight: 500;
  font-size: ${calcRem(24)};
`;

export const Info = styled.div`
  ${FLEX.FlexCenterCenter};
  flex-direction: column;
  gap: 20px;
  padding: 60px 0;
  img {
    width: 50%;
  }
`;

export const Copyright = styled.p`
  text-align: center;
  margin-top: 50px;
  color: ${COLORS.BUTTONS1};
  font-size: ${calcRem(18)};
`;

export const ListWrapper = styled.ul`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const ListItem = styled.li`
  border-bottom: 1px solid ${COLORS.BUTTONS1};
  padding: 20px 0;
  ${FLEX.FlexBetweenCenter};

  > div {
    flex: 4;
    max-width: 60%;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  ${mediaQuery.isMobile} {
    flex-direction: column;
    ${FLEX.FlexStartStart};
    gap: 6px;
    padding: 10px 0;
  }
`;

export const ButtonWrapper = styled.span`
  ${FLEX.FlexEndCenter};
  gap: 15px;
  flex: 1;
`;

export const PageWrapper = styled.div`
  /* padding: 50px 0; */
  width: 100%;

  .pagination-component {
    > ul {
      width: 100%;
      gap: 0;

      /* li {
        flex: 1;
      } */
    }
  }
`;

export const TcWrapper = styled.div`
  /* padding: 50px 0; */
  color: ${({ theme }) => theme.COLORS.WHITE};
  max-height: 500px;
  overflow: auto;

  > div {
    padding-top: 20px;

    > a {
      color: ${COLORS.SELECTED};
    }
  }
`;

export const BigButtonBox = styled.div`
  ${FLEX.FlexEndCenter};
  /* max-width: 160px; */
  flex: 1;

  ${mediaQuery.isMobile} {
    flex-basis: 80px;
  }
`;

export const UploadWrapper = styled.div`
  width: 100%;
  padding: 20px 0;
  display: inline-flex;
`;

export const TaWrapper = styled.div`
  width: 100%;

  textarea {
    width: 100%;
    height: 250px;
  }
`;

export const VersionInfo = styled.ul`
  display: flex;
  flex-direction: column;
  flex: 1;
`;
