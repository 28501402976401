/**
 * @author ydoni
 * @description bar chart
 */
import { BarItemProps, ResponsiveBar, BarDatum, BarSvgProps } from "@nivo/bar";
import { useTheme, linearGradientDef } from "@nivo/core";
import { useAppSelector } from "redux/hooks";
import { COLORS } from "styles/colors";
import BarChartTooltip from "./BarChartTooltip";

export const barKeyName = "barChartKey";

interface Props extends BarSvgProps<any> {
  isLoading?: boolean;
}

export default function BarChart(
  props: Omit<BarSvgProps<any>, "height" | "width">
): JSX.Element {
  const themeMode = useAppSelector((state) => state.theme.theme);

  const BoldTick = ({
    value: _value,
    x,
    y,
    opacity,
    rotate,
    format,
    lineX,
    lineY,
    onClick,
    textX,
    textY,
    textBaseline,
    textAnchor,
  }: any) => {
    const theme = useTheme();

    let value = _value;
    if (format !== undefined) {
      value = format(value);
    }

    let gStyle = { opacity };

    return (
      <g
        transform={`translate(${x},${y})`}
        {...(onClick ? { onClick: (e) => onClick(e, value) } : {})}
        style={gStyle}
      >
        <line
          x1={0}
          x2={lineX}
          y1={0}
          y2={lineY}
          style={theme.axis.ticks.line}
        />
        <text
          dominantBaseline={textBaseline}
          textAnchor={textAnchor}
          transform={`translate(${textX},${textY}) rotate(${rotate})`}
          // (1) merge theme styles with our fontWeight
          style={{
            ...theme.axis.ticks.text,
            fontWeight: 400,
            fill: "white",
          }}
        >
          {value}
        </text>
      </g>
    );
  };

  const BarComponent = (props: BarItemProps<BarDatum>) => {
    // console.log(props);
    const { bar, label } = props;
    return (
      <g transform={`translate(${bar.x}, ${bar.y})`}>
        <rect
          width={bar.width}
          height={bar.height}
          fill={bar.data.fill}
          strokeWidth="0"
          stroke="white"
        />

        <text
          transform={`translate(${bar.width + 20}, ${bar.height / 2})`}
          // textAnchor="left"
          // dominantBaseline="central"
          style={{
            fontSize: 13,
            pointerEvents: "none",
            fill: COLORS.WHITE,
            opacity: 1,
          }}
        >
          {label.concat("%")}
        </text>
      </g>
    );
  };

  const ValueOutside = ({ bars }: any) => {
    return bars.map((bar: any) => {
      const {
        key,
        width,
        height,
        x,
        y,
        data: { value },
      } = bar;
      // console.log(bar);
      return (
        <g key={key} transform={`translate(${x}, ${y})`}>
          <text
            transform={`translate(${20}, ${height / 2 + 3})`}
            textAnchor="middle"
            style={{
              fontSize: 13,
              pointerEvents: "none",
              fill: COLORS.WHITE,
              opacity: 1,
              fontWeight: themeMode === "light" ? 500 : "normal",
              textShadow: `rgba(0, 0, 0, 0.6) 1.95px 1.95px 2px`,
            }}
          >
            {value !== null ? value + "%" : ""}
          </text>
        </g>
      );
    });
  };

  return (
    <ResponsiveBar
      keys={[barKeyName]}
      indexBy="indexName"
      margin={{ top: 0, right: 30, bottom: 10, left: 120 }}
      padding={0.5}
      layout="horizontal"
      valueScale={{ type: "linear" }}
      indexScale={{ type: "band", round: true }}
      colors={({ data }) => String(data[`${barKeyName}Color`])}
      axisTop={null}
      axisRight={null}
      axisBottom={null}
      axisLeft={{
        tickSize: 0,
        tickPadding: 20,
        tickRotation: 0,
        //renderTick: BoldTick,
      }}
      enableLabel={false}
      label={(v) => {
        return `${v.value}%`;
      }}
      //barComponent={BarComponent}
      enableGridX={false}
      enableGridY={false}
      legends={[]}
      role="application"
      ariaLabel="myDW Bar Chart"
      defs={[
        // using helpers
        // will inherit colors from current element
        linearGradientDef(
          "gradientA",
          [
            { offset: 0, color: "inherit" },
            { offset: 100, color: "inherit", opacity: 0.6 },
          ],
          {
            gradientTransform: "rotate(90 0.5 0.5)",
          }
        ),
      ]}
      fill={[{ match: "*", id: "gradientA" }]}
      layers={[
        "grid",
        "axes",
        "bars",
        "markers",
        "legends",
        "annotations",
        (props) => <ValueOutside {...props} />,
      ]}
      tooltip={BarChartTooltip}
      theme={{
        grid: {
          line: {
            stroke: "#7883A1", // 그리드 라인의 색상
            strokeWidth: 0.5, // 그리드 라인의 두께
          },
        },
        labels: {
          text: {
            fontSize: 14,
            fill: themeMode === "light" ? COLORS.BLACK : "#FFFFFF",
          },
        },
        legends: {
          text: {
            fontSize: 12,
            fill: themeMode === "light" ? COLORS.BLACK : "#FFFFFF",
          },
        },
        axis: {
          legend: {
            text: {
              fontSize: 13,
              fill: themeMode === "light" ? COLORS.BLACK : "#FFFFFF",
            },
          },
          ticks: {
            text: {
              fontSize: 13,
              fill: themeMode === "light" ? COLORS.BLACK : "#FFFFFF",
            },
          },
        },
      }}
      minValue={0}
      {...props}
    />
  );
}
