import React, {
  FormEvent,
  ForwardedRef,
  HTMLProps,
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
} from "react";
import { useState } from "react";
import { useQuery } from "react-query";
import {
  BasePagination,
  SORT_DIRECTION,
} from "api/interfaces/commonInterface.interface";
import { getUserList, getUserTotalList } from "api/userAPI";
import { ColumnDef, Row, RowSelectionState } from "@tanstack/react-table";
import { IndeterminateCheckbox, VGroupTable } from "./comp/VGroupTable";
import { keys } from "lodash";
import { CheckInput } from "styles/ModalCommonStyled";
import { Checkbox } from "antd";

export type SearchUserProps = {
  accountId: string;
  keyword: string;
  selectedUserId?: string[];
  onSelectedRowsChangeUser?: (selectedRows: string[]) => void;
  onSelectedRowsChangeEmail?: (selectedRows: string[]) => void;
  isSupportEmail?: boolean;
  userGroupId?: string;
  selectedUserIdEmail?: {
    userId: string;
    enableEmail: boolean;
    enablePush: boolean;
  }[];
  ref?: ForwardedRef<any>;
};

export interface DataRow {
  enablePush: boolean;
  enableEmail: boolean;
  id: string;
  name: string;
  email: string;
}

export const SearchUserList = forwardRef(
  (props: SearchUserProps, ref: ForwardedRef<any>): JSX.Element => {
    const [users, setUsers] = useState<DataRow[]>([]);
    const [emailCheckedList, setEmailCheckedLists] = useState<Set<string>>(
      new Set()
    );
    const [pushCheckedList, setPushCheckedLists] = useState<Set<string>>(
      new Set()
    );
    const [totalRows, setTotalRows] = useState<number>(10);
    const [selectedCombine, setSelectedCombine] = useState<RowSelectionState>(
      {}
    );

    const [userQueryInfo, setUserQueryInfo] = useState<BasePagination>({
      total: 0,
      pageNum: 0,
      pageLimit: 2000,
      keyword: props.keyword as string,
      sortType: "name",
      sortDirection: SORT_DIRECTION.ASC,
    });

    const checkOnlyGroup = (
      userGroups: {
        name: string;
        groupId: string;
        enableDelete: boolean;
        description: string;
      }[]
    ) => {
      if (userGroups.length !== 1) {
        return false;
      } else {
        if (
          props.userGroupId !== undefined &&
          userGroups[0].groupId === props.userGroupId
        ) {
          return true;
        } else {
          return false;
        }
      }
    };

    const { data, error, refetch } = useQuery(
      ["user", userQueryInfo, props.selectedUserIdEmail],
      () =>
        getUserTotalList({
          payload: userQueryInfo,
          accountId: props.accountId,
        }),
      {
        retry: 0,
        refetchOnWindowFocus: false,
        onSuccess: (res: any) => {
          if (res.result === undefined) {
            return;
          }
          setUsers(
            res.result.map((user: any) => {
              return {
                id: user.userId,
                name: user.name,
                email: user.email,
                enableEmail:
                  props.selectedUserIdEmail !== undefined
                    ? (props.selectedUserIdEmail.find(
                        (temp) => temp.userId === user.userId
                      )?.enableEmail as boolean)
                    : false,
                enablePush:
                  props.selectedUserIdEmail !== undefined
                    ? (props.selectedUserIdEmail.find(
                        (temp) => temp.userId === user.userId
                      )?.enablePush as boolean)
                    : false,
                disabled:
                  user.userGroupCount > 1
                    ? false
                    : checkOnlyGroup(user.userGroups),
              };
            })
          );
          //setTotalRows(res.page.total);
          let tmpCombine = {};
          res.result.forEach((user: any, index: number) => {
            if (props.selectedUserId?.includes(user.userId)) {
              Object.assign(tmpCombine, { [user.userId]: true });
            }

            if (props.selectedUserIdEmail !== undefined) {
              let selectId = props.selectedUserIdEmail.find(
                (temp) => temp.userId === user.userId
              );
              if (selectId !== undefined) {
                Object.assign(tmpCombine, { [user.userId]: true });
              }
            }
          });
          setSelectedCombine(tmpCombine);
        },
        onError: (e: any) => {},
      }
    );

    useEffect(() => {
      let tmpCombine = {};
      const tmpData = [...users];
      tmpData.forEach((user, index: number) => {
        if (props.selectedUserId?.includes(user.id)) {
          Object.assign(tmpCombine, { [user.id]: true });
        }

        if (props.selectedUserIdEmail !== undefined) {
          let selectId = props.selectedUserIdEmail.find(
            (temp) => temp.userId === user.id
          );
          if (selectId !== undefined) {
            user.enableEmail = selectId?.enableEmail as boolean;
            user.enablePush = selectId?.enablePush as boolean;
          }
        }
      });
      setUsers(tmpData);
      setSelectedCombine(tmpCombine);
    }, [props.selectedUserIdEmail]);

    const columns = React.useMemo<ColumnDef<any>[]>(
      () => [
        {
          accessorKey: "name",
          size: 100,
          header: ({ table }) => {
            const changeableRows = table
              .getRowModel()
              .rows.filter((row) => !row.original.disabled);

            return (
              <>
                <IndeterminateCheckbox
                  {...{
                    checked: table.getIsAllRowsSelected(),
                    indeterminate: table.getIsSomeRowsSelected(),
                    onChange: (e) => {
                      changeableRows.forEach((row) =>
                        row.toggleSelected(e.currentTarget.checked)
                      );
                    },
                    // onClick: onClickCheckBox
                  }}
                />{" "}
                Name
              </>
            );
          },
          cell: ({ row, getValue }) => (
            <div
              className="input-name"
              style={{
                paddingLeft: `${row.depth * 2}rem`,
              }}
            >
              <>
                <IndeterminateCheckbox
                  {...{
                    disabled: row.original.disabled,
                    //checked: row.original.disabled ? true : row.getIsSelected(),
                    checked: row.original.disabled ? true : row.getIsSelected(),
                    indeterminate: row.getIsSomeSelected(),
                    onChange: row.getToggleSelectedHandler(),
                    // onClick: onClickCheckBox
                  }}
                />{" "}
                {row.getCanExpand() ? (
                  <button
                    {...{
                      onClick: row.getToggleExpandedHandler(),
                      style: { cursor: "pointer" },
                    }}
                  >
                    {row.getIsExpanded() ? "▼" : "▶"}
                  </button>
                ) : (
                  " "
                )}{" "}
                {getValue()}
              </>
            </div>
          ),
          footer: (props) => props.column.id,
        },
        {
          accessorKey: "email",
          header: () => "Email",
          cell: ({ row, cell, getValue }) => getValue(),
          size: 200,
        },
      ],
      []
    );

    const handleChange = useCallback((row: RowSelectionState) => {
      // You can set state or dispatch with something like Redux so we can use the retrieved data
      let checkedRecorder: string[] = [];
      const selectKeys = Object.keys(row);

      if (selectKeys.length > 0) {
        selectKeys.forEach((select) => {
          if (!select.endsWith("merged")) {
            checkedRecorder.push(select);
          }
        });
      }
      if (props.onSelectedRowsChangeUser) {
        props.onSelectedRowsChangeUser(checkedRecorder);
      }
    }, []);

    useImperativeHandle(ref, () => ({
      getCheckedListAll() {
        const selectedList = users.filter(
          (user) => user.enableEmail === true || user.enablePush === true
        );
        return selectedList;
      },
    }));

    //FIXME EDIT시 기존에 체크된 User정보를 테이블에 적용하는 로직 필요.
    return (
      <>
        <VGroupTable
          columns={columns}
          data={users}
          search={props.keyword}
          selectedRow={selectedCombine}
          onSelectedRowsChange={handleChange}
        />
      </>
    );
  }
);
