import { Route, Routes, useLocation } from "react-router-dom";
import List from "./list";
import Groups from "./groups";

type Props = {};

export default function Recorders(props: Props): JSX.Element {
  const location = useLocation();

  // const state = location.state;

  if (location.pathname.includes("groups")) return <Groups />;
  else return <List />;
}
