import {
  ReportThumbnailFilterRow,
  ThumbnailData,
  ThumbnailViewItem,
} from "api/interfaces/reportInterface.interface";
import ThumbnailItem from "./ThumbnailItem";
import * as s from "./ThumbnailStyled";
import { ProfileAccountInfo } from "api/interfaces/accountInterface.interface";

type Props = {
  data: Array<ThumbnailData>;
  recorderName :string;
  channelName: string;
  onChangeModal: (popupData?: ThumbnailData , recorderName?: string , cameraName?: string ) => void;
  selectedAccount: ProfileAccountInfo;
};

export default function ThumbnailFilter(props: Props): JSX.Element {

  return (
    <>
      <s.FilterChannelName>{props.channelName}</s.FilterChannelName>

      <s.ThumbnailWrapper>
        {props.data.sort((a:ThumbnailData, b:ThumbnailData)=>
          a.timestamp.localeCompare(b.timestamp)
          ).map((item, index) => (
          <ThumbnailItem
            isFilter
            index={index}
            item={item}
            selectedAccount={props.selectedAccount}
            cameraName={props.channelName}
            recorderName={props.recorderName}
            onChangeModal={props.onChangeModal}
          />
        ))
      }
      </s.ThumbnailWrapper>

      </>
  );
}
