import { TableColumn } from "react-data-table-component";
import { useCallback, useEffect, useState } from "react";
import { useQuery } from "react-query";
import {
  BasePagination,
  SORT_DIRECTION,
} from "api/interfaces/commonInterface.interface";
import { getRecorderGroupList, getRecorderGroupTotalList } from "api/recorderAPI";
import { IndeterminateCheckbox, VGroupTable } from "./comp/VGroupTable";
import { ColumnDef, Row, RowSelectionState } from "@tanstack/react-table";
import React from "react";

export type SearchRecorderGroupProps = {
  accountId: string;
  selectedRecorderGroupRow?: number[];
  onSelectedRowsChange?: (selectedRows: number[]) => void;
  search?: string;
  allSelect?: boolean;
  userId : string | undefined;
};

interface DataRow {
  id: number;
  name: string;
  recorders: number;
  description: string;
}

export function SearchRecorderGroup(
  props: SearchRecorderGroupProps
): JSX.Element {
  const [groups, setGroups] = useState<DataRow[]>([]);
  const [selectedCombine, setSelectedCombine] = useState<RowSelectionState>({});
  const [groupQueryInfo, setGroupQueryInfo] = useState<BasePagination>({
    total: 0,
    pageNum: 0,
    pageLimit: 2000,
    keyword: "",
    sortType: "name",
    sortDirection: SORT_DIRECTION.ASC,
  });

  const columns = React.useMemo<ColumnDef<any>[]>(
    () => [
      {
        accessorKey: "name",
        header: ({ table }) => (
          <>
            <IndeterminateCheckbox
              {...{
                checked: table.getIsAllRowsSelected(),
                indeterminate: table.getIsSomeRowsSelected(),
                onChange: table.getToggleAllRowsSelectedHandler(),
                // onClick: onClickCheckBox
              }}
            />{" "}
            {/* <button
              {...{
                onClick: table.getToggleAllRowsExpandedHandler(),
              }}
            >
              {table.getIsAllRowsExpanded() ? "👇" : "👉"}
            </button>{" "} */}
            Name
          </>
        ),
        cell: ({ row, getValue }) => (
          <div
            style={{
              // Since rows are flattened by default,
              // we can use the row.depth property
              // and paddingLeft to visually indicate the depth
              // of the row
              paddingLeft: `${row.depth * 2}rem`,
            }}
          >
            <>
              <IndeterminateCheckbox
                {...{
                  checked: row.getIsSelected(),
                  indeterminate: row.getIsSomeSelected(),
                  onChange: row.getToggleSelectedHandler(),
                  // onClick: onClickCheckBox
                }}
              />{" "}
              {row.getCanExpand() ? (
                <button
                  {...{
                    onClick: row.getToggleExpandedHandler(),
                    style: { cursor: "pointer" },
                  }}
                >
                  {row.getIsExpanded() ? "▼" : "▶"}
                </button>
              ) : (
                " "
              )}{" "}
              {getValue()}
            </>
          </div>
        ),
        footer: (props) => props.column.id,
      },
      {
        accessorKey: "recorders",
        header: () => "recorders",
        size: 50,
      },
      {
        accessorKey: "description",
        header: "description",
        size: 80,
      },
    ],
    []
  );

  const { isLoading, isSuccess, error, refetch } = useQuery(
    ["rgroup", props.selectedRecorderGroupRow],
    () =>
      getRecorderGroupTotalList({
        accountId: props.accountId,
        userId: props.userId
      }),
    {
      retry: 0,
      refetchOnWindowFocus: false,
      onSuccess: (res: any) => {
        if (res.result === undefined) {
          return;
        }
        setGroups(
          res.result.map((group: any) => {
            return {
              id: group.recorderGroupId,
              name: group.name,
              recorders: group.recorderCount,
              description:
                group.description !== undefined ? group.description : "-",
            };
          })
        );
        let tmpCombine = {};
        res.result.forEach((group: any, index: number) => {
          if (props.selectedRecorderGroupRow?.includes(group.recorderGroupId)) {
            Object.assign(tmpCombine, { [group.recorderGroupId]: true });
          }
        });
        setSelectedCombine(tmpCombine);
      },
      onError: (e: any) => {},
    }
  );

  // useEffect(()=>{
  //   let tmpCombine ={};
  //   if(props.selectedRecorderGroupRow){
  //     groups.forEach((group, index:number) =>{
  //       if(props.selectedRecorderGroupRow?.includes(group.id)){
  //         Object.assign(tmpCombine,{ [group.id] : true} )
  //       }
  //     });
  //     setSelectedCombine(tmpCombine);
  //   }
  // },[props.selectedRecorderGroupRow]);

  useEffect(() => {
    if (props.allSelect) {
      let checkedRecorder: number[] = groups.map((group) => group.id as number);
      // if (props.onSelectedRowsChange) {
      //   props.onSelectedRowsChange(checkedRecorder);
      // }
    }
  }, [props.allSelect, isLoading, props, groups]);

  const handleChange = useCallback((row: RowSelectionState) => {
    // You can set state or dispatch with something like Redux so we can use the retrieved data
    let checkedRecorder: number[] = [];
    const selectKeys = Object.keys(row);
    try {
      if (selectKeys.length > 0) {
        selectKeys.forEach((select) => {
          if (!select.endsWith("merged")) {
            checkedRecorder.push(Number(select));
          }
        });
      }
      if (props.onSelectedRowsChange) {
        props.onSelectedRowsChange(checkedRecorder);
      }
    } catch {}
  }, []);

  return (
    <>
      <VGroupTable
        columns={columns}
        data={groups}
        search={props.search}
        selectedRow={selectedCombine}
        onSelectedRowsChange={handleChange}
      />
    </>
  );
}
