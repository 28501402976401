import { ReactComponent as CAMERA_ICON } from "assets/icons/camera.svg";
import styled from "styled-components";
import { COLORS } from "styles/colors";
import { FLEX } from "styles/flex";

function Camera(): JSX.Element {
  return (
    <Image>
      <CAMERA_ICON />
      {/* <img src={CAMERA_ICON} alt="camera image" /> */}
    </Image>
  );
}

const Image = styled.p`
  width: 48px;
  height: 48px;
  border-radius: 50%;
  background-color: ${COLORS.BUTTONS1};
  border: 5px solid ${({ theme }) => theme.COLORS.WIDGETCOLOR};
  ${FLEX.FlexCenterCenter};

  img {
    width: 52%;
    /* height: 60%; */
  }
  > svg {
    width: 52%;
    color: ${COLORS.WHITE};
  }
`;

export default Camera;
