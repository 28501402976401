import { Table } from "components/atoms/table/Table";
import { SortOrder, TableColumn } from "react-data-table-component";
import { TableTemplate } from "components/templates/default/table/TableTemplate";
import AddModal from "./add";
import EditModal from "./edit";
import {
  CreateAccountRequest,
  GetAccountListQuery,
  linkAccountInfo,
} from "api/interfaces/accountInterface.interface";
import { Account } from "api/mappers/accountMapper";
import { ClickSearch } from "components/atoms/input/search/SearchInput";
import {
  Validate,
  ValidateMsg,
} from "api/interfaces/commonInterface.interface";
import Status, { StatusType } from "components/blocks/status/Status";

export interface DataRow {
  name: string;
  level: string;
  levelFullName: string;
  accountNumber: string;
  accounts: number;
  recorders: number;
  users: number;
  accountId: string;
  accountAdditionalNumber: string;
  description: string;
  parentAccount: linkAccountInfo[];
}
type Props = {
  isAddModal: boolean;
  isEditModal: boolean;
  onModal: (type?: string) => void;
  columns: TableColumn<DataRow>[];
  data: Array<DataRow>;
  onRowClicked: (row: any, event: any) => void;
  onClickCreate: (accountInfo: CreateAccountRequest) => void;
  onClickDelete: (accountId: string) => void;
  onClickSave: (account: Account, file: File) => void;
  currAccount: Account;
  onClickSearch: ClickSearch;
  accountId: string;
  paginationPerPage: number | undefined;
  queryInfo: GetAccountListQuery;
  keyword: string;
  onChangePage: (page: number, totalRows: number) => void;
  handleSort: (
    selectedColumn: TableColumn<any>,
    sortDirection: SortOrder
  ) => void;
  accountValidate: Validate;
  accountValidateMsg: ValidateMsg;
  isSelf: boolean;
  status: StatusType;
  initialPending: boolean;
  onCellClick: (row: DataRow) => void;
  isCreateLoading: boolean;
  isUpdateLoading: boolean;
};

export function AccountPresenter({
  isAddModal,
  isEditModal,
  onModal,
  columns,
  data,
  onClickCreate,
  onClickDelete,
  currAccount,
  onClickSave,
  onClickSearch,
  accountId,
  paginationPerPage,
  keyword,
  queryInfo,
  onChangePage,
  handleSort,
  accountValidate,
  accountValidateMsg,
  isSelf,
  status,
  onCellClick,
  initialPending,
  isCreateLoading,
  isUpdateLoading,
}: // onRowClicked,
Props): JSX.Element {
  return (
    <>
      <TableTemplate
        titleComponent={isSelf ? "My Organization" : "Organization List"}
        onClickBtn={isSelf ? undefined : () => onModal("add")}
        buttonLabel={isSelf ? undefined : "Add Organization"}
        keyword={keyword}
        onClickSearch={onClickSearch}
        centerChildren={<Status status={status} />}
        requiredPermissions={"manageAccount"}
        isSelf={isSelf}
      >
        <Table
          columns={columns}
          data={data}
          paginationTotalRows={status.totalValue as number}
          paginationPerPage={paginationPerPage}
          onChangePage={onChangePage}
          handleSort={handleSort}
          onRowClicked={onCellClick}
          defaultSortFieldId={"name"}
          progressPending={initialPending}
          isRowClickEditable={true}
        />
      </TableTemplate>
      {isAddModal && (
        <AddModal
          onModal={() => onModal("add")}
          onClickCreate={onClickCreate}
          accountValidate={accountValidate}
          accountValidateMsg={accountValidateMsg}
          isCreateLoading={isCreateLoading}
        />
      )}
      {isEditModal && (
        <EditModal
          onModal={() => onModal("edit")}
          accountInfo={currAccount}
          onClickSave={onClickSave}
          onClickDelete={onClickDelete}
          isUpdateLoading={isUpdateLoading}
        />
      )}
    </>
  );
}
