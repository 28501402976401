import { Text } from "components/atoms/text/Text";
import * as s from "./SetupStyled";
import BOOK_ICON from "assets/icons/book.svg";
import INFO_ICON from "assets/icons/info.svg";
import * as mediaQuery from "components/MediaQuery";

type Props = {};

export default function SetupGuide(props: Props): JSX.Element {
  return (
    <>
      <s.Welcome>
        <s.Inner>
          <s.Title>
            <img src={BOOK_ICON} alt="book icon" />
            Welcome to myDW, Steven!
          </s.Title>
          <Text fontSize={14}>
            This is your step-by-step guide to getting started with myDW. If
            you're not sure where to go next, you can always come back here and
            see what's left to do. Keep in mind, not all of these steps need to
            be done in order and not every step is required to make use of myDW.
            But it is highly recommended that you at least get familiar with
            each of these features. If you need additional help, consult our
            video guides or contact technical support.
          </Text>
        </s.Inner>
      </s.Welcome>
      <s.Container>
        <mediaQuery.Default>
          <s.Section>
            <s.Inner>
              <s.Title>
                Setup Guide
                <img src={INFO_ICON} alt="book icon" />
              </s.Title>
            </s.Inner>
          </s.Section>
          <s.Section>
            <s.Inner>
              <s.Title>Progress</s.Title>
            </s.Inner>
          </s.Section>
        </mediaQuery.Default>
        <mediaQuery.Mobile>
          <s.Section>
            <s.Inner>
              <s.Title>Progress</s.Title>
            </s.Inner>
          </s.Section>
          <s.Section>
            <s.Inner>
              <s.Title className="setup-guide-title">
                Setup Guide
                <img src={INFO_ICON} alt="book icon" />
              </s.Title>
            </s.Inner>
          </s.Section>
        </mediaQuery.Mobile>
      </s.Container>
    </>
  );
}
