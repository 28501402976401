import { BasePagination } from "api/interfaces/commonInterface.interface";
import { Table } from "components/atoms/table/Table";
import { TechSupportDto } from "api/interfaces/managerInterface.interface";
import { ClickSearch } from "components/atoms/input/search/SearchInput";
import { TableTemplate } from "components/templates/default/table/TableTemplate";
import { SortOrder, TableColumn } from "react-data-table-component";
import Status, { StatusType } from "components/blocks/status/Status";
import ViewSupport from "./edit";

type Props = {
  data: TechSupportDto[];
  selectedData : TechSupportDto;
  columns: TableColumn<TechSupportDto>[];
  onModal: () => void;
  paginationInfo: BasePagination;
  onClickSearch: ClickSearch;
  onChangePage: (page: number, totalRows: number) => void;
  handleSort: (
    selectedColumn: TableColumn<any>,
    sortDirection: SortOrder
  ) => void;
  status: StatusType;
  showPopup: boolean;
  onRowClick : (row: TechSupportDto) => void;
  tablePending: boolean;
};
export function TroubleManagerPresenter(props: Props): JSX.Element {

  return (
    <TableTemplate
      onClickSearch={props.onClickSearch}
      centerChildren={<Status status={props.status}/>}
      keyword={props.paginationInfo.keyword}      
    >
      <Table
        columns={props.columns}
        data={props.data}
        paginationTotalRows={props.paginationInfo.total}
        paginationPerPage={props.paginationInfo.pageNum}
        onChangePage={props.onChangePage}
        onRowClicked={props.onRowClick}
        handleSort={props.handleSort}
        defaultSortFieldId="createdAt"
        defaultSortAsc={false}
        progressPending={props.tablePending}
      />
      {props.showPopup && (
        <ViewSupport
          isEdit={true}
          selectedData={props.selectedData}
          onModal={props.onModal}
          onClickSave={() => {}}
        />
      )}
    </TableTemplate>
  );
}
