import { useEffect, useState } from "react";
import * as s from "../FilterStyled";
import styled from "styled-components";
import { FLEX } from "styles/flex";
import { calcRem } from "styles/theme";
import { More } from "../../SubHeader";
import { ProfileAccountInfo } from "api/interfaces/accountInterface.interface";
import { useAppSelector } from "redux/hooks";
import { Option } from "utils/options";

type Props = {
  more: More;
  onChange: (e: any) => void;
};

const moreList = [
  {
    label: "Type",
    value: "types",
  },
  {
    label: "Recorder",
    value: "recorders",
  },
  {
    label: "Alert",
    value: "alerts",
  },
  // {
  //   label: "Recorder Group",
  //   value: "recorderGroup",
  // },
  // {
  //   label: "User",
  //   value: "user",
  // },
  // {
  //   label: "User Group",
  //   value: "userGroup",
  // },
  // {
  //   label: "Assigned",
  //   value: "assigned",
  // },
  // {
  //   label: "Camera",
  //   value: "camera",
  // },
  // {
  //   label: "State",
  //   value: "state",
  // },
];

const MoreFilter = (props: Props): JSX.Element => {
  const selectedAccount: ProfileAccountInfo = useAppSelector(
    (state) => state.accountSettings
  );
  return (
    <s.Container>
      <Wrapper>
        {moreList.map((stat: Option, idx: number) => {
          if (
            !(
              stat.value === "recorders" &&
              selectedAccount.accountLevel === "EU"
            )
          )
            return (
              <Item key={idx}>
                <label>
                  <input
                    type="checkbox"
                    checked={props.more[stat.value]}
                    name={stat.value}
                    onChange={props.onChange}
                  />

                  {stat.label}
                </label>
              </Item>
            );
        })}
      </Wrapper>
    </s.Container>
  );
};

export default MoreFilter;

const Wrapper = styled.ul`
  position: relative;
  ${FLEX.FlexStartStart};
  flex-direction: column;
  gap: 8px;
`;

const Item = styled.li`
  color: ${({ theme }) => theme.COLORS.WHITE};
  ${FLEX.FlexStartCenter};
  /* gap: 6px; */
  font-size: ${calcRem(13)};

  img {
    height: 20px;
  }
`;
