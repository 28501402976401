import SystemSpecs from "pages/reports/view/health/component/type/systemspecs";
import * as s from "./RecorderDetailStyled";
import { useQuery } from "react-query";
import Storage from "pages/reports/view/health/component/type/Storage";
type Props = { type: string; recorderId: string };

const RecorderDetailSystem = (props: Props): JSX.Element => {
  return (
    <s.SystemWrapper>
      <SystemSpecs type={props.type} recorderId={props.recorderId} />
    </s.SystemWrapper>
  );
};

export default RecorderDetailSystem;
