import { useState } from "react";
import { getAlertRuleDetailInfo } from "api/alertAPI";
import { ProfileAccountInfo } from "api/interfaces/accountInterface.interface";
import { useAppSelector } from "redux/hooks";
import { useQuery } from "react-query";
import { AlertRuleListResponse, PostAlertRequest } from "api/interfaces/alertInterface.interface";
import { notify } from "components/atoms/notification/Notification";
import AddRuleContainer from "../add/rules/AddRuleContainer";
import { useIntl } from "react-intl";

interface Props {
  onModal: (type?: string) => void;
  isEdit?: boolean;
  isView?: boolean;
  alertRule?: AlertRuleListResponse;
}

function EditRuleContainer(props: Props): JSX.Element {
  const intl = useIntl();
  const selectedAccount: ProfileAccountInfo = useAppSelector(
    (state) => state.accountSettings
  );
  const [data, setData] = useState<PostAlertRequest>();

  const { isLoading, isSuccess, error, refetch } = useQuery(
    ["alertRuleDetailInfo", props.alertRule?.alertRuleId],
    () =>
      getAlertRuleDetailInfo({
        accountId: selectedAccount.accountId,
        alertRuleId: props.alertRule?.alertRuleId as string,
      }),
    {
      retry: 0,
      refetchOnWindowFocus: false,
      onSuccess: (res: any) => {
        if (res.result !== undefined && res.error === 0) {
          setData(res.result);
        } else {
          notify("error", intl.formatMessage({
            id: "label.alertRule.notify.getDetail.fail",
            defaultMessage: "Alert rule search error.",
          }));
        }
      },
      onError: (e: any) => {
        notify("error", intl.formatMessage({
          id: "label.alertRule.notify.getDetail.fail",
          defaultMessage: "Alert rule search error.",
        }));
      },
    }
  );

  return (
    <>
      {isSuccess ? (
        <AddRuleContainer
          isEdit={props.isEdit}
          isView={props.isView}
          editData={data}
          alertRule={props.alertRule}
          onModal={() => props.onModal("edit")}
        />
      ) : null}
    </>
  );
}

export default EditRuleContainer;
