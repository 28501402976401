import { AxiosResponse } from "axios";
import { AxiosInstance } from "./axiosInstance";
import {
  GetVideoShareListResponse,
  GetVideoShareViewResponse,
  GetVideoShareViewCommentListResponse,
  VideoShareComment,
  VideoShareResult,
  GetVideoShareViewUSerListResponse,
  VideoShareUserPost,
  VideoShareUserUpdate,
  GetVideoShareHashTagListResponse,
  VideoBasePagination,
  VideoShareUserPostResponse,
  GetVideoStringResponse,
  GuestVideoInfo,
  DownloadUrlResponse,
} from "./interfaces/videoshareInterface.interface";
import {
  BasePagination,
  CommonResponse,
  CustomError,
} from "./interfaces/commonInterface.interface";
import qs from "qs";
import {
  PostResendCordRequest,
  PostResendCordResponse,
} from "./interfaces/authInterface.interface";

const API_PATH = "/videoShare";

// Get Videoshare list complete status
export const getVideoShareList = async ({
  accountId,
}: {
  accountId: string;
}): Promise<GetVideoShareListResponse> => {
  try {
    const { data }: AxiosResponse<GetVideoShareListResponse> =
      await AxiosInstance.get(`${API_PATH}/list/${accountId}`);
    // console.log(data);
    return data;
  } catch (e) {
    console.error(e);
    throw new Error();
  }
};

// Get Videoshare all list
export const getVideoShareAllList = async ({
  payload,
  accountId,
}: {
  payload: VideoBasePagination;
  accountId: string;
}): Promise<GetVideoShareListResponse> => {
  try {
    const { data }: AxiosResponse<GetVideoShareListResponse> =
      await AxiosInstance.get(
        `${API_PATH}/list/all/${accountId}?${qs.stringify(payload, {
          skipNulls: true,
        })}`
      );
    // console.log(data);
    return data;
  } catch (e) {
    const customErr = e as CustomError;
    throw customErr;
  }
};

// Get HashTag All
export const getVideoShareHashTag = async ({
  accountId,
  search,
}: {
  accountId: string;
  search:string;
}): Promise<GetVideoShareHashTagListResponse> => {
  try {
    const { data }: AxiosResponse<GetVideoShareHashTagListResponse> =
      await AxiosInstance.post(`${API_PATH}/view/list/tag/all/${accountId}`, 
      { search : search}
      );
    // console.log(data);
    return data;
  } catch (e) {
    console.error(e);
    throw new Error();
  }
};

// Get Videoshare View by VideoId
export const getVideoShareViewByVideoId = async ({
  videoId,
}: {
  videoId: string;
}): Promise<GetVideoShareViewResponse> => {
  try {
    const { data }: AxiosResponse<GetVideoShareViewResponse> =
      await AxiosInstance.get(`${API_PATH}/view/${videoId}`);
    return data;
  } catch (e) {
    console.error(e);
    throw new Error();
  }
};

// Get Videoshare View comment list by VideoId
export const getVideoShareViewCommentByVideoId = async ({
  videoId,
}: {
  videoId: string;
}): Promise<GetVideoShareViewCommentListResponse> => {
  try {
    const { data }: AxiosResponse<GetVideoShareViewCommentListResponse> =
      await AxiosInstance.get(`${API_PATH}/view/comment/list/${videoId}`);
    return data;
  } catch (e) {
    console.error(e);
    throw new Error();
  }
};

// Get Videoshare View comment list by VideoId
export const getVideoShareViewUserListByVideoId = async ({
  videoId,
  payload,
}: {
  videoId: string;
  payload: BasePagination;
}): Promise<GetVideoShareViewUSerListResponse> => {
  try {
    const { data }: AxiosResponse<GetVideoShareViewUSerListResponse> =
      await AxiosInstance.get(
        `${API_PATH}/view/user/list/${videoId}?${qs.stringify(payload)}`
      );
    return data;
  } catch (e) {
    console.error(e);
    throw new Error();
  }
};

// post Videoshare View comment list by VideoId
export const createVideoShareComment = async ({
  videoId,
  payload,
}: {
  videoId: string;
  payload: VideoShareComment;
}): Promise<void> => {
  try {
    await AxiosInstance.post(
      `${API_PATH}/view/comment/create/${videoId}`,
      payload
    );
  } catch (e) {
    console.error(e);
    throw new Error();
  }
};

export const createUserVideoShare = async ({
  videoId,
  accountId,
  payload,
}: {
  videoId: string;
  accountId: string;
  payload: VideoShareUserPost;
}): Promise<VideoShareUserPostResponse> => {
  try {
    const { data }: AxiosResponse<VideoShareUserPostResponse> =
      await AxiosInstance.post(
        `${API_PATH}/view/user/create/${videoId}/${accountId}`,
        payload
      );
    return data;
  } catch (e) {
    console.error(e);
    throw new Error();
  }
};

export const updateUserVideoShare = async ({
  videoId,
  accountId,
  payload,
}: {
  videoId: string;
  accountId: string;
  payload: VideoShareUserUpdate;
}): Promise<void> => {
  try {
    await AxiosInstance.put(
      `${API_PATH}/view/user/update/${videoId}/${accountId}`,
      payload
    );
  } catch (e) {
    console.error(e);
    throw new Error();
  }
};

export const deleteUserVideoShare = async ({
  userId,
}: {
  userId: string;
}): Promise<void> => {
  try {
    await AxiosInstance.delete(`${API_PATH}/view/user/delete/${userId}`);
  } catch (e) {
    console.error(e);
    throw new Error();
  }
};

export const updateVideoShareContent = async ({
  videoId,
  key,
  value,
}: {
  videoId: string;
  key: string;
  value: any;
}): Promise<void> => {
  try {
    await AxiosInstance.put(
      `${API_PATH}/view/update/${key === "tags" ? "tag" : key}/${videoId}`,
      {
        [key]: value,
      }
    );
  } catch (e) {
    console.error(e);
    throw new Error();
  }
};

export const updateVideoSharing = async ({
  videoId,
  sharing,
}: {
  videoId: string;
  sharing: any;
}): Promise<void> => {
  try {
    await AxiosInstance.put(`${API_PATH}/view/update/sharing/${videoId}`, {
      sharing: sharing,
    });
  } catch (e) {
    console.error(e);
    throw new Error();
  }
};

export const createSnapShotEmail = async ({
  form,
  accountId,
  videoId,
}: {
  form: FormData;
  accountId: string;
  videoId: string;
}): Promise<CommonResponse> => {
  try {
    const { data }: AxiosResponse<CommonResponse> = await AxiosInstance.post(
      `${API_PATH}/view/thumbnail/send/${accountId}/${videoId}`,
       form
    );
    return data;
  } catch (e) {
    console.error(e);
    throw new Error();
  }
};

export const createSnapShotEmailGuest = async ({
  form,
  videoId,
}: {
  form: FormData;
  videoId: string;
}): Promise<CommonResponse> => {
  try {
    const { data }: AxiosResponse<CommonResponse> = await AxiosInstance.post(
      `${API_PATH}/guest/thumbnail/send/${videoId}`,
      form
    );
    return data;
  } catch (e) {
    console.error(e);
    throw new Error();
  }
};

export const getVideoDownloadUrl = async (
  videoId: string,
  segment: number,
  fileName: string
): Promise<DownloadUrlResponse> => {
  try {
    const { data }: AxiosResponse<DownloadUrlResponse> =
      await AxiosInstance.post(
        `${API_PATH}/download/video/${videoId}` , 
        {
          fileName : fileName
        }
      );
    return data;
  } catch (e) {
    console.error(e);
    throw new Error();
  }
};

export const getVideoTokenInfo = async (
  payload: GuestVideoInfo
): Promise<GetVideoStringResponse> => {
  try {
    const { data }: AxiosResponse<GetVideoStringResponse> =
      await AxiosInstance.get(
        `${API_PATH}/guest/video?${qs.stringify(payload)}`
      );
    return data;
  } catch (e) {
    const customErr = e as CustomError;
    throw customErr;
  }
};

export const postResendCodeForGuest = async ({
  payload,
}: {
  payload: PostResendCordRequest;
}): Promise<PostResendCordResponse> => {
  try {
    const { data }: AxiosResponse<PostResendCordResponse> =
      await AxiosInstance.post(`${API_PATH}/guest/resendCode`, payload);
    return data;
  } catch (e) {
    console.error(e);
    throw new Error();
  }
};

// DELETE Videosahre by VideoId
export const deleteVideoShare = async ({
  videoId,
}: {
  videoId: string;
}): Promise<AxiosResponse> => {
  try {
    const res: AxiosResponse = await AxiosInstance.delete(
      `${API_PATH}/delete/${videoId}`
    );
    return res;
  } catch (e) {
    console.error(e);
    throw new Error();
  }
};

// DELETE Videosahre Comment
export const deleteVideoShareComment = async ({
  commentId,
}: {
  commentId: string;
}): Promise<AxiosResponse> => {
  try {
    const res: AxiosResponse = await AxiosInstance.delete(
      `${API_PATH}/view/comment/delete/${commentId}`
    );
    return res;
  } catch (e) {
    console.error(e);
    throw new Error();
  }
};
