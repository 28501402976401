import { checkPassword } from "api/authAPI";
import { useAuth } from "components/Auth";
import BigButton from "components/atoms/buttons/BigButton";
import Input from "components/atoms/input/Input";
import { ModalInput } from "components/atoms/input/modal/ModalInput";
import SmallPopupDefaultTemplate from "components/templates/modal/small/SmallPopupDefaultTemplate";
import { useCallback, useState } from "react";
import { useIntl } from "react-intl";
import { useMutation } from "react-query";
import styled from "styled-components";
import { COLORS } from "styles/colors";
import { FLEX } from "styles/flex";
import { calcRem } from "styles/theme";
import { changeFirstWordToUppercase } from "utils/functions";

export type Props = {
  title?: string;
  subTitle?: string;
  onDeactiveModal: () => void;
  onConfirm?: () => void;
};

export default function DeactivateModal({
  title,
  subTitle,
  onDeactiveModal,
  onConfirm,
}: Props): JSX.Element {
  const intl = useIntl();
  const auth = useAuth();

  const [password, setPassword] = useState<string>("");
  const [validateMsg, setValidateMsg] = useState<string>("");
  const [isValidated, setIsValidated] = useState<boolean>(false);

  const mutationCheckPassword = useMutation(checkPassword, {
    onSuccess: (res: any) => {
      if (res.result.isExist) {
        setValidateMsg("");
        setIsValidated(false);
        onConfirm ? onConfirm() : onDeactiveModal();
      } else {
        setIsValidated(true);
        setValidateMsg(
          intl.formatMessage({
            id: "validateMsg.signup.loginIncorrect",
            defaultMessage: "Incorrect Login Credentials.",
          })
        );
      }
    },
    onError: () => {
      setIsValidated(true);
      setValidateMsg(
        intl.formatMessage({
          id: "validateMsg.signup.loginIncorrect",
          defaultMessage: "Incorrect Login Credentials.",
        })
      );
    },
  });
  const onClickCheckPassword = useCallback(() => {
    if (password === "") {
      setValidateMsg(
        intl.formatMessage({
          id: "validateMsg.signup.password.empty",
          defaultMessage: "Password cannot be blank.",
        })
      );
      setIsValidated(true);
    } else {
      setValidateMsg("");
      setIsValidated(false);

      mutationCheckPassword.mutate({
        payload: {
          email: auth.user?.email,
          password: password,
        },
      });
    }
  }, [auth.user?.email, mutationCheckPassword, password]);

  const onChangeInput = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setPassword(e.target.value);
    },
    []
  );
  const buttons = (
    <ButtonWrapper>
      <BigButton
        buttonType="cancel"
        label="Cancel"
        onClickBtn={onDeactiveModal}
      />
      <BigButton label="OK" onClickBtn={onClickCheckPassword} />
    </ButtonWrapper>
  );
  return (
    <SmallPopupDefaultTemplate onModal={onDeactiveModal} button={buttons}>
      <Container>
        <Title>
          {title ? title : "Are you sure you want to deactivate your profile?"}

          <br />
          <strong>
            {subTitle
              ? subTitle
              : `This will remove you from every account you are associated with`}
          </strong>
        </Title>
        <Inner>
          <ModalInput label="User ID">
            <Input disabled value={auth.user.email} />
          </ModalInput>
          <ModalInput
            label="Password"
            essential
            isValidated={isValidated}
            validatedWord={validateMsg}
          >
            <Input
              type="password"
              autoComplete="new-password"
              value={password}
              onChange={onChangeInput}
              isValidated={isValidated}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  onClickCheckPassword();
                }
              }}
            />
          </ModalInput>
        </Inner>
      </Container>
    </SmallPopupDefaultTemplate>
  );
}

const Title = styled.div`
  font-weight: 500;
  color: ${({ theme }) => theme.COLORS.WHITE};
  font-size: ${calcRem(20)};
  text-align: center;
  padding: 20px 0;
  line-height: ${calcRem(24)};
  word-break: break-word;
  strong {
    color: ${COLORS.COMPANY};
    font-size: ${calcRem(16)};
    line-height: ${calcRem(20)};
  }
`;

const ButtonWrapper = styled.div`
  ${FLEX.FlexCenterCenter};
  gap: 16px;
`;

const Container = styled.div`
  ${FLEX.FlexCenterCenter};
  flex-direction: column;
`;

const Inner = styled.div`
  width: 70%;
`;
