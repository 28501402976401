import styled from "styled-components";
import * as s from "../../ModalTemplateStyled";
import SmallPopupDefaultTemplate from "../SmallPopupDefaultTemplate";
import PrimaryButton from "components/atoms/buttons/PrimaryButton";
import { Text } from "components/atoms/text/Text";
import { COLORS } from "styles/colors";
import { FLEX } from "styles/flex";
import { calcRem } from "styles/theme";
import TextArea from "components/atoms/input/TextArea";
import EmailAdd from "components/atoms/select/email";
import { Option } from "utils/options";
import { useState } from "react";
import { UserEmailSelect } from "components/atoms/select/email/EmailAdd";
import { MultiValue } from "react-select";

export type Props = {
  onModal: () => void;
};

export default function ReportEmailPopup({ onModal }: Props): JSX.Element {
  const button = <PrimaryButton label="Send" onClickBtn={onModal} />;

  const [currentData, setCurrentData] = useState<MultiValue<any>>([]);
  const onAddEmail = (value: MultiValue<any>) => {
    // console.log(value, "value");
    setCurrentData(value);
  };

  return (
    <SmallPopupDefaultTemplate button={button} onModal={onModal}>
      <Container>
        <s.SmallTitleWrapper>
          <Text fontSize={24} bold>
            Send Report Email
          </Text>
          <Text className="subtitle">
            Email this Report to one or more participants
          </Text>
        </s.SmallTitleWrapper>
        <EmailSelectWrapper>
          <EmailAdd
            value={currentData}
            onChange={onAddEmail}
            placeholder="Add participants via email"
            isMulti
          />
        </EmailSelectWrapper>

        <OptionTitle>Message (Optional)</OptionTitle>
        <TextArea placeholder="Enter your message here." />
      </Container>
    </SmallPopupDefaultTemplate>
  );
}

const Container = styled.div`
  max-width: 100%;

  textarea {
    min-height: 90px;
  }
`;
const OptionTitle = styled.div`
  font-size: ${calcRem(18)};
  padding-bottom: 10px;
  color: ${({ theme }) => theme.COLORS.WHITE};
  margin-top: 12px;
`;

const EmailSelectWrapper = styled.div`
  padding: 20px 0;
  max-width: 100%;
`;
