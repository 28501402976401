import * as mediaQuery from "components/MediaQuery";
import styled from "styled-components";
import Cropper from "react-easy-crop";
import { Point, Area, Size, MediaSize } from "react-easy-crop/types";
import { useCallback, useEffect, useRef, useState } from "react";
import BigPopupNoHeaderTemplate from "components/templates/modal/big/BigPopupNoHeaderTemplate";
import PrimaryButton from "../../buttons/PrimaryButton";
import { Text } from "../../text/Text";
import getCroppedImg from "../CanvasUtil";
import MapUpload from "components/atoms/input/upload/map/MapUpload";
import { FLEX } from "styles/flex";
import CLOSE_ICON from "assets/icons/sidenav/x.svg";
import { calcRem } from "styles/theme";
import { COLORS } from "styles/colors";
import { isEmpty } from "lodash";
import { notify } from "components/atoms/notification/Notification";
import { useIntl } from "react-intl";
import { convertURLtoFile } from "utils/functions";

interface Props {
  // newUrl: string;
  originUrl: string;
  onModal: (type?: string) => void;
  onFinish: (croppedImage: any, originFile: File) => void;
  // onChange: (value: any) => void;
}

export default function ImageCrop(props: Props): JSX.Element {
  const intl = useIntl();
  const fileInputRef = useRef<any>(null);

  const [url, setUrl] = useState<string>(props.originUrl);
  const [newProfileImage, setNewProfileImage] = useState<File | null>(null);

  const [crop, setCrop] = useState<Point>({ x: 0, y: 0 });

  const [croppedAreaPixels, setCroppedAreaPixels] = useState<Area | null>(null);

  const [zoom, setZoom] = useState(1);
  const onCropComplete = useCallback(
    (croppedArea: Area, croppedAreaPixels: Area) => {
      // croppedArea : % , croppedAreaPixel : px 단위
      // console.log(croppedArea, croppedAreaPixels, crop);
      setCroppedAreaPixels(croppedAreaPixels);
      // showCroppedImage();
    },
    []
  );

  const readFile = (file: File) => {
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.addEventListener("load", () => resolve(reader.result), false);
      reader.readAsDataURL(file);
    });
  };
  const onChange = async (e: any) => {
    if (e === "") {
      setUrl("");
      setNewProfileImage(null);
      return;
    }

    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0];
      setNewProfileImage(file);
      if (file.size > 2000000) {
        notify(
          "warning",
          intl.formatMessage({
            id: "label.profile.notify.image.over.error",
            defaultMessage: "The size of the image is larger than recommended",
          })
        );
        return;
      }
      let imageDataUrl = await readFile(file);
      if (imageDataUrl) {
        setUrl(String(imageDataUrl));
      }
      return;
    }
  };
  const onDelete = () => {
    onChange("");
    if (fileInputRef.current && fileInputRef.current.value) {
      // console.log(fileInputRef.current.value, "fileInputRef.current.value");
      fileInputRef.current.value = "";
      // onChange("");
    }
  };

  const onFinish = async () => {
    // console.log(props.newUrl, croppedAreaPixels);
    if (newProfileImage !== null && url !== "") {
      try {
        const croppedImage = await getCroppedImg(url, croppedAreaPixels);
        props.onFinish(croppedImage, newProfileImage);
      } catch (e) {
        console.error(e);
      }
    }
  };

  const [minZoom, setMinZoom] = useState<number>(0.1);
  const [objectFit, setObjectFit] = useState<
    "vertical-cover" | "horizontal-cover" | "contain" | "auto-cover" | undefined
  >("vertical-cover");
  const containerRef = useRef<HTMLDivElement>(null);

  const onMediaLoaded = (media: MediaSize) => {
    const ratio = media.width / media.height;
    if (containerRef.current) {
      const { clientHeight } = containerRef.current;
      if (ratio < 1) {
        setMinZoom(150 / clientHeight);
      }
    }
  };

  useEffect(() => {
    convertURLtoFile(props.originUrl).then((data) => {
      setNewProfileImage(data);
    });
  }, [props.originUrl]);

  return (
    <BigPopupNoHeaderTemplate>
      <button onClick={() => props.onModal("close")} className="close-button">
        <img alt="close button" src={CLOSE_ICON} />
      </button>
      <Text fontSize={24} bold>
        Upload Photo
      </Text>
      <ModalWrapper ref={containerRef}>
        <Cropper
          image={url}
          crop={crop}
          zoom={zoom}
          minZoom={minZoom}
          zoomSpeed={0.3}
          onCropChange={setCrop}
          onCropComplete={onCropComplete}
          onZoomChange={setZoom}
          cropShape="round"
          cropSize={{
            width: 150,
            height: 150,
          }}
          aspect={1}
          objectFit={objectFit}
          onMediaLoaded={onMediaLoaded}
          restrictPosition={true} // minZoom 이 1 보다 작은 경우에 false 로 해야 사진 줄인 후 위치 조절 가능
        />
      </ModalWrapper>
      <ButtonWrapper>
        <div className="upload-input-box">
          <MapUpload
            onUpload={onChange}
            onDelete={onDelete}
            ref={fileInputRef}
            isDeleteDisabled={isEmpty(url)}
          />
          <Text color={COLORS.BUTTONS1} fontSize={13}>
            Maximum Size: 2MB
          </Text>
        </div>
        <RightButtonWrapper>
          <p>
            <PrimaryButton
              buttonType="cancel"
              label="Cancel"
              onClickBtn={() => props.onModal("close")}
            />
          </p>
          <p>
            <PrimaryButton
              label="Save"
              onClickBtn={onFinish}
              disabled={isEmpty(url)}
            />
          </p>
        </RightButtonWrapper>
      </ButtonWrapper>
    </BigPopupNoHeaderTemplate>
  );
}

const ModalWrapper = styled.div`
  width: 100%;
  margin: 30px 0 10px;

  .reactEasyCrop_Container {
    position: relative;
    width: 100%;
    height: 460px;
  }

  .reactEasyCrop_CropAreaGrid {
    ::after,
    ::before {
      border-color: ${({ theme }) => `${theme.COLORS.WHITE}30`};
    }
  }
`;

const ButtonWrapper = styled.div`
  width: 100%;
  ${FLEX.FlexBetweenCenter};

  > * {
    flex: 1;
  }

  .upload-input-box {
    ${FLEX.FlexStartCenter};
    gap: 8px;
    flex: 3;
  }

  ${mediaQuery.isMobile} {
    flex-direction: column;

    .upload-input-box {
      flex-direction: column;
      align-items: flex-end;
    }
  }
`;

const RightButtonWrapper = styled.div`
  ${FLEX.FlexEndCenter};
  gap: 10px;

  > p {
    ${FLEX.FlexEndCenter};
    font-size: ${calcRem(14)};
    button {
      padding: 10px;
    }
  }
  ${mediaQuery.isMobile} {
    padding-top: 20px;
  }
`;
