import { useCallback, useEffect, useState } from "react";
import { SortOrder, TableColumn } from "react-data-table-component";
import TableButton from "components/atoms/buttons/TableButton";
import { EmailPresenter } from "./EmailPresenter";

import * as s from "./EmailStyled";
import {
  BasePagination,
  SORT_DIRECTION,
} from "api/interfaces/commonInterface.interface";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { deleteEmail, getEmailList, postEmail } from "api/emailAPI";
import { AddEmail, Email } from "api/interfaces/emailInterface.interface";
import { ProfileAccountInfo } from "api/interfaces/accountInterface.interface";
import { useAppSelector } from "redux/hooks";
import { deleteHtmlTag, showCount } from "utils/functions";
import { getAccountTimeFormat } from "utils/timeUtil";
import { putEmail } from "api/emailAPI";
import { Location, useLocation, useNavigate } from "react-router-dom";
import { notify } from "components/atoms/notification/Notification";
import { useIntl } from "react-intl";
import { isNull } from "lodash";
import { menuNavigation, queryNavigate } from "utils/MenuUtil";

export enum EMAIL_PAGE_TABS {
  SENT_EMAILS,
  SCHEDULED_EMAILS,
}

export default function EmailContainer(): JSX.Element {
  const intl = useIntl();
  const navigate = useNavigate();
  const location:Location = useLocation();
  
  const [isAddModal, setIsAddModal] = useState(false);
  const [isEditModal, setIsEditModal] = useState(false);
  const [isViewModal, setIsViewModal] = useState(false);

  const [currentMenu, setCurrentMenu] = useState<EMAIL_PAGE_TABS>(
    EMAIL_PAGE_TABS.SENT_EMAILS
  );
  const queryClient = useQueryClient();
  const selectedAccount: ProfileAccountInfo = useAppSelector(
    (state) => state.accountSettings
  );

  const emailMenus = [
    { label: "Sent Emails", value: true },
    { label: "Scheduled Emails", value: true },
  ];

  const onChangeCurrentMenu = (index: number) => {
    setCurrentMenu(index);
  };

  useEffect(() => {
    const changeParam = new URLSearchParams(location.search);
    if (changeParam.size > 0) {
      setEmailQueryInfo((info) => {
        return {
          ...info,
          pageNum: isNull(changeParam.get("pageNum"))
            ? 0
            : Number(changeParam.get("pageNum")),
          pageLimit: isNull(changeParam.get("pageLimit"))
            ? 20
            : Number(changeParam.get("pageLimit")),
          keyword: isNull(changeParam.get("keyword"))
            ? ""
            : (changeParam.get("keyword") as string),
          sortType: isNull(changeParam.get("sortType"))
            ? "subject"
            : (changeParam.get("sortType") as string),
          sortDirection: isNull(changeParam.get("sortDirection"))
            ? SORT_DIRECTION.DESC
            : (changeParam.get("sortDirection") as SORT_DIRECTION),
        };
      });
    } else {
      setEmailQueryInfo((info) => {
        return {
          ...info,
          pageNum: 0,
          pageLimit: 20,
          keyword: "",
          sortType: "subject",
          sortDirection: SORT_DIRECTION.DESC,
        };
      });
    }
  },[location]);

  const mutationDeleteEmail = useMutation(deleteEmail, {
    onSuccess: () => {
      if (currentMenu === EMAIL_PAGE_TABS.SENT_EMAILS) {
        notify(
          "success",
          intl.formatMessage({
            id: "label.setting.email.notify.delete.success",
            defaultMessage: "Email history deleted successfully.",
          })
        );
      }
      if (currentMenu === EMAIL_PAGE_TABS.SCHEDULED_EMAILS) {
        notify(
          "success",
          intl.formatMessage({
            id: "label.setting.email.notify.schedule.delete.success",
            defaultMessage: "Scheduled email deleted successfully.",
          })
        );
      }
      queryClient.invalidateQueries("email");
    },
    onError: () => {
      if (currentMenu === EMAIL_PAGE_TABS.SENT_EMAILS) {
        notify(
          "error",
          intl.formatMessage({
            id: "label.setting.email.notify.delete.fail",
            defaultMessage: "Failed to delete email history.",
          })
        );
      }
      if (currentMenu === EMAIL_PAGE_TABS.SCHEDULED_EMAILS) {
        notify(
          "error",
          intl.formatMessage({
            id: "label.setting.email.notify.schedule.delete.fail",
            defaultMessage: "Failed to delete scheduled email.",
          })
        );
      }
    },
  });

  const onDeleteEmail = (emailId: string) => {
    mutationDeleteEmail.mutate({
      accountId: selectedAccount.accountId,
      emailId: emailId,
    });
  };

  const onDetailRecipients = (row: Email) => {
    setSelectedEmail(row);
    menuNavigation(selectedAccount, navigate, `/manager/emails/recipients/${row.subject}/${row.emailId}`);
  };

  //temp columns
  const columns: TableColumn<Email>[] = [
    {
      name: "Subject",
      sortable: true, // 정렬 활성화,
      sortField: "subject",
      selector: (row) => row.subject,
    },
    {
      name: "Recipients",
      selector: (row) => {
        if (row.recipientType === "USER")
          return showCount(row.users.map((user) => user.name));
        else return row.recipientType;
      },
    },
    {
      name: "Message",
      selector: (row) => deleteHtmlTag(row.message),
    },
    {
      name: "Open Rate",
      selector: (row) => `${row.openRate}%`,
      center: true,
    },
    {
      id: "createTime",
      sortField: "createTime",
      name: "Send Time",
      sortable: true,
      selector: (row) =>
        row.scheduleTime === undefined
          ? getAccountTimeFormat(row.sendTime, selectedAccount, true)
          : getAccountTimeFormat(row.scheduleTime, selectedAccount, true),
    },
    // 삭제 버튼
    {
      name: "",
      minWidth: "100",
      cell: (row) => (
        // disabled row level 에 따라 변경
        <s.ButtonWrapper>
          <TableButton
            buttonType="warning"
            label="Delete"
            onClickBtn={() => onDeleteEmail(row.emailId)}
          />
        </s.ButtonWrapper>
      ),
      right: true,
    },
    // 마지막 버튼
    {
      name: "",
      minWidth: "300",
      cell: (row) => (
        <s.Row>
          <TableButton
            label="View Recipients"
            onClickBtn={() => onDetailRecipients(row)}
          />
          <span className="row-click-button">
            <TableButton label="Open" onClickBtn={() => onRowClicked(row)} />
          </span>
        </s.Row>
      ),
      right: true,
    },
  ];

  const scheduledColumns: TableColumn<Email>[] = [
    {
      name: "Subject",
      sortable: true, // 정렬 활성화,
      sortField: "subject",
      selector: (row) => row.subject,
    },
    {
      name: "Recipients",
      selector: (row) => row.recipientType,
    },
    {
      name: "Message",
      selector: (row) => deleteHtmlTag(row.message),
    },
    {
      id: "scheduleTime",
      name: "Scheduled Send Time",
      selector: (row) =>
        row.scheduleTime !== undefined
          ? getAccountTimeFormat(row.scheduleTime, selectedAccount, true)
          : "N/A",
    },
    // 마지막 버튼
    {
      name: "",
      minWidth: "300",
      cell: (row) => (
        <s.Row>
          <TableButton
            buttonType="warning"
            label="Delete"
            onClickBtn={() => onDeleteEmail(row.emailId)}
          />
          <span className="row-click-button">
            <TableButton label="Edit" onClickBtn={() => onRowClicked(row)} />
          </span>
        </s.Row>
      ),
      right: true,
    },
  ];

  const onModal = (type?: string) => {
    if (type === "add") {
      setIsAddModal(!isAddModal);
    }
    if (type === "edit") {
      setIsEditModal(!isEditModal);
    }
    if (type === "view") {
      setIsViewModal(!isViewModal);
    }
  };

  // row 전체 클릭 시
  const onRowClicked = (row: Email) => {
    if (currentMenu === EMAIL_PAGE_TABS.SENT_EMAILS) {
      onModal("view");
    }
    if (currentMenu === EMAIL_PAGE_TABS.SCHEDULED_EMAILS) {
      onModal("edit");
    }
    setSelectedEmail(row);
  };

  const [emails, setEmails] = useState<Email[]>([]);
  const [scheduleEmails, setScheduleEmails] = useState<Email[]>([]);
  const [selectedEmail, setSelectedEmail] = useState<Email>();

  const [pending, setPending] = useState<boolean>(true);

  const [emailQueryInfo, setEmailQueryInfo] = useState<BasePagination>({
    total: 0,
    pageNum: 0,
    pageLimit: 20,
    keyword: "",
    sortType: "subject",
    sortDirection: SORT_DIRECTION.ASC,
  });

  const [status, setStatus] = useState({
    totalTitle: "Total",
    totalValue: 0,
  });

  const { error, refetch } = useQuery(
    ["email", emailQueryInfo, currentMenu],
    () =>
      getEmailList({
        isSchedule: currentMenu === EMAIL_PAGE_TABS.SENT_EMAILS ? false : true,
        payload: emailQueryInfo,
        accountId: selectedAccount.accountId,
      }),
    {
      retry: 3,
      refetchOnWindowFocus: false,
      onSuccess: (res: any) => {
        if (res.error !== 0) {
          if (currentMenu === EMAIL_PAGE_TABS.SENT_EMAILS) {
            setEmails([]);
          }
          if (currentMenu === EMAIL_PAGE_TABS.SCHEDULED_EMAILS) {
            setScheduleEmails([]);
          }
          setStatus((info) => {
            return {
              ...info,
              totalValue: 0,
            };
          });
          return;
        }
        if (currentMenu === EMAIL_PAGE_TABS.SENT_EMAILS) {
          setEmails(res.result);
        }
        if (currentMenu === EMAIL_PAGE_TABS.SCHEDULED_EMAILS) {
          setScheduleEmails(res.result);
        }
        setStatus((info) => {
          return {
            ...info,
            totalValue: res.page.total,
          };
        });
      },
      onError: (e: any) => {
        if (currentMenu === EMAIL_PAGE_TABS.SENT_EMAILS) {
          setEmails([]);
        }
        if (currentMenu === EMAIL_PAGE_TABS.SCHEDULED_EMAILS) {
          setScheduleEmails([]);
        }
        setStatus((info) => {
          return {
            ...info,
            totalValue: 0,
          };
        });
      },
      onSettled: (e: any) => {
        setPending(false);
      },
    }
  );

  const mutationCreateEmail = useMutation(postEmail, {
    onSuccess: () => {
      if (currentMenu === EMAIL_PAGE_TABS.SENT_EMAILS) {
        notify(
          "success",
          intl.formatMessage({
            id: "label.common.notify.email.success",
            defaultMessage: "Email sent successfully",
          })
        );
      }
      if (currentMenu === EMAIL_PAGE_TABS.SCHEDULED_EMAILS) {
        notify(
          "success",
          intl.formatMessage({
            id: "label.common.notify.email.success",
            defaultMessage: "Email sent successfully",
          })
        );
      }
      queryClient.invalidateQueries("email");
      setIsAddModal(false);
    },
    onError: () => {
      if (currentMenu === EMAIL_PAGE_TABS.SENT_EMAILS) {
        notify(
          "error",
          intl.formatMessage({
            id: "label.common.notify.email.fail",
            defaultMessage: "Failed to send email.",
          })
        );
      }
      if (currentMenu === EMAIL_PAGE_TABS.SCHEDULED_EMAILS) {
        notify(
          "error",
          intl.formatMessage({
            id: "label.common.notify.email.fail",
            defaultMessage: "Failed to send email.",
          })
        );
      }
    },
  });

  const mutationUpdateEmail = useMutation(putEmail, {
    onSuccess: () => {
      notify(
        "success",
        intl.formatMessage({
          id: "label.email.notify.update.success",
          defaultMessage: "Email update successfully",
        })
      );
      queryClient.invalidateQueries("email");
      setIsEditModal(false);
    },
    onError: () => {
      notify(
        "error",
        intl.formatMessage({
          id: "label.email.notify.update.fail",
          defaultMessage: "Email Update failed",
        })
      );
    },
  });

  const onClickSendEmail = useCallback(
    (email: AddEmail, emailId?: string) => {
      if (email.recipientType !== "USER") {
        delete email.users;
      }

      if (emailId) {
        mutationUpdateEmail.mutate({
          accountId: selectedAccount.accountId,
          emailId: emailId,
          payload: email,
        });
      } else {
        mutationCreateEmail.mutate({
          accountId: selectedAccount.accountId,
          payload: email,
        });
      }
    },
    [mutationCreateEmail]
  );

  const onChangePage = (page: number, totalRows: number) => {
    // console.log(page, totalRows);
    if (page !== emailQueryInfo.pageNum + 1) {
      queryNavigate(navigate, location.pathname, {
        pageLimit: emailQueryInfo.pageLimit,
        pageNum: page - 1,
        keyword: emailQueryInfo.keyword as string,
        sortType: emailQueryInfo.sortType,
        sortDirection: emailQueryInfo.sortDirection as SORT_DIRECTION,
      }, selectedAccount);
      setPending(true);
      // setEmailQueryInfo((info) => {
      //   return { ...info, pageNum: (page - 1) as number };
      // });
    }
  };

  const handleSort = async (
    selectedColumn: TableColumn<any>,
    sortDirection: SortOrder
  ) => {
    if (selectedColumn.sortField !== undefined) {
      setEmailQueryInfo((info) => {
        return {
          ...info,
          sortType: selectedColumn.sortField as string,
          sortDirection: sortDirection.toUpperCase() as SORT_DIRECTION,
        };
      });
      //setPending(true);
    }
  };

  const onClickSearch = useCallback(
    (keyword: string) => {
      if(emailQueryInfo.keyword !== keyword){
        queryNavigate(navigate, location.pathname, {
          pageLimit: emailQueryInfo.pageLimit,
          pageNum: 0,
          keyword: keyword,
          sortType: emailQueryInfo.sortType,
          sortDirection: emailQueryInfo.sortDirection as SORT_DIRECTION,
        }, selectedAccount);
      }else{
        refetch();
      }
      setPending(true);
      // setEmailQueryInfo((query) => {
      //   return {
      //     ...query,
      //     keyword,
      //   };
      // });
      // refetch();
    },
    [emailQueryInfo.keyword, emailQueryInfo.pageLimit, emailQueryInfo.sortDirection, emailQueryInfo.sortType, location.pathname, navigate, refetch, selectedAccount]
  );

  return (
    <EmailPresenter
      onModal={onModal}
      isAddModal={isAddModal}
      isEditModal={isEditModal}
      isViewModal={isViewModal}
      columns={columns}
      data={
        currentMenu === EMAIL_PAGE_TABS.SENT_EMAILS ? emails : scheduleEmails
      }
      currentMenu={currentMenu}
      emailMenus={emailMenus}
      onChangeCurrentMenu={onChangeCurrentMenu}
      scheduledColumns={scheduledColumns}
      onClickSendEmail={onClickSendEmail}
      selectedEmail={selectedEmail}
      onRowClicked={onRowClicked}
      onChangePage={onChangePage}
      handleSort={handleSort}
      paginationInfo={emailQueryInfo}
      status={status}
      onClickSearch={onClickSearch}
      tablePending={pending}
    />
  );
}
