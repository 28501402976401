import { Text } from "components/atoms/text/Text";
import { COLORS } from "styles/colors";
import SmallPopupDefaultTemplate from "components/templates/modal/small/SmallPopupDefaultTemplate";
import BigButton from "components/atoms/buttons/BigButton";
import styled from "styled-components";
import { calcRem } from "styles/theme";
import { FLEX } from "styles/flex";

type Props = {
  onDelete: () => void;
  onConfirmDelete: () => void;
  label?: string;
};

export default function DeleteConfirmModal({
  onDelete,
  onConfirmDelete,
  label,
}: Props): JSX.Element {
  return (
    <SmallPopupDefaultTemplate
      onModal={onDelete}
      button={
        <ButtonWrapper>
          <BigButton buttonType="cancel" label="No" onClickBtn={onDelete}/>
          <BigButton label="Yes" onClickBtn={onConfirmDelete} delay/>
        </ButtonWrapper>
      }
    >
      <DeleteTextWrapper>
        <Text fontSize={20} bold>
          Are you sure you want to delete this{" "}
          {label !== undefined ? label : "video"}?
        </Text>
        <Text fontSize={20} bold color={COLORS.COMPANY}>
          This {label !== undefined ? label : "video"} will be deleted
          permanently and cannot be recovered.
        </Text>
      </DeleteTextWrapper>
    </SmallPopupDefaultTemplate>
  );
}
export const DeleteTextWrapper = styled.div`
  text-align: center;
  padding: 20px 0;
  line-height: ${calcRem(28)};
  ${FLEX.FlexCenterCenter};
  gap: 8px;
  flex-direction: column;

  * {
    word-break: break-word;
    white-space: normal;
  }
`;

const ButtonWrapper = styled.div`
  ${FLEX.FlexCenterCenter};
  gap: 10px;
`;
