import styled from "styled-components";
import { COLORS, LIGHT_THEME_COLORS } from "styles/colors";
import { FLEX } from "styles/flex";
import NotFound from "assets/images/404-error.png";
import { calcRem } from "styles/theme";
import BigButton from "components/atoms/buttons/BigButton";
import * as mediaQuery from "components/MediaQuery";
import { useAuth } from "components/Auth";
import { GoPageNav } from "utils/MenuUtil";
import { useNavigate } from "react-router-dom";
import { useAppDispatch } from "redux/hooks";
import { getStartPageUrl } from "utils/functions";

export default function OrgNotfound(): JSX.Element {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const auth = useAuth();

  const onReturn = () => {
    // if ([1, 2, 3].includes(auth.user.startPage)) {
    //   GoPageNav(navigate, dispatch, getStartPageUrl(auth.user.startPage), {
    //     replace: true,
    //   });
    // } else {
    //   GoPageNav(navigate, dispatch, getStartPageUrl(1), {
    //     replace: true,
    //   });
    // }
    window.location.href = "/login";
  };
  return (
    <Wrapper>
      <Inner>
        <div className="title">
          <h1>Error 403</h1>
          <span>Organization not found.</span>
        </div>
        <Contents>
          <div className="img-wrapper">
            <img src={NotFound} alt="not_found_page_icon" />
          </div>
          <div className="text-wrapper">
            <h3>Sorry, we couldn't find organization.</h3>
            <p>
              Organization you are looking for may have been  deleted, or  
              you do not have access permissions.
              <br />
              <br />
              Click the button below to return to a working page on the site and
              resuming using myDW.
            </p>
            <BigButton label="Return to myDW" onClickBtn={onReturn} />
          </div>
        </Contents>
      </Inner>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  width: 100%;
  ${FLEX.FlexCenterCenter};
  padding: 50px;
`;

const Inner = styled.div`
  ${FLEX.FlexStartCenter};
  flex-direction: column;
  color: ${COLORS.BORDER};
  background-color: ${({ theme }) =>
    theme.value == "light" ? LIGHT_THEME_COLORS.LIGHTGRAY : COLORS.PRIMARY1};
  padding: 30px 50px;
  border-radius: 6px;
  width: 800px;
  height: max-content;

  .title {
    width: 100%;
    padding-bottom: 20px;
    border-bottom: 1px solid ${COLORS.BORDER};
    font-size: ${calcRem(18)};
    color: ${COLORS.LIGHTGRAY};

    > h1 {
      color: ${({ theme }) => theme.COLORS.WHITE};
      font-weight: 400;
      font-size: ${calcRem(40)};
      margin-bottom: 8px;
    }

    ${mediaQuery.isMobile} {
      text-align: center;
    }
  }
`;

const Contents = styled.div`
  width: 100%;
  padding-top: 40px;
  ${FLEX.FlexBetweenStart};
  gap: 20px;

  ${mediaQuery.isDefault} {
    height: 250px;
  }

  ${mediaQuery.isMobile} {
    flex-direction: column;
    align-items: center;
  }

  .img-wrapper {
    ${mediaQuery.isDefault} {
      flex: 1;
      flex-direction: column;
      height: 100%;
    }
    ${mediaQuery.isMobile} {
      flex-direction: column;
      height: 100px;
    }

    > img {
      height: 100%;
    }
  }

  .text-wrapper {
    flex: 1.5;
    ${FLEX.FlexBetweenStart};
    flex-direction: column;
    color: ${COLORS.LIGHTGRAY};
    font-size: ${calcRem(15)};
    line-height: ${calcRem(18)};

    h3 {
      color: ${({ theme }) => theme.COLORS.WHITE};
      font-size: ${calcRem(24)};
      font-weight: 400;
    }

    button {
      width: 100%;
    }

    ${mediaQuery.isDefault} {
      height: 100%;
    }

    ${mediaQuery.isMobile} {
      align-items: center;
      text-align: center;
      gap: 10px;
    }
  }
`;
