import * as s from "../ModalTemplateStyled";
import ModalHeader, {
  ModalSubTitleInterface,
} from "components/blocks/modalheader/ModalHeader";
import ModalTemplate from "../ModalTemplate";
type Props = {
  title: string;
  subTitle?: string | ModalSubTitleInterface[];
  children?: React.ReactNode;
  onModal: () => void;
};

const BigPopupTemplate = ({ title, subTitle, children, onModal }: Props) => (
  <ModalTemplate>
    <s.Modal id={title + "_popup"}>
      <ModalHeader title={title} subTitle={subTitle} onModal={onModal} />
      <s.Inner>{children}</s.Inner>
    </s.Modal>
  </ModalTemplate>
);

export default BigPopupTemplate;
