import styled from "styled-components";
import { COLORS } from "styles/colors";
import * as mediaQuery from "components/MediaQuery";

export const StorageWrapper = styled.div`
  ${mediaQuery.isDefault} {
    width: 380px;
  }
  ${mediaQuery.isMobile} {
    width: 100%;
  }
`;
