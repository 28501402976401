import { Table } from "components/atoms/table/Table";
import { SortOrder, TableColumn } from "react-data-table-component";
import { TableTemplate } from "components/templates/default/table/TableTemplate";
import { GroupDataRow } from "api/interfaces/recorderInterface.interface";
import AddGroupsModal from "../add/groups";
import Status, { StatusType } from "components/blocks/status/Status";
import { ClickSearch } from "components/atoms/input/search/SearchInput";
import { BasePagination } from "api/interfaces/commonInterface.interface";

type Props = {
  isAddModal: boolean;
  isEditModal: boolean;
  onModal: (type: string) => void;
  columns: TableColumn<GroupDataRow>[];
  status: StatusType;
  data: Array<GroupDataRow>;
  selectGroup?: GroupDataRow;
  onClickEdit: (row: GroupDataRow) => void;
  onChangePage: (page: number, totalRows: number) => void;
  handleSort: (
    selectedColumn: TableColumn<any>,
    sortDirection: SortOrder
  ) => void;
  onClickSearch: ClickSearch;
  paginationPerPage: number | undefined;
  paginationInfo:BasePagination;
  totalRows: number | undefined;
  isRowClickEditable : boolean;
  initialPending : boolean;
};

export default function GroupPresenter({
  isAddModal,
  isEditModal,
  onModal,
  columns,
  status,
  data,
  selectGroup,
  paginationPerPage,
  paginationInfo,
  totalRows,
  onClickEdit,
  onChangePage,
  handleSort,
  onClickSearch,
  isRowClickEditable,
  initialPending
}: Props): JSX.Element {
  const centerChildren = <Status status={status} />;

  return (
    <>
      <TableTemplate
        onClickBtn={() => onModal("add")}
        buttonLabel="Add Groups"
        centerChildren={centerChildren}
        onClickSearch={onClickSearch}
        requiredPermissions="manageRecordersAndGroups"
        keyword={paginationInfo.keyword}
      >
        <Table
          columns={columns}
          data={data}
          paginationPerPage={paginationPerPage}
          paginationTotalRows={totalRows}
          onRowClicked={onClickEdit}
          handleSort={handleSort}
          onChangePage={onChangePage}
          defaultSortFieldId="name"
          isRowClickEditable={isRowClickEditable}
          progressPending={initialPending}
        />
      </TableTemplate>
      {isAddModal && <AddGroupsModal onModal={() => onModal("add")} />}
      {isEditModal && (
        <AddGroupsModal
          isEditModal={isEditModal}
          selectGroup={selectGroup}
          onModal={() => onModal("edit")}
        />
      )}
    </>
  );
}
