import WARNING_CIRCLE_ICON from "assets/icons/warning_circle_red_border.svg";
import NOT_AVAILABLE_ICON from "assets/icons/warning_circle.svg";
import DefaultThumbnail from "../DefaultThumbnail";
import * as s from "./ThumbnailStyled";

import { useEffect, useLayoutEffect, useState } from "react";
import { getThumbnailErrorString } from "utils/ReportUtil";
import ThumbnailAvailable from "./ThumbnailAvailable";

type CVVThumbnailProps = {
  videoLoss: boolean;
  thumbnailUrl: string | undefined;
  isLazy?: boolean;
  isRecorderList?: boolean;
  errorStatus?: string;
};

function CVVThumbnail(props: CVVThumbnailProps): JSX.Element {
  const [thumbnailAvailable, setThumbnailAvailable] = useState<boolean>(false);

  const imageOnErrorHandler = (
    event: React.SyntheticEvent<HTMLImageElement, Event>
  ) => {
    setThumbnailAvailable(true);
    event.currentTarget.style.display = "none";
  };

  useEffect(() => {
    setThumbnailAvailable(false);
  }, [props.thumbnailUrl]);

  return (
    <DefaultThumbnail>
      {props.thumbnailUrl === undefined || props.thumbnailUrl === "" ? (
        <s.Container
          className="video-loss-image"
          isRecorderList={props.isRecorderList}
        >
          <img
            src={WARNING_CIRCLE_ICON}
            className="empty_thumb"
            alt="warning circle icon"
            loading={
              props.isLazy !== undefined && props.isLazy ? "lazy" : "eager"
            }
          />
          <p>{`Thumbnail Not Found`}</p>
        </s.Container>
      ) : props.errorStatus !== undefined &&
        props.errorStatus?.toLowerCase() !== "success" ? (
        <>
          <s.Container className="video-loss-image">
            <img
              src={WARNING_CIRCLE_ICON}
              className="empty_thumb"
              alt="warning circle icon"
              loading={
                props.isLazy !== undefined && props.isLazy ? "lazy" : "eager"
              }
            />
            <p className="warning-text">
              {getThumbnailErrorString(props.errorStatus)}
            </p>
          </s.Container>
        </>
      ) : (
        <>
          <s.ImageBg>
            <div className="black-filter"></div>
            {thumbnailAvailable ? (
              <s.Container className="video-loss-image transcoding-image">
                <ThumbnailAvailable isRecorderList={props.isRecorderList} />
              </s.Container>
            ) : (
              <img
                src={props.thumbnailUrl}
                alt="recorder thumbnail"
                onError={imageOnErrorHandler}
                loading="lazy"
                className="thumbnail-url"
              />
            )}
          </s.ImageBg>
        </>
      )}
    </DefaultThumbnail>
  );
}

export default CVVThumbnail;
