import styled from "styled-components";
import { FLEX } from "styles/flex";
import { COLORS } from "styles/colors";
import SEARCH_ICON from "assets/icons/search.svg";
import SEARCHGRAY_ICON from "assets/icons/search_gray.svg";
import Input from "components/atoms/input/Input";
import * as mediaQuery from "components/MediaQuery";
import { useEffect, useState } from "react";
import { checkBlankSpace, checkKeywordSpace } from "utils/functions";

export type ClickSearch = (keyword: string) => void;
interface SearchInputProps {
  isValidated?: boolean;
  placeholder?: string;
  children?: any;
  keyword?: string;
  map?: boolean;
  onClickSearch?: ClickSearch;
  onChange: (value: string) => void;
  disabled?: boolean;
  debounce?: number;
}

const Wrapper = styled.label<{
  map?: boolean;
  isValidated?: boolean;
  disabled?: boolean;
}>`
  ${FLEX.FlexBetweenCenter};
  background-color: ${COLORS.WHITE};
  border-radius: 4px;
  flex: 2;
  border: 1px solid #abb5c5;
  max-width: 100%;
  width: 100%;

  ${({ isValidated }) =>
    isValidated &&
    `
    border: 1px solid #FF2E2E;
      background-color: #FFD5D5;
  `}

  ${({ disabled }) =>
    disabled &&
    `
    background-color: ${COLORS.BUTTONS1};
  `}

  input {
    border: none;

    ${({ map }) =>
      map && {
        backgroundColor: COLORS.PRIMARY2,
        padding: 15,
        color: COLORS.WHITE,
      }}
    ::placeholder {
      ${({ map }) =>
        map && {
          color: "#8F95A5",
        }}
    }
  }

  ${({ map }) =>
    map && {
      maxWidth: "100%",
      backgroundColor: COLORS.PRIMARY2,
      borderRadius: 9,
      overflow: "hidden",
      border: `1px solid ${COLORS.BUTTONS1}`,
      boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.16)",
    }}
`;

const SearchButton = styled.button<{ map?: boolean }>`
  ${FLEX.FlexCenterCenter};
  padding: 0 8px;

  > img {
    height: 18px;
  }
`;

const DebounceSearchInput = ({
  isValidated, // validation true 일 때 빨간색 border
  placeholder,
  keyword = "",
  map,
  onClickSearch,
  onChange,
  disabled,
  debounce = 500,
}: SearchInputProps): JSX.Element => {
  const onClick = onClickSearch ? onClickSearch : () => {};
  const [value, setValue] = useState<string>(keyword);
  useEffect(() => {
    setValue(keyword);
  }, [keyword]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      onChange(value);
    }, debounce);

    return () => clearTimeout(timeout);
  }, [value]);

  return (
    <Wrapper
      map={map}
      isValidated={isValidated}
      disabled={disabled !== undefined ? disabled : false}
    >
      <Input
        isValidated={isValidated}
        placeholder={placeholder ? placeholder : "Search"}
        disabled={disabled !== undefined ? disabled : false}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            if (checkKeywordSpace(value)) return;
            if (onClick) {
              onClick(value);
            }
          }
        }}
        onChange={(e) => setValue(e.target.value)}
      />
      <SearchButton map={map}>
        <img
          className="search-input-icon"
          src={map ? SEARCHGRAY_ICON : SEARCH_ICON}
          alt="search icon"
          onClick={() => {
            if (checkKeywordSpace(value)) return;
            if (onClick) {
              onClick(value);
            }
          }}
        />
      </SearchButton>
    </Wrapper>
  );
};

export default DebounceSearchInput;
