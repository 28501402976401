import {
  useNavigate,
  useParams,
} from "react-router-dom";
import { useCallback, useEffect, useState } from "react";
import React from "react";
import {
  VideoShareViewResult,
} from "api/interfaces/videoshareInterface.interface";
import { useMutation, useQuery } from "react-query";
import {
  deleteVideoShare,
  getVideoShareViewByVideoId,
} from "api/videoshareAPI";
import { notify } from "components/atoms/notification/Notification";
import ViewVSPresenter from "./ViewVSPresenter";
import { useIntl } from "react-intl";
import useApiError from "hook/useApiError";
import { CustomError } from "api/interfaces/commonInterface.interface";
import { useAppSelector } from "redux/hooks";
import { ProfileAccountInfo } from "api/interfaces/accountInterface.interface";

export default function ViewVSContainer(): JSX.Element {
  const intl = useIntl();
  const { handleError } = useApiError();
  
  const initialState = {
    status: "open",
    sharing: false,
    title: "",
    cameraName: "",
    recorderName: "",
    mergedSystemName: "",
    type: "",
    time: "",
    username: "",
    url: "",
    startTimeMs: 0,
    creationTimeMs: 0,
    durationMs: 0,
    size: 0,
    date: "",
    tags: [],
    timeZone: "",
    description : "",
  };

  const navigate = useNavigate();
  const { videoId } = useParams();

  const [isLoading, setIsLoading] = useState(true);
  const [isDelete, setIsDelete] = useState(false);

  const [state, setState] = useState<VideoShareViewResult>(initialState);
  const [videoUrl, setVideoUrl] = useState<string>("");

  const [isDisabledAdd, setIsDisabledAdd] = useState<boolean>(state.sharing);

  const playerReference = React.useRef(null);

  const selectedAccount: ProfileAccountInfo = useAppSelector(
    (state) => state.accountSettings
  );

  const playerReady = (player: any) => {
    playerReference.current = player;

    // handling video player
    player.on("waiting", () => {
      console.log("Video Player is waiting");
    });
    player.on("dispose", () => {
      console.log("Video player will dispose");
    });
  };

  const { error, refetch } = useQuery(
    ["videoshareView", videoId],
    () => {
      if (videoId !== undefined) {
        return getVideoShareViewByVideoId({
          videoId: videoId,
        });
      }
    },
    {
      retry: 0,
      refetchOnWindowFocus: false,
      onSuccess: (res: any) => {
        // console.log(res, "video share view");
        if (res.error === 0) {
          setState(res.result);
          setVideoUrl(res.result.url);
        }
      },
      onError: (e: any) => {
        console.log(e, "error");
      },
    }
  );

  const onDelete = (value: boolean) => {
    setIsDelete(value);
  };

  const onConfirmDelete = () => {
    mutationDeleteVideoShare.mutate({
      videoId,
    });
  };

  const mutationDeleteVideoShare = useMutation(deleteVideoShare, {
    onSuccess: (res: any) => {
      if (res.status === 200) {
        // queryClient.invalidateQueries("videoShare");
        // navigate(-1);
        //BUG [RND-361]
        notify("success",intl.formatMessage({
          id: "label.videoshare.notify.delete.success",
          defaultMessage: "Video share deleted successfully.",
        }))
        navigate("/videoShare");
      }
    },
    onError: (err:CustomError) => {
      handleError(err, intl.formatMessage({
        id: "label.videoshare.notify.delete.fail",
        defaultMessage: "Failed to delete video share.",
      }))
    },
  });

  useEffect(() => {
    if (state) {
      setIsLoading(false);

      setIsDisabledAdd(state.sharing);
    }
  }, [state]);

  const onUpdateContentValues = useCallback((key: string, value: any) => {
    setState((prev) => {
      return {
        ...prev,
        [key]: value,
      };
    });
  }, []);

  return (
    <ViewVSPresenter
      videoId={videoId}
      videoUrl={videoUrl}
      state={state}
      isLoading={isLoading}
      isDelete={isDelete}
      isDisabledAdd={isDisabledAdd}
      playerReady={playerReady}
      onDelete={onDelete}
      onConfirmDelete={onConfirmDelete}
      onUpdateContentValues={onUpdateContentValues}
      selectedAccount={selectedAccount}
    />
  );
}
