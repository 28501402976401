import styled from "styled-components";
import { COLORS } from "styles/colors";
import { calcRem } from "styles/theme";
import { FLEX } from "styles/flex";
import {
  calcStoragePercent,
  formatBytes,
  formatBytesFromMB,
} from "utils/functions";
import * as mediaQuery from "components/MediaQuery";

interface Props {
  totalStorage: number;
  usedStorage: number;
  isMB?: boolean;
}

const StorageBar = ({ usedStorage, totalStorage, isMB }: Props) => {
  const percentage = calcStoragePercent(usedStorage, totalStorage);

  //RND-548: report view에서 storage가 MB단위..
  return (
    <Bar>
      <Percentage>{percentage ? percentage.toFixed(2) : 0}%</Percentage>
      <StatusStorage percentage={percentage}>
        {totalStorage !== undefined && totalStorage !== 0
          ? isMB !== undefined
            ? `${formatBytesFromMB(usedStorage)}/${formatBytesFromMB(
                totalStorage
              )}`
            : `${formatBytes(usedStorage)}/${formatBytes(totalStorage)}`
          : ""}
      </StatusStorage>
      <StatusBar percentage={percentage}></StatusBar>
    </Bar>
  );
};

const Bar = styled.div`
  width: 100%;
  height: 20px;
  background-color: ${({ theme }) =>
    theme.value === "light" ? COLORS.LIGHTGRAY : COLORS.BUTTONS1};
  position: relative;
  border-radius: 1px;

  span {
    color: ${COLORS.WHITE};
    font-size: ${calcRem(14)};
    position: absolute;
  }
`;

export const StatusBar = styled.p<{ percentage: number }>`
  /* position: absolute; */
  z-index: 21;
  width: ${({ percentage }) => `${percentage}%`};
  max-width: 100%;
  height: 100%;
  border-radius: 1px;

  ${({ percentage }) =>
    percentage > 60 &&
    percentage <= 80 &&
    `
    background: linear-gradient(45deg, #FFA200, #FFCC00);
  `};

  ${({ percentage }) =>
    percentage <= 60 &&
    `
    background: linear-gradient(45deg, #3BC800, #17FFE0);
  `};
  ${({ percentage }) =>
    percentage > 80 &&
    `
    background: linear-gradient(45deg, ${COLORS.RED}, ${COLORS.COMPANY});
  `};
`;

const StatusStorage = styled.span<{ percentage: number }>`
  /* right: ${({ percentage }) => `${90 - percentage}%`}; */
  right: 30px;
  top: 50%;
  transform: translateY(-50%);
  /* z-index: 22; */
`;

const Percentage = styled.span`
  top: 50%;
  transform: translateY(-50%);
  left: 14px;
  /* z-index: 22; */
`;

export default StorageBar;
