import styled from "styled-components";
import { COLORS, LIGHT_THEME_COLORS } from "styles/colors";
import { calcRem } from "styles/theme";

interface Props {
  isValidated?: boolean;
}

const Input = styled.input<Props>`
  width: 100%;
  /* max-height: 50px; */
  border-radius: 4px;
  padding: 8px;
  box-sizing: border-box;
  border: 1px solid #cacaca;
  /* ${({ theme }) =>
    theme.value === "light"
      ? `
      border-color: #828c9e;
    
    `
      : `
    border-color: #828c9e;
  `} */
  color: ${COLORS.BLACK};
  font-size: ${calcRem(14)};

  ::placeholder {
    color: #abb5c5;
  }
  :disabled {
    background-color: ${({ theme }) =>
      theme.value === "light" ? LIGHT_THEME_COLORS.LIGHTGRAY : COLORS.BUTTONS1};
    ${({ theme }) =>
      theme.value === "light" &&
      `
      border-color: ${LIGHT_THEME_COLORS.BORDER};
    `}
    color: ${({ theme }) => `${theme.COLORS.WHITE}60`};
    cursor: not-allowed;
  }

  ${({ isValidated }) =>
    isValidated &&
    `
      border: 1px solid ${COLORS.VALIDATERED};
      background-color: #FFD5D5;
  `}
`;

export default Input;
