import styled from "styled-components";
import { COLORS } from "styles/colors";
import { FLEX } from "styles/flex";
import { calcRem } from "styles/theme";
import * as mediaQuery from "components/MediaQuery";

export const Wrapper = styled.div`
  position: relative;
`;

export const Bubble = styled.div`
  position: absolute;
  z-index: 600;
  top: 35px;
  left: 50%;
  transform: translateX(-50%);
`;

export const BubbleInner = styled.ul`
  ${FLEX.FlexStartStart};
  flex-direction: column;
  width: 100%;
  font-weight: 500;
  font-size: ${calcRem(12)};
  gap: 10px;
  padding: 4px;

  * {
    font-weight: 500;
  }

  > li {
    ${FLEX.FlexBetweenCenter};
    width: 100%;
    flex: 1;
    > span {
      display: flex;
      flex: 2;
    }

    .detail-bubble-value {
      justify-content: center;
      flex: 1;
    }
  }
`;
