import { Table } from "components/atoms/table/Table";
import { SortOrder, TableColumn } from "react-data-table-component";
import { TableTemplate } from "components/templates/default/table/TableTemplate";
import Status, { StatusType } from "components/blocks/status/Status";
import { useLocation } from "react-router-dom";
import AddRule from "./modal/add/rules";
import EditRule from "./modal/edit/EditRuleContainer";
import {
  ActiveAlert,
  ActiveAlertList,
  AlertRuleListResponse,
  HistoryDetailAlert,
  PostAlertRequest,
  SelectedAlertView,
} from "api/interfaces/alertInterface.interface";
import { ClickSearch } from "components/atoms/input/search/SearchInput";
import { ProfileAccountInfo } from "api/interfaces/accountInterface.interface";
import * as mediaQuery from "components/MediaQuery";
import {
  BasePagination,
  LEVEL_TYPE,
} from "api/interfaces/commonInterface.interface";
import AlertDetailModal from "components/templates/modal/alert";

type Props = {
  columns: TableColumn<ActiveAlert>[];
  //mobileColumns: TableColumn<ActiveAlertList>[];
  data: Array<ActiveAlert>;
  onModal: (type?: string) => void;
  isAddModal: boolean;
  isEditModal: boolean;
  isViewModal: boolean;
  status: StatusType;
  onRowClick: (row: ActiveAlert) => void;
  onClickSearch: ClickSearch;
  paginationPerPage: number;
  paginationInfo: BasePagination;
  onChangePage: (page: number, totalRows: number) => void;
  handleSort: (
    selectedColumn: TableColumn<any>,
    sortDirection: SortOrder
  ) => void;
  paginationTotalRows: number;
  selectedAccount: ProfileAccountInfo;
  isRowClickEditable: boolean;
  initialPending: boolean;
  selectedAlert: SelectedAlertView | undefined;
};

export function AlertPresenter(props: Props): JSX.Element {
  const location = useLocation();
  const centerChildren = <Status status={props.status} />;

  // const data = props.alertRules ? props.alertRules.result[0].ruleList : [];

  return (
    <>
      <TableTemplate
        onClickSearch={props.onClickSearch}
        centerChildren={centerChildren}
        keyword={props.paginationInfo.keyword}
        requiredPermissions={
          props.selectedAccount.accountLevel === LEVEL_TYPE.DW
            ? ""
            : "manageAlertRules"
        }
      >
        {/* <mediaQuery.Default> */}
        <Table
          columns={props.columns}
          data={props.data}
          onRowClicked={props.onRowClick}
          paginationTotalRows={props.paginationTotalRows}
          paginationPerPage={props.paginationPerPage}
          onChangePage={props.onChangePage}
          handleSort={props.handleSort}
          defaultSortFieldId="createdAt"
          defaultSortAsc={false}
          isRowClickEditable={props.isRowClickEditable}
          progressPending={props.initialPending}
        />
        {/* </mediaQuery.Default> */}
        {/* <mediaQuery.Mobile>
            <Table
              columns={props.mobileColumns}
              data={props.data}
              onRowClicked={props.onRowClick}
              paginationTotalRows={props.paginationTotalRows}
              paginationPerPage={props.paginationPerPage}
              onChangePage={props.onChangePage}
              handleSort={props.handleSort}
              defaultSortFieldId="createdAt"
              defaultSortAsc={false}
              isRowClickEditable={props.isRowClickEditable}
            />
          </mediaQuery.Mobile> */}
      </TableTemplate>
      {props.isViewModal && props.selectedAlert !== undefined && (
        <AlertDetailModal
          onModal={() => props.onModal("view")}
          selectedAlert={props.selectedAlert}
        />
      )}
    </>
  );
}
