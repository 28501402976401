import { Layout } from "react-grid-layout";
import * as s from "../TableItemStyled";
import { TableItemName } from "../TableItemList";
import TableItemHeader from "../TableItemHeader";

interface Props {
  layout: Layout;
  chartLayout: Layout[];
  onDeleteWidget: (key: TableItemName) => void;
  onLayoutChange: (layout: Layout[]) => void;
}

export default function Revenue(props: Props): JSX.Element {
  return (
    <>
      <TableItemHeader
        layout={props.layout}
        chartLayout={props.chartLayout}
        onDeleteWidget={props.onDeleteWidget}
        onLayoutChange={props.onLayoutChange}
      />
      <s.Contents className="drag-cancel-content">
        <s.ContentWrapper>
          <s.ContentInner></s.ContentInner>
        </s.ContentWrapper>
      </s.Contents>
    </>
  );
}
