import BigPopupTemplate from "components/templates/modal/big/BigPopupTemplate";
import SmallPopupTemplate from "components/templates/modal/small/SmallPopupTemplate";
import { useState } from "react";
import styled from "styled-components";
import { FLEX } from "styles/flex";
import AddHealth from "./health";
import AddCvv from "./cvv";
import AddInstall from "./installation";
import AddInventory from "./inventory";
import AddThumbnail from "./thumbnail";
import AddTimelapse from "./timelapse";
import {
  CVVReport,
  HealthReport,
  InstallReport,
  InventoryReport,
  ThumbnailReport,
} from "api/interfaces/reportInterface.interface";
import { ReportDataRow } from "api/interfaces/reportInterface.interface";
import AddReportItem, { AddListType } from "./AddReportItem";
import { changeTypeNumberForReportText } from "utils/functions";

type Props = {
  onModal: (type?: string) => void;
  isEdit?: boolean;
  reportRow?: ReportDataRow;
  data?:
    | HealthReport
    | InventoryReport
    | InstallReport
    | CVVReport
    | ThumbnailReport;
};

export type AddReportListType =
  | "health"
  | "cvv"
  | "inventory"
  | "installation"
  | "thumbnail"
  | "timelapse";
export default function AddReport(props: Props): JSX.Element {
  // console.log(props.reportRow);
  type ObjType = {
    [key in AddReportListType]: React.ReactNode;
  };

  // next 버튼 클릭 시 true 로 전환 (이 방식이 맞는 지 모르겠음. by.ydoni)
  const [isAdd, setIsAdd] = useState<boolean>(props.isEdit ? true : false);

  const [checkedType, setCheckedType] = useState<AddReportListType>(
    props.isEdit && props.reportRow !== undefined
      ? changeTypeNumberForReportText(props.reportRow.type)
      : "health"
  );

  const addType: ObjType = {
    health: (
      <AddHealth
        onModal={props.onModal}
        isEdit={props.isEdit}
        reportRow={props.reportRow}
        data={props.data as HealthReport}
      />
    ),
    cvv: (
      <AddCvv
        onModal={props.onModal}
        isEdit={props.isEdit}
        reportRow={props.reportRow}
        data={props.data as CVVReport}
      />
    ),
    inventory: (
      <AddInventory
        onModal={props.onModal}
        isEdit={props.isEdit}
        reportRow={props.reportRow}
        data={props.data as InventoryReport}
      />
    ),
    installation: (
      <AddInstall
        onModal={props.onModal}
        isEdit={props.isEdit}
        reportRow={props.reportRow}
        data={props.data as InstallReport}
      />
    ),
    thumbnail: (
      <AddThumbnail
        onModal={props.onModal}
        isEdit={props.isEdit}
        reportRow={props.reportRow}
        data={props.data as ThumbnailReport}
      />
    ),
    timelapse: <AddTimelapse onModal={props.onModal} />,
  };

  const addList: AddListType[] = [
    {
      title: "Health",
      subTitle: "Provides a usage summary of one or more sites.",
      value: "health",
      disabled: false,
    },
    {
      title: "CVV (Camera View Verification)",
      subTitle: "Uses CVV to inform you of any scenery changes.",
      value: "cvv",
      disabled: false,
    },
    {
      title: "Inventory",
      subTitle: "Displays a list of sites with detailed information.",
      value: "inventory",
      disabled: false,
    },
    {
      title: "Installation",
      subTitle:
        "Provides installation confirmation with recorder and camera information.",
      value: "installation",
      disabled: false,
    },
    {
      title: "Thumbnail",
      subTitle: "Save thumbnails periodically.",
      value: "thumbnail",
      disabled: true,
    },
    {
      title: "Timelapse",
      subTitle:
        "Create timelapses at a set duration and speed for any of your camera channels.",
      value: "timelapse",
      disabled: true,
    },
  ];

  const onChangeType = (value: AddReportListType) => {
    setCheckedType(value);
  };

  return props.isEdit || isAdd ? (
    <BigPopupTemplate
      title={props.isEdit ? "Edit Report" : "Add Report"}
      subTitle={addList.find((add) => add.value === checkedType)?.title}
      onModal={props.onModal}
    >
      {addType[checkedType]}
    </BigPopupTemplate>
  ) : (
    <SmallPopupTemplate
      title="Create a New Report"
      subTitle="Select a type of report"
      onClick={() => setIsAdd(true)}
      onModal={props.onModal}
    >
      <RadioWrapper>
        {addList.map((item, index) => (
          <AddReportItem
            item={item}
            index={index}
            onChangeType={onChangeType}
            checkedType={checkedType}
          />
        ))}
      </RadioWrapper>
    </SmallPopupTemplate>
  );
}

const RadioWrapper = styled.div`
  ${FLEX.FlexBetweenStart};
  gap: 20px;
  flex-direction: column;
  width: 100%;
  color: ${({ theme }) => theme.COLORS.WHJITE};
  /* width: 400px;
  max-width: 400px; */
  /* height: 300px; */
`;
