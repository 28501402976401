import ZOOM_PLUS from "assets/icons/zoom_plus.svg";
import ZOOM_MINUS from "assets/icons/zoom_minus.svg";
import HOME from "assets/icons/home.svg";
import MapButton from "components/atoms/buttons/map/MapButton";
import styled from "styled-components";
import { FLEX } from "styles/flex";
import * as mediaQuery from "components/MediaQuery";
import { MdCenterFocusStrong } from "react-icons/md";
import { AiOutlineZoomIn, AiOutlineZoomOut } from "react-icons/ai";
import { COLORS } from "styles/colors";
import DEFAULT_MAP from "assets/icons/map/map_type_default.png";
import SATELLITE_MAP from "assets/icons/map/map_type_satellite.png";
import { useAppSelector } from "redux/hooks";

type Props = {
  isRoadView: boolean;
  onZoomPlus: () => void;
  onZoomOut: () => void;
  onZoomFit: () => void;
  onChangeView: () => void;
};
const MapControl = (props: Props): JSX.Element => {
  const theme = useAppSelector((state) => state.theme.theme);
  const isLightTheme = theme === "light";

  const controls = [
    {
      type: "roadView",
      src: props.isRoadView ? SATELLITE_MAP : DEFAULT_MAP,
      image: true,
      onClick: () => props.onChangeView(),
    },
    {
      type: "home",
      src: HOME,
      icon: (
        <MdCenterFocusStrong
          color={isLightTheme ? COLORS.BLACK : COLORS.WHITE}
          size={24}
        />
      ),
      onClick: () => props.onZoomFit(),
    },
    {
      type: "zoomPlus",
      src: ZOOM_PLUS,
      icon: (
        <AiOutlineZoomIn
          color={isLightTheme ? COLORS.BLACK : COLORS.WHITE}
          size={24}
        />
      ),
      onClick: () => props.onZoomPlus(),
    },
    {
      type: "zoomMinus",
      src: ZOOM_MINUS,
      icon: (
        <AiOutlineZoomOut
          color={isLightTheme ? COLORS.BLACK : COLORS.WHITE}
          size={24}
        />
      ),
      onClick: () => props.onZoomOut(),
    },
  ];

  return (
    <ControlContainer>
      {controls.map((item, index) => (
        <MapButton button={item} key={index} />
      ))}
    </ControlContainer>
  );
};

const ControlContainer = styled.p`
  ${FLEX.FlexStartCenter};
  gap: 5px;
  ${mediaQuery.isDefault} {
    flex-direction: column;
  }
  ${mediaQuery.isMobile} {
    gap: 4px;
    flex-direction: row-reverse;
  }
`;

export default MapControl;
