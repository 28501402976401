import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { NoticeInfo } from "api/interfaces/noticeInterface.interface";

export interface NoticeState {
  notices: NoticeInfo[];
}

const initialState: NoticeState = {
  notices: [],
};

export const noticeSlice = createSlice({
  name: "notices",
  initialState,
  reducers: {
    setNotices: (state, { payload }: PayloadAction<NoticeInfo[]>) => {
      state.notices = payload;
    },
    deleteNotice: (state, { payload }: PayloadAction<string>) => {
      state.notices.map((item, index) => {
        if (item.noticeId === payload) {
          state.notices.splice(index, 1);
        }
      });
    },
  },
});

export const { setNotices, deleteNotice } = noticeSlice.actions;

export default noticeSlice.reducer;
