import { useRef, useEffect, useState, forwardRef } from "react";
import styled from "styled-components";
import { FLEX } from "styles/flex";
import { calcRem } from "styles/theme";

import { ReactComponent as CHEVRON_DOWN } from "assets/icons/chevron_down_white.svg";
import { ReactComponent as CHEVRON_UP } from "assets/icons/chevron_up_white.svg";
import { COLORS, LEVELS } from "styles/colors";
import UserProfileImage from "./UserProfileImage";
import { useAuth } from "components/Auth";
import { ProfileAccountInfo } from "api/interfaces/accountInterface.interface";
import { useAppSelector } from "redux/hooks";
import * as mediaQuery from "components/MediaQuery";
import { useDispatch } from "react-redux";
import { setIsPopup } from "redux/reducers/menu/userPopup";
import UserPopup from "./UserPopup";
import { setMenu } from "redux/reducers/menu/menuSlice";
import { useNavigate } from "react-router-dom";
import { ReactComponent as AccountSVG } from "assets/icons/sidenav/account.svg";

interface UserProfileProps {
  isMap?: boolean;
  onChangeSwitch: () => void;
}

const UserProfile = forwardRef(
  ({ onChangeSwitch }: UserProfileProps, ref: any): JSX.Element => {
    const auth = useAuth();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const account: ProfileAccountInfo = useAppSelector(
      (state) => state.accountSettings
    );

    const isPopup: boolean = useAppSelector((state) => state.userPopup);
    // const buttonRef = useRef<any>(null);

    const onPopup = (event: any) => {
      event.preventDefault();
      event.stopPropagation();

      dispatch(setIsPopup());
    };

    const onChangeSwitchMobile = () => {
      onChangeSwitch();
      // dispatch(setMenu("Account"));
      // navigate("/account", {
      //   state: {
      //     isSwitchMenu: true,
      //   },
      //   replace: true,
      // });
    };

    return (
      <Container level={account.accountLevel} ref={ref}>
        <Wrapper onClick={onPopup} level={account.accountLevel}>
          {/* user 사진 */}
          <ImageWrapper>
            <UserProfileImage />
          </ImageWrapper>
          <UserInfo level={account.accountLevel}>
            <li key="UserProfile_user-name" className="user-name">
              <span>{auth.user?.email}</span>
              <mediaQuery.Default>
                <p className="chevron-icon">
                  {isPopup ? <CHEVRON_UP /> : <CHEVRON_DOWN />}
                </p>
              </mediaQuery.Default>
            </li>
            <li key="UserProfile_business-name" className="business-name">
              {/* <strong> */}
              <div className="org">
                <AccountSVG />
              </div>
              {/* Org.
              </strong> */}
              {account.accountName}
            </li>
          </UserInfo>
          <mediaQuery.Mobile>
            <p className="chevron-icon">
              {isPopup ? (
                <CHEVRON_UP color={COLORS.WHITE} />
              ) : (
                <CHEVRON_DOWN color={COLORS.WHITE} />
              )}
            </p>
          </mediaQuery.Mobile>
        </Wrapper>
        <mediaQuery.Mobile>
          {isPopup && <UserPopup onChangeSwitch={onChangeSwitchMobile} />}
        </mediaQuery.Mobile>
      </Container>
    );
  }
);

export default UserProfile;

const Container = styled.div<{ level: string }>`
  position: relative;

  ${mediaQuery.isMobile} {
    background-color: ${({ level }) => LEVELS[level]};
    border-radius: 50px;
    padding: 5px;
    width: 100%;
    margin-top: 16px;
    max-width: 200px;
  }
`;

const Wrapper = styled.div<{ level: string }>`
  ${FLEX.FlexStartCenter};
  gap: 6px;
  cursor: pointer;
  .chevron-icon {
    flex: 1;
    ${FLEX.FlexCenterCenter};

    > svg {
      width: 12px;
      path {
        fill: ${(props) =>
          props.level === "EU" && props.theme.value === "light"
            ? COLORS.BLACK
            : COLORS.WHITE};
      }
    }
  }
  ${mediaQuery.isMobile} {
    justify-content: space-between;
    gap: 3px;
    width: 100%;

    .chevron-icon {
      > svg {
        path {
          fill: ${COLORS.WHITE};
        }
      }
    }
  }
`;

const ImageWrapper = styled.div`
  width: 32px;
  height: 32px;
`;

const UserInfo = styled.ul<{ level: string }>`
  ${FLEX.FlexBetweenStart};
  flex-direction: column;
  height: 100%;
  user-select: none;

  li {
    font-weight: 400;
    white-space: nowrap;
    line-height: ${calcRem(16)};
  }

  .user-name {
    color: ${COLORS.WHITE};
    color: ${(props) => props.level === "EU" && props.theme.COLORS.WHITE};

    /* color: ${(props) => props.theme.COLORS.WHITE}; */
    font-size: ${calcRem(14)};
    > span {
      max-width: 200px;
      text-overflow: ellipsis;
      overflow: hidden;
      padding: 3px 0;
    }
  }

  .business-name {
    font-size: ${calcRem(12)};
    font-weight: 500;
    color: ${COLORS.WHITE}98;
    color: ${(props) => props.level === "EU" && props.theme.COLORS.WHITE}98;
    ${FLEX.FlexStartCenter};
    gap: 4px;

    strong {
      ${FLEX.FlexStartCenter};
      gap: 3px;
    }
    .org {
      width: 13px;
      height: 13px;

      > svg {
        width: 100%;
        height: 100%;
      }
    }
  }

  ${mediaQuery.isDefault} {
    .user-name {
      ${FLEX.FlexStartCenter};
      gap: 8px;

      > img {
        width: 10px;
      }
    }
    .business-name {
      max-width: 140px;
      overflow: hidden;
      text-overflow: ellipsis;
      /* ${({ level }) =>
        level === "" ? null : ` color: ${LEVELS[level]} `} */
    }
  }

  ${mediaQuery.isMobile} {
    flex: 5;
    width: 100%;
    overflow: hidden;
    color: ${COLORS.WHITE};

    /* .business-name {
      color: ${COLORS.WHITE};
    } */
    .user-name {
      font-weight: 500;
    }

    .user-name,
    .business-name {
      color: ${COLORS.WHITE};
      width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .chevron-icon {
      color: ${COLORS.WHITE};
    }
  }
`;
