import styled from "styled-components";
import { COLORS } from "styles/colors";
import { FLEX } from "styles/flex";
import * as mediaQuery from "components/MediaQuery";
import { calcRem } from "styles/theme";

export const Container = styled.div`
  padding: 50px 0;
  color: ${({ theme }) => theme.COLORS.WHITE};
  ${FLEX.FlexStartStart};
  flex-direction: column;
  width: 100%;
  gap: 20px;

  .bubble-parent {
    position: relative;
  }
`;

export const BubbleContainer = styled.div<{ right?: boolean }>`
  position: absolute;
  top: 35px;
  z-index: 666;

  ${({ right }) =>
    right
      ? `
    right: -50%;


  `
      : `
  left: 50%;
  transform: translateX(-50%);
  `}
`;

export const Title = styled.div`
  padding-bottom: 20px;
`;

export const LicenseTitle = styled.div`
  ${FLEX.FlexStartCenter};
  gap: 24px;

  .preparing {
    color: ${COLORS.BUTTONS2};
    font-weight: 500;
    ${FLEX.FlexStartCenter};
    gap: 6px;

    > img {
      width: 15px;
    }
  }
  .trial {
    color: ${COLORS.WARNING};
    font-weight: 500;
    ${FLEX.FlexStartCenter};
    gap: 6px;

    > img {
      width: 15px;
    }
  }
  ${mediaQuery.isMobile} {
    flex-direction: column;
    gap: 10px;
    align-items: flex-start;

    .preparing {
      font-size: ${calcRem(14)};

      > img {
        width: 12px;
      }
    }
  }
`;

export const HealthWrapper = styled.div<{ disabled?: boolean }>`
  width: 100%;
  ${FLEX.FlexStartStart};
  flex-direction: column;
  gap: 20px;
  flex: 1;
  pointer-events: ${({ disabled }) => (disabled ? `none` : `auto`)};
  opacity: ${({ disabled }) => (disabled ? 0.2 : 1)};
`;

export const LicenseList = styled.ul`
  ${FLEX.FlexStartStart};
  gap: 20px;
  width: 100%;
  height: max-content;

  ${mediaQuery.isMobile} {
    flex-direction: column;
    padding: 0 10px;

    > li {
      width: 100%;
    }
  }
`;

export const StorageList = styled.ul`
  ${FLEX.FlexStartStart};
  gap: 30px;
  width: 100%;

  .storage-message {
    flex: 1;

    > div {
      flex: 1;
      width: 100%;
    }

    input {
      flex: 1;
    }
  }

  ${mediaQuery.isMobile} {
    flex-direction: column;
    background-color: ${({ theme }) =>
      theme.value === "light" ? COLORS.WHITE : "#1c2639"};
    padding: 20px;
    border-radius: 5px;

    ${({ theme }) =>
      theme.value === "light" &&
      `
    border: 1px solid ${COLORS.LIGHTGRAY};
  `}
  }
`;

export const LicenseItem = styled.li<{ isSelected?: boolean }>`
  display: flex;
  flex-direction: column;
  flex: 1;
  /* min-width: 320px;
  max-width: 320px; */
  min-width: max-content;
  height: 360px;
  background-color: ${({ theme }) =>
    theme.value === "light" ? COLORS.WHITE : "#070e17"};
  border-radius: 5px;
  padding: 20px;
  color: ${({ theme }) => theme.COLORS.WHITE};
  cursor: pointer;
  box-sizing: border-box;

  ${({ theme }) =>
    theme.value === "light" &&
    `
    border: 1px solid ${COLORS.LIGHTGRAY};
  `}

  ${({ isSelected, theme }) =>
    isSelected &&
    `
      outline: 3px solid ${theme.COLORS.SELECTED};
  `}

  &:first-child {
    cursor: unset;
  }
  &:last-child {
    background-color: ${({ theme }) =>
      theme.value === "light" ? theme.COLORS.FILTERITEM : "#1c2639"};
    cursor: unset;
  }
  .current-plan {
    font-size: ${calcRem(10)};
    color: ${({ theme }) => theme.COLORS.WHITE}90;
    font-weight: 500;
  }
  .add-title {
    font-size: ${calcRem(18)};
    height: 28px;
  }
  textarea {
    width: 100%;
    height: 50px;
    max-height: 50px;
    min-height: 50px;
    color: ${COLORS.BLACK};
  }

  ${mediaQuery.isDefault} {
    &:last-child {
      flex: 2;
      max-width: 700px;
    }
  }

  ${mediaQuery.isMobile} {
    &:last-child {
      width: 100%;
    }
  }
`;

export const LicenseTypeTitle = styled.div`
  padding-bottom: 8px;
  border-bottom: 1px solid #545e77;
  font-size: ${calcRem(20)};
  font-weight: 500;
  ${FLEX.FlexStartCenter};
  gap: 6px;
  white-space: nowrap;

  > img {
    width: 22px;
  }
`;

export const LicenseTypeList = styled.ul`
  gap: 15px;
  padding: 20px 0;
  width: 100%;

  > li {
    width: 100%;
    ${FLEX.FlexStartCenter};
    gap: 8px;

    > img {
      width: 15px;
    }
  }

  ${mediaQuery.isDefault} {
    ${FLEX.FlexStartStart};
    flex-direction: column;
    flex: 1;
  }

  ${mediaQuery.isMobile} {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    min-width: 0;
  }
`;

export const LicenseAdd = styled(LicenseTypeList)`
  .required-item {
    ${FLEX.FlexStartCenter};
    flex: 1;

    .count {
      padding: 0 6px;
    }
  }
  .amount-item {
    gap: 8px;
    ${FLEX.FlexStartCenter};
    button {
      gap: 20px;
      ${FLEX.FlexStartCenter};
      color: ${({ theme }) => theme.COLORS.WHITE};
    }
  }

  .amount-wrapper {
    .amount-item {
      width: 100%;
      justify-content: space-between;
    }
  }

  > li {
    width: 100%;
    ${FLEX.FlexBetweenCenter};

    padding: 0;
    .message-wrapper {
      width: 100%;
      ${FLEX.FlexStartStart};
      flex-direction: column;
      gap: 10px;
    }

    .title {
      font-weight: 500;
    }
    ${mediaQuery.isDefault} {
      gap: 30px;
      .amount-wrapper {
        ${FLEX.FlexStartCenter};
        gap: 20px;
      }
    }
  }

  ${mediaQuery.isMobile} {
    display: flex;
    flex-direction: column;
    .amount-wrapper {
      ${FLEX.FlexStartCenter};
      gap: 20px;
    }
    textarea {
      resize: none;
    }
  }
`;

export const StorageItem = styled.li`
  ${FLEX.FlexStartStart};
  flex-direction: column;
  /* flex: 1; */
  gap: 10px;

  .title-wrapper {
    width: 100%;
    ${FLEX.FlexBetweenCenter};

    > span {
      &:last-child {
        font-size: ${calcRem(14)};
      }
    }
  }
  .title {
    font-weight: 500;
  }

  /* > div {
    ${FLEX.FlexStartCenter};
  } */

  ${mediaQuery.isMobile} {
    width: 100%;
  }
`;

export const StorageWrapper = styled.div`
  width: 100%;

  padding: 6px 0;

  ${mediaQuery.isDefault} {
    min-width: 400px;
    max-width: 400px;
  }
`;

export const AmountWrapper = styled.div`
  ${FLEX.FlexStartCenter};
  gap: 20px;

  .amount-input {
    .ant-input-number {
      width: 80px;
    }
    ${FLEX.FlexStartCenter};
    gap: 8px;
  }
`;

export const ButtonWrapper = styled.div`
  ${FLEX.FlexStartCenter};
  gap: 14px;

  button {
    color: ${({ theme }) => theme.COLORS.WHITE};
  }
`;

export const ModalWrapper = styled.div`
  color: ${({ theme }) => theme.COLORS.WHITE};
  padding-top: 20px;

  .inner {
    padding: 20px;
    font-size: ${calcRem(14)};

    .modal-input-label-title {
      font-size: ${calcRem(14)} !important;
    }
  }
`;

export const ModalRequestContents = styled.ul`
  ${FLEX.FlexStartStart};
  flex-direction: column;
  gap: 16px;
  padding-bottom: 20px;

  .show-count {
    width: 100%;
    ${FLEX.FlexBetweenCenter};
  }
  > li {
    .title {
      font-weight: 500;
    }
  }
`;
