import { Fragment, useState , KeyboardEvent} from "react";
import Input from "./Input";
import { FaEyeSlash } from "react-icons/fa";
import { FaEye } from "react-icons/fa";
import styled from "styled-components";
interface Props {
    value :string;
    isValidated : boolean;
    onChange : (e: React.ChangeEvent<HTMLInputElement>) => void;
    onClickLogin: () => void;
}


const PasswordInput  = (props :Props) => {

    const [passwordType, setPasswordType] = useState("password");
    
    const togglePassword =()=>{
        if(passwordType==="password")
        {
         setPasswordType("text")
        }else{
        setPasswordType("password")
        }
      }
    return (
        <PasswordWrapper>
            <EyeSpan onClick={togglePassword}>
                { passwordType==="password"? <FaEyeSlash size={18}/> : <FaEye size={18}/>}
            </EyeSpan>
            <Input
             id="dw_login_input_password"
             type={passwordType}
             name="password"
             className="input-field"
             placeholder="Enter Password"
             value={props.value}
             onChange={props.onChange}
             autoComplete="new-password"
             onKeyDown={(e: KeyboardEvent<HTMLInputElement>) => {
                if (e.key === "Enter") {
                  props.onClickLogin();
                }
              }}
              autoFocus
              isValidated={props.isValidated} 
            ></Input>
            
        </PasswordWrapper>
    )
}

const PasswordWrapper = styled.div`
    width: 100%;
    position: relative;
`
const EyeSpan = styled.span`
    display: flex;
    align-items: center;
    position: absolute;
    height: 100%;
    top: 0;
    right: 0;
    padding-right: 10px;
`

export default PasswordInput;

