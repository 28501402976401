import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export type ThemeText = "light" | "dark";
export type Theme = { theme: ThemeText };

const initialState: Theme = { theme: "dark" };

/**
 * SideBar의 메뉴를 클릭 시 선택된 메뉴를 전역으로 저장하는 Redux
 * 저장된 메뉴ITem는 상단의 메뉴바를 바꿔주는데 사용함.
 * Default는 상단의 DashBoard Item
 */
// TODO Account와 Recorder의 상단에 표시로직 구현되어야함. 다른메뉴에서는 비활성화
export const ThemeReducer = createSlice({
  name: "theme",
  initialState,
  reducers: {
    setMode(theme, action: PayloadAction<"light" | "dark">) {
      theme.theme = action.payload;
    },
  },
});

export const { setMode } = ThemeReducer.actions;

export default ThemeReducer;
