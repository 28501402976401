import {
  useState,
  useCallback,
  useEffect,
} from "react";
import { useMutation } from "react-query";
import * as s from "styles/ModalCommonStyled";
import BigPopupTemplate from "components/templates/modal/big/BigPopupTemplate";
import { ModalInput } from "components/atoms/input/modal/ModalInput";
import Input from "components/atoms/input/Input";
import BigButton from "components/atoms/buttons/BigButton";

import {
  Validate,
} from "api/interfaces/commonInterface.interface";
import { videoGuideOptions, type Option } from "utils/options";

import { notify } from "components/atoms/notification/Notification";

import { MultiValue } from "react-select";
import { isEmpty } from "lodash";

import { ValidateMsg } from "api/interfaces/commonInterface.interface";
import { ValidateWord } from "components/atoms/text/Text";
import { useIntl } from "react-intl";
import Select from "components/atoms/select/Select";
import { VideoGuideGroupDto } from "api/interfaces/managerInterface.interface";
import { SelectGroup, SelectedCategory } from "../../VideoManagerContainer";
import { deleteGuideGroup } from "api/managerAPI";
import DeleteConfirmModal from "components/templates/modal/small/confirm/delete";

interface Props {
  isEdit?: boolean; // 편집 모드일 때
  selectedCategory :SelectedCategory;
  selectedGroup?: SelectGroup;
  onModal: (type?: string) => void;
  onClickSave: (groupDto: VideoGuideGroupDto, update?: boolean) => void;
  onClickDeactive?: (groupId: string) => void;
}

export enum UPDATE_TYPE {
  CATEGORY,
  GROUP_NAME,
}

function AddGroup(props: Props): JSX.Element {
  const intl = useIntl();

  const [isDeleteConfirm, setIsDeleteConfirm] = useState(false);

  const [validate, setValidate] = useState<Validate>({
    name: false,
  });
  const [validateMsg, setValidateMsg] = useState<ValidateMsg>({
    name: "",
  });

  const [groupInfo, setGroupInfo] = useState<VideoGuideGroupDto>({
    category: "",
    name: "",
    groupId: ""
  });


  useEffect(()=>{
    if(props.isEdit){
      if(props.selectedGroup !== undefined){
        setGroupInfo({
          category : props.selectedGroup.category,
          name : props.selectedGroup.groupName,
          groupId : props.selectedGroup.groupId
        });
      }
    }else{
      if(props.selectedCategory !== undefined){
        setGroupInfo({
          category : props.selectedCategory.categoryId,
          name : "",
          groupId : ""
        });
      }
    }
  },[props.isEdit, props.selectedGroup, props.selectedCategory])

  useEffect(()=>{
    if(props.selectedCategory){
      setGroupInfo((info) => {
        return { ...info, category: props.selectedCategory.categoryId as string };
      });
    }
  },[props.selectedCategory])


  const onChangeGroupInfo = useCallback(
    (
      type: UPDATE_TYPE,
      value: string | number | string[] | MultiValue<Option>
    ) => {
      if (type === UPDATE_TYPE.GROUP_NAME) {
        setGroupInfo((info) => {
          return { ...info, name: value as string };
        });
      } else if (type === UPDATE_TYPE.CATEGORY) {
        setGroupInfo((info) => {
          return { ...info, category: value as string };
        });
      } 
    },
    []
  );

  // const queryInfo: BasePagination = {
  //   total: 0,
  //   pageNum: 0,
  //   pageLimit: 1000,
  //   keyword: "",
  //   sortType: "name",
  //   sortDirection: SORT_DIRECTION.ASC,
  // };

  // const [groups, setGroups] = useState<Option[]>([]);
  // const { error } = useQuery(
  //   ["group", queryInfo],
  //   () =>
  //   getGroupListForEdit({
  //       payload: queryInfo,
  //       accountId: selectedAccount.accountId,
  //     }),
  //   {
  //     retry: 0,
  //     refetchOnWindowFocus: false,
  //     onSuccess: (res: any) => {
  //       if (res.result === undefined) {
  //         return;
  //       }
  //       setGroups(
  //         res.result.map((group: any) => {
  //           const { groupId, name } = group;
  //           return {
  //             value: groupId as string,
  //             label: name,
  //           };
  //         })
  //       );

  //       if (userDto.userGroups !== undefined) {
  //         const idList = userDto.userGroups.map((group) => group.groupId);

  //         setSelectGroup(
  //           res.result
  //             .map((group: any) => {
  //               const { groupId, name } = group;
  //               return {
  //                 value: groupId as string,
  //                 label: name,
  //               };
  //             })
  //             .filter((option: any) => idList.includes(option.value))
  //         );
  //       }
  //     },
  //     onError: (e: any) => {
  //       console.info(e);
  //     },
  //   }
  // );

  const mutationDeleteGroup = useMutation(deleteGuideGroup, {
    onSuccess: () => {
      notify("success", intl.formatMessage({
        id: "label.manager.video.group.delete.success",
        defaultMessage: "Video group deleted successfully."
      }));
      props.onModal("edit");
    },
    onError: () => {
      notify("error", intl.formatMessage({
        id: "label.manager.video.group.delete.fail",
        defaultMessage: "Failed to delete video group."
      }));
    },
  });

  const onClickDelete = useCallback(() => {
    setIsDeleteConfirm(false);
    if(props.selectedGroup?.groupId){
      mutationDeleteGroup.mutate({
        groupId: props.selectedGroup?.groupId,
      });
    }   
  },
  [mutationDeleteGroup, props.selectedGroup?.groupId]
);

  const onSaveBefore = useCallback(() => {
    setValidate((info) => {
      return { ...info, name: false };
    });
    setValidateMsg((info) => {
      return { ...info, name: "" };
    });

    let checkedFlag = false;
    if (isEmpty(groupInfo.name)) {
      checkedFlag = true;
      setValidate((info) => {
        return { ...info, name: true };
      });
      setValidateMsg((info) => {
        return {
          ...info,
          name: intl.formatMessage({
            id: "validateMsg.user.name",
            defaultMessage: "Please enter user name.",
          }) as string,
        };
      });
    }

    if (checkedFlag) {
      return;
    }

    props.onClickSave(groupInfo, props.isEdit);
  }, [groupInfo, intl, props]);

  return (
    <BigPopupTemplate
      title={props.isEdit ? "Edit Group" : "Add Group"}
      onModal={props.onModal}
    >
        <ModalInput label="Category" essential>
          <Select
            options={videoGuideOptions}
            //defaultValue={videoGuideOptions[0]}
            value={props.isEdit ? videoGuideOptions.filter(
              (option) => option.value === groupInfo.category
            ) : videoGuideOptions.filter(
              (option) => option.value === groupInfo.category)}
            isDisabled={props.isEdit}
            onChange={(value: string) => {
              onChangeGroupInfo(UPDATE_TYPE.CATEGORY, value);
              console.log({ value });
            }}            
          />
        </ModalInput>
      <s.Row>
        <ModalInput label="Group Name" essential>
          <Input
            placeholder="Enter group name."
            pattern="[A-Za-z]+"
            maxLength={50}
            value={groupInfo.name}
            onChange={(e) => {
              onChangeGroupInfo(UPDATE_TYPE.GROUP_NAME, e.target.value);
            }}
            isValidated={validate.groupName}
          />
          <ValidateWord>{validateMsg.groupName}</ValidateWord>
        </ModalInput>
      </s.Row>

      <s.ButtonWrapper>
        {props.isEdit && (
          <BigButton
            buttonType="warning"
            label="Delete"
            onClickBtn={() => setIsDeleteConfirm(true)}
          />
        )}
        <BigButton
          label="Save"
          onClickBtn={onSaveBefore}
        />
      </s.ButtonWrapper>
      {props.isEdit && isDeleteConfirm && (
        <DeleteConfirmModal
          label="VideoGuide"
          onDelete={() => setIsDeleteConfirm(false)}
          onConfirmDelete={onClickDelete}
        />
      )}
    </BigPopupTemplate>
  );
}

export default AddGroup;
