import InnerContainer from "components/atoms/inner/InnerContainer";
import styled from "styled-components";
import { COLORS } from "styles/colors";
import { noScrollBar } from "styles/commonStyles";
import { FLEX } from "styles/flex";
import { calcRem } from "styles/theme";
import * as mediaQuery from "components/MediaQuery";
import DefaultThumbnail from "components/atoms/image/DefaultThumbnail";

export const Container = styled.div`
  position: relative;
`;

export const VideoContainer = styled(InnerContainer)`
  padding-top: 30px;
`;

export const VideoDiv = styled.div`
  position: relative;
  width: 100%;

  > button {
    position: absolute;
    top: 40%;
    transform: translateY(-50%);
    height: 30px;

    > img {
      height: 100%;
    }
  }

  .button-left {
    left: -30px;
  }
  .button-right {
    right: -30px;
  }

  ${mediaQuery.isMobile} {
    margin-top: 30px;
  }
`;
export const VideoWrapper = styled.ul`
  ${mediaQuery.isDefault} {
    width: 100%;
    ${FLEX.FlexStartStart};
    gap: 20px;
    overflow: hidden;
    overflow-x: auto;
    padding: 16px 0;

    ${noScrollBar};
  }
`;

export const VideoItem = styled.li`
  cursor: pointer;

  * {
    white-space: normal;
  }
  .title {
    padding: 14px 0 8px;
  }

  ${mediaQuery.isDefault} {
    min-width: 18%;
    max-width: 18%;
  }

  ${mediaQuery.isMobile} {
    padding-bottom: 40px;
    width: 100%;
  }
`;

export const VideoThumbnail = styled(DefaultThumbnail)`
  img {
    object-fit: cover;
  }
  /* width: 100%;
  position: relative;

  img {
    overflow: hidden;
    object-fit: cover;
    width: 100%;
    height: auto;
  } */
`;

export const ModalContainer = styled.div`
  ${FLEX.FlexCenterCenter};
  width: 100vw;
  height: 100vh;

  .close-button {
    position: absolute;
    top: 50px;
    right: 50px;
    /* transform: scale(2); */
    width: 15px;

    img {
      width: 100%;
    }
  }
`;

export const VideoPlay = styled.div`
  /* width: 80vw;
  height: 100vh; */

  #player {
    width: 80vw;
    height: 70vh;
  }
`;
