import * as s from "./VideoGuideStyled";
import { useRef } from "react";
import { CenterlineText, Text } from "components/atoms/text/Text";
import { COLORS } from "styles/colors";
import CHEVRON_LEFT from "assets/icons/chevron_left_slide.svg";
import CHEVRON_RIGHT from "assets/icons/chevron_right_slide.svg";
import * as mediaQuery from "components/MediaQuery";
import { VideoGuideGroup } from "api/interfaces/managerInterface.interface";

type Props = { item: VideoGuideGroup; onChangeModal: (url: string) => void };

export default function VideoGuidesList({
  item,
  onChangeModal,
}: Props): JSX.Element {
  const horizontalScrollRef = useRef<any>(null);
  const handleNextButtonClick = (nextType: "prev" | "next") => {
    if (!horizontalScrollRef.current) return;
    if (nextType === "prev") {
      horizontalScrollRef.current.scrollTo({
        left:
          horizontalScrollRef.current.scrollLeft -
          horizontalScrollRef.current.offsetWidth,
        behavior: "smooth",
      });
    } else {
      horizontalScrollRef.current.scrollTo({
        left:
          horizontalScrollRef.current.scrollLeft +
          horizontalScrollRef.current.offsetWidth,
        behavior: "smooth",
      });
    }
  };

  return (
    <div>
      <CenterlineText color={COLORS.BUTTONS1} bold fontSize={20}>
        {item.name}
      </CenterlineText>
      <s.VideoDiv>
        <mediaQuery.Default>
          <button
            className="button-left"
            onClick={() => handleNextButtonClick("prev")}
          >
            <img src={CHEVRON_LEFT} />
          </button>
        </mediaQuery.Default>
        <s.VideoWrapper ref={horizontalScrollRef}>
          {item.videoGuideList?.map((video, index) => (
            <s.VideoItem
              key={`video-guide-${index}`}
              onClick={() => onChangeModal(video.videoUrl)}
            >
              <s.VideoThumbnail>
                {/* <ImageLabel time>02:04</ImageLabel> */}
                <img src={video.thumbnailUrl} alt="youtube thumbnail" />
              </s.VideoThumbnail>
              <Text className="title" bold>
                {video.title}
              </Text>
              <Text color={COLORS.BUTTONS1} fontSize={14}>
                {video.description}
              </Text>
            </s.VideoItem>
          ))}
        </s.VideoWrapper>
        <mediaQuery.Default>
          <button
            className="button-right"
            onClick={() => handleNextButtonClick("next")}
          >
            <img src={CHEVRON_RIGHT} />
          </button>
        </mediaQuery.Default>
      </s.VideoDiv>
    </div>
  );
}
