import { createSlice } from "@reduxjs/toolkit";
import {
  ICON_TYPE,
  NOTICE_COLOR,
} from "api/interfaces/noticeInterface.interface";

export interface CookieInterface {
  message: string;
  color: NOTICE_COLOR.COOKIE;
  type: ICON_TYPE.COOKIE;
}

export interface CookieState {
  cookie: boolean;
}

const initialState: CookieState = {
  cookie: true,
};

export const cookieSlice = createSlice({
  name: "cookie",
  initialState,
  reducers: {
    deleteCookieBar: (state) => {
      state.cookie = false;
    },
  },
});

export const { deleteCookieBar } = cookieSlice.actions;

export default cookieSlice.reducer;
