import { AddGroupsPresenter } from "./AddGroupsPresenter";
import { useAppSelector } from "redux/hooks";
import { ProfileAccountInfo } from "api/interfaces/accountInterface.interface";
import { useCallback, useEffect, useState } from "react";
import { CreateRecorderGroupRequest } from "api/interfaces/recorderInterface.interface";
import {
  deleteRecorderGroup,
  postRecorderGroup,
  putRecorderGroup,
} from "api/recorderAPI";
import { notify } from "components/atoms/notification/Notification";
import { useMutation } from "react-query";
import { GroupDataRow } from "api/interfaces/recorderInterface.interface";
import { checkWhiteSpaceRegEx } from "utils/regEx";
import {
  CustomError,
  Validate,
  ValidateMsg,
} from "api/interfaces/commonInterface.interface";
import { useIntl } from "react-intl";
import { useAuth } from "components/Auth";
import { isEmpty } from "lodash";
import useApiError from "hook/useApiError";

interface Props {
  onModal: () => void;
  selectGroup?: GroupDataRow;
  isEditModal?: boolean;
}

export enum UPDATE_TYPE {
  NAME,
  DESC,
  RECORDER,
}

function AddGroupsContainer(props: Props): JSX.Element {
  const intl = useIntl();
  const auth = useAuth();
  const { handleError } = useApiError();
  
  const [isConfirm, SetIsConfirm] = useState<boolean>(false);
  const selectedAccount: ProfileAccountInfo = useAppSelector(
    (state) => state.accountSettings
  );

  const extreactRecorderId = (systems: { mergedSystemId: string }[]) => {
    return systems.map((recorder) => recorder.mergedSystemId);
  };

  const [recorderGroupValidate, setRecorderGroupValidate] = useState<Validate>({
    isRecorderGroupName: false,
    isRecorderId: false,
  });
  const [recorderGroupValidateMsg, setRecorderGroupValidateMsg] =
    useState<ValidateMsg>({
      isRecorderGroupName: "",
      isRecorderId: "",
    });

  const [recorderGroup, setRecorderGroup] =
    useState<CreateRecorderGroupRequest>({
      name: "",
      description: "",
      mergedSystemIds: [],
      userId: undefined
    });

  useEffect(() => {
    if (props.isEditModal) {
      setRecorderGroup({
        name: props.selectGroup !== undefined ? props.selectGroup.name : "",
        description:
          props.selectGroup !== undefined ? props.selectGroup.description : "",
        mergedSystemIds:
          props.selectGroup !== undefined &&
          props.selectGroup.systems !== undefined
            ? extreactRecorderId(props.selectGroup.systems)
            : [],
        userId : props.selectGroup !== undefined ? props.selectGroup.userId : undefined 
      });
    }
  }, [props.selectGroup]);

  const mutationCreateRecorderGroup = useMutation(postRecorderGroup, {
    onSuccess: () => {
      notify("success", intl.formatMessage({
        id: "label.recorderGroup.notify.create.success",
        defaultMessage: "Recorder group added successfully.",
      }));
      props.onModal();
    },
    onError: (err: CustomError) => {
      handleError(
        err,
        intl.formatMessage({
          id: "label.recorderGroup.notify.create.fail",
          defaultMessage: "Failed to add recorder group.",
        })
      );
    },
  });

  const initRecorderGroupValidate = useCallback((field: string) => {
    setRecorderGroupValidate((info) => {
      return { ...info, [field as keyof typeof recorderGroupValidate]: false };
    });
    setRecorderGroupValidateMsg((info) => {
      return { ...info, [field as keyof typeof recorderGroupValidate]: "" };
    });
  }, []);

  const onClickCreate = useCallback(() => {
    if (isEmpty(recorderGroup.name) || checkWhiteSpaceRegEx(recorderGroup.name)) {
      setRecorderGroupValidate((info) => {
        return { ...info, isRecorderGroupName: true as boolean };
      });

      setRecorderGroupValidateMsg((info) => {
        return {
          ...info,
          isRecorderGroupName: intl.formatMessage({
            id: "validateMsg.recorderGroup.name",
            defaultMessage: "Please enter recorder group name.",
          }) as string,
        };
      });

      return;
    }

    // [0427] 그룹생성시 레코더를 선택하지 않는 빈그룹도 생성하도록 보완(By부)
    // if (recorderGroup.recorderIds.length === 0) {
    //   setRecorderGroupValidate((info) => {
    //     return { ...info, isRecorderId: true as boolean };
    //   });

    //   setRecorderGroupValidateMsg((info) => {
    //     return {
    //       ...info,
    //       isRecorderId: intl.formatMessage({
    //         id: "validateMsg.recorderGroup.emptyRecorder",
    //         defaultMessage: "Select one or more recorders",
    //       }) as string,
    //     };
    //   });
    //   return;
    // }

    mutationCreateRecorderGroup.mutate({
      payload: recorderGroup,
      accountId: selectedAccount.accountId,
    });
  }, [intl, mutationCreateRecorderGroup, recorderGroup, selectedAccount.accountId]);

  const onChangeRecorderGroupInfo = useCallback(
    (type: UPDATE_TYPE, value: string | string[]) => {
      if (type === UPDATE_TYPE.RECORDER) {
        setRecorderGroup((info) => {
          return { ...info, mergedSystemIds: value as string[] };
        });
      } else if (type === UPDATE_TYPE.NAME) {
        setRecorderGroup((info) => {
          return { ...info, name: value as string };
        });
      } else if (type === UPDATE_TYPE.DESC) {
        setRecorderGroup((info) => {
          return { ...info, description: value as string };
        });
      }
    },
    []
  );

  const setIsDeleteConfirm = (confirm: boolean) => {
    SetIsConfirm(confirm);
  };
  const onDelete = (value: boolean) => {
    SetIsConfirm(value);
  };

  const mutationDeleteRecorderGroup = useMutation(deleteRecorderGroup, {
    onSuccess: () => {
      notify("success", intl.formatMessage({
        id: "label.recorderGroup.notify.delete.success",
        defaultMessage: "Recorder group deleted successfully.",
      }));
      props.onModal();
    },
    onError: () => {
      notify("error", intl.formatMessage({
        id: "label.recorderGroup.notify.delete.fail",
        defaultMessage: "Failed to delete recorder group.",
      }));
    },
  });

  const onConfirmDelete = useCallback(() => {
    mutationDeleteRecorderGroup.mutate({
      recorderGroupId: props.selectGroup?.recorderGroupId as number,
      accountId: selectedAccount.accountId,
    });
  }, [mutationDeleteRecorderGroup, props.selectGroup?.recorderGroupId, selectedAccount.accountId]);

  const mutationPutRecorderGroup = useMutation(putRecorderGroup, {
    onSuccess: (res: any) => {
      if (res.error === 0) {
        notify("success", intl.formatMessage({
          id: "label.recorderGroup.notify.update.success",
          defaultMessage: "Recorder group updated successfully.",
        }));
        props.onModal();
      } else {
        notify("error", intl.formatMessage({
          id: "label.recorderGroup.notify.update.fail",
          defaultMessage: "Failed to update recorder group.",
        }));
      }
    },
    onError: (err: CustomError) => {
      handleError(
        err,
        intl.formatMessage({
          id: "label.recorderGroup.notify.update.fail",
          defaultMessage: "Failed to update recorder group.",
        })
      );
    },
  });

  const onClickUpdate = useCallback(() => {

    if (isEmpty(recorderGroup.name) || checkWhiteSpaceRegEx(recorderGroup.name)) {
      setRecorderGroupValidate((info) => {
        return { ...info, isRecorderGroupName: true as boolean };
      });

      setRecorderGroupValidateMsg((info) => {
        return {
          ...info,
          isRecorderGroupName: intl.formatMessage({
            id: "validateMsg.recorderGroup.name",
            defaultMessage: "Please enter recorder group name.",
          }) as string,
        };
      });

      return;
    }
    
    mutationPutRecorderGroup.mutate({
      payload: recorderGroup,
      accountId: selectedAccount.accountId,
      recorderGroupId: props.selectGroup?.recorderGroupId as number,
    });
  }, [intl, mutationPutRecorderGroup, props.selectGroup?.recorderGroupId, recorderGroup, selectedAccount.accountId]);

  return (
    <AddGroupsPresenter
      onModal={props.onModal}
      accountId={selectedAccount.accountId}
      onChangeRecorderGroupInfo={onChangeRecorderGroupInfo}
      onClickCreate={onClickCreate}
      selectData={recorderGroup}
      isEditModal={props.isEditModal}
      setIsDeleteConfirm={setIsDeleteConfirm}
      isConfirm={isConfirm}
      onDelete={onDelete}
      onConfirmDelete={onConfirmDelete}
      onClickUpdate={onClickUpdate}
      recorderGroupValidate={recorderGroupValidate}
      recorderGroupValidateMsg={recorderGroupValidateMsg}
      initRecorderGroupValidate={initRecorderGroupValidate}
      userId={auth.user.userId}
    />
  );
}

export default AddGroupsContainer;
