import { useState, useCallback, useRef, useEffect } from "react";
import Input from "components/atoms/input/Input";
import { ModalInput } from "components/atoms/input/modal/ModalInput";
import PhoneInput from "components/atoms/input/phone/PhoneInput";
import SmallPopupTemplate from "components/templates/modal/small/SmallPopupTemplate";
import VerifyPopup from "components/templates/modal/small/verify/VerifyPopup";
import { changeFirstWordToUppercase } from "utils/functions";
import { isEmpty } from "lodash";
import { useAppSelector } from "redux/hooks";
import { ProfileAccountInfo } from "api/interfaces/accountInterface.interface";
import { useMutation } from "react-query";
import { postVerify, postVerifyEmail } from "api/authAPI";
import { ValidateWord } from "components/atoms/text/Text";
import { Text } from "components/atoms/text/Text";
import { postChangeProfile } from "api/userAPI";
import styled from "styled-components";
import { COLORS } from "styles/colors";
import { calcRem } from "styles/theme";
import { FLEX } from "styles/flex";
import InfoBubble from "components/blocks/bubble/InfoBubble";
import ONLINE_ICON from "assets/icons/online.svg";
import WARNING_ICON from "assets/icons/warning_red.svg";
import {
  checkEmailRegEx,
  checkPasswordNumberRegEx,
  checkPasswordRegEx,
} from "utils/regEx";
import { useIntl } from "react-intl";
import { notify } from "components/atoms/notification/Notification";
import { useNavigate } from "react-router-dom";
import useApiError from "hook/useApiError";
import { CustomError } from "api/interfaces/commonInterface.interface";
import PasswordBubble from "components/blocks/bubble/PasswordBubble";
import { isPhoneNumberValid } from "utils/validHelper";

export type Props = {
  email: string;
  phone: string;
  userId: string;
  modalType: string;
  onModal: (type: string) => void;
};

export default function ChangeModal({
  onModal,
  modalType,
  ...props
}: Props): JSX.Element {
  const navigate = useNavigate();
  const { handleError, handleErrorSubReason } = useApiError();

  const [isVerifyModal, setIsVerifyModal] = useState<boolean>(false);

  const selectedAccount: ProfileAccountInfo = useAppSelector(
    (state) => state.accountSettings
  );
  const intl = useIntl();

  const [verifyCode, setVerifyCode] = useState<string>("");
  const [inputVerifyCode, setInputVerifyCode] = useState<string>("");

  const [inputEmail, setInputEmail] = useState<string>("");
  const [currentPwd, setCurrentPwd] = useState<string>("");
  const [inputPwd, setInputPwd] = useState<string>("");
  const [inputRePwd, setInputRePwd] = useState<string>("");
  const [inputPhone, setInputPhone] = useState<string>("");

  const [isValidated, setIsValidated] = useState<boolean>(false);
  const [isValidatedMsg, setIsValidatedMsg] = useState<string>("");

  const [isPwValidated, setIsPwValidated] = useState({
    current: false,
    new: false,
    confirm: false,
  });

  const [isFocus, setIsFocus] = useState<boolean>(false);
  const passwordRef = useRef<any>(null);

  const onChangeVerifyCode = useCallback(
    (value: string) => {
      setInputVerifyCode(value);
    },
    [inputVerifyCode]
  );

  const title = (modalType: string) => {
    switch (modalType) {
      case "email":
        return "Email Address";
      case "password":
        return "Password";
      case "phoneNumber":
        return "Phone Number";
      default:
        return changeFirstWordToUppercase(modalType);
    }
  };

  useEffect(() => {
    if (document.activeElement === passwordRef.current) {
      if (
        !(inputPwd.length < 8) &&
        checkPasswordRegEx(inputPwd) &&
        checkPasswordNumberRegEx(inputPwd)
      ) {
        let timer = setTimeout(() => {
          setIsFocus(false);
        }, 800);
        return () => {
          clearTimeout(timer);
        };
      } else {
        setIsFocus(true);
      }
    }
  }, [inputPwd]);

  const email = (
    <>
      <ModalInput label="Current Email" noBold>
        <Input disabled defaultValue={props.email} />
      </ModalInput>
      <ModalInput label="New Email" noBold essential>
        <Input
          placeholder="Enter new email"
          maxLength={100}
          onChange={(e) => onChangeUserInfo(1, e.target.value)}
        />
      </ModalInput>
    </>
  );

  const password = (
    <>
      <ModalInput
        label="Current Password"
        noBold
        essential
        isValidated={isPwValidated.current}
        validatedWord={isValidatedMsg}
      >
        <Input
          type="password"
          placeholder="Enter your password"
          maxLength={100}
          value={currentPwd}
          onChange={(e) => onChangeUserInfo(2, e.target.value)}
        />
      </ModalInput>
      <ModalInput
        label="New Password"
        noBold
        essential
        isValidated={isPwValidated.new}
        validatedWord={isValidatedMsg}
      >
        <PasswordInputInner>
          <Input
            id="dw_signup_input_password"
            ref={passwordRef}
            type="password"
            autoComplete="new-password"
            className="input-field"
            placeholder="Password"
            maxLength={100}
            value={inputPwd}
            onChange={(e) => onChangeUserInfo(3, e.target.value)}
            onFocus={() => setIsFocus(true)}
            onBlur={() => setIsFocus(false)}
          />
          {isFocus && (
            <Bubble>
              <PasswordBubble password={inputPwd} />
            </Bubble>
          )}
        </PasswordInputInner>
      </ModalInput>
      <ModalInput
        label="Confirm New Password"
        noBold
        essential
        isValidated={isPwValidated.confirm}
        validatedWord={isValidatedMsg}
      >
        <Input
          type="password"
          placeholder="Re-enter new password"
          maxLength={100}
          onChange={(e) => onChangeUserInfo(4, e.target.value)}
        />
      </ModalInput>
    </>
  );

  const phoneNumber = (
    <>
      <ModalInput label="Current Phone Number" noBold>
        <PhoneInput selected={props.phone} disabled />
      </ModalInput>
      <ModalInput label="New Phone Number" noBold essential>
        <PhoneInput
          placeholder="Enter new phone number"
          onChange={(value) => onChangeUserInfo(5, value)}
        />
      </ModalInput>
    </>
  );

  const mutationVerifyEmail = useMutation(postVerifyEmail, {
    onSuccess: (res: any) => {
      if (res.error === 0) {
        setVerifyCode(res.result.verifiedNumber);
        setIsVerifyModal(!isVerifyModal);
      } else {
        // setIsValidated({ ...isValidated, resend: true });
        // setValidateMsg({
        //   ...validateMsg,
        //   resend: "Email is not exist",
        // });
      }
    },
    onError: () => {
      // setIsValidated({ ...isValidated, resend: true });
      // setValidateMsg({
      //   ...validateMsg,
      //   resend: "Email is not exist",
      // });
    },
  });

  const mutationVerifyPhone = useMutation(postVerify, {
    onSuccess: (res: any) => {
      if (res.error === 0) {
        setVerifyCode(res.result.verifiedNumber);
        setIsVerifyModal(!isVerifyModal);
      } else {
        setIsValidated(true);
        setIsValidatedMsg(modalType + " Change is Failure");
      }
    },
    onError: (err: CustomError) => {
      setIsValidated(true);
      handleError(
        err,
        intl.formatMessage({
          id: "label.profile.notify.phone.inVerify",
          defaultMessage: "Phone number has not been verified",
        })
      );
      setIsValidatedMsg("Please check your phone number");
    },
  });

  const mutationChangeProfile = useMutation(postChangeProfile, {
    onSuccess: (res: any, variables: any) => {
      if (res.error === 0) {
        notify(
          "success",
          intl.formatMessage({
            id: "label.profile.notify.update.success",
            defaultMessage: "Profile updated successfully.",
          })
        );
        if (variables.type === "email") {
          navigate("/login", { replace: true });
        }
        onModal(modalType);
      } else {
        // setIsValidated({ ...isValidated, resend: true });
        // setValidateMsg({
        //   ...validateMsg,
        //   resend: "Email is not exist",
        // });
      }
    },
    onError: (err: CustomError) => {
      setIsValidated(true);

      handleErrorSubReason(
        err,
        intl.formatMessage({
          id: "label.profile.notify.update.fail",
          defaultMessage: "Failed to update profile.",
        })
      );
    },
  });

  // console.log(isValidatedMsg);
  const onClickBtn = useCallback(
    (modalType: string) => {
      setIsValidated(false);
      if (modalType === "email") {
        if (isEmpty(inputEmail)) {
          setIsValidated(true);
          setIsValidatedMsg(
            intl.formatMessage({
              id: "validateMsg.profile.email.empty",
              defaultMessage: "Please enter email.",
            })
          );
          return;
        }
        if (!checkEmailRegEx(inputEmail)) {
          setIsValidated(true);
          setIsValidatedMsg(
            intl.formatMessage({
              id: "validateMsg.profile.email.irregular",
              defaultMessage: "Enter a valid email address.",
            })
          );
          return;
        }
        mutationVerifyEmail.mutate({
          payload: {
            email: inputEmail,
          },
        });
      } else if (modalType === "password") {
        if (isEmpty(currentPwd)) {
          setIsPwValidated({
            ...isPwValidated,
            current: true,
          });
          setIsValidatedMsg(
            intl.formatMessage({
              id: "validateMsg.profile.passwordEmpty",
              defaultMessage: "Please enter current password.",
            })
          );
          return;
        }
        if (isEmpty(inputPwd)) {
          setIsPwValidated({
            ...isPwValidated,
            current: false,
            new: true,
          });
          setIsValidatedMsg(
            intl.formatMessage({
              id: "validateMsg.profile.newPasswordEmpty",
              defaultMessage: "Please enter new password.",
            })
          );
          return;
        }

        if (inputRePwd !== inputPwd) {
          setIsPwValidated({
            ...isPwValidated,
            current: false,
            new: false,
            confirm: true,
          });
          setIsValidatedMsg(
            intl.formatMessage({
              id: "validateMsg.signup.passwordMismatch",
              defaultMessage: "The password you entered does not match.",
            })
          );
          return;
        }
        if (currentPwd === inputPwd) {
          setIsPwValidated({
            ...isPwValidated,
            current: false,
            new: false,
            confirm: true,
          });
          setIsValidatedMsg(
            intl.formatMessage({
              id: "validateMsg.profile.passwordSame",
              defaultMessage:
                "New password cannot be same as the one used previously.",
            })
          );
          return;
        }

        if (inputPwd.length < 8) {
          setIsPwValidated({
            ...isPwValidated,
            current: false,
            new: true,
          });
          setIsValidatedMsg(
            intl.formatMessage({
              id: "validateMsg.signup.password.short",
              defaultMessage: "Password must be at least 8 characters.",
            })
          );
          return;
        }

        if (!checkPasswordRegEx(inputPwd)) {
          setIsPwValidated({
            ...isPwValidated,
            current: false,
            new: true,
          });
          setIsValidatedMsg(
            intl.formatMessage({
              id: "validateMsg.signup.password.NotContainUppercase",
              defaultMessage:
                "Password must contain both lowercase and uppercase letters.",
            })
          );
          return;
        }

        if (!checkPasswordNumberRegEx(inputPwd)) {
          setIsPwValidated({
            ...isPwValidated,
            current: false,
            new: true,
          });
          setIsValidatedMsg(
            intl.formatMessage({
              id: "validateMsg.signup.password.NotContainSpecial",
              defaultMessage:
                "Password must contain one or more numeric and special characters.",
            })
          );
          return;
        }

        // mutationVerifyEmail.mutate({
        //   payload: {
        //     email: props.email,
        //   },
        // });
        mutationChangeProfile.mutate({
          userId: props.userId,
          type: modalType,
          payload: {
            currentPassword: currentPwd,
            newPassword: inputPwd,
          },
        });
      } else {
        if (isEmpty(inputPhone)) {
          setIsValidated(true);
          setIsValidatedMsg(
            intl.formatMessage({
              id: "validateMsg.profile.phone.empty",
              defaultMessage: "Please enter phone number.",
            })
          );
          return;
        }

        if (props.phone === inputPhone) {
          setIsValidated(true);
          setIsValidatedMsg(
            intl.formatMessage({
              id: "validateMsg.profile.phone.same",
              defaultMessage:
                "New phone number cannot be same as the one used previously.",
            })
          );
          return;
        }
        if (isPhoneNumberValid(inputPhone)) {
          mutationVerifyPhone.mutate({
            payload: {
              phoneNumber: inputPhone,
            },
          });
        } else {
          setIsValidated(true);
          setIsValidatedMsg(
            intl.formatMessage({
              id: "validateMsg.profile.phone.validate",
              defaultMessage: "Please try again with the correct format.",
            })
          );
          return;
        }
      }
    },
    [
      currentPwd,
      inputEmail,
      inputPhone,
      inputPwd,
      inputRePwd,
      intl,
      isPwValidated,
      mutationChangeProfile,
      mutationVerifyEmail,
      mutationVerifyPhone,
      props.phone,
      props.userId,
    ]
  );

  const onVerify = useCallback(
    (modalType: string) => {
      if (verifyCode === inputVerifyCode) {
        if (modalType === "password") {
          mutationChangeProfile.mutate({
            userId: props.userId,
            type: modalType,
            payload: {
              currentPassword: currentPwd,
              newPassword: inputPwd,
            },
          });
        } else if (modalType === "phoneNumber") {
          mutationChangeProfile.mutate({
            userId: props.userId,
            type: modalType,
            payload: {
              phoneNumber: inputPhone,
            },
          });
        } else if (modalType === "email") {
          mutationChangeProfile.mutate({
            userId: props.userId,
            type: modalType,
            payload: {
              email: inputEmail,
            },
          });
        }
      } else {
        setIsValidated(true);
        setIsValidatedMsg(
          intl.formatMessage({
            id: "validateMsg.signup.verifyNumber",
            defaultMessage: "The code you entered does not match.",
          })
        );
        setIsVerifyModal(false);
      }
    },
    [
      currentPwd,
      inputEmail,
      inputPhone,
      inputPwd,
      inputVerifyCode,
      mutationChangeProfile,
      props.userId,
      verifyCode,
    ]
  );

  const onChangeUserInfo = useCallback((type: number, value: string) => {
    switch (type) {
      case 1:
        setInputEmail(value);
        break;
      case 2:
        setCurrentPwd(value);
        break;
      case 3:
        setInputPwd(value);
        break;
      case 4:
        setInputRePwd(value);
        break;
      case 5:
        setInputPhone(value);
        break;
      default:
        break;
    }
  }, []);

  return (
    <>
      <SmallPopupTemplate
        title={`Change Your ${title(modalType)}`}
        onModal={() => onModal(modalType)}
        onClick={() => onClickBtn(modalType)}
        cancel
      >
        {modalType === "email" && email}
        {modalType === "password" && password}
        {modalType === "phoneNumber" && phoneNumber}
        {isValidatedMsg && modalType !== "password" && (
          <ValidateWord>{isValidatedMsg}</ValidateWord>
        )}
      </SmallPopupTemplate>
      {isVerifyModal && (
        <VerifyPopup
          verifyCode={verifyCode}
          modalType={modalType}
          target={modalType === "phoneNumber" ? inputPhone : inputEmail}
          onModal={() => onModal(modalType)}
          onVerify={() => onVerify(modalType)}
          onChange={onChangeVerifyCode}
        />
      )}
    </>
  );
}

export const commonLoginHeight = "40px";

export const PasswordInputInner = styled.div`
  position: relative;
  width: 100%;

  > input {
    color: ${COLORS.BLACK};
    font-size: ${calcRem(14)};
  }
`;

export const Bubble = styled.p`
  position: absolute;
  /* width: 60%; */
  margin-top: 10px;

  ul {
    width: 100%;
    ${FLEX.FlexStartStart};
    flex-direction: column;
    gap: 8px;
    padding: 10px 0;
  }
`;

export const PasswordText = styled.li`
  ${FLEX.FlexCenterCenter};
  gap: 8px;
  font-size: ${calcRem(15)};

  > img {
    width: 15px;
    height: 15px;
  }
`;

export const PasswordTitle = styled.div`
  ${FLEX.FlexCenterCenter};
  gap: 10px;
  padding-bottom: 8px;

  > .title {
    margin-bottom: 0;
  }
`;

export const VerifyWrapper = styled.div`
  padding-bottom: 10px;
  width: 80%;
  margin: 0 auto;
  ${FLEX.FlexCenterCenter};
  flex-direction: column;
`;
