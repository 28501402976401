import { TableItemListStyled } from "./TableItemStyled";
import { FiMenu } from "react-icons/fi";
import Inventory from "./list/Inventory";
import { Layout } from "react-grid-layout";

interface Props {
  layout: Layout;
  chartLayout: Layout[];
  onDeleteWidget: (key: TableItemName) => void;
  onLayoutChange: (layout: Layout[]) => void;
}

export type TableItemName =
  | "installation"
  | "revenue"
  | "recorder"
  | "alertFeed"
  | "alertList"
  | "alertHistory"
  | "cvvDetection"
  | "inventory"
  | "storage"
  | "loginHistory";

export const tableItemList: { [key in TableItemName]: string } = {
  recorder: "Recorders",
  alertFeed: "Alert Feed",
  alertList: "Alert List",
  alertHistory: "Alert History",
  cvvDetection: "CVV Detections",
  inventory: "Inventory",
  loginHistory: "Login History",
  storage: "Storage Use",
  revenue: "Revenue",
  installation: "Device Installations",
} as const;

export const isDisabledDashboardTableList = [
  "revenue",
  "installation",
  // "storage",
];
export default function TableItemList(props: Props): JSX.Element {
  return (
    <TableItemListStyled.Container>
      {Object.entries(tableItemList)
        .filter((fil) => !props.chartLayout.map((ch) => ch.i).includes(fil[0]))
        .map((item) => {
          return (
            <TableItemListStyled.Item
              key={`layout-item-${item[0]}`}
              isDisabled={isDisabledDashboardTableList.includes(item[0])}
              onClick={() => {
                if (isDisabledDashboardTableList.includes(item[0])) {
                  return;
                }
                let tempLayouts: Layout[] = props.chartLayout.map((lay) => {
                  if (lay.i === props.layout.i) {
                    return {
                      ...lay,
                      i: item[0],
                    };
                  } else return lay;
                });
                props.onLayoutChange(tempLayouts);
              }}
            >
              {item[1]}
            </TableItemListStyled.Item>
          );
        })}
      <TableItemListStyled.Item
        onClick={() => {
          props.onDeleteWidget(props.layout.i as TableItemName);
        }}
      >
        Remove Widget
      </TableItemListStyled.Item>
    </TableItemListStyled.Container>
  );
}
