import * as OsDetect from "react-device-detect";
import customProtocolCheck from "custom-protocol-check";
import { isEmpty } from "lodash";
import { getDWStatus } from "api/recorderAPI";
import { checkStatus, openNewWindow } from "./functions";
import { MergedRecorder } from "api/interfaces/recorderInterface.interface";

export const setupAppLauncher = (
  type: string,
  cloudSystemId: string,
  accessToken: string,
  ip?: string,
  port?: number,
  mac?: string,
  p2p?: number,
  camMax?: number
) => {
  //TODO firmware version에 따른 로직 추가 필요.
  let scheme = null;
  let query = null;
  let androidPackageName = null;
  let iosMarketUrl: Location;
  let brand = "";

  //"dw-c3cms://c3cms.digital-watchdog.com/client?ip=P2P-A50F81F4B6BA-201FF5&mac=a8:dc:5a:20:1f:f5&p2p=1&cammax=12&auth=YWRtaW46ZElnSXRBTHdBVENIZE9HOkFkbWluMTIzNCE="

  let cmsLink = `dw-vms://dwspectrum.digital-watchdog.com/client/${cloudSystemId}?code=${accessToken}`;
  if (type === "Spectrum" && isEmpty(cloudSystemId)) {
    cmsLink = `dw-vms://dwspectrum.digital-watchdog.com/client/${ip}:${port}?auth=${accessToken}`;
  }
  if (type !== "Spectrum") {
    cmsLink = `dw-c3cms://c3cms.digital-watchdog.com/client?ip=${ip}&mac=${mac}&p2p=${p2p}&cammax=${camMax}&auth=${accessToken}`;
  }

  if (cmsLink.indexOf("dw-vms://") !== -1) {
    brand = "dwspectrum";
    query = cmsLink.split("dw-vms://")[1];
  } else if (cmsLink.indexOf("dw-c3cms://") !== -1) {
    brand = "focus";
    query = cmsLink.split("dw-c3cms://")[1];
  } else {
    return false;
  }
  if (type.toUpperCase() === "SPECTRUM") {
    scheme = "dw-vms";
    androidPackageName = "com.digitalwatchdog.dwmobile";
    iosMarketUrl =
      "itms-apps://apps.apple.com/us/app/id1090087818" as unknown as Location;
  } else if (brand === "focus") {
    scheme = "dw-c3cms";
    androidPackageName = "com.dw.mobile.VMAXIPPlus";
    iosMarketUrl =
      "itms-apps://apps.apple.com/us/app/id1079281090" as unknown as Location;
  } else {
    return false;
  }

  let ua = navigator.userAgent.toLowerCase();
  let url = scheme + "://" + query;

  //alert('userAgent 값은?');
  //alert(ua);

  const isIOS =
    navigator.userAgent.match(/iPhone|iPad|iPod/i) === null &&
    navigator.maxTouchPoints !== 5
      ? false
      : true;

  if (ua.search("android") > -1) {
    //alert('접속장비는 android 입니다.');
    document.location =
      "intent://" +
      query +
      "#Intent;scheme=" +
      scheme +
      ";package=" +
      androidPackageName +
      ";end";
    //} else if ((ua.search("iphone") > -1) || (ua.search("ipod") > -1) || (ua.search("ipad") > -1)) {
  } else if (isIOS) {
    //alert('접속장비는 iphone 또는 ipad 또는 ipod 입니다.');
    var prevType = "";

    window.addEventListener("blur focus", (e) => {
      // prevType = this.data("prevType");

      if (prevType !== e.type) {
        switch (e.type) {
          // 화면 밖으로 나갔을때
          case "blur":
            break;
          // 화면 안으로 들어왔을때
          case "focus":
            break;
        }
      }
      // $(this).data("prevType", e.type);
    });
    // $(window).on("blur focus", function(e){
    //     prevType = $(this).data("prevType");

    //     if (prevType != e.type) {
    //         switch (e.type) {
    //             // 화면 밖으로 나갔을때
    //             case "blur":
    //                 break;
    //             // 화면 안으로 들어왔을때
    //             case "focus":
    //                 break;
    //         }
    //     }
    //     $(this).data("prevType", e.type);
    // });

    setTimeout(function () {
      if (prevType === undefined || prevType === "focus") {
        window.location = iosMarketUrl;
      }
    }, 2000);

    window.location = url as unknown as Location;
  } else {
    //PC
    //alert('접속장비는 PC 입니다.');
    let fallback = "";
    var OsNo = navigator.userAgent.toLowerCase();
    if (scheme === "dw-vms") {
      if (/win/.test(OsNo)) {
        fallback = "https://dwspectrum.digital-watchdog.com/download/windows";
      } else if (/linux/.test(OsNo)) {
        fallback = "https://dwspectrum.digital-watchdog.com/download/linux";
      } else if (/x11/.test(OsNo)) {
        fallback = "https://dwspectrum.digital-watchdog.com/download/linux";
      } else if (/mac/.test(OsNo)) {
        fallback = "https://dwspectrum.digital-watchdog.com/download/macos";
      } else {
        fallback = "https://dwspectrum.digital-watchdog.com/download/windows";
      }
    } else if (scheme === "dw-c3cms") {
      if (/win/.test(OsNo) || /mac/.test(OsNo)) {
        fallback = "https://digital-watchdog.com/productdetail/C3_CMS/";
      } else if (/x11/.test(OsNo) || /mac/.test(OsNo)) {
        fallback = "";
      } else {
        fallback = "https://digital-watchdog.com/productdetail/C3_CMS/";
      }
    }
    gotoCMS_DeskTOP_NewTry(url, fallback, scheme);
  }
};

function gotoCMS_DeskTOP_NewTry(url: string, fallback: string, scheme: string) {
  //var windowReference=window.open();
  customProtocolCheck(
    url,
    () => {
      window.open(fallback, "_blank", "noopener, noreferrer");
    },
    () => {
      console.log("Custom protocol found and opened the file successfully.");
    },
    10000,
    () => {
      console.log("Unsupported");
    }
  );
  // window.protocolCheck(url,
  //     function () {
  //         if(fallback !== '') {
  //             //console.log(fallback);
  //             //window.open(fallback,'_blank','noopener');
  //             //windowReference.location = fallback;
  //             document.location = fallback;
  //         }else{
  //             alert('Sorry, Compatible APP is not prepared for Linux/Unix OS.');
  //         }
  //     });
  //event.preventDefault ? event.preventDefault() : event.returnValue = false;
}

export const setupWebLauncher = (
  type: string,
  accessToken: string,
  cloudSystemId?: string,
  linkWindow?: Window | null,
  brand?:string
) => {
  let global_userAgent = "Web";
  let windowReference: Window | null = null;
  let targetUrl: string = "";
  const isIOS =
    navigator.userAgent.match(/iPhone|iPad|iPod/i) === null &&
    navigator.maxTouchPoints !== 5
      ? false
      : true;
  if (global_userAgent === "Web" && isIOS) {
    if (navigator.userAgent.match(/safari/i) === null)
      global_userAgent = "WebViewIOS";
    else global_userAgent = "Web";
  }
  if (global_userAgent === "Web" || global_userAgent === "") {
    if (type.toUpperCase() === "SPECTRUM") {
      if (linkWindow !== undefined && linkWindow !== null) {
        linkWindow.location.href = `https://dwspectrum.digital-watchdog.com/systems/${cloudSystemId}?code=${accessToken}`;
      }
      //targetUrl = `https://dwspectrum.digital-watchdog.com/systems/${cloudSystemId}?code=${accessToken}`;
      //openNewWindow(targetUrl);
      //windowReference = window.open("");
    } else {
      if (linkWindow !== undefined && linkWindow !== null) {
        if(process.env.REACT_APP_DATADOG_ENV !== "prod"){
          linkWindow.location.href = `https://testpf.mydw.cloud/mydw?token=${accessToken}`;
        }else{
          linkWindow.location.href = `https://pf.mydw.cloud/mydw?token=${accessToken}`;
        }
      }
      // targetUrl = `https://pf.mydw.cloud/mydw?token=${accessToken}`;
      // openNewWindow(targetUrl);
      //windowReference = window.open("");
    }
  } else if (global_userAgent === "WebViewIOS") {
    //$(".dark-board-list-table").html("아이폰 APP 함수 - clickedRecorderWeb - 호출");
    (window as any).webkit.messageHandlers.clickedRecorderWeb.postMessage("");
  } else if (global_userAgent === "WebViewANDROID") {
    //alert("Android WebView is not implemented.");
    return;
  }

  // getDWStatus().then((data)=>{
  //   if (global_userAgent === "Web" || global_userAgent === "") {
  //     if(windowReference)
  //       windowReference.location.href = targetUrl;
  //   }else if(global_userAgent === 'WebViewIOS'){
  //       //$(".dark-board-list-table").html("아이폰 APP 함수 - openRecorderWeb - 호출");
  //       (window as any).webkit.messageHandlers.openRecorderWeb.postMessage('{"url":"'+targetUrl+'"}');
  //   }else if(global_userAgent === 'WebViewANDROID'){

  //   }
  // });
};

export const recorderTitleString = (
  recorderName: string,
  mergedSystemName: string,
  type?: string
) => {
  const title =
    type !== undefined
      ? type !== "Spectrum"
        ? mergedSystemName
        : `${mergedSystemName}>${recorderName}`
      : mergedSystemName === recorderName
      ? recorderName
      : `${mergedSystemName}>${recorderName}`;
  return title;
};

export const changeReponseRecorderListFormat = (list: MergedRecorder[]) => {
  return list.map((recorder: MergedRecorder) => {
    // console.log(recorder);
    return {
      id: recorder.mergedSystemId,
      recordId:
        recorder.recorderCount === 1
          ? recorder.recorders[0]?.recorderId
          : recorder.mergedSystemId,
      mergedSystemId: recorder.mergedSystemId,
      type: recorder.type,
      title: recorder.mergedSystemName,
      status:
        recorder.recorderCount !== undefined &&
        recorder.onlineStatusCount !== undefined
          ? checkStatus(recorder.recorderCount, recorder.onlineStatusCount)
          : "offline",
      createBy: recorder.createBy,
      createByEmail: recorder.createByEmail,
      //host: recorder.installer,
      onlineStatusCount: recorder.onlineStatusCount,
      recorderCount: recorder.recorderCount,
      cloudSystemId: recorder.cloudSystemId,
      version: recorder.firmwareVersion,
      cameras: recorder.cameraCount,
      enableEdit: recorder.enableEdit,
      licenses: {
        count: 0,
        status: "PREPARING",
      },
      row: recorder,
    };
  });
};

export const deRegistrationSupportCheck = (version:string) => {
  let isSupport = true;
  
  const supportVersion = "0.1.5.6".split('.');
  const compareVersion = version.split('.');

  for(let i =0; i < 4; i+=1) {
    const a = parseInt(supportVersion[i], 10) ;
    const b = compareVersion[i] ? parseInt(compareVersion[i], 10) : 0 ;
    if(i === 0 ){
      if(b >= 2 ){
        break;
      }
    }else{
      if(b < a){
        isSupport = false;
        break;
      }
    }    
  }

  return isSupport;
}


// export const videoUploadStatusToPercent = (status: VideoUploadStatus) => {
//   let percent: number = 0;
//   switch (status) {
//     case "presigned":
//       percent = 10;
//       break;
//     case "uploading":
//       percent = 50;
//       break;
//     case "encoding":
//       percent = 80;
//       break;
//     case "vtt":
//       percent = 90;
//       break;
//     case "success":
//       percent = 100;
//       break;
//     case "pending":
//       percent = 100;
//       break;
//     default:
//       return 0;
//   }
//   return percent;
// };

// function gotoSetup(recorderId:string, accessToken:string){
//     // alert('global_userAgent 값은?');
//     // alert(global_userAgent);
//     let windowReference:any;
//     //$(".dark-board-list-table").html(global_userAgent);
//     const isIOS = navigator.userAgent.match(/iPhone|iPad|iPod/i) === null && navigator.maxTouchPoints !== 5 ? false : true;
//     let detect_userAgent = checkUserAgent();
//     if (global_userAgent == 'Web' && isIOS) {
//         if (navigator.userAgent.match(/safari/i) === null)
//             global_userAgent = "WebViewIOS";
//         else
//             global_userAgent = "Web";
//     }
//     if(global_userAgent == 'Web' || global_userAgent == '') {
//         windowReference = window.open('/html/loading_webLink.html', '_newWin' + site_id);
//     }else if(global_userAgent == 'WebViewIOS'){
//         //$(".dark-board-list-table").html("아이폰 APP 함수 - clickedRecorderWeb - 호출");
//         window.webkit.messageHandlers.clickedRecorderWeb.postMessage('');
//     }else if(global_userAgent == 'WebViewANDROID'){
//         alert('Android WebView is not implemented.');
//         return;
//     }

//     $.ajax({
//         type: 'GET',
//         url: '/sites_api/getSiteSetupUrl?id='+site_id,
//         dataType: "json",
//         success: function (data) {
//             if(data.resultCode == 'Success'){
//                 if(global_userAgent == 'Web' || global_userAgent == '') {
//                     windowReference.location = data.url;
//                 }else if(global_userAgent == 'WebViewIOS'){
//                     //$(".dark-board-list-table").html("아이폰 APP 함수 - openRecorderWeb - 호출");
//                     window.webkit.messageHandlers.openRecorderWeb.postMessage('{"url":"'+data.url+'"}');
//                 }else if(global_userAgent == 'WebViewANDROID'){

//                 }
//                 //window.open(data.url, '_newWin'+site_id);
//             }else{
//                 //alert(data.errorText);
//             }
//         },
//         error: function () {
//             //alert('Unknown Error.');
//         }
//     });
//     //return false;
// }

// function gotoCMS(site_id) {
//     $.ajax({
//         type: 'GET',
//         url: '/sites_api/getCmsLink?id='+site_id,
//         dataType: "json",
//         success: function (data) {
//             if(data.resultCode == 'Success'){
//                 gotoSetupOrAPP(data.url);
//             }else{
//                 alert(data.errorText);
//             }
//         },
//         error: function () {
//             alert('Unknown Error. Please Retry.');
//         }
//     });
// }

// function gotoSetupOrAPP(cmsLink){

//     let scheme = null;
//     let query = null;
//     let androidPackageName = null;
//     let iosMarketUrl = null;
//     let brand = '';

//     if(cmsLink.indexOf('dw-vms://') !== -1) {
//         brand = 'dwspectrum';
//         query = cmsLink.split('dw-vms://')[1];
//     }else if(cmsLink.indexOf('dw-c3cms://') !== -1) {
//         brand = 'focus';
//         query = cmsLink.split('dw-c3cms://')[1];
//     }else {
//         return false;
//     }
//     if(brand === 'dwspectrum') {
//         scheme = "dw-vms";
//         androidPackageName = "com.digitalwatchdog.dwmobile";
//         iosMarketUrl = "itms-apps://apps.apple.com/us/app/id1090087818";
//     }else if(brand === 'focus'){
//         scheme = "dw-c3cms";
//         androidPackageName = "com.dw.mobile.VMAXIPPlus";
//         iosMarketUrl = "itms-apps://apps.apple.com/us/app/id1079281090";
//     }

//     let ua = navigator.userAgent.toLowerCase();
//     let url = scheme + "://" + query;

//     //alert('userAgent 값은?');
//     //alert(ua);

//     const isIOS = navigator.userAgent.match(/iPhone|iPad|iPod/i) === null && navigator.maxTouchPoints != 5 ? false : true;

//     if (ua.search("android") > -1) {
//         //alert('접속장비는 android 입니다.');
//         document.location = "intent://" + query + "#Intent;scheme="
//             + scheme + ";package=" + androidPackageName + ";end";
//         //} else if ((ua.search("iphone") > -1) || (ua.search("ipod") > -1) || (ua.search("ipad") > -1)) {
//     } else if (isIOS) {
//         //alert('접속장비는 iphone 또는 ipad 또는 ipod 입니다.');
//         var prevType = "";
//         $(window).on("blur focus", function(e){
//             prevType = $(this).data("prevType");

//             if (prevType != e.type) {
//                 switch (e.type) {
//                     // 화면 밖으로 나갔을때
//                     case "blur":
//                         break;
//                     // 화면 안으로 들어왔을때
//                     case "focus":
//                         break;
//                 }
//             }
//             $(this).data("prevType", e.type);
//         });

//         setTimeout(function (){
//             if (prevType == undefined || prevType == 'focus') {
//                 window.location = iosMarketUrl;
//             }
//         },2000);
//         window.location = url;

//     } else {
//         //PC
//         //alert('접속장비는 PC 입니다.');
//         let fallback = '';
//         var OsNo = navigator.userAgent.toLowerCase();
//         jQuery.os = {
//             Linux: /linux/.test(OsNo),
//             Unix: /x11/.test(OsNo),
//             Mac: /mac/.test(OsNo),
//             Windows: /win/.test(OsNo)
//         }
//         if (scheme === "dw-vms") {
//             if ($.os.Windows) {
//                 fallback = 'https://dwspectrum.digital-watchdog.com/download/windows';
//             } else if ($.os.Linux) {
//                 fallback = 'https://dwspectrum.digital-watchdog.com/download/linux';
//             } else if ($.os.Unix) {
//                 fallback = 'https://dwspectrum.digital-watchdog.com/download/linux';
//             } else if ($.os.Mac) {
//                 fallback = 'https://dwspectrum.digital-watchdog.com/download/macos';
//             } else {
//                 fallback = 'https://dwspectrum.digital-watchdog.com/download/windows';
//             }
//         } else if (scheme === "dw-c3cms"){
//             if ($.os.Windows || $.os.Mac) {
//                 fallback = 'https://digital-watchdog.com/productdetail/C3_CMS/';
//             } else if ($.os.Unix || $.os.Mac){
//                 fallback = '';
//             } else {
//                 fallback = 'https://digital-watchdog.com/productdetail/C3_CMS/';
//             }
//         }
//         gotoCMS_DeskTOP_NewTry(url, fallback, scheme);
//     }

// }

// function gotoCMS_DeskTOP_NewTry(url, fallback, scheme) {
//     //var windowReference=window.open();
//     window.protocolCheck(url,
//         function () {
//             if(fallback !== '') {
//                 //console.log(fallback);
//                 //window.open(fallback,'_blank','noopener');
//                 //windowReference.location = fallback;
//                 document.location = fallback;
//             }else{
//                 alert('Sorry, Compatible APP is not prepared for Linux/Unix OS.');
//             }
//         });
//     //event.preventDefault ? event.preventDefault() : event.returnValue = false;

// }
