import { useState } from "react";
import TimelapsePresenter from "./TimelapsePresenter";

export default function TimelapseContainer(): JSX.Element {
  const [isModal, setIsModal] = useState(false);

  const onChangeModal = () => {
    setIsModal(!isModal);
  };

  return <TimelapsePresenter onChangeModal={onChangeModal} isModal={isModal} />;
}
