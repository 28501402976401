import { Table } from "components/atoms/table/Table";
import { SortOrder, TableColumn } from "react-data-table-component";
import { TableTemplate } from "components/templates/default/table/TableTemplate";
import AddUser from "./add/user/AddUser";
import EditUser from "./add/user/EditUser";
import AddUserGroup from "./add/group";
import Status, { StatusType } from "components/blocks/status/Status";
import { useIntl } from "react-intl";
import { isEqual } from "lodash";
import { useLocation } from "react-router-dom";
import { Group, UserDto } from "api/interfaces/userInterface.interface";
import { ClickSearch } from "components/atoms/input/search/SearchInput";
import { useEffect } from "react";
import { useAppDispatch } from "redux/hooks";
import { setMenu } from "redux/reducers/menu/menuSlice";
import { BasePagination } from "api/interfaces/commonInterface.interface";
import SwitchAccount from "pages/account/switch/SwitchAccount";

export interface DataRow {
  id: string;
  userCnt?: number;
  email?: string;
  name: string;
  description: string;
  groupName?: string[];
  enableDelete?: boolean;
  enableEdit?: boolean;
  isActivate?: boolean;
  createAt: string;
  host?: string;
  hostValidation?: boolean;
  raw: UserDto | Group;
  invitedDate: string;
  invitedBy: string;
  invitedEmail: string;
}

type Props = {
  menuType: string;
  isAddModal: boolean;
  isEditModal: boolean;
  columns: TableColumn<DataRow>[];
  data: Array<DataRow>;
  onModal: (type?: string) => void;
  status: StatusType;
  onClickSave: (data: any, update?: boolean) => void;
  selectedUser?: UserDto;
  onChangePage: (page: number, totalRows: number) => void;
  onRowClicked: (row: any) => void;
  selectedEdit: UserDto | Group | undefined;
  onClickSearch: ClickSearch;
  handleSort: (
    selectedColumn: TableColumn<any>,
    sortDirection: SortOrder
  ) => void;
  isRowClickEditable: boolean;
  initialPending: boolean;
  paginationInfo: BasePagination;
  isSwitchModal: boolean;
  onChangeSwitch?: () => void;
  isUpdateLoading: boolean;
  isCreateLoading: boolean;
};

export function UsersPresenter(props: Props): JSX.Element {
  const location = useLocation();
  const dispatch = useAppDispatch();
  const intl = useIntl();
  const centerChildren = (
    <>
      {!location.pathname.includes("roles") && <Status status={props.status} />}
    </>
  );
  let buttonLabel = "Add User";

  if (isEqual(props.menuType, "group")) {
    buttonLabel = intl.formatMessage({
      id: "group.button.add",
      defaultMessage: "Add Group",
    });
  } else if (isEqual(props.menuType, "role")) {
    buttonLabel = intl.formatMessage({
      id: "role.button.add",
      defaultMessage: "Add Role",
    });
  }

  useEffect(() => {
    dispatch(setMenu("Users"));
  }, []);

  return (
    <>
      <TableTemplate
        onClickBtn={() => props.onModal("add")}
        buttonLabel={buttonLabel}
        onClickSearch={props.onClickSearch}
        centerChildren={centerChildren}
        requiredPermissions={"manageUsersAndGroups"}
        keyword={props.paginationInfo.keyword}
      >
        <Table
          columns={props.columns}
          data={props.data}
          paginationTotalRows={props.status.totalValue as number}
          onChangePage={props.onChangePage}
          handleSort={props.handleSort}
          onRowClicked={props.onRowClicked}
          defaultSortFieldId={"name"}
          defaultSortAsc
          isRowClickEditable={props.isRowClickEditable}
          progressPending={props.initialPending}
        />
      </TableTemplate>
      {/* Add user */}
      {isEqual(props.menuType, "user") && props.isAddModal && (
        <AddUser
          onModal={() => props.onModal("add")}
          onClickSave={props.onClickSave}
          selectedUser={props.selectedUser}
          isLoading={props.isCreateLoading}
        />
      )}
      {isEqual(props.menuType, "user") && props.isEditModal && (
        <EditUser
          onModal={props.onModal}
          onClickSave={props.onClickSave}
          selectedUser={props.selectedUser as UserDto}
          isLoading={props.isUpdateLoading}
        />
      )}
      {/* Add user group */}
      {/* <AddUserGroup onModal={props.onModal} /> */}
      {isEqual(props.menuType, "group") && props.isAddModal && (
        <AddUserGroup
          isEdit={false}
          onModal={() => props.onModal("add")}
          onClickSave={props.onClickSave}
        />
      )}
      {isEqual(props.menuType, "group") && props.isEditModal && (
        <AddUserGroup
          isEdit={true}
          selectedEdit={props.selectedEdit as Group}
          onModal={() => props.onModal("edit")}
          onClickSave={props.onClickSave}
        />
      )}
      {/* Add role */}
      {/* <AddRole onModal={props.onModal} /> */}
      {/* {isEqual(props.menuType, "role") && props.isAddModal && (
        <AddRole
          isEdit={false}
          onModal={() => props.onModal("add")}
          onClickSave={props.onClickSave}
        />
      )}
      {isEqual(props.menuType, "role") && props.isEditModal && (
        <AddRole
          isEdit={true}
          onModal={() => props.onModal("edit")}
          onClickSave={props.onClickSave}
        />
      )} */}
      {/* Deactivate --- delete 데이터할 때 warning modal */}
      {/* <Deactivate onModal={props.onModal} type="role" /> */}
      {props.isSwitchModal && (
        <SwitchAccount
          isForceSwitch={true}
          onChangeSwitch={() => {
            if (props.onChangeSwitch) {
              props.onChangeSwitch();
            }
          }}
        />
      )}
    </>
  );
}
