import * as s from "../VideoShareStyled";
import { TableTemplate } from "components/templates/default/table/TableTemplate";

import SearchInput, {
  ClickSearch,
} from "components/atoms/input/search/SearchInput";
import StorageLabel, {
  StorageProps,
} from "components/blocks/storage/StorageLabel";
import { useEffect, useRef, useState } from "react";
import { IoChevronBackOutline, IoChevronForward } from "react-icons/io5";
import { ReactComponent as VIDEO_SHARE_ICON } from "assets/icons/videoshare_white.svg";
import * as mediaQuery from "components/MediaQuery";
import VideoSharePoster from "./VideoSharePoster";
import { isEmpty } from "lodash";
import {
  VideoBasePagination,
  VideoShareFilter,
  VideoShareResult,
} from "api/interfaces/videoshareInterface.interface";
import { ProfileAccountInfo } from "api/interfaces/accountInterface.interface";
import Spinner from "components/atoms/loader/Spinner";

export type Props = {
  tagFilters: string[];
  videoList: VideoShareResult[];
  videoTotal: number;
  isStatusFilter: VideoShareFilter;
  onChangeStatusFilter: (value: string) => void;
  listQueryInfo: VideoBasePagination;
  onClickSearch: ClickSearch;
  selectedTag: string[];
  onClickTag: (item: string, event?: React.MouseEvent) => void;
  isSharePopup: string;
  onClickShare: (videoId: string, isOpen: boolean) => void;
  storageUsage: StorageProps;
  selectedAccount: ProfileAccountInfo;
  status: string;
};

export default function VideoSharePresenter(props: Props): JSX.Element {
  const horizontalScrollRef = useRef<any>(null);
  const centerChildren = (
    <>
      <mediaQuery.Default>
        <s.SearchWrapper>
          <SearchInput
            onClickSearch={props.onClickSearch}
            keyword={props.listQueryInfo.keyword}
          />
        </s.SearchWrapper>
      </mediaQuery.Default>

      <mediaQuery.Mobile>
        <div className="storage-label-mobile">
          <StorageLabel
            totalStorage={props.storageUsage.totalStorage}
            usedStorage={props.storageUsage.usedStorage}
          />
        </div>
      </mediaQuery.Mobile>
    </>
  );
  const sub = (
    <>
      <mediaQuery.Default>
        <div className="storage-label-default">
          <StorageLabel
            totalStorage={props.storageUsage.totalStorage}
            usedStorage={props.storageUsage.usedStorage}
          />
        </div>
      </mediaQuery.Default>

      <mediaQuery.Mobile>
        <SearchInput onClickSearch={props.onClickSearch} />
      </mediaQuery.Mobile>
    </>
  );

  const handleNextButtonClick = (nextType: "prev" | "next") => {
    if (nextType === "prev") {
      horizontalScrollRef.current.scrollTo({
        left:
          horizontalScrollRef.current.scrollLeft -
          horizontalScrollRef.current.offsetWidth,
        behavior: "smooth",
      });
    } else {
      horizontalScrollRef.current.scrollTo({
        left:
          horizontalScrollRef.current.scrollLeft +
          horizontalScrollRef.current.offsetWidth,
        behavior: "smooth",
      });
    }
  };

  const [isOverflow, setIsOverflow] = useState<boolean>(false);

  useEffect(() => {
    if (!horizontalScrollRef.current) return;

    if (
      horizontalScrollRef.current.scrollLeft <
      horizontalScrollRef.current.offsetWidth
    ) {
      setIsOverflow(true);
    }
  }, [horizontalScrollRef]);

  const titleComponent = (
    <mediaQuery.Default>
      <s.TitleWrapper>
        <s.TitleComponent>
          <p className="icon">
            <VIDEO_SHARE_ICON />
          </p>
          Video Share
        </s.TitleComponent>
      </s.TitleWrapper>
    </mediaQuery.Default>
  );

  return (
    <>
      <TableTemplate
        titleComponent={titleComponent}
        centerChildren={centerChildren}
        noSearch
        buttons={sub}
      >
        <s.HeaderContainer>
          <s.RightFilter>
            <s.LeftScrollButton onClick={() => handleNextButtonClick("prev")}>
              <IoChevronBackOutline />
            </s.LeftScrollButton>
            <s.RightScrollButton onClick={() => handleNextButtonClick("next")}>
              <IoChevronForward />
            </s.RightScrollButton>
            <s.RightScroll ref={horizontalScrollRef}>
              <s.RightItem
                key={"video_tag_all"}
                isFilter={
                  props.selectedTag.includes("All") ||
                  props.selectedTag.length === 0
                }
                onClick={(e) => {
                  props.onClickTag("All");
                }}
              >
                {"All"}
              </s.RightItem>
              {props.tagFilters.map(
                (item, index) =>
                  item !== undefined &&
                  !isEmpty(item) && (
                    <s.RightItem
                      key={"video_tag_" + index}
                      isFilter={props.selectedTag.includes(item)}
                      onClick={(e) => {
                        console.log(e);
                        props.onClickTag(item);
                      }}
                    >
                      {item}
                    </s.RightItem>
                  )
              )}
            </s.RightScroll>
          </s.RightFilter>
        </s.HeaderContainer>

        {props.status === "loading" ? (
          <Spinner />
        ) : (
          <s.Grid id="video-grid-container">
            {props.videoList?.map((item, index) => {
              return (
                <VideoSharePoster
                  key={`video_poster_${index}`}
                  item={item}
                  index={index}
                  selectedTag={props.selectedTag}
                  onModelVisible={props.isSharePopup === item.videoId}
                  onClickShare={props.onClickShare}
                  selectedAccount={props.selectedAccount}
                />
              );
            })}
          </s.Grid>
        )}
      </TableTemplate>
    </>
  );
}
