import {
  GetVideoShareViewUSerListResponse,
  VideoShareUserListResult,
} from "api/interfaces/videoshareInterface.interface";
import TableButton from "components/atoms/buttons/TableButton";
import { Table } from "components/atoms/table/Table";
import {
  ForwardedRef,
  forwardRef,
  useCallback,
  useImperativeHandle,
  useState,
} from "react";
import { SortOrder, TableColumn } from "react-data-table-component";
import { useQuery } from "react-query";
import { changeDateToFormat } from "utils/functions";
import ADD_USER_ICON from "assets/icons/add_user.svg";
import * as mediaQuery from "components/MediaQuery";
import { getVideoShareViewUserListByVideoId } from "api/videoshareAPI";
import EditModal from "./edit";
import AddModal from "./add";
import {
  BasePagination,
  SORT_DIRECTION,
} from "api/interfaces/commonInterface.interface";
import { useAppSelector } from "redux/hooks";
import { ProfileAccountInfo } from "api/interfaces/accountInterface.interface";
import { getAccountTimeFormat, getAccountTimeFormatDate } from "utils/timeUtil";
import { ImUserPlus } from "react-icons/im";

type Props = {
  videoId: string;
  isDisabledAdd: boolean;
};

const ViewVSUserList = forwardRef(
  (props: Props, ref: ForwardedRef<any>): JSX.Element => {
    const [userList, setUserList] = useState<VideoShareUserListResult[]>([]);
    const [editUser, setEditUser] = useState<VideoShareUserListResult>();
    const [isAdd, setIsAdd] = useState(false);
    //edit participant
    const [isEdit, setIsEdit] = useState(false);
    const selectedAccount: ProfileAccountInfo = useAppSelector(
      (state) => state.accountSettings
    );

    useImperativeHandle(ref, () => ({
      openSharePopup() {
        onChangeModal("add", true);
      },
    }));

    const onRowClicked = (row: VideoShareUserListResult) => {
      setEditUser(row);
      onChangeModal("edit", true);
    };

    const columns: TableColumn<VideoShareUserListResult>[] = [
      {
        name: "Name",
        grow: 1,
        sortable: true,
        sortField: "name",
        selector: (row) => row.name,
      },
      {
        name: "Email",
        grow: 3,
        sortable: true,
        sortField: "email",
        selector: (row) => row.email,
        hide: mediaQuery.mobileMax,
      },
      {
        name: "Shared Date",
        grow: 1,
        // sortable: true,
        // sortField: "share_date",
        selector: (row) =>
          getAccountTimeFormat(row.shareDate, selectedAccount, true),
        hide: mediaQuery.mobileMax,
      },
      {
        name: "Share Expiration Date",
        grow: 1.5,
        // sortable: true,
        // sortField: "expire",
        selector: (row) =>
          row.expires !== undefined
            ? getAccountTimeFormatDate(row.expires, selectedAccount, true)
            : "None",
      },
      {
        name: "Allow Download",
        grow: 1,
        selector: (row) => (row.canDownload ? "Yes" : "No"),
        center: true,
        hide: mediaQuery.mobileMax,
      },
      {
        name: (
          <TableButton
            label="Add"
            buttonType="primary"
            icon={<ImUserPlus size={18} />}
            disabled={!props.isDisabledAdd}
            onClickBtn={() => {
              props.isDisabledAdd && onChangeModal("add", true);
            }}
          />
        ),
        right: true,
        minWidth: "150px",
        cell: (row) => (
          <span className="row-click-button">
            <TableButton label="Edit" onClickBtn={() => onRowClicked(row)} />
          </span>
        ),
      },
    ];

    const onChangeModal = (type: string, value: boolean) => {
      if (type === "edit") {
        setIsEdit(value);
      }
      if (type === "add") {
        setIsAdd(value);
      }
    };

    const [videoUserListQueryInfo, setVideoUserListQueryInfo] =
      useState<BasePagination>({
        total: 0,
        pageNum: 0,
        pageLimit: 10,
        keyword: "",
        sortType: "name",
        sortDirection: SORT_DIRECTION.ASC,
      });

    const { error, refetch } = useQuery(
      ["videoshareViewUserList", videoUserListQueryInfo],
      () =>
        getVideoShareViewUserListByVideoId({
          videoId: props.videoId,
          payload: videoUserListQueryInfo,
        }),
      {
        retry: 0,
        refetchOnWindowFocus: false,
        onSuccess: (res: GetVideoShareViewUSerListResponse) => {
          if (res.error !== 0 || res.result === undefined) {
            setUserList([]);
            setVideoUserListQueryInfo((info) => {
              return { ...info, total: 0, pageNum: 0 };
            });
            return;
          }
          setUserList(res.result);
          setVideoUserListQueryInfo((info) => {
            return {
              ...info,
              total: res.page.total,
              pageNum: res.page.pageNum as number,
            };
          });
        },
        onError: (e: any) => {
          setUserList([]);
          setVideoUserListQueryInfo((info) => {
            return { ...info, total: 0, pageNum: 0 };
          });
        },
      }
    );

    const onChangePage = useCallback(
      (page: number, totalRows: number) => {
        if (page !== videoUserListQueryInfo.pageNum + 1) {
          setVideoUserListQueryInfo((info) => {
            return { ...info, pageNum: (page - 1) as number };
          });
        }
      },
      [videoUserListQueryInfo.pageNum]
    );

    const handleSort = async (
      selectedColumn: TableColumn<any>,
      sortDirection: SortOrder
    ) => {
      if (selectedColumn.sortField !== undefined) {
        setVideoUserListQueryInfo((info) => {
          return {
            ...info,
            sortType: selectedColumn.sortField as string,
            sortDirection: sortDirection.toUpperCase() as SORT_DIRECTION,
          };
        });
      }
    };

    return (
      <>
        <Table
          columns={columns}
          data={userList}
          onRowClicked={onRowClicked}
          paginationTotalRows={videoUserListQueryInfo.total}
          paginationPerPage={videoUserListQueryInfo.pageLimit}
          onChangePage={onChangePage}
          handleSort={handleSort}
        />

        {isAdd && (
          <AddModal
            isShare={false}
            index={1}
            videoId={props.videoId}
            onChangeModal={() => onChangeModal("add", false)}
          />
        )}
        {isEdit && editUser !== undefined && (
          <EditModal
            videoId={props.videoId}
            state={editUser}
            onChangeModal={() => onChangeModal("edit", false)}
          />
        )}
      </>
    );
  }
);

export default ViewVSUserList;
