import { ProfileAccountInfo } from "api/interfaces/accountInterface.interface";
import {
  CameraStep,
  NotifyUser,
  NotifyUserGroup,
  ReportDataRow,
  ThumbnailReport,
} from "api/interfaces/reportInterface.interface";
import {
  deleteReport,
  postThumbnailReportCreate,
  putReportEnable,
  putThumbnailReportCreate,
} from "api/reportAPI";
import { notify } from "components/atoms/notification/Notification";
import { useAuth } from "components/Auth";
import { useCallback, useEffect, useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import { useAppSelector } from "redux/hooks";
import { NOTIFICATION_TYPE } from "../common/AddNotification";
import AddThumbnailPresenter from "./AddThumbnailPresenter";
import {
  CustomError,
  REPORT_DETECT_TIME,
  Validate,
  ValidateMsg,
} from "api/interfaces/commonInterface.interface";
import { TimeDaySelectIF } from "components/atoms/select/TimeDaySelect";
import { useIntl } from "react-intl";
import { chain, groupBy, isEmpty } from "lodash";
import { formatBytesFromMB } from "utils/functions";
import { selectedRecorderCountThumbnail } from "utils/ReportUtil";
import useApiError from "hook/useApiError";

export enum UPDATE_TYPE {
  RECORDER,
  RECORDER_GROUP,
  CAMERA,
  SCHEDULE_TIME,
  SCHEDULE_WEEK,
  STORAGE,
  SEND_TIME,
  INTERVAL,
  WEEKLY_VALUE,
  MONTHLY_VALUE,
  IS_END_OF_MONTH,
  USER,
  USER_GROUP,
  REPORT_NAME,
  REPORT_DESC,
  REPORT_ENABLE,
}

export interface ScheduleItem {
  id: number;
  time: string;
  days: boolean[];
}

type Props = {
  onModal: (type?: string) => void;
  isEdit?: boolean;
  reportRow?: ReportDataRow;
  data?: ThumbnailReport;
};

export default function AddThumbnailContainer(props: Props): JSX.Element {
  const auth = useAuth();
  const intl = useIntl();
  const { handleError } = useApiError();
  
  const selectedAccount: ProfileAccountInfo = useAppSelector(
    (state) => state.accountSettings
  );
  const queryClient = useQueryClient();

  const [page, setPage] = useState<number>(0);

  const [recorderCounts, setRecorderCounts] = useState<number>(0);
  const [cameraCounts, setCameraCounts] = useState<number>(0);

  //const [scheduleItems, setScheduleItems] = useState<ScheduleItem[]>([{ id: 0, time: '09:00', days:[true,true,true,true,true,true,true] }]);

  const [thumbnailReport, setThumbnailReport] = useState<ThumbnailReport>({
    recorder: { autoApply: false, systems: [], recorderGroupId: [] },
    recorderCameraId: [],
    schedule: [
      {
        timeString: "09:00",
        days: [true, true, true, true, true, true, true],
      },
    ],
    notification: {
      dayTime: "09:00",
      nightTime: "",
      intervalType: REPORT_DETECT_TIME.WEEK,
      weeklyValue: [true, true, true, true, true, true, true],
      monthlyValue: 1,
      isEndOfMonth: true,
      users: [],
      userGroups: [],
    },
    preferences: {
      host: auth.user.name.concat(`(${auth.user.email})`),
      title: "",
      description: "",
      isEnable: true,
      userId : undefined
    },
    storage: 12,
  });

  const steps = [
    {
      label: "Recorders",
    },
    {
      label: "Camera",
    },
    {
      label: "Schedule",
    },
    {
      label: "Notification",
    },
    {
      label: "Preferences",
    },
  ];

  useEffect(() => {
    if (props.data) {
      setThumbnailReport(props.data);
      setThumbnailReport((info) => {
        return {
          ...info,
          recorder: {
            ...info.recorder,
            autoApply: false,
          },
        };
      });
      if (props.data.recorderCameraId) {
        const cameraCount = props.data.recorderCameraId.reduce(
          (countCamera: number, curr: CameraStep) => {
            return countCamera + curr.camera.length;
          },
          0
        );
        setCameraCounts(cameraCount);
      } else {
        setCameraCounts(0);
      }

      const groupBySelect = selectedRecorderCountThumbnail(
        props.data.recorderCameraId
      );
      setSelectRecorderCount(groupBySelect !== undefined ? groupBySelect : 0);
    }
  }, [props.data]);

  const onChangePage = (name: string, index?: number) => {
    if (name === "next") {
      setPage(page + 1);
    }
    if (name === "previous") {
      setPage(page - 1);
    }
    if (name === "save") {
      props.onModal();
    }
    if (name === "step" && index !== undefined) {
      setPage(index);
    }
  };

  const [estimateStorage, setEstimateStorage] = useState<string>("0 MB");
  useEffect(() => {
    const estimate =
      cameraCounts *
      thumbnailReport.schedule.reduce((weekTotal, data) => {
        return (
          weekTotal +
          data.days.reduce((dayCount, dayOfWeek) => {
            return dayCount + (dayOfWeek ? 1 : 0);
          }, 0)
        );
      }, 0) *
      thumbnailReport.storage;

    if (estimate !== 0) {
      setEstimateStorage(formatBytesFromMB(estimate));
    } else {
      setEstimateStorage("0 MB");
    }
  }, [cameraCounts, thumbnailReport, thumbnailReport.storage]);

  const onChangeNotification = useCallback(
    (
      type: NOTIFICATION_TYPE,
      value: string | number | boolean | string[] | boolean[]
    ) => {
      if (type === NOTIFICATION_TYPE.SEND_TIME) {
        setThumbnailReport((info) => {
          return {
            ...info,
            notification: {
              ...info.notification,
              dayTime: value as string,
            },
          };
        });
      } else if (type === NOTIFICATION_TYPE.INTERVAL) {
        setThumbnailReport((info) => {
          return {
            ...info,
            notification: {
              ...info.notification,
              intervalType: value as string,
            },
          };
        });
      } else if (type === NOTIFICATION_TYPE.WEEKLY_VALUE) {
        setThumbnailReport((info) => {
          return {
            ...info,
            notification: {
              ...info.notification,
              weeklyValue: value as boolean[],
            },
          };
        });
      } else if (type === NOTIFICATION_TYPE.MONTHLY_VALUE) {
        setThumbnailReport((info) => {
          return {
            ...info,
            notification: {
              ...info.notification,
              monthlyValue: value as number,
            },
          };
        });
      } else if (type === NOTIFICATION_TYPE.IS_END_OF_MONTH) {
        setThumbnailReport((info) => {
          return {
            ...info,
            notification: {
              ...info.notification,
              isEndOfMonth: value as boolean,
            },
          };
        });
      } else if (type === NOTIFICATION_TYPE.USER) {
        setThumbnailReport((info) => {
          return {
            ...info,
            notification: {
              ...info.notification,
              users: (value as string[]).map((data) => {
                return {
                  userId: data,
                  isEmailEnable: true,
                };
              }) as Array<NotifyUser>,
            },
          };
        });
      } else if (type === NOTIFICATION_TYPE.USER_GROUP) {
        setThumbnailReport((info) => {
          return {
            ...info,
            notification: {
              ...info.notification,
              userGroups: (value as string[]).map((data) => {
                return {
                  userGroupId: data,
                  isEmailEnable: true,
                };
              }) as Array<NotifyUserGroup>,
            },
          };
        });
      }
    },
    []
  );

  const [selectRecorderCount, setSelectRecorderCount] = useState<number>(0);
  const onChangeReportInfo = useCallback(
    (
      type: UPDATE_TYPE,
      value:
        | string
        | number
        | boolean
        | string[]
        | boolean[]
        | number[]
        | CameraStep[]
    ) => {
      if (type === UPDATE_TYPE.RECORDER) {
        setThumbnailReport((info) => {
          return {
            ...info,
            recorder: {
              ...info.recorder,
              systems: value as string[],
            },
          };
        });
      } else if (type === UPDATE_TYPE.RECORDER_GROUP) {
        setThumbnailReport((info) => {
          return {
            ...info,
            recorder: {
              ...info.recorder,
              recorderGroupId: value as number[],
            },
          };
        });
      } else if (type === UPDATE_TYPE.CAMERA) {
        setThumbnailReport((info) => {
          return {
            ...info,
            recorderCameraId: value as CameraStep[],
          };
        });

        const cameraCount = (value as CameraStep[]).reduce(
          (countCamera: number, curr: CameraStep) => {
            return countCamera + curr.camera.length;
          },
          0
        );
        setCameraCounts(cameraCount);

        const groupBySelect = selectedRecorderCountThumbnail(
          value as CameraStep[]
        );
        setSelectRecorderCount(groupBySelect !== undefined ? groupBySelect : 0);
      } else if (type === UPDATE_TYPE.SCHEDULE_TIME) {
        setThumbnailReport((info) => {
          return {
            ...info,
            schedule: {
              ...info.schedule,
              timeString: value as string,
            },
          };
        });
      } else if (type === UPDATE_TYPE.STORAGE) {
        setThumbnailReport((info) => {
          return {
            ...info,
            storage: value as number,
          };
        });
      } else if (type === UPDATE_TYPE.SCHEDULE_WEEK) {
        setThumbnailReport((info) => {
          return {
            ...info,
            schedule: {
              ...info.schedule,
              days: value as boolean[],
            },
          };
        });
      } else if (type === UPDATE_TYPE.REPORT_NAME) {
        setThumbnailReport((info) => {
          return {
            ...info,
            preferences: {
              ...info.preferences,
              title: value as string,
            },
          };
        });
      } else if (type === UPDATE_TYPE.REPORT_DESC) {
        setThumbnailReport((info) => {
          return {
            ...info,
            preferences: {
              ...info.preferences,
              description: value as string,
            },
          };
        });
      } else if (type === UPDATE_TYPE.REPORT_ENABLE) {
        setThumbnailReport((info) => {
          return {
            ...info,
            preferences: {
              ...info.preferences,
              isEnable: value as boolean,
            },
          };
        });
      }
    },
    []
  );

  const mutationCreateReport = useMutation(postThumbnailReportCreate, {
    onSuccess: () => {
      notify("success", intl.formatMessage({
        id: "label.report.notify.thumbnail.create.success",
        defaultMessage: "Thumbnail report created successfully.",
      }));
      props.onModal();
    },
    onError: (err:CustomError) => {
      handleError(err, intl.formatMessage({
        id: "label.report.notify.thumbnail.create.fail",
        defaultMessage: "Failed to create Thumbnail report.",
      }));
    },
  });

  const mutationUpdateReport = useMutation(putThumbnailReportCreate, {
    onSuccess: () => {
      notify("success", intl.formatMessage({
        id: "label.report.notify.thumbnail.update.success",
        defaultMessage: "Thumbnail report updated successfully.",
      }));
      props.onModal();
    },
    onError: (err:CustomError) => {
      handleError(err, intl.formatMessage({
        id: "label.report.notify.thumbnail.update.fail",
        defaultMessage: "Failed to update Thumbnail report.",
      }));
    },
  });

  const [reportRuleValidate, setReportRuleValidate] = useState<Validate>({
    isRecorder: false,
    isCamera: false,
    isRuleName: false,
  });
  const [reportRuleValidateMsg, setReportRuleValidateMsg] =
    useState<ValidateMsg>({
      isRecorder: "",
      isCamera: "",
      isRuleName: "",
    });

  const initReportRuleValidate = useCallback((field: string) => {
    setReportRuleValidate((info) => {
      return { ...info, [field as keyof typeof reportRuleValidate]: false };
    });
    setReportRuleValidateMsg((info) => {
      return { ...info, [field as keyof typeof reportRuleValidateMsg]: "" };
    });
  }, []);
  const onClickCreate = useCallback(() => {
    //setIsAddModal(true);
    initReportRuleValidate("isRecorder");
    initReportRuleValidate("isCamera");
    initReportRuleValidate("isRuleName");

    if (
      (thumbnailReport.recorder.systems === undefined ||
        thumbnailReport.recorder.systems.length === 0) &&
      (thumbnailReport.recorder.recorderGroupId === undefined ||
        thumbnailReport.recorder.recorderGroupId.length === 0)
    ) {
      setReportRuleValidate((info) => {
        return { ...info, isRecorder: true as boolean };
      });

      setReportRuleValidateMsg((info) => {
        return {
          ...info,
          isRecorder: intl.formatMessage({
            id: "validateMsg.report.rule.emptyRecorder",
            defaultMessage: "Select one or more recorders",
          }) as string,
        };
      });
      setPage(0);
      return;
    }

    if (
      thumbnailReport.recorderCameraId === undefined ||
      (thumbnailReport.recorderCameraId as CameraStep[]).length === 0
    ) {
      setReportRuleValidate((info) => {
        return { ...info, isCamera: true as boolean };
      });

      setReportRuleValidateMsg((info) => {
        return {
          ...info,
          isRecorder: intl.formatMessage({
            id: "validateMsg.report.rule.emptyCamera",
            defaultMessage: "Select one or more cameras.",
          }) as string,
        };
      });
      setPage(1);
      return;
    }

    if (isEmpty(thumbnailReport.preferences.title)) {
      setReportRuleValidate((info) => {
        return { ...info, isRuleName: true as boolean };
      });

      setReportRuleValidateMsg((info) => {
        return {
          ...info,
          isRuleName: intl.formatMessage({
            id: "validateMsg.report.rule.emptyRuleName",
            defaultMessage: "Please enter report rule name.",
          }) as string,
        };
      });
      setPage(4);
      return;
    }

    if (props.isEdit) {
      mutationUpdateReport.mutate({
        payload: thumbnailReport,
        accountId: selectedAccount.accountId,
        reportId: props.reportRow?.id as string,
      });
    } else {
      mutationCreateReport.mutate({
        payload: thumbnailReport,
        accountId: selectedAccount.accountId,
      });
    }
  }, [
    thumbnailReport,
    mutationCreateReport,
    mutationUpdateReport,
    selectedAccount.accountId,
  ]);

  const recorderCount = () => {
    if (thumbnailReport.recorder.systems !== undefined) {
      return thumbnailReport.recorder.systems.length;
    } else {
      return 0;
    }
  };

  const [isConfirm, SetIsConfirm] = useState<boolean>(false);

  const mutationDeleteReport = useMutation(deleteReport, {
    onSuccess: () => {
      notify("success", intl.formatMessage({
        id: "label.report.notify.thumbnail.delete.success",
        defaultMessage: "Thumbnail report deleted successfully.",
      }));
      props.onModal("edit");
    },
    onError: (err:CustomError) => {
      handleError(err, intl.formatMessage({
        id: "label.report.notify.thumbnail.delete.fail",
        defaultMessage: "Failed to delete Thumbnail report.",
      }));
    },
  });

  const onConfirmDelete = () => {
    mutationDeleteReport.mutate({
      reportId: props.reportRow?.id as string,
      accountId: selectedAccount.accountId,
    });
  };
  const setIsDeleteConfirm = (confirm: boolean) => {
    SetIsConfirm(confirm);
  };
  const onDelete = (value: boolean) => {
    SetIsConfirm(value);
  };

  const mutationEnableReport = useMutation(putReportEnable, {
    onSuccess: () => {
      //notify("success", "Success : Success to Enable Health Report.");
    },
    onError: () => {
      //notify("error", "Error : Failed to delete Health Report.");
    },
  });

  const onChangeEnable = (value: boolean) => {
    // mutationEnableReport.mutate({
    //   reportId: props.reportRow?.id as string,
    //   payload: {
    //     enable: value,
    //   },
    // });
  };

  const onChangeDayOfWeek = useCallback(
    (selectDayOfWeek: TimeDaySelectIF[]) => {
      setThumbnailReport((info) => {
        return {
          ...info,
          schedule: selectDayOfWeek as TimeDaySelectIF[],
        };
      });
    },
    []
  );

  const onChangeRecorderCount = (recorderCount: number) => {
    setRecorderCounts(recorderCount);
  };

  const onChangeCameraCount = (cameraCount: number) => {
    //setCameraCounts(cameraCount);
  };

  return (
    <AddThumbnailPresenter
      steps={steps}
      page={page}
      onChangePage={onChangePage}
      onChangeReportInfo={onChangeReportInfo}
      onChangeNotification={onChangeNotification}
      accountId={selectedAccount.accountId}
      hostName={auth.user?.name + "(" + auth.user?.email + ")"}
      onClickCreate={onClickCreate}
      thumbnailReport={thumbnailReport}
      recorderCount={recorderCounts}
      cameraCount={cameraCounts}
      isEdit={props.isEdit}
      setIsDeleteConfirm={setIsDeleteConfirm}
      onConfirmDelete={onConfirmDelete}
      isConfirm={isConfirm}
      onDelete={onDelete}
      onChangeEnable={onChangeEnable}
      onChangeDayOfWeek={onChangeDayOfWeek}
      onChangeRecorderCount={onChangeRecorderCount}
      onChangeCameraCount={onChangeCameraCount}
      reportRuleValidate={reportRuleValidate}
      reportRuleValidateMsg={reportRuleValidateMsg}
      estimateStorage={estimateStorage}
      selectRecorderCount={selectRecorderCount}
      reportRow={props.reportRow}
      // scheduleItems={scheduleItems}
      // addScheduleItem={addScheduleItem}
      // deleteScheduleItem={deleteScheduleItem}
    />
  );
}
