import GaugeComponent from "react-gauge-component";
import { GaugeComponentProps } from "react-gauge-component/dist/lib/GaugeComponent/types/GaugeComponentProps";
import { COLORS } from "styles/colors";

interface Props extends GaugeComponentProps {
  title: string;
  value: number | undefined;
}

export default function Guage(props: Props): JSX.Element {
  const style: React.CSSProperties = {
    // width: 300,
    ...props.style,
  };
  return (
    <GaugeComponent
      type="radial"
      style={style}
      arc={{
        gradient: true,
        cornerRadius: 1,
        colorArray: ["#5BE12C", "#EA4228"],
        subArcs: [{ limit: 10 }, { limit: 30 }, {}, {}, {}],
        padding: 0,
        width: 0.2,
      }}
      pointer={{
        elastic: true,
        animationDelay: 0,
        color: "#aaa",
      }}
      {...props}
    />
  );
}
