import { PASSWORD_STATUS } from "pages/auth/signup/SignupPresenter";
import { parsePhoneNumber } from "react-phone-number-input/max";

export interface PasswordValid {
  lowerCase: boolean;
  upperCase: boolean;
  number: boolean;
  specialCharactor: boolean;
}

export const validPassword = (password: string): PASSWORD_STATUS => {
  const passwordLen = password.length;
  const upperCase = /[A-Z]/.test(password);

  if (passwordLen <= 8) {
    return PASSWORD_STATUS.VERY_POOR;
  }

  const lowerCase = /[a-z]/.test(password);
  const num = /\d/.test(password);
  const specialCharactor = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/.test(
    password
  );
  const valiadGood = specialCharactor && lowerCase && upperCase && num;

  if (valiadGood && passwordLen >= 14) {
    return PASSWORD_STATUS.EXCELLENT;
  }
  if (valiadGood && passwordLen >= 12) {
    return PASSWORD_STATUS.GOOD;
  }
  if (valiadGood && passwordLen >= 10) {
    return PASSWORD_STATUS.OK;
  }

  return PASSWORD_STATUS.POOR;
};

export const getOnlyNumber = (input: string): string => {
  return /\d/.test(input) ? input : "";
};

export const isPhoneNumberValid = (input:string) =>{
  const phone = parsePhoneNumber (input);
  if(phone !== undefined && phone.country === "US"){
    return phone.isPossible() && phone.isValid()
  }else{
    return false;
  }
}