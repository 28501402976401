import { BarDatum, BarTooltipProps } from "@nivo/bar";
import styled from "styled-components";
import { COLORS } from "styles/colors";
import { calcRem } from "styles/theme";

export default function BarChartTooltip(
  props: BarTooltipProps<BarDatum>
): JSX.Element {
  return (
    <Wrapper>
      {/* <span
        style={{
          display: "block",
          width: "12px",
          height: "12px",
          background: props.point.color,
        }}
      /> */}
      <span>
        {props.data.indexName} : {props.data.barChartKey}%
      </span>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  background-color: ${COLORS.WHITE};
  padding: 4px;
  font-size: ${calcRem(13)};
  border-radius: 4px;

  > span {
    font-weight: 500;
  }
`;
