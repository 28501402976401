import styled from "styled-components";
import { COLORS, LIGHT_THEME_COLORS } from "styles/colors";
import { calcRem } from "styles/theme";

interface Props {}

const TextArea = styled.textarea<Props>`
  width: 100%;
  min-height: 70px;
  border-radius: 4px;
  padding: 10px;
  box-sizing: border-box;
  ${({ theme }) =>
    theme.value !== "light" &&
    `
    border: 1px solid #cacaca;
  `}
  font-size: ${calcRem(14)};
  border-color: #cacaca;

  ::placeholder {
    color: #abb5c5;
  }
  :disabled {
    background-color: ${({ theme }) =>
      theme.value === "light" ? LIGHT_THEME_COLORS.LIGHTGRAY : COLORS.BUTTONS1};
    color: ${({ theme }) => `${theme.COLORS.WHITE}60`};
    border: ${({ theme }) =>
      theme.value === "light" && LIGHT_THEME_COLORS.BORDER};
  }
`;

export default TextArea;
