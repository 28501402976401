import { useCallback, useEffect, useMemo, useState } from "react";
import { AccountPresenter, DataRow } from "./AccountPresenter";
import { Media, SortOrder, TableColumn } from "react-data-table-component";
import * as s from "./AccountStyled";
import { Level } from "components/atoms/level/Level";
import TableButton from "components/atoms/buttons/TableButton";
import { useMutation, useQuery, useQueryClient } from "react-query";
import {
  deleteAccount,
  getAccoountList,
  postAccountCreate,
  putAccountUpdate,
} from "api/accountAPI";
import {
  CreateAccountRequest,
  GetAccountListQuery,
  ProfileAccountInfo,
} from "api/interfaces/accountInterface.interface";
import { Account, getAccountListMappger } from "api/mappers/accountMapper";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import { calRowCount, handleCopyClipBoard } from "utils/functions";
import { notify } from "components/atoms/notification/Notification";
import { setAccountSetting } from "redux/reducers/account/accountSlice";
import { useAuth } from "components/Auth";
import { useLocation, useNavigate } from "react-router-dom";
import {
  clearBreadcrumbRoute,
  setBreadcrumbRoute,
} from "redux/reducers/breadcrumb/breadcrumb";
import { checkEmailRegEx, checkWhiteSpaceRegEx } from "utils/regEx";
import { useIntl } from "react-intl";
import {
  CustomError,
  LEVEL_TYPE,
  SORT_DIRECTION,
  Validate,
  ValidateMsg,
} from "api/interfaces/commonInterface.interface";
import {
  getAccountInfo,
  getAllAccountInfo,
  getSwitchAccountInfo,
  isCheckPermission,
  isSubCP,
} from "utils/AccountUtil";
import { isEmpty, isNull } from "lodash";
import RBACWrapper from "components/blocks/function/RBACWrapper";
import { IoIosCopy } from "react-icons/io";
import { COLORS } from "styles/colors";
import useApiError from "hook/useApiError";
import { menuNavigation, queryNavigate } from "utils/MenuUtil";
import { ReactComponent as AccountSVG } from "assets/icons/sidenav/account.svg";

export interface Props {}

export function AccountContainer(): JSX.Element {
  const queryClient = useQueryClient();
  const intl = useIntl();

  const selectedAccount: ProfileAccountInfo = useAppSelector(
    (state) => state.accountSettings
  );
  const dispatch = useAppDispatch();
  const auth = useAuth();
  const navigate = useNavigate();
  const location: any = useLocation();

  const [isAddModal, setIsAddModal] = useState(false);
  const [isEditModal, setIsEditModal] = useState(false);
  const [currAccount, setCurrAccount] = useState<Account>();
  const initIsSelf =
    location?.state?.isSwitchMenu !== undefined
      ? location?.state?.isSwitchMenu
      : false;
  const [isSelf, setIsSelf] = useState(initIsSelf);
  const [totalCount, setTotalCount] = useState<number>(0);

  const params = new URLSearchParams(location.search);

  const [accountValidate, setAccountValidate] = useState<Validate>({
    isAccountName: false,
    isLevel: false,
    isUserName: false,
    isUserEmail: false,
  });

  const [accountValidateMsg, setAccountValidateMsg] = useState<ValidateMsg>({
    isAccountName: "",
    isLevel: "",
    isUserName: "",
    isUserEmail: "",
  });

  const [status, setStatus] = useState({
    totalTitle: "Total",
    totalValue: 0,
  });

  const [pending, setPending] = useState<boolean>(true);
  const { handleError } = useApiError();

  useEffect(() => {
    let state = (location?.state?.isSwitchMenu as boolean) || undefined;
    if (state !== undefined) {
      setIsSelf(state);
    } else {
      setIsSelf(false);
    }
    const changeParam = new URLSearchParams(location.search);
    if (changeParam.size > 0) {
      setAccountQueryInfo({
        pageNum: isNull(changeParam.get("pageNum"))
          ? 0
          : Number(changeParam.get("pageNum")),
        pageLimit: isNull(changeParam.get("pageLimit"))
          ? 20
          : Number(changeParam.get("pageLimit")),
        keyword: isNull(changeParam.get("keyword"))
          ? ""
          : (changeParam.get("keyword") as string),
        sortType: isNull(changeParam.get("sortType"))
          ? "name"
          : (changeParam.get("sortType") as string),
        sortDirection: isNull(changeParam.get("sortDirection"))
          ? SORT_DIRECTION.ASC
          : (changeParam.get("sortDirection") as SORT_DIRECTION),
      });
    } else {
      setAccountQueryInfo({
        pageNum: 0,
        pageLimit: 20,
        keyword: "",
        sortType: "name",
        sortDirection: SORT_DIRECTION.ASC,
      });
    }
  }, [location]);

  const [isRowClickEditable, setIsRowClickEditable] = useState<boolean>(
    isCheckPermission("manageAccount", selectedAccount)
  );
  useEffect(() => {
    if (selectedAccount) {
      setIsRowClickEditable(
        isCheckPermission("manageAccount", selectedAccount)
      );
    }
  }, [selectedAccount]);

  const rowsPerPage = calRowCount();

  const mutationDeleteAccount = useMutation(deleteAccount, {
    onSuccess: (res: any) => {
      if (res.error !== 0) {
        notify(
          "error",
          intl.formatMessage({
            id: "validateMsg.account.delete.fail",
            defaultMessage: "Failed to delete organization.",
          })
        );
      } else {
        setIsEditModal(false);
        notify(
          "success",
          intl.formatMessage({
            id: "validateMsg.account.delete.success",
            defaultMessage: "Organization deleted successfully.",
          })
        );
        queryClient.invalidateQueries("account");
      }
    },
    onError: (err: CustomError) => {
      handleError(
        err,
        intl.formatMessage({
          id: "validateMsg.account.delete.fail",
          defaultMessage: "Failed to delete organization.",
        })
      );
    },
  });

  const deleteAccountCb = useCallback(
    (accountId: string) => {
      mutationDeleteAccount.mutate(accountId);
    },
    [mutationDeleteAccount]
  );

  //temp columns
  const columns: TableColumn<DataRow>[] = [
    {
      id: "name",
      name: "Name",
      // sortable: true, // 정렬 활성화,
      grow: 2,
      cell: (row) => (
        <s.NameLabel onClick={() => onCellClick(row)}>
          <Level level={row.levelFullName} />
          {row.name}
        </s.NameLabel>
      ),
      sortable: true,
      sortField: "name",
    },
    {
      name: "Level",
      grow: 1.5,
      selector: (row) => row.levelFullName,
      hide: Media.SM,
      sortable: true,
      sortField: "level",
    },
    {
      name: "Organization ID",
      grow: 1.5,
      cell: (row) => (
        <s.NameLabel>
          <div className="org">
            <AccountSVG />
          </div>
          {row.accountNumber}

          <button onClick={() => handleCopyClipBoard(row.accountNumber)}>
            <IoIosCopy />
          </button>
        </s.NameLabel>
      ),
      sortable: true,
      sortField: "accountNumber",
    },
    {
      name: "Sub Organizations",
      grow: 1,
      selector: (row) => (row.level === LEVEL_TYPE.EU ? `-` : row.accounts),
      center: true,
      hide: Media.SM,
      omit : isSubCP(selectedAccount)
    },
    {
      name: "Recorders",
      grow: 1,
      selector: (row) => row.recorders,
      center: true,
      hide: Media.SM,
    },
    {
      name: "Users",
      grow: 1,
      selector: (row) => row.users,
      center: true,
      hide: Media.SM,
    },
    // 마지막 버튼
    {
      name: "",
      minWidth: "100px",
      cell: (row) => (
        <s.Row>
          {!isSelf && (
            <RBACWrapper requiredPermissions="manageAccount">
              <TableButton
                label="Edit"
                onClickBtn={() => {
                  const account = Array.from(accounts).find(
                    (account) => account.accountId === row.accountId
                  );
                  setCurrAccount(account);
                  onModal("edit");
                }}
              />
            </RBACWrapper>
          )}
          <span className="row-click-button">
            <TableButton
              id={"dw_account_button_".concat(row.accountId)}
              label="Enter"
              onClickBtn={() => onCellClick(row)}
            />
          </span>
        </s.Row>
      ),
      right: true,
    },
  ];

  const onModal = (type?: string) => {
    if (type === "add") {
      if (selectedAccount.accountLevel !== "EU") {
        setIsAddModal(!isAddModal);
      }
    }
    if (type === "edit") {
      setIsEditModal(!isEditModal);
    }
  };

  // 이름만 클릭 시 다음 상세 페이지로 이동
  const onCellClick = (row: DataRow) => {
    // TODO Breadcrumb선택 시 해당 Account로 이동하는 함수 구현필요.

    // const selectAccount = auth.user?.accounts.find((account, index, array) => {
    //   return account.accountId === row.accountId;
    // });

    getSwitchAccountInfo(row.accountId).then(function (selectAccount) {
      if (!!selectAccount) {
        if (selectAccount.userPermission === undefined) {
          notify(
            "warning",
            intl.formatMessage({
              id: "validateMsg.account.permissionsDeny",
              defaultMessage:
                "The organization needs permissions. Please contact your administrator.",
            })
          );
          return;
        }
        setPending(true);
        if (isSelf) {
          dispatch(clearBreadcrumbRoute());
          setIsSelf(false);
        }
        dispatch(setAccountSetting(selectAccount));
        if (row.levelFullName === "End User") {
          if (row.parentAccount !== undefined && row.parentAccount.length > 0) {
            const bcArray = row.parentAccount;
            const setBreadCrumb = bcArray.reverse().map((link) => {
              return {
                name: link.accountName,
                routeName: "/account",
                accountId: link.accountId,
              };
            });
            setBreadCrumb.push({
              name: selectAccount.accountName,
              routeName: "/recorder/list",
              accountId: selectAccount.accountId,
            });
            dispatch(setBreadcrumbRoute(setBreadCrumb));
          } else {
            dispatch(
              setBreadcrumbRoute([
                {
                  name: selectAccount.accountName,
                  routeName: "/recorder/list",
                  accountId: selectAccount.accountId,
                },
              ])
            );
          }
          // dispatch(setMenu("Recorders"));
          menuNavigation(selectAccount, navigate, `/recorders/list`);
          //navigate(`/recorders/list/${selectAccount.accountId}`);
        } else {
          menuNavigation(selectAccount, navigate, `/account`);
          //navigate(`/account/${selectAccount.accountId}`);
          //   if (row.parentAccount !== undefined && row.parentAccount.length > 0) {
          //     const bcArray = row.parentAccount;
          //     const setBreadCrumb = bcArray.reverse().map((link) => {
          //       return {
          //         name: link.accountName,
          //         routeName: "/account",
          //         accountId: link.accountId,
          //       };
          //     });
          //     setBreadCrumb.push({
          //       name: selectAccount.accountName,
          //       routeName: "/account",
          //       accountId: selectAccount.accountId,
          //     });
          //     dispatch(setBreadcrumbRoute(setBreadCrumb));
          //   } else {
          //     dispatch(
          //       setBreadcrumbRoute([
          //         {
          //           name: selectAccount.accountName,
          //           routeName: "/account",
          //           accountId: selectAccount.accountId,
          //         },
          //       ])
          //     );
          //   }
        }
      }
    });
    // if (!!selectAccount) {
    //   if (isSelf) {
    //     dispatch(clearBreadcrumbRoute());
    //     setIsSelf(false);
    //   }
    //   dispatch(setAccountSetting(selectAccount));
    //   if (row.levelFullName === "End User") {
    //     dispatch(
    //       pushBreadcrumbRoute({
    //         name: selectAccount.accountName,
    //         routeName: "/recorder/list",
    //         accountId: selectAccount.accountId,
    //       })
    //     );
    //     dispatch(setMenu("Recorders"));
    //     navigate("/recorders/list", { replace: true });
    //   } else {
    //     dispatch(
    //       pushBreadcrumbRoute({
    //         name: selectAccount.accountName,
    //         routeName: "/account",
    //         accountId: selectAccount.accountId,
    //       })
    //     );
    //   }
    // }
  };

  // row 전체 클릭 시
  const onRowClicked = (row: any, event: any) => {
    console.log(row, event);
  };

  const [accountQueryInfo, setAccountQueryInfo] = useState<GetAccountListQuery>(
    {
      pageNum: 0,
      pageLimit: 20,
      keyword: "",
      sortType: "name",
      sortDirection: SORT_DIRECTION.ASC,
    }
  );

  const [accounts, setAccounts] = useState<Account[]>([]);
  const { data, error, refetch } = useQuery(
    ["account", accountQueryInfo, selectedAccount],
    () =>
      getAccoountList({
        payload: accountQueryInfo,
        accountId: selectedAccount.accountId,
        isSelf: false,
      }),
    {
      retry: 0,
      refetchOnWindowFocus: false,
      onSuccess: (res: any) => {
        setStatus({
          ...status,
          totalValue: res.page.total,
        });
        if (res.result === undefined) {
          setAccounts([]);
        } else {
          setAccounts(getAccountListMappger(res.result));
        }
      },
      onError: (e: any) => {
        setStatus({
          totalTitle: "Total ",
          totalValue: 0,
        });
        setAccounts([]);
      },
      onSettled: (e: any) => {
        setPending(false);
      },
    }
  );

  const accountList = useMemo((): DataRow[] => {
    return Array.from(accounts).map((account) => {
      const {
        name,
        level,
        levelFullName,
        hasAccounts,
        hasRecorders,
        hasUsers,
        accountId,
        accountNumber,
        timezone,
        dateFormat,
        timeFormat,
        accountAdditionalNumber,
        description,
        parentAccount,
      } = account;
      return {
        name,
        level,
        levelFullName,
        accounts: hasAccounts,
        recorders: hasRecorders,
        users: hasUsers,
        accountId,
        accountNumber,
        timezone,
        dateFormat,
        timeFormat,
        accountAdditionalNumber,
        description,
        parentAccount,
      };
    });
  }, [accounts]);

  const mutationCreateAccount = useMutation(postAccountCreate, {
    onSuccess: (res: any) => {
      notify(
        "success",
        intl.formatMessage({
          id: "validateMsg.account.create.success",
          defaultMessage: "Organization added successfully.",
        })
      );
      getAllAccountInfo()
        .then((response) => {
          if (!!response.totalAccount) {
            auth.updateTotalAccount(response.totalAccount);
          }
        })
        .catch()
        .finally();
      setIsAddModal(false);
      queryClient.invalidateQueries("account");
    },
    onError: (err: CustomError) => {
      handleError(
        err,
        intl.formatMessage({
          id: "validateMsg.account.create.fail",
          defaultMessage: "Failed to add organization.",
        })
      );
    },
  });

  const onClickCrearte = useCallback(
    (accountInfo: CreateAccountRequest) => {
      if (checkWhiteSpaceRegEx(accountInfo.name)) {
        setAccountValidate((info) => {
          return { ...info, isAccountName: true as boolean };
        });

        setAccountValidateMsg((info) => {
          return {
            ...info,
            isAccountName: intl.formatMessage({
              id: "validateMsg.account.name",
              defaultMessage: "Please enter organization name.",
            }) as string,
          };
        });

        return;
      }

      if (checkWhiteSpaceRegEx(accountInfo.level)) {
        setAccountValidate((info) => {
          return { ...info, isLevel: true as boolean };
        });

        setAccountValidateMsg((info) => {
          return {
            ...info,
            isLevel: intl.formatMessage({
              id: "validateMsg.account.level",
              defaultMessage: "Organization level is mandatory.",
            }) as string,
          };
        });
      }

      if (!isEmpty(accountInfo.inviteName)) {
        if (isEmpty(accountInfo.inviteEmail)) {
          setAccountValidate((info) => {
            return { ...info, isUserEmail: true as boolean };
          });

          setAccountValidateMsg((info) => {
            return {
              ...info,
              isUserEmail: intl.formatMessage({
                id: "validateMsg.account.userMail.empty",
                defaultMessage: "Please enter email",
              }) as string,
            };
          });
          return;
        } else {
          if (!checkEmailRegEx(accountInfo.inviteEmail as string)) {
            setAccountValidate((info) => {
              return { ...info, isUserEmail: true as boolean };
            });

            setAccountValidateMsg((info) => {
              return {
                ...info,
                isUserEmail: intl.formatMessage({
                  id: "validateMsg.account.userMail.illegal",
                  defaultMessage: "Enter a valid email address",
                }) as string,
              };
            });
            return;
          }
        }
      }

      //setIsAddModal(true);
      mutationCreateAccount.mutate({
        payload: accountInfo, // Account -> UpdateRequest
        accountId: selectedAccount.accountId,
      });
    },
    [mutationCreateAccount, selectedAccount.accountId]
  );

  const mutationUpdateAccount = useMutation(putAccountUpdate, {
    onSuccess: (res: any) => {
      if (res.error === 0) {
        notify(
          "success",
          intl.formatMessage({
            id: "validateMsg.account.modify.success",
            defaultMessage: "Organization updated successfully.",
          })
        );
        setIsAddModal(false);
        queryClient.invalidateQueries("account");
      } else {
        notify(
          "error",
          intl.formatMessage({
            id: "validateMsg.account.modify.fail",
            defaultMessage: "Failed to update organization.",
          })
        );
      }
    },
    onError: (err: CustomError) => {
      handleError(
        err,
        intl.formatMessage({
          id: "validateMsg.account.modify.fail",
          defaultMessage: "Failed to update organization.",
        })
      );
    },
  });

  const onClickUpdate = useCallback(
    (accountInfo: Account, file: File) => {
      setIsEditModal(false);
      const formData = new FormData();
      if (file !== undefined) {
        formData.append("file", file);
      }
      const json = JSON.stringify(accountInfo);
      const blob = new Blob([json], { type: "application/json" });
      formData.append("accountOptionDto", blob);

      mutationUpdateAccount.mutate({
        form: formData, // Account -> UpdateRequest
        accountId: selectedAccount.accountId,
      });
    },
    [mutationUpdateAccount, selectedAccount.accountId]
  );

  const onClickSearch = useCallback(
    (keyword: string) => {
      if (accountQueryInfo.keyword !== keyword) {
        queryNavigate(
          navigate,
          `/account`,
          {
            pageLimit: accountQueryInfo.pageLimit,
            pageNum: 0,
            keyword: keyword,
            sortType: accountQueryInfo.sortType,
            sortDirection: accountQueryInfo.sortDirection as SORT_DIRECTION,
          },
          selectedAccount
        );
      } else {
        refetch();
      }
      setPending(true);
      // setAccountQueryInfo((query) => {
      //   return {
      //     ...query,
      //     keyword,
      //   };
      // });
      //refetch();
    },
    [
      accountQueryInfo.keyword,
      accountQueryInfo.pageLimit,
      accountQueryInfo.sortDirection,
      accountQueryInfo.sortType,
      navigate,
      refetch,
      selectedAccount,
    ]
  );

  const onChangePage = (page: number, totalRows: number) => {
    // console.log(page, totalRows);
    if (page !== accountQueryInfo.pageNum + 1) {
      queryNavigate(
        navigate,
        `/account`,
        {
          pageLimit: accountQueryInfo.pageLimit,
          pageNum: page - 1,
          keyword: accountQueryInfo.keyword,
          sortType: accountQueryInfo.sortType,
          sortDirection: accountQueryInfo.sortDirection as SORT_DIRECTION,
        },
        selectedAccount
      );
      setPending(true);
      // setAccountQueryInfo((info) => {
      //   return { ...info, pageNum: (page - 1) as number };
      // });
    }
  };

  const handleSort = async (
    selectedColumn: TableColumn<any>,
    sortDirection: SortOrder
  ) => {
    if (selectedColumn.sortField !== undefined) {
      setAccountQueryInfo((info) => {
        return {
          ...info,
          sortType: selectedColumn.sortField as string,
          sortDirection: sortDirection.toUpperCase() as string,
        };
      });
      //setPending(true);
    }
  };

  return (
    <AccountPresenter
      onModal={onModal}
      isAddModal={isAddModal}
      isEditModal={isEditModal}
      columns={columns}
      data={accountList}
      currAccount={currAccount as Account}
      onRowClicked={onRowClicked}
      onClickCreate={onClickCrearte}
      onClickDelete={deleteAccountCb}
      onClickSave={onClickUpdate}
      onClickSearch={onClickSearch}
      accountId={selectedAccount.accountId}
      paginationPerPage={accountQueryInfo.pageLimit}
      queryInfo={accountQueryInfo}
      keyword={accountQueryInfo.keyword}
      onChangePage={onChangePage}
      handleSort={handleSort}
      accountValidate={accountValidate}
      accountValidateMsg={accountValidateMsg}
      isSelf={isSelf}
      status={status}
      onCellClick={onCellClick}
      initialPending={pending}
      isCreateLoading={mutationCreateAccount.isLoading}
      isUpdateLoading={mutationUpdateAccount.isLoading}
    />
  );
}

