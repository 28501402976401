import { useState, useCallback } from "react";
import { useQuery } from "react-query";
import * as s from "styles/ModalCommonStyled";
import BigPopupTemplate from "components/templates/modal/big/BigPopupTemplate";
import { ModalInput } from "components/atoms/input/modal/ModalInput";
import Input from "components/atoms/input/Input";
import TextArea from "components/atoms/input/TextArea";
import BigButton from "components/atoms/buttons/BigButton";

import {
  BasePagination,
  CustomError,
  SORT_DIRECTION,
  Validate,
} from "api/interfaces/commonInterface.interface";
import {
  AddUserDto,
  Group,
  UserDto,
} from "api/interfaces/userInterface.interface";
import { getGroupListForEdit } from "api/userAPI";
import type { Option } from "utils/options";
import { useAppSelector } from "redux/hooks";
import { ProfileAccountInfo } from "api/interfaces/accountInterface.interface";

import SelectsCombo from "components/atoms/select/SelectCombo";
import { MultiValue } from "react-select";
import { isEmpty } from "lodash";
import { checkEmailRegEx } from "utils/regEx";
import { ValidateMsg } from "api/interfaces/commonInterface.interface";
import { ValidateWord } from "components/atoms/text/Text";
import { useIntl } from "react-intl";
import Spinner from "components/atoms/loader/Spinner";
import styled from "styled-components";

interface Props {
  onModal: (type?: string) => void;
  onClickSave: (userDto: AddUserDto, update?: boolean) => void;
  selectedUser: UserDto | undefined;
  onClickDeactive?: (userId: string) => void;
  isLoading: boolean;
}

function AddUser(props: Props): JSX.Element {
  const intl = useIntl();

  const selectedAccount: ProfileAccountInfo = useAppSelector(
    (state) => state.accountSettings
  );

  const [selectGroup, setSelectGroup] = useState<Option[]>([]);

  const [validate, setValidate] = useState<Validate>({
    // lastName: false,
    // firstName: false,
    email: false,
    group: false,
  });
  const [validateMsg, setValidateMsg] = useState<ValidateMsg>({
    // lastName: "",
    // firstName: "",
    email: "",
    group: "",
  });

  const [userDto, setUserInfo] = useState<AddUserDto>({
    userId: "",
    // name: "",
    lastName: "",
    firstName: "",
    email: "",
    description: "",
    userGroups: [],
  });

  const onChangeUserInfo = useCallback((e: React.ChangeEvent<any>) => {
    let {
      target: { name, value },
    } = e;
    if (name === "email") {
      // level 설정 client/server 상위 선택못하게 막아야 함
      setUserInfo((info) => {
        return { ...info, email: (value as string).trim() };
      });
    } else {
      setUserInfo((info) => {
        return { ...info, [name]: value as string };
      });
    }
  }, []);

  const onChangeUserGroup = useCallback((value: MultiValue<Option>) => {
    setUserInfo((info) => {
      // setSelectGroup(value.);
      const onSelectValue = new Array<Group>();
      (value as MultiValue<any>).forEach((option) => {
        const obj = {} as Group;
        obj.groupId = option.value;
        onSelectValue.push(obj);
      });
      return { ...info, userGroups: onSelectValue as Group[] };
    });

    const onSetValue = new Array<Option>();
    (value as MultiValue<any>).forEach((option) => {
      onSetValue.push(option);
    });
    setSelectGroup(onSetValue);
  }, []);

  const queryInfo: BasePagination = {
    total: 0,
    pageNum: 0,
    pageLimit: 1000,
    keyword: "",
    sortType: "name",
    sortDirection: SORT_DIRECTION.ASC,
  };

  const [groups, setGroups] = useState<Option[]>([]);
  const { error } = useQuery(
    ["group", queryInfo],
    () =>
      getGroupListForEdit({
        payload: queryInfo,
        accountId: selectedAccount.accountId,
      }),
    {
      retry: 0,
      refetchOnWindowFocus: false,
      onSuccess: (res: any) => {
        if (res.result === undefined) {
          return;
        }
        setGroups(
          res.result.map((group: any) => {
            const { groupId, name, enableEdit } = group;
            return {
              value: groupId as string,
              label: name,
              isDisabled: enableEdit !== undefined ? !enableEdit : false,
            };
          })
        );

        if (userDto.userGroups !== undefined) {
          const idList = userDto.userGroups.map((group) => group.groupId);

          setSelectGroup(
            res.result
              .map((group: any) => {
                const { groupId, name } = group;
                return {
                  value: groupId as string,
                  label: name,
                };
              })
              .filter((option: any) => idList.includes(option.value))
          );
        }
      },
      onError: (e: any) => {
        console.info(e);
      },
    }
  );

  const onSaveBefore = useCallback(() => {
    setValidate((info) => {
      return {
        ...info,
        // lastName: false,
        // firstName: false,
        email: false,
        group: false,
        companyPhone: false,
        description: false,
      };
    });
    setValidateMsg((info) => {
      return {
        ...info,
        // lastName: "",
        // firstName: "",
        email: "",
        group: "",
        companyPhone: "",
        description: "",
      };
    });

    let checkedFlag = false;

    if (!checkEmailRegEx(userDto.email)) {
      checkedFlag = true;
      setValidate((info) => {
        return { ...info, email: true };
      });
      setValidateMsg((info) => {
        return {
          ...info,
          email: intl.formatMessage({
            id: "validateMsg.user.email",
            defaultMessage: "Enter a valid email address",
          }) as string,
        };
      });
    }
    // if (userDto.firstName === "") {
    //   checkedFlag = true;
    //   setValidate((info) => {
    //     return { ...info, firstName: true };
    //   });
    //   setValidateMsg((info) => {
    //     return {
    //       ...info,
    //       firstName: intl.formatMessage({
    //         id: "validateMsg.user.name",
    //         defaultMessage: "A User name must be entered.",
    //       }) as string,
    //     };
    //   });
    //   return;
    // }
    // if (userDto.lastName === "") {
    //   checkedFlag = true;
    //   setValidate((info) => {
    //     return { ...info, lastName: true };
    //   });
    //   setValidateMsg((info) => {
    //     return {
    //       ...info,
    //       lastName: intl.formatMessage({
    //         id: "validateMsg.user.name",
    //         defaultMessage: "A User name must be entered.",
    //       }) as string,
    //     };
    //   });
    //   return;
    // }

    if (userDto.userGroups === undefined || userDto.userGroups.length === 0) {
      checkedFlag = true;
      setValidate((info) => {
        return { ...info, group: true };
      });
      setValidateMsg((info) => {
        return {
          ...info,
          group: intl.formatMessage({
            id: "validateMsg.user.group",
            defaultMessage: "A User Group must be selected.",
          }) as string,
        };
      });
    }

    if (!isEmpty(userDto.description) && userDto.description.length > 256) {
      setValidate((info) => {
        return { ...info, description: false };
      });
      setValidateMsg((info) => {
        return {
          ...info,
          description: intl.formatMessage({
            id: "validateMsg.profile.description",
            defaultMessage: "Please do not exceed 255 characters.",
          }) as string,
        };
      });
      return;
    }
    if (checkedFlag) {
      return;
    }

    props.onClickSave(userDto, false);
  }, [props, userDto]);

  return (
    <BigPopupTemplate title="Add User" onModal={() => props.onModal("add")}>
      <Inner>
        {props.isLoading && (
          <s.LoadingWrapper>
            <Spinner />
          </s.LoadingWrapper>
        )}
        <s.Row>
          <ModalInput label="Email (ID)" essential half>
            <Input
              name="email"
              autoComplete="off"
              type="search"
              placeholder="Enter this user's email address."
              pattern="[A-Za-z]+"
              maxLength={100}
              value={userDto.email}
              onChange={onChangeUserInfo}
              isValidated={validate.email}
            />
            <ValidateWord>{validateMsg.email}</ValidateWord>
          </ModalInput>
          <ModalInput label="User Groups" essential half>
            <SelectsCombo
              options={groups}
              value={selectGroup}
              placeholder="Select Groups"
              onChange={(value: MultiValue<Option>) => {
                onChangeUserGroup(value);
              }}
            />
            <ValidateWord>{validateMsg.group}</ValidateWord>
          </ModalInput>
        </s.Row>
        <s.Row>
          <ModalInput label="First Name">
            <Input
              name="firstName"
              value={userDto.firstName}
              placeholder="Enter a name for this user."
              maxLength={100}
              onChange={onChangeUserInfo}
              // isValidated={validate.firstName}
            />
            {/* <ValidateWord>{validateMsg.firstName}</ValidateWord> */}
          </ModalInput>
          <ModalInput label="Last Name">
            <Input
              name="lastName"
              value={userDto.lastName}
              placeholder="Enter a name for this user."
              maxLength={100}
              onChange={onChangeUserInfo}
              // isValidated={validate.lastName}
            />
            {/* <ValidateWord>{validateMsg.lastName}</ValidateWord> */}
          </ModalInput>
        </s.Row>

        <ModalInput label="Description" isValidated={validate.description}>
          <TextArea
            name="description"
            value={userDto.description}
            placeholder="Enter description"
            maxLength={200}
            onChange={onChangeUserInfo}
          />
          <ValidateWord>{validateMsg.description}</ValidateWord>
        </ModalInput>

        <s.ButtonWrapper>
          <BigButton label="Save" onClickBtn={onSaveBefore} />
        </s.ButtonWrapper>
      </Inner>
    </BigPopupTemplate>
  );
}

export default AddUser;

const Inner = styled.div`
  position: relative;
  width: 100%;
`;
