import { CustomError } from "api/interfaces/commonInterface.interface";
import { notify } from "components/atoms/notification/Notification";
import { useCallback } from "react";
import { useIntl } from "react-intl";
import { notification } from 'antd';

const useApiError = () => {

    const intl = useIntl();
    
    const handleError = useCallback((restError: CustomError, defaultMessage:string)=> {
        const error = restError.response?.data.error;
        const translationKeyExists = !!intl.messages[`ServerErrorCode.${error}`];
        if(translationKeyExists){
            notify("error", intl.formatMessage({
                id: `ServerErrorCode.${error}`,
                defaultMessage: defaultMessage,
              }));
        }else{
            notify("error", defaultMessage);
        }

    },[intl])

    const handleErrorSubReason = useCallback((restError: CustomError, defaultMessage:string)=> {
        const error = restError.response?.data.error;
        const translationKeyExists = !!intl.messages[`ServerErrorCode.${error}`];
        if(translationKeyExists){
              notification.error({
                message: defaultMessage,
                description : intl.formatMessage({
                    id: `ServerErrorCode.${error}`,
                    defaultMessage: defaultMessage,
                  }),
                placement : "topRight"
            });  
        }else{
            notify("error", defaultMessage);
        }

    },[intl]);

    const displayError = useCallback((restError: CustomError, defaultMessage:string)=> {
        const error = restError.response?.data.error;
        const translationKeyExists = !!intl.messages[`ServerErrorCode.${error}`];
        if(translationKeyExists){
            return intl.formatMessage({
                id: `ServerErrorCode.${error}`,
                defaultMessage: defaultMessage,
              });
        }else{
            return defaultMessage;
        }
    },[intl])

    return {handleError, handleErrorSubReason, displayError}  as const;
};

export default useApiError;