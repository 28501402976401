import * as s from "../TableItemStyled";
import { useState } from "react";
import { useQuery } from "react-query";
import { useIntl } from "react-intl";
import { ProfileAccountInfo } from "api/interfaces/accountInterface.interface";
import { useAppSelector } from "redux/hooks";
import {
  AssignModalType,
  commonPaginationPerPage,
} from "pages/dashboard/dashboard/WidgetContainer";
import {
  DashbaordAlertSendMessage,
  DashboarAlertLatest,
} from "api/interfaces/dashboardInterface.interface";
import { postDashboardAlertLatest } from "api/dashboardAPI";
import Alerts from "pages/dashboard/common/alerts";
import SendPhonePopup from "components/templates/modal/small/phone/SendPhonePopup";
import SendOneEmailPopup from "components/templates/modal/small/email/SendOneEmailPopup";
import AccountSelects from "../AccountSelects";
import { useMediaQuery } from "react-responsive";
import { desktop } from "components/MediaQuery";
import TableItemHeader from "../TableItemHeader";
import { Layout } from "react-grid-layout";
import { TableItemName } from "../TableItemList";
import SearchInput from "components/atoms/input/search/SearchInput";

interface Props {
  layout: Layout;
  chartLayout: Layout[];
  onDeleteWidget: (key: TableItemName) => void;
  onLayoutChange: (layout: Layout[]) => void;
}

export default function AlertFeed(props: Props): JSX.Element {
  const isDesktop = useMediaQuery({ minWidth: desktop });
  const intl = useIntl();
  const selectedAccount: ProfileAccountInfo = useAppSelector(
    (state) => state.accountSettings
  );
  const [accountIds, setAccountIds] = useState<string[]>([]);
  const [alertsData, setAlertsData] = useState<DashboarAlertLatest[]>([]);
  const [isAssignModal, setIsAssignModal] = useState<AssignModalType>({
    phone: false,
    email: false,
  });
  const [assignInfo, setAssignInfo] = useState<{ [key: string]: string }>({
    phone: "",
    email: "",
  });

  const [keyword, setKeyword] = useState<string>("");

  const [selectedAlertItem, setSelectedAlertItem] =
    useState<DashbaordAlertSendMessage>({
      alertRuleId: "",
      recorderId: "",
    });
  const getAlertLatest = useQuery(
    ["getAlertLatest", selectedAccount, accountIds, keyword],
    () =>
      postDashboardAlertLatest({
        accountId: selectedAccount.accountId,
        maxCount: isDesktop ? 30 : 5,
        payload: {
          accountIds,
          keyword,
        },
      }),
    {
      retry: 0,
      // refetchInterval: 10000,
      refetchOnWindowFocus: false,
      onSuccess: (res: any) => {
        // console.log(res, "res ---");
        if (res.result) {
          // console.log(res.result, "alert latest");
          setAlertsData(res.result);
        } else {
          setAlertsData([]);
        }
      },
      onError: (e: any) => {
        console.log(e, "error");
      },
    }
  );

  const onAssignModal = (type: string, value?: string) => {
    if (value !== undefined) {
      setAssignInfo({
        ...assignInfo,
        [type]: value,
      });
    }

    setIsAssignModal({
      ...isAssignModal,
      [type]: !isAssignModal[type],
    });
  };

  const onSelectItem = (item: DashboarAlertLatest) => {
    setSelectedAlertItem({
      alertRuleId: item.alertRuleId,
      recorderId: item.recorderId,
    });
  };

  const onChangeAccount = (value: string[]) => {
    setAccountIds(value);
  };

  const onClickSearch = (keyword: string) => {
    setKeyword(keyword);
  };

  return (
    <>
      <TableItemHeader
        layout={props.layout}
        chartLayout={props.chartLayout}
        onDeleteWidget={props.onDeleteWidget}
        onLayoutChange={props.onLayoutChange}
        isError={getAlertLatest.isError}
      />
      <s.Contents className="drag-cancel-content">
        <s.ContentWrapper>
          <s.ContentHeader>
            <div>
              <AccountSelects onChange={onChangeAccount} value={accountIds} />
            </div>
            <div className="dashboard-search-input">
              <SearchInput onClickSearch={onClickSearch} />
            </div>
          </s.ContentHeader>
          <s.ContentInner>
            <Alerts
              noScroll
              alertsData={alertsData}
              onAssignModal={onAssignModal}
              onSelectItem={onSelectItem}
            />
          </s.ContentInner>
        </s.ContentWrapper>
      </s.Contents>
      {isAssignModal.email && (
        <SendOneEmailPopup
          onModal={() => onAssignModal("email")}
          value={assignInfo.email}
          recorderId={selectedAlertItem.recorderId}
        />
      )}

      {isAssignModal.phone && (
        <SendPhonePopup
          onModal={() => onAssignModal("phone")}
          value={assignInfo.phone}
          alertRuleId={selectedAlertItem.alertRuleId}
          recorderId={selectedAlertItem.recorderId}
        />
      )}
    </>
  );
}
