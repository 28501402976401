import * as s from "./SystemSpecStyled";

import Guage from "./Guage";

import { useLocation } from "react-router-dom";
import { Labels } from "react-gauge-component/dist/lib/GaugeComponent/types/Labels";
import { useAppSelector } from "redux/hooks";
import { COLORS } from "styles/colors";

export interface GuageListInterface {
  title: string;
  value: number;
}

type Props = {
  guageList: GuageListInterface[];
};

export default function GuageList(props: Props): JSX.Element {
  const location = useLocation();
  const isMap = location.pathname.includes("map");
  const theme = useAppSelector((state) => state.theme.theme);
  return (
    <div className="right inner-box guage-wrapper">
      {props.guageList.map((guage) => {
        return (
          <s.GuageBox>
            <Guage
              style={guageStyles(isMap)}
              title={guage.title}
              value={guage.value}
              labels={guageLabels(isMap, theme) as Labels}
            />
            <span>{guage.title}</span>
          </s.GuageBox>
        );
      })}
    </div>
  );
}

const guageStyles = (isMap: boolean) => {
  return isMap
    ? {
        width: 150,
      }
    : {
        width: "100%",
      };
};

const guageLabels = (isMap: boolean, theme: string) => {
  return isMap
    ? {
        valueLabel: {
          // formatTextValue: (value) => `${value}%`,
          style: {
            fill: theme === "light" ? `${COLORS.BLACK}90` : COLORS.WHITE,
            textShadow: theme === "light" && "unset",
            fontSize: 28,
            fontWeight: 500,
          },
        },
        tickLabels: {
          type: "inner",
          ticks: [{ value: 50 }, { value: 100 }],
        },
      }
    : {
        valueLabel: {
          // formatTextValue: (value) => `${value}%`,
          style: {
            fill: theme === "light" ? `${COLORS.BLACK}90` : COLORS.WHITE,
            textShadow: theme === "light" && "unset",
            fontSize: 20,
            fontWeight: 500,
          },
        },
        tickLabels: {
          type: "inner",
          ticks: [
            { value: 20 },
            { value: 40 },
            { value: 60 },
            { value: 80 },
            { value: 100 },
          ],
        },
      };
};
