import { Table } from "components/atoms/table/Table";
import { SortOrder, TableColumn } from "react-data-table-component";
import { TableTemplate } from "components/templates/default/table/TableTemplate";
import AddModal from "./add";
import { ClickSearch } from "components/atoms/input/search/SearchInput";
import { Notice, NoticeInfo } from "api/interfaces/noticeInterface.interface";
import { BasePagination, ValidateMsg } from "api/interfaces/commonInterface.interface";
import Status, { StatusType } from "components/blocks/status/Status";

export type Props = {
  isAddModal: boolean;
  isEditModal: boolean;
  onModal: (type?: string) => void;
  columns: TableColumn<NoticeInfo>[];
  data: Array<NoticeInfo>;
  onClickSearch: ClickSearch;
  onRowClicked: (row: NoticeInfo) => void;
  selectedNotice: NoticeInfo | undefined;
  onChangePage: (page: number, totalRows: number) => void;
  handleSort: (
    selectedColumn: TableColumn<any>,
    sortDirection: SortOrder
  ) => void;
  paginationInfo : BasePagination;
  status: StatusType;
  tablePending: boolean;
};

export function NoticePresenter({
  isAddModal,
  isEditModal,
  onModal,
  columns,
  data,
  selectedNotice,
  onClickSearch,
  onRowClicked,
  handleSort,
  onChangePage,
  paginationInfo,
  status,
  tablePending
}: Props): JSX.Element {
  return (
    <>
      <TableTemplate
        onClickBtn={() => onModal("add")}
        buttonLabel="Add Notice"
        onClickSearch={onClickSearch}
        keyword={paginationInfo.keyword}
        titleComponent="Site Notices"
        centerChildren={<Status status={status} />}
      >
        <Table columns={columns} data={data} onRowClicked={onRowClicked} 
        paginationPerPage={paginationInfo.pageLimit}
        paginationTotalRows={status.totalValue}
        onChangePage={onChangePage}
        handleSort={handleSort}
        progressPending={tablePending}
        />
      </TableTemplate>
      {isAddModal && (
        <AddModal onModal={() => onModal("add")} />
      )}
      {isEditModal && (
        <AddModal
          isEdit
          onModal={() => onModal("edit")}
          selectedNotice={selectedNotice}
        />
      )}
    </>
  );
}
