import * as s from "../CommonStyled";
import AlertItem from "./AlertItem";

import {
  DashbaordAlertSendMessage,
  DashboarAlertLatest,
} from "api/interfaces/dashboardInterface.interface";

type DualProps = {
  titleHeight?: number;
  noScroll?: boolean;
  alertsData: DashboarAlertLatest[];
  onAssignModal: (type: string, info: string) => void;
  onSelectItem: (item: DashboarAlertLatest) => void;
};

const Alerts = (props: DualProps): JSX.Element => {
  return (
    <s.Scroll>
      {/* 정렬 순서 : update 된 시간 */}
      {props.alertsData.map((data, index) => (
        <AlertItem
          data={data}
          key={`dashboard-alert-item-${index}`}
          onAssignModal={props.onAssignModal}
          onSelectItem={props.onSelectItem}
        />
      ))}
    </s.Scroll>
  );
};

export default Alerts;
