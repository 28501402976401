import { Route, Routes, useLocation } from "react-router-dom";
import Health from "./health";
import Inventory from "./inventory";
import Cvv from "./cvv";
import Installaion from "./installation";
import Thumbnail from "./thumbnail";
import Timelapse from "./timelapse";

type Props = {};

export default function ViewReport(props: Props): JSX.Element {
  const location = useLocation();

  const state = location.state;

  if (location.pathname.includes("health")) return <Health state={state} />;
  if (location.pathname.includes("inventory"))
    return <Inventory state={state} />;
  if (location.pathname.includes("cvv")) return <Cvv state={state} />;
  if (location.pathname.includes("installation"))
    return <Installaion state={state} />;
  if (location.pathname.includes("thumbnail"))
    return <Thumbnail state={state} />;
  if (location.pathname.includes("timelapse")) return <Timelapse />;
  else return <Health state={state} />;
}
