import styled from "styled-components";
import { COLORS, LIGHT_THEME_COLORS } from "styles/colors";
import { FLEX } from "styles/flex";
import * as mediaQuery from "components/MediaQuery";
import { Modal, Inner } from "../ModalTemplateStyled";
import { calcRem } from "styles/theme";
import { AccountItemScrollButton } from "pages/account/AccountStyled";

export const Inners = styled(Inner)`
  width: 95%;
  padding: 10px 0;
  /* width: 100%; */

  ${mediaQuery.isMobile} {
    padding: 10px;
  }
`;

export const AlertViewNoData = styled.div`
  width: 100%;
  height: 100%;
  flex: 1;
  ${FLEX.FlexStartCenter};
  flex-direction: column;
  padding: 50px 0;
  color: ${COLORS.LIGHTGRAY};
  gap: 10px;
  font-weight: 500;
  font-size: ${calcRem(22)};

  /* img {
    width: 15%;
  } */
`;

export const AlertMapViewWrapper = styled.div`
  width: 100%;
  height: 100%;
  ${FLEX.FlexStartStart};
  /* gap: 14px; */
  flex-direction: column;
  padding: 0 14px;
  flex: 1;
`;
/**
 * Alert View Ppup
 */
export const AlertModal = styled(Modal)`
  border-radius: 15px;
  padding: 0;
  padding-bottom: 30px;
  ${mediaQuery.isDefault} {
    min-width: 960px;
  }
  ${mediaQuery.isMobile} {
    overflow: hidden;
    .bg-blue {
      padding: 0;
    }
  }
`;

export const CloseButton = styled.button`
  ${mediaQuery.isDefault} {
    position: absolute;
    top: 20px;
    right: 20px;
  }

  ${mediaQuery.isMobile} {
    > img {
      height: 13px;
    }
  }
`;
export const AlertModalHeader = styled.div`
  width: 100%;
  background-color: ${({ theme }) => theme.COLORS.PRIMARY3};
  position: relative;

  ${mediaQuery.isDefault} {
    padding: 8px 0 10px;
  }

  ${mediaQuery.isMobile} {
    ${FLEX.FlexStartStart};
    flex-direction: column;
    gap: 4px;
    padding: 8px 20px;
  }
`;

export const HeaderInner = styled(Inners)`
  ${FLEX.FlexBetweenCenter};
  flex-direction: row;
  margin: 0 auto;
  padding-right: 30px;

  ${mediaQuery.isMobile} {
    padding: 0;

    .wep-app-link {
      ${FLEX.FlexEndCenter};
      gap: 8px;
    }
  }
`;

export const HeaderSub = styled.div`
  ${FLEX.FlexStartStart};
  gap: 8px;
  flex: 1;
  width: 100%;

  .web-app-link-wrapper {
    flex: 0 0 auto;
  }
  .system-title {
    display: flex;
    flex: 1;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    flex-direction: column;
    gap: 6px;
  }
  .system-name {
    flex: 1;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .server-name {
    flex: 1;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .title-wrapper {
    ${FLEX.FlexStartCenter};
    gap: 8px;
    align-items: baseline;
    flex: 1;
    width: 100%;
  }
`;

export const ActionInComment = styled.span`
  color: #828b9b;
  font-size: ${calcRem(12)};
  padding-left: 15px;

  > strong {
    font-weight: 500;
  }
`;

export const InnerContents = styled.div`
  width: 100%;
  ${FLEX.FlexStartStart};
  flex-direction: column;
  gap: 10px;
`;

export const CommmonWrapper = styled.div<{ isMap: boolean }>`
  width: 100%;
  color: ${({ theme }) => theme.COLORS.WHITE};

  input {
    background: transparent;
    color: ${({ theme }) => theme.COLORS.WHITE};
  }

  .subtitle {
    font-size: ${calcRem(16)};
    font-weight: 400;
  }

  .select-box {
    max-width: 120px;
    max-height: 25px;
    ${FLEX.FlexEndCenter};
  }

  .column-box {
    ${FLEX.FlexStartStart};
    flex-direction: column;
    gap: 8px;
    width: 100%;
  }

  ${({ isMap }) =>
    isMap
      ? `
      padding: 18px 0;

      .column-box {
        gap: 6px;
      }
      `
      : `
      padding: 14px 20px;
      border-radius: 6px;
    `}
`;
export const Edit = styled.span`
  ${FLEX.FlexEndCenter};
  gap: 6px;
  cursor: pointer;
  color: ${({ theme }) => theme.COLORS.SELECTED};
  /* flex: 1; */

  img {
    height: 14px;
  }
  ${mediaQuery.isMobile} {
    gap: 4px;
    img {
      height: 10px;
    }
  }
`;

export const AlertStatusStyled = {
  AlertStatusWrapper: styled.div`
    width: 100%;
    ${FLEX.FlexBetweenCenter};
    align-items: baseline;
    padding: 10px 0 20px;
  `,
  AlertStatusChange: styled.div`
    ${FLEX.FlexEndCenter};
    gap: 8px;

    span {
      color: ${COLORS.WHITE};
      font-size: ${calcRem(14)};
      white-space: nowrap;
    }
  `,
};

// AlertInfo
export const AlertInfoStyled = {
  AlertInfoWrapper: styled(CommmonWrapper)`
    ${FLEX.FlexBetweenStart};

    .causes {
      color: ${COLORS.RED};
    }

    .center {
      gap: 20px;
    }

    ${({ isMap, theme }) =>
      isMap
        ? `
        flex-direction: column;
        border-bottom: 1px solid ${COLORS.BORDER};

        .center {
          flex-direction: row;
          margin-bottom: 14px;
        }
      `
        : `
    background-color: ${theme.COLORS.PRIMARY3};    

    `}

    ${mediaQuery.isMobile} {
      flex-direction: column;
      gap: 10px;
    }
  `,
  AlertInfoItem: styled.li<{ isMap?: boolean }>`
    flex: 1;
    font-size: ${calcRem(14)};
    width: 100%;

    .subtitle {
      width: 100%;
      ${FLEX.FlexBetweenCenter};

      ${({ isMap }) =>
        isMap &&
        `
        justify-content:flex-start;
        gap: 10px;
      `}

      ${mediaQuery.isMobile} {
        justify-content: flex-start;
        gap: 8px;
      }
    }

    ul {
      ${FLEX.FlexStartStart};
      flex-direction: column;
      gap: 4px;
      width: 100%;

      > li {
        width: 100%;
        ${FLEX.FlexBetweenCenter};

        .ruleName {
          flex: 1;
        }
      }
    }
  `,
};

// TraceList

export const TraceListStyled = {
  TraceListWrapper: styled(CommmonWrapper)`
    ${FLEX.FlexStartStart};
    flex: 1;
    flex-direction: column;
    gap: 30px;
    width: 100%;

    .assign {
      width: 220px;
      max-width: 220px;
    }

    ${({ isMap, theme }) =>
      !isMap &&
      `
    background-color:  ${theme.COLORS.PRIMARY3}60;
    `}

    ${mediaQuery.isMobile} {
      gap: 10px;
    }
  `,
  AlertTraceInputWrapper: styled.div`
    ${FLEX.FlexStartCenter};
    flex: 3;
    gap: 6px;
    width: 100%;

    input {
      /* max-width: 280px; */
    }
  `,
  TraceList: styled.div`
    width: 100%;
    flex: 1;
    ${FLEX.FlexStartStart};
    flex-direction: column;
  `,
  AssignWrapper: styled.div<{ isMap: boolean }>`
    ${FLEX.FlexStartStart};
    width: 100%;
    gap: 16px;
    ${({ isMap }) =>
      isMap
        ? `
      flex-direction: column;
    `
        : `
    `}

    .technician {
      flex: 3;

      /* ${({ isMap }) =>
        !isMap &&
        `
          padding-right: 16px;
        `} */
    }

    .assignto {
      flex: 1;
      ${({ isMap }) =>
        isMap
          ? `

          border-bottom: 1px solid ${COLORS.BORDER};
        `
          : `
          padding-left: 16px;
          border-left: 1px solid ${COLORS.BORDER};
        `}
    }

    ${mediaQuery.isMobile} {
      flex-direction: column;

      .assignto {
        border: none;
        padding: 0;
      }
    }
  `,
  TraceTitle: styled.div<{ isMap: boolean }>`
    width: 100%;

    ${FLEX.FlexBetweenCenter};
    margin-bottom: 10px;

    .subtitle {
      flex: 1;
    }

    ${({ isMap }) =>
      isMap &&
      `
      flex-direction: column;
      align-items: flex-start;
      gap: 6px;
    `}

    ${mediaQuery.isMobile} {
      flex-direction: column;
      align-items: flex-start;
      gap: 8px;
    }
  `,
  AssignBox: styled.div`
    ${FLEX.FlexBetweenCenter};
    width: 100%;
    height: 33px;
    line-height: 33px;
    gap: 8px;

    > .assignee-name {
      flex: 1;
      width: 100%;
      max-width: 160px;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  `,

  AssignButtonWrapper: styled.div`
    ${FLEX.FlexEndCenter};
    gap: 8px;

    button {
      color: ${({ theme }) => theme.COLORS.SELECTED};
    }
  `,
  AlertTraceItemWrapper: styled.ul`
    width: 100%;
    height: calc(100% - 55px);
    max-height: calc(100% - 55px);
    overflow-y: auto;
    flex: 1;
    ${FLEX.FlexStartStart};
    flex-direction: column;

    > li {
      width: 100%;
      border-bottom: 1px solid ${COLORS.BORDER};
      padding: 8px 0;
      color: ${({ theme }) => COLORS.LIGHTGRAY};

      .title-wrapper {
        ${FLEX.FlexBetweenCenter};
      }

      .status {
        padding-top: 8px;
        font-size: ${calcRem(14)};
        * {
          display: inline;
        }
      }

      .trace-comment {
        padding-top: 4px;
        ${FLEX.FlexStartStart};
        gap: 6px;
        font-size: ${calcRem(14)};

        .comment {
          color: ${({ theme }) => theme.COLORS.WHITE};
        }
      }
    }
  `,
};

// Alert Type List
export const AlertTypeListStyled = {
  Wrapper: styled.div`
    width: 100%;
    /* border: 1px solid red; */

    .left-button {
      left: 0;
      right: unset;
    }

    .react-multi-carousel-track {
      gap: 10px;
      align-items: center;
    }
    .alert-item {
      flex: unset !important;
      width: unset !important;
    }
  `,
  AlertItem: styled.div<{ selected?: boolean; isMap: boolean }>`
    color: ${({ theme }) => theme.COLORS.WHITE};
    padding: 16px 14px;
    ${FLEX.FlexBetweenEnd};
    flex-direction: column;
    border-radius: 6px;
    background-color: ${({ theme, isMap }) =>
      isMap
        ? theme.value === "light"
          ? "#fafafa"
          : theme.COLORS.PRIMARY1
        : theme.COLORS.PRIMARY3};
    cursor: pointer;

    min-width: 240px;
    min-height: 120px;

    .title {
      width: 100%;
      ${FLEX.FlexStartCenter};
      gap: 6px;

      span {
        font-size: ${calcRem(17)};
        font-weight: 400;
      }
    }

    ${({ selected }) =>
      selected &&
      `
      min-width: 250px;
      min-height: 130px;
      border: 3px solid ${COLORS.RED};

      .title {
        gap: 8px;
        // ${FLEX.FlexStartCenter};


        span {
          font-size: ${calcRem(18)};
        }
      }
      
    `}

    ${({ isMap }) =>
      isMap &&
      `
        min-width: 180px;
        min-height: 120px;
        padding: 12px;


        .title {
          // > svg {
          //   width: 15px;
          // }
          span {
            font-size: ${calcRem(16)};
          }
        }
      `}
  `,
  TitleWrapper: styled.div<{ isMap: boolean }>`
    ${FLEX.FlexBetweenCenter};
    width: 100%;

    .alert-type-icon {
      ${({ isMap }) =>
        isMap
          ? `
      height: 18px;
      `
          : `
      height: 22px;
      `}
      ${FLEX.FlexStartStart};

      > img {
        height: 100%;
      }
    }
  `,
  SelectWrapper: styled.div<{ isMap: boolean }>`
    ${FLEX.FlexBetweenCenter};
    width: 100%;
    gap: 8px;

    .video {
      ${({ isMap }) =>
        isMap
          ? `
          min-width: 100px;
          max-width: 100px;
      `
          : `
          min-width: 120px;
          max-width: 120px;
      `}
    }
  `,
  VideoLossThumbnail: styled.div`
    width: 82px;
  `,
  ScrollButton: styled(AccountItemScrollButton)``,
};
