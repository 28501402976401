import { useCallback, useEffect, useMemo, useState } from "react";
import { useQuery } from "react-query";
import * as s from "styles/ModalCommonStyled";
import SearchInput from "components/atoms/input/search/SearchInput";
import { useIntl } from "react-intl";
import {
  GetAccountListQuery,
  SORT_TYPE,
} from "api/interfaces/accountInterface.interface";
import { getAccoountList, getAccoountTotalList } from "api/accountAPI";
import { Account, getAccountListMappger } from "api/mappers/accountMapper";
import { IndeterminateCheckbox, VGroupTable } from "./comp/VGroupTable";
import { ColumnDef, Row, RowSelectionState } from "@tanstack/react-table";
import DebounceSearchInput from "components/atoms/input/search/DebounceSearchInput";
import React from "react";

export type SearchAccountProps = {
  accountId: string;
  placeholder?: string;
  selectedAccountId?: string[];
  disabledAccountId?: string[];
  isSingleMode?: boolean;
  onSelectedRowsChange?: (selectedRows: string[]) => void;
  disabled?: boolean;
  allSelect?: boolean;
  userId : string | undefined
};

export interface DataRow {
  id: string;
  name: string;
  level: string;
  levelFullName: string;
  accounts: number;
  recorders: number;
}

export function SearchAccount(props: SearchAccountProps): JSX.Element {
  const intl = useIntl();

  const [accounts, setAccounts] = useState<Account[]>([]);
  const [selectAccount, setSelectAccount] = useState<number>(0);
  const [selectedCombine, setSelectedCombine] = useState<RowSelectionState>({});
  const [searchText, setSearchText] = useState<string>("");

  const columns = React.useMemo<ColumnDef<any>[]>(
    () => [
      {
        accessorKey: "name",
        size: 300,
        header: ({ table }) => (
          <>
            <IndeterminateCheckbox
              {...{
                checked: table.getIsAllRowsSelected(),
                indeterminate: table.getIsSomeRowsSelected(),
                onChange: table.getToggleAllRowsSelectedHandler(),
                disabled: props.disabled,
              }}
            />{" "}
            {/* <button
                {...{
                  onClick: table.getToggleAllRowsExpandedHandler(),
                }}
              >
                {table.getIsAllRowsExpanded() ? "👇" : "👉"}
              </button>{" "} */}
            Name
          </>
        ),
        cell: ({ row, getValue }) => (
          <div
            style={
              {
                // Since rows are flattened by default,
                // we can use the row.depth property
                // and paddingLeft to visually indicate the depth
                // of the row
                // paddingLeft: `${row.depth * 2}rem`,
              }
            }
          >
            <>
              <IndeterminateCheckbox
                {...{
                  checked: row.getIsSelected(),
                  indeterminate: row.getIsSomeSelected(),
                  onChange: row.getToggleSelectedHandler(),
                  disabled: props.disabled,
                }}
              />{" "}
              {row.getCanExpand() ? (
                <button
                  {...{
                    onClick: row.getToggleExpandedHandler(),
                    style: { cursor: "pointer" },
                  }}
                >
                  {row.getIsExpanded() ? "🔽" : "▶️"}
                </button>
              ) : (
                " "
              )}{" "}
              {getValue()}
            </>
          </div>
        ),
        minSize: 300,
        footer: (props) => props.column.id,
      },
      {
        accessorKey: "level",
        header: () =>
          intl.formatMessage({
            id: "table.label.level",
            defaultMessage: "Level",
          }),
        size: 80,
      },
      {
        accessorKey: "accounts",
        header: intl.formatMessage({
          id: "table.label.accountCnt",
          defaultMessage: "Organizations",
        }),
        size: 80,
      },
      {
        accessorKey: "recorders",
        header: intl.formatMessage({
          id: "table.label.recorderCnt",
          defaultMessage: "Recorders",
        }),
        size: 80,
      },
    ],
    []
  );

  const [accountQueryInfo, setAccountQueryInfo] = useState<GetAccountListQuery>(
    {
      pageNum: 0,
      pageLimit: 2000,
      keyword: "",
      sortType: "name",
      sortDirection: SORT_TYPE.DESC,
    }
  );

  const { isLoading, data, error, refetch } = useQuery(
    ["accountSearch", props.userId],
    () =>
      getAccoountTotalList({
        accountId: props.accountId,
        userId: props.userId,
      }),
    {
      retry: 0,
      refetchOnWindowFocus: false,
      onSuccess: (res: any) => {
        if (res.result === undefined) {
          setAccounts([]);
          return;
        } else {
          setAccounts(getAccountListMappger(res.result));
        }

        let tmpCombine = {};
        res.result.forEach((account: any, index: number) => {
          if (props.selectedAccountId?.includes(account.accountId)) {
            Object.assign(tmpCombine, { [account.accountId]: true });
          }
        });
        setSelectedCombine(tmpCombine);
      },
      onError: (e: any) => {
        setAccounts([]);
      },
    }
  );

  // useEffect(()=>{
  //   let tmpCombine ={};
  //   accounts.forEach((account, index:number) =>{
  //     if(props.selectedAccountId?.includes(account.accountId)){
  //       Object.assign(tmpCombine,{ [index] : true} )
  //     }
  //   });
  //   setSelectedCombine(tmpCombine);
  // },[accounts]);

  const accountList = useMemo((): DataRow[] => {
    return Array.from(accounts).map((account) => {
      const {
        name,
        level,
        levelFullName,
        hasAccounts,
        hasRecorders,
        accountId,
      } = account;
      return {
        name,
        level,
        levelFullName,
        accounts: hasAccounts,
        recorders: hasRecorders,
        id: accountId,
      };
    });
  }, [accounts]);

  useEffect(() => {
    if (props.allSelect) {
      let checkedRecorder: string[] = accounts.map(
        (account) => account.accountId as string
      );
      if (props.onSelectedRowsChange) {
        props.onSelectedRowsChange(checkedRecorder);
      }
    }
  }, [props.allSelect, isLoading]);

  const handleChange = useCallback((row: RowSelectionState) => {
    // You can set state or dispatch with something like Redux so we can use the retrieved data
    let checkedRecorder: string[] = [];
    const selectKeys = Object.keys(row);

    if (selectKeys.length > 0) {
      selectKeys.forEach((select) => {
        if (!select.endsWith("merged")) {
          checkedRecorder.push(select);
        }
      });
    }
    if (props.onSelectedRowsChange) {
      props.onSelectedRowsChange(checkedRecorder);
    }
  }, []);

  const onClickSearch = (keyword: string) => {
    setAccountQueryInfo((info) => {
      return { ...info, keyword: keyword as string };
    });
    refetch();
  };

  const onChangeSearchText = useCallback((value: string) => {
    setSearchText(value);
  }, []);

  return (
    <>
      <DebounceSearchInput
        placeholder={props.placeholder}
        onClickSearch={onClickSearch}
        onChange={onChangeSearchText}
      />
      <s.ModalTableContainer>
        <VGroupTable
          columns={columns}
          data={accountList}
          selectedRow={selectedCombine}
          search={searchText}
          onSelectedRowsChange={handleChange}
        />
      </s.ModalTableContainer>
    </>
  );
}
