import { AxiosResponse } from "axios";
import qs from "qs";
import { AxiosInstance } from "./axiosInstance";
import {
  CreateEmailRequest,
  GetEmailListResposne,
  GetEmailRecipientListResposne,
  GetEmailRequest,
  UpdateEmailRequest,
} from "./interfaces/emailInterface.interface";
import { GET_EMAIL_LIST } from "./mocks/emailMock";

const API_PATH = "/setting/email";

export const deleteEmail = async ({
  accountId,
  emailId,
}: {
  accountId: string;
  emailId: string;
}): Promise<void> => {
  try {
    await AxiosInstance.delete(`${API_PATH}/delete/${accountId}/${emailId}`);
  } catch (e) {
    console.error(e);
    throw new Error();
  }
};

export const getEmailList = async ({
  payload,
  isSchedule,
  accountId,
}: {
  payload: GetEmailRequest;
  isSchedule?: boolean;
  accountId: string;
}): Promise<GetEmailListResposne> => {
  try {
    const res: AxiosResponse<GetEmailListResposne> = await AxiosInstance.get(
      `${API_PATH}/list/${accountId}?${qs.stringify(
        payload
      )}&isSchedule=${isSchedule}`
    );
    return res.data;
  } catch (e) {
    console.error(e);
    throw new Error();
  }
};

export const getEmailRecipientList = async ({
  payload,
  emailId,
  accountId,
}: {
  payload: GetEmailRequest;
  emailId: string;
  accountId: string;
}): Promise<GetEmailRecipientListResposne> => {
  try {
    const res: AxiosResponse<GetEmailRecipientListResposne> =
      await AxiosInstance.get(
        `${API_PATH}/list/detail/${accountId}/${emailId}?${qs.stringify(
          payload
        )}`
      );
    return res.data;
  } catch (e) {
    console.error(e);
    throw new Error();
  }
};

export const postEmail = async ({
  payload,
  accountId,
}: {
  payload: CreateEmailRequest;
  accountId: string;
}): Promise<void> => {
  try {
    await AxiosInstance.post(`${API_PATH}/create/${accountId}`, payload);
  } catch (e) {
    console.error(e);
    throw new Error();
  }
};

export const putEmail = async ({
  payload,
  accountId,
  emailId,
}: {
  payload: UpdateEmailRequest;
  accountId: string;
  emailId: string;
}): Promise<void> => {
  try {
    await AxiosInstance.put(
      `${API_PATH}/update/${accountId}/${emailId}`,
      payload
    );
  } catch (e) {
    console.error(e);
    throw new Error();
  }
};
