import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  DashboardAlertData,
  DashboardData,
  DashboardReduxData,
} from "api/interfaces/dashboardInterface.interface";
import { changeDashboardDataFormat } from "utils/DashboardUtil";
import { getDuplicateCount } from "utils/functions";

const initialState: DashboardReduxData = {
  spectrum: {
    systemAlert: 0,
    diskAlert: 0,
    videoAlert: 0,
    alertList: [],
    recorderCount: 0,
  },
  nvr: {
    systemAlert: 0,
    diskAlert: 0,
    videoAlert: 0,
    alertList: [],
    recorderCount: 0,
  },
  dvr: {
    systemAlert: 0,
    diskAlert: 0,
    videoAlert: 0,
    alertList: [],
    recorderCount: 0,
  },
  recorderList: [],
};

// 사용자 로그인 후 Account 정보와 선택정보를 전역으로 관리.

export const dashboardDataSlice = createSlice({
  name: "dashboardData",
  initialState,
  reducers: {
    setDashboardAlertData(
      state,
      action: PayloadAction<{
        spectrum: DashboardAlertData[];
        nvr: DashboardAlertData[];
        dvr: DashboardAlertData[];
      }>
    ) {},
    setDashboardData(state, action: PayloadAction<DashboardData[]>) {
      if (action.payload === undefined || action.payload === null) {
        return initialState;
      } else {
        let newState: DashboardReduxData = {
          spectrum: {
            systemAlert: 0,
            diskAlert: 0,
            videoAlert: 0,
            alertList: [],
            recorderCount: 0,
          },
          nvr: {
            systemAlert: 0,
            diskAlert: 0,
            videoAlert: 0,
            alertList: [],
            recorderCount: 0,
          },
          dvr: {
            systemAlert: 0,
            diskAlert: 0,
            videoAlert: 0,
            alertList: [],
            recorderCount: 0,
          },
          recorderList: [],
        };
        action.payload.forEach((recorder: DashboardData, index) => {
          const recorderType =
            recorder.type !== undefined ? recorder.type.toUpperCase() : "";

          switch (recorderType) {
            case "SPECTRUM":
              newState.spectrum.recorderCount =
                newState.spectrum.recorderCount + 1;
              newState.spectrum.systemAlert =
                newState.spectrum.systemAlert + recorder.system;
              newState.spectrum.diskAlert =
                newState.spectrum.diskAlert + recorder.disk;
              newState.spectrum.videoAlert =
                newState.spectrum.videoAlert + recorder.video;
              newState.spectrum.alertList.push(
                ...recorder.alert,
                ...recorder.assign,
                ...recorder.critical
              );
              newState.recorderList.push(recorder);
              break;
            case "NVR":
              newState.nvr.recorderCount = newState.nvr.recorderCount + 1;
              newState.nvr.systemAlert =
                newState.nvr.systemAlert + recorder.system;
              newState.nvr.diskAlert = newState.nvr.diskAlert + recorder.disk;
              newState.nvr.videoAlert =
                newState.nvr.videoAlert + recorder.video;
              newState.nvr.alertList.push(
                ...recorder.alert,
                ...recorder.assign,
                ...recorder.critical
              );
              newState.recorderList.push(recorder);
              break;
            case "DVR":
              newState.dvr.recorderCount = newState.dvr.recorderCount + 1;
              newState.dvr.systemAlert =
                newState.dvr.systemAlert + recorder.system;
              newState.dvr.diskAlert = newState.dvr.diskAlert + recorder.disk;
              newState.dvr.videoAlert =
                newState.dvr.videoAlert + recorder.video;
              newState.dvr.alertList.push(
                ...recorder.alert,
                ...recorder.assign,
                ...recorder.critical
              );
              newState.recorderList.push(recorder);
              break;
            default:
              break;
          }
        });
        return newState;
      }
    },
    setDashboardDataInit(state) {
      return initialState;
    },
  },
});

export const { setDashboardAlertData, setDashboardData, setDashboardDataInit } =
  dashboardDataSlice.actions;

export default dashboardDataSlice;
