import * as s from "./ThumbnailStyled";
import { Text } from "components/atoms/text/Text";
import { COLORS } from "styles/colors";

import { ThumbnailData } from "api/interfaces/reportInterface.interface";
import { getRecorderTimeFormat } from "utils/timeUtil";
import { ProfileAccountInfo } from "api/interfaces/accountInterface.interface";

import VideoThumbnail from "components/atoms/image/thumbnail/VideoThumbnail";

type Props = {
  isFilter?: boolean;
  item: ThumbnailData;
  recorderName: string;
  cameraName: string;
  index: number;
  onChangeModal: (
    popupData?: ThumbnailData,
    recorderName?: string,
    cameraName?: string
  ) => void;
  selectedAccount: ProfileAccountInfo;
};

export default function ThumbnailItem(props: Props): JSX.Element {
  return (
    <s.ThumbnailItem key={props.item.id}>
      <div
        onClick={(e) => {
          e.preventDefault();
          if (
            props.item.errorType === undefined ||
            props.item.errorType.toLowerCase() === "success"
          ) {
            props.onChangeModal(
              props.item,
              props.recorderName,
              props.cameraName
            );
          } else return;
        }}
      >
        <VideoThumbnail
          videoLoss={false}
          thumbnailUrl={props.item.url}
          // errorType="CameraInvalid"
          errorType={props.item.errorType}
        />
      </div>
      <Text className="title" bold>
        {props.isFilter
          ? getRecorderTimeFormat(
              props.item.timestamp,
              props.selectedAccount,
              props.item.timezone,
              true,
              true
            )
          : props.cameraName}
      </Text>
      {!props.isFilter && (
        <Text className="thumbnail-content">{props.recorderName}</Text>
      )}
    </s.ThumbnailItem>
  );
}
