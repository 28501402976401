import SmallLabel, { Label } from "components/atoms/text/labels/SmallLabel";
import TRIANGLE_WARNING_ICON from "assets/icons/triangle_warning.svg";
import { COLORS } from "styles/colors";
import styled from "styled-components";

type Props = {
  status?: string;
};

export default function CvvLabel(props: Props): JSX.Element {
  return (
    <LabelComponent color={props.status === "warning" ? COLORS.RED : "#0014FF"}>
      {props.status === "warning" && (
        <img src={TRIANGLE_WARNING_ICON} alt="triangle-warning-icon" />
      )}
      CVV
    </LabelComponent>
  );
}

const LabelComponent = styled(Label)`
  padding: 4px 6px;
  background-color: ${({ color }) => color};
  color: ${COLORS.WHITE};
`;
