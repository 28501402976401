import { Row, Back } from "../SubHeaderStyled";
import { Text } from "components/atoms/text/Text";
import { useNavigate } from "react-router-dom";
import { FiChevronLeft } from "react-icons/fi";
import { useAppSelector } from "redux/hooks";
import { COLORS } from "styles/colors";

function BackButton({}): JSX.Element {
  const navigate = useNavigate();
  const theme = useAppSelector((state) => state.theme.theme);
  return (
    <Back onClick={() => navigate(-1)}>
      <FiChevronLeft
        size={18}
        color={theme === "light" ? COLORS.BLACK : COLORS.WHITE}
      />
      <Text fontSize={18}>Back</Text>
    </Back>
  );
}
export default BackButton;
