import styled from "styled-components";
import { FLEX } from "styles/flex";
import { COLORS, LEVELS } from "styles/colors";
import { useAuth } from "components/Auth";
import { ProfileAccountInfo } from "api/interfaces/accountInterface.interface";
import { useAppSelector } from "redux/hooks";
import { stringAvatar } from "utils/functions";
import { calcRem } from "styles/theme";
import { useState } from "react";
import { isEmpty } from "lodash";

function UserProfileImage(): JSX.Element {
  const auth = useAuth();
  const account: ProfileAccountInfo = useAppSelector(
    (state) => state.accountSettings
  );
  const [state, setState] = useState(1);

  return (
    <UserProfileImageWrapper level={account.accountLevel}>
      {auth.user?.profileImg === "" ||
      auth.user?.profileImg === null ||
      auth.user?.profileImg === undefined ? (
        <UserDiv level={account.accountLevel}>
          { auth.user.name === undefined || isEmpty(auth.user.name) || auth.user.name === null 
            ? ""
            : stringAvatar(auth.user.name)}
        </UserDiv>
      ) : (
        <UserImage alt="user-image" key={state} src={auth.user?.profileImg} />
      )}
    </UserProfileImageWrapper>
  );
}

export default UserProfileImage;

const UserProfileImageWrapper = styled.div<{ level: string }>`
  width: 100%;
  height: 100%;
  border-radius: 50%;
  overflow: hidden;
  box-sizing: border-box;
  border: 2px solid;
  border-color: ${({ level }) => LEVELS[level]};
`;

const UserDiv = styled.div<{ level: string }>`
  color: ${COLORS.WHITE};
  ${FLEX.FlexCenterCenter};
  font-weight: 500;
  font-size: ${calcRem(14)};
  width: 100%;
  height: 100%;
  background-color: ${COLORS.WHITE}30;
  background-color: ${({ level }) => level === "EU" && LEVELS[level]};
`;

const UserImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
`;
