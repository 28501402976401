import React, { Fragment, useState } from "react";
import {
  FilterFn,
  useReactTable,
  getCoreRowModel,
  getPaginationRowModel,
  getGroupedRowModel,
  getExpandedRowModel,
  ColumnDef,
  flexRender,
} from "@tanstack/react-table";
import { rankItem, rankings } from "@tanstack/match-sorter-utils";
import * as s from "../BillingPaymentStyled";
import {
  BillingAccount,
  BillingDetail,
  BillingLicenses,
} from "api/interfaces/licenseInterface.interface";
import { useQuery } from "react-query";
import { getLicenseBillingDetail } from "api/licenseAPI";
import { Date } from "../BillingPaymentContainer";
import { useLocation } from "react-router-dom";
import { useIntl } from "react-intl";
import { notify } from "components/atoms/notification/Notification";

export type Props = {
  date: Date;
  sortBy: number;
};

const RankItemOptions = {
  threshold: rankings.CONTAINS,
};
const fuzzyFilter: FilterFn<any> = (row, columnId, value, addMeta) => {
  // Rank the item

  const itemRank = rankItem(row.getValue(columnId), value, RankItemOptions);

  // Store the itemRank info
  addMeta({
    itemRank,
  });

  // Return if the item should be filtered in/out
  return itemRank.passed;
};

export default function PaymentDetailTable(props: Props): JSX.Element {
  const intl  = useIntl();
  const location = useLocation();
  const accountId = location.state.accountId;
  const detailAccountId = location.state.detailAccountId;
  // console.log(location);
  const [detailData, setDetailData] = useState<BillingDetail[]>([]);
  const columns = React.useMemo<ColumnDef<BillingDetail>[]>(
    () => [
      {
        accessorKey: "partNumber",
        header: "PART NUMBER",
        cell: ({ getValue }) => getValue(),
      },
      {
        accessorKey: "description",
        header: "DESCRIPTION",
        cell: ({ getValue }) => getValue(),
      },
      {
        accessorKey: "qty",
        header: "QTY",
        cell: ({ getValue }) => getValue(),
      },
    ],
    []
  );
  const getBillingDetail = useQuery(
    ["billingDetail", accountId, props.date, detailAccountId],
    () => {
      return getLicenseBillingDetail({
        accountId: accountId,
        payload: {
          year: props.date.year,
          month: props.date.month,
          detailAccountId: detailAccountId,
        },
      });
    },
    {
      retry: 0,
      refetchOnWindowFocus: false,
      onSuccess: (res: any) => {
        if (res.result) {
          var tempArr = [];
          if (Object.keys(res.result).length !== 0) {
            // licenses
            if (props.sortBy === 0) {
              tempArr = res.result.licenses.map((license: BillingLicenses) => {
                return {
                  partNumber: license.partNumber,
                  description: license.description,
                  qty: license.totalQty,
                };
              });
            }
            // account
            if (props.sortBy === 1) {
              tempArr = res.result.accounts
                .filter(
                  (account: BillingAccount) =>
                    account.accountId === detailAccountId
                )[0]
                .licenses.map((license: BillingLicenses) => {
                  return {
                    partNumber: license.partNumber,
                    description: license.description,
                    qty: license.totalQty,
                  };
                });
            }
          }

          setDetailData(tempArr);
        }
      },
      onError: (e: any) => {
        notify("error", intl.formatMessage({
          id: "label.billing.notify.search.billing.fail",
          defaultMessage: "Billing detail search error.",
        }));
      },
    }
  );
  const table = useReactTable({
    data: detailData,
    columns,
    filterFns: {
      fuzzy: fuzzyFilter,
    },
    getGroupedRowModel: getGroupedRowModel(),
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
    // debugTable: true,
  });

  const rows = table.getRowModel().rows;
  return (
    <s.TableTitleBox>
      <s.TableWrapper>
        <table>
          <thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <th key={header.id} style={{ width: header.getSize() }}>
                    {header.isPlaceholder
                      ? null
                      : flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody>
            {rows.map((row) => {
              // console.log(row);
              return (
                <s.TBodyTR key={row.id}>
                  {row.getVisibleCells().map((cell) => {
                    return (
                      // Otherwise, just render the regular cell

                      <td key={cell.id}>
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext()
                        )}
                      </td>
                    );
                  })}
                </s.TBodyTR>
              );
            })}
          </tbody>
        </table>
      </s.TableWrapper>
    </s.TableTitleBox>
  );
}
