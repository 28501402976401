import styled from "styled-components";
import { COLORS, LEVELS } from "styles/colors";
import { calcRem } from "styles/theme";
import { FLEX } from "styles/flex";
import { LEVEL_TYPE } from "api/interfaces/accountInterface.interface";
import { ReactComponent as AccountSVG } from "assets/icons/sidenav/account.svg";

export type LicenseStatus = "PREPARING";

interface Props {
  level: LEVEL_TYPE;
  accountId: string;
}

const AccountId = ({ level, accountId }: Props) => (
  <Label level={level}>
    <AccountSVG />
    {accountId}
  </Label>
);

const Label = styled.span<{ level: LEVEL_TYPE }>`
  padding: 2px 3px;
  border-radius: 5px;
  background-color: ${({ level }) => LEVELS[level]};
  font-size: ${calcRem(12)};
  color: ${COLORS.WHITE};
  ${FLEX.FlexStartCenter};
  gap: 3px;

  > svg {
    width: 11px;
    height: 11px;
  }
`;
export default AccountId;
