import InnerContainer from "components/atoms/inner/InnerContainer";
import styled from "styled-components";
import { COLORS } from "styles/colors";
import { FLEX } from "styles/flex";
import { calcRem } from "styles/theme";
import * as mediaQuery from "components/MediaQuery";

export const Container = styled(InnerContainer)`
  ${FLEX.FlexCenterCenter};
  width: 100%;
  padding-top: 50px;
`;

export const Inner = styled.div`
  width: 100%;
  background-color: #0c1724;
  ${FLEX.FlexCenterCenter};
  flex-direction: column;
  padding: 40px 0 80px;
`;

export const Wrapper = styled.div`
  width: 80%;
  padding: 80px 0;
  border-top: 1px solid ${COLORS.BORDER};
  border-bottom: 1px solid ${COLORS.BORDER};
  ${FLEX.FlexCenterCenter};
`;

export const Circle = styled.div`
  border: 8px solid ${COLORS.SELECTED};
  width: 250px;
  height: 250px;
  border-radius: 50%;
  ${FLEX.FlexCenterCenter};
  padding: 20px 0;
  cursor: pointer;

  h2 {
    color: ${COLORS.SELECTED};
    font-size: 40px;
    text-align: center;
  }
  h3 {
    color: ${COLORS.SELECTED}57;
    font-size: 30px;
  }

  .bold {
    font-weight: 500;
  }
`;

export const Row = styled.div`
  ${FLEX.FlexStartCenter};
  gap: 40px;

  ${mediaQuery.isMobile} {
    flex-direction: column;
  }
`;

export const Select = styled.div`
  ${FLEX.FlexStartCenter};
  gap: 6px;
  cursor: pointer;
  padding-bottom: 6px;

  > img {
    width: 18px;
  }
`;

export const Info = styled.div`
  ${FLEX.FlexStartCenter};
  gap: 10px;
  padding-bottom: 20px;

  > img {
    height: 48px;
  }

  h5 {
    font-size: ${calcRem(28)};
    color: ${COLORS.SELECTED};
  }

  p {
    color: ${COLORS.BUTTONS1};
    font-size: ${calcRem(22)};
  }
`;

export const InternetSpeedTitle = styled(Info)`
  font-weight: 500;
  font-size: ${calcRem(24)};
  color: ${COLORS.WHITE};

  > img {
    height: 30px;
  }
`;

export const SubContent = styled.p`
  color: #36405a;
  font-size: ${calcRem(18)};
  line-height: ${calcRem(24)};
`;
