import styled from "styled-components";
import LineInterpolationChart from "components/atoms/chart/LineInterpolationChart";
import ReportSelect from "components/atoms/select/modal/ReportSelect";
import { intervalOptions } from "utils/options";
import { FLEX } from "styles/flex";
import {
  Interval,
  PerformanceData,
  ReportHealthDetailPerformance,
} from "api/interfaces/reportInterface.interface";
import { memo, useEffect, useMemo, useState } from "react";
import { COLORS } from "styles/colors";
import { Serie, Datum } from "@nivo/line";
import { getChartTime } from "utils/timeUtil";
import { ProfileAccountInfo } from "api/interfaces/accountInterface.interface";
import { useAppSelector } from "redux/hooks";
import NoData from "components/templates/nopage/NoData";
import * as mediaQuery from "components/MediaQuery";

type Props = {
  reportId: string;
  recorderId: string;
  data: ReportHealthDetailPerformance;
  performanceIntervalValue: Interval;
  onChangeIntervalOption: (e: any) => void;
};
export default function Performance({
  data,
  performanceIntervalValue,
  onChangeIntervalOption,
}: Props): JSX.Element {
  const selectedAccount: ProfileAccountInfo = useAppSelector(
    (state) => state.accountSettings
  );

  const [cpuData, setCpuData] = useState<Datum[]>([]);
  const [memoryData, setMemoryData] = useState<Datum[]>([]);

  const changeToDataFormat = (data: PerformanceData[]) => {
    let tempCpu: Datum[] = [];
    let tempMemory: Datum[] = [];

    data.map((d: PerformanceData) => {
      Object.keys(d).map((dKey: string) => {
        let timeStampSplit = d.timestamp;
        let timeStamp = getChartTime(
          timeStampSplit,
          selectedAccount,
          performanceIntervalValue
        );

        if (dKey === "cpu") {
          tempCpu.push({
            x: timeStamp,
            y: d.cpu,
          });
        } else if (dKey === "memory") {
          tempMemory.push({
            x: timeStamp,
            y: d.memory,
          });
        }
      });
    });

    setCpuData(tempCpu);
    setMemoryData(tempMemory);
  };

  useEffect(() => {
    changeToDataFormat(data.performance);
  }, [data]);

  return (
    <Inner>
      <div className="select-wrapper">
        <ReportSelect
          options={intervalOptions}
          value={intervalOptions.filter(function (option) {
            return option.value === performanceIntervalValue;
          })}
          onChange={onChangeIntervalOption}
        />
      </div>
      <div className="chart-wrapper">
        {cpuData.length === 0 && memoryData.length === 0 ? (
          <NoData />
        ) : (
          <LineInterpolationChart
            data={[
              {
                id: "CPU",
                color: COLORS.BUTTONS2,
                data: cpuData,
              },
              {
                id: "Memory",
                color: "#03EFE1",
                data: memoryData,
              },
            ]}
            fixedMax={100}
          />
        )}
      </div>
    </Inner>
  );
}

const Inner = styled.div`
  width: 100%;
  height: 100%;
  padding: 15px 0;
  box-sizing: border-box;
  ${FLEX.FlexBetweenStart};
  flex-direction: column;
  flex: 1;

  .select-wrapper {
    margin-bottom: 10px;
  }
  .chart-wrapper {
    width: 98%;
    height: 90%;
    min-height: 90%;
    max-height: 90%;
    display: flex;
    flex: 1;
  }

  ${mediaQuery.isMobile} {
    height: 350px;
    min-height: 350px;
  }
`;
