import { ProfileAccountInfo } from "api/interfaces/accountInterface.interface";
import { Fragment } from "react";
import { useAppSelector } from "redux/hooks";

type RBACWrapperProps = {
  children: React.ReactNode;
  requiredPermissions: string;
};

const RBACWrapper = ({
  requiredPermissions,
  children,
}: RBACWrapperProps): JSX.Element | null => {
  const selectedAccount: ProfileAccountInfo = useAppSelector(
    (state) => state.accountSettings
  );
  const hasRequiredPermissions =
    selectedAccount.userPermission[
      requiredPermissions as keyof typeof selectedAccount.userPermission
    ];

  if (requiredPermissions === undefined) {
    return <Fragment>{children}</Fragment>;
  } else {
    if (hasRequiredPermissions) {
      return <Fragment>{children}</Fragment>;
    } else {
      return <></>;
    }
  }
};

export default RBACWrapper;
