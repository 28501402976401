import * as s from "styles/ModalCommonStyled";
import { Text } from "components/atoms/text/Text";
import DefaultThumbnail from "components/atoms/image/DefaultThumbnail";
import blankCamera from "assets/images/camera/camera_is_offline.png";
import { Fragment, useCallback, useEffect } from "react";
import { IndeterminateCheckbox } from "./comp/VGroupTable";
import { MergedCameraList } from "api/interfaces/cameraInterface.interface";
import {
  CameraDetailInfo,
  CameraStep,
} from "api/interfaces/reportInterface.interface";
import CVVThumbnail from "components/atoms/image/thumbnail/CVVThumbnail";
import { isEmpty } from "lodash";
import Spinner from "components/atoms/loader/Spinner";

export type SearchCameraProps = {
  index: number;
  recorder: MergedCameraList;
  selectCamera?: CameraStep[];
  // onChangeCamera?: (
  //   recorderId: string,
  //   cameraId: string[],
  //   checked: boolean,
  //   checkedAll?: boolean
  // ) => void;
  onChangeCameras?: (
    cameras: CameraStep[],
    checked: boolean,
    checkedAll?: boolean
  ) => void;
};

export function SearchCamera(props: SearchCameraProps): JSX.Element {
  const onChangeAll = useCallback(
    (checked: boolean) => {
      let cameras = Array.of<CameraStep>();
      props.recorder.recorder.forEach((data) => {
        if (data.cameras !== undefined) {
          let tempArray = Array.of<CameraDetailInfo>();
          if (checked) {
            data.cameras.forEach((camera) => {
              let object = {
                cameraId: camera.camera_id,
                channel: camera.camera_channel,
              };
              tempArray.push(object);
            });
          }
          const step: CameraStep = {
            mergedRecorderId: props.recorder.merged_system_id,
            recorderId: data.recorder_id,
            camera: tempArray,
          };
          cameras.push(step);
        }
      });
      if (props.onChangeCameras) {
        props.onChangeCameras(cameras, checked, checked);
      }
    },
    [props]
  );

  const checkAll = useCallback(() => {
    let checked = true;
    props.recorder.recorder.forEach((data) => {
      if (data.cameras !== undefined) {
        data.cameras.forEach((camera) => {
          const cameraOnRecorder = props.selectCamera?.find(
            (selectD) => selectD.recorderId === data.recorder_id
          );
          if (cameraOnRecorder) {
            if (
              !cameraOnRecorder?.camera.find(
                (selectCamera) =>
                  selectCamera.cameraId === camera.camera_id &&
                  selectCamera.channel === camera.camera_channel
              )
            ) {
              checked = false;
            }
          } else {
            checked = false;
          }
        });
      }
    });

    return checked;
  }, [props.recorder.recorder, props.selectCamera]);

  const checkSomeSelect = useCallback(() => {
    let checked = false;
    props.recorder.recorder.forEach((data) => {
      if (data.cameras !== undefined) {
        data.cameras.forEach((camera) => {
          const cameraOnRecorder = props.selectCamera?.find(
            (selectD) => selectD.recorderId === data.recorder_id
          );
          if (
            cameraOnRecorder?.camera.find(
              (selectCamera) =>
                selectCamera.cameraId === camera.camera_id &&
                selectCamera.channel === camera.camera_channel
            )
          ) {
            checked = true;
          }
        });
      }
    });
    return checked;
  }, [props.recorder.recorder, props.selectCamera]);

  useEffect(() => {}, [props.selectCamera]);

  const determineCheck = (
    recorderId: string,
    cameraId: string,
    camera_channel: number
  ) => {
    let checked = false;
    const cameraOnRecorder = props.selectCamera?.find(
      (selectD) => selectD.recorderId === recorderId
    );
    if (cameraOnRecorder !== undefined) {
      if (
        cameraOnRecorder?.camera.find(
          (selectCamera) =>
            selectCamera.cameraId === cameraId &&
            selectCamera.channel === camera_channel
        )
      ) {
        checked = true;
      }
    }
    return checked;
  };

  return (
    <Fragment>
      <s.CvvListTitle>
        <label>
          <IndeterminateCheckbox
            {...{
              checked: checkAll(),
              indeterminate: checkSomeSelect(),
              onChange: (e) => onChangeAll(e.currentTarget.checked),
              // onClick: onClickCheckBox
            }}
          />
          {/* <input
            key={props.index}
            type="checkbox"
            onChange={(e) => onChangeAll(e.currentTarget.checked)}
            checked={checkAll()}
          /> */}
          <Text bold>{props.recorder.merged_system_name}</Text>
        </label>
      </s.CvvListTitle>
      <s.CvvListWrapper>
        <ul className="inner">
          {props.recorder.recorder.map((recorder) =>
            recorder.cameras.map((camera, index) => (
              <s.CvvListItem key={index}>
                <s.CvvCameraThumbnail>
                  <input
                    key={index}
                    type="checkbox"
                    checked={
                      props.selectCamera !== undefined
                        ? determineCheck(
                            recorder.recorder_id,
                            camera.camera_id,
                            camera.camera_channel
                          )
                        : false
                    }
                    onChange={(e) => {
                      if (props.onChangeCameras) {
                        const step: CameraStep = {
                          mergedRecorderId: props.recorder.merged_system_id,
                          recorderId: recorder.recorder_id,
                          camera: Array.of({
                            cameraId: camera.camera_id,
                            channel: camera.camera_channel,
                          }),
                        };
                        props.onChangeCameras(
                          Array.of(step),
                          e.currentTarget.checked
                        );
                      }
                    }}
                  />
                  <s.CvvCameraThumbnailTitle>
                    {camera.camera_name}
                  </s.CvvCameraThumbnailTitle>
                  <CVVThumbnail
                    errorStatus={
                      !isEmpty(camera.error_type)
                        ? `${camera.error_type}`
                        : undefined
                    }
                    videoLoss={false}
                    thumbnailUrl={
                      !isEmpty(camera.thumbnail_url)
                        ? camera.thumbnail_url
                        : undefined
                    }
                  />
                  {/* <DefaultThumbnail>
                        {camera.thumbnail_url !== undefined ? (
                          <img
                            src={camera.thumbnail_url}
                            alt="cvv camera thumbnail"
                          />
                        ) : (
                          <img src={blankCamera} alt="camera off thumbnail" />
                        )}
                      </DefaultThumbnail> */}
                </s.CvvCameraThumbnail>
              </s.CvvListItem>
            ))
          )}
        </ul>
      </s.CvvListWrapper>
    </Fragment>
  );
}
