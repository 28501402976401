import * as s from "./SignupStyled";
import * as cs from "../SignCommonStyled";
import TITLE_MYDW_ICON from "assets/icons/title_myDW.svg";
import {
  SIGNUP_STEP,
  SIGNUP_STEP_TITLE,
  UserInfo,
  Validate,
} from "./SignupContainer";
import { EULAModal } from "./EULAModal";
import { useAppSelector } from "redux/hooks";
import { Spin } from "antd";
import { ValidateMsg } from "../signin/LoginContainer";
import InputInfo from "./step/InputInfo";
import ResetPassword from "./step/ResetPassword";
import ResetPasswordSuccess from "./step/ResetPasswordSuccess";
import SelectSignUp from "./step/SelectSignUp";
import Success from "./step/Success";

interface Props {
  onCode: (code: string) => void;
  onPopupClose: () => void;
  step: SIGNUP_STEP;
  checkedEmail: string;
  onClickSignup: () => void;
  onClickVerify: () => void;
  onClickLogin: () => void;
  onClickVerifyCancel: () => void;
  passwordStatus: number;
  onChangeUserInfo: (type: UPDATE_TYPE, data: string | boolean) => void;
  userInfo: UserInfo;
  verifyCode: string;
  onChangeVerifyCode: (value: string) => void;
  onblurConfirmPassword: () => void;
  isValidConfirm: boolean;
  isValidPassword: boolean;
  isFlagSelected: string;
  onClickPassword: () => void;
  onClickBackLogin: () => void;
  onSelectFlag: (code: string) => void;
  onOpenEULAModal: (isOpen: boolean) => void;
  isOpenEULA: boolean;
  isValidated: Validate;
  firstNameRef: any;
  lastNameRef: any;
  passwordRef: any;
  confirmPasswordRef: any;
  agreementRef: any;
  onClickSignPage: () => void;
  onClickCloudCreate: () => void;
  validateMsg: string;
  onClickResend: () => void;
  isValidVerify: boolean;
  isCloudAccount: boolean;
  onClickCloudLogin: () => void;
  isLoadedToken: boolean;
  isExpiredToken: boolean;
  cloudLoading: boolean;
  spectrumValidated: Validate;
  spectrumValidateMsg: ValidateMsg;
  isPhoneVerified: boolean;
  onSendVerify: () => void;
  mfaNumber: string;
  validatePhoneMsg: string;
}

export enum UPDATE_TYPE {
  FIRST_NAME = "FIRST_NAME",
  LAST_NAME = "LAST_NAME",
  PASSWORD = "PASSWORD",
  CONFIRM_PASSWORD = "CONFIRM_PASSWORD",
  PHONE_NUMBER = "PHONE_NUMBER",
  AGREEMENT = "AGREEMENT",
  TWOFACTOR = "TWOFACTOR",
  NOTIFYSMS = "NOTIFYSMS",
}

export enum PASSWORD_STATUS {
  VERY_POOR,
  POOR,
  OK,
  GOOD,
  EXCELLENT,
}

export function SignupPagePresenter(props: Props): JSX.Element {
  const {
    step,
    onCode,
    onPopupClose,
    checkedEmail,
    onClickSignup,
    onClickVerify,
    onClickLogin,
    onClickVerifyCancel,
    passwordStatus,
    onChangeUserInfo,
    userInfo,
    verifyCode,
    onChangeVerifyCode,
    onblurConfirmPassword,
    isValidConfirm,
    isValidPassword,
    onClickPassword,
    onClickBackLogin,
    isOpenEULA,
    onOpenEULAModal,
    isValidated,
    firstNameRef,
    lastNameRef,
    passwordRef,
    confirmPasswordRef,
    agreementRef,
    onClickSignPage,
    onClickCloudCreate,
    validateMsg,
    onClickResend,
    isValidVerify,
    isCloudAccount,
    onClickCloudLogin,
    isLoadedToken,
    isExpiredToken,
    cloudLoading,
    spectrumValidated,
    spectrumValidateMsg,
    isPhoneVerified,
    onSendVerify,
    mfaNumber,
    validatePhoneMsg,
  } = props;

  const noticeLength: number = useAppSelector(
    (state) => state.notice.notices.length
  );

  return (
    <Spin
      spinning={cloudLoading}
      size="large"
      indicator={
        <div className="progress-indicator progress-indicator--responsive" />
      }
    >
      <div>
        <cs.BG noticeLength={noticeLength}>
          <cs.Container>
            <s.TitleWrapper>
              <s.SignupTitle>
                WELCOME TO
                <img src={TITLE_MYDW_ICON} width="80" alt="MyDW Icon" />
              </s.SignupTitle>
              <p className="txt">Complete Surveillance Solutions</p>
            </s.TitleWrapper>

            <cs.LoginBox>
              {SIGNUP_STEP_TITLE[step] !== "" && (
                <div className="title">{SIGNUP_STEP_TITLE[step]}</div>
              )}
              {step === SIGNUP_STEP.SUCCESS && (
                <Success userInfo={userInfo} onClickLogin={onClickLogin} />
              )}
              {step === SIGNUP_STEP.SELECT_SIGNUP && (
                <SelectSignUp
                  onCode={onCode}
                  onPopupClose={onPopupClose}
                  checkedEmail={checkedEmail}
                  onClickSignPage={onClickSignPage}
                  validateMsg={validateMsg}
                  spectrumValidated={spectrumValidated}
                  spectrumValidateMsg={spectrumValidateMsg}
                  isLoadedToken={isLoadedToken}
                />
              )}

              {step === SIGNUP_STEP.RESET_PASSWORD && (
                <ResetPassword
                  checkedEmail={checkedEmail}
                  userInfo={userInfo}
                  onChangeUserInfo={onChangeUserInfo}
                  onblurConfirmPassword={onblurConfirmPassword}
                  isValidConfirm={isValidConfirm}
                  isValidPassword={isValidPassword}
                  onClickPassword={onClickPassword}
                  onClickBackLogin={onClickBackLogin}
                  isValidated={isValidated}
                  passwordRef={passwordRef}
                  confirmPasswordRef={confirmPasswordRef}
                  validateMsg={validateMsg}
                  isExpiredToken={isExpiredToken}
                />
              )}
              {step === SIGNUP_STEP.RESET_PASSWORD_SUCCESS && (
                <ResetPasswordSuccess onClickBackLogin={onClickBackLogin} />
              )}
              {step === SIGNUP_STEP.INPUT_INFO && (
                <InputInfo
                  checkedEmail={checkedEmail}
                  userInfo={userInfo}
                  verifyCode={verifyCode}
                  onChangeVerifyCode={onChangeVerifyCode}
                  onClickSignup={onClickSignup}
                  onClickVerify={onClickVerify}
                  onClickVerifyCancel={onClickVerifyCancel}
                  onChangeUserInfo={onChangeUserInfo}
                  onblurConfirmPassword={onblurConfirmPassword}
                  isValidConfirm={isValidConfirm}
                  onOpenEULAModal={onOpenEULAModal}
                  isValidated={isValidated}
                  firstNameRef={firstNameRef}
                  lastNameRef={lastNameRef}
                  passwordRef={passwordRef}
                  confirmPasswordRef={confirmPasswordRef}
                  agreementRef={agreementRef}
                  validateMsg={validateMsg}
                  onClickResend={onClickResend}
                  isValidVerify={isValidVerify}
                  isPhoneVerified={isPhoneVerified}
                  onSendVerify={onSendVerify}
                  mfaNumber={mfaNumber}
                  validatePhoneMsg={validatePhoneMsg}
                />
              )}
            </cs.LoginBox>
          </cs.Container>
        </cs.BG>
        {isOpenEULA && (
          <EULAModal onOpenEULAModal={() => onOpenEULAModal(false)} />
        )}
      </div>
    </Spin>
  );
}
