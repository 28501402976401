import * as s from "../MyProfileStyled";
import TAKE_PHOTO from "assets/icons/take_photo.svg";
import UPLOAD_PHOTO from "assets/icons/upload_photo.svg";
import OFFLINE_ICON from "assets/icons/offline.svg";
import { isEmpty } from "lodash";

export type Props = {
  profileImageUrl: string;
  onChangeIsCrop: (type?: string) => void;
  onDeleteProfileImage: () => void;
};

export default function PhotoMenu({
  profileImageUrl,
  onChangeIsCrop,
  onDeleteProfileImage,
}: Props): JSX.Element {
  const photoMenuList = isEmpty(profileImageUrl)
    ? [
        {
          label: "Update Photo",
          icon: UPLOAD_PHOTO,
          onClick: () => onChangeIsCrop("open"),
        },
        {
          label: "Take Photo",
          icon: TAKE_PHOTO,
          status: "disabled",
        },
      ]
    : [
        {
          label: "Update Photo",
          icon: UPLOAD_PHOTO,
          onClick: () => onChangeIsCrop("open"),
        },
        {
          label: "Take Photo",
          icon: TAKE_PHOTO,
          status: "disabled",
        },
        {
          label: "Remove Photo",
          icon: OFFLINE_ICON,
          status: "warning",
          onClick: onDeleteProfileImage,
        },
      ];

  return (
    <s.PhotoMenuBubble>
      <s.PhotoMenu>
        {photoMenuList.map((menu, index) => (
          <s.PhotoMenuItem
            key={index}
            status={menu.status}
            onClick={menu.onClick}
          >
            {menu.label}
            <img src={menu.icon} alt={menu.label} />
          </s.PhotoMenuItem>
        ))}
      </s.PhotoMenu>
    </s.PhotoMenuBubble>
  );
}
