import { Text } from "components/atoms/text/Text";
import { useState } from "react";
import styled from "styled-components";
import { FLEX } from "styles/flex";
import { calcRem } from "styles/theme";
import InfoBubble from "components/blocks/bubble/InfoBubble";
import { BubbleContainer } from "components/blocks/status/StatusStyled";
import { MdConstruction } from "react-icons/md";
import { AddReportListType } from "./AddReport";

export type AddListType = {
  title: string;
  subTitle: string;
  value: AddReportListType;
  disabled: boolean;
};
type Props = {
  item: AddListType;
  index: number;
  onChangeType: (value: AddReportListType) => void;
  checkedType: AddReportListType;
};

export default function AddReportItem(props: Props): JSX.Element {
  const [isHover, setIsHover] = useState<boolean>(false);

  return (
    <RadioItem
      aria-disabled={props.item.disabled}
      isDisabled={props.item.disabled}
    >
      <input
        type="radio"
        name="addType"
        value={props.item.value}
        checked={props.checkedType === props.item.value}
        onChange={(e) => {
          props.onChangeType(props.item.value);
        }}
        readOnly={props.item.disabled}
        disabled={props.item.disabled}
      />
      <p>
        <div className="report-item-title">
          <Text
            className="report-radio-item"
            fontSize={18}
            bold
            aria-disabled={props.item.disabled}
            // color={props.item.disabled ? `${COLORS.WHITE}80` : COLORS.WHITE}
          >
            {props.item.title}
          </Text>
          {props.item.disabled && (
            <button
              className="coming-soon"
              onMouseEnter={() => setIsHover(true)}
              onMouseLeave={() => setIsHover(false)}
            >
              <MdConstruction />

              {isHover && (
                <BubbleContainer className="bubble-container">
                  <InfoBubble>Coming Soon</InfoBubble>
                </BubbleContainer>
              )}
            </button>
          )}
        </div>
        <Text color="#80879C" fontSize={14} className="subtitle">
          {props.item.subTitle}
        </Text>
      </p>
    </RadioItem>
  );
}

const RadioItem = styled.label<{ isDisabled: boolean }>`
  ${FLEX.FlexStartStart};
  gap: 10px;
  width: 100%;
  cursor: pointer;

  > input {
    width: 15px;
    height: 15px;
  }

  p {
    width: 80%;
  }

  .subtitle {
    padding-top: 4px;
    white-space: normal;
    line-height: ${calcRem(18)};
  }

  .report-radio-item {
    color: ${({ isDisabled, theme }) =>
      isDisabled ? `${theme.COLORS.WHITE}80` : theme.COLORS.WHITE};
  }

  .report-item-title {
    ${FLEX.FlexStartCenter};
    gap: 4px;

    button {
      ${FLEX.FlexCenterCenter};
      position: relative;

      .bubble-container {
        top: 30px;
      }
    }
    * {
      color: ${({ isDisabled, theme }) =>
        isDisabled ? `${theme.COLORS.WHITE}80` : theme.COLORS.WHITE};
    }
  }
`;
