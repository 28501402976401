import LeftAside from "./LeftAside";
import styled from "styled-components";
import { FLEX } from "styles/flex";
import { COLORS } from "styles/colors";
import { onViewRecorderList } from "redux/reducers/map/recorderList";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import { Text } from "components/atoms/text/Text";
import { calcRem } from "styles/theme";
import { useEffect, useState } from "react";
import { DashboardData } from "api/interfaces/dashboardInterface.interface";
import VideoThumbnail from "components/atoms/image/thumbnail/VideoThumbnail";
import ToggleComponent from "components/atoms/toggle/Toggle";
import WebAppLink from "components/blocks/link/WebAppLink";
import { ProfileAccountInfo } from "api/interfaces/accountInterface.interface";
import * as mediaQuery from "components/MediaQuery";
import X from "assets/icons/x_map.svg";

type DualProps = {
  onClickRecorder: (id: string) => void;
  onSelectRecorder: (rec: DashboardData) => void;
  onClose: () => void;
  selectedAlertId: string | undefined;
  isFetching: boolean;
};

const RecorderListView = (props: DualProps): JSX.Element => {
  const dashboardRecorderList = useAppSelector(
    (state: any) => state.dashboardData.recorderList
  );
  const selectedAccount: ProfileAccountInfo = useAppSelector(
    (state) => state.accountSettings
  );
  const [isSimple, setIsSimple] = useState<boolean>(false);

  return (
    <RelativeBox>
      <LeftAside
        isCollapsed={true}
        onClose={() => {
          props.onClose();
          // dispatch(onViewRecorderList(false));
        }}
      >
        <Container>
          <Title>
            Recorders
            <mediaQuery.Default>
              <label>
                <input
                  type="checkbox"
                  checked={!isSimple}
                  onChange={(e) => setIsSimple(!e.target.checked)}
                />
                Detail
              </label>
            </mediaQuery.Default>
            <mediaQuery.Mobile>
              <button onClick={props.onClose}>
                <img src={X} />
              </button>
            </mediaQuery.Mobile>
          </Title>
          <RecorderWrapper>
            {
              // props.isFetching ? (
              //   <> </>
              // ) : (
              dashboardRecorderList.map(
                (data: DashboardData, index: number) => (
                  <RecorderItem
                    isSelected={props.selectedAlertId === data.recorderId}
                    key={index}
                    onClick={() => {
                      // console.log("------");
                      props.onClickRecorder(data.recorderId);
                      props.onSelectRecorder(data);
                    }}
                  >
                    {/* <ItemImage>
                      <VideoThumbnail
                        isRecorderList
                        videoLoss={false}
                        thumbnailUrl={data.thumbnail}
                      />
                    </ItemImage> */}
                    <div className="recorder-info">
                      <div className="recorder-info-title">
                        <Text fontSize={14} className="recorder-info-name">
                          {data.mergedSystemName !== undefined
                            ? data.mergedSystemName
                            : data.name}
                        </Text>
                        <div>
                          <WebAppLink
                            type={data.type}
                            recorderId={data.recorderId}
                            accountId={selectedAccount.accountId}
                            mergedSystemId={data.mergedSystemId}
                            cloudSystemId={data.cloudSystemId}
                          />
                        </div>
                      </div>
                      {!isSimple && (
                        <>
                          {data.type === "Spectrum" && (
                            <Text fontSize={12} color={COLORS.LIGHTGRAY}>
                              {data.name}
                            </Text>
                          )}
                          <Text fontSize={11} color={COLORS.BUTTONS1}>
                            {data.location}
                          </Text>
                        </>
                      )}
                    </div>
                  </RecorderItem>
                )
              )
              // )
            }
          </RecorderWrapper>
        </Container>
      </LeftAside>
    </RelativeBox>
  );
};

export default RecorderListView;

const RelativeBox = styled.div`
  position: relative;
  height: 100%;
  ${mediaQuery.isDefault} {
    width: 250px;
  }

  ${mediaQuery.isMobile} {
    width: 100%;
  }
`;

const Container = styled.div`
  width: 100%;
  height: 100%;
  ${FLEX.FlexBetweenStart};
  flex-direction: column;
  flex: 1;
  position: relative;

  > div {
    width: 100%;
    max-height: 100%;
  }
`;

const Title = styled.div`
  width: 100%;
  ${FLEX.FlexBetweenCenter};
  border-bottom: 1px solid #cacaca;
  padding: 10px;
  gap: 30px;
  font-weight: 500;
  color: ${({ theme }) => theme.COLORS.WHITE};

  > label {
    font-weight: 300;
    font-size: ${calcRem(13)};
    gap: 4px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    input {
      width: 12px;
      height: 12px;
    }
  }
`;

const RecorderWrapper = styled.ul`
  ${FLEX.FlexStartStart};
  flex: 1 1 0;
  flex-direction: column;
  width: 100%;
  min-height: 0px;
  height: 100%;
  max-height: 100%;
  overflow-y: auto;
`;

const RecorderItem = styled.li<{ isSelected: boolean }>`
  border-bottom: 1px solid #cacaca;
  padding: 10px;
  width: 100%;
  ${FLEX.FlexBetweenCenter};
  gap: 8px;
  cursor: pointer;

  :hover {
    background-color: ${({ theme }) =>
      theme.value === "light" ? "#fafafa" : COLORS.PRIMARY2};
  }

  .recorder-info {
    ${FLEX.FlexStartStart};
    flex: 3;
    flex-direction: column;
    width: 70%;
    height: 100%;
    gap: 4px;

    > div {
      width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .recorder-info-title {
      ${FLEX.FlexBetweenCenter};

      .recorder-info-name {
        flex: 1;
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .web-app-link {
        font-size: ${calcRem(11)};
      }
    }
  }

  ${({ isSelected, theme }) =>
    isSelected &&
    `
    background-color: ${theme.COLORS.PRIMARY2};
  `}
`;
