import styled from "styled-components";
import { COLORS } from "styles/colors";
import { FLEX } from "styles/flex";
import { calcRem } from "styles/theme";
import * as as from "pages/dashboard/stats/StatsStyled";
import InnerContainer from "components/atoms/inner/InnerContainer";
import * as mediaQuery from "components/MediaQuery";

export const Welcome = styled.div`
  width: 100%;
  background-color: ${COLORS.PRIMARY1};
  padding: 30px 0;

  div {
    white-space: normal;
    line-height: ${calcRem(24)};
  }
`;

export const Container = styled(as.Container)`
  grid-template-columns: 1fr 1fr;
  section:nth-child(1) {
    grid-row: 1;
  }
  section:nth-child(2) {
    background-color: #0e1722;
  }
`;
export const Section = styled.section`
  padding: 30px 0;
`;

export const Inner = styled(InnerContainer)`
  ${mediaQuery.isMobile} {
    .setup-guide-title {
      font-size: ${calcRem(12)};
      img {
        height: 20px;
      }
    }
  }
`;

export const Title = styled.p`
  width: 100%;
  padding-bottom: 20px;
  margin-bottom: 20px;
  ${FLEX.FlexStartCenter};
  gap: 10px;

  border-bottom: 1px solid ${COLORS.BUTTONS1};
  color: ${COLORS.WHITE};
  font-weight: 500;
  font-size: ${calcRem(24)};

  img {
    height: 25px;
  }
`;
