import * as s from "../ModalTemplateStyled";
import ModalTemplate from "../ModalTemplate";
import styled from "styled-components";
import { COLORS } from "styles/colors";
import * as mediaQuery from "components/MediaQuery";
type Props = {
  children?: React.ReactNode;
};

const BigPopupNoHeaderTemplate = ({ children }: Props) => (
  <ModalTemplate center>
    <Modal>
      <Inner>{children}</Inner>
    </Modal>
  </ModalTemplate>
);

const Modal = styled(s.Modal)`
  background-color: ${({ theme }) => theme.COLORS.PRIMARY3};
  padding: 10px;

  ${mediaQuery.isMobile} {
    width: 80%;
  }
`;

const Inner = styled(s.Inner)`
  width: 95%;
  position: relative;

  .close-button {
    position: absolute;
    top: 0;
    right: -10px;

    img {
      width: 18px;
    }
  }

  ${mediaQuery.isMobile} {
    .close-button {
      right: 0px;
    }
  }
`;

export default BigPopupNoHeaderTemplate;
