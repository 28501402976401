import styled from "styled-components";
import { COLORS, LEVELS } from "styles/colors";
import { FLEX } from "styles/flex";
import { calcRem } from "styles/theme";

export const Row = styled.div<{ status?: boolean }>`
  ${FLEX.FlexStartCenter};
  gap: 8px;

  ${({ status }) =>
    status &&
    `
    color: ${COLORS.SELECTED}
  `}
`;

export const StatusTxt = styled.div<{ string: string }>`
  color: #828b9b;
  ${({ string }) =>
    string === "ACTIVE" &&
    `
    color: ${COLORS.PROGRESSBAR};
  `};
  ${({ string }) =>
    string === "ASSIGNED" &&
    `
    color: ${COLORS.BUTTONS2};
  `};
`;

export const AccountBox = styled.div`
  ${FLEX.FlexStartStart};
  flex-direction: column;
  gap: 6px;
  padding: 6px 0;
`;

export const SubTitleWrapper = styled.div`
  ${FLEX.FlexStartEnd};
  gap: 7px;
`;

export const Label = styled.label`
  ${FLEX.FlexStartCenter};
  align-items: flex-end;
  gap: 4px;

  > img {
    height: 15px;
  }
`;
