import styled from "styled-components";
import { FLEX } from "styles/flex";
import { Text } from "components/atoms/text/Text";
import * as mediaQuery from "components/MediaQuery";
import LIST_ICON from "assets/icons/list.svg";
import LIST_GRAY_ICON from "assets/icons/list_gray.svg";
import { MdFormatListBulleted } from "react-icons/md";

import { COLORS } from "styles/colors";
import { calcRem } from "styles/theme";
import { useState } from "react";
import { BsCheck } from "react-icons/bs";

interface TableFilter {
  label: string;
  value: string;
}
type TableColumnFilterTitleProps = {
  title: string;
  filters?: Array<TableFilter>;
  isFiltering?: string; // filters value string 으로 받아오기
  onClick?: (value: string | undefined) => void;
};

function TableColumnFilterTitle({
  title,
  filters,
  isFiltering,
  onClick,
}: TableColumnFilterTitleProps): JSX.Element {
  const [isOpenFilter, setIsOpenFilter] = useState<boolean>(false);
  const [isAll, setIsAll] = useState<boolean>(true);

  return (
    <>
      <TitleContainer
        onClick={() => setIsOpenFilter(!isOpenFilter)}
        isAll={isAll}
      >
        <Text bold>{title}</Text>
        <MdFormatListBulleted className="list-icon" />
        {/* <img src={isAll ? LIST_GRAY_ICON : LIST_ICON} alt="arrow down icon" /> */}
      </TitleContainer>
      {isOpenFilter && (
        <FilterWrapper>
          <li
            data-id="all"
            onClick={(e) => {
              if (onClick) {
                setIsAll(true);
                onClick(e.currentTarget.dataset.id);
                setIsOpenFilter(!isOpenFilter);
              }
            }}
          >
            All
          </li>
          {filters?.map((filter, index) => (
            <li
              key={`table-filter-${index}`}
              data-id={filter.value}
              onClick={(e) => {
                if (onClick) {
                  setIsAll(false);
                  onClick(e.currentTarget.dataset.id);
                  setIsOpenFilter(!isOpenFilter);
                }
              }}
            >
              {isFiltering && isFiltering === filter.value && <BsCheck />}
              {filter.label}
            </li>
          ))}
        </FilterWrapper>
      )}
    </>
  );
}

const TitleContainer = styled.div<{ isAll: boolean }>`
  ${FLEX.FlexCenterCenter};
  gap: 6px;
  cursor: pointer;
  position: relative;

  > img {
    width: 13px;
  }

  .list-icon {
    color: ${({ isAll, theme }) =>
      isAll ? theme.COLORS.WHITE : theme.COLORS.SELECTED};
  }

  ${mediaQuery.isMobile} {
    > img {
      width: 12px;
    }
  }
`;

const FilterWrapper = styled.ul`
  border-radius: 4px;
  background-color: ${COLORS.WHITE};
  border: 1px solid ${COLORS.LIGHTGRAY};
  padding: 4px 0;
  position: absolute;
  top: 50px;
  z-index: 6;
  min-width: 80px;

  li {
    padding: 3px 6px;
    font-size: ${calcRem(12)};
    color: ${COLORS.BLACK};
    font-weight: normal;
    cursor: pointer;
    ${FLEX.FlexStartCenter};
    gap: 2px;

    &:hover {
      background-color: ${COLORS.LIGHTGRAY}20;
    }
  }
`;

export default TableColumnFilterTitle;
