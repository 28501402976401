import { FC, Fragment, useCallback, useEffect, useState } from "react";
import { useQuery } from "react-query";
import {
  AutoSizer as _AutoSizer,
  List as _List,
  ListProps,
  AutoSizerProps,
  WindowScroller,
  ListRowProps,
  CellMeasurer,
  CellMeasurerCache,
} from "react-virtualized";
import styled from "styled-components";
import { getCameraListWithRecorderList } from "api/cameraAPI";
import {
  CameraStep,
  RecorderStep,
} from "api/interfaces/reportInterface.interface";
import { SearchCamera } from "./SearchCamera";
import { MergedCameraList } from "api/interfaces/cameraInterface.interface";
import Spinner from "components/atoms/loader/Spinner";

const List = _List as unknown as FC<ListProps>;
const AutoSizer = _AutoSizer as unknown as FC<AutoSizerProps>;

export const ThumbnailContainer = styled.div`
  margin-top: 20px;
  height: 400px;
  max-height: 400px;
  overflow-y: hidden;
`;

export type SearchCameraProps = {
  selectRecorder?: RecorderStep;
  selectCamera?: CameraStep[];
  selectAuto?: boolean;
  accountId: string;
  page?: number;
  onChangeCamera?: (camera: CameraStep[]) => void;
  onChangeRecorderCount: (recorderCount: number) => void;
  onChangeCameraCount: (cameraCount: number) => void;
};

const cache = new CellMeasurerCache({
  fixedWidth: true,
  minHeight: 50,
});

export function SearchCameraWithImage(props: SearchCameraProps): JSX.Element {
  const [recorders, setRecorders] = useState<MergedCameraList[]>([]);
  const [chooseCamera, setChooseCamera] = useState<CameraStep[]>([]);
  const [selectRecorder, setSelectRecorder] = useState<RecorderStep>();

  useEffect(() => {
    if (props.selectRecorder) {
      setSelectRecorder(props.selectRecorder);
    }
  }, [props.selectRecorder]);

  const { error, refetch, isFetching } = useQuery(
    ["camera", props.selectRecorder, props.selectAuto],
    () =>
      getCameraListWithRecorderList({
        payload: {
          autoApply: props.selectAuto as boolean,
          systems:
            props.selectRecorder !== undefined
              ? props.selectRecorder.systems !== undefined
                ? props.selectRecorder.systems
                : []
              : [],
          recorderGroupIds:
            props.selectRecorder !== undefined
              ? props.selectRecorder.recorderGroupId !== undefined
                ? props.selectRecorder.recorderGroupId
                : []
              : [],
        },
        accountId: props.accountId,
      }),
    {
      enabled: props.page === 1,
      retry: 0,
      refetchOnWindowFocus: false,
      onSuccess: (res: any) => {
        if (res.error !== 0 || res.result === undefined) {
          setRecorders([]);
          setChooseCamera([]);
          props.onChangeRecorderCount(0);
          props.onChangeCameraCount(0);
        } else {
          setRecorders(res.result);
          props.onChangeRecorderCount(res.result.length);
          props.onChangeCameraCount(res.page.total);
        }
      },
      onError: (_e: any) => {
        setRecorders([]);
      },
    }
  );

  const cameraCount = (mergedRecorder: MergedCameraList) => {
    let cameraCount = 0;

    if (mergedRecorder !== undefined) {
      cameraCount = mergedRecorder.recorder.reduce(
        (cameraCnt, cur) =>
          cameraCnt + (cur.cameras !== undefined ? cur.cameras.length : 0),
        0
      );
    }
    return cameraCount;
  };

  const calRowHeight = (index: any) => {
    return recorders !== undefined &&
      recorders[index] !== undefined &&
      recorders[index].recorder !== undefined
      ? (Math.floor(cameraCount(recorders[index]) / 4) + 1) * 80 + 165
      : 90;
  };

  useEffect(() => {
    cache.clearAll();
  }, [recorders]);

  useEffect(() => {
    if (props.selectCamera !== undefined) {
      setChooseCamera(props.selectCamera);
    }
  }, [props.selectCamera]);

  // const preCheckedRecorder = useCallback(
  //   (recorders: MergedCameraList) => {
  //     if (chooseCamera === undefined) {
  //       return [];
  //     } else {

  //       const findObject = chooseCamera.find(
  //         (object) => object.recorderId === recorderId
  //       );
  //       if (findObject !== undefined) {
  //         return findObject.cameraId;
  //       } else {
  //         return [];
  //       }
  //     }
  //   },
  //   [chooseCamera]
  // );

  const onChangeCameras = useCallback(
    (cameras: CameraStep[], checked: boolean, checkAll?: boolean) => {
      const cameraList = [...chooseCamera];
      if (checkAll !== undefined) {
        cameras.forEach((camera) => {
          const findObjectIndex = cameraList.findIndex(
            (object) => object.recorderId === camera.recorderId
          );

          if (checkAll) {
            if (findObjectIndex !== -1) {
              cameraList[findObjectIndex].mergedRecorderId =
                camera.mergedRecorderId;
              cameraList[findObjectIndex].camera = camera.camera;
            } else {
              cameraList.push({
                mergedRecorderId: camera.mergedRecorderId,
                recorderId: camera.recorderId,
                camera: camera.camera,
              });
            }
          } else {
            cameraList.forEach((item, index) => {
              if (item.recorderId === camera.recorderId) {
                cameraList.splice(index, 1);
              }
            });
          }
        });
        setChooseCamera((info) => {
          return cameraList;
        });
      } else {
        cameras.forEach((camera) => {
          const findObjectIndex = cameraList.findIndex(
            (object) => object.recorderId === camera.recorderId
          );

          if (checked) {
            if (findObjectIndex === -1) {
              cameraList.push({
                mergedRecorderId: camera.mergedRecorderId,
                recorderId: camera.recorderId,
                camera: camera.camera,
              });
            } else {
              if (
                !cameraList[findObjectIndex].camera.find(
                  (cam) =>
                    cam.cameraId === camera.camera[0].cameraId &&
                    cam.channel === camera.camera[0].channel
                )
              ) {
                cameraList[findObjectIndex].mergedRecorderId =
                  camera.mergedRecorderId;
                cameraList[findObjectIndex].camera.push(...camera.camera);
              }
            }
          } else {
            if (findObjectIndex !== -1) {
              if (
                cameraList[findObjectIndex].camera.find(
                  (cam) =>
                    cam.cameraId === camera.camera[0].cameraId &&
                    cam.channel === camera.camera[0].channel
                )
              ) {
                const removeIndex = cameraList[
                  findObjectIndex
                ].camera.findIndex(
                  (data) =>
                    data.cameraId === camera.camera[0].cameraId &&
                    data.channel === camera.camera[0].channel
                );
                cameraList[findObjectIndex].camera.splice(removeIndex, 1);
              }
            }
          }
        });
        setChooseCamera((info) => {
          return cameraList;
        });
      }

      if (props.onChangeCamera) {
        props.onChangeCamera(cameraList);
      }
    },
    [chooseCamera, props]
  );

  // const onChangeCamera = useCallback(
  //   (
  //     recorderId: string,
  //     cameraId: string[],
  //     checked: boolean,
  //     checkAll?: boolean
  //   ) => {
  //     const cameraList = [...chooseCamera];
  //     if (checkAll !== undefined) {
  //       const findObjectIndex = cameraList.findIndex(
  //         (object) => object.recorderId === recorderId
  //       );

  //       if (checkAll) {
  //         if (findObjectIndex !== -1) {
  //           cameraList[findObjectIndex].cameraId = cameraId;
  //         } else {
  //           cameraList.push({
  //             recorderId: recorderId,
  //             cameraId: cameraId,
  //           });
  //         }
  //       } else {
  //         cameraList.forEach((item, index) => {
  //           if (item.recorderId === recorderId) {
  //             cameraList.splice(index, 1);
  //           }
  //         });
  //       }
  //       setChooseCamera((info) => {
  //         return cameraList;
  //       });
  //     } else {
  //       const findObjectIndex = cameraList.findIndex(
  //         (object) => object.recorderId === recorderId
  //       );

  //       if (checked) {
  //         if (findObjectIndex === -1) {
  //           cameraList.push({
  //             recorderId: recorderId,
  //             cameraId: cameraId,
  //           });
  //         } else {
  //           if (!cameraList[findObjectIndex].cameraId.includes(cameraId[0])) {
  //             cameraList[findObjectIndex].cameraId.push(cameraId[0]);
  //           }
  //         }
  //       } else {
  //         if (findObjectIndex !== -1) {
  //           if (cameraList[findObjectIndex].cameraId.includes(cameraId[0])) {
  //             const removeIndex = cameraList[
  //               findObjectIndex
  //             ].cameraId.findIndex((data) => data === cameraId[0]);
  //             cameraList[findObjectIndex].cameraId.splice(removeIndex, 1);
  //           }
  //         }
  //         setChooseCamera((info) => {
  //           return cameraList;
  //         });
  //       }
  //     }

  //     if (props.onChangeCamera) {
  //       props.onChangeCamera(cameraList);
  //     }
  //   },
  //   [chooseCamera, props]
  // );

  const rowRenderer = useCallback(
    ({ index, columnIndex, key, parent, style }: ListRowProps) => {
      // if (isFetching) {
      //   return <Spinner />;
      // }
      if (recorders === undefined) {
        return <Fragment />;
      }

      const todo = recorders[index];
      cache.set(index, columnIndex, 100, calRowHeight(index));
      return (
        <CellMeasurer cache={cache} parent={parent} key={key} rowIndex={index}>
          {({ registerChild }) => (
            <div style={style}>
              <div style={{ flexGrow: 1 }}>
                <SearchCamera
                  recorder={todo}
                  selectCamera={chooseCamera}
                  index={index}
                  onChangeCameras={onChangeCameras}
                />
              </div>
            </div>
          )}
        </CellMeasurer>
      );
    },
    [
      onChangeCameras,
      recorders,
      // isFetching
    ]
  );

  if (isFetching) {
    return (
      <div style={{ paddingBottom: 20 }}>
        <Spinner />
      </div>
    );
  }

  //TODO  카메라 대수에 따라 Rowheight를 계산해줘야 정상동작함.
  return (
    <ThumbnailContainer>
      <WindowScroller>
        {({ height, scrollTop, isScrolling, onChildScroll }) => (
          <AutoSizer>
            {({ width }) => (
              <List
                width={width}
                height={400}
                style={{ outline: "none" }}
                //isScrolling={isScrolling}
                rowCount={recorders.length}
                rowRenderer={rowRenderer}
                //rowHeight={height}
                rowHeight={(index) => cache.rowHeight(index)}
                overscanRowCount={10}
                deferredMeasurementCache={cache}
              />
            )}
          </AutoSizer>
        )}
      </WindowScroller>
    </ThumbnailContainer>
  );
}
