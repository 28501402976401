import { useRef, useState, useEffect } from "react";
import styled from "styled-components";
import { COLORS } from "styles/colors";
import DATE_ICON from "assets/icons/date.svg";
import { DateFromString, changeTimeStampFormat } from "utils/functions";
import { FLEX } from "styles/flex";
import CalendarComponent from "../calendar/Calendar";
import { calcRem } from "styles/theme";
import DateTimePickerComponent from "../calendar/DateTimePicker";
import { Value } from "react-datetime-picker/dist/cjs/shared/types";
import { useAppSelector } from "redux/hooks";
import { ProfileAccountInfo } from "api/interfaces/accountInterface.interface";
import { getAccountTimeDateFormat, getAccountTimeFormat } from "utils/timeUtil";

type Props = {
  disabled?: boolean;
  value?: string;
  timezone?: string;
  placeholder?: string;
  right?: boolean;
  onChange?: (date: Date) => void;
};

const DateTimeSelect = ({
  value,
  disabled,
  timezone,
  right,
  onChange,
}: Props) => {
  const account: ProfileAccountInfo = useAppSelector(
    (state) => state.accountSettings
  );

  const calendarRef = useRef<any>(null);
  const [calendarDay, setCalendarDay] = useState(new Date());

  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if (null != value) {
      //setCalendarDay(DateFromString(value));
      setCalendarDay(getAccountTimeDateFormat(value, account));
    }
  }, []);

  useEffect(() => {
    const onClick = (event: any) => {
      if (calendarRef.current && !calendarRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.body.addEventListener("click", onClick);

    return function cleanup() {
      window.removeEventListener("click", onClick);
    };
  }, [calendarRef]);

  const onClickCalendar = (date: Value) => {
    if (date !== null) {
      setCalendarDay(date);
      setIsOpen(!isOpen);
      if (onChange !== undefined) {
        onChange(date);
      }
    }
  };

  return (
    <Container>
      <DateTimePickerComponent onChange={onClickCalendar} value={calendarDay} />
    </Container>
  );
};

const Container = styled.div`
  position: relative;
  width: 100%;
  border-radius: 4px;
  border: 1px solid #cacaca;
`;

export default DateTimeSelect;
