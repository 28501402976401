import * as s from "../CommonStyled";
import { Text } from "components/atoms/text/Text";
import VideoThumbnail from "components/atoms/image/thumbnail/VideoThumbnail";

type DualProps = {
  //data: DualModeLivefeedType;
  data: {
    name: string;
    videoLoss: boolean;
    thumbnailUrl: string;
  };
  columns: number;
};

const LivefeedItem = (props: DualProps): JSX.Element => {
  return (
    <s.LivefeedItem>
      <VideoThumbnail
        videoLoss={!props.data.videoLoss}
        thumbnailUrl={props.data.thumbnailUrl}
      />
      <s.LiveRow>
        <s.Circle status={props.data.videoLoss} />
        <s.LivefeedColumn>
          <Text bold fontSize={10}>
            {props.data.name}
          </Text>
          {/* <Text fontSize={10} color="#828B9B">
            {props.data.location}
          </Text> */}
        </s.LivefeedColumn>
      </s.LiveRow>
    </s.LivefeedItem>
  );
};

export default LivefeedItem;
