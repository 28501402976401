import * as s from "../SignupStyled";
import * as cs from "../../SignCommonStyled";
import { Validate } from "../SignupContainer";
import { Text, ValidateWord } from "components/atoms/text/Text";
import AuthPopup from "components/templates/modal/auth/AuthPopup";
import { getSpectrumAuthUrl } from "utils/AccountUtil";
import { ValidateMsg } from "../../signin/LoginContainer";

interface Props {
  onCode: (code: string) => void;
  onPopupClose: () => void;

  checkedEmail: string;

  onClickSignPage: () => void;
  validateMsg: string;
  spectrumValidated: Validate;
  spectrumValidateMsg: ValidateMsg;
  isLoadedToken: boolean;
}

export default function SelectSignUp(props: Props): JSX.Element {
  const {
    onCode,
    onPopupClose,
    checkedEmail,
    onClickSignPage,
    validateMsg,
    spectrumValidated,
    spectrumValidateMsg,
    isLoadedToken,
  } = props;

  return (
    <>
      {checkedEmail !== "" && <cs.EmailBadge>{checkedEmail}</cs.EmailBadge>}
      <s.InputWrapper>
        <cs.BigButton
          // dw
          id="dw_login_button_next"
          disabled={!isLoadedToken}
          onClick={onClickSignPage}
        >
          Create Account
        </cs.BigButton>
        <Text fontSize={12} bold>
          OR
        </Text>

        <AuthPopup
          url={getSpectrumAuthUrl(checkedEmail)}
          onCode={onCode}
          onClose={onPopupClose}
          title={"DW Spectrum Web Portal"}
          width={500}
          height={500}
        >
          <cs.BigButton dw disabled={!isLoadedToken}>
            Login with DW Cloud
          </cs.BigButton>
        </AuthPopup>
      </s.InputWrapper>
      <cs.Bottom>
        <ValidateWord>{validateMsg}</ValidateWord>
        {spectrumValidated.email && (
          <ValidateWord>{spectrumValidateMsg.email}</ValidateWord>
        )}
      </cs.Bottom>
    </>
  );
}
