import { useEffect, useState } from "react";
import styled from "styled-components";
import { COLORS, LIGHT_THEME_COLORS } from "styles/colors";
import { FLEX } from "styles/flex";

interface Day {
  [key: string]: string;
}

export type Props = {
  selectedDays?: Array<boolean>;
  onChangeDayOfWeek?: (selectDayOfWeek: boolean[]) => void;
};

const days: Day[] = [
  {
    label: "Sun",
    value: "sunday",
  },
  {
    label: "Mon",
    value: "monday",
  },
  {
    label: "Tue",
    value: "tuesday",
  },
  {
    label: "Wed",
    value: "wednesday",
  },
  {
    label: "Thu",
    value: "thursday",
  },
  {
    label: "Fri",
    value: "friday",
  },
  {
    label: "Sat",
    value: "saturday",
  },
];

const DaySelect = ({ selectedDays, onChangeDayOfWeek }: Props) => {
  const [dayOfWeek, setDayOfWeek] = useState(
    selectedDays ? selectedDays : [true, true, true, true, true, true, true]
  );

  const dayClickHandler = (e: any) => {
    let state = dayOfWeek[e.target.dataset.id];
    dayOfWeek[e.target.dataset.id] = !state;
    setDayOfWeek([...dayOfWeek]);
    if (onChangeDayOfWeek) {
      onChangeDayOfWeek(dayOfWeek);
    }
  };

  useEffect(() => {
    if (selectedDays) {
      setDayOfWeek(selectedDays);
    }
  }, [selectedDays]);

  return (
    <DayWrapper>
      {/* isSelected -> 선택된 요일들 */}
      {days.map((item, index) => (
        <DayItem
          key={index}
          data-id={index}
          isSelected={dayOfWeek[index]}
          onClick={dayClickHandler}
        >
          {item.label}
        </DayItem>
      ))}
    </DayWrapper>
  );
};

const DayWrapper = styled.ul`
  ${FLEX.FlexBetweenCenter};
  gap: 10px;
  width: 100%;
`;

const DayItem = styled.li<{ isSelected?: boolean }>`
  border-radius: 3px;
  ${FLEX.FlexCenterCenter};
  width: 40px;
  height: 35px;
  flex: 1;
  font-weight: 500;
  cursor: pointer;

  color: ${({ isSelected, theme }) =>
    isSelected ? theme.COLORS.PRIMARY2 : "#ABB5C5"};
  background-color: ${({ isSelected, theme }) =>
    isSelected
      ? theme.COLORS.BUTTONS2
      : theme.value === "light"
      ? LIGHT_THEME_COLORS.LIGHTGRAYBORDER
      : COLORS.WHITE};
`;

export default DaySelect;
