import { Text } from "components/atoms/text/Text";
import styled, { keyframes } from "styled-components";
import { COLORS } from "styles/colors";
import { FLEX } from "styles/flex";
import { motion } from "framer-motion";
import { calcRem } from "styles/theme";

export const SystemWrapper = styled.div`
  padding: 0 14px;
  gap: 10px;
  ${FLEX.FlexStartStart};
  flex-direction: column;
  width: 100%;
`;

export const Content = styled.div<{ isNoMap?: boolean }>`
  /* padding: 10px 0; */
  width: 100%;

  ${({ isNoMap }) =>
    !isNoMap &&
    `
      border-bottom: 1px solid ${COLORS.BORDER};
  `}
`;

export const SubTitle = styled.div`
  width: 100%;
  ${FLEX.FlexBetweenCenter};
  font-weight: 500;
  font-size: ${calcRem(14)};
  color: ${({ theme }) => theme.COLORS.WHITE};
`;
