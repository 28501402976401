import { useAuth } from "components/Auth";
import { Fragment, forwardRef, useCallback, useEffect, useState } from "react";
import { createSearchParams, useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "redux/hooks";

import * as s from "./SubStyled";
import { setMenu } from "redux/reducers/menu/menuSlice";

import HELP_ICON from "assets/icons/help_header.svg";
import settingSVG from "assets/icons/settings_header.svg";
import SWITCH from "assets/icons/switch.svg";
import SIGNOUTSVG from "assets/icons/signout.svg";
import { onOpen } from "redux/reducers/sidenav/SideNavReducer";
import { setIsPopup } from "redux/reducers/menu/userPopup";

import { IoIosCopy } from "react-icons/io";
import { COLORS } from "styles/colors";
import { handleCopyClipBoard } from "utils/functions";
import { ProfileAccountInfo } from "api/interfaces/accountInterface.interface";
import { menuNavigation } from "utils/MenuUtil";
interface Props {
  onChangeSwitch: () => void;
}

const UserPopup = forwardRef((props: Props, ref: any): JSX.Element => {
  const navigate = useNavigate();
  const auth = useAuth();

  const dispatch = useAppDispatch();
  const [totalAccount, setTotalAccount] = useState<number>(
    auth.user.totalAccount
  );

  const isOpen: boolean = useAppSelector(
    (state: any) => state.sidenavOpen.isOpen
  );
  const selectedAccount: ProfileAccountInfo = useAppSelector(
    (state) => state.accountSettings
  );
  const onPopup = () => {
    dispatch(setIsPopup());
  };

  useEffect(() => {
    setTotalAccount(auth.user.totalAccount);
  }, [auth.user.totalAccount]);

  const onClickLoutout = useCallback(() => {
    /**
     * @todo clear redux store
     */
    auth.logout();
    // navigate("/login", { replace: true });
  }, [auth, navigate]);

  const menus = [
    {
      label: "Switch Organization",
      icon: SWITCH,
      onClick: props.onChangeSwitch,
    },
    {
      label: "Profile Settings",
      icon: settingSVG,
      onClick: () => {
        dispatch(setMenu("Settings"));
        menuNavigation(selectedAccount, navigate, "/settings/myaccount");
      },
    },
    {
      label: "Help",
      icon: HELP_ICON,
      onClick: () => {
        dispatch(setMenu("Help"));
        menuNavigation(selectedAccount, navigate, "/help/videoguides");
        //navigate("/help/videoguides");
      },
    },
    {
      label: "Sign Out",
      icon: SIGNOUTSVG,
      onClick: onClickLoutout,
    },
  ];

  return (
    <>
      <s.PopupWrapper key={"userPopupWrapper"} onClick={onPopup} ref={ref}>
        <s.AccountId>
          <span>Organization ID</span>
          <span>
            {selectedAccount.accountNumber}{" "}
            <button
              onClick={(e) => {
                // e.preventDefault();
                e.stopPropagation();
                handleCopyClipBoard(selectedAccount.accountNumber);
              }}
            >
              <IoIosCopy color={COLORS.BORDER} />
            </button>
          </span>
        </s.AccountId>
        {menus.map((menu, index) =>
          index === 0 ? (
            totalAccount !== undefined && totalAccount !== 1 ? (
              <Fragment key={index}>
                {index === menus.length - 1 && <s.Line></s.Line>}
                <s.PopupMenu
                  key={menu.label}
                  onClick={() => {
                    menu.onClick();
                    dispatch(onOpen(!isOpen));
                  }}
                >
                  <img src={menu.icon} alt={`${menu.label} icon`} />
                  {menu.label}
                </s.PopupMenu>
              </Fragment>
            ) : (
              <></>
            )
          ) : (
            <Fragment key={index}>
              {index === menus.length - 1 && <s.Line></s.Line>}
              <s.PopupMenu
                key={menu.label}
                onClick={() => {
                  menu.onClick();
                  dispatch(onOpen(!isOpen));
                }}
              >
                <img src={menu.icon} alt={`${menu.label} icon`} />
                {menu.label}
              </s.PopupMenu>
            </Fragment>
          )
        )}
      </s.PopupWrapper>
    </>
  );
});

export default UserPopup;
