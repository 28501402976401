import { useEffect, useMemo, useState } from "react";
import { AddListPresenter } from "./AddListPresenter";
import { useLoadScript, LoadScriptProps } from "@react-google-maps/api";

const googleMapsLibraries: LoadScriptProps["libraries"] = ["places"];

interface Props {
  type: string;
  onModal: () => void;
}

function AddListContainer(props: Props): JSX.Element {
  const [ruleType, setRuleType] = useState("directs");
  const [page, setPage] = useState<number>(-1);
  const [selected, setSelected] = useState<google.maps.LatLngLiteral>({
    lat: 33.8583483,
    lng: -118.0647871,
  });

  useEffect(() => {
    if (navigator.geolocation) {
      //HTTPS가 아닌경우 현재 BLOCK됨.
      navigator.geolocation.getCurrentPosition(
        (position: GeolocationPosition) => {
          const pos = {
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          };
          console.log("pos=" + pos);
          setSelected(pos);
        }
      );
    }
  }, []);

  // const { isLoaded, loadError } = useLoadScript({
  //   googleMapsApiKey: `${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}`,
  //   language: `en`,
  //   libraries: googleMapsLibraries,
  // });

  const onChangeLocationSelect = (address: google.maps.LatLngLiteral) => {
    setSelected(address);
  };

  const onChangeRuleType = (e: string) => {
    setRuleType(e);
  };

  const onChangePage = (name: string, index?: number) => {
    if (name === "next") {
      setPage(page + 1);
    }
    if (name === "previous") {
      setPage(page - 1);
    }
    if (name === "save") {
      props.onModal();
    }
    if (name === "step" && index !== undefined) {
      console.log(name, index);
      setPage(index);
    }
  };

  const recorderRuleTypes = [
    {
      name: "directs",
      title: "Direct Connect",
      subTitle: "Add a recorder with access information.",
    },
    {
      name: "clouds",
      title: "Cloud Connect",
      subTitle: "Import DW Spectrum® IPVMS system(s) from DW Cloud.",
    },
  ];
  return (
    <AddListPresenter
      type={props.type}
      onModal={props.onModal}
      ruleType={ruleType}
      onChangeRuleType={onChangeRuleType}
      recorderRuleTypes={recorderRuleTypes}
      onChangePage={onChangePage}
      page={page}
      isLoaded={true}
      selected={selected}
      onChangeLocationSelect={onChangeLocationSelect}
    />
  );
}

export default AddListContainer;
