import { useRef, useEffect, useState, useLayoutEffect } from "react";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import * as s from "./SideNavBarStyled";
import * as mediaQuery from "components/MediaQuery";
import TopInfo from "./TopInfo";
import MenuComponent from "./Menu";
import CLOSE_MOBILE_ICON from "assets/icons/cross.svg";
import COLLAPSE_ICON from "assets/icons/sidenav/collapse.svg";
import HAMBURGER_ICON from "assets/icons/hamburger.svg";
import { ProfileAccountInfo } from "api/interfaces/accountInterface.interface";
import { COLORS, LIGHT_THEME_COLORS } from "styles/colors";
import { onOpen } from "redux/reducers/sidenav/SideNavReducer";

import { Sidebar, useProSidebar } from "react-pro-sidebar";
import { useNavigate } from "react-router-dom";

type Props = {
  onChangeSwitch: () => void;
};
const themes = {
  light: {
    sidebar: {
      backgroundColor: COLORS.WHITE,
      color: "#607489",
      borderColor: LIGHT_THEME_COLORS.LIGHTGRAYBORDER,
      boxShadow: `rgba(100, 100, 111, 0.2) 0px 7px 29px 0px`,
    },
  },
  dark: {
    sidebar: {
      backgroundColor: COLORS.SIDEBARBG,
      color: COLORS.WHITE,
      border: "none",
    },
  },
};

function SideNavBar(props: Props): JSX.Element {
  const { collapseSidebar, collapsed } = useProSidebar();
  const navigate = useNavigate();
  const isOpen: boolean = useAppSelector(
    (state: any) => state.sidenavOpen.isOpen
  );
  // const themeMode = useAppSelector((state) => state.theme.theme);
  const theme = useAppSelector((state) => state.theme.theme);
  const dispatch = useAppDispatch();

  const [sideWidth, setSideWidth] = useState<number>(80);

  useLayoutEffect(() => {
    setSideWidth(s.collapsedSidebarWidth);
  }, []);

  return (
    <>
      <mediaQuery.Mobile>
        <s.SidebarBG>
          <s.SideBarContainer isCollapsed={true}>
            <s.TopButtonWrapper>
              <s.CollapseButton onClick={() => dispatch(onOpen(!isOpen))}>
                <img
                  className="close"
                  src={CLOSE_MOBILE_ICON}
                  alt="Close Icon"
                />
              </s.CollapseButton>
            </s.TopButtonWrapper>

            <TopInfo
              isCollapsed={true}
              onOpen={() => dispatch(onOpen(!isOpen))}
              onChangeSwitch={props.onChangeSwitch}
            />
            <MenuComponent
              isCollapsed={true}
              onOpen={() => dispatch(onOpen(!isOpen))}
            />
          </s.SideBarContainer>
        </s.SidebarBG>
      </mediaQuery.Mobile>
      <mediaQuery.Default>
        <Sidebar
          // ref={sidebarRef}
          defaultCollapsed={true}
          width={`${sideWidth}px`}
          backgroundColor={themes[theme].sidebar.backgroundColor}
          rootStyles={{
            ...themes[theme].sidebar,
            maxWidth: `${sideWidth}px`,
          }}
        >
          <s.SideWrapper>
            {collapsed ? (
              <s.CollapseButton
                collapsed={collapsed}
                onClick={() => collapseSidebar()}
              >
                <img
                  className="hamburger"
                  src={HAMBURGER_ICON}
                  alt="hamburger Icon"
                />
              </s.CollapseButton>
            ) : (
              <s.CollapseButton onClick={() => collapseSidebar()}>
                <img className="close" src={COLLAPSE_ICON} alt="Close Icon" />
              </s.CollapseButton>
            )}

            <TopInfo isCollapsed={!collapsed} onChangeSwitch={() => null} />
            <MenuComponent />

            {/* <s.UserInfo>
              <s.SidebarLevel
                level={account.accountLevel}
                isCollapsed={!collapsed}
              >
                {account.accountLevel}
              </s.SidebarLevel>
            </s.UserInfo> */}
          </s.SideWrapper>
        </Sidebar>
      </mediaQuery.Default>
    </>
  );
}

export default SideNavBar;
