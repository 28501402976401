import { Text } from "components/atoms/text/Text";
import { COLORS } from "styles/colors";
import SmallPopupDefaultTemplate from "components/templates/modal/small/SmallPopupDefaultTemplate";
import BigButton from "components/atoms/buttons/BigButton";
import styled from "styled-components";
import { calcRem } from "styles/theme";
import { FLEX } from "styles/flex";
import AuthPopup from "components/templates/modal/auth/AuthPopup";
import { getSpectrumAuthUrl } from "utils/AccountUtil";
import { useAuth } from "components/Auth";
import { useAppSelector } from "redux/hooks";
import DW_Cloud_logo from "assets/images/dw_cloud_logo_w.png";
import DW_Cloud_logo_black from "assets/images/dw_cloud_logo_b.png";
import { ReactComponent as SSO_ICON } from "assets/icons/sso.svg";
import { useCallback, useState } from "react";
import { isEmpty } from "lodash";
import { CustomError } from "api/interfaces/commonInterface.interface";
import { useIntl } from "react-intl";
import { LocalConnectionInfoResponse } from "api/interfaces/recorderInterface.interface";
import { useMutation } from "react-query";
import { postLocalConnectCreate } from "api/recorderAPI";
import { notify } from "components/atoms/notification/Notification";
import MYDW_ICON from "assets/icons/mydw-logo.png";
import useApiError from "hook/useApiError";

type Props = {
  onClose: () => void;
  // onConfirm: () => void;
  onCode: () => void;
  label?: string;
  accountId: string;
  mergedSystemId: string;
};

export default function SpectrumLoginConfirmModal({
  onClose,
  // onConfirm,
  onCode,
  label,
  accountId,
  mergedSystemId,
}: Props): JSX.Element {
  const auth = useAuth();
  const intl = useIntl();
  const themeMode = useAppSelector((state) => state.theme.theme);
  const { handleError } = useApiError();

  const [code, setCode] = useState<boolean>(false);

  const mutationSpectrumConnection = useMutation(postLocalConnectCreate, {
    onSuccess: (res: LocalConnectionInfoResponse) => {
      if (
        res.result.token === undefined &&
        res.result.token === "" &&
        res.result.token === null
      ) {
        notify(
          "error",
          intl.formatMessage({
            id: "label.recorder.notify.connectionTest.fail",
            defaultMessage: "The connection test failed.",
          })
        );
      } else {
        setCode(true);
      }
    },
    onError: (err: CustomError) => {
      handleError(
        err,
        intl.formatMessage({
          id: "label.recorder.notify.connectionTest.fail",
          defaultMessage: "The connection test failed.",
        })
      );
    },
  });

  const onCodeReceived = useCallback(
    (code: string, params: URLSearchParams) => {
      if (!isEmpty(code)) {
        mutationSpectrumConnection.mutate({
          accountId: accountId,
          mergedSystemId: mergedSystemId,
          payload: { code: code },
        });
      }
    },
    [accountId, mergedSystemId, mutationSpectrumConnection]
  );

  return (
    <SmallPopupDefaultTemplate
      onModal={onClose}
      button={
        <ButtonWrapper>
          <AuthPopup
            url={getSpectrumAuthUrl(auth.user.email)}
            onCode={onCodeReceived}
            onClose={onClose}
            title={"DW Spectrum Web Portal"}
            width={500}
            height={500}
          >
            {!code && <BigButton label="Login" onClickBtn={() => {}} />}
          </AuthPopup>
          {code && <BigButton label="Connect" onClickBtn={() => onCode()} />}
          <BigButton buttonType="cancel" label="Cancel" onClickBtn={onClose} />
        </ButtonWrapper>
      }
    >
      <LogoWrapper>
        <div className="logo-inner">
          <img src={MYDW_ICON} alt="mydw-icon" className="mydw-icon" />
          <img
            src={themeMode === "light" ? DW_Cloud_logo_black : DW_Cloud_logo}
            alt="mydw-cloud-logo"
          />
        </div>
        <div className="sso-wrapper">
          <div className="diagonal-line"></div>
          <div className="sso-icon">
            <SSO_ICON />
            Single Sign-On
          </div>
          <div className="diagonal-line second"></div>
        </div>
      </LogoWrapper>
      <DeleteTextWrapper>
        DW Cloud account is required. <br />
        If you login to MyDW with DW Cloud account, this process will not be
        needed. <br />
        This process will be skipped if you log in to MYDW with DW Cloud
        account.
      </DeleteTextWrapper>
    </SmallPopupDefaultTemplate>
  );
}

const LogoWrapper = styled.div`
  color: ${({ theme }) => theme.COLORS.WHITE};
  margin-top: 20px;

  ${FLEX.FlexCenterCenter};
  flex-direction: column;
  gap: 40px;

  .logo-inner {
    width: 80%;
    ${FLEX.FlexBetweenCenter};

    > img {
      height: 40px;
    }

    .mydw-icon {
      height: 50px;
    }
  }

  .sso-wrapper {
    width: 120px;
    position: relative;

    .diagonal-line {
      position: absolute;
      width: 1px;
      height: 60px;
      background-color: ${({ theme }) => theme.COLORS.WHITE};
      transform: rotate(-45deg);
      top: -38px;
    }
    .second {
      transform: rotate(45deg);
      right: -2px;
    }
  }

  .sso-icon {
    border-radius: 50%;
    width: 100%;
    height: 120px;
    border: 1px solid ${({ theme }) => theme.COLORS.WHITE};
    ${FLEX.FlexCenterCenter};
    flex-direction: column;
  }
`;

const DeleteTextWrapper = styled.div`
  text-align: center;
  padding: 20px 0;
  line-height: ${calcRem(28)};
  font-size: ${calcRem(16)};
  color: ${({ theme }) => theme.COLORS.WHITE};
`;

const ButtonWrapper = styled.div`
  ${FLEX.FlexCenterCenter};
  gap: 10px;
`;
