import * as s from "./VideoGuideStyled";
import Header from "./VideoGuidesHeader";
import { useState } from "react";
import VideoGuidesPlay from "./VideoGuidesPlay";
import { useQuery } from "react-query";
import {
  getVideoGuideAllCategory,
  getVideoGuideCategory,
} from "api/managerAPI";
import {
  GetVideoGuideGroupListResponse,
  VideoGuideGroup,
} from "api/interfaces/managerInterface.interface";
import { extractYouTubeBaseUrl, getIDfromYoutubeUrl } from "utils/functions";
import VideoGuidesList from "./VideoGuidesList";

export default function VideoGuides(): JSX.Element {
  const [isCurrentMenu, setIsCurrentMenu] = useState<string>("about");
  const [isModal, setIsModal] = useState<boolean>(false);
  const [videoId, setVideoId] = useState<string>("");

  const onClickMenu = (index: string) => {
    setIsCurrentMenu(index);
  };

  const onChangeModal = (id?: string) => {
    if (id) {
      setVideoId(extractYouTubeBaseUrl(id));
      setIsModal(true);
      // }
    } else {
      setIsModal(false);
      setVideoId("");
    }
  };

  const [guideVideo, setGuideVideo] = useState<VideoGuideGroup[]>([]);
  const getVideoGuideListInfo = useQuery(
    ["videoGuideList", isCurrentMenu],
    () => getVideoGuideAllCategory(),
    // getVideoGuideCategory({
    //   category: isCurrentMenu,
    // }),
    {
      retry: 0,
      refetchOnWindowFocus: false,
      onSuccess: (res: GetVideoGuideGroupListResponse) => {
        if (res.error !== 0 || res.result === undefined) {
          setGuideVideo([]);
        }
        setGuideVideo(res.result);
      },
      onError: (e: any) => {
        setGuideVideo([]);
      },
    }
  );

  return (
    <>
      <s.Container>
        <Header isCurrentMenu={isCurrentMenu} onClickMenu={onClickMenu} />
        <s.VideoContainer>
          {guideVideo?.map((item, index) => (
            <VideoGuidesList
              item={item}
              onChangeModal={onChangeModal}
              key={`video-list-${index}`}
            />
          ))}
        </s.VideoContainer>
      </s.Container>
      {/* 왜 두번 렌더링 되는지 모르겠음 */}
      {isModal && videoId !== "" && (
        <VideoGuidesPlay videoId={videoId} onChangeModal={onChangeModal} />
      )}
    </>
  );
}
