import styled from "styled-components";
import { FLEX } from "styles/flex";
import { COLORS } from "styles/colors";
import Status from "components/atoms/text/labels/Status";
import { Text, UnderlineText } from "components/atoms/text/Text";
import Cloud from "components/atoms/text/labels/Cloud";
import { DataRow } from "api/interfaces/recorderInterface.interface";
import { RecorderHealthRow } from "api/interfaces/reportInterface.interface";
import * as mediaQuery from "components/MediaQuery";
import { useLocation } from "react-router-dom";
import { useAppSelector } from "redux/hooks";
import { isEmpty } from "lodash";

interface TableTitleRow {
  status: string;
  cloudSystemId?: string;
  type: string;
  title: string;
  recorderCount?: number;
  mergedSystemId?: string;
  version: string;
}

type TableTitleProps = {
  report?: boolean;
  row: TableTitleRow;
  onCellClick?: () => void;
  onUnderlineClick?: (systemName: string, mergedSystemId: string) => void;
  onUnderlineClickWithVersion?: (
    systemName: string,
    mergedSystemId: string,
    version: string
  ) => void;
  isRowClickEditable?: boolean;
};

function TableTitle({
  report,
  row,
  onCellClick,
  onUnderlineClick,
  onUnderlineClickWithVersion,
  isRowClickEditable,
}: TableTitleProps): JSX.Element {
  const location = useLocation();
  const theme = useAppSelector((state) => state.theme.theme);
  const isLight = theme === "light";

  // TODO Location 정보가 없는 경우 triangle_warning_red 아이콘 표시.
  return (
    <TitleContainer onClick={onCellClick}>
      <TitleWrapper>
        <NameLabel>
          {!report && <Status status={row.status} />}
          {!isEmpty(row.cloudSystemId) &&
            (row.type === "Spectrum" || row.type === "SPECTRUM") && (
              <mediaQuery.Default>
                <Cloud />
              </mediaQuery.Default>
            )}

          <Text className="title">{row.title}</Text>
        </NameLabel>
        {row.recorderCount !== undefined &&
          row.recorderCount > 1 &&
          !location.pathname.includes("/billing/recorders") && (
            <UnderlineText
              isRowClickEditable={isRowClickEditable}
              color={isLight ? COLORS.BUTTONS2 : COLORS.SELECTED}
              onClick={(e) => {
                if (
                  row.mergedSystemId !== undefined &&
                  onUnderlineClick !== undefined
                ) {
                  onUnderlineClick(row.title, row.mergedSystemId);
                }
                if (
                  row.mergedSystemId !== undefined &&
                  onUnderlineClickWithVersion !== undefined
                ) {
                  onUnderlineClickWithVersion(
                    row.title,
                    row.mergedSystemId,
                    row.version
                  );
                }
              }}
              fontSize={14}
            >
              Recorders
              <mediaQuery.Default>: {row.recorderCount}</mediaQuery.Default>
            </UnderlineText>
          )}
      </TitleWrapper>
      <mediaQuery.Default>
        <Text fontSize={12} color="#828B9B">
          [{row.version}]
        </Text>
      </mediaQuery.Default>
    </TitleContainer>
  );
}

const NameLabel = styled.div`
  ${FLEX.FlexStartCenter};
  gap: 5px;
  //cursor: pointer;

  .title {
    overflow: hidden;
    text-overflow: ellipsis;
  }

  ${mediaQuery.isDefault} {
    /* padding-bottom: 3px; */
    .title {
      width: 180px;
    }
  }

  ${mediaQuery.is4K} {
    .title {
      width: 540px;
    }
  }

  ${mediaQuery.isMobile} {
    .title {
      width: 110px;
    }
  }
`;

const TitleContainer = styled.div`
  width: 100%;
  ${mediaQuery.isDefault} {
    padding-top: 5px;
  }
`;
const TitleWrapper = styled.div`
  ${FLEX.FlexBetweenCenter};
  width: 100%;
`;

export default TableTitle;
