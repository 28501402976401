import * as s from "../VideoShareStyled";
import EmailAddSelect from "components/atoms/select/email/EmailAdd";
import { Text, ValidateWord } from "components/atoms/text/Text";
import SmallPopupTemplate from "components/templates/modal/small/SmallPopupTemplate";
import TinyButton from "components/atoms/buttons/TinyButton";
import { VideoShareViewResult } from "api/interfaces/videoshareInterface.interface";
import DownloadIcon from "assets/icons/video_download.svg";
import { useCallback, useEffect, useState } from "react";
import { MultiValue } from "react-select";
import { useIntl } from "react-intl";
import { useMutation } from "react-query";
import { notify } from "components/atoms/notification/Notification";
import {
  createSnapShotEmail,
  createSnapShotEmailGuest,
} from "api/videoshareAPI";
import { getCurrentMilliSecond } from "utils/timeUtil";
import { blobToFile } from "components/atoms/image/CanvasUtil";
import { changeDateToFormat } from "utils/functions";
import moment from "moment";
import Input from "components/atoms/input/Input";
import { useAppSelector } from "redux/hooks";
import { ProfileAccountInfo } from "api/interfaces/accountInterface.interface";
import TextArea from "components/atoms/input/TextArea";

type Props = {
  state?: VideoShareViewResult;
  capture: () => void;
  capturedBlob: Blob | undefined;
  capturedVtt: string | undefined;
  onModelSnapshot: () => void;
  videoId: string | undefined;
  isAuth?: boolean;
};

export default function Snapshot(props: Props): JSX.Element {
  const intl = useIntl();

  const selectedAccount: ProfileAccountInfo = useAppSelector(
    (state) => state.accountSettings
  );

  const [imageUrl, setImageUrl] = useState<string>();
  const [sendUsers, setSendUsers] = useState<MultiValue<any>>([]);
  const [errorMessage, setErrorMessage] = useState("");
  const [postContent, setPostContent] = useState("");

  useEffect(() => {
    if (props.capturedBlob !== undefined) {
      const objectURL = window.URL.createObjectURL(props.capturedBlob);
      setImageUrl(objectURL);
    }
    return () => {
      if (imageUrl !== undefined) {
        window.URL.revokeObjectURL(imageUrl);
      }
    };
  }, [props.capturedBlob]);

  const onSelectUser = useCallback((user: MultiValue<any>) => {
    if (user.length === 0) {
      return;
    }
    setSendUsers(user);
  }, []);

  const onChangeEmail = useCallback((email: string) => {
    const option = { email, value: email };
    setSendUsers([option]);
  }, []);

  const mutationSnapShotSend = useMutation(createSnapShotEmail, {
    onSuccess: (res: any) => {
      if (res.error === 0) {
        notify(
          "success",
          intl.formatMessage({
            id: "label.common.notify.email.success",
            defaultMessage: "Email sent successfully",
          })
        );
        props.onModelSnapshot();
      } else {
        notify(
          "error",
          intl.formatMessage({
            id: "label.common.notify.email.fail",
            defaultMessage: "Failed to send email.",
          })
        );
      }
    },
    onError: () => {
      notify(
        "error",
        intl.formatMessage({
          id: "label.common.notify.email.fail",
          defaultMessage: "Failed to send email.",
        })
      );
    },
  });

  const mutationSnapShotSendGuest = useMutation(createSnapShotEmailGuest, {
    onSuccess: (res: any) => {
      if (res.error === 0) {
        notify(
          "success",
          intl.formatMessage({
            id: "label.common.notify.email.success",
            defaultMessage: "Email sent successfully",
          })
        );
        props.onModelSnapshot();
      } else {
        notify(
          "error",
          intl.formatMessage({
            id: "label.common.notify.email.fail",
            defaultMessage: "Failed to send email.",
          })
        );
      }
    },
    onError: () => {
      notify(
        "error",
        intl.formatMessage({
          id: "label.common.notify.email.fail",
          defaultMessage: "Failed to send email.",
        })
      );
    },
  });

  const onCaptureSend = useCallback(() => {
    setErrorMessage("");
    if (sendUsers.length === 0) {
      setErrorMessage(
        intl.formatMessage({
          id: "validateMsg.videoShare.err.emptyUser",
          defaultMessage: "Select one or more users or enter email address.",
        })
      );
      return;
    }

    if (props.videoId === undefined) {
      setErrorMessage(
        intl.formatMessage({
          id: "validateMsg.videoShare.err.invalidVideoId",
          defaultMessage: "Video information is invalid.",
        })
      );
      return;
    }
    const formData = new FormData();

    if (props.capturedBlob !== undefined) {
      const uploadFile = blobToFile(
        props.capturedBlob,
        "snapshot_" + props.capturedVtt + ".png"
      );
      formData.append("snapshot", uploadFile);
      const valueArr: string[] = [];
      const emailArr = sendUsers.forEach((item) => {
        valueArr.push(item.value);
      });
      const json = JSON.stringify(valueArr);
      const blob = new Blob([json], { type: "application/json" });
      formData.append("users", blob);

      const json2 = JSON.stringify(props.capturedVtt);
      const blob2 = new Blob([props.capturedVtt as string], {
        type: "application/text",
      });
      formData.append("snapshotTime", blob2);

      const json3 = JSON.stringify(postContent);
      const blob3 = new Blob([postContent], { type: "application/text" });
      formData.append("message", blob3);

      if (props.isAuth) {
        mutationSnapShotSend.mutate({
          form: formData,
          accountId: selectedAccount.accountId,
          videoId: props.videoId,
        });
      } else {
        mutationSnapShotSendGuest.mutate({
          form: formData,
          videoId: props.videoId,
        });
      }
    }
  }, [
    intl,
    mutationSnapShotSend,
    mutationSnapShotSendGuest,
    props.capturedBlob,
    props.capturedVtt,
    props.isAuth,
    props.videoId,
    selectedAccount.accountId,
    sendUsers,
  ]);

  return (
    <SmallPopupTemplate
      title="Snapshot"
      subTitle="Download or share a still image from this video clip."
      onClick={onCaptureSend}
      onModal={props.onModelSnapshot}
      confirmLabel="Send"
    >
      <s.SnapshotImage>
        {<img className="capture-image" src={imageUrl} alt="captured_image" />}
        <div className="snapshot-bar">
          {/* 시간 */}
          <span>{changeDateToFormat(moment())}</span>

          <TinyButton
            label="Save Image"
            onClickBtn={props.capture}
            icon={<img src={DownloadIcon} alt="capture_download" />}
          />
        </div>
      </s.SnapshotImage>
      <s.SendBox>
        <Text bold>Send Snapshot</Text>
        {props.isAuth ? (
          <EmailAddSelect
            //placeholder="Select User or Enter Email Address"
            onChange={onSelectUser}
            isVs
            isMulti
          />
        ) : (
          <Input
            placeholder="Enter email address."
            pattern="[A-Za-z]+"
            maxLength={50}
            onChange={(e) => onChangeEmail(e.currentTarget.value)}
          />
        )}
        <Text bold>Message</Text>
        <TextArea
          placeholder="Enter your message here."
          rows={4}
          value={postContent}
          onChange={(e) => setPostContent(e.target.value)}
        />
      </s.SendBox>
      <ValidateWord>{errorMessage}</ValidateWord>
    </SmallPopupTemplate>
  );
}
