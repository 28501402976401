import { useState } from "react";
import { Theme } from "redux/reducers/theme/themeReducer";

export function useLocalStorage<T>(key: string, initialValue: T, ttl?: number) {
  const [storedValue, setStoredValue] = useState<T>(() => {
    if (typeof window === "undefined") {
      return initialValue;
    }
    try {
      const item = window.localStorage.getItem(key);
      return item ? JSON.parse(item) : initialValue;
    } catch (error) {
      console.error(error);
      return initialValue;
    }
  });
  const setValue = (value: T | ((val: T) => T), ttl?: number) => {
    try {
      const valueToStore =
        value instanceof Function ? value(storedValue) : value;
      setStoredValue(valueToStore);
      if (typeof window !== "undefined") {
        window.localStorage.setItem(key, JSON.stringify(valueToStore));
      }
    } catch (error) {
      console.error(error);
    }
  };
  return [storedValue, setValue] as const;
}

// 만료 시간과 함께 데이터를 저장
export function setItemWithExpireTime(
  keyName: string,
  keyValue: string,
  tts: number
) {
  const obj = {
    value: keyValue,
    expire: Date.now() + tts,
  };

  // 객체를 JSON 문자열로 변환
  const objString = JSON.stringify(obj);

  // setItem
  window.localStorage.setItem(keyName, objString);
}

export function getItemFromLocalStorage(keyName: string) {
  const objString = window.localStorage.getItem(keyName);

  if (!objString) {
    return null;
  }
  const obj = JSON.parse(objString);

  return obj;
}
// 만료 시간을 체크하며 데이터 읽기
export function getItemWithExpireTime(keyName: string) {
  const objString = window.localStorage.getItem(keyName);

  if (!objString) {
    return null;
  }

  const obj = JSON.parse(objString);

  if (Date.now() > obj.expire) {
    window.localStorage.removeItem(keyName);
    return null;
  }
  return obj.value;
}

// export const setTheme = (theme: "dark" | "light", userId: string) => {
//   let obj = {
//     theme: theme,
//     userId: userId,
//   };

//   let objString = JSON.stringify(obj);
//   window.localStorage.setItem("theme", objString);
// };

// export const getTheme = (user: string) => {
//   const objString = window.localStorage.getItem("theme");

//   if (!objString) {
//     return null;
//   }
//   const obj = JSON.parse(objString);

//   console.log(obj, "get theme");
//   return obj;
// };
