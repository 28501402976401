import * as cs from "../../SignCommonStyled";
import { useIntl } from "react-intl";

interface Props {
  onClickBackLogin: () => void;
}

export default function ResetPasswordSuccess(props: Props): JSX.Element {
  const { onClickBackLogin } = props;
  const intl = useIntl();

  return (
    <>
      <div className="description">
        Your password has been reset. <br />
        You can now go back to the login screen and login to myDW.
      </div>

      <cs.BigButton
        id="dw_signup_button_forward_login"
        type="button"
        onClick={onClickBackLogin}
      >
        {intl.formatMessage({
          id: "email_sent_back",
          defaultMessage: "Return to Login Screen",
        })}
      </cs.BigButton>
    </>
  );
}
