import { TableTemplate } from "components/templates/default/table/TableTemplate";
import * as s from "./InstallStyled";
import BigButton from "components/atoms/buttons/BigButton";
import EXPORT_ICON from "assets/icons/export.svg";
import { CenterlineText, Text, ValidateWord } from "components/atoms/text/Text";
import COPY_ICON from "assets/icons/copy.svg";
import { COLORS } from "styles/colors";
import { useNavigate } from "react-router-dom";
import DefaultThumbnail from "components/atoms/image/DefaultThumbnail";
import { useMediaQuery } from "react-responsive";
import * as mediaQuery from "components/MediaQuery";
import { InstallationView } from "api/interfaces/reportInterface.interface";
import { convertType, getDomainUrl, openInNewTab } from "utils/functions";
import VideoThumbnail from "components/atoms/image/thumbnail/VideoThumbnail";
import REFRESH_ICON from "assets/icons/refresh_gray.svg";
import Paragraph from "antd/lib/typography/Paragraph";
import { ClickSearch } from "components/atoms/input/search/SearchInput";
import { Button, Spin } from "antd";
import SmallPopupTemplate from "components/templates/modal/small/SmallPopupTemplate";
import { ModalInput } from "components/atoms/input/modal/ModalInput";
import TextArea from "components/atoms/input/TextArea";
import EmailAdd from "components/atoms/select/email";
import { MultiValue } from "react-select";
import { useIntl } from "react-intl";
import NormalConfirmModal from "components/templates/modal/small/confirm/normal";
import Spinner from "components/atoms/loader/Spinner";
import { useAppSelector } from "redux/hooks";
import { menuNavigation } from "utils/MenuUtil";
import { ProfileAccountInfo } from "api/interfaces/accountInterface.interface";

type Props = {
  title: string;
  installationData: InstallationView;
  print: () => void;
  email: () => void;
  componentRef: any;
  onClickRefresh: () => void;
  onClickComplete: () => void;
  onClickSearch: ClickSearch;
  isUpdating: boolean;
  isSendEmail: boolean;
  onSelectEmail: (user: MultiValue<any>) => void;
  onChangeEmailMsg: (e: any) => void;
  setIsSendEmailModal: (flag: boolean) => void;
  onClickSendEmail: () => void;
  isValid: boolean;
  isValidEmail: boolean;
  confirmFinish: boolean;
  onConfirmCancel: () => void;
  onConfirm: () => void;
  isRefreshing: boolean;
  removedRecorder: boolean;
  selectedAccount: ProfileAccountInfo
};

export default function InstallPresenter(props: Props): JSX.Element {
  const intl = useIntl();
  const navigate = useNavigate();
  const theme = useAppSelector((state) => state.theme.theme);

  //TODO Send Email 2.0.x later
  const buttons = (
    <s.ButtonWrapper>
      <mediaQuery.Default>
        <BigButton
          label="Print"
          buttonType="cancel"
          onClickBtn={props.print}
          icon={EXPORT_ICON}
        />
      </mediaQuery.Default>
      <BigButton
        label="Send Email"
        buttonType="cancel"
        onClickBtn={props.email}
      />
    </s.ButtonWrapper>
  );

  const titleComponent = [
    {
      label: "Report List",
      onClick: () => menuNavigation(props.selectedAccount, navigate, "/reports"),
    },
    {
      // 추후 변경 필요 recorder name 으로
      label: "Installation Report",
    },
  ];
  const isMobile = useMediaQuery({ maxWidth: mediaQuery.mobileMax });

  const headerFontSize = isMobile ? 16 : 18;
  const itemFontSize = isMobile ? 16 : 12;

  return (
    <TableTemplate
      detail
      buttons={buttons}
      titleComponent={titleComponent}
      onClickSearch={props.onClickSearch}
    >
      <s.Container ref={props.componentRef}>
        <s.Top>
          <s.Header>
            {/* 추후 맵핑으로 변경 */}

            <s.HeaderItem>
              <Text fontSize={headerFontSize} bold>
                Recorder
              </Text>
              <Text fontSize={14} color="#828B9B">
                {props.installationData.recorderInfo.name
                  ? props.installationData.recorderInfo.name
                  : "-"}
              </Text>
            </s.HeaderItem>

            <s.HeaderItem>
              <Text fontSize={headerFontSize} bold>
                Model
              </Text>
              <Text fontSize={14} color="#828B9B">
                {props.installationData.recorderInfo.model !== undefined
                  ? props.installationData.recorderInfo.model
                  : // ? convertType(props.installationData.recorderInfo.model)
                    "N/A"}
              </Text>
            </s.HeaderItem>
            <s.HeaderItem>
              <Text fontSize={headerFontSize} bold>
                Installation Date
              </Text>
              <Text fontSize={14} color="#828B9B">
                {props.installationData.installationReport.installationDate}
              </Text>
            </s.HeaderItem>
            <s.HeaderItem>
              <Text fontSize={headerFontSize} bold>
                Location
              </Text>
              <Text fontSize={14} color="#828B9B">
                {props.installationData.recorderInfo.location}
              </Text>
            </s.HeaderItem>
          </s.Header>

          <mediaQuery.Default>
            <s.ButtonWrapper>
              <Spin spinning={props.isUpdating}>
                <BigButton
                  label={"Refresh"}
                  onClickBtn={props.onClickRefresh}
                  disabled={
                    !props.installationData.installationReport.updatable ||
                    props.removedRecorder ||
                    props.isRefreshing
                  }
                />
              </Spin>
              <BigButton
                label={"Complete"}
                onClickBtn={props.onClickComplete}
                disabled={
                  !props.installationData.installationReport.updatable ||
                  props.removedRecorder
                }
              />
            </s.ButtonWrapper>
          </mediaQuery.Default>
        </s.Top>
        <s.Grid>
          {/* {props.isRefreshing && (
            <s.SpinnerContainer>
              <Spinner  size={30} aria-label="Loading Spinner"/>
            </s.SpinnerContainer>
          )} */}
          <s.LeftAside>
            {props.installationData?.cameraInfo !== undefined &&
              props.installationData?.cameraInfo.map((item, index) => {
                return (
                  <s.CameraItem key={`install-data-${index}`}>
                    <DefaultThumbnail>
                      <VideoThumbnail
                        videoLoss={false}
                        thumbnailUrl={item.thumbnailUrl}
                        //errorType={item.installationStatus}
                        installationStatus={item.installationStatus}
                        isRefreshing={props.isRefreshing}
                      />
                      {/* <img
                    src={item.url}
                    alt="camera_thumbnail"
                  /> */}
                    </DefaultThumbnail>
                    <s.CameraContent>
                      <s.CameraContentRow>
                        <Text
                          className="title"
                          fontSize={itemFontSize}
                          bold
                          color="#818CA8"
                        >
                          Camera
                        </Text>
                        <Text className="content" fontSize={itemFontSize}>
                          {item.name}
                        </Text>
                      </s.CameraContentRow>
                      <s.CameraContentRow>
                        <Text
                          className="title"
                          fontSize={itemFontSize}
                          bold
                          color="#818CA8"
                        >
                          Model
                        </Text>
                        <Text className="content" fontSize={itemFontSize}>
                          {item.model}
                        </Text>
                      </s.CameraContentRow>
                      {/* <s.CameraContentRow>
                      <Text
                        className="title"
                        fontSize={itemFontSize}
                        bold
                        color="#818CA8"
                      >
                        Web
                      </Text>
                      <Text
                        className="ip-address content"
                        fontSize={itemFontSize}
                      >
                        <Paragraph copyable>{getDomainUrl(item.url)}</Paragraph>
                      </Text> */}
                      {/*TODO pathfinder 기능완료 후 연결
                    <s.Copy className="content">
                      <div className="ip-address">
                        <span>{item.url}</span>
                      </div>
                      <button>
                        <img
                          className="copy"
                          src={COPY_ICON}
                          alt="copy icon"
                          onClick={(e) => {
                            if (item.url !== undefined) {
                              openInNewTab(item.url);
                            }
                          }}
                        />
                      </button>
                    </s.Copy> */}
                      {/* </s.CameraContentRow> */}
                    </s.CameraContent>
                  </s.CameraItem>
                );
              })}
          </s.LeftAside>
          <s.RightAside>
            {/* 추후 mapping 으로 변경필요 */}
            <s.RightWrapper>
              <CenterlineText
                fontSize={18}
                color={theme === "light" ? COLORS.COMPANY : COLORS.SELECTED}
                bold
                border={3}
              >
                Customer
              </CenterlineText>
              <s.RightItem>
                <Text className="title" color="#818CA8" bold>
                  Name
                </Text>

                <Text className="content">
                  {props.installationData.installationReport.customerName}
                </Text>
              </s.RightItem>
              <s.RightItem>
                <Text className="title" color="#818CA8" bold>
                  Phone
                </Text>

                <Text className="content">
                  {
                    props.installationData.installationReport
                      .customerPhoneNumber
                  }
                </Text>
              </s.RightItem>
              <s.RightItem noBorder>
                  <Text className="title" color="#818CA8" bold>
                    Address
                  </Text>
                  <Text className="content address">
                    {
                      props.installationData.installationReport
                        .customerLocation
                    }
                  </Text>
                </s.RightItem>
            </s.RightWrapper>

            <s.RightWrapper>
              <CenterlineText
                fontSize={18}
                color={theme === "light" ? COLORS.COMPANY : COLORS.SELECTED}
                bold
                border={3}
              >
                Installer
              </CenterlineText>

              <s.RightItem>
                <Text className="title" color="#818CA8" bold>
                  Name
                </Text>
                <Text className="content">
                  {props.installationData.installationReport.installerName}
                </Text>
              </s.RightItem>

              <s.RightItem>
                <Text className="title" color="#818CA8" bold>
                  Phone
                </Text>
                <Text className="content">
                  {
                    props.installationData.installationReport
                      .installerPhoneNumber
                  }
                </Text>
              </s.RightItem>

              <div>
                <s.RightItem>
                  <Text className="title" color="#818CA8" bold>
                    Company
                  </Text>
                  <Text className="content">
                    {props.installationData.installationReport.installerCompany}
                  </Text>
                </s.RightItem>
                <s.RightItem>
                  <Text className="title" color="#818CA8" bold>
                    Phone
                  </Text>
                  <Text className="content">
                    {
                      props.installationData.installationReport
                        .installerCompanyPhoneNumber
                    }
                  </Text>
                </s.RightItem>
                <s.RightItem noBorder>
                  <Text className="title" color="#818CA8" bold>
                    Address
                  </Text>
                  <Text className="content address">
                    {
                      props.installationData.installationReport
                        .installerCompanyAddress
                    }
                  </Text>
                </s.RightItem>
              </div>
            </s.RightWrapper>
          </s.RightAside>
        </s.Grid>
      </s.Container>
      {
        // 조건 추가 assigned &&
        props.isSendEmail && (
          <SmallPopupTemplate
            cancel
            title="Send Report Email"
            subTitle="Email this report to one or more participants"
            onClick={() => props.onClickSendEmail()}
            onModal={() => props.setIsSendEmailModal(false)}
            confirmLabel="Send"
          >
            <ModalInput label="Email" essential>
              <EmailAdd
                //placeholder="Select User or Enter Email Address"
                onChange={props.onSelectEmail}
                isMulti
                // isSelectedArr={state.user}
              />
            </ModalInput>
            <ModalInput label="Message" >
              <TextArea
                placeholder="Enter your message here"
                onChange={props.onChangeEmailMsg}
              />
            </ModalInput>
            {!props.isValid && (
              <ValidateWord>
                {intl.formatMessage({
                  id: "validateMsg.videoShare.err.emptyUser",
                  defaultMessage:
                    "Select one or more users or enter email address.",
                })}
              </ValidateWord>
            )}
            {!props.isValidEmail && (
              <ValidateWord>
                {intl.formatMessage({
                  id: "validateMsg.videoShare.err.invalidEmail",
                  defaultMessage: "The entered email is invalid.",
                })}
              </ValidateWord>
            )}
          </SmallPopupTemplate>
        )
      }
      {props.confirmFinish && (
        <NormalConfirmModal
          label={"install"}
          onConfirm={props.onConfirm}
          onConfirmCancel={props.onConfirmCancel}
        />
      )}
    </TableTemplate>
  );
}
