import styled from "styled-components";
import { FLEX } from "styles/flex";
import { COLORS } from "styles/colors";
import Status from "components/atoms/text/labels/Status";
import { Text, UnderlineText } from "components/atoms/text/Text";
import Cloud from "components/atoms/text/labels/Cloud";
import * as mediaQuery from "components/MediaQuery";
import { useLocation } from "react-router-dom";
import { useAppSelector } from "redux/hooks";
import { isEmpty } from "lodash";
import { WidgetRecorderList } from "api/interfaces/widgetInterface.interface";
import { checkStatus } from "utils/functions";
import { Dropdown } from "antd";

export type TableTitleProps = {
  cloudSystemId?: string;
  firmwareVersion?: string;
  recorderCount?: number;
  onlineStatusCount?: number;
  recorders?: {
    recorderId: string;
    name: string;
    brand: string;
    accountName: string;
    accountNumber: string;
  }[];
  mergedSystemName: string;
  type: string;
};

function DashboardTableTitle(props: TableTitleProps): JSX.Element {
  const location = useLocation();
  const theme = useAppSelector((state) => state.theme.theme);
  const isLight = theme === "light";

  const status =
    props.recorderCount !== undefined && props.onlineStatusCount !== undefined
      ? checkStatus(props.recorderCount, props.onlineStatusCount)
      : "offline";

  const items =
    props.recorders === undefined
      ? []
      : props.recorders.map((rec) => {
          return {
            key: rec.recorderId,
            label: rec.name,
          };
        });

  return (
    <TitleContainer>
      <TitleWrapper>
        <NameLabel>
          <div className="icon-wrapper">
            {props.recorderCount !== undefined &&
              props.onlineStatusCount !== undefined && (
                <Status status={status} />
              )}
            <mediaQuery.Default>
              {!isEmpty(props.cloudSystemId) &&
                (props.type === "Spectrum" || props.type === "SPECTRUM") && (
                  <Cloud size={20} />
                )}
            </mediaQuery.Default>
          </div>

          <Text className="title">{props.mergedSystemName}</Text>
        </NameLabel>
      </TitleWrapper>
      {(props.type === "Spectrum" || props.type === "SPECTRUM") &&
        props.recorderCount !== undefined &&
        props.recorderCount > 1 && (
          <Dropdown
            menu={{
              items,
              // style: {
              //   fontSize: 12,
              //   paddingBlock: 4,
              // },
            }}
          >
            <UnderlineText
              color={isLight ? COLORS.BUTTONS2 : COLORS.SELECTED}
              fontSize={14}
              className="recorder-underline"
            >
              Recorders
              <mediaQuery.Default>: {props.recorderCount}</mediaQuery.Default>
            </UnderlineText>
          </Dropdown>
        )}
      {props.firmwareVersion !== undefined && (
        <mediaQuery.Default>
          <Text fontSize={12} color="#828B9B">
            [{props.firmwareVersion}]
          </Text>
        </mediaQuery.Default>
      )}
    </TitleContainer>
  );
}

const NameLabel = styled.div`
  ${FLEX.FlexStartCenter};
  width: 100%;
  gap: 4px;
  flex: 1 1 auto;

  .icon-wrapper {
    ${FLEX.FlexStartCenter};
    flex: 0 0 auto;
    gap: 4px;
  }

  .title {
    /* width: 100%; */
    flex: 1 1 auto;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 100%;
  }
`;

const TitleContainer = styled.div`
  width: 100%;
  max-width: 100%;

  .recorder-underline {
    flex: 0 0 auto;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 150px;
    padding-bottom: 3px;
  }
`;
const TitleWrapper = styled.div`
  ${FLEX.FlexBetweenCenter};
  width: 100%;
  flex-wrap: nowrap;
`;

export default DashboardTableTitle;
