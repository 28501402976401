export enum SORT_DIRECTION {
  ASC = "ASC",
  DESC = "DESC",
}

export interface BasePagination {
  total: number;
  pageNum: number;
  pageLimit: number;
  keyword?: string;
  sortType: string;
  sortDirection: SORT_DIRECTION;
  type?: string | null;
  category?: string | null;
  status?: string | null;
  tags?: string;
}

export interface DefaultQueryParam {
  pageLimit: number;
  keyword :string;
  pageNum : number;
  sortType: string;
  sortDirection: SORT_DIRECTION;
  tags?:string | null;
  type?: string | null;
  status?:string | null;
  category?: string | null;
}

export enum HTTP_RESULT {
  OK = "OK",
  NOK = "NOK",
}

export enum SORT_TYPE {
  ASC = "ASC",
  DESC = "DESC",
}

export enum LEVEL_TYPE {
  DW = "DW",
  EU = "EU",
  CP = "CP",
  INTL = "",
}
export interface GetListQuery {
  pageNum: number;
  pageLimit: number;
  keyword: string;
  sortType: string;
  sortDirection: SORT_TYPE;
  accountId: string;
}

export enum ALERT_DETECT_TIME {
  DAY = "DAY",
  HOUR = "HOUR",
  MIN = "MINUTE",
}

export enum REPORT_DETECT_TIME {
  WEEK = "WEEK",
  MONTH = "MONTH",
}

export enum REPORT_TYPE {
  HEALTH = "Health",
  CVV = "CVV",
  THUMBNAIL = "Thumbnail",
  INVENTORY = "Inventory",
  INSTALLATION = "Installation",
  TIMELAPSE = "TIMELAPSE",
}

export const ACCESSTYPE = {
  DIRECT: "Direct",
  CLOUD: "Cloud",
} as const;
export type ACCESSTYPE = typeof ACCESSTYPE[keyof typeof ACCESSTYPE];

export interface Validate {
  [key: string]: boolean;
}
export interface ValidateMsg {
  [key: string]: string;
}

export interface CustomError extends Error {
  response?: {
    data: {
      error: number;
      errorString: string;
      result:any;
    };
    status: number;
    headers: string;
  };
}

export interface CommonResponse {
  error: number;
  errorString: string;
}

export interface CommonPageResponse {
  error: number;
  errorString: string;
  page : PageResponse
}

export interface PageResponse {
  total: number;
  pageNum: number;
  pageLimit: number;
  keyword: string;
  sortType: SORT_TYPE;
  sortDirection: SORT_DIRECTION;
}

export interface LicenseCameraStatus {
  totalCameraCount: number,
  totalLicensedCameraCount: number
}

export enum TECH_USER_TYPE {
  consumerEU = "consumerEU",
  corporateEU = "corporateEU",
  distributor = "distributor",
  installer = "installer",
}