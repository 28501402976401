import styled from "styled-components";
import { COLORS } from "styles/colors";
import { calcRem } from "styles/theme";

type RecorderTitleProps = {
  mergedSystemName: string;
  recorderName: string;
  type?: string;
};

function RecorderTitle(props: RecorderTitleProps): JSX.Element {
  return (
    <AlertTitleName data-tag="allowRowEvents">
      {props.type !== undefined ? (
        props.type !== "Spectrum" ? (
          <span data-tag="allowRowEvents" >{props.mergedSystemName}</span>
        ) : (
          <span data-tag="allowRowEvents">
            {props.mergedSystemName}
            <span className="server-name" data-tag="allowRowEvents"> {props.recorderName}</span>
          </span>
        )
      ) : props.mergedSystemName === props.recorderName ? (
        <span data-tag="allowRowEvents">{props.mergedSystemName}</span>
      ) : (
        <span data-tag="allowRowEvents">
          {props.mergedSystemName}
          <span className="server-name" data-tag="allowRowEvents"> {props.recorderName}</span>
        </span>
      )}
    </AlertTitleName>
  );
}

export default RecorderTitle;

const AlertTitleName = styled.div`
  /* flex: 6; */
  width: 80%;
  color: ${({ theme }) => theme.COLORS.WHITE};
  font-size: ${calcRem(14)};
  line-height: 120%;

  .server-name {
    color: ${COLORS.BUTTONS1};
    font-size: ${calcRem(13)};
  }
`;
