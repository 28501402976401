import styled from "styled-components";
import { COLORS } from "styles/colors";
import { FLEX } from "styles/flex";
import { calcRem } from "styles/theme";

export const VGroupContainer = styled.div`
  min-height: 100px;
  max-height: 35vh;
  max-width: 600px !important;
  overflow: auto;
  /* scrollbar-color: rebeccapurple green; */
  font-size: ${calcRem(14)};
  color: ${({ theme }) => theme.COLORS.WHITE};

  table {
    table-layout: fixed;
    width: 100%;
    color: #ffffff;
  }

  thead {
    background: ${({ theme }) => theme.COLORS.PRIMARY3};
    margin: 5;
    position: sticky;
    top: 0;
    font-size: ${calcRem(12)};
    color: ${({ theme }) => theme.COLORS.WHITE};
  }

  th {
    padding: 8px;
    text-align: left;
    vertical-align: middle;
    height: 40px;
    font-weight: 500;
  }

  td {
    padding: 8px;
    color: ${({ theme }) => theme.COLORS.WHITE};
  }

  /* ::-webkit-scrollbar {
    width: 10px; // 스크롤바의 너비
  }

  ::-webkit-scrollbar-thumb {
    height: 30%; // 스크롤바의 길이
    background: #232b44; //스크롤바의 색상

    border-radius: 7px;
  }

  ::-webkit-scrollbar-track {
    background: #1d274054; // 스크롤바 뒷 배경 색상
  } */

  .th-row {
    ${FLEX.FlexStartCenter};
    gap: 6px;
  }
`;
