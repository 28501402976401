import { KeyboardEvent, useCallback, useState } from "react";
import styled from "styled-components";
import styles from "./AddressInput.module.css";
import { FLEX } from "styles/flex";
import usePlacesAutocomplete, {
  getGeocode,
  getLatLng,
} from "use-places-autocomplete";
import useOnclickOutside from "react-cool-onclickoutside";
import Input from "../Input";
import { isEmpty } from "lodash";

const Wrapper = styled.div`
  ${FLEX.FlexBetweenCenter};
  width: 100%;
  display: inline-block;
`;

let cachedVal = "";
const acceptedKeys = ["ArrowUp", "ArrowDown", "Escape", "Enter"];
type Suggestion = google.maps.places.AutocompletePrediction;

const PlacesAutocomplete = (props: {
  onChangeLocationSelect: (
    address: google.maps.LatLngLiteral,
    addressString: string
  ) => void;
  onChange?: (e: any) => void;
  defaultValue?: string;
  isDisabled?: boolean;
  value?: string;
}): JSX.Element => {
  const [currIndex, setCurrIndex] = useState<number | null>(null);
  const {
    ready,
    value,
    suggestions: { status, data },
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete({
    requestOptions: {
      /* Define search scope here */
    },
    debounce: 300,
  });

  const hasSuggestions = status === "OK";

  const dismissSuggestions = () => {
    setCurrIndex(null);
    clearSuggestions();
  };

  const ref = useOnclickOutside(dismissSuggestions);

  const handleInput = (e: any) => {
    // Update the keyword of the input element
    setValue(e.target.value);
    if (props.onChange) {
      props.onChange(e);
    }
  };

  const handleEnter = (idx: number) => () => {
    setCurrIndex(idx);
  };

  const handleLeave = () => {
    setCurrIndex(null);
  };

  const handleSelect =
    ({ description }: any) =>
    () => {
      // When user selects a place, we can replace the keyword without request data from API
      // by setting the second parameter to "false"
      setValue(description, false);
      clearSuggestions();

      // Get latitude and longitude via utility functions
      getGeocode({ address: description }).then((results) => {
        const { lat, lng } = getLatLng(results[0]);
        props.onChangeLocationSelect(
          { lat, lng },
          results[0].formatted_address
        );
      });
    };

  const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    if (!hasSuggestions || !acceptedKeys.includes(e.key)) return;

    if (e.key === "Enter" || e.key === "Escape") {
      dismissSuggestions();
      return;
    }

    let nextIndex: number | null;

    if (e.key === "ArrowUp") {
      e.preventDefault();
      nextIndex = currIndex ?? data.length;
      nextIndex = nextIndex && nextIndex > 0 ? nextIndex - 1 : null;
    } else {
      nextIndex = currIndex ?? -1;
      nextIndex = nextIndex < data.length - 1 ? nextIndex + 1 : null;
    }

    setCurrIndex(nextIndex);
    // @ts-ignore
    setValue(data[nextIndex] ? data[nextIndex].description : cachedVal, false);
  };

  const renderSuggestions = (): JSX.Element => {
    const suggestions = data.map((suggestion: Suggestion, idx: number) => {
      const {
        place_id,
        structured_formatting: { main_text, secondary_text },
      } = suggestion;

      return (
        // eslint-disable-next-line jsx-a11y/click-events-have-key-events
        <li
          key={place_id}
          id={`ex-list-item-${idx}`}
          className={styles.list_item}
          onClick={handleSelect(suggestion)}
          onMouseEnter={handleEnter(idx)}
          role="option"
          aria-selected={idx === currIndex}
          value={props.value}
        >
          <strong>{main_text}</strong>
          <small className={styles.sub_text}>{secondary_text}</small>
        </li>
      );
    });

    return <>{suggestions}</>;
  };

  const handleKeyPress = useCallback(
    (e: React.KeyboardEvent) => {
      if (e.key === "Enter") {
        if (value !== undefined && !isEmpty(props.value)) {
          setValue(value, false);
          clearSuggestions();

          // Get latitude and longitude via utility functions
          getGeocode({ address: value }).then((results) => {
            const { lat, lng } = getLatLng(results[0]);
            props.onChangeLocationSelect(
              { lat, lng },
              results[0].formatted_address
            );
          });
        }
      }
    },
    [clearSuggestions, value]
  );

  // const searchRef = useRef<any>(null);
  // useEffect(() => {
  //   // 특정 영역 외 클릭 시 발생하는 이벤트
  //   function handleFocus(e: any) {
  //     if (searchRef.current !== null && !searchRef.current.contains(e.target)) {
  //       // input 체크 해제
  //       alert("click");
  //     }
  //   }

  //   // 이벤트 리스너에 handleFocus 함수 등록
  //   document.addEventListener("mouseup", handleFocus);
  //   return () => {
  //     document.removeEventListener("mouseup", handleFocus);
  //   };
  // }, [searchRef]);

  return (
    <Wrapper
      ref={ref}
      id="addrInput"
      // eslint-disable-next-line jsx-a11y/role-has-required-aria-props
      role="combobox"
      aria-owns="ex-list-box"
      aria-haspopup="listbox"
      aria-expanded={hasSuggestions}
    >
      <Input
        // ref={searchRef}
        maxLength={200}
        autoComplete="list"
        value={props.value}
        onChange={handleInput}
        onKeyDown={handleKeyDown}
        disabled={props.isDisabled ? props.isDisabled : !ready}
        defaultValue={props.defaultValue}
        placeholder="Enter Address"
        type="text"
        aria-autocomplete="list"
        aria-controls="ex-list-box"
        aria-activedescendant={
          currIndex !== null ? `ex-list-item-${currIndex}` : undefined
        }
        onKeyUp={handleKeyPress}
      />
      {hasSuggestions && (
        <ul
          className={styles.addr_ul}
          id="ex-list-box"
          onMouseLeave={handleLeave}
          role="listbox"
        >
          {renderSuggestions()}
        </ul>
      )}
    </Wrapper>
  );
};

const AddressInput = (props: {
  isLoaded: boolean;
  onChangeLocationSelect: (
    address: google.maps.LatLngLiteral,
    addressString: string
  ) => void;
  onChange?: (e: any) => void;
  defaultValue?: string;
  value?: string;
  isDisabled?: boolean;
}): JSX.Element => {
  // const { isLoaded, loadError } = useLoadScript({
  //   googleMapsApiKey:`${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}`,
  //   language:`en`,
  //   libraries :googleMapsLibraries
  // });

  return (
    <>
      {props.isLoaded ? (
        <PlacesAutocomplete
          defaultValue={props.defaultValue}
          onChangeLocationSelect={props.onChangeLocationSelect}
          isDisabled={props.isDisabled}
          value={props.value}
          onChange={props.onChange}
        />
      ) : null}
    </>
  );
};

export default AddressInput;
