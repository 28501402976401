import { Table } from "components/atoms/table/Table";
import { SortOrder, TableColumn } from "react-data-table-component";
import { TableTemplate } from "components/templates/default/table/TableTemplate";
import Status from "components/blocks/status/Status";
import { RecorderHealthRow } from "api/interfaces/reportInterface.interface";
import HealthDetail from "./HealthDetail";
import { Routes, Route, useLocation, useNavigate } from "react-router-dom";
import {
  alertStatus,
  Circle,
  healthAlertStatus,
} from "pages/dashboard/map/sub/subheader/filters/status/StatusFilter";
import {
  StatusLabel,
  StatusName,
  StatusValue,
} from "components/blocks/status/Status";
import * as s from "./HealthStyled";
import DetailButton from "components/atoms/buttons/detail/DetailButton";
import { ClickSearch } from "components/atoms/input/search/SearchInput";
import * as mediaQuery from "components/MediaQuery";
import { BasePagination } from "api/interfaces/commonInterface.interface";
import { Fragment } from "react";
import { ProfileAccountInfo } from "api/interfaces/accountInterface.interface";
import { menuNavigation } from "utils/MenuUtil";
import { useMediaQuery } from "react-responsive";

interface Count {
  [key: string]: number;
}

type Props = {
  title: string | undefined;
  columns: TableColumn<RecorderHealthRow>[];
  mobileColumns: TableColumn<RecorderHealthRow>[];
  data: RecorderHealthRow[];
  totalCount: Count;
  alertDetail: Count;
  isDetail: boolean;
  // mergedState: any;
  reportId: string;
  mergedSystemName: string;
  onRowClicked: (row: any) => void;
  onChangePage: (page: number, totalRows: number) => void;
  handleSort: (
    selectedColumn: TableColumn<any>,
    sortDirection: SortOrder
  ) => void;
  onClickSearch: ClickSearch;
  paginationInfo: BasePagination;
  selectedAccount: ProfileAccountInfo;
  tablePending : boolean;
};

export default function HealthPresenter(props: Props): JSX.Element {
  const location = useLocation();
  const navigate = useNavigate();

  //status
  const status = {
    totalTitle: "",
    items: healthAlertStatus.map((stat, idx) => {
      return (
        (stat.value === "healthy" || stat.value === "alert") && (
          <StatusLabel key={idx}>
            <Circle bgColor={stat.color} style={{ width: 10, height: 10 }} />
            <StatusName>{stat.label}</StatusName>
            <StatusValue color={stat.color}>
              {props.totalCount[stat.value]}
            </StatusValue>
          </StatusLabel>
        )
      );
    }),
  };

  const detailItems = [
    {
      name: "system",
      value: props.alertDetail.system,
      label: "System Alerts",
    },
    {
      name: "disk",
      value: props.alertDetail.disk,
      label: "Disk Alerts",
    },
    {
      name: "video",
      value: props.alertDetail.video,
      label: "Video Alerts",
    },
  ];

  const centerChildren = (
    <s.StatusWrapper>
      <Status status={status} />
      <Fragment />
      <mediaQuery.Default>
        <DetailButton
          title={"Health problems by category"}
          contents={detailItems}
        />
      </mediaQuery.Default>
    </s.StatusWrapper>
  );
  const isNotMobile = useMediaQuery({ minWidth: mediaQuery.tabletMin });

  const titleComponent = props.isDetail
    ? [
        {
          label: "Report List",
          onClick: () =>
            menuNavigation(props.selectedAccount, navigate, "/reports"),
          //navigate("/reports"),
        },
        {
          label: props.title,
          onClick: () =>
            menuNavigation(
              props.selectedAccount,
              navigate,
              `/reports/health/${props.reportId}`
            ),
        },
        {
          label: props.mergedSystemName,
          isNoshow: !isNotMobile,
        },
      ]
    : [
        {
          label: "Report List",
          onClick: () =>
            menuNavigation(props.selectedAccount, navigate, "/reports"),
          //navigate("/reports"),
        },
        {
          label: props.title,
        },
      ];

  const Health = (
    <TableTemplate
      centerChildren={centerChildren}
      titleComponent={titleComponent}
      onClickSearch={props.onClickSearch}
      keyword={props.paginationInfo.keyword}
      detail
    >
      <mediaQuery.Default>
        <Table
          columns={props.columns}
          data={props.data}
          onRowClicked={(row) => props.onRowClicked(row)}
          defaultSortFieldId={"name"}
          handleSort={props.handleSort}
          // paginationPerPage={emailRecipientQueryInfo.pageLimit}
          paginationTotalRows={props.totalCount["total"]}
          isRowClickEditable={true}
          progressPending={props.tablePending}
        />
      </mediaQuery.Default>
      <mediaQuery.Mobile>
        <Table
          columns={props.mobileColumns}
          data={props.data}
          onRowClicked={(row) => props.onRowClicked(row)}
          defaultSortFieldId={"name"}
          handleSort={props.handleSort}
          paginationTotalRows={props.totalCount["total"]}
          isRowClickEditable={true}
          progressPending={props.tablePending}
        />
      </mediaQuery.Mobile>
    </TableTemplate>
  );

  return (
    <Routes>
      <Route path="/" element={Health} />
      <Route path="merged" element={Health} />
      <Route path="detail" element={<HealthDetail />} />
    </Routes>
  );
}
