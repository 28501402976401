import React, { useEffect, useState, useRef, useCallback } from 'react';
import styled from 'styled-components';
import { URLSearchParams } from 'url';

type IWindowProps = {
  url: string;
  title: string;
  width: number;
  height: number;
};

type IPopupProps = IWindowProps & {
  onClose: () => void;
  onCode: (code: string, params: URLSearchParams) => void;
  children: React.ReactNode;
};

const AuthPopup: React.FC<IPopupProps> = ({
  title = '',
  width = 500,
  height = 600,
  url,
  children,
  onCode,
  onClose,
}: IPopupProps) => {
  const [popup, setPopup] = useState<Window | null>(null);

  const handleOpenOAuthPopup = useCallback((): void => {
    const left = window.screenX + (window.outerWidth - width) / 2;
    const top = window.screenY + (window.outerHeight - height) / 2.5;
    const popup = window.open(
      url,
      title,
      `width=${width},height=${height},left=${left},top=${top}`
    );
    popup?.focus();
    setPopup(popup);
  }, [height, title, url, width]);

  const clearPopup = useCallback((): void => {
    if (popup !== null) {
      console.log("clear popup");
      popup.close();
    }
    setPopup(null);
  }, [popup]);

  // useEffect(() => {
  //   const currentUrl = window.location.href;
  //   const searchParams = new URL(currentUrl).searchParams;
  //   const code = searchParams.get("code");
  //   if (code) {
  //     window.opener.postMessage({ code }, window.location.origin);
  //   }
  // }, []);

  useEffect(() => {
    if (popup === null) {
      return;
    }
    const githubOAuthCodeListener = (e: MessageEvent<any>): void => {
      // 동일한 Origin 의 이벤트만 처리하도록 제한
      console.log(e);
      if (e.origin !== window.location.origin) {
        return;
      }
      // const currentUrl = popup.location.href;
      // const params = new URL(currentUrl).searchParams;
      const { code } = e.data;
      if (code) {
        console.log(`The popup URL has URL code param = ${code}`);
        const currentUrl = popup.location.href;
        const params = new URL(currentUrl).searchParams;

        onCode(code,params);
        setPopup(null);
        popup?.close();
        return;
      }
    }
    window.addEventListener("message", githubOAuthCodeListener, false);

    return () => {
      window.removeEventListener("message", githubOAuthCodeListener);
      // popup?.close();
      // setPopup(null);
    };
  }, [popup]);

  return (
    // eslint-disable-next-line
    <Container
      onClick={handleOpenOAuthPopup}
    >
      { children }
    </Container>
  );
};

export default AuthPopup;

const Container = styled.div`
  width: 100%;
  height: 100%;
`; 