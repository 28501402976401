import * as s from "styles/ModalCommonStyled";
import BigPopupTemplate from "components/templates/modal/big/BigPopupTemplate";
import { ModalInput } from "components/atoms/input/modal/ModalInput";
import Input from "components/atoms/input/Input";
import BigButton from "components/atoms/buttons/BigButton";
import { UPDATE_TYPE } from "./AddGroupsContainer";
import { SearchRecorder } from "components/element/SearchRecorder";
import { CreateRecorderGroupRequest } from "api/interfaces/recorderInterface.interface";
import DeleteConfirmModal from "components/templates/modal/small/confirm/delete/DeleteConfirmModal";
import { useCallback } from "react";
import {
  Validate,
  ValidateMsg,
} from "api/interfaces/commonInterface.interface";
import { ValidateWord } from "components/atoms/text/Text";

interface Props {
  onModal: (type?: string) => void;
  accountId: string;
  onChangeRecorderGroupInfo: (
    type: UPDATE_TYPE,
    value: string | string[]
  ) => void;
  onClickCreate: () => void;
  selectData?: CreateRecorderGroupRequest;
  isEditModal?: boolean;
  setIsDeleteConfirm?: (confirm: boolean) => void;
  isConfirm: boolean;
  onDelete: (value: boolean) => void;
  onConfirmDelete: () => void;
  onClickUpdate: () => void;
  recorderGroupValidate: Validate;
  recorderGroupValidateMsg: ValidateMsg;
  initRecorderGroupValidate: (field: string) => void;
  userId: string | undefined;
}
interface InputProps {
  onChangeRecorderGroupInfo: (
    type: UPDATE_TYPE,
    value: string | string[]
  ) => void;
}

export function AddGroupsPresenter(props: Props): JSX.Element {
  return (
    <BigPopupTemplate
      title={props.isEditModal ? "Edit Recorder Group" : "Add Recorder Group"}
      onModal={props.onModal}
    >
      <s.AddWrapper>
        <ModalInput label="Group Name" essential>
          <Input
            placeholder="Enter group name here."
            value={props.selectData?.name}
            isValidated={props.recorderGroupValidate.isRecorderGroupName}
            onChange={useCallback(
              (e: React.ChangeEvent<HTMLInputElement>) => {
                props.onChangeRecorderGroupInfo(
                  UPDATE_TYPE.NAME,
                  e.target.value
                );
              },
              [props]
            )}
            onFocus={() => {
              props.initRecorderGroupValidate("isRecorderGroupName");
            }}
          />
          <ValidateWord>
            {props.recorderGroupValidateMsg.isRecorderGroupName}
          </ValidateWord>
        </ModalInput>

        <ModalInput label="Description">
          <Input
            placeholder="Enter group description here."
            defaultValue={props.selectData?.description}
            onChange={useCallback(
              (e: React.ChangeEvent<HTMLInputElement>) => {
                props.onChangeRecorderGroupInfo(
                  UPDATE_TYPE.DESC,
                  e.target.value
                );
              },
              [props]
            )}
          />
        </ModalInput>

        <ModalInput label="Add Recorder">
          <SearchRecorder
            accountId={props.accountId}
            selectedRow={props.selectData?.mergedSystemIds}
            onSelectedRowsChange={useCallback((value: string[]) => {
              props.onChangeRecorderGroupInfo(UPDATE_TYPE.RECORDER, value);
            }, [])}
            userId={props.isEditModal ? props.selectData?.userId : props.userId}
          />
          <ValidateWord>
            {props.recorderGroupValidateMsg.isRecorderId}
          </ValidateWord>
        </ModalInput>
        <s.ButtonWrapper>
          {props.isEditModal && (
            <BigButton
              buttonType="warning"
              label="Delete"
              onClickBtn={() =>
                props.setIsDeleteConfirm !== undefined
                  ? props.setIsDeleteConfirm(true)
                  : null
              }
            />
          )}
          {props.isEditModal ? (
            <BigButton label="Save" onClickBtn={props.onClickUpdate} />
          ) : (
            <BigButton label="Save" onClickBtn={props.onClickCreate} />
          )}
          {props.isEditModal && props.isConfirm && (
            <DeleteConfirmModal
              label="Recorder Group"
              onDelete={() => props.onDelete(false)}
              onConfirmDelete={props.onConfirmDelete}
            />
          )}
        </s.ButtonWrapper>
      </s.AddWrapper>
    </BigPopupTemplate>
  );
}
