import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  KeywordSearch,
  DashboardKeywordReduxData,
} from "api/interfaces/dashboardInterface.interface";

const initialState: DashboardKeywordReduxData = {
  // isSearchOpen: false,
  searchKeywordList: [],
  // filters: {

  // }
};

// 사용자 로그인 후 Account 정보와 선택정보를 전역으로 관리.

export const dashboardSearchData = createSlice({
  name: "dashboardSearchData",
  initialState,
  reducers: {
    // setIsSearchOpen(state, action: PayloadAction<boolean>) {
    //   return { ...state, isSearchOpen: action.payload };
    // },
    setDashboardSearchData(state, action: PayloadAction<KeywordSearch[]>) {
      if (action.payload === undefined || action.payload === null) {
        return initialState;
      } else {
        return {
          ...state,
          searchKeywordList: action.payload,
        };
      }
    },
  },
});

export const { setDashboardSearchData } = dashboardSearchData.actions;

export default dashboardSearchData;
