import qs from "qs";
import { AxiosResponse } from "axios";
import {
  BillingCommon,
  CreateChannelLicensePayload,
  CreateStorageLicensePayload,
  GetBilling,
  GetBillingDetailPayload,
  GetBillingPayload,
  GetLicenseAccountList,
  GetLicenseInfo,
} from "./interfaces/licenseInterface.interface";
import { AxiosInstance } from "./axiosInstance";
import { BasePagination } from "./interfaces/commonInterface.interface";

const LICENSE_API_PATH = "/license";

export const getLicenseAccountList = async ({
  payload,
  accountId,
}: {
  payload: BasePagination;
  accountId: string;
}): Promise<GetLicenseAccountList> => {
  try {
    const { data }: AxiosResponse<GetLicenseAccountList> =
      await AxiosInstance.get(
        `${LICENSE_API_PATH}/list/${accountId}?${qs.stringify(payload)}`
      );
    return data;
  } catch (e) {
    console.error(e);
    throw new Error();
  }
};

export const getLicenseInfo = async ({
  accountId, // request 하는 해당 account id
  euAccountId, // cp 일 경우에 request 할 eu account id
}: {
  accountId: string;
  euAccountId: string;
}): Promise<GetLicenseInfo> => {
  try {
    const { data }: AxiosResponse<GetLicenseInfo> = await AxiosInstance.get(
      `${LICENSE_API_PATH}/info/${accountId}?accountId=${euAccountId}`
    );
    return data;
  } catch (e) {
    console.error(e);
    throw new Error();
  }
};

export const getLicenseBilling = async ({
  accountId,
  payload,
}: {
  accountId: string;
  payload: GetBillingPayload;
}): Promise<GetBilling> => {
  try {
    const { data }: AxiosResponse<GetBilling> = await AxiosInstance.get(
      `${LICENSE_API_PATH}/billing/${accountId}?${qs.stringify(payload)}`
    );
    return data;
  } catch (e) {
    console.error(e);
    throw new Error();
  }
};

export const getLicenseBillingCommon = async ({
  accountId,
}: {
  accountId: string;
}): Promise<BillingCommon> => {
  try {
    const { data }: AxiosResponse<BillingCommon> = await AxiosInstance.get(
      `${LICENSE_API_PATH}/billing/common/${accountId}`
    );
    return data;
  } catch (e) {
    console.error(e);
    throw new Error();
  }
};

export const getLicenseBillingDetail = async ({
  accountId,
  payload,
}: {
  accountId: string;
  payload: GetBillingDetailPayload;
}): Promise<GetBilling> => {
  try {
    const { data }: AxiosResponse<GetBilling> = await AxiosInstance.get(
      `${LICENSE_API_PATH}/billing/detail/${accountId}?${qs.stringify(payload)}`
    );
    return data;
  } catch (e) {
    console.error(e);
    throw e;
  }
};

export const postStorageLicense = async ({
  payload,
  accountId,
}: {
  payload: CreateStorageLicensePayload;
  accountId: string;
}): Promise<CreateStorageLicensePayload> => {
  try {
    const { data }: AxiosResponse<CreateStorageLicensePayload> =
      await AxiosInstance.post(
        `${LICENSE_API_PATH}/update/storageCart/${accountId}`,
        payload
      );
    return data;
  } catch (e) {
    console.error(e);
    throw e;
  }
};

export const postChannelLicense = async ({
  payload,
  accountId,
}: {
  payload: CreateChannelLicensePayload;
  accountId: string;
}): Promise<CreateChannelLicensePayload> => {
  try {
    const { data }: AxiosResponse<CreateChannelLicensePayload> =
      await AxiosInstance.post(
        `${LICENSE_API_PATH}/update/channelCart/${accountId}`,
        payload
      );
    return data;
  } catch (e) {
    console.error(e);
    throw e;
  }
};

export const postStoragePayLicense = async ({
  payload,
  accountId,
}: {
  payload: CreateStorageLicensePayload;
  accountId: string;
}): Promise<CreateStorageLicensePayload> => {
  try {
    const { data }: AxiosResponse<CreateStorageLicensePayload> =
      await AxiosInstance.post(
        `${LICENSE_API_PATH}/pay/storageCart/${accountId}`,
        payload
      );
    return data;
  } catch (e) {
    console.error(e);
    throw e;
  }
};

export const postChannelPayLicense = async ({
  payload,
  accountId,
}: {
  payload: CreateChannelLicensePayload;
  accountId: string;
}): Promise<CreateChannelLicensePayload> => {
  try {
    const { data }: AxiosResponse<CreateChannelLicensePayload> =
      await AxiosInstance.post(
        `${LICENSE_API_PATH}/pay/channelCart/${accountId}`,
        payload
      );
    return data;
  } catch (e) {
    console.error(e);
    throw e;
  }
};
