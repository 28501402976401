import * as s from "./WidgetStyled";
import SmallPopupTemplate from "components/templates/modal/small/SmallPopupTemplate";
import { Layout } from "react-grid-layout";
import styled from "styled-components";
import { FLEX } from "styles/flex";
import { calcRem } from "styles/theme";
import { Text } from "components/atoms/text/Text";
import { COLORS, LIGHT_THEME_COLORS } from "styles/colors";
import { useCallback, useMemo, useState } from "react";
import {
  isDisabledDashboardTableList,
  tableItemList,
} from "./item/table/TableItemList";
import { TableItemListStyled } from "./item/table/TableItemStyled";
import { MdConstruction } from "react-icons/md";

interface Props {
  layouts: Layout[];
  isAddModal: boolean;
  onAddLayout: (type?: string, newItem?: string[]) => void;
}

export default function AddLayout(props: Props): JSX.Element {
  const layout = props.layouts.map((l) => l.i);

  const [newItem, setNewItem] = useState<string[]>([]);

  const list = useMemo(() => {
    return Object.entries(tableItemList).filter((addItem, index) => {
      const isIncluded = layout.includes(addItem[0]);
      return !isIncluded;
    });
  }, [tableItemList, layout]);

  const onChange = useCallback(
    (checked: boolean, type: string) => {
      if (checked) {
        setNewItem([...newItem, type]);
        // 체크 해제할 시 CheckList에서 해당 id값이 `아닌` 값만 배열에 넣기
      } else {
        setNewItem(newItem.filter((checkedId) => checkedId !== type));
      }
    },
    [newItem]
  );

  const onSave = useCallback(
    (type?: string) => {
      props.onAddLayout(type, newItem);
    },
    [newItem, props]
  );

  return (
    // <TableItemListStyled.Container>
    //   {list.map((addItem, index) => {
    //     const isDisabled = isDisabledDashboardTableList.includes(addItem[0]);
    //     // 이미 레이아웃에 있는 위젯은 리스트에 표현은 하지만 checkbox disabled 함.
    //     return (
    //       <TableItemListStyled.Item
    //         key={index}
    //         isDisabled={isDisabled}
    //         onClick={(e) => onChange(true, addItem[0])}
    //       >
    //         {/* <input
    //           type="checkbox"
    //           disabled={isDisabled}
    //           onChange={(e) => onChange(e.currentTarget.checked, addItem[0])}
    //         /> */}
    //         {/* <span> */}
    //         {addItem[1]}
    //         {/* </span> */}
    //       </TableItemListStyled.Item>
    //     );
    //   })}
    //   <li></li>
    // </TableItemListStyled.Container>
    <SmallPopupTemplate
      title="Add Widgets"
      subTitle="Select a type of widget"
      onClick={() => onSave("close")}
      onModal={() => props.onAddLayout("close")}
      cancel
    >
      <RadioWrapper>
        {list.map((addItem, index) => {
          const isDisabled = isDisabledDashboardTableList.includes(addItem[0]);
          // 이미 레이아웃에 있는 위젯은 리스트에 표현은 하지만 checkbox disabled 함.
          return (
            <RadioItem key={index} isDisabled={isDisabled}>
              <input
                type="checkbox"
                disabled={isDisabled}
                onChange={(e) => onChange(e.currentTarget.checked, addItem[0])}
              />
              <span>
                {addItem[1]}
                {isDisabled && (
                  <button
                    className="coming-soon"
                    // onMouseEnter={() => setIsHover(true)}
                    // onMouseLeave={() => setIsHover(false)}
                  >
                    <MdConstruction color={COLORS.BUTTONS1} size={14} />

                    {/* {isHover && (
                <BubbleContainer className="bubble-container">
                  <InfoBubble>Coming Soon</InfoBubble>
                </BubbleContainer>
              )} */}
                  </button>
                )}
              </span>
            </RadioItem>
          );
        })}
      </RadioWrapper>
    </SmallPopupTemplate>
  );
}
const RadioWrapper = styled.div`
  ${FLEX.FlexBetweenStart};
  gap: 10px;
  flex-direction: column;
  width: 400px;
  max-width: 400px;
  /* height: 300px; */
`;

const RadioItem = styled.label<{ isDisabled: boolean }>`
  ${FLEX.FlexStartStart};
  gap: 10px;
  width: 100%;

  ${({ isDisabled }) =>
    !isDisabled &&
    `
      cursor: pointer;
    `}

  span {
    ${FLEX.FlexStartStart};
    gap: 2px;
    color: ${({ theme, isDisabled }) =>
      isDisabled ? COLORS.BUTTONS1 : theme.COLORS.WHITE};

    /* font-size: ${calcRem(18)}; */
  }

  .subtitle {
    padding-top: 4px;
    white-space: normal;
    line-height: ${calcRem(18)};
  }
`;
