import { Fragment, useCallback, useEffect, useMemo, useState } from "react";
import BillingPresenter from "./BillingAccountPresenter";
import { Media, SortOrder, TableColumn } from "react-data-table-component";
import { useMutation, useQuery, useQueryClient } from "react-query";
import * as s from "../BillingStyled";
import {
  deleteAccount,
  postAccountCreate,
  postUpdateSuspension,
  putAccountUpdate,
} from "api/accountAPI";
import { Level } from "components/atoms/level/Level";
import TableButton from "components/atoms/buttons/TableButton";
import ToggleComponent from "components/atoms/toggle/Toggle";
import {
  CreateAccountRequest,
  ProfileAccountInfo,
} from "api/interfaces/accountInterface.interface";
import SuspendLabel from "../sub/SuspendLabel";
import { Location, useLocation, useNavigate } from "react-router-dom";
import { Default } from "components/MediaQuery";
import { LicenseAccountList } from "api/interfaces/licenseInterface.interface";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import { getLicenseAccountList } from "api/licenseAPI";
import {
  Account,
  getAccountListMappger,
  getLevelFullName,
} from "api/mappers/accountMapper";
import {
  BasePagination,
  CustomError,
  LEVEL_TYPE,
  SORT_DIRECTION,
  Validate,
  ValidateMsg,
} from "api/interfaces/commonInterface.interface";
import { setMenu } from "redux/reducers/menu/menuSlice";
import {
  clearBreadcrumbRoute,
  pushBreadcrumbRoute,
} from "redux/reducers/breadcrumb/breadcrumb";
import { setAccountSetting } from "redux/reducers/account/accountSlice";
import { useIntl } from "react-intl";
import { notify } from "components/atoms/notification/Notification";
import { getAccountInfo, getSwitchAccountInfo } from "utils/AccountUtil";
import { checkEmailRegEx, checkWhiteSpaceRegEx } from "utils/regEx";
import { isEmpty, isNull } from "lodash";
import { BsExclamationCircle } from "react-icons/bs";
import * as mediaQuery from "components/MediaQuery";
import useApiError from "hook/useApiError";
import { menuNavigation, queryNavigate } from "utils/MenuUtil";
import { IoIosCopy } from "react-icons/io";
import { ReactComponent as AccountSVG } from "assets/icons/sidenav/account.svg";
import { handleCopyClipBoard } from "utils/functions";
import { NameLabel } from "pages/account/AccountStyled";

export interface Props {}

export default function BillingAccountContainer(): JSX.Element {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const queryClient = useQueryClient();
  const intl = useIntl();
  const location: Location = useLocation();

  const selectedAccount: ProfileAccountInfo = useAppSelector(
    (state) => state.accountSettings
  );

  const [customerAccounts, setCustomerAccounts] = useState<
    LicenseAccountList[]
  >([]);
  const [accountInfo, setAccountInfo] = useState<Account | undefined>();
  //suspend modal
  const [isCheckSuspend, setIsCheckSuspend] = useState<boolean>(false);

  const [currentRow, setCurrentRow] = useState<LicenseAccountList>();

  const [isAddModal, setIsAddModal] = useState(false);
  const [isEditModal, setIsEditModal] = useState(false);
  const initIsSelf =
    location?.state?.isSwitchMenu !== undefined
      ? location?.state?.isSwitchMenu
      : false;
  const [isSelf, setIsSelf] = useState<boolean>(initIsSelf);

  const { handleError } = useApiError();
  const [pending, setPending] = useState<boolean>(true);

  const [accountValidate, setAccountValidate] = useState<Validate>({
    isAccountName: false,
    isLevel: false,
    isUserName: false,
    isUserEmail: false,
  });

  const [accountValidateMsg, setAccountValidateMsg] = useState<ValidateMsg>({
    isAccountName: "",
    isLevel: "",
    isUserName: "",
    isUserEmail: "",
  });

  useEffect(() => {
    let state = (location?.state?.isSwitchMenu as boolean) || undefined;
    if (state !== undefined) {
      setIsSelf(state);
    } else {
      setIsSelf(false);
    }
    const changeParam = new URLSearchParams(location.search);
    if (changeParam.size > 0) {
      setLicenseQueryInfo((info) => {
        return {
          ...info,
          pageNum: isNull(changeParam.get("pageNum"))
            ? 0
            : Number(changeParam.get("pageNum")),
          pageLimit: isNull(changeParam.get("pageLimit"))
            ? 20
            : Number(changeParam.get("pageLimit")),
          keyword: isNull(changeParam.get("keyword"))
            ? ""
            : (changeParam.get("keyword") as string),
          sortType: isNull(changeParam.get("sortType"))
            ? "name"
            : (changeParam.get("sortType") as string),
          sortDirection: isNull(changeParam.get("sortDirection"))
            ? SORT_DIRECTION.ASC
            : (changeParam.get("sortDirection") as SORT_DIRECTION),
        };
      });
    } else {
      setLicenseQueryInfo((info) => {
        return {
          ...info,
          pageNum: 0,
          pageLimit: 20,
          keyword: "",
          sortType: "name",
          sortDirection: SORT_DIRECTION.ASC,
        };
      });
    }
  }, [location]);

  const onChangeIsSuspend = () => {
    setIsCheckSuspend(!isCheckSuspend);
  };

  const mutationUpdateSuspension = useMutation(postUpdateSuspension, {
    onSuccess: (res: any) => {
      notify(
        "success",
        intl.formatMessage({
          id: "label.billing.notify.update.success",
          defaultMessage: "License suspension update successfully.",
        })
      );
      setIsCheckSuspend(false);
      //RND-541 :Suspended account switch doesn't display properly.
      queryClient.invalidateQueries("account_license");
    },
    onError: (err: CustomError) => {
      handleError(
        err,
        intl.formatMessage({
          id: "label.billing.notify.update.fail",
          defaultMessage: "Failed to update license suspension.",
        })
      );
    },
  });

  const onConfirmSuspend = () => {
    if (currentRow !== undefined) {
      setCurrentRow({
        ...currentRow,
        finallySuspended: !currentRow.finallySuspended,
      });
      // console.log(currentRow.finallySuspended, "currentRow suspend");
      mutationUpdateSuspension.mutate({
        accountId: currentRow.accountId,
        suspensionStatus: !currentRow.finallySuspended,
      });
    }
  };

  //temp columns
  const columns: TableColumn<LicenseAccountList>[] = [
    {
      id: "name",
      name: "Name",
      grow: 2,
      cell: (row) => (
        <s.NameLabel onClick={() => onCellClick(row)}>
          <Level level={getLevelFullName(row.level)} />
          {row.name}
        </s.NameLabel>
      ),
      sortable: true,
      sortField: "name",
    },

    {
      name: "Level",
      grow: 1.5,
      selector: (row) => getLevelFullName(row.level),
      hide: Media.SM,
      sortable: true,
      sortField: "level",
    },
    {
      name: "Organization ID",
      grow: 1.5,
      cell: (row) => (
        <NameLabel>
          <div className="org">
            <AccountSVG />
          </div>
          {row.accountNumber}

          <button onClick={() => handleCopyClipBoard(row.accountNumber)}>
            <IoIosCopy />
          </button>
        </NameLabel>
      ),
      hide: Media.SM,
      sortable: true,
      sortField: "accountNumber",
    },
    {
      name: "Sub Organizations",
      grow: 1,
      selector: (row) => (row.level === LEVEL_TYPE.EU ? `-` : row.hasAccounts),
      center: true,
      hide: Media.SM,
    },
    {
      name: "Suspend",
      grow: 2,
      cell: (row) => (
         row.level === LEVEL_TYPE.EU ?
        <s.Row>         
          <ToggleComponent
            isToggled={row.finallySuspended}
            checked={row.finallySuspended}
            onChange={() => {
              setCurrentRow(row);
              setIsCheckSuspend(true);
            }}
          />
          <Default>
            {row.finallySuspended && row.suspendedDays > 0 && (
              <SuspendLabel suspendDays={row.suspendedDays} />
            )}
          </Default>
        </s.Row>
        : <Fragment/>
      ),
      hide: Media.SM,
    },
    {
      name: "",
      minWidth: "150px",
      grow: 3,
      cell: (row) => (
        <>
          <mediaQuery.Default>
            <s.Row>
              {row.level === LEVEL_TYPE.EU && (
                <s.Row>
                  {row.remainingTrialDays > 0 &&
                    (row.currentLicenseType === undefined ||
                      row.currentLicenseType === "NONE") && (
                      <s.LicenseLabel>
                        Trial: {row.remainingTrialDays} days left
                      </s.LicenseLabel>
                    )}
                  {row.licenseRequested && (
                    <s.LicenseLabel isRequested>
                      <BsExclamationCircle size={13} />
                      <mediaQuery.Default>
                        {row.channelIsPreparing
                          ? "Licenses Requested"
                          : "Storage Requested"}
                      </mediaQuery.Default>
                      <mediaQuery.Mobile>Requested</mediaQuery.Mobile>
                    </s.LicenseLabel>
                  )}
                  <TableButton
                    buttonType={"primary"}
                    label="Licenses"
                    onClickBtn={() => {
                      menuNavigation(
                        selectedAccount,
                        navigate,
                        "/billing/license",
                        false,
                        { accountId: row.accountId, name: row.name }
                      );
                      // navigate("/billing/license", {
                      //   state: { accountId: row.accountId, name: row.name },
                      // });
                    }}
                  />
                </s.Row>
              )}

              <TableButton
                label="Billing"
                onClickBtn={() => {
                  navigate("/billing/payment", {
                    state: {
                      accountId: row.accountId,
                    },
                  });
                }}
              />
            </s.Row>
          </mediaQuery.Default>
          <mediaQuery.Mobile>
            <s.Row>
              <TableButton
                buttonType={"primary"}
                label="Licenses"
                onClickBtn={() => {
                  navigate("/billing/license", {
                    state: { accountId: row.accountId, name: row.name },
                  });
                }}
              />
              <TableButton
                label="Billing"
                onClickBtn={() => {
                  navigate("/billing/payment", {
                    state: {
                      accountId: row.accountId,
                    },
                  });
                }}
              />
            </s.Row>
          </mediaQuery.Mobile>
        </>
      ),
      right: true,
    },
    // 마지막 버튼
    {
      name: "",
      // minWidth: Media.LG ? "150px" : "80px",
      grow: 2,
      cell: (row) => (
        <s.Row>
          {!isSelf && (
            <TableButton
              label="Edit"
              onClickBtn={() => {
                let tempAccount = getAccountListMappger(customerAccounts).map(
                  (ca) => {
                    let tempKeyArr = Object.entries(ca).filter((cKey) => {
                      return (
                        cKey[0] !== "suspended" &&
                        cKey[0] !== "suspensionOn" &&
                        cKey[0] !== "cascadedSuspension" &&
                        cKey[0] !== "finallySuspended" &&
                        cKey[0] !== "suspendedDays" &&
                        cKey[0] !== "onGoingTrial" &&
                        cKey[0] !== "remainingTrialDays" &&
                        cKey[0] !== "licenseRequested"
                      );
                    });
                    return Object.fromEntries(tempKeyArr);
                  }
                );

                let account: Account = Array.from(tempAccount).find(
                  (acc) => acc.accountId === row.accountId
                ) as Account;

                // console.log(
                //   customerAccounts,
                //   getAccountListMappger(customerAccounts),
                //   tempAccount,
                //   account
                // );
                setAccountInfo(account);
                onModal("edit");
              }}
            />
          )}

          <span className="row-click-button">
            <TableButton label="Enter" onClickBtn={() => onCellClick(row)} />
          </span>
        </s.Row>
      ),
      right: true,
    },
  ];

  const [licenseQueryInfo, setLicenseQueryInfo] = useState<BasePagination>({
    total: 0,
    pageNum: 0,
    pageLimit: 20,
    keyword: "",
    sortType: "name",
    sortDirection: SORT_DIRECTION.ASC,
  });

  const [status, setStatus] = useState({
    totalTitle: "Total",
    totalValue: 0,
  });

  const getLicenseList = useQuery(
    ["account_license", licenseQueryInfo, selectedAccount],
    () =>
      getLicenseAccountList({
        payload: licenseQueryInfo,
        accountId: selectedAccount.accountId,
      }),
    {
      retry: 0,
      refetchOnWindowFocus: false,
      onSuccess: (res: any) => {
        // console.log(res, "license");
        if (res.result === undefined) {
          setCustomerAccounts([]);
          setStatus({
            ...status,
            totalValue: 0,
          });
        } else {
          setStatus({
            ...status,
            totalValue: res.page.total,
          });
          setCustomerAccounts(res.result);
        }
      },
      onError: (e: any) => {
        setStatus({
          totalTitle: "Total ",
          totalValue: 0,
        });
        setCustomerAccounts([]);
      },
      onSettled: (e: any) => {
        setPending(false);
      },
    }
  );

  const mutationUpdateAccount = useMutation(putAccountUpdate, {
    onSuccess: (res: any) => {
      if (res.error === 0) {
        notify(
          "success",
          intl.formatMessage({
            id: "validateMsg.account.modify.success",
            defaultMessage: "Organization updated successfully.",
          })
        );
        setIsAddModal(false);
        queryClient.invalidateQueries("account_license");
      } else {
        notify(
          "error",
          intl.formatMessage({
            id: "validateMsg.account.modify.fail",
            defaultMessage: "Failed to update organization.",
          })
        );
      }
    },
    onError: (err: CustomError) => {
      handleError(
        err,
        intl.formatMessage({
          id: "validateMsg.account.modify.fail",
          defaultMessage: "Failed to update organization.",
        })
      );
    },
  });

  const onCellClick = (row: LicenseAccountList) => {
    getSwitchAccountInfo(row.accountId).then(function (selectAccount) {
      if (!!selectAccount) {
        if (selectAccount.userPermission === undefined) {
          notify(
            "warning",
            intl.formatMessage({
              id: "validateMsg.account.permissionsDeny",
              defaultMessage:
                "The organization needs permissions. Please contact your administrator.",
            })
          );
          return;
        }
        if (isSelf) {
          dispatch(clearBreadcrumbRoute());
          setIsSelf(false);
        }
        // console.log(selectAccount);
        dispatch(setAccountSetting(selectAccount));
        if (row.level === LEVEL_TYPE.EU) {
          // console.log("11");
          // dispatch(
          //   pushBreadcrumbRoute({
          //     name: selectAccount.accountName,
          //     routeName: "/recorder/list",
          //     accountId: selectAccount.accountId,
          //   })
          // );
          dispatch(setMenu("Recorders"));
          menuNavigation(selectAccount, navigate, `/recorders/list`);
          //navigate(`/recorders/list/${selectAccount.accountId}` );
        } else {
          // console.log("22");
          // dispatch(setMenu("Billing"));
          // dispatch(
          //   pushBreadcrumbRoute({
          //     name: selectAccount.accountName,
          //     routeName: "/billing",
          //     accountId: selectAccount.accountId,
          //   })
          // );
          menuNavigation(selectAccount, navigate, `/billing/customeraccount`);
          //navigate(`/billing/customeraccount/${selectAccount.accountId}`);
        }
      }
    });
  };

  const onModal = (type?: string) => {
    if (type === "add") {
      setIsAddModal(!isAddModal);
    }
    if (type === "edit") {
      setIsEditModal(!isEditModal);
    }
  };

  // 이름만 클릭 시 다음 상세 페이지로 이동
  // const onCellClick = (row: LicenseAccountList) => {
  //   navigate("/billing/recorders");
  // };

  const mutationCreateAccount = useMutation(postAccountCreate, {
    onSuccess: () => {
      notify(
        "success",
        intl.formatMessage({
          id: "validateMsg.account.create.success",
          defaultMessage: "Organization added successfully.",
        })
      );
      queryClient.invalidateQueries("account_license");
    },
    onError: (err: CustomError) => {
      handleError(
        err,
        intl.formatMessage({
          id: "validateMsg.account.create.fail",
          defaultMessage: "Failed to add organization.",
        })
      );
    },
  });
  const mutationDeleteAccount = useMutation(deleteAccount, {
    onSuccess: (res: any) => {
      if (res.error !== 0) {
        notify(
          "error",
          intl.formatMessage({
            id: "validateMsg.account.delete.fail",
            defaultMessage: "Failed to delete organization.",
          })
        );
      } else {
        setIsEditModal(false);
        notify(
          "success",
          intl.formatMessage({
            id: "validateMsg.account.delete.success",
            defaultMessage: "Organization deleted successfully.",
          })
        );
        queryClient.invalidateQueries("account_license");
      }
    },
    onError: (err: CustomError) => {
      handleError(
        err,
        intl.formatMessage({
          id: "validateMsg.account.delete.fail",
          defaultMessage: "Failed to delete organization.",
        })
      );
    },
  });
  const onClickCreate = useCallback(
    (accountInfo: CreateAccountRequest) => {
      if (checkWhiteSpaceRegEx(accountInfo.name)) {
        setAccountValidate((info) => {
          return { ...info, isAccountName: true as boolean };
        });

        setAccountValidateMsg((info) => {
          return {
            ...info,
            isAccountName: intl.formatMessage({
              id: "validateMsg.account.name",
              defaultMessage: "Please enter organization name.",
            }) as string,
          };
        });

        return;
      }

      if (checkWhiteSpaceRegEx(accountInfo.level)) {
        setAccountValidate((info) => {
          return { ...info, isLevel: true as boolean };
        });

        setAccountValidateMsg((info) => {
          return {
            ...info,
            isLevel: intl.formatMessage({
              id: "validateMsg.account.level",
              defaultMessage: "Organization level is mandatory.",
            }) as string,
          };
        });
      }

      if (!isEmpty(accountInfo.inviteName)) {
        if (isEmpty(accountInfo.inviteEmail)) {
          setAccountValidate((info) => {
            return { ...info, isUserEmail: true as boolean };
          });

          setAccountValidateMsg((info) => {
            return {
              ...info,
              isUserEmail: intl.formatMessage({
                id: "validateMsg.account.userMail.empty",
                defaultMessage: "Please enter email",
              }) as string,
            };
          });
          return;
        } else {
          if (!checkEmailRegEx(accountInfo.inviteEmail as string)) {
            setAccountValidate((info) => {
              return { ...info, isUserEmail: true as boolean };
            });

            setAccountValidateMsg((info) => {
              return {
                ...info,
                isUserEmail: intl.formatMessage({
                  id: "validateMsg.account.userMail.illegal",
                  defaultMessage: "Enter a valid email address",
                }) as string,
              };
            });
            return;
          }
        }
      }
      setIsAddModal(false);
      mutationCreateAccount.mutate({
        payload: accountInfo,
        accountId: "0",
      });
    },
    [mutationCreateAccount]
  );

  const onClickUpdate = useCallback(
    (accountInfo: Account, file: File) => {
      setIsEditModal(false);
      const formData = new FormData();
      if (file !== undefined) {
        formData.append("file", file);
      }
      const json = JSON.stringify(accountInfo);
      const blob = new Blob([json], { type: "application/json" });
      formData.append("accountOptionDto", blob);

      mutationUpdateAccount.mutate({
        form: formData, // Account -> UpdateRequest
        accountId: selectedAccount.accountId,
      });
    },
    [mutationUpdateAccount, selectedAccount.accountId]
  );
  const onClickDelete = useCallback(
    (accountId: string) => {
      mutationDeleteAccount.mutate(accountId);
    },
    [mutationDeleteAccount]
  );

  const onClickSearch = useCallback(
    (keyword: string) => {
      if (licenseQueryInfo.keyword !== keyword) {
        queryNavigate(
          navigate,
          location.pathname,
          {
            pageLimit: licenseQueryInfo.pageLimit,
            pageNum: 0,
            keyword: keyword,
            sortType: licenseQueryInfo.sortType,
            sortDirection: licenseQueryInfo.sortDirection as SORT_DIRECTION,
          },
          selectedAccount
        );
      } else {
        getLicenseList.refetch();
      }
      setPending(true);
      // setLicenseQueryInfo((query) => {
      //   return {
      //     ...query,
      //     keyword,
      //   };
      // });
      // getLicenseList.refetch();
    },
    [
      getLicenseList,
      licenseQueryInfo.keyword,
      licenseQueryInfo.pageLimit,
      licenseQueryInfo.sortDirection,
      licenseQueryInfo.sortType,
      location.pathname,
      navigate,
      selectedAccount,
    ]
  );
  const onChangePage = useCallback(
    (page: number, totalRows: number) => {
      if (page !== licenseQueryInfo.pageNum + 1) {
        queryNavigate(
          navigate,
          location.pathname,
          {
            pageLimit: licenseQueryInfo.pageLimit,
            pageNum: page - 1,
            keyword: licenseQueryInfo.keyword as string,
            sortType: licenseQueryInfo.sortType,
            sortDirection: licenseQueryInfo.sortDirection as SORT_DIRECTION,
          },
          selectedAccount
        );
        setPending(true);
      }
    },
    [
      licenseQueryInfo.keyword,
      licenseQueryInfo.pageLimit,
      licenseQueryInfo.pageNum,
      licenseQueryInfo.sortDirection,
      licenseQueryInfo.sortType,
      location.pathname,
      navigate,
      selectedAccount,
    ]
  );

  const handleSort = async (
    selectedColumn: TableColumn<any>,
    sortDirection: SortOrder
  ) => {
    if (selectedColumn.sortField !== undefined) {
      setLicenseQueryInfo((info) => {
        return {
          ...info,
          sortType: selectedColumn.sortField as string,
          sortDirection: sortDirection.toUpperCase() as SORT_DIRECTION,
        };
      });
      //setPending(true);
    }
  };

  return (
    <BillingPresenter
      onModal={onModal}
      isAddModal={isAddModal}
      isEditModal={isEditModal}
      isCheckSuspend={isCheckSuspend}
      columns={columns}
      data={customerAccounts}
      accountInfo={accountInfo}
      accountValidate={accountValidate}
      accountValidateMsg={accountValidateMsg}
      onClickSearch={onClickSearch}
      onClickCreate={onClickCreate}
      onClickUpdate={onClickUpdate}
      onClickDelete={onClickDelete}
      onChangePage={onChangePage}
      onChangeIsSuspend={onChangeIsSuspend}
      onCellClick={onCellClick}
      onConfirmSuspend={onConfirmSuspend}
      initialPending={pending}
      paginationInfo={licenseQueryInfo}
      status={status}
      isSelf={isSelf}
      isCreateLoading={mutationCreateAccount.isLoading}
      isUpdateLoading={mutationUpdateAccount.isLoading}
      handleSort={handleSort}
    />
  );
}
