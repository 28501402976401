import { PayloadAction, createSlice } from "@reduxjs/toolkit";

export type ValidAuth = {
  isAuthenticated: boolean;
  isLogout:boolean;
};

const initialState: ValidAuth = {
  isAuthenticated: true,
  isLogout : false
};

export const ValidAuthSlice = createSlice({
  name: "token",
  initialState,
  reducers: {
    setIsAuth: (state, action: PayloadAction<boolean>) => {
      state.isAuthenticated = action.payload;
    },
    setIsLogout: (state ) => {
      state.isLogout = true;
    },
  },
});

export const { setIsAuth, setIsLogout } = ValidAuthSlice.actions;
export default ValidAuthSlice;
