import moment from "moment";
import * as s from "./CardItemStyled";
import { FaArrowCircleDown, FaArrowCircleUp } from "react-icons/fa";
import { COLORS, INDECREASECOLORS } from "styles/colors";
import LineChart from "components/atoms/chart/LineChart";
import {
  WidgetCardData,
  WidgetCardDetail,
  WidgetCardNoMap,
  WidgetCardResult,
} from "api/interfaces/widgetInterface.interface";
import CardItemDetail from "./CardItemDetail";
import { changeFirstWordToUppercase } from "utils/functions";
import { UnderlineText } from "components/atoms/text/Text";
import { Datum } from "@nivo/line";
import { FaCircleMinus } from "react-icons/fa6";
interface Props {
  name: CardItemName;
  cardData: WidgetCardNoMap;
  isDetailView: boolean;
  onView: () => void;
}
export type CardItemName =
  | "recorderCount"
  | "cameraCount"
  | "alertCount"
  | "organizationCount";

const keyTitle: { [key in CardItemName]: string } = {
  recorderCount: "Recorders",
  cameraCount: "Cameras",
  alertCount: "Alerts",
  organizationCount: "Organizations",
};

export type IncreaseString = "INCREASE" | "DECREASE" | "NONE";

const changeStringBottom = (string: string) => {
  switch (string) {
    case "CP":
      return "Channel Partner";
    case "EU":
      return "End User";
    default:
      return changeFirstWordToUppercase(string);
  }
};

export default function CardItem(props: Props): JSX.Element {
  // console.log(props.cardData.stats, props.name);

  const transformData = (data: WidgetCardDetail[]): WidgetCardDetail[] => {
    let vmaxObject: WidgetCardDetail = {
      type: "VMAX",
      count: 0,
    };

    // Filter and transform data
    const result = data.reduce((acc, item) => {
      if (item.type === "spectrum") {
        acc.push(item);
      } else {
        vmaxObject.count += item.count;
      }
      return acc;
    }, [] as WidgetCardDetail[]);

    // Add the new VMAX object to the result array
    result.push(vmaxObject);
    return result;
  };

  const calculateTotalIncrease = (data: Datum[]): IncreaseString => {
    // 데이터가 2개 이상 있는지 확인
    if (data.length < 2) {
      return "NONE";
    }

    const firstY = data[0].y as number;
    const lastY = data[data.length - 1].y as number;

    // 첫 번째와 마지막 데이터가 모두 null이 아닌지 확인
    if (firstY === null || lastY === null) {
      return "NONE";
    }

    // 첫 번째 값과 마지막 값을 비교하여 결과 반환
    if (lastY > firstY) {
      return "INCREASE";
    } else if (lastY < firstY) {
      return "DECREASE";
    } else {
      return "NONE";
    }
  };

  const indecrease = calculateTotalIncrease(props.cardData.stats);

  return (
    <s.Item>
      <s.Top className="content ">
        <s.Title isDetail={props.isDetailView} className="drag-handle">
          <div className="name bold">
            {keyTitle[props.name as CardItemName]}
          </div>
          <UnderlineText className="detail" bold onClick={props.onView}>
            Details
          </UnderlineText>
        </s.Title>

        {props.isDetailView ? (
          <CardItemDetail
            name={props.name as CardItemName}
            details={props.cardData.details}
          />
        ) : (
          <>
            <s.Increase isIncrease={indecrease}>
              <span className="number bold">{props.cardData.count}</span>
              {indecrease === "INCREASE" && (
                <FaArrowCircleUp size={16} color={COLORS.GREEN} />
              )}
              {indecrease === "DECREASE" && <FaArrowCircleDown size={16} />}
              {indecrease === "NONE" && <FaCircleMinus size={16} />}
            </s.Increase>
            <s.ChartWrapper>
              <LineChart
                data={[
                  {
                    id: "summary-increase",
                    color: INDECREASECOLORS[indecrease],
                    data: props.cardData.stats,
                  },
                ]}
                yScale={{
                  type: "linear",
                  min: "auto",
                  max: "auto",
                  stacked: true,
                  reverse: false,
                }}
                curve={"basis"}
                enablePoints={false}
                margin={{ top: 5, right: 0, bottom: 2, left: 0 }}
                enableGridY={false}
                lineWidth={3}
                enableArea={true}
                isInteractive={false}
                axisBottom={null}
              />
            </s.ChartWrapper>
          </>
        )}
      </s.Top>

      {/* card 클릭 시 detail View */}
      {!props.isDetailView && props.cardData.details && (
        <s.Bottom className="content ">
          <s.BottomInner>
            {props.name === "recorderCount" || props.name === "cameraCount"
              ? transformData(props.cardData.details).map(
                  (item: WidgetCardDetail, index: number) => (
                    <s.DetaiItem key={index}>
                      <span className="detail-title">
                        {changeStringBottom(item.type)}:{" "}
                      </span>
                      <span className="detail-value">{item.count}</span>
                    </s.DetaiItem>
                  )
                )
              : props.cardData.details.map(
                  (item: WidgetCardDetail, index: number) => (
                    <s.DetaiItem key={index}>
                      <span className="detail-title">
                        {changeStringBottom(item.type)}:{" "}
                      </span>
                      <span className="detail-value">{item.count}</span>
                    </s.DetaiItem>
                  )
                )}
          </s.BottomInner>
        </s.Bottom>
      )}
    </s.Item>
  );
}
