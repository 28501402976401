import { TableColumn } from "react-data-table-component";
import {
  ForwardedRef,
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import { useQuery } from "react-query";

import {
  BasePagination,
  SORT_DIRECTION,
} from "api/interfaces/commonInterface.interface";
import { getGroupList } from "api/userAPI";
import { IndeterminateCheckbox, VGroupTable } from "./comp/VGroupTable";
import { ColumnDef, Row, RowSelectionState } from "@tanstack/react-table";
import React from "react";
import users from "pages/users";

export type SearchUserGroupProps = {
  accountId: string;
  keyword?: string;
  selectedUserGroupId?: string[];
  onSelectedRowsChangeUserGroup?: (selectedRows: string[]) => void;
  isSupportEmail?: boolean;
  selectedUserGroupIdEmail?: {
    userGroupId: string;
    enableEmail: boolean;
    enablePush: boolean;
  }[];
  ref?: ForwardedRef<any>;
};

interface DataRow {
  id: string;
  name: string;
  description: string;
  enableEmail: boolean;
  enablePush: boolean;
}

export const SearchUserGroup = forwardRef(
  (props: SearchUserGroupProps, ref: ForwardedRef<any>): JSX.Element => {
    const [groups, setGroups] = useState<DataRow[]>([]);
    const [selectedCombine, setSelectedCombine] = useState<RowSelectionState>(
      {}
    );
    const [groupQueryInfo, setGroupQueryInfo] = useState<BasePagination>({
      total: 0,
      pageNum: 0,
      pageLimit: 2000,
      keyword: "",
      sortType: "name",
      sortDirection: SORT_DIRECTION.ASC,
    });

    const headerCheckStatus = useCallback(
      (data: Row<any>[], type: string) => {
        let status: string = "NONE";
        switch (type) {
          case "EMAIL":
            const checkEmailCount = data.filter(
              (row) => row.original.enableEmail === true
            ).length;
            if (checkEmailCount > 0 && checkEmailCount < groups.length) {
              status = "INTER";
            } else if (checkEmailCount === groups.length) {
              status = "ALL";
            }
            break;
          case "PUSH":
            const checkPushCount = data.filter(
              (row) => row.original.enablePush === true
            ).length;
            if (checkPushCount > 0 && checkPushCount < groups.length) {
              status = "INTER";
            } else if (checkPushCount === groups.length) {
              status = "ALL";
            }
            break;
          default:
            break;
        }
        return status;
      },
      [groups.length]
    );

    const emailHeaderToggleHandler = useCallback(
      (checked: boolean) => {
        const tmpData = [...groups];
        tmpData.forEach((data) => {
          data.enableEmail = checked;
        });
        setGroups(tmpData);
      },
      [groups]
    );

    const pushHeaderToggleHandler = useCallback(
      (checked: boolean) => {
        const tmpData = [...groups];
        tmpData.forEach((data) => {
          data.enablePush = checked;
        });
        setGroups(tmpData);
      },
      [groups]
    );

    const columns = React.useMemo<ColumnDef<any>[]>(
      () =>
        props.isSupportEmail !== undefined && props.isSupportEmail
          ? [
              {
                accessorKey: "name",
                size: 100,
                header: ({ table }) => <>Name</>,
                cell: ({ row, getValue }) => (
                  <div
                    style={{
                      // Since rows are flattened by default,
                      // we can use the row.depth property
                      // and paddingLeft to visually indicate the depth
                      // of the row
                      paddingLeft: `${row.depth * 2}rem`,
                    }}
                  >
                    <>
                      {" "}
                      {row.getCanExpand() ? (
                        <button
                          {...{
                            onClick: row.getToggleExpandedHandler(),
                            style: { cursor: "pointer" },
                          }}
                        >
                          {row.getIsExpanded() ? "▼" : "▶"}
                        </button>
                      ) : (
                        " "
                      )}{" "}
                      {getValue()}
                    </>
                  </div>
                ),
                footer: (props) => props.column.id,
              },
              {
                accessorKey: "description",
                header: () => "Description",
                size: 200,
              },
              {
                accessorKey: "enableEmail",
                header: ({ table }) => (
                  <>
                    <IndeterminateCheckbox
                      {...{
                        checked:
                          headerCheckStatus(
                            table.getRowModel().rows,
                            "EMAIL"
                          ) === "ALL"
                            ? true
                            : false,
                        indeterminate:
                          headerCheckStatus(
                            table.getRowModel().rows,
                            "EMAIL"
                          ) === "INTER"
                            ? true
                            : false,
                        onChange: (e) =>
                          emailHeaderToggleHandler(e.currentTarget.checked),
                        // onClick: onClickCheckBox
                      }}
                    />{" "}
                    Email
                  </>
                ),
                cell: ({ row }) => (
                  <div
                    style={{
                      paddingLeft: `${row.depth * 2}rem`,
                    }}
                  >
                    <input
                      key={row.original.id + "_email"}
                      type="checkbox"
                      checked={row.original.enableEmail}
                      onChange={(e) => {
                        const tmpData = [...groups];
                        tmpData[row.index].enableEmail =
                          !tmpData[row.index].enableEmail;
                        setGroups(tmpData);
                      }}
                    />{" "}
                  </div>
                ),
                size: 70,
                enableHiding: true,
              },
              {
                accessorKey: "enablePush",
                header: ({ table }) => (
                  <>
                    <IndeterminateCheckbox
                      {...{
                        checked:
                          headerCheckStatus(
                            table.getRowModel().rows,
                            "PUSH"
                          ) === "ALL"
                            ? true
                            : false,
                        indeterminate:
                          headerCheckStatus(
                            table.getRowModel().rows,
                            "PUSH"
                          ) === "INTER"
                            ? true
                            : false,
                        onChange: (e) =>
                          pushHeaderToggleHandler(e.currentTarget.checked),
                        // onClick: onClickCheckBox
                      }}
                    />{" "}
                    Push
                  </>
                ),
                cell: ({ row, cell, getValue }) => (
                  <div
                    style={{
                      paddingLeft: `${row.depth * 2}rem`,
                    }}
                  >
                    <input
                      key={row.original.id + "_push"}
                      type="checkbox"
                      checked={row.original.enablePush}
                      onChange={(e) => {
                        const tmpData = [...groups];
                        tmpData[row.index].enablePush =
                          !tmpData[row.index].enablePush;
                        setGroups(tmpData);
                      }}
                    />{" "}
                  </div>
                ),
                size: 70,
                enableHiding: true,
              },
            ]
          : [
              {
                accessorKey: "name",
                // size: 250,
                header: ({ table }) => (
                  <>
                    <IndeterminateCheckbox
                      {...{
                        checked: table.getIsAllRowsSelected(),
                        indeterminate: table.getIsSomeRowsSelected(),
                        onChange: table.getToggleAllRowsSelectedHandler(),
                        // onClick: onClickCheckBox
                      }}
                    />{" "}
                    {/* <button
              {...{
                onClick: table.getToggleAllRowsExpandedHandler(),
              }}
            >
              {table.getIsAllRowsExpanded() ? "👇" : "👉"}
            </button>{" "} */}
                    Name
                  </>
                ),
                cell: ({ row, getValue }) => (
                  <div
                    className="input-name"
                    style={{
                      // Since rows are flattened by default,
                      // we can use the row.depth property
                      // and paddingLeft to visually indicate the depth
                      // of the row
                      paddingLeft: `${row.depth * 2}rem`,
                    }}
                  >
                    <>
                      <IndeterminateCheckbox
                        {...{
                          checked: row.getIsSelected(),
                          indeterminate: row.getIsSomeSelected(),
                          onChange: row.getToggleSelectedHandler(),
                          // onClick: onClickCheckBox
                        }}
                      />{" "}
                      {row.getCanExpand() ? (
                        <button
                          {...{
                            onClick: row.getToggleExpandedHandler(),
                            style: { cursor: "pointer" },
                          }}
                        >
                          {row.getIsExpanded() ? "▼" : "▶"}
                        </button>
                      ) : (
                        " "
                      )}{" "}
                      {getValue()}
                    </>
                  </div>
                ),
                footer: (props) => props.column.id,
              },
              {
                accessorKey: "description",
                header: () => "Description",
                size: 200,
              },
            ],
      [groups, props.isSupportEmail]
    );

    const { error, refetch } = useQuery(
      ["group", groupQueryInfo, props.selectedUserGroupIdEmail],
      () =>
        getGroupList({
          payload: groupQueryInfo,
          accountId: props.accountId,
        }),
      {
        retry: 0,
        refetchOnWindowFocus: false,
        onSuccess: (res: any) => {
          if (res.result === undefined) {
            return;
          }
          setGroups(
            res.result.map((group: any) => {
              return {
                id: group.groupId as string,
                name: group.name,
                description: group.description,
                enableEmail:
                  props.selectedUserGroupIdEmail !== undefined
                    ? (props.selectedUserGroupIdEmail.find(
                        (temp) => temp.userGroupId === group.groupId
                      )?.enableEmail as boolean)
                    : false,
                enablePush:
                  props.selectedUserGroupIdEmail !== undefined
                    ? (props.selectedUserGroupIdEmail.find(
                        (temp) => temp.userGroupId === group.groupId
                      )?.enablePush as boolean)
                    : false,
              };
            })
          );
          let tmpCombine = {};
          res.result.forEach((group: any, index: number) => {
            if (props.selectedUserGroupId?.includes(group.groupId!)) {
              Object.assign(tmpCombine, { [group.groupId!]: true });
            }

            if (props.selectedUserGroupIdEmail !== undefined) {
              let selectId = props.selectedUserGroupIdEmail.find(
                (temp) => temp.userGroupId === group.groupId
              );
              if (selectId !== undefined) {
                Object.assign(tmpCombine, { [group.groupId!]: true });
              }
            }
          });
          setSelectedCombine(tmpCombine);
        },
        onError: (e: any) => {},
      }
    );

    useEffect(() => {
      let tmpCombine = {};
      const tmpData = [...groups];
      groups.forEach((group, index: number) => {
        if (props.selectedUserGroupId?.includes(group.id)) {
          Object.assign(tmpCombine, { [group.id]: true });
        }
        if (props.selectedUserGroupIdEmail !== undefined) {
          let selectId = props.selectedUserGroupIdEmail.find(
            (temp) => temp.userGroupId === group.id
          );
          group.enableEmail = selectId?.enableEmail as boolean;
          group.enablePush = selectId?.enablePush as boolean;
        }
      });
      //setSelectedCombine(tmpCombine);
      setGroups(tmpData);
    }, [props.selectedUserGroupIdEmail]);

    const handleChange = useCallback((row: RowSelectionState) => {
      // You can set state or dispatch with something like Redux so we can use the retrieved data
      let checkedRecorder: string[] = [];
      const selectKeys = Object.keys(row);

      if (selectKeys.length > 0) {
        selectKeys.forEach((select) => {
          if (!select.endsWith("merged")) {
            checkedRecorder.push(select);
          }
        });
      }
      if (props.onSelectedRowsChangeUserGroup) {
        props.onSelectedRowsChangeUserGroup(checkedRecorder);
      }
    }, []);

    useImperativeHandle(ref, () => ({
      getCheckedListAll() {
        const selectedList = groups.filter(
          (user) => user.enableEmail === true || user.enablePush === true
        );
        return selectedList;
      },
    }));
    return (
      <>
        <VGroupTable
          columns={columns}
          data={groups}
          search={props.keyword}
          selectedRow={selectedCombine}
          onSelectedRowsChange={handleChange}
        />
      </>
    );
  }
);
