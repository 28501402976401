import { TableTemplate } from "components/templates/default/table/TableTemplate";
import { Table } from "components/atoms/table/Table";
import { ClickSearch } from "components/atoms/input/search/SearchInput";
import { SortOrder, TableColumn } from "react-data-table-component";
import { BasePagination } from "api/interfaces/commonInterface.interface";
import { OperationLog } from "api/interfaces/managerInterface.interface";
import DateSelect from "components/atoms/select/DateSelect";
import DateTimeSelect from "components/atoms/select/DateTimeSelect";
import SelectsCombo from "components/atoms/select/SelectCombo";

type Props = {
  data: OperationLog[];
  columns: TableColumn<OperationLog>[];
  paginationInfo: BasePagination;
  onClickSearch: ClickSearch;
  onChangePage: (page: number, totalRows: number) => void;
  handleSort: (
    selectedColumn: TableColumn<any>,
    sortDirection: SortOrder
  ) => void;
};
export function OperationPresenter(props: Props): JSX.Element {
  const centerChildren = (
    <>
      {" "}
      <SelectsCombo placeholder="Select TimeZone" options={[]} />
      <DateTimeSelect></DateTimeSelect>
      <SelectsCombo placeholder="Select User" options={[]} />
      {/* <SelectsCombo placeholder="Select Account" options={[]} />
      <SelectsCombo placeholder="Select Recorder" options={[]} /> */}
    </>
  );
  return (
    <TableTemplate
      onClickSearch={props.onClickSearch}
      centerChildren={centerChildren}
    >
      <Table
        columns={props.columns}
        data={props.data}
        paginationTotalRows={props.paginationInfo.total}
        paginationPerPage={props.paginationInfo.pageNum}
        onChangePage={props.onChangePage}
        handleSort={props.handleSort}
        defaultSortFieldId="createdAt"
        defaultSortAsc={false}
      />
    </TableTemplate>
  );
}
