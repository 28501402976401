import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RoutesProps } from "components/blocks/headernav/sub/RoutesMark";

export const Clear: string = "clear";

export interface Breadcrumb {
  routeItem: RoutesProps[];
}

const initialState: Breadcrumb = {
  routeItem: [],
};

/**
 * SideBar의 메뉴를 클릭 시 선택된 메뉴를 전역으로 저장하는 Redux
 * 저장된 메뉴ITem는 상단의 메뉴바를 바꿔주는데 사용함.
 * Default는 상단의 DashBoard Item
 */
// TODO Account와 Recorder의 상단에 표시로직 구현되어야함. 다른메뉴에서는 비활성화
export const BreadcrumbSlice = createSlice({
  name: "breadcrumb",
  initialState,
  reducers: {
    clearPushBreadcrumbRoute(state, action: PayloadAction<RoutesProps>) {
      state.routeItem = [];
      state.routeItem.push(action.payload);
    },
    pushBreadcrumbRoute(state, action: PayloadAction<RoutesProps>) {
      state.routeItem.push(action.payload);
    },
    popBreadcrumbRoute(state) {
      if (state.routeItem.length > 0) {
        state.routeItem.pop();
      }
    },
    selectBreadcrumbRoute(state, action: PayloadAction<string>) {
      const selectIndex = state.routeItem.findIndex(
        (item) => item.accountId === action.payload
      );
      state.routeItem.splice(selectIndex + 1);
    },
    clearBreadcrumbRoute(state) {
      state.routeItem = [];
    },
    setBreadcrumbRoute(state, action: PayloadAction<RoutesProps[]>) {
      state.routeItem = action.payload;
    },
  },
});

export const {
  pushBreadcrumbRoute,
  popBreadcrumbRoute,
  clearBreadcrumbRoute,
  clearPushBreadcrumbRoute,
  selectBreadcrumbRoute,
  setBreadcrumbRoute,
} = BreadcrumbSlice.actions;

export default BreadcrumbSlice;
