import Tags, { TagifyBaseReactProps } from "@yaireo/tagify/dist/react.tagify"; // React-wrapper file
import "@yaireo/tagify/dist/tagify.css"; // Tagify CSS
import styled from "styled-components";
import { COLORS } from "styles/colors";
import { calcRem } from "styles/theme";

const Hashtag = (props: TagifyBaseReactProps) => (
  <Container>
    <Tags
      {...props}
      settings={{
        delimiters: ",| |\\.",
        trim: false,
      }}
      // tagifyRef={tagifyRef} // optional Ref object for the Tagify instance itself, to get access to  inner-methods
      // settings={settings}  // tagify settings object
      // defaultValue="a,b,c"
      // {...tagifyProps}   // dynamic props such as "loading", "showDropdown:'abc'", "value"
      // onChange={onChange}
    />
  </Container>
);

const Container = styled.div`
  /* max-height: 50px; */
  max-width: 100%;
  flex: 1;

  .tags-input {
    /* max-height: 50px; */
  }

  .tagify {
    border-color: #abb5c5;
    background-color: ${COLORS.WHITE};
    border-radius: 4px;
    width: 100%;
    flex-wrap: unset;
    overflow-x: auto;
  }
  .tagify__tag > div > span {
    font-size: ${calcRem(14)};
  }

  /* .tagify_input {
    --input-color: white;
  } */
`;
export default Hashtag;
