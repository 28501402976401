import { useLocation } from "react-router-dom";
import VideoGuides from "./videoGuides";
import TechSupport from "./techSupport";
import SetupGuide from "./setupGuide";
import SpeedTest from "./speedTest";
import About from "./about";

type Props = {};

export default function Help(props: Props): JSX.Element {
  const location = useLocation();

  if (location.pathname.includes("techsupport")) return <TechSupport />;
  if (location.pathname.includes("setupguide")) return <SetupGuide />;
  if (location.pathname.includes("speedtest")) return <SpeedTest />;
  if (location.pathname.includes("about")) return <About />;
  else return <VideoGuides />;
}
