import { intervalOptions, Option, widgetChartOptions } from "utils/options";
import { useIntl } from "react-intl";
import styled from "styled-components";
import * as mediaQuery from "components/MediaQuery";
import Selects from "components/atoms/select/Select";

export type WidgetChart = "graph" | "list";
interface Props {
  value: string;
  defaultValue?: any;
  onChange: (value: WidgetChart) => void;
}

export default function TypeSelects(props: Props): JSX.Element {
  const onChange = (value: string) => {
    props.onChange && props.onChange(value as WidgetChart);
  };

  return (
    <Select>
      <Selects
        options={widgetChartOptions}
        onChange={onChange}
        value={widgetChartOptions.find((op) => op.value === props.value)}
      />
    </Select>
  );
}

const Select = styled.div`
  width: 80px;
`;
