import styled from "styled-components";
import { COLORS } from "styles/colors";
import { FLEX } from "styles/flex";
import { calcRem } from "styles/theme";

interface CheckInterface {
  value: number;
  label: string;
  isChecked: boolean;
  extra?: string;
  disabled?: boolean;
  checkCondition?: string[];
}
export interface ListInterface {
  title: string;
  check: CheckInterface[];
}

interface Props {
  onCheckedElement?: (checked: boolean, name: number) => void;
  list: ListInterface[];
  isCheck?: boolean[];
  isDisabled?: boolean[];
  isNoTitle?: boolean;
  permissionDisabled?: boolean;
}

function CheckList(props: Props): JSX.Element {
  return (
    <Container isNoTitle={props.isNoTitle}>
      {props.list.map((item, index) => (
        <ItemWrapper key={index}>
          {!props.isNoTitle === true && (
            <Title>
              <Inner>{item.title}</Inner>
            </Title>
          )}
          <CheckWrapper listLength={props.list.length} aria-disabled={true}>
            {item.check.map((c, i) => (
              <label key={i}>
                <input
                  name={String(c.value)}
                  type="checkbox"
                  checked={
                    props.isCheck !== undefined
                      ? props.isCheck[c.value - 1]
                      : false
                  }
                  onChange={(e) => {
                    if (props.onCheckedElement) {
                      props.onCheckedElement(
                        e.target.checked,
                        Number(e.currentTarget.name)
                      );
                    }
                  }}
                  disabled={
                    props.isDisabled !== undefined
                      ? props.isDisabled[c.value - 1]
                      : false
                  }
                />
                <p>
                  <span>{c.label}</span>
                  {c.extra && <span className="extra">{c.extra}</span>}
                </p>
              </label>
            ))}
          </CheckWrapper>
        </ItemWrapper>
      ))}
    </Container>
  );
}

const Container = styled.div<{ isNoTitle?: boolean }>`
  width: 100%;
  ${FLEX.FlexStartBetween};
  ${({ isNoTitle }) =>
    !isNoTitle
      ? `
        margin-bottom: 20px;
      `
      : `
        margin-bottom: 10px;
      `}

  label {
    color: ${({ theme }) => theme.COLORS.WHITE};
    font-size: ${calcRem(14)};
    ${FLEX.FlexStartStart};
    flex: 1;

    > p {
      flex: 1;
      ${FLEX.FlexStartStart};
      flex-direction: column;

      .extra {
        color: ${COLORS.BUTTONS1};
        font-size: ${calcRem(12)};
        margin-top: 4px;
      }
    }
  }
`;

const Inner = styled.div`
  padding: 0 15px;
  background-color: ${({ theme }) => theme.COLORS.PRIMARY3};
`;

const ItemWrapper = styled.div`
  width: 100%;
`;

const Title = styled.div`
  padding: 6px;
  background-color: ${({ theme }) => theme.COLORS.PRIMARY3};

  color: ${({ theme }) => theme.COLORS.WHITE};
  font-weight: 500;
  border-radius: 3px;
  font-size: ${calcRem(14)};
`;

const CheckWrapper = styled.div<{ listLength: number }>`
  padding: 10px 20px;

  ${({ listLength }) =>
    listLength > 1
      ? `
      ${FLEX.FlexStartStart};
      flex-direction: column;
      gap: 10px;
  `
      : `
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 10px;

  `}
`;

export default CheckList;
