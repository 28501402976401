import SmallPopupTemplate from "components/templates/modal/small/SmallPopupTemplate";
import { ModalInput } from "components/atoms/input/modal/ModalInput";
import Input from "components/atoms/input/Input";
import { useCallback, useState } from "react";
import { useMutation } from "react-query";
import { checkPassword } from "api/authAPI";
import { ValidateWord } from "components/atoms/text/Text";
import { useAuth } from "components/Auth";
import styled from "styled-components";
import { useIntl } from "react-intl";
import useApiError from "hook/useApiError";
import { CustomError } from "api/interfaces/commonInterface.interface";
import { COLORS } from "styles/colors";
import { calcRem } from "styles/theme";
import SmallPopupDefaultTemplate from "components/templates/modal/small/SmallPopupDefaultTemplate";
import { FLEX } from "styles/flex";
import BigButton from "components/atoms/buttons/BigButton";

interface Props {
  type: string;
  targetId: string;
  onModal: (result: boolean) => void;
  title?: string;
  subTitle?: string;
  isDeactivateMode?: boolean;
}
function DeactivateModal(props: Props): JSX.Element {
  const intl = useIntl();
  const auth = useAuth();
  const { displayError } = useApiError();
  const title =
    props.title !== undefined
      ? props.title
      : props.type === "user" && props.isDeactivateMode
      ? `Do you want to deactivate myDW subscription?`
      : `Are you sure you want to remove this ${props.type}?`;
  const subTitle =
    props.subTitle !== undefined
      ? props.subTitle
      : props.type === "role"
      ? "It cannot be recovered"
      : props.isDeactivateMode
      ? `Because you do not have a myDW organization, myDW subscription will be removed.`
      : "";

  const [password, setPassword] = useState<string>("");
  const [validateMsg, setValidateMsg] = useState<string>("");
  const [isValidated, setIsValidated] = useState<boolean>(false);

  const mutationCheckPassword = useMutation(checkPassword, {
    onSuccess: (res: any) => {
      if (res.result.isExist) {
        setValidateMsg("");
        setIsValidated(false);
        props.onModal(true);
      } else {
        setIsValidated(true);
        setValidateMsg(
          intl.formatMessage({
            id: "validateMsg.signup.loginIncorrect",
            defaultMessage: "Incorrect Login Credentials.",
          })
        );
      }
    },
    onError: (err: CustomError) => {
      setIsValidated(true);
      setValidateMsg(
        displayError(
          err,
          intl.formatMessage({
            id: "validateMsg.signup.loginIncorrect",
            defaultMessage: "Incorrect Login Credentials.",
          })
        )
      );
    },
  });

  const onClickCheckPassword = useCallback(() => {
    setValidateMsg("");
    setIsValidated(false);

    mutationCheckPassword.mutate({
      payload: {
        email: auth.user?.email,
        password: password,
      },
    });
  }, [auth.user?.email, mutationCheckPassword, password]);

  const onChangeInput = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setPassword(e.target.value);
    },
    []
  );

  const buttons = (
    <ButtonWrapper>
      <BigButton
        buttonType="cancel"
        label="Cancel"
        onClickBtn={() => props.onModal(false)}
      />
      <BigButton label="OK" delay onClickBtn={onClickCheckPassword} />
    </ButtonWrapper>
  );

  return (
    <SmallPopupDefaultTemplate
      onModal={() => props.onModal(false)}
      button={buttons}
    >
      <Title>
        {title ? title : "Are you sure you want to deactivate your profile?"}
        <br />
        {props.targetId}

        <br />
        <strong>{subTitle ? subTitle : ``}</strong>
      </Title>
      <ModalInput label="User ID">
        <Input placeholder="Enter Name" value={auth.user?.email} disabled />
      </ModalInput>
      <ModalInput label="Password" essential>
        <Input
          type="password"
          autoComplete="new-password"
          placeholder=""
          onChange={onChangeInput}
          isValidated={isValidated}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              onClickCheckPassword();
            }
          }}
        />
      </ModalInput>
      <ValidateWord>{validateMsg}</ValidateWord>
    </SmallPopupDefaultTemplate>
  );
}

export default DeactivateModal;

const Title = styled.div`
  font-weight: 500;
  color: ${({ theme }) => theme.COLORS.WHITE};
  font-size: ${calcRem(20)};
  text-align: center;
  padding: 20px 0;
  line-height: ${calcRem(24)};
  width: 100%;
  ${FLEX.FlexCenterCenter};
  flex-direction: column;
  white-space: normal;
  word-break: break-word;

  strong {
    color: ${COLORS.COMPANY};
    font-size: ${calcRem(16)};
    line-height: ${calcRem(20)};
    width: 100%;
  }
`;
const ButtonWrapper = styled.div`
  ${FLEX.FlexCenterCenter};
  gap: 16px;
`;
