import { Text } from "components/atoms/text/Text";
import { COLORS } from "styles/colors";
import SmallPopupDefaultTemplate from "components/templates/modal/small/SmallPopupDefaultTemplate";
import BigButton from "components/atoms/buttons/BigButton";
import styled from "styled-components";
import { calcRem } from "styles/theme";
import { FLEX } from "styles/flex";

type Props = {
  onConfirmCancel: () => void;
  onConfirm: () => void;
  label?: string;
};

export default function NormalConfirmModal({
  onConfirmCancel,
  onConfirm,
  label,
}: Props): JSX.Element {
  return (
    <SmallPopupDefaultTemplate
      onModal={onConfirmCancel}
      button={
        <ButtonWrapper>
          <BigButton buttonType="cancel" label="No" onClickBtn={onConfirmCancel} />
          <BigButton label="Yes" onClickBtn={onConfirm} />
        </ButtonWrapper>
      }
    >
      <DeleteTextWrapper>
        {label === "install" && (
        <>
        <Text fontSize={20} bold>
          Do you really want to finish this{" "}
          {label !== undefined ? label : "install"}?
        </Text>
        <Text fontSize={20} bold color={COLORS.COMPANY}>
          The finished installation report cannot be changed.
        </Text>
        </>
        )}
        {label === "techSupport" && (
        <>
        <Text fontSize={20} bold>
          Do you really want to close this submit{" "}

        </Text>
        </>
        )}
      </DeleteTextWrapper>
    </SmallPopupDefaultTemplate>
  );
}
export const DeleteTextWrapper = styled.div`
  text-align: center;
  padding: 20px 0;
  line-height: ${calcRem(28)};
`;

const ButtonWrapper = styled.div`
  ${FLEX.FlexCenterCenter};
  gap: 10px;
`;
