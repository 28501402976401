import * as s from "../TableItemStyled";
import { useCallback, useEffect, useState } from "react";
import { useQuery } from "react-query";
import {
  BasePagination,
  SORT_DIRECTION,
} from "api/interfaces/commonInterface.interface";
import { useIntl } from "react-intl";
import {
  ActiveAlert,
  SelectedAlertView,
} from "api/interfaces/alertInterface.interface";
import { StatusTxt } from "pages/alert/AlertStyled";
import { displayAlert } from "utils/AlertUtil";
import { getRecorderTimeFormat } from "utils/timeUtil";
import { ProfileAccountInfo } from "api/interfaces/accountInterface.interface";
import { useAppSelector } from "redux/hooks";
import AlertDetailModal from "components/templates/modal/alert";
import { UnderlineText } from "components/atoms/text/Text";
import {
  commonOrgColumn,
  commonPaginationPerPage,
} from "pages/dashboard/dashboard/WidgetContainer";
import SearchInput from "components/atoms/input/search/SearchInput";
import ModalSubMenu from "components/blocks/modal/menu/ModalSubMenu";
import AccountSelects from "../AccountSelects";
import { TableColumnsType, TablePaginationConfig } from "antd";
import {
  DashboardChartDetailTable,
  changeSortOrderString,
} from "components/atoms/table/AntdTable";
import { postWidgetListAlertHistory } from "api/widgetAPI";
import { PostWidgetAlertHistoryListResponse } from "api/interfaces/widgetInterface.interface";
import { FilterValue, SortOrder, SorterResult } from "antd/es/table/interface";
import { isEmpty } from "lodash";
import { mobileMax } from "components/MediaQuery";
import { useMediaQuery } from "react-responsive";
import TableItemHeader from "../TableItemHeader";
import { Layout } from "react-grid-layout";
import { TableItemName } from "../TableItemList";
import RecorderTitle from "components/blocks/recorder/RecorderTitle";

interface Props {
  layout: Layout;
  chartLayout: Layout[];
  onDeleteWidget: (key: TableItemName) => void;
  onLayoutChange: (layout: Layout[]) => void;
}

export default function AlertHistory(props: Props): JSX.Element {
  const intl = useIntl();
  const selectedAccount: ProfileAccountInfo = useAppSelector(
    (state) => state.accountSettings
  );
  const isMobile = useMediaQuery({ maxWidth: mobileMax });

  const tabs = [{ label: "Video" }, { label: "System" }, { label: "Disk" }];

  const [currentMenu, setCurrentMenu] = useState(0);
  const [accountIds, setAccountIds] = useState<string[]>([]);

  const [isViewModal, setIsViewModal] = useState(false);
  const [data, setData] = useState<ActiveAlert[]>([]);
  const [selectedAlert, setSelectedAlert] = useState<
    SelectedAlertView | undefined
  >();
  const initialQueryInfo = {
    total: 0,
    pageNum: 0,
    // pageLimit: 100,
    pageLimit: commonPaginationPerPage,
    keyword: "",
    sortType: "alertTrigger",
    sortDirection: SORT_DIRECTION.DESC,
  };

  const [alertActiveQueryInfo, setAlertActiveQueryInfo] =
    useState<BasePagination>({ ...initialQueryInfo });

  const { error, refetch, isError } = useQuery(
    [
      "postWidgetListAlertHistory",
      alertActiveQueryInfo,
      currentMenu,
      selectedAccount,
      accountIds,
    ],
    () =>
      postWidgetListAlertHistory({
        accountId: selectedAccount.accountId,
        payload: {
          accountIds,
          type: tabs[currentMenu].label,
          listDto: alertActiveQueryInfo,
        },
      }),
    {
      retry: 0,
      refetchOnWindowFocus: false,
      onSuccess: (res: PostWidgetAlertHistoryListResponse) => {
        if (res.error !== 0 && res.result === undefined) {
          setData([]);
          setAlertActiveQueryInfo((info) => {
            return { ...info, total: 0 as number, pageNum: 0 };
          });
        } else {
          // console.log(res.result, "postWidgetListAlertHistory");
          setData(res.result);
          setAlertActiveQueryInfo((info) => {
            return { ...info, total: res.page.total as number };
          });
        }
      },
      onError: (err: any) => {
        setData([]);
        // setAlertActiveQueryInfo((info) => {
        //   return { ...info, total: 0 as number, pageNum: 0 };
        // });
      },
    }
  );
  const onRowClick = (row: ActiveAlert) => {
    // console.log(row);
    setIsViewModal(!isViewModal);
    setSelectedAlert({
      type: row.recorderType,
      recorderId: row.recorderId,
      recorderName: row.recorderName,
      mergedSystemName: row.mergedSystemName,
      mergedSystemId: row.mergedSystemId,
      alertType: row.alertTypeString,
      camera: {
        channel: row.cameraChannel,
        cameraName: row.cameraName,
        cameraId: row.cameraId,
      },
      storageName: row.storageName,
      category: row.type,
      historyId: row.alertHistoryId,
    });
  };

  const onChangeTable = (
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue | null>,
    sorter: SorterResult<ActiveAlert> | SorterResult<ActiveAlert>[]
    // extra: TableCurrentDataSource<ActiveAlert>
  ) => {
    // console.log(filters, sorter, "onChangeTable");

    if (!isEmpty(sorter) && sorter !== null) {
      const sorters = Array.isArray(sorter) ? sorter : [sorter];

      sorters.forEach((sort) => {
        if (sort && "columnKey" in sort && "order" in sort) {
          setAlertActiveQueryInfo((info: BasePagination) => {
            return {
              ...info,
              sortType: sort.columnKey as string,
              sortDirection: changeSortOrderString(sort.order as SortOrder),
            };
          });
        }
      });
    }

    // if (filters !== null && !isEmpty(filters)) {
    //   setAlertActiveQueryInfo((info) => {
    //     if (filters.status !== null) {
    //       return {
    //         ...info,
    //         keyword: filters.status[0] as string,
    //         pageNum: 0,
    //       };
    //     } else return info;
    //   });
    // }
  };

  const columns: TableColumnsType<ActiveAlert> = [
    commonOrgColumn(selectedAccount),

    {
      title: "Recorder",
      key: "recorderName",
      dataIndex: "recorderName",
      render: (value, row) => (
        <RecorderTitle
          mergedSystemName={row.mergedSystemName}
          recorderName={row.recorderName}
          type={row.recorderType}
        />
      ),
    },
    {
      title: "Status",
      key: "status",
      dataIndex: "status",
      render: (value, row) => (
        <StatusTxt string={value.toUpperCase()}>
          {value.toUpperCase()}
        </StatusTxt>
      ),
      // width: 100,
      // filterMultiple: false,
      // filters: ActiveAlertStatusTypeFilter.map((fil) => {
      //   return { text: fil.label, value: fil.value };
      // }),
    },
    {
      title: "Alert",
      key: "alertTypeString",
      dataIndex: "alertTypeString",
      render: (value, row) => displayAlert(row),
      ellipsis: !isMobile,
    },
    {
      title: "Date",
      key: "alertTrigger",
      dataIndex: "alertTrigger",
      render: (value, row) =>
        value !== undefined
          ? getRecorderTimeFormat(value, selectedAccount, row.timezone, true)
          : "",
      sorter: true,
      sortDirections: ["ascend", "descend"],
      showSorterTooltip: false,
      defaultSortOrder: "descend",
    },
    {
      title: "",
      render: (value, row) => (
        <UnderlineText onClick={() => onRowClick(row)} fontSize={14}>
          View
        </UnderlineText>
      ),
      align: "right",
      width: 50,
    },
  ];

  const onChangeTabMenu = (index: number) => {
    setCurrentMenu(index);
  };
  const onChangeAccount = (value: string[]) => {
    setAlertActiveQueryInfo((quer) => {
      return {
        ...quer,
        pageNum: 0,
      };
    });
    setAccountIds(value);
  };

  const onClickSearch = (keyword: string) => {
    setAlertActiveQueryInfo((quer) => {
      return {
        ...initialQueryInfo,
        keyword,
      };
    });
  };

  const onChangePage = useCallback(
    (page: number, totalRows: number) => {
      if (page !== alertActiveQueryInfo.pageNum + 1) {
        setAlertActiveQueryInfo((info) => {
          return { ...info, pageNum: (page - 1) as number };
        });
      }
    },
    [alertActiveQueryInfo.pageNum]
  );

  useEffect(() => {
    if (props.layout.h >= 2) {
      setAlertActiveQueryInfo((info) => {
        return {
          ...info,
          pageLimit: commonPaginationPerPage * props.layout.h,
        };
      });
    } else {
      setAlertActiveQueryInfo((info) => {
        return {
          ...info,
          pageLimit: commonPaginationPerPage,
        };
      });
    }
  }, [props.layout.h]);

  return (
    <>
      <TableItemHeader
        layout={props.layout}
        chartLayout={props.chartLayout}
        onDeleteWidget={props.onDeleteWidget}
        onLayoutChange={props.onLayoutChange}
        isError={isError}
      />
      <s.Contents className="drag-cancel-content">
        <s.ContentWrapper>
          <s.ContentHeader>
            <div>
              <ModalSubMenu
                menus={tabs}
                currentMenu={currentMenu}
                onChange={onChangeTabMenu}
              />
            </div>
            <div>
              <AccountSelects onChange={onChangeAccount} value={accountIds} />
              <SearchInput onClickSearch={onClickSearch} />
            </div>
          </s.ContentHeader>
          <s.ContentInner>
            <DashboardChartDetailTable
              dataSource={data}
              columns={columns}
              queryInfo={alertActiveQueryInfo}
              onChangePage={onChangePage}
              onChange={onChangeTable}
            />
          </s.ContentInner>
        </s.ContentWrapper>
      </s.Contents>
      {isViewModal && selectedAlert !== undefined && (
        <AlertDetailModal
          onModal={() => setIsViewModal(!isViewModal)}
          selectedAlert={selectedAlert}
        />
      )}
    </>
  );
}
