import { useCallback, useMemo, useRef, useState } from "react";
import { AlertViewTable } from "components/atoms/table/Table";
import * as s from "../AlertViewStyled";
import { TableColumn } from "react-data-table-component";
import { getAccountTimeFormat, getRecorderTimeFormat } from "utils/timeUtil";
import { ProfileAccountInfo } from "api/interfaces/accountInterface.interface";
import { useAppSelector } from "redux/hooks";
import {
  AlertTraceList,
  Assignee,
} from "api/interfaces/alertInterface.interface";
import Input from "components/atoms/input/Input";
import PrimaryButton from "components/atoms/buttons/PrimaryButton";
import { useLocation } from "react-router-dom";
import { useMutation } from "react-query";
import {
  getAlertViewUserList,
  postAlertAssign,
  postAlertComment,
  postAlertSendEmail,
  postAlertSendMessage,
} from "api/alertAPI";
import { notify } from "components/atoms/notification/Notification";
import { useIntl } from "react-intl";
import { useQueryClient } from "react-query";
import { useQuery } from "react-query";
import { SearchUserAllList } from "api/interfaces/userInterface.interface";
import { Option } from "utils/options";
import SmallPopupTemplate from "../../small/SmallPopupTemplate";
import { ModalInput } from "components/atoms/input/modal/ModalInput";
import TextArea from "components/atoms/input/TextArea";
import { isEmpty } from "lodash";
import useApiError from "hook/useApiError";
import { CustomError } from "api/interfaces/commonInterface.interface";
import { Text } from "components/atoms/text/Text";
import { COLORS, statusToColor } from "styles/colors";
import AlertSelects from "components/atoms/select/modal/AlertSelects";
import { BiMailSend } from "react-icons/bi";
import { BiMessageRoundedDots } from "react-icons/bi";
interface Props {
  data: AlertTraceList[];
  alertHistoryId: string;
  recorderId: string;
  assignee: Assignee | undefined;
  isClosed: boolean;
}

const TraceList = (props: Props) => {
  const intl = useIntl();
  const location = useLocation();
  const { handleError } = useApiError();

  const queryClient = useQueryClient();
  const isMap = location.pathname.includes("map");
  const selectedAccount: ProfileAccountInfo = useAppSelector(
    (state) => state.accountSettings
  );
  const tracelistStyled = s.TraceListStyled;

  const smsTextRef = useRef<HTMLTextAreaElement>(null);
  const emailTextRef = useRef<HTMLTextAreaElement>(null);
  const [isSendMessageModal, setIsSendMessageModal] = useState(false);
  const [isSendEmailModal, setIsSendEmailModal] = useState(false);

  const [error, setError] = useState({
    status: false,
    msg: "",
  });

  // assign
  const [assign, setAssign] = useState({
    email: "",
    message: "",
  });

  // trace list comment
  const [comment, setComment] = useState<string>("");
  const [users, setUsers] = useState<Option[]>([]);

  const columns: TableColumn<AlertTraceList>[] = useMemo(
    () => [
      {
        name: "Action",
        selector: (row) => row.action,
        grow: 1,
      },
      {
        name: "Date & Time",
        // selector: (row) => changeTimeStampFormat(row.eventDate),
        selector: (row) =>
          row.createdAt !== undefined
            ? getRecorderTimeFormat(
                row.createdAt,
                selectedAccount,
                row.timezone,
                true
              )
            : "",
        grow: 1.5,
      },
      {
        name: "User",
        selector: (row) => row.username,
        grow: 1,
      },
      {
        name: "Note",
        selector: (row) => row.comment,
        grow: 3,
      },
    ],
    []
  );

  const getUserAll = useQuery(
    ["getAllUser"],
    () =>
      getAlertViewUserList({
        accountId: selectedAccount.accountId,
        recorderId: props.recorderId,
      }),
    {
      retry: 3,
      onSuccess: (res: any) => {
        setUsers(
          res.result.map((r: SearchUserAllList) => {
            return {
              label: r.name,
              value: r.email,
            };
          })
        );
      },
      onError: (e: any) => {
        setUsers([]);
      },
    }
  );

  const mutationAssign = useMutation(postAlertAssign, {
    onSuccess: () => {
      notify(
        "success",
        intl.formatMessage({
          id: "label.alert.notify.assigned.success",
          defaultMessage: "Assigned successfully",
        })
      );
      setAssign({
        email: "",
        message: "",
      });
      queryClient.invalidateQueries("alertViewList");
      queryClient.invalidateQueries("alertTraceList");
    },
    onError: (err: CustomError) => {
      handleError(
        err,
        intl.formatMessage({
          id: "label.alert.notify.assigned.fail",
          defaultMessage: "Assigned failed",
        })
      );
    },
  });
  const mutationInsertComment = useMutation(postAlertComment, {
    onSuccess: () => {
      notify(
        "success",
        intl.formatMessage({
          id: "label.alert.notify.commentAdd.success",
          defaultMessage: "Comment added successfully",
        })
      );
      setComment("");
      queryClient.invalidateQueries("alertTraceList");
    },
    onError: (err: CustomError) => {
      handleError(
        err,
        intl.formatMessage({
          id: "label.alert.notify.commentAdd.fail",
          defaultMessage: "Failed to add comment.",
        })
      );
    },
  });
  const mutationSendAlertMessage = useMutation(postAlertSendMessage, {
    onSuccess: () => {
      notify(
        "success",
        intl.formatMessage({
          id: "label.common.notify.message.success",
          defaultMessage: "Message sent successfully",
        })
      );
      setIsSendMessageModal(false);
    },
    onError: (err: CustomError) => {
      handleError(
        err,
        intl.formatMessage({
          id: "label.common.notify.message.fail",
          defaultMessage: "Message sending failed.",
        })
      );
    },
  });
  const mutationSendAlertEmail = useMutation(postAlertSendEmail, {
    onSuccess: () => {
      notify(
        "success",
        intl.formatMessage({
          id: "label.common.notify.email.success",
          defaultMessage: "Email sent successfully",
        })
      );
      setIsSendEmailModal(false);
    },
    onError: (err: CustomError) => {
      handleError(
        err,
        intl.formatMessage({
          id: "label.common.notify.email.fail",
          defaultMessage: "Failed to send email.",
        })
      );
    },
  });

  const onAssign = useCallback(() => {
    mutationAssign.mutate({
      accountId: selectedAccount.accountId,
      recorderId: props.recorderId,
      payload: assign,
    });
  }, [assign]);

  const onComment = useCallback(() => {
    // console.log("??????");
    // if (isEmpty(comment)) {
    //   setValidate((info) => {
    //     return { ...info, comment: true };
    //   });

    //   setValidateMsg((info) => {
    //     return {
    //       ...info,
    //       comment: intl.formatMessage({
    //         id: "validateMsg.alert.view.emptyComment",
    //         defaultMessage: "Please enter Comment",
    //       }) as string,
    //     };
    //   });
    //   return;
    // }

    mutationInsertComment.mutate({
      accountId: selectedAccount.accountId,
      recorderId: props.recorderId,
      payload: {
        note: comment,
      },
    });
  }, [comment, intl, mutationInsertComment, selectedAccount.accountId]);

  const onClickSendEmail = useCallback(() => {
    // if (isEmpty(emailTextRef.current?.value)) {
    //   setError({
    //     status: true,
    //     msg: "Please enter a message.",
    //   });
    //   return;
    // }

    if (props.assignee === undefined) {
      setError({
        status: true,
        msg: "Please select assignee.",
      });
      return;
    }
    setError({
      status: false,
      msg: "",
    });
    mutationSendAlertEmail.mutate({
      accountId: selectedAccount.accountId,
      historyId: props.alertHistoryId,
      recorderId: props.recorderId,
      payload: {
        email: props.assignee.assigneeMail as string,
        message: emailTextRef.current?.value as string,
      },
    });
  }, [
    selectedAccount.accountId,
    props.alertHistoryId,
    props.recorderId,
    props.assignee,
  ]);

  const onClickSendSms = useCallback(() => {
    if (
      props.assignee === undefined ||
      props.assignee.assigneePhone === undefined
    ) {
      return;
    }
    if (isEmpty(smsTextRef.current?.value)) {
      return;
    }
    mutationSendAlertMessage.mutate({
      accountId: selectedAccount.accountId,
      historyId: props.alertHistoryId,
      recorderId: props.recorderId,
      payload: {
        phoneNumber: props.assignee.assigneePhone as string,
        message: smsTextRef.current?.value as string,
      },
    });
  }, [
    selectedAccount.accountId,
    props.alertHistoryId,
    props.recorderId,
    props.assignee,
  ]);

  const onChange = useCallback(
    (e: any) => {
      let {
        target: { name, value },
      } = e;

      if (name === "comment") {
        setComment(value);
      } else {
        setAssign({
          ...assign,
          [name]: value,
        });
      }
    },
    [comment, assign]
  );

  const onChangeAssignee = useCallback(
    (value: string) => {
      setAssign({
        ...assign,
        email: value,
      });
    },
    [assign]
  );

  return (
    <>
      {
        // 조건 추가 assigned &&
        isSendEmailModal && props.assignee !== undefined && (
          <SmallPopupTemplate
            cancel
            title="Send an Email"
            subTitle="Send a email to your technician"
            onClick={() => onClickSendEmail()}
            onModal={() => setIsSendEmailModal(false)}
            confirmLabel="Send"
          >
            <ModalInput label="Email" essential>
              <Input
                placeholder="Enter email address"
                disabled={true}
                defaultValue={props.assignee.assigneeMail}
              />
            </ModalInput>
            <ModalInput
              label="Message (Optional)"
              isValidated={error.status}
              validatedWord={error.msg}
            >
              <TextArea
                placeholder="Enter your message here"
                ref={emailTextRef}
              />
            </ModalInput>
          </SmallPopupTemplate>
        )
      }
      {
        // 조건 추가 assigned &&
        isSendMessageModal &&
          props.assignee !== undefined &&
          props.assignee?.assigneePhone !== undefined && (
            <SmallPopupTemplate
              cancel
              title="Send a Text Message"
              subTitle="Send a message to your technician"
              onClick={() => onClickSendSms()}
              onModal={() => setIsSendMessageModal(false)}
              confirmLabel="Send"
            >
              <ModalInput label="Phone Number" essential>
                <Input
                  placeholder="Enter a phone number"
                  defaultValue={props.assignee.assigneePhone}
                  disabled={true}
                />
              </ModalInput>
              <ModalInput label="Message" essential>
                <TextArea
                  placeholder="Enter your message here"
                  ref={smsTextRef}
                />
              </ModalInput>
            </SmallPopupTemplate>
          )
      }
      <tracelistStyled.TraceListWrapper isMap={isMap}>
        <tracelistStyled.AssignWrapper isMap={isMap}>
          <li className="technician column-box">
            <div className="subtitle">Assign to a Technician</div>
            <tracelistStyled.AlertTraceInputWrapper>
              <div className="select-box assign">
                <AlertSelects
                  value={users.filter(function (option) {
                    return option.value === assign.email;
                  })}
                  options={users}
                  onChange={onChangeAssignee}
                  isDisabled={props.isClosed}
                />
              </div>
              <Input
                placeholder="Enter a message to send to the assigned technician."
                name="message"
                onChange={onChange}
                value={assign.message}
                disabled={props.isClosed}
              />
              <PrimaryButton
                label="Assign"
                onClickBtn={onAssign}
                disabled={props.isClosed}
              />
            </tracelistStyled.AlertTraceInputWrapper>
          </li>

          {((isMap && props.assignee !== undefined) || !isMap) && (
            <li className="assignto column-box">
              <div className="subtitle">Assignee</div>

              <tracelistStyled.AssignBox>
                <div className="assignee-name">
                  {props.assignee === undefined ? (
                    <Text
                      color={COLORS.BUTTONS1}
                      fontSize={14}
                    >{`<NONE>`}</Text>
                  ) : (
                    props.assignee.assignee
                  )}
                </div>

                {props.assignee !== undefined && (
                  <tracelistStyled.AssignButtonWrapper>
                    {props.assignee.assigneePhone !== undefined && (
                      <button
                        className="assignee-button"
                        onClick={() => setIsSendMessageModal(true)}
                      >
                        <BiMessageRoundedDots size={20} />
                      </button>
                    )}
                    {props.assignee.assigneeMail !== "" && (
                      <button
                        className="assignee-button"
                        onClick={() => setIsSendEmailModal(true)}
                      >
                        <BiMailSend size={20} />
                      </button>
                    )}
                  </tracelistStyled.AssignButtonWrapper>
                )}
              </tracelistStyled.AssignBox>
            </li>
          )}
        </tracelistStyled.AssignWrapper>
        <tracelistStyled.TraceList>
          <tracelistStyled.TraceTitle isMap={isMap}>
            <div className="subtitle">Alert Trace List</div>
            {!props.isClosed && (
              <tracelistStyled.AlertTraceInputWrapper>
                <Input
                  placeholder="Add a comment"
                  name="comment"
                  value={comment}
                  onChange={onChange}
                />
                <PrimaryButton label="Comment" onClickBtn={onComment} />
              </tracelistStyled.AlertTraceInputWrapper>
            )}
          </tracelistStyled.TraceTitle>
          {isMap ? (
            <tracelistStyled.AlertTraceItemWrapper>
              {props.data.map((trace: AlertTraceList, idx: number) => {
                return (
                  <li key={`trace-item-${idx}`}>
                    <div className="title-wrapper">
                      <Text fontSize={15}>{trace.username}</Text>
                      <Text fontSize={14} color={COLORS.LIGHTGRAY}>
                        {trace.createdAt !== undefined &&
                          getRecorderTimeFormat(
                            trace.createdAt,
                            selectedAccount,
                            trace.timezone,
                            true
                          )}
                      </Text>
                    </div>
                    <div className="status">
                      Status:{" "}
                      <Text color={statusToColor(trace.action)} fontSize={14}>
                        {trace.action}
                      </Text>
                    </div>
                    <div className="trace-comment">
                      Comment: <span className="comment">{trace.comment}</span>
                    </div>
                  </li>
                );
              })}
            </tracelistStyled.AlertTraceItemWrapper>
          ) : (
            <AlertViewTable columns={columns} data={props.data} />
          )}
        </tracelistStyled.TraceList>
      </tracelistStyled.TraceListWrapper>
    </>
  );
};

export default TraceList;
