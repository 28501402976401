import InstallPresenter from "./InstallPresenter";
import {
  ExportEmailRequest,
  InstallationView,
  InstallationViewResponse,
  ReportDataRow,
  getReportInfoResponse,
} from "api/interfaces/reportInterface.interface";
import { Location, useLocation, useNavigate, useParams } from "react-router-dom";
import { useCallback, useEffect, useRef, useState } from "react";
import { useQuery } from "react-query";
import { useAppSelector } from "redux/hooks";
import { ProfileAccountInfo } from "api/interfaces/accountInterface.interface";
import {
  getInstallReportView,
  getInstallThumbnailSync,
  getReportInfo,
  getReportInfoInstall,
  postSendEmailInstallation,
  putCompleteInstallReport,
} from "api/reportAPI";
import { getAccountTimeFormatDate, getRecorderTimeFormat } from "utils/timeUtil";
import { useReactToPrint } from "react-to-print";
import InstallPrintPage from "./InstallPrintPage";
import {
  BasePagination,
  SORT_DIRECTION,
} from "api/interfaces/commonInterface.interface";
import { notify } from "components/atoms/notification/Notification";
import { useIntl } from "react-intl";
import { useMutation } from "react-query";
import { useQueryClient } from "react-query";
import { MultiValue } from "react-select";
import { isEmpty } from "lodash";
import { checkEmailRegEx } from "utils/regEx";
import qs from "qs";

type Props = {
  state: ReportDataRow | undefined;
};

export default function InstallContainer(props: Props): JSX.Element {
  const intl = useIntl();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const selectedAccount: ProfileAccountInfo = useAppSelector(
    (state) => state.accountSettings
  );
  const location: Location = useLocation();
  const params = useParams();
  
  const componentRef = useRef<any>(null);
  const [isUpdating, setIsUpdating] = useState<boolean>(false);
  //const title = props.state?.title as string;

  const [intervalMs, setIntervalMs] = useState<number>(0);
  const [confirmFinish, setConfirmFinish] = useState<boolean>(false);
  const [isRefreshing, setIsRefreshing] = useState<boolean>(false);

  const [removedRecorder, setRemovedRecorder]  = useState<boolean>(false);

  const {account} = qs.parse(location.search ,{ignoreQueryPrefix: true,decoder: s => decodeURIComponent(s)});
  const reportId = params.reportId as string;
  const [reportTitle, setReportTitle] = useState<string>("");

  const countRef = useRef(0);

  const [installationData, setInstallationData] = useState<InstallationView>({
    recorderInfo: {
      name: "",
      installationDate: "",
      location: "",
      model: "",
      timezone: "",
    },
    cameraInfo: [],
    installationReport: {
      id: "",
      customerName: "",
      customerPhoneNumber: "",
      customerLocation: "",
      installerName: "",
      installerPhoneNumber: "",
      installerCompany: "",
      installerCompanyPhoneNumber: "",
      installerCompanyAddress: "",
      installationDate: "",
      deviceSerial: "",
      deviceDescription: "",
      analogCameraModels: [],
      updatable: true,
    },
  });

  useEffect(()=>{
    if(props.state){
      setRemovedRecorder(props.state.recorderTotalCount === 0 ? true : false);
    }
  },[props.state])

  const [installQueryInfo, setInstallQueryInfo] = useState<BasePagination>({
    total: 0,
    pageNum: 0,
    pageLimit: 200,
    sortType: "",
    sortDirection: SORT_DIRECTION.ASC,
    keyword: "",
  });

  // const reportDetailInfo = useQuery(
  //   ["reportDetailInfo", reportId],
  //   () =>
  //     getReportInfoInstall({
  //       accountId: selectedAccount.accountId,
  //       reportId: reportId,
  //     }),
  //   {
  //     retry: 0,
  //     refetchOnWindowFocus: false,
  //     onSuccess: (res: any) => {
  //       if (res.result !== undefined && res.error === 0) {
  //         setReportTitle(res.result.preferences?.title);
  //       }else{
  //         setReportTitle("N/A")
  //       }
  //     },
  //     onError: (e: any) => {
  //       setReportTitle("N/A")
  //     }
  //   }
  // );

  const installListQuery = useQuery(
    ["installationRecorder", installQueryInfo],
    () =>
      getInstallReportView({
        reportId: reportId,
        payload: installQueryInfo,
      }),
    {
      retry: 0,
      refetchInterval: intervalMs,
      refetchOnWindowFocus: false,
      onSuccess: (res: InstallationViewResponse) => {
        if (res.error !== 0 || res.result === undefined) {
          setIntervalMs(0);
          return;
        }
        res.result.installationReport.installationDate =
          res.result.installationReport.installationDate !== undefined
            ? getAccountTimeFormatDate(
                res.result.installationReport.installationDate,
                selectedAccount,
                // res.result.recorderInfo.timezone,
                true
              )
            : "";
        setInstallationData(res.result);

        if (intervalMs > 0) {
          countRef.current += 1;
          const waitingCamera = res.result.cameraInfo?.filter((camera) => {
            return camera.installationStatus === "WAITING";
          });

          if (waitingCamera.length === 0) {
            notify(
              "success",
              intl.formatMessage({
                id: "label.report.notify.install.sync.end",
                defaultMessage: "The thumbnail synchronization process completed successfully.",
              })
            );
            setIsRefreshing(false);
            setIntervalMs(0);
            countRef.current =0;
          }
          // else if( countRef.current > res.result.cameraInfo.length){
          //   setIsRefreshing(false);
          //   setIntervalMs(0);
          //   countRef.current =0;
          // }     
        }else if(intervalMs === 0){
          const waitedCamera = res.result.cameraInfo?.filter((camera) => {
            return camera.installationStatus === "WAITING";
          });
          if(waitedCamera !== undefined && waitedCamera.length > 0){
            setIntervalMs(5000);
          }
        }
      },
      onError: (e: any) => {
        setIsRefreshing(false);
        setIntervalMs(0);
        countRef.current =0;
        notify(
          "error",
          intl.formatMessage({
            id: "label.report.notify.install.list.error",
            defaultMessage: "Installation search error.",
          })
        );
      },
    }
  );

  const mutationInstallThumbnailSync = useMutation(getInstallThumbnailSync, {
    onSuccess: () => {
      notify(
        "success",
        intl.formatMessage({
          id: "label.report.notify.install.sync.success",
          defaultMessage: "Start thumbnail synchronization.",
        })
      );
      setIntervalMs(3000);
      queryClient.invalidateQueries("installationRecorder");
    },
    onError: () => {
      notify(
        "error",
        intl.formatMessage({
          id: "label.report.notify.install.sync.fail",
          defaultMessage: "Failed to sync thumbnail.",
        })
      );
    },
  });

  const onClickRefresh = useCallback(() => {
    if (reportId) {
      setIsRefreshing(true);
      mutationInstallThumbnailSync.mutate({
        reportId: reportId,
      });
    }
  }, [mutationInstallThumbnailSync, reportId]);

  const print = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: "Installation Report",
    copyStyles: true,
  });

  const onClickSearch = useCallback(
    (keyword: string) => {
      setInstallQueryInfo((query) => {
        return {
          ...query,
          keyword,
        };
      });
      installListQuery.refetch();
    },
    [installListQuery]
  );
  const mutationCompleteInstall = useMutation(putCompleteInstallReport, {
    onSuccess: () => {
      setConfirmFinish(false);
      notify(
        "success",
        intl.formatMessage({
          id: "label.report.notify.install.update.complete.success",
          defaultMessage: "Report marked as completed.",
        })
      );
      queryClient.invalidateQueries("installationRecorder");
    },
    onError: () => {
      notify(
        "error",
        intl.formatMessage({
          id: "label.report.notify.install.update.complete.fail",
          defaultMessage: "Failed to update the report as completed.",
        })
      );
    },
  });

  const onClickComplete = useCallback(() => {
    setConfirmFinish(true);
  }, []);

  const onConfirmCancel = useCallback(() => {
    setConfirmFinish(false);
  }, []);

  const onConfirm = useCallback(() => {
    if (reportId) {
      mutationCompleteInstall.mutate({
        reportId: reportId,
      });
    }
  }, [mutationCompleteInstall, reportId]);

  const mutationSendEmail = useMutation(postSendEmailInstallation, {
    onSuccess: () => {
      notify(
        "success",
        intl.formatMessage({
          id: "label.report.notify.install.emailSend.success",
          defaultMessage: "The report email sent successfully.",
        })
      );
      setIsSendEmail(false);
      //queryClient.invalidateQueries("installationRecorder");
    },
    onError: () => {
      notify(
        "error",
        intl.formatMessage({
          id: "label.report.notify.install.emailSend.fail",
          defaultMessage: "Failed to send Installation report.",
        })
      );
    },
  });

  const [isSendEmail, setIsSendEmail] = useState<boolean>(false);
  const [sendMailInfo, setSendMailInfo] = useState<ExportEmailRequest>({
    email: [],
    title: "",
    message: "",
    reportId: reportId as string,
  });

  const email = useCallback(() => {
    if (reportId) {      
      setIsSendEmail(true);
      setSendMailInfo({
        email: [],
        title: "",
        message: "",
        reportId: reportId as string,
      })
    }
  }, [reportId]);

  const onSelectEmail = useCallback(
    (user: MultiValue<any>) => {
      setSendMailInfo({
        ...sendMailInfo,
        email: user,
      });
    },
    [sendMailInfo]
  );

  const onChangeEmailMsg = useCallback(
    (e: any) => {
      setSendMailInfo({
        ...sendMailInfo,
        message: e.target.value,
      });
    },
    [sendMailInfo]
  );

  const setIsSendEmailModal = (flag: boolean) => {
    setIsSendEmail(flag);
  };

  const [isValid, setIsValid] = useState<boolean>(true);
  const [isValidEmail, setIsValidEmail] = useState<boolean>(true);

  const onClickSendEmail = useCallback(() => {
    setIsValid(true);
    setIsValidEmail(true);
    if (sendMailInfo.email.length === 0) {
      setIsValid(false);
      return;
    }

    let checkInvalidEmail = true;
    try{
      sendMailInfo.email.forEach((value) => {
        if (
          value === undefined ||
          isEmpty(value.value) ||
          !checkEmailRegEx(value.value)
        ) {
          throw new Error();
        }
      });
    }catch (error){
      setIsValidEmail(false);
      return;
    }

    // if (!checkInvalidEmail) {
    //   setIsValidEmail(false);
    //   return;
    // }

    mutationSendEmail.mutate({
      reportId: reportId,
      accountId : selectedAccount.accountId,
      payload: {
        email: sendMailInfo.email.map((item) => {
          return item.value;
        }),
        title: sendMailInfo.title,
        message: sendMailInfo.message,
        reportId: sendMailInfo.reportId,
      },
    });
  }, [mutationSendEmail, reportId, selectedAccount.accountId, sendMailInfo.email, sendMailInfo.message, sendMailInfo.reportId, sendMailInfo.title]);

  return (
    <>
      <InstallPresenter
        installationData={installationData}
        title={reportTitle}
        print={print}
        email={email}
        componentRef={componentRef}
        onClickRefresh={onClickRefresh}
        onClickSearch={onClickSearch}
        onClickComplete={onClickComplete}
        isUpdating={isUpdating}
        isSendEmail={isSendEmail}
        onSelectEmail={onSelectEmail}
        onChangeEmailMsg={onChangeEmailMsg}
        setIsSendEmailModal={setIsSendEmailModal}
        onClickSendEmail={onClickSendEmail}
        isValid={isValid}
        isValidEmail={isValidEmail}
        confirmFinish={confirmFinish}
        onConfirmCancel={onConfirmCancel}
        onConfirm={onConfirm}
        isRefreshing={isRefreshing}
        removedRecorder={removedRecorder}
        selectedAccount={selectedAccount}
      />
      {/* <div
       style={{ visibility: "hidden" }}
      > */}
        <InstallPrintPage
          installationData={installationData}
          title={reportTitle}
          componentRef={componentRef}
        />
      {/* </div> */}
    </>
  );
}
