import styled from "styled-components";
import { FLEX } from "styles/flex";
import * as mediaQuery from "components/MediaQuery";

interface ButtonType {
  type: string;
  src?: any;
  image?: boolean;
  icon?: any;
  onClick: () => void;
}
type MapButtonProps = {
  button: ButtonType;
};

function MapButton({ button }: MapButtonProps): JSX.Element {
  return (
    <Button key={button.type} image={button.image} onClick={button.onClick}>
      {button.icon ? (
        button.icon
      ) : (
        <img src={button.src} alt={`${button.type} ICON`} />
      )}
    </Button>
  );
}

const Button = styled.button<{ image?: boolean }>`
  background-color: ${({ theme }) => theme.COLORS.WIDGETCOLOR};
  border-radius: 6px;
  padding: 5px;
  width: 35px;
  height: 35px;
  box-sizing: border-box;
  ${FLEX.FlexCenterCenter};

  img {
    width: 18px;

    ${({ image }) =>
      image &&
      `
    width: 32px;
    height: 32px;

  `}
  }

  ${({ image }) =>
    image &&
    `
    padding: 2px;
  `}

  ${mediaQuery.isMobile} {
    width: 30px;
    height: 30px;

    img {
      ${({ image }) =>
        image &&
        `
      width: 26px;
      height: 26px;
      object-fit: cover;
  `}
    }
  }
`;

export default MapButton;
