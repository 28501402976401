import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface RecorderOpen {
  isViewRecorderList: boolean;
}

const initialState: RecorderOpen = {
  isViewRecorderList: true,
};

/**
 * SideBar의 메뉴를 클릭 시 선택된 메뉴를 전역으로 저장하는 Redux
 * 저장된 메뉴ITem는 상단의 메뉴바를 바꿔주는데 사용함.
 * Default는 상단의 DashBoard Item
 */
export const recorderList = createSlice({
  name: "viewRecorderList",
  initialState,
  reducers: {
    onViewRecorderList(RecorderOpen, action: PayloadAction<boolean>) {
      RecorderOpen.isViewRecorderList = action.payload;
    },
  },
});

export const { onViewRecorderList } = recorderList.actions;

export default recorderList;
