import styled from "styled-components";
import * as mediaQuery from "components/MediaQuery";
import { COLORS, LEVELS } from "styles/colors";
import { FLEX } from "styles/flex";
import { calcRem } from "styles/theme";
import { VGroupContainer } from "components/element/comp/VGroupTableStyled";
import { LEVEL_TYPE } from "api/interfaces/commonInterface.interface";

export const Container = styled.div`
  padding: 10px;
  border-radius: 9px;
  background-color: ${({ theme }) => theme.COLORS.PRIMARY2};
  border: 1px solid #abb5c5;
  box-shadow: 0 3px 6px #00000016;

  ${mediaQuery.isMobile} {
    /* max-width: 80vw; */
  }
`;

export const Title = styled.div`
  padding: 5px 0;
  border-bottom: 1px solid ${COLORS.BORDER}50;
  ${FLEX.FlexBetweenCenter};

  input {
    padding: 4px;
  }

  > * {
    flex: 1;
  }
`;

export const Contents = styled.div`
  margin-top: 10px;
`;

export const AccountFilterItem = styled.span<{ level: LEVEL_TYPE }>`
  color: ${({ level, theme }) =>
    theme.value === "dark" && level === LEVEL_TYPE.EU
      ? "#5796d5"
      : LEVELS[level]};
  width: 100%;
  flex: 1;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const TreeContents = styled(Contents)`
  .site-tree-search-value {
    color: red;
  }

  .ant-tree {
    background: none;
    color: ${({ theme }) => theme.COLORS.WHITE};
  }
  .ant-tree-treenode {
    padding: 0;
  }

  .ant-checkbox .ant-checkbox-inner,
  .ant-tree-checkbox .ant-tree-checkbox-inner {
    /* background: none; */
    border-radius: 2px;
    border: 1px solid #acacac;
  }

  .ant-checkbox-checked .ant-checkbox-inner,
  .ant-tree-checkbox-checked .ant-tree-checkbox-inner {
    background-color: ${({ theme }) =>
      theme.COLORS.SELECTED}; /* 체크된 상태의 배경색을 변경합니다. */
  }

  .ant-checkbox-indeterminate .ant-checkbox-inner,
  .ant-tree-checkbox-indeterminate .ant-tree-checkbox-inner {
    border: none;
    position: relative;
    /* background-color: ${COLORS.GREEN}; */
    background: ${COLORS.GREEN} no-repeat center center;

    ::after {
      content: "";
      display: block;
      width: 10px;
      /* height: 10px; */
      border: solid white;
      border-width: 2px 0 0 0;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      background: none;
      height: 0;
    }
  }

  .ant-tree-list-scrollbar-vertical {
    width: 6px !important;
  }
  .ant-tree-list-scrollbar-thumb {
    background: ${({ theme }) =>
      theme.value === "light" ? COLORS.LIGHTGRAY : "#545E77"} !important;
  }

  /* .ant-tree-checkbox-inner {
    width: 15px;
    height: 15px;
    border: 1px solid #acacac;
    border-radius: 2px;
  } */
`;
export const AccountContents = styled(TreeContents)`
  width: 100%;
`;

export const RecorderContents = styled(Contents)`
  ${mediaQuery.isDefault} {
    min-width: 450px;
    width: 450px;
  }

  max-height: 500px;
  overflow-y: auto;

  .modal-submenu {
    padding: 10px 0;
    gap: 14px;
    li {
      font-weight: 500;
      font-size: ${calcRem(14)};
      padding: 4px 0;
    }
  }
`;

export const AlertContents = styled(TreeContents)`
  min-width: 260px;

  .ant-tree-treenode {
    width: 100%;
    /* padding: 3px 0; */
  }

  .ant-tree-node-content-wrapper {
    width: 100%;
  }

  .ant-tree-treenode-switcher-close .ant-tree-treenode-leaf-last {
    margin-bottom: 10px;
  }

  ${mediaQuery.isDefault} {
    margin-top: 0;
  }
`;

export const Reset = styled.div`
  padding-top: 20px;
  ${FLEX.FlexBetweenCenter};
`;

export const AlertItemWrapper = styled.div`
  input {
    width: 16px;
    height: 16px;
  }
`;

export const AlertItem = styled.div`
  width: 100%;
  ${FLEX.FlexStartStart};
  flex-direction: column;
  gap: 4px;
  padding: 2px 0 8px;
  border-bottom: 1px solid ${COLORS.BORDER}50;
  margin-bottom: 8px;

  input[type="checkbox"] {
    width: 15px;
    height: 15px;
  }

  .title-inner {
    flex: 1;
  }

  button {
    width: 100%;
    ${FLEX.FlexBetweenCenter};
    padding-top: 6px;
  }
`;

export const AlertSubs = styled.li`
  font-size: ${calcRem(12)};
  color: ${({ theme }) => theme.COLORS.WHITE};
  /* padding: 6px 28px; */

  /* label {
    padding: 4px 0;
    font-size: ${calcRem(12)};
    color: ${({ theme }) => theme.COLORS.WHITE};
  }
  input[type="checkbox"] {
    width: 15px;
    height: 15px;
  } */
`;

export const TableContainer = styled.div`
  max-height: 300px;
  width: 100% !important;
  overflow-y: auto;
  /* scrollbar-color: rebeccapurple green; */

  table {
    table-layout: fixed;
    width: 99%;
    color: #ffffff;
  }

  thead {
    visibility: collapse;
  }

  tbody {
    width: 99%;
  }

  th {
    padding: 8px;
    text-align: left;
    vertical-align: middle;
    height: 40px;
  }

  td {
    padding: 5px;
  }
`;

export const RecorderTableContainer = styled(TreeContents)`
  max-height: 300px;
  width: 100% !important;
  overflow-y: auto;

  .ant-table {
    background: transparent;
    color: ${({ theme }) => theme.COLORS.WHITE};
    font-size: ${calcRem(14)};
  }

  /* .ant-table-placeholder {
    background: ${COLORS.WHITE}20 !important;
  } */

  .ant-table-thead {
    th {
      background: ${({ theme }) => `${theme.COLORS.WHITE}20`} !important;
      color: ${({ theme }) => theme.COLORS.WHITE} !important;
    }
  }

  .ant-table-row-selected .ant-table-cell {
    background-color: ${({ theme }) => `${theme.COLORS.SELECTED}30`} !important;
  }
  .ant-table-cell {
    padding: 4px 6px !important;
    border-color: ${({ theme }) => theme.COLORS.BORDER} !important;

    td {
      border-color: ${({ theme }) => theme.COLORS.BORDER} !important;
    }

    ::before {
      background: transparent !important;
    }
  }

  .ant-table-cell-row-hover {
    background-color: ${({ theme }) => `${theme.COLORS.WHITE}10`} !important;
  }

  td {
    padding: 4px;
  }

  .recorder-type {
    font-size: ${calcRem(12)};
    width: unset;
    padding: 6px;
    min-width: 70px;
  }
`;

export const AccountTableItem = styled.div`
  ${FLEX.FlexBetweenCenter};

  input[type="checkbox"] {
    width: 15px;
    height: 15px;
  }

  .user-icon {
    height: 14px;
  }
  .left {
    ${FLEX.FlexStartCenter};
    gap: 8px;
  }
  .right {
    ${FLEX.FlexEndCenter};
    gap: 8px;
  }
`;

export const RecorderTableItem = styled.tr`
  font-size: ${calcRem(12)};
  width: 100%;

  input[type="checkbox"] {
    width: 15px;
    height: 15px;
  }
  .recorder-type {
    padding: 6px 4px;
    /* width: unset; */
    height: unset;
    width: 70px;
    font-size: ${calcRem(12)};
  }
  .left {
    ${FLEX.FlexStartCenter};
    gap: 8px;
  }
`;

export const RecorderTitleCell = styled.span`
  ${FLEX.FlexBetweenCenter};
  /* flex-direction: column; */

  .title {
    flex: 2;
  }
  .value {
    flex: 2;
    width: 100%;
    ${FLEX.FlexStartCenter};
    flex-direction: column;
    gap: 6px;

    > span {
      flex: 1;
      width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .server-name {
    color: ${COLORS.BUTTONS1};
  }
  .servers {
    display: flex;
    flex: 1;
    justify-content: flex-end;
  }
`;

export const LocationCell = styled.span`
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const RecorderTableGroupItem = styled.td<{ isDepth?: boolean }>`
  ${FLEX.FlexBetweenCenter};
  width: 430px;
  ${({ isDepth }) =>
    !isDepth
      ? `
     border-bottom: 1px solid ${COLORS.BORDER};  
  `
      : `

  `}
  padding: 10px 0;

  .title {
    flex: 1;
    ${FLEX.FlexStartCenter};
    gap: 8px;
  }
  .location {
    flex: 3;
    color: ${COLORS.BUTTONS1};
  }

  .recorder-group-td {
    padding-left: 10px;
  }
`;
