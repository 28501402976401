import { TableTemplate } from "components/templates/default/table/TableTemplate";
import PaginationComponent from "components/blocks/pagination";
import * as ts from "../thumbnail/ThumbnailStyled";

import { Text } from "components/atoms/text/Text";
import { COLORS } from "styles/colors";
import StorageLabel from "components/blocks/storage/StorageLabel";
import ModalTemplate from "components/templates/modal/ModalTemplate";
import styled from "styled-components";
import { FLEX } from "styles/flex";
import { calcRem } from "styles/theme";
import { useNavigate } from "react-router-dom";
import DefaultThumbnail from "components/atoms/image/DefaultThumbnail";
import { StorageWrapper } from "components/templates/default/table/TableTemplateStyled";
import { SelectBox } from "styles/ModalCommonStyled";
import * as mediaQuery from "components/MediaQuery";
import Selects from "components/atoms/select/Select";

type Props = {
  isModal: boolean;
  onChangeModal: () => void;
};
const reportReportData = () => {
  let temp = [];
  for (let i = 0; i < 12; i++) {
    temp.push({
      channelName: `Camera Channel Name ${i}`,
      recorderName: `Recorder Name #${i}`,
      url: "staging/{ae310607-6bd9-931e-ba28-689bd8da3048}/{8236e03f-2449-486c-8e3d-9b3a64eb8251}/0/{01660dea-2363-be2b-69c2-985643494729}/80x85za7zocz3ylqulek495e7ekwhq.jpg",
    });
  }
  return temp;
};

export default function TimelapsePresenter(props: Props): JSX.Element {
  const navigate = useNavigate();
  const options = [
    {
      label: "Recorder Name #1",
      value: "1",
    },
    {
      label: "Recorder Name #2",
      value: "2",
    },
    {
      label: "Recorder Name #3",
      value: "3",
    },
    {
      label: "Recorder Name #4",
      value: "4",
    },
    {
      label: "Recorder Name #5",
      value: "5",
    },
  ];

  const cameraOptions = [
    {
      label: "All Cameras",
      value: "1",
    },
    {
      label: "Recorder Name #2",
      value: "2",
    },
    {
      label: "Recorder Name #3",
      value: "3",
    },
  ];
  const sub = (
    <StorageWrapper>
      <StorageLabel totalStorage={200} usedStorage={180} />
    </StorageWrapper>
  );
  const titleComponent = [
    {
      label: "Report List",
      onClick: () => navigate(-1),
    },
    {
      label: "My Timelapse Report",
    },
  ];
  return (
    <>
      <TableTemplate
        detail
        noSearch
        buttons={sub}
        titleComponent={titleComponent}
      >
        <ts.Header>
          <ts.HeaderSelect>
            <mediaQuery.Default>
              <SelectBox>
                <Selects options={options} />
              </SelectBox>
              <SelectBox>
                <Selects options={cameraOptions} />
              </SelectBox>
            </mediaQuery.Default>
            <mediaQuery.Mobile>
              <MobileSelectBox>
                <Selects options={options} />
              </MobileSelectBox>
              <MobileSelectBox>
                <Selects options={cameraOptions} />
              </MobileSelectBox>
            </mediaQuery.Mobile>
          </ts.HeaderSelect>
        </ts.Header>

        <ts.Grid>
          {reportReportData().map((item, index) => (
            <>
              <ts.ThumbnailItem key={index} onClick={props.onChangeModal}>
                <DefaultThumbnail>
                  <img
                    src={item.url}
                    alt={`recorder ${item.recorderName} camera img`}
                  />
                  <Timelapse>
                    <Text className="small">
                      04/19/2022 6:00 - 08/19/2022 7:00
                    </Text>
                    <Text className="small">2:00</Text>
                  </Timelapse>
                </DefaultThumbnail>
                <Text className="title" bold>
                  {item.channelName}
                </Text>
                <Text color={`${COLORS.WHITE}75`}>{item.recorderName}</Text>
              </ts.ThumbnailItem>
              {props.isModal && (
                <ModalTemplate>
                  <ModalInner>
                    {/* 현재는 img 로 돼있지만 timelapse 이므로 동영상으로 변경해야함 */}
                    <DefaultThumbnail>
                      <img
                        src={item.url}
                        alt={`recorder ${item.recorderName} camera img`}
                      />
                      <Timelapse top>
                        <Text>
                          <strong>
                            <mediaQuery.Default>TIMELAPSE</mediaQuery.Default>
                            DURATION :
                          </strong>
                          04/19/2022 6:00 - 08/19/2022 7:00
                        </Text>
                        <Text>
                          <strong>
                            <mediaQuery.Default>THIS</mediaQuery.Default> FRAME
                            :
                          </strong>
                          04/20/2022 7:31
                        </Text>
                      </Timelapse>
                    </DefaultThumbnail>
                  </ModalInner>
                </ModalTemplate>
              )}
            </>
          ))}
        </ts.Grid>

        <PaginationComponent
          rowsPerPage={5}
          rowCount={100}
          currentPage={0}
          onChangePage={() => console.log("on change page in pagination")}
          onChangeRowsPerPage={() => console.log("")}
        />
      </TableTemplate>
    </>
  );
}

const Timelapse = styled.p<{ top?: boolean }>`
  background-color: ${COLORS.BLACK}80;
  position: absolute;
  padding: 4px 3px;
  width: 100%;

  ${({ top }) =>
    top
      ? `
    ${FLEX.FlexStartCenter};
    top: 0;
    padding: 6px 5px;

    ${mediaQuery.isDefault} {
      gap: 20px;

    }

    ${mediaQuery.isMobile} {
    justify-content: space-between;
  }
  `
      : `
    bottom: 0;
    ${FLEX.FlexBetweenCenter};
  `};

  .small {
    font-size: ${calcRem(14)};
  }

  ${mediaQuery.isMobile} {
    * {
      font-size: ${calcRem(12)};
    }
  }
`;

const ModalInner = styled.div`
  ${FLEX.FlexCenterCenter};
  width: 70%;
  height: 100%;

  img {
    width: 100%;
  }
`;

const MobileSelectBox = styled.div`
  width: 40%;
`;
