import styled from "styled-components";
import { COLORS, LEVELS } from "styles/colors";
import { FLEX } from "styles/flex";
import * as mediaQuery from "components/MediaQuery";
import { calcRem } from "styles/theme";

export const collapsedSidebarWidth = 200;
export const discollapsedSidebarWidth = 80;

export const SidebarBG = styled.div`
  width: 100vw;
  height: 100vh;
  background-color: ${COLORS.BLACK}95;
  position: fixed;
  z-index: 993;
`;

export const SideBarContainer = styled.div<{ isCollapsed?: boolean }>`
  padding: 20px 15px;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 991;
  background-color: ${({ theme }) => theme.COLORS.SIDEBARBG};
  ${mediaQuery.isDefault} {
    width: ${({ isCollapsed }) =>
      isCollapsed
        ? `${discollapsedSidebarWidth}px`
        : `${collapsedSidebarWidth}px`};
    ${FLEX.FlexBetweenCenter};
    height: 100vh;
    flex-direction: column;
    /* width: 100%; */
  }
  ${mediaQuery.isMobile} {
    height: 100%;
    width: 60vw;
    max-width: 60vw;
    box-sizing: border-box;

    /* overflow-y: auto;
  overflow-x: static; */
    ::-webkit-scrollbar {
      display: none;
    }
  }
`;

export const SideWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;
export const TopButtonWrapper = styled.ul`
  .pin,
  .close {
    filter: invert(31%) sepia(27%) saturate(638%) hue-rotate(184deg)
      brightness(92%) contrast(88%);
  }

  ${mediaQuery.isDefault} {
    ${FLEX.FlexEndCenter};
    gap: 10px;
    width: 100%;
  }
`;

export const CollapseButton = styled.div<{ collapsed?: boolean }>`
  cursor: pointer;
  width: 100%;
  > img {
    height: 16px;
  }

  ${mediaQuery.isDefault} {
    padding: 20px;

    ${({ collapsed }) =>
      collapsed ? FLEX.FlexCenterCenter : FLEX.FlexEndCenter}
  }

  ${mediaQuery.isMobile} {
    justify-content: flex-start;
  }
`;

/**
 * profile style
 */
export const ProfileContainer = styled.div`
  ${FLEX.FlexEndCenter};
  flex-direction: column;
  margin: 30px 0;
  cursor: pointer;
  /* border: 1px solid red; */

  ${mediaQuery.isDefault} {
    height: 60px;
  }
`;

export const UserWrapper = styled.div<{ isCollapsed: boolean }>`
  position: relative;
  ${({ isCollapsed }) =>
    isCollapsed &&
    `
  width: 90%;
  
  `}
`;

export const Logo = styled.img<{ isCollapsed: boolean }>`
  width: 90%;
  height: 60px;
  object-fit: contain;
  fill: ${COLORS.COMPANY};
  ${({ isCollapsed }) => (isCollapsed ? `width: 120px` : `width: 60px`)}
`;

export const UserInfo = styled.div`
  margin: 20px 0 20px;
  ${FLEX.FlexStartCenter};
  flex-direction: column;
  gap: 8px;
  width: 100%;

  ${mediaQuery.isMobile} {
    margin-top: 5px;
    margin-bottom: 20px;
    color: ${COLORS.WHITE};
  }
`;

export const UserButton = styled.div<{ isCollapsed: boolean; level: string }>`
  ${FLEX.FlexStartCenter};
  border-radius: 50px;
  justify-content: space-between;

  ${mediaQuery.isDefault} {
    padding: 6px;
    ${({ isCollapsed }) =>
      isCollapsed &&
      `
  
        width: 100%;
  
      `}
  }
  ${mediaQuery.isMobile} {
    width: 100%;
    max-width: 100%;
    position: relative;
    padding: 5px;
  }

  ${({ level }) =>
    level === "" ? null : ` background-color: ${LEVELS[level]} `}
`;

export const UserProfileImageWrapper = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  overflow: hidden;
`;

export const UserImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
`;
export const UserDiv = styled.div<{ level: string }>`
  color: ${COLORS.WHITE};
  ${FLEX.FlexCenterCenter};
  padding: 10px 0;
  width: 100%;
  height: 100%;
  ${({ level }) =>
    level === "" ? null : ` background-color: ${LEVELS[level]} `}
`;

export const UserDesc = styled.div`
  margin: 0 10px;
  flex: 3;
  width: 50%;
  ${mediaQuery.isDefault} {
    max-width: 100%;
  }
  ${mediaQuery.isMobile} {
    max-width: 150px;
  }
`;

export const UserName = styled.p`
  width: 100%;
  overflow: hidden;
  font-size: ${calcRem(14)};
  font-weight: 500;
  color: ${COLORS.WHITE};
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const UserEmail = styled(UserName)`
  font-weight: 300;
  margin-top: 5px;
`;

export const Company = styled.div`
  width: 80%;
  background-color: ${COLORS.COMPANY};
  border-radius: 30px;
  font-size: ${calcRem(14)};
  color: #ffffff;
  font-weight: 500;
  ${FLEX.FlexCenterCenter};
  padding: 4px;

  ${mediaQuery.isMobile} {
    width: 60%;
  }
`;

export const ArrowButton = styled.div`
  width: 15px;

  > img {
    width: 100%;
  }
`;

export const SidebarLevel = styled.div<{
  level: string;
  isCollapsed?: boolean;
}>`
  font-size: ${calcRem(14)};
  color: #ffffff;
  font-weight: bold;
  ${FLEX.FlexCenterCenter};

  ${mediaQuery.isDefault} {
    ${({ isCollapsed }) =>
      isCollapsed
        ? `
        width: 80%;
    height: 32px;
    border-radius: 30px;
        padding: 8px;
  `
        : `
        width: 32px;
    height: 32px;
    border-radius: 50%;
    `}
  }
  ${mediaQuery.isMobile} {
    width: 80%;
    border-radius: 30px;
    padding: 5px;
    max-width: 150px;
  }

  ${({ level }) =>
    level === "" ? null : ` background-color: ${LEVELS[level]} `}
`;

/**
 * menu list style
 */

export const MenuContainer = styled.ul`
  width: 100%;

  ${mediaQuery.isMobile} {
    flex: 1;
    ${FLEX.FlexStartStart};
    flex-direction: column;
    height: calc(100% - 220px);
    align-items: center;
  }
`;

export const MenuItem = styled.li<{
  isCollapsed: boolean;
  isCurrent: boolean;
  disabled: boolean | undefined;
}>`
  width: 100%;
  max-height: 64px;
  box-sizing: border-box;
  ${({ isCollapsed }) =>
    isCollapsed ? FLEX.FlexStartCenter : FLEX.FlexCenterCenter};
  gap: 20px;
  flex: 1;
  cursor: pointer;
  /* padding: 18px 0; */

  ${mediaQuery.isMobile} {
    max-height: 50px;
    width: 90%;

    * {
      color: ${({ disabled, isCurrent, theme }) =>
        disabled
          ? theme.value === "light"
            ? "#9fb6cf"
            : `${COLORS.WHITE}20`
          : isCurrent
          ? COLORS.COMPANY
          : COLORS.SIDEBARMENU};
    }
    /* 
    svg {
      g {
        * {
          fill: ${({ disabled, isCurrent }) =>
      disabled
        ? COLORS.PRIMARY1
        : isCurrent
        ? COLORS.COMPANY
        : COLORS.LIGHTGRAY};
        }
        stroke: ${({ disabled, isCurrent }) =>
      disabled
        ? COLORS.PRIMARY1
        : isCurrent
        ? COLORS.COMPANY
        : COLORS.LIGHTGRAY};
        path {
          fill: ${({ disabled, isCurrent }) =>
      disabled
        ? COLORS.PRIMARY1
        : isCurrent
        ? COLORS.COMPANY
        : COLORS.LIGHTGRAY};
          stroke: ${({ disabled, isCurrent }) =>
      disabled
        ? COLORS.PRIMARY1
        : isCurrent
        ? COLORS.COMPANY
        : COLORS.LIGHTGRAY};
        }
      }
    } */
  }
`;

export const MenuSVG = styled.div<{ isCollapsed?: boolean }>`
  ${mediaQuery.isMobile} {
    width: 21px;
    padding: 1px;
    ${({ isCollapsed }) =>
      isCollapsed ? FLEX.FlexStartCenter : FLEX.FlexCenterCenter};
    /* svg {
      g {
        stroke: ${COLORS.WHITE}80;
      }
      path {
        fill: ${COLORS.WHITE}80;
        stroke: ${COLORS.WHITE}80;
      }
    } */
  }
`;

export const MenuLabel = styled.label`
  font-weight: 500;
  /* color: #ffffff80; */
`;

/**
 * UserPopup
 */

export const PopupContainer = styled.div<{ isCollapsed: boolean }>`
  border: 1px solid #828b9b;
  position: absolute;
  background-color: ${COLORS.WHITE};
  padding: 15px;
  z-index: 994;
  cursor: pointer;
  border-radius: 6px;

  ${mediaQuery.isDefault} {
    min-width: 250px;
    ${({ isCollapsed }) =>
      isCollapsed
        ? `
          top: -20px;
          right: -${collapsedSidebarWidth + 30}px;
    `
        : `
          top: -20px;
          right: -${collapsedSidebarWidth + 30}px;
    `}

    :after {
      border-color: transparent ${COLORS.WHITE};
      border-style: solid;
      border-width: 10px 18px 10px 0;
      content: "";
      display: block;
      position: absolute;
      left: -18px;
      top: 35px;
      width: 0;
      z-index: 1;
    }
    :before {
      border-color: transparent #828b9b;
      border-style: solid;
      border-width: 11px 18px 11px 0;
      border-left: 0;
      content: "";
      display: block;
      top: 35px;
      left: -18px;
      position: absolute;
      width: 0;
      z-index: 0;
    }
  }

  ${mediaQuery.isMobile} {
    min-width: 110%;
    max-width: 110%;
    left: 0;
    margin-top: 5px;
  }
`;

export const PopupUserEmail = styled.p`
  color: rgba(130, 139, 155, 0.58);
  padding-bottom: 10px;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const PopupMenu = styled.p`
  color: #828b9b;
  /* padding: 15px 0; */
`;

export const PopupListContainer = styled.ul`
  border-top: 1px solid #828b9b;
  border-bottom: 1px solid #828b9b;
  padding: 15px 0;
  min-height: 50px;
  margin: 15px 0;
  ${FLEX.FlexStartStart};
  flex-direction: column;
  width: 100%;
  gap: 5px;
`;

export const PopupListItem = styled.li`
  ${FLEX.FlexStartCenter};
  gap: 6px;
  color: #828b9b;
  width: 100%;
`;

export const CircleAccountLevel = styled.div<{ level: string }>`
  border-radius: 50px;
  background-color: ${({ level }) => LEVELS[level]};
  color: ${COLORS.WHITE};
  font-size: ${calcRem(12)};
  font-weight: 500;
  width: 19px;
  height: 19px;
  ${FLEX.FlexCenterCenter};
`;
