import * as s from "../ModalTemplateStyled";
import ModalTemplate from "../ModalTemplate";
import CLOSE_ICON from "assets/icons/sidenav/x.svg";
import { forwardRef } from "react";

type Props = {
  children: React.ReactNode;
  onModal?: () => void;
  button: React.ReactNode;
  disableClose?: boolean;
};
const SmallPopupDefaultTemplate = forwardRef<HTMLDivElement, Props>(
  ({ children, onModal, button, disableClose }: Props, ref): JSX.Element => {
    return (
      <ModalTemplate ref={ref}>
        <s.SmallContainer>
          <s.SmallWrapper>
            {disableClose === undefined && (
              <s.CloseButton
                onClick={(e) => {
                  e.stopPropagation();
                  onModal && onModal();
                }}
              >
                <img alt="close button" src={CLOSE_ICON} />
              </s.CloseButton>
            )}
            <s.SmallInner>{children}</s.SmallInner>
            <s.SmallButtonWrapper>{button}</s.SmallButtonWrapper>
          </s.SmallWrapper>
        </s.SmallContainer>
      </ModalTemplate>
    );
  }
);

export default SmallPopupDefaultTemplate;
