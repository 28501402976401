import { CenterlineText } from "components/atoms/text/Text";
import styled from "styled-components";
import { COLORS } from "styles/colors";
import { FLEX } from "styles/flex";
import { calcRem } from "styles/theme";
import * as mediaQuery from "components/MediaQuery";

export const Container = styled.div`
  width: 100%;
  position: relative;
  ${mediaQuery.isDefault} {
    height: calc(100vh - 160px);
    overflow: hidden;
  }
`;

export const SpinnerContainer = styled.div`
  position: absolute;
  z-index: 777;
  width: 100%;
  height: 100%;
`;

export const Top = styled.div`
  width: 100%;
  background-color: ${({ theme }) => theme.COLORS.PRIMARY3};
  border-radius: 4px;
  padding: 20px;
  ${FLEX.FlexBetweenCenter};

  .refresh-button {
    :hover {
      > svg {
        path {
          fill: ${({ theme }) => theme.COLORS.WHITE};
        }
      }
    }
  }
`;

export const Header = styled.ul`
  ${FLEX.FlexStartStart};
  gap: 50px;
  flex: 4;

  ${mediaQuery.isMobile} {
    ${FLEX.FlexStartStart};
    gap: 18px;
    flex-direction: column;
  }
`;

export const HeaderItem = styled.li`
  ${FLEX.FlexStartStart};
  flex-direction: column;
  gap: 8px;

  ${mediaQuery.isMobile} {
    flex-direction: row;
    gap: 16px;
  }
`;

export const ButtonWrapper = styled.div`
  ${FLEX.FlexEndCenter};
  gap: 10px;

  ${mediaQuery.isDefault} {
    button {
      padding: 12px 10px;
      min-width: 100px;
    }
  }
`;

export const Grid = styled.div`
  ${mediaQuery.isDefault} {
    display: grid;
    grid-template-columns: auto 240px;
    grid-template-rows: repeat(2, auto);
    /* height: 500px; */
    gap: 10px;
    margin-top: 30px;
    padding-bottom: 40px;
    height: calc(100% - 95px);
  }

  ${mediaQuery.isMobile} {
    width: 100%;
    ${FLEX.FlexStartStart};
    flex-direction: column-reverse;
    gap: 26px;
    margin-top: 20px;

    .title {
      min-width: 20%;
    }
  }
`;

export const LeftAside = styled.section`
  width: 100%;
  height: 100%;
  /* overflow: hidden; */
  ${mediaQuery.isDefault} {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-auto-rows: min-content;
    gap: 20px;
    overflow-y: auto;
    min-height: 0;
    min-height: 35vh;
    min-width: 0;
  }
  @media (max-width: 1300px) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media (max-width: 900px) {
    grid-template-columns: repeat(2, 1fr);
  }
  ${mediaQuery.isMobile} {
    ${FLEX.FlexStartStart};
    flex-direction: column;
    gap: 20px;
  }
`;

export const CameraItem = styled.div`
  /* overflow: hidden; */
  border-radius: 0 0 4px 4px;
  background-color: ${({ theme }) => theme.COLORS.PRIMARY3};
  white-space: nowrap;
  box-sizing: border-box;
  width: 100%;
  min-width: 0;
`;

export const CameraContent = styled.ul`
  ${FLEX.FlexCenterCenter};
  flex-direction: column;
  padding: 10px 20px;
  width: 100%;
  max-width: 100%;

  /* box-sizing: border-box; */
`;

export const CameraContentRow = styled.li`
  ${FLEX.FlexStartCenter};
  flex: 1;
  width: 100%;
  max-width: 100%;

  &:nth-child(2) {
    margin: 10px 0;
  }

  ${mediaQuery.isDefault} {
    .ip-address {
      flex: 1;
      width: 100%;
      overflow: hidden;

      > span {
        font-size: ${calcRem(12)};
        color: ${({ theme }) => theme.COLORS.WHITE};
        width: 100%;
        text-overflow: ellipsis;
      }
    }
  }

  .ant-typography {
    margin-bottom: 0 !important;
    color: ${({ theme }) => theme.COLORS.WHITE};
    font-size: ${calcRem(13)};
  }
  .title {
    flex: 1;
    min-width: 65px;
    max-width: 65px;
  }
  .content {
    flex: 2;
    white-space: normal;
  }
  .copy {
    width: 12px;
  }
`;

export const Copy = styled.div`
  ${FLEX.FlexStartCenter};
  gap: 6px;
  overflow: hidden;
  white-space: nowrap;
`;

export const RightAside = styled.aside`
  background-color: ${({ theme }) =>
    theme.value === "light" ? COLORS.WHITE : "#0b1423"};
  padding: 15px 20px;

  ${mediaQuery.isDefault} {
    height: 100%;

    ${({ theme }) =>
      theme.value === "light" &&
      `
    border: 1px solid ${theme.COLORS.BORDER};
    `}
  }

  ${mediaQuery.isMobile} {
    width: 100%;
  }
`;

export const RightWrapper = styled.ul`
  ${mediaQuery.isDefault} {
    margin-bottom: 50px;
  }
  ${mediaQuery.isMobile} {
    &:first-child {
      margin-bottom: 30px;
    }
  }
  > p {
    margin-top: 20px;
  }
`;

export const RightItem = styled.li<{ noBorder?: boolean }>`
  ${FLEX.FlexStartStart};
  border-bottom: 1px solid ${COLORS.BUTTONS1}50;
  padding: 15px 0 8px;

  ${mediaQuery.isDefault} {
    div {
      font-size: ${calcRem(14)};
    }
    .title {
      flex: 2;
    }
    .content {
      flex: 3;
      width: 100%;
      max-width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .address {
      word-break: keep-all;
      white-space: normal;
      text-overflow: unset;
    }
  }
  ${mediaQuery.isMobile} {
    ${({ noBorder }) =>
      noBorder &&
      `
      border: none;
    `}
  }
`;
