/**
 * @author ydoni
 * @date 22.12.09
 * @description Dashboard Chart view Alerts 상태
 */

import { Text } from "components/atoms/text/Text";
import { COLORS, ALERT_CHART_COLORS } from "styles/colors";
import TRIANGLE_WARNING_CRITICAL from "assets/icons/triangle_warning_critical.svg";
import TRIANGLE_WARNING_RED from "assets/icons/triangle_warning_red.svg";
import TRIANGLE_WARNING_ORANGE from "assets/icons/triangle_warning_orange.svg";
import TRIANGLE_WARNING_BLUE from "assets/icons/triangle_warning_blue.svg";

import CHECK from "assets/icons/online_green.svg";
import {
  DashbaordAlertSendMessage,
  DashboarAlertLatest,
  DashboardChartAlertResponse,
} from "api/interfaces/dashboardInterface.interface";
import styled from "styled-components";
import { FLEX } from "styles/flex";
import { calcRem } from "styles/theme";
import { Fragment, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import VideoThumbnail from "components/atoms/image/thumbnail/VideoThumbnail";
import { secondsToDays, secondsToTime } from "utils/timeUtil";
import ReportEmailPopup from "components/templates/modal/small/email/ReportEmailPopup";
import { BiMailSend } from "react-icons/bi";
import { BiMessageRoundedDots } from "react-icons/bi";
import * as mediaQuery from "components/MediaQuery";

type DualProps = {
  data: DashboarAlertLatest;
  onAssignModal: (type: string, info: string) => void;
  onSelectItem: (item: DashboarAlertLatest) => void;
};

const changeToIcon = (status: string) => {
  switch (status) {
    case "RESOLVED":
      return CHECK;
    case "ASSIGNED":
      return TRIANGLE_WARNING_BLUE;
    case "WARNING":
      return TRIANGLE_WARNING_ORANGE;
    case "ALERT":
      return TRIANGLE_WARNING_RED;
    case "CRITICAL":
      return TRIANGLE_WARNING_CRITICAL;
  }
};

const AlertItem = (props: DualProps): JSX.Element => {
  const location = useLocation();
  const item = props.data;
  const isStatus =
    item.alertStatusString === "ALERT"
      ? item.isCriticalSeverity
        ? "CRITICAL"
        : item.alertStatusString
      : item.alertStatusString;
  const isMap = location.pathname.includes("/dashboard/map");
  // console.log(item, item.ruleType, "alert item");

  const alertText = (item: DashboarAlertLatest) => {
    if (
      item.ruleType !== undefined &&
      item.alertThreshold !== undefined &&
      item.warningElapsed !== undefined
      // item.targetCount !== undefined
    ) {
      if (item.ruleType === "Count")
        // return `OCCUR ${item.targetCount} TIMES IN 24 HOURS`;
        return `OCCUR ${item.warningElapsed} TIMES IN 24 HOURS`;
      if (item.ruleType === "Time") {
        let subtractTimeText = secondsToTime(
          item.alertThreshold - item.warningElapsed
        );
        // console.log(item, subtractTimeText, "alerttext");

        if (subtractTimeText) return `${subtractTimeText} TO ALERT`;
        else return "";
      }
      return item.alertTypeString;
    } else return item.alertTypeString;
  };

  const alertPercentage = (item: DashboarAlertLatest) => {
    // 진행중인 BAR 를 가려주는 비율로 계산해줘야함 (즉, 반대의 비율로 계산)
    if (
      item.ruleType !== undefined &&
      item.alertThreshold !== undefined &&
      item.warningElapsed !== undefined
    ) {
      return (1 - item.warningElapsed / item.alertThreshold) * 100;
    } else {
      if (isStatus === "WARNING") return 100;
      else return 0;
    }
  };

  const assginPercentage = (assigneeElapsed: number) => {
    // console.log(
    //   assigneeElapsed,
    //   secondsToDays(assigneeElapsed),
    //   "assginPercentage"
    // );
    if (secondsToDays(assigneeElapsed) >= 5) return 0;
    else return (1 - secondsToDays(assigneeElapsed) / 5) * 100;
  };

  return (
    <>
      <AlertItemWrapper>
        <AlertTitle>
          <StatusTitle isStatus={isStatus}>{item.alertTypeString}</StatusTitle>
          <mediaQuery.Default>
            <AlertTitleName isMap={isMap}>
              {item.mergedSystemName === item.name ? (
                <span>
                  {item.mergedSystemName}
                  {item.alertType === 40 && ` [${item.cameraName}]`}
                </span>
              ) : (
                <span>
                  {item.mergedSystemName}
                  <span className="server-name"> {item.name}</span>
                  {item.alertType === 40 && ` [${item.cameraName}] `}
                </span>
              )}
            </AlertTitleName>
          </mediaQuery.Default>
          <mediaQuery.Mobile>
            <AlertThumbnail>
              <VideoThumbnail
                videoLoss={item.alertType === 40}
                thumbnailUrl={item.thumbnailUrl}
                isRecorderList={isMap}
              />
            </AlertThumbnail>
          </mediaQuery.Mobile>
        </AlertTitle>
        <AlertContents>
          <mediaQuery.Default>
            <AlertThumbnail>
              <VideoThumbnail
                videoLoss={item.alertType === 40}
                thumbnailUrl={item.thumbnailUrl}
                isRecorderList={isMap}
              />
            </AlertThumbnail>
          </mediaQuery.Default>
          <AlertDesc>
            <AlertStatus>
              <AlertStatusBar>
                {/* 1번째 BAR */}
                <AlertStatusItem isWarning={true}>
                  <StatusTextWrapper>
                    {!isMap && <StatusText isEnd>{alertText(item)}</StatusText>}
                  </StatusTextWrapper>

                  <Bar action={"ALERT"} />
                  <HideBar
                    percentage={alertPercentage(item)}
                    action={"ALERT"}
                  />
                </AlertStatusItem>

                {/* 2번째 BAR */}
                <AlertStatusItem>
                  <StatusTextWrapper>
                    {!isMap &&
                      isStatus === "ASSIGNED" &&
                      item.assigneeElapsed !== undefined && (
                        <StatusText>
                          ASSIGNED {secondsToTime(item.assigneeElapsed)}{" "}
                          <mediaQuery.Default>AGO</mediaQuery.Default>
                        </StatusText>
                      )}
                    <StatusText isEnd>
                      <span className="status-name">{isStatus}</span>
                    </StatusText>
                  </StatusTextWrapper>

                  {/**
                   * Assign만 했을때
                   *  Assigned 날짜 5일 기준으로 계산함
                   *  5일 이후면 percentage 100 으로
                   */}
                  {isStatus === "ASSIGNED" && (
                    <AssingedBar>
                      <Bar action={isStatus} />
                      <HideBar
                        percentage={assginPercentage(item.assigneeElapsed)}
                        action={isStatus}
                      />
                    </AssingedBar>
                  )}

                  {isStatus === "RESOLVED" && <Bar action={isStatus} />}
                </AlertStatusItem>
              </AlertStatusBar>

              <img src={changeToIcon(isStatus)} alt="status icon" />
            </AlertStatus>
            {isMap && isStatus === "ALERT" && (
              <MapAlertReason>{alertText(item)}</MapAlertReason>
            )}
            <Text color="#687391" fontSize={14}>
              Rule: {item.alertRuleName}
            </Text>

            {isStatus === "ASSIGNED" &&
              item.assigneeName !== undefined &&
              item.assigneeId !== undefined && (
                <Assign isMap={isMap}>
                  <span>Assigned to {item.assigneeName}</span>
                  {item.assigneePhone !== undefined && (
                    <button
                      onClick={() => {
                        props.onAssignModal("phone", item.assigneePhone);
                        props.onSelectItem && props.onSelectItem(item);
                      }}
                    >
                      <BiMessageRoundedDots size={isMap ? 16 : 18} />
                    </button>
                  )}

                  {item.assigneeEmail !== undefined && (
                    <button
                      onClick={() => {
                        props.onAssignModal("email", item.assigneeEmail);
                        props.onSelectItem && props.onSelectItem(item);
                      }}
                    >
                      <BiMailSend size={isMap ? 16 : 18} />
                    </button>
                  )}
                </Assign>
              )}

            {/*
                //Auto recovery
                <Text
                  className="etc"
                  color={COLORS.BUTTONS1}
                  fontSize={isMap ? 12 : 16}
                >
                  Automatic recovery after Warning for 40 Mins
                </Text> */}
            {/* {isStatus === "WARNING" && (
              <Text color={COLORS.BUTTONS1} fontSize={isMap ? 12 : 16}>
                Trigger alert after 9 hours
              </Text>
            )} */}
          </AlertDesc>
        </AlertContents>
      </AlertItemWrapper>
    </>
  );
};

export default AlertItem;
const AlertItemWrapper = styled.li`
  padding: 10px 0;
  padding-right: 15px;
  border-bottom: 1px solid
    ${({ theme }) =>
      theme.value === "light" ? "rgb(237, 237, 237)" : COLORS.BORDER};

  &:last-child {
    border-width: 0;
  }
`;

export const AlertTitle = styled.div`
  ${FLEX.FlexStartCenter};
  padding-bottom: 12px;
  gap: 14px;

  ${mediaQuery.isMobile} {
    align-items: flex-start;
    flex-direction: column;
    gap: 8px;
  }
`;

const AlertLeftStyle = `
  width: 20%;
  min-width: 140px;
`;

export const StatusTitle = styled.p<{ isStatus: string }>`
  /* flex: 2; */
  ${AlertLeftStyle}
  color: ${({ isStatus }) => ALERT_CHART_COLORS[isStatus]};
  font-weight: 500;
  white-space: nowrap;
  font-size: ${calcRem(14)};
  overflow: hidden;
  text-overflow: ellipsis;

  ${mediaQuery.isMobile} {
    font-size: ${calcRem(16)};
  }
`;

export const AlertTitleName = styled.p<{ isMap: boolean }>`
  /* flex: 6; */
  width: 80%;
  color: ${({ theme }) => theme.COLORS.WHITE};
  ${({ isMap }) =>
    isMap &&
    `
  font-size: ${calcRem(14)};
`}

  .server-name {
    color: ${COLORS.BUTTONS1};
    font-size: ${calcRem(13)};
  }

  ${mediaQuery.isMobile} {
    span {
      ${FLEX.FlexStartStart};
      flex-direction: column;
      gap: 4px;
    }
  }
`;

export const AlertContents = styled.div`
  ${FLEX.FlexBetweenStart};
  /* height: 70px; */
  gap: 14px;
  /* padding-bottom: 20px; */

  ${mediaQuery.isMobile} {
    flex-direction: column;
  }
`;

export const AlertThumbnail = styled.div`
  ${AlertLeftStyle};
  /* height: 100%; */
  overflow: hidden;

  /* img {
    background-color: #fff;
    width: 100%;
    height: 100%;
  } */
`;
export const AlertDesc = styled.div`
  /* flex: 6; */
  width: 80%;
  height: 100%;
  ${FLEX.FlexStartStart};
  gap: 8px;
  flex-direction: column;
  /* border: 1px solid blue; */

  .etc {
    width: 100%;
  }

  ${mediaQuery.isMobile} {
    width: 100%;
  }
`;
const AlertStatus = styled.div`
  width: 100%;
  height: 20px;

  ${FLEX.FlexBetweenCenter};
  gap: 12px;
`;

const AlertStatusBar = styled.ul`
  width: 100%;
  height: 100%;
  ${FLEX.FlexStartCenter};
`;

const AlertStatusItem = styled.li<{ isWarning?: boolean; isAssign?: boolean }>`
  width: 50%;
  height: 100%;
  color: ${COLORS.WHITE};
  font-weight: 500;
  font-size: ${calcRem(12)};
  position: relative;
  background-color: transparent;
  ${FLEX.FlexStartCenter};

  ${({ isWarning }) =>
    isWarning &&
    `
      background-color: ${COLORS.RED}30;
    `}

  ${({ isAssign }) =>
    isAssign &&
    `
      width: 75%;
    `}
`;

const Bar = styled.div<{ action: string }>`
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;

  ${({ action }) =>
    (action === "ALERT" || action === "WARNING" || action === "CRITICAL") &&
    `
    background : linear-gradient(50deg, ${ALERT_CHART_COLORS.WARNING}, ${ALERT_CHART_COLORS.ALERT});

   `};

  ${({ action }) =>
    (action === "RESOLVED" || action === "ASSIGNED") &&
    `
    background : linear-gradient(50deg, ${ALERT_CHART_COLORS.ASSIGNED}, ${ALERT_CHART_COLORS.RESOLVED});

   `};
`;

const StatusTextWrapper = styled.div`
  width: 100%;
  ${FLEX.FlexBetweenCenter};
  position: absolute;
  z-index: 3;
  padding: 0 6px;
`;
const StatusText = styled.span<{ isEnd?: boolean; bold?: boolean }>`
  ${({ bold }) => bold && `font-weight: 600;`};
  flex: 1;
  color: ${COLORS.WHITE};
  ${({ theme, isEnd }) =>
    theme.value === "light" &&
    !isEnd &&
    `
  text-shadow: rgba(0, 0, 0, 0.8) 1px 1px 1px;
  `}
  font-size: ${calcRem(12)};
  white-space: nowrap;
  ${({ isEnd, theme }) =>
    isEnd &&
    `${FLEX.FlexEndCenter};
    .status-name {
      color: ${theme.COLORS.WHITE};
    }
  `};
`;

const Assign = styled.div<{ isMap: boolean }>`
  ${FLEX.FlexStartCenter};
  gap: 8px;
  color: ${({ theme }) => theme.COLORS.SELECTED} !important;
  font-size: ${({ isMap }) => calcRem(isMap ? 12 : 16)};

  button {
    ${FLEX.FlexCenterCenter};
    color: ${({ theme }) => theme.COLORS.SELECTED};
  }
`;

const MapAlertReason = styled.span`
  font-size: ${calcRem(12)};
  color: ${COLORS.RED};
`;

const HideBar = styled.div<{ percentage: number; action: string }>`
  position: absolute;
  top: 0;
  right: 0;
  width: ${({ percentage }) => `${percentage}%`};
  height: 100%;

  ${({ action }) =>
    (action === "ALERT" || action === "WARNING" || action === "CRITICAL") &&
    `
    background-color: #3F121C;


   `};

  ${({ action, theme }) =>
    (action === "RESOLVED" || action === "ASSIGNED") &&
    `
    background-color : ${theme.COLORS.WIDGETCOLOR};

   `};
`;

const AssingedBar = styled(AlertStatusItem)`
  width: calc(100% - 70px);
`;
