import * as s from "./InstallPrintPageStyled";
import { Text } from "components/atoms/text/Text";
import DefaultThumbnail from "components/atoms/image/DefaultThumbnail";
import { InstallationView } from "api/interfaces/reportInterface.interface";
import { convertType, getDomainUrl } from "utils/functions";
import VideoThumbnail from "components/atoms/image/thumbnail/VideoThumbnail";
import { Fragment } from "react";
import { ProfileAccountInfo } from "api/interfaces/accountInterface.interface";
import { useAppSelector } from "redux/hooks";

type Props = {
  title: string;
  installationData: InstallationView;
  componentRef: any;
};

export default function InstallPrintPage(props: Props): JSX.Element {
  const selectedAccount: ProfileAccountInfo = useAppSelector(
    (state) => state.accountSettings
  );

  // console.log(props.installationData);
  return (
    <s.Container ref={props.componentRef}>
      <s.Header>
        Your Installation Report Is Now Available on<span>myDW</span>
      </s.Header>
      <s.Inner>
        <div>
          <ul className="right-wrapper">
            <li>
              <Text className="title" bold>
                Recorder:
              </Text>
              <Text className="content">
                {props.installationData.recorderInfo.name}
              </Text>
            </li>
            <li>
              <Text className="title" bold>
                Customer:
              </Text>
              <Text className="content">
                {props.installationData.installationReport.customerName}
              </Text>
            </li>
            <li>
              <Text className="title" bold>
                Organization:
              </Text>
              <Text className="content">{selectedAccount.accountName}</Text>
            </li>
            <li>
              <Text className="title" bold>
                Model:
              </Text>
              <Text className="content">
                {props.installationData.recorderInfo.model !== undefined
                  ? props.installationData.recorderInfo.model
                  : // ? convertType(props.installationData.recorderInfo.model)
                    "N/A"}
              </Text>
            </li>
            <li>
              <Text className="title" bold>
                Installation Date
              </Text>
              <Text className="content">
                {props.installationData.installationReport.installationDate}
              </Text>
            </li>
            {/* <li>
              <Text className="title" bold>
                Analog Camera Model
              </Text>
              <Text className="content"></Text>
            </li> */}
            <li>
              <Text className="title" bold>
                Location
              </Text>
              <Text className="content">
                {props.installationData.recorderInfo.location}
              </Text>
            </li>
            <li>
              <Text className="title" bold>
                Description
              </Text>
              <Text className="content">
                {props.installationData.installationReport.deviceDescription
                  ? props.installationData.installationReport.deviceDescription
                  : ""}
              </Text>
            </li>
          </ul>
        </div>
        <div>
          <s.PreviewTitle>Installation Preview</s.PreviewTitle>
          <section className="left-aside">
            {props.installationData?.cameraInfo !== undefined &&
              props.installationData?.cameraInfo.map((item, index: number) => (
                <s.CameraItem
                  key={`installation-camera-${index}`}
                  isBreak={
                    index + 1 === 4 || (index + 1 > 6 && (index - 3) % 6 === 0)
                  }
                >
                  <DefaultThumbnail>
                    <VideoThumbnail
                      videoLoss={false}
                      thumbnailUrl={item.thumbnailUrl}
                      isLazy={false}
                      installationStatus={item.installationStatus}
                    />
                  </DefaultThumbnail>
                  <s.CameraContent>
                    <s.CameraContentRow>
                      <Text className="title" fontSize={14} bold>
                        Camera
                      </Text>
                      <Text className="content" fontSize={14}>
                        {item.name}
                      </Text>
                    </s.CameraContentRow>
                    <s.CameraContentRow>
                      <Text className="title" fontSize={14} bold>
                        Model
                      </Text>
                      <Text className="content" fontSize={14}>
                        {item.model}
                      </Text>
                    </s.CameraContentRow>
                    {/* <s.CameraContentRow>
                      <Text className="title" fontSize={14} bold>
                        Web
                      </Text>

                      <Text fontSize={14} className="ip-address">
                        {getDomainUrl(item.url)}
                      </Text>
                    </s.CameraContentRow> */}
                  </s.CameraContent>
                </s.CameraItem>
              ))}
          </section>
        </div>
        <s.BottomInfo>
          <s.BottomWrapper>
            <ul>
              <li>
                <Text className="title">Customer</Text>
                <Text className="content">
                  {props.installationData.installationReport.customerName}
                </Text>
              </li>
              {/* <li>
                <Text className="title">Date</Text>
                <Text className="content"></Text>
              </li> */}
              <li>
                <Text className="title">Phone</Text>
                <Text className="content">
                  {
                    props.installationData.installationReport
                      .customerPhoneNumber
                  }
                </Text>
              </li>
              <li>
                <Text className="title">Address</Text>
                <Text className="content">
                  {props.installationData.installationReport.customerLocation}
                </Text>
              </li>
              <li>
                <Text className="title">Signature</Text>
              </li>
            </ul>
            <ul>
              <li>
                <Text className="title">Installer</Text>
                <Text className="content">
                  {props.installationData.installationReport.installerName}
                </Text>
              </li>
              {/* <li>
                <Text className="title">Date</Text>
                <Text className="content"></Text>
              </li> */}
              <li>
                <Text className="title">Phone</Text>
                <Text className="content">
                  {
                    props.installationData.installationReport
                      .installerPhoneNumber
                  }
                </Text>
              </li>
              <li>
                <Text className="title">Company</Text>
                <Text className="content">
                  {props.installationData.installationReport.installerCompany
                    ? props.installationData.installationReport.installerCompany
                    : ""}
                </Text>
              </li>
              <li>
                <Text className="title">Phone</Text>
                <Text className="content">
                  {props.installationData.installationReport
                    .installerCompanyPhoneNumber
                    ? props.installationData.installationReport
                        .installerCompanyPhoneNumber
                    : ""}
                </Text>
              </li>
              <li>
                <Text className="title">Address</Text>
                <Text className="content">
                  {props.installationData.installationReport
                    .installerCompanyAddress
                    ? props.installationData.installationReport
                        .installerCompanyAddress
                    : ""}
                </Text>
              </li>
              <li>
                <Text className="title">Signature</Text>
              </li>
            </ul>
          </s.BottomWrapper>
          {/* <s.Footer>
            <ul>
              <li>
                <Text className="title">Company</Text>
                <Text className="content">
                  {props.installationData.installationReport.installerCompany
                    ? props.installationData.installationReport.installerCompany
                    : ""}
                </Text>
              </li>
              <li>
                <Text className="title">Phone</Text>
                <Text className="content">
                  {props.installationData.installationReport
                    .installerCompanyPhoneNumber
                    ? props.installationData.installationReport
                        .installerCompanyPhoneNumber
                    : ""}
                </Text>
              </li>
              <li>
                <Text className="title">Address</Text>
                <Text className="content">
                  {props.installationData.installationReport
                    .installerCompanyAddress
                    ? props.installationData.installationReport
                        .installerCompanyAddress
                    : ""}
                </Text>
              </li>
            </ul>
          </s.Footer> */}
        </s.BottomInfo>
      </s.Inner>
    </s.Container>
  );
}
