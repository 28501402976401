import styled from "styled-components";
import { FLEX } from "styles/flex";
import { COLORS, LIGHT_THEME_COLORS } from "styles/colors";

import { calcRem } from "styles/theme";
import { useEffect, useRef, useState } from "react";
import "react-phone-number-input/style.css";
import PhoneInput, {
  formatPhoneNumber,
  formatPhoneNumberIntl,
  isPossiblePhoneNumber,
  isValidPhoneNumber,
} from "react-phone-number-input";
import { commonLoginHeight } from "pages/auth/SignCommonStyled";

interface PhoneInputProps {
  login?: boolean;
  placeholder?: string;
  children?: any;
  selected?: string;
  onSelect?: (code: string) => void;
  onChange?: (phoneNumber: string) => void;
  disabled?: boolean;
  onEnterKeyPress?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
  isValidated?: boolean;
}

const Wrapper = styled.label<{
  disabled?: boolean;
  login?: boolean;
  isValidated?: boolean;
}>`
  ${FLEX.FlexStartCenter};
  width: 100%;
  height: 33px;
  border-radius: 4px;
  border: 1px solid #cacaca;
  overflow: hidden;

  ${({ login }) =>
    login &&
    `
      height: ${commonLoginHeight};
    `}

  .PhoneInput {
    height: 100%;
    width: 100%;

    input {
      height: 100%;
      padding: 0 6px;
      font-size: ${calcRem(14)};
      border: none;

      ::placeholder {
        color: #abb5c5;
      }

      :disabled {
        background-color: ${({ theme }) =>
          theme.value === "light"
            ? LIGHT_THEME_COLORS.LIGHTGRAY
            : COLORS.BUTTONS1};

        color: ${({ theme }) => `${theme.COLORS.WHITE}60`};
      }
    }

    .PhoneInputCountry {
      padding: 0 6px;
      background-color: ${COLORS.WHITE};
      margin-right: 0;
      border-right: 1px solid #cacaca;

      .PhoneInputCountrySelect {
        z-index: unset;
      }

      ${({ disabled, theme }) =>
        disabled && {
          backgroundColor:
            theme.value === "light"
              ? LIGHT_THEME_COLORS.LIGHTGRAY
              : COLORS.BUTTONS1,
          borderColor: LIGHT_THEME_COLORS.BORDER,
        }}
    }
  }

  ${({ disabled }) =>
    disabled &&
    `
    border-color: ${LIGHT_THEME_COLORS.BORDER};
  `}

  ${({ isValidated }) =>
    isValidated &&
    `
    border-color: ${COLORS.VALIDATERED};

  `}
`;

const PhoneInputComponent = ({
  login,
  placeholder,
  selected,
  onChange,
  disabled,
  isValidated,
}: PhoneInputProps): JSX.Element => {
  const [value, setValue] = useState<string>(selected ? selected : "");

  const onChangeValue = (changeValue: string | undefined) => {
    if (changeValue === undefined) setValue("");
    else {
      setValue(changeValue);
    }
  };

  useEffect(() => {
    //console.log(isValidPhoneNumber(value), isPossiblePhoneNumber(value));
    onChange && onChange(value);
  }, [value]);

  useEffect(() => {
    selected !== undefined && setValue(selected);
  }, [selected]);

  return (
    <Wrapper login={login} isValidated={isValidated} disabled={disabled}>
      <PhoneInput
        defaultCountry="US"
        countries={["US"]}
        initialValueFormat="national"
        countryCallingCodeEditable={false}
        limitMaxLength
        addInternationalOption={false}
        disabled={disabled}
        placeholder={placeholder}
        value={value}
        onChange={onChangeValue}
        international={false}
      />
    </Wrapper>
  );
};

export default PhoneInputComponent;
