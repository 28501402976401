import * as s from "./DetailButtonStyled";
import { UnderlineText } from "components/atoms/text/Text";
import { COLORS } from "styles/colors";
import { useState } from "react";
import InfoBubble from "components/blocks/bubble/InfoBubble";

export type ContentsType = {
  [key: string]: number | string;
};
type DetailProps = {
  title: string;
  contents: ContentsType[];
};

function DetailButton(props: DetailProps): JSX.Element {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  return (
    <s.Wrapper>
      <UnderlineText
        fontSize={14}
        color={COLORS.BUTTONS1}
        onMouseEnter={() => setIsOpen(true)}
        onMouseLeave={() => setIsOpen(false)}
      >
        Details
      </UnderlineText>
      {isOpen && (
        <s.Bubble>
          <InfoBubble>
            <s.BubbleInner>
              {props.title}:
              {props.contents.map((content, index) => (
                <li key={index}>
                  <span>{content.label}</span>
                  <span className="detail-bubble-value">{content.value}</span>
                </li>
              ))}
            </s.BubbleInner>
          </InfoBubble>
        </s.Bubble>
      )}
    </s.Wrapper>
  );
}

export default DetailButton;
