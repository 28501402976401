import {
  configureStore,
  ThunkAction,
  Action,
  getDefaultMiddleware,
  combineReducers,
  AnyAction,
  EmptyObject,
  ThunkMiddleware,
} from "@reduxjs/toolkit";
import noticeReducer, { NoticeState } from "./reducers/notices/noticeReducer";
import cookieReducer, { CookieState } from "./reducers/notices/cookieReducer";
import menuSlice from "./reducers/menu/menuSlice";
import userPopup from "./reducers/menu/userPopup";
import logger from "redux-logger";
import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage";
import storageSession from 'redux-persist/lib/storage/session'
import accountSlice from "./reducers/account/accountSlice";
import BreadcrumbSlice, { Breadcrumb } from "./reducers/breadcrumb/breadcrumb";
import SideNavReducer, { SideNavOpen } from "./reducers/sidenav/SideNavReducer";
import RecorderList from "./reducers/map/recorderList";
import themeReducer, { Theme } from "./reducers/theme/themeReducer";
import mapFilterSlice, { MapFilter } from "./reducers/map/mapFilter";
import dashboardDataSlice from "./reducers/map/dashboardData";
import dashboardSearchData from "./reducers/map/searchData";
import ValidAuthSlice from "./reducers/auth/isAuth";

const userPersistConfig ={
  key: "authRoot",
  storage : storage,
}

const persistConfig = {
  key: "root",
  storage : storageSession,
  whitelist: [
    "menuSelect",
    "userPopup",
    "accountSettings",
    "breadcrumb",
    "sidenavOpen",
    "recorderList",
    "mapFilter",
    "dashboardSearchData",
    "dashboardData",
  ],
  blacklist:[
      "notice",
      "theme",
      "cookie",
      "isAuth"
    ]
};

const rootReducer = combineReducers({
  menuSelect: menuSlice.reducer,
  userPopup: userPopup.reducer,
  notice: persistReducer(userPersistConfig,noticeReducer),
  cookie: persistReducer(userPersistConfig,cookieReducer),
  accountSettings: accountSlice.reducer,
  breadcrumb: BreadcrumbSlice.reducer,
  sidenavOpen: SideNavReducer.reducer,
  recorderList: RecorderList.reducer,
  theme: persistReducer(userPersistConfig,themeReducer.reducer),
  mapFilter: mapFilterSlice.reducer,
  dashboardData: dashboardDataSlice.reducer,
  dashboardSearchData: dashboardSearchData.reducer,
  isAuth:  ValidAuthSlice.reducer
});
// const rootReducer = combineReducers({
//   menuSelect: persistReducer(persistConfig, menuSlice.reducer),
//   userPopup: persistReducer(persistConfig,userPopup.reducer),
//   notice: persistReducer(userPersistConfig,noticeReducer),
//   cookie: persistReducer(userPersistConfig,cookieReducer),
//   accountSettings: persistReducer(persistConfig,accountSlice.reducer),
//   breadcrumb: persistReducer(persistConfig,BreadcrumbSlice.reducer),
//   sidenavOpen: persistReducer(persistConfig,SideNavReducer.reducer),
//   recorderList: persistReducer(persistConfig,RecorderList.reducer),
//   theme: persistReducer(userPersistConfig,themeReducer.reducer),
//   mapFilter: persistReducer(persistConfig,mapFilterSlice.reducer),
//   dashboardData: persistReducer(persistConfig,dashboardDataSlice.reducer),
//   dashboardSearchData: persistReducer(persistConfig,dashboardSearchData.reducer)
// });

const persistedReducer = persistReducer( persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware:

    process.env.NODE_ENV === "development"
      ? [
          ...getDefaultMiddleware({
            serializableCheck: false,
          }),
          logger,
        ]
      : [
          ...getDefaultMiddleware({
            serializableCheck: false,
          }),
        ],
  devTools: process.env.NODE_ENV !== "production",
  preloadedState: {
    // counter: {
    //   value: 0,
    //   status: "idle",
    // },
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;

export const persistor = persistStore(store);
