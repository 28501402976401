import * as s from "../TableItemStyled";
import { useCallback, useState } from "react";
import { useQuery } from "react-query";
import { notify } from "components/atoms/notification/Notification";
import { useIntl } from "react-intl";

import { ProfileAccountInfo } from "api/interfaces/accountInterface.interface";
import { useAppSelector } from "redux/hooks";
import AccountSelects from "../AccountSelects";
import { mobileMax } from "components/MediaQuery";
import { useMediaQuery } from "react-responsive";
import { intervalOptions } from "utils/options";
import BarChart, { barKeyName } from "components/atoms/chart/BarChart";
import { BarDatum } from "@nivo/bar";
import { COLORS } from "styles/colors";
import { postStatCloudStorage } from "api/widgetAPI";
import {
  GetStatInterval,
  GetWidgetCloudStorageResponse,
} from "api/interfaces/widgetInterface.interface";
import {
  convertBytesToTB,
  convertTBtoBytes,
  formatBytes,
  formatBytesAndUnits,
} from "utils/functions";
import Selects from "components/atoms/select/Select";
import {
  calTickValues,
  getAxisBottomValue,
  getMaxYValue,
} from "pages/dashboard/dashboard/WidgetContainer";
import TableItemHeader from "../TableItemHeader";
import { Layout } from "react-grid-layout";
import { TableItemName } from "../TableItemList";
import { StorageProps } from "components/blocks/storage/StorageLabel";
import NoDataChart from "components/templates/nopage/NoDataChart";

interface Props {
  layout: Layout;
  chartLayout: Layout[];
  onDeleteWidget: (key: TableItemName) => void;
  onLayoutChange: (layout: Layout[]) => void;
}

export default function CloudStorage(props: Props): JSX.Element {
  const intl = useIntl();
  const selectedAccount: ProfileAccountInfo = useAppSelector(
    (state) => state.accountSettings
  );

  const [accountIds, setAccountIds] = useState<string[]>([]);
  const [interval, setInterval] = useState<GetStatInterval>("daily");

  const [data, setData] = useState<BarDatum[]>([]);
  const [curStorage, setCurStorage] = useState<StorageProps>({
    usedStorage: 0,
    totalStorage: 0,
  });

  const getCloudStorage = useQuery(
    ["postStatCloudStorage", selectedAccount, accountIds, interval],
    () =>
      postStatCloudStorage({
        accountId: selectedAccount.accountId,
        interval: interval,
        payload: {
          accountIds,
          keyword: "",
        },
      }),
    {
      retry: 0,
      refetchOnWindowFocus: false,
      onSuccess: (res: GetWidgetCloudStorageResponse) => {
        if (res.error !== 0 && res.result === undefined) {
          setData([]);
        } else {
          let tempData = res.result.stats
            ? res.result.stats.map((stat) => {
                return {
                  key: stat.date,
                  indexName: stat.date,
                  [barKeyName]: stat.usedStorage,
                  [`${barKeyName}Color`]: COLORS.BUTTONS2,
                };
              })
            : [];
          // console.log(res.result, "cloudstorage");

          setCurStorage({
            usedStorage: res.result.usedStorage,
            totalStorage: res.result.totalStorage,
          });
          setData(tempData);
        }
      },
      onError: (err: any) => {
        setData([]);
      },
    }
  );

  const onChangeAccount = (value: string[]) => {
    setAccountIds(value);
  };

  const onChangeInterval = (value: string) => {
    setInterval(value.toLowerCase() as GetStatInterval);
  };

  return (
    <>
      <TableItemHeader
        layout={props.layout}
        chartLayout={props.chartLayout}
        onDeleteWidget={props.onDeleteWidget}
        onLayoutChange={props.onLayoutChange}
        isError={getCloudStorage.isError}
      />
      <s.Contents className="drag-cancel-content">
        <s.ContentWrapper>
          <s.ContentHeader>
            <div>
              <AccountSelects onChange={onChangeAccount} value={accountIds} />
              <div className="select-wrapper interval-select">
                <Selects
                  value={intervalOptions.find(
                    (opt) => opt.value === interval.toUpperCase()
                  )}
                  options={intervalOptions.filter(
                    (opt) => opt.value !== "MONTHLY"
                  )}
                  onChange={onChangeInterval}
                />
              </div>
            </div>
            <div>
              Cloud storage used:{" "}
              <strong className="cloud-storage">
                {curStorage.usedStorage === 0
                  ? 0
                  : formatBytes(curStorage.usedStorage)}{" "}
                /{" "}
                {curStorage.totalStorage === 0
                  ? 0
                  : formatBytes(curStorage.totalStorage)}
              </strong>
            </div>
          </s.ContentHeader>
          <s.ContentInner>
            <div className="bar-chart-wrapper">
              {data.length === 0 ? (
                <NoDataChart />
              ) : (
                <BarChart
                  data={data}
                  layout="vertical"
                  margin={{ top: 70, right: 20, bottom: 30, left: 70 }}
                  gridYValues={4}
                  enableGridY={
                    data.every((d) => d.barChartKey === 0) ? false : true
                  }
                  legendLabel={(value) => `${value} TB`}
                  axisLeft={{
                    // Y축의 단위를 byte가 아닌 적절한 단위로 변환하여 표시
                    format: (value) => {
                      const { value: formattedValue, unit } =
                        formatBytesAndUnits(value);
                      return `${formattedValue} ${unit}`;
                    },
                    tickSize: 0,
                    tickValues: calTickValues(data),
                  }}
                  axisBottom={{
                    tickValues: getAxisBottomValue(data, props.layout.w),
                    tickSize: 7, // 눈금의 길이 설정
                    tickPadding: 1, // 눈금과 레이블 간의 간격 설정
                  }}
                  isInteractive={false}
                  layers={[
                    "grid",
                    "axes",
                    "bars",
                    "markers",
                    "legends",
                    "annotations",
                  ]}
                  maxValue={getMaxYValue(data)}
                />
              )}
            </div>
          </s.ContentInner>
        </s.ContentWrapper>
      </s.Contents>
    </>
  );
}
