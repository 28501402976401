import styled from "styled-components";
import { COLORS } from "styles/colors";
import { FLEX } from "styles/flex";

export const Row = styled.div`
  ${FLEX.FlexStartCenter};
  gap: 15px;
`;
export const NameLabel = styled(Row)`
  /* gap: 7px; */
  cursor: pointer;
`;

export const ButtonWrapper = styled(Row)<{ disabled?: boolean }>`
  ${({ disabled }) =>
    disabled &&
    `
    opacity: 0.25;
  `}
`;

/**
 * View Modal Styled
 */
export const ViewInner = styled.section`
  width: 100%;
  text-align: left;
  color: #828b9b;
  padding: 50px 0;
  line-height: 1.5rem;
`;

export const ViewSubWrapper = styled.ul`
  padding: 20px 0;
`;

export const ViewSubTitle = styled.li`
  color: ${COLORS.BUTTONS1};
  padding: 3px 0;

  strong {
    padding-right: 10px;
  }

  .message {
    padding-bottom: 20px;
  }
`;
