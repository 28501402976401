import styled from "styled-components";
import { COLORS, LIGHT_THEME_COLORS } from "styles/colors";
import { FLEX } from "styles/flex";
import { calcRem } from "styles/theme";
import * as mediaQuery from "components/MediaQuery";

export const Container = styled.div`
  color: ${({ theme }) => theme.COLORS.WHITE};
  width: 100%;
  padding: 50px 0;
`;

export const Header = styled.ul`
  padding-bottom: 14px;
  border-bottom: 1px solid ${({ theme }) => theme.COLORS.BORDER};
  ${FLEX.FlexBetweenCenter};

  button {
    color: ${({ theme }) => theme.COLORS.WHITE};
  }

  ${mediaQuery.isDefault} {
    > li {
      ${FLEX.FlexStartCenter};
      gap: 8px;
      flex: 1;

      .select-wrapper {
        min-width: 100px;
      }

      :nth-child(2) {
        flex: 2;
        justify-content: center;
      }
      :nth-child(3) {
        justify-content: flex-end;
      }
    }
  }

  ${mediaQuery.isMobile} {
    > li {
      ${FLEX.FlexBetweenCenter};
      flex: 1;

      :nth-child(2) {
        justify-content: center;
      }
    }
  }
`;

export const Select = styled.select`
  -o-appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: none;
  border: none;
  text-decoration: underline;
  cursor: pointer;
  text-align: center;
  font-size: ${calcRem(20)};
  color: ${({ theme }) => theme.COLORS.WHITE};

  option {
    color: ${COLORS.BLACK};
    font-size: ${calcRem(14)};
  }
`;

export const InfoTitleContainer = styled.ul`
  ${FLEX.FlexBetweenStart};
  padding: 40px 0;
  font-size: ${calcRem(14)};

  > li {
    width: 300px;
    ${FLEX.FlexStartStart};
    flex-direction: column;
    gap: 5px;

    h1 {
      font-size: ${calcRem(32)};
      font-weight: 500;
      color: ${({ theme }) => theme.COLORS.WHITE};
    }
  }
  .date {
    color: ${({ theme }) => theme.COLORS.BUTTONS1};
  }
  .company-image {
    width: 100px;
    height: 100px;
    margin-bottom: 6px;

    > img {
      width: 100%;
    }
  }
  .billing-info-box {
    width: 100%;
    ${FLEX.FlexStartStart};
    flex-direction: column;
    gap: 5px;
    > li {
      width: 100%;
      ${FLEX.FlexBetweenCenter};
      border-bottom: 1px solid ${({ theme }) => theme.COLORS.BORDER};
      padding: 4px 0;

      > div {
        font-size: ${calcRem(14)};
      }
    }
  }

  ${mediaQuery.isMobile} {
    flex-direction: column;
    gap: 20px;
    width: 100%;

    > li {
      width: 100%;

      h1 {
        font-size: ${calcRem(24)};
      }
    }
    > li:first-child {
      padding-bottom: 20px;
      border-bottom: 1px solid ${({ theme }) => theme.COLORS.BORDER};
    }
  }
`;

export const TableBox = styled.div`
  width: 100%;
  margin-bottom: 50px;

  .modal-submenu {
    padding-top: 0;
    gap: 10px;

    * {
      font-size: ${calcRem(14)};
    }

    li {
      padding-bottom: 4px;
      padding-top: 0;
    }
  }
`;

export const TableContainer = styled.div`
  ${FLEX.FlexStartStart};
  flex-direction: column;
  gap: 30px;
  width: 100%;
`;

export const TableTitleBox = styled(TableContainer)`
  gap: 10px;
`;

export const TableWrapper = styled.div`
  width: 100%;

  table {
    /* table-layout: fixed; */
    width: 100%;
    color: ${({ theme }) => theme.COLORS.WHITE};
  }
  thead {
    background: ${({ theme }) => theme.COLORS.PRIMARY3};

    th {
      padding: 10px;
      font-weight: 500;
      text-align: left;
    }
  }
  tbody {
    width: 100%;
  }

  td {
    padding: 4px 10px;
    color: ${({ theme }) =>
      theme.value === "light" ? `${COLORS.BLACK}` : "#828b9b"};
    font-size: ${calcRem(14)};
  }

  .bold {
    font-weight: 500;
  }

  .row {
    ${FLEX.FlexStartCenter};
    gap: 20px;
  }
  .center {
    ${FLEX.FlexCenterCenter};
  }
`;

export const TBodyTR = styled.tr`
  width: 100%;

  :nth-child(odd) {
    background-color: ${({ theme }) =>
      theme.value === "light" ? `#fcfcfc` : `${COLORS.PRIMARY3}50`};
  }
`;

export const TableTitleItem = styled.div<{ isCollapsed?: boolean }>`
  ${FLEX.FlexStartCenter};
  flex: 1;
  width: 100%;

  .bold {
    font-weight: 500;
  }

  ${({ isCollapsed, theme }) =>
    isCollapsed &&
    `
    padding-bottom: 10px;
    border-bottom: 1px solid ${theme.COLORS.BORDER}
  `}
`;

export const TableTitle = styled.div`
  ${FLEX.FlexStartCenter};
  flex: 1;
  gap: 8px;
  font-size: ${calcRem(18)};

  > img {
    width: 24px;
    height: 24px;
  }
  .collapse-button {
    > img {
      width: 14px;
    }
  }
`;

export const StatusCell = styled.ul`
  ${FLEX.FlexStartCenter};
  width: 100%;

  > li {
    flex: 1;
  }
`;

export const SubMenuWrapper = styled.div`
  width: 100%;
  ${FLEX.FlexEndStart};
  gap: 10px;

  .title {
    font-weight: 500;
    font-size: ${calcRem(14)};
  }
`;
