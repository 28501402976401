import * as s from "./StatusStyled";
import { Text } from "components/atoms/text/Text";
import { COLORS } from "styles/colors";
import * as mediaQuery from "components/MediaQuery";
import { useMediaQuery } from "react-responsive";
import styled from "styled-components";
import { calcRem } from "styles/theme";
import { FLEX } from "styles/flex";

type StatusProps = {
  status: StatusType;
};

type StatusShowProps = {
  [key: string]: boolean;
};

export interface StatusType {
  totalTitle?: string;
  totalValue?: number;
  items?: Array<any>;
  isMobileShow?: StatusShowProps;
}

/**
 *
 * @param status
 * 현재는 status 타입이 bool 이지만,
 * Array로 받아와서 데이터 mapping 하는 형식
 *
 * ex. [{
 * value: 10
 * }]
 * @returns
 */
function Status({ status }: StatusProps): JSX.Element {
  const isNotMobile = useMediaQuery({ minWidth: mediaQuery.tabletMin });
  return (
    <s.StatusContainer>
      <StatusLabel>
        <mediaQuery.Mobile>
          {status.isMobileShow &&
            status.isMobileShow.totalTitle &&
            status.totalTitle && (
              <StatusLabel>
                <Text fontSize={20}>{status.totalTitle}</Text>
              </StatusLabel>
            )}
        </mediaQuery.Mobile>
        {isNotMobile && status.totalTitle && (
          <StatusLabel>
            <StatusName>{status.totalTitle}</StatusName>
          </StatusLabel>
        )}

        <mediaQuery.Mobile>
          {status.isMobileShow &&
            status.isMobileShow.totalValue !== undefined &&
            status.totalValue !== undefined && (
              <Text fontSize={24} color={COLORS.PROGRESSBAR} bold>
                {status.totalValue}
              </Text>
            )}
        </mediaQuery.Mobile>
        {isNotMobile && status.totalValue !== undefined && (
          <StatusValue>{status.totalValue}</StatusValue>
        )}
      </StatusLabel>

      <mediaQuery.Mobile>
        {status.isMobileShow &&
          status.isMobileShow.items &&
          status.items?.map((item: any, index: number) => (
            <StatusLabel key={index}>{item}</StatusLabel>
          ))}
      </mediaQuery.Mobile>
      {isNotMobile &&
        status.items?.map((item: any, index: number) => (
          <StatusLabel key={index}>{item}</StatusLabel>
        ))}
    </s.StatusContainer>
  );
}

export default Status;

export const StatusLabel = styled.div`
  ${FLEX.FlexStartCenter};
  align-items: baseline;
  gap: 6px;

  > img {
    height: 15px;
  }
`;

export const StatusName = styled.div`
  font-size: ${calcRem(14)};
  color: ${(props) => props.theme.COLORS.STATUS};
`;

export const StatusValue = styled.span<{ color?: string }>`
  font-size: ${calcRem(22)};
  color: ${({ color, theme }) => (color ? color : theme.COLORS.BUTTONS2)};
  font-weight: 500;
`;
