import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState: boolean = false;

/**
 * headernavbar profile 클릭 시 뜨는 popup
 */
export const userPopup = createSlice({
  name: "userPopup",
  initialState,
  reducers: {
    setIsPopup(Popup) {
      return !Popup;
    },
    setOffPopup(Popup) {
      return (Popup = false);
    },
  },
});

export const { setIsPopup, setOffPopup } = userPopup.actions;

export default userPopup;
