import Calendar, { CalendarProps } from "react-calendar";
import styled from "styled-components";
import { COLORS } from "styles/colors";
import 'react-calendar/dist/Calendar.css';

export default function CalendarComponent(props: CalendarProps): JSX.Element {
  return (
    <Wrapper>
      <Calendar
        locale={"en"}
        calendarType={"US"}
        {...props}
        
        className="mx-auto w-full text-sm border-b"
      />
    </Wrapper>
  );
}

const Wrapper = styled.div`
  max-width: 350px;
  min-width: 250px;
  border: 1px solid #cacaca;
  border-radius: 6px;
  overflow: hidden;
  background-color: ${COLORS.WHITE};
  position: relative;

  button {
    padding: 12px 0;
  }

  .react-calendar {
    position: relative;
    border: none;
    width: 100%;
    z-index: 999;
    line-height: 0.8em;
  }

  .react-calendar__tile {
    line-height: 9px;
  }

  .react-datepicker-popper {
    z-index: 100 !important;
  }
`;
