import { useState } from "react";
import DateTimePicker from "react-datetime-picker";
import styled from "styled-components";
import { COLORS } from "styles/colors";
import type CalendarType from "react-calendar";
import { Value } from "react-datetime-picker/dist/cjs/shared/types";
import "react-datetime-picker/dist/DateTimePicker.css";
import "react-clock/dist/Clock.css";
import DATE_ICON from "assets/icons/date.svg";
import { useAppSelector } from "redux/hooks";
import { ProfileAccountInfo } from "api/interfaces/accountInterface.interface";

type CalendarProps = {
  value: Date;
  onChange: (value: Value) => void;
};

export default function DateTimePickerComponent(
  props: CalendarProps
): JSX.Element {
  return (
    <Wrapper>
      <DateTimePicker
        amPmAriaLabel="Select AM/PM"
        calendarAriaLabel="Toggle calendar"
        clearAriaLabel="Clear value"
        dayAriaLabel="Day"
        hourAriaLabel="Hour"
        maxDetail="second"
        minuteAriaLabel="Minute"
        monthAriaLabel="Month"
        nativeInputAriaLabel="Date and time"
        onChange={props.onChange}
        secondAriaLabel="Second"
        value={props.value}
        yearAriaLabel="Year"
        locale="en"
        isClockOpen={false}
        disableClock={true}
        clearIcon={null}
        showLeadingZeros={true}
        // format={account.timezone}
        calendarIcon={<img src={DATE_ICON} alt="calendar icon" />}
      />
    </Wrapper>
  );
}

const Wrapper = styled.div`
  width: 100%;
  // border: 1px solid #abb5c5;
  border-radius: 4px;
  background-color: ${COLORS.WHITE};
  max-width: 100%;
  flex-basis: 20px;
  flex-direction: column;
  flex-grow: 100;
  align-items: stretch;
  font-size: 0.875rem;
  padding-left: 5px;

  .react-datetime-picker {
    width: 100%;
    border: none;
    height: 33px;
  }

  .react-datetime-picker__wrapper {
    width: 100%;
    display: flex;
    flex-grow: 1;
    flex-shrink: 0;
    border: none;
  }

  .react-datetime-picker__calendar-button > img {
    width: 20px;
  }
`;
