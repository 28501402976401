import styled from "styled-components";
import { Container } from "components/atoms/buttons/ButtonStyled";
import EXPORT_ICON from "assets/icons/export.svg";
import EXPORT_BLACK_ICON from "assets/icons/export_black.svg";
import { COLORS } from "styles/colors";
import { calcRem } from "styles/theme";
import SEND_EMAIL_ICON from "assets/icons/send.svg";
import SEND_EMAIL_BLACK_ICON from "assets/icons/send_black.svg";
import { useAppSelector } from "redux/hooks";

interface Props {
  onClick: () => void;
}

export const ExportButton = (props: Props) => {
  const themeMode = useAppSelector((state) => state.theme.theme);
  return (
    <ButtonContainer onClick={props.onClick} buttonType="cancel">
      <img
        src={themeMode === "light" ? EXPORT_BLACK_ICON : EXPORT_ICON}
        alt="export icon"
      />
      Export
    </ButtonContainer>
  );
};

export const SendEmailButton = (props: Props) => {
  const themeMode = useAppSelector((state) => state.theme.theme);
  return (
    <ButtonContainer onClick={props.onClick} buttonType="cancel">
      <img
        src={themeMode === "light" ? SEND_EMAIL_BLACK_ICON : SEND_EMAIL_ICON}
        alt="send email icon"
      />
      Send Email
    </ButtonContainer>
  );
};

const ButtonContainer = styled(Container)`
  background-color: #262e44;
  border-radius: 2px;
  font-weight: 500;
  color: ${COLORS.WHITE};
  font-size: ${calcRem(12)};

  > img {
    height: 10px;
  }
`;
