import { Container } from "../LeftAsideStyled";
import styled from "styled-components";
import { FLEX } from "styles/flex";
import { COLORS } from "styles/colors";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import { Text } from "components/atoms/text/Text";
import { calcRem } from "styles/theme";
import { useEffect, useState } from "react";

import WebAppLink from "components/blocks/link/WebAppLink";
import { ProfileAccountInfo } from "api/interfaces/accountInterface.interface";
import Type from "components/atoms/text/labels/Type";
import ModalSubMenu from "components/blocks/modal/menu/ModalSubMenu";
import RecorderDetailAlert from "./RecorderDetailAlert";
import RecorderDetailSystem from "./RecorderDetailSystem";
import RecorderDetailCamera from "./RecorderDetailCamera";
import * as AlertViewStyled from "components/templates/modal/alert/AlertViewStyled";
import { SelectedAlertView } from "api/interfaces/alertInterface.interface";
import X from "assets/icons/x_map.svg";
import { LEVEL_TYPE } from "api/interfaces/commonInterface.interface";
import { convertType } from "utils/functions";
import * as mediaQuery from "components/MediaQuery";
type DualProps = {
  selectedAlert: SelectedAlertView;
  isViewRecorderList: boolean;
  onModal: () => void;
};

const menus = [
  {
    label: "Alert",
  },
  {
    label: "System",
  },
  // {
  //   label: "Camera",
  // },
];

const RecorderDetailView = (props: DualProps): JSX.Element => {
  const selectedAccount: ProfileAccountInfo = useAppSelector(
    (state) => state.accountSettings
  );

  const [currentMenu, setCurrentMenu] = useState<number>(0);

  return (
    <Wrapper isViewRecorderList={props.isViewRecorderList}>
      <ViewContainer>
        <Header>
          <AlertViewStyled.CloseButton
            onClick={props.onModal}
            className="close-button"
          >
            <img alt="close button" src={X} />
          </AlertViewStyled.CloseButton>
          <AlertViewStyled.HeaderInner>
            <Title>
              <AlertViewStyled.HeaderSub>
                <Type type={convertType(props.selectedAlert.type)} />
                {/* <div className="title-wrapper"> */}
                <div className="system-title">
                  <Text bold className="system-name">
                    {props.selectedAlert.mergedSystemName !== undefined
                      ? props.selectedAlert.mergedSystemName
                      : props.selectedAlert.recorderName}
                  </Text>
                  {props.selectedAlert.type === "Spectrum" && (
                    <Text fontSize={14} color="#828B9B" className="server-name">
                      {props.selectedAlert.recorderName}
                    </Text>
                  )}
                </div>
                <div className="web-app-link-wrapper">
                  <WebAppLink
                    type={props.selectedAlert.type}
                    accountId={selectedAccount.accountId}
                    recorderId={props.selectedAlert.recorderId}
                    mergedSystemId={props.selectedAlert.mergedSystemId}
                    cloudSystemId={props.selectedAlert.cloudSystemId}
                  />
                </div>
                {/* </div> */}
              </AlertViewStyled.HeaderSub>
              {props.selectedAlert.location && (
                <span className="location">{props.selectedAlert.location}</span>
              )}
            </Title>
          </AlertViewStyled.HeaderInner>
        </Header>

        <ModalSubMenu
          menus={menus}
          currentMenu={currentMenu}
          onChange={(index: number) => setCurrentMenu(index)}
        />

        <Inner>
          {currentMenu === 0 && (
            <RecorderDetailAlert selectedAlert={props.selectedAlert} />
          )}
          {currentMenu === 1 && (
            <RecorderDetailSystem
              type={props.selectedAlert.type}
              recorderId={props.selectedAlert.recorderId}
            />
          )}
          {/* {currentMenu === 2 && <RecorderDetailCamera />} */}
        </Inner>
      </ViewContainer>
    </Wrapper>
  );
};

export default RecorderDetailView;

const Wrapper = styled.div<{ isViewRecorderList: boolean }>`
  height: 100%;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  position: absolute;
  top: 0;
  z-index: 667;

  ${mediaQuery.isDefault} {
    width: 480px;
    ${({ isViewRecorderList }) =>
      isViewRecorderList
        ? `
        left: 265px;

        `
        : `
        left: 10px;
        
     `}
  }

  .close-button {
    top: 10px;
    right: 10px;

    > img {
      width: 15px;
    }

    ${mediaQuery.isMobile} {
      top: 5px;

      > img {
        width: 10px;
      }
    }
  }

  .modal-submenu {
    padding-top: 0;
  }
  ${mediaQuery.isMobile} {
    width: 100%;
  }
`;

const Header = styled.header`
  width: 100%;
  border-bottom: 1px solid ${COLORS.BORDER};
  padding-top: 12px;

  ${mediaQuery.isMobile} {
    padding: 10px;
  }
`;

const ViewContainer = styled(Container)`
  border-radius: 6px;
  height: 100%;
  /* height: calc(100% - 20px); */
  overflow-y: auto;

  .modal-sub-menu-li {
    flex: 1;
    text-align: center;
    font-size: ${calcRem(14)};
  }
`;

const Title = styled.div`
  width: 100%;

  .location {
    color: ${COLORS.BUTTONS1};
    font-size: ${calcRem(13)};
    margin-top: 8px;
    display: inline-block;
  }
`;
const Inner = styled.div`
  width: 100%;
  flex: 1;
  height: 100%;
`;
