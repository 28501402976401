import * as s from "./TableItemStyled";
import Inventory from "./list/Inventory";
import AlertList from "./list/AlertList";
import { TableItemName } from "./TableItemList";
import AlertFeed from "./list/AlertFeed";
import AlertHistory from "./list/AlertHistory";
import CvvDetection from "./list/CvvDetection";
import Recorders from "./list/Recorders";
import LoginHistory from "./list/LoginHistory";
import { Layout } from "react-grid-layout";
import CloudStorage from "./list/CloudStorage";
import Revenue from "./list/Revenue";
import Installation from "./list/Installation";

interface Props {
  layout: Layout;
  chartLayout: Layout[];
  onDeleteWidget: (key: TableItemName) => void;
  onLayoutChange: (layout: Layout[]) => void;
}

export default function TableItem(props: Props): JSX.Element {
  const keyComponents: { [key in TableItemName]: JSX.Element | string } = {
    installation: <Installation {...props} />, // later
    revenue: <Revenue {...props} />, // later
    storage: <CloudStorage {...props} />,
    recorder: <Recorders {...props} />,
    alertFeed: <AlertFeed {...props} />,
    alertList: <AlertList {...props} />,
    alertHistory: <AlertHistory {...props} />,
    cvvDetection: <CvvDetection {...props} />,
    inventory: <Inventory {...props} />,
    loginHistory: <LoginHistory {...props} />,
  };

  return (
    <s.Item key={`layout-item-${props.layout.i}`}>
      {keyComponents[props.layout.i as TableItemName]}
    </s.Item>
  );
}
