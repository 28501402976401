import * as s from "styles/ModalCommonStyled";
import StepperComponent, { StepType } from "components/blocks/stepper/Stepper";
import BigButton from "components/atoms/buttons/BigButton";
import { ModalInput } from "components/atoms/input/modal/ModalInput";
import Input from "components/atoms/input/Input";
import TextArea from "components/atoms/input/TextArea";
import { UPDATE_TYPE } from "./AddHealthContainer";
import { SearchRecorder } from "components/element/SearchRecorder";
import { HealthReport } from "api/interfaces/reportInterface.interface";
import AddNotification, { NOTIFICATION_TYPE } from "../common/AddNotification";
import { ProfileAccountInfo } from "api/interfaces/accountInterface.interface";
import DeleteConfirmModal from "components/templates/modal/small/confirm/delete";
import { Text, ValidateWord } from "components/atoms/text/Text";
import { ReportDataRow } from "api/interfaces/reportInterface.interface";
import Toggle from "components/atoms/toggle/Toggle";
import {
  Validate,
  ValidateMsg,
} from "api/interfaces/commonInterface.interface";

type Props = {
  steps: Array<StepType>;
  page: number;
  selectedAccount: ProfileAccountInfo;
  onChangePage: (name: string, index?: number) => void;
  onChangeHealthReportInfo: (
    type: UPDATE_TYPE,
    value: string | number | boolean | string[] | boolean[] | number[]
  ) => void;
  onChangeNotification: (
    type: NOTIFICATION_TYPE,
    value: string | number | boolean | string[] | boolean[] | number[]
  ) => void;
  hostName: string;
  onClickCreate: () => void;
  data: HealthReport | HealthReport;
  // onClickDelete: () => void;
  isEdit: boolean | undefined;
  isConfirm: boolean;
  setIsDeleteConfirm?: (confirm: boolean) => void;
  onDelete: (value: boolean) => void;
  onConfirmDelete: () => void;
  onChangeEnable: (value: boolean) => void;
  reportRow?: ReportDataRow;
  reportRuleValidate: Validate;
  reportRuleValidateMsg: ValidateMsg;
};

export default function AddHealthPresenter(props: Props): JSX.Element {
  return (
    <>
      <StepperComponent
        activeStep={props.page}
        steps={props.steps}
        onClick={props.onChangePage}
      />
      <s.ModalInnerHalf>
        <div style={{ display: props.page === 0 ? "block" : "none" }}>
          <s.CheckInput>
            <input
              type="checkbox"
              // defaultChecked={props.data.recorder.autoApply}
              checked={props.data.recorder.autoApply}
              onChange={(e) => {
                props.onChangeHealthReportInfo(
                  UPDATE_TYPE.AUTO_APPLY,
                  e.currentTarget.checked
                );
              }}
            />{" "}
            Apply to all current and future recorders.
          </s.CheckInput>
          <s.ModalTableContainer
            style={{
              visibility: props.data.recorder.autoApply ? "hidden" : "visible",
            }}
          >
            <SearchRecorder
              isTab={true}
              selectedRow={props.data.recorder.systems}
              selectedGroupRow={props.data.recorder.recorderGroupId}
              accountId={props.selectedAccount.accountId}
              onSelectedRowsChange={(value: string[]) =>
                props.onChangeHealthReportInfo(UPDATE_TYPE.RECORDER, value)
              }
              onSelectedGroupRowsChange={(value: number[]) =>
                props.onChangeHealthReportInfo(
                  UPDATE_TYPE.RECORDER_GROUP,
                  value
                )
              }
              userId={props.isEdit ? props.data.preferences?.userId : undefined}
            />
          </s.ModalTableContainer>
          <ValidateWord>{props.reportRuleValidateMsg.isRecorder}</ValidateWord>
        </div>
        <div style={{ display: props.page === 1 ? "block" : "none" }}>
          <AddNotification
            data={props.data.notification}
            accountId={props.selectedAccount.accountId}
            onChange={props.onChangeNotification}
            isEndOfMonth={props.data.notification.isEndOfMonth}
          />
        </div>
        <div style={{ display: props.page === 2 ? "block" : "none" }}>
          <ModalInput label="Title" essential>
            <Input
              placeholder="Enter a title for this report"
              value={props.data.preferences.title}
              onChange={(e) =>
                props.onChangeHealthReportInfo(
                  UPDATE_TYPE.REPORT_NAME,
                  e.target.value
                )
              }
            />
            <ValidateWord>
              {props.reportRuleValidateMsg.isRuleName}
            </ValidateWord>
          </ModalInput>
          <ModalInput label="Host">
            <Input disabled value={props.data.preferences.host} />
          </ModalInput>
          <ModalInput label="Description">
            <TextArea
              placeholder="Enter Description"
              value={props.data.preferences.description}
              onChange={(e) =>
                props.onChangeHealthReportInfo(UPDATE_TYPE.DESC, e.target.value)
              }
            />
          </ModalInput>
          {props.isEdit && (
            <s.RowModalInput>
              <Text bold>Enable</Text>
              <Toggle
                isToggled={
                  props.isEdit
                    ? (props.data.preferences?.isEnable as boolean)
                    : true
                }
                checked={
                  props.isEdit
                    ? (props.data.preferences?.isEnable as boolean)
                    : true
                }
                onChange={(
                  checked: boolean,
                  event: React.MouseEvent<HTMLButtonElement>
                ) => {
                  // e.stopPropagation();
                  props.onChangeEnable(checked);
                }}
              />
            </s.RowModalInput>
          )}
        </div>
      </s.ModalInnerHalf>
      <s.ButtonWrapper>
        {props.isEdit && (
          <BigButton
            buttonType="warning"
            label="Delete"
            onClickBtn={() =>
              props.setIsDeleteConfirm !== undefined
                ? props.setIsDeleteConfirm(true)
                : null
            }
            disabled={false}
          />
        )}
        {props.page !== 0 && (
          <BigButton
            buttonType="cancel"
            label="Previous"
            onClickBtn={() => props.onChangePage("previous")}
          />
        )}

        {props.page === 2 ? (
          <BigButton label="Save" onClickBtn={props.onClickCreate} />
        ) : (
          <BigButton
            buttonType="cancel"
            label="Next"
            onClickBtn={() => props.onChangePage("next")}
          />
        )}
        {props.isConfirm && (
          <DeleteConfirmModal
            label="report rule"
            onDelete={() => props.onDelete(false)}
            onConfirmDelete={props.onConfirmDelete}
          />
        )}
      </s.ButtonWrapper>
    </>
  );
}
