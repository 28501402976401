import { CameraStep } from "api/interfaces/reportInterface.interface";
import { chain } from "lodash";

//BUG [RND-243] GroupBy mergedRecorderId
export const selectedRecorderCountThumbnail = (value: CameraStep[]) => {
  const groupBySelect = chain(value)
    .groupBy((camera) => camera.mergedRecorderId)
    .map((data) => {
      return {
        recorders: data[0].recorderId + "-" + data[0].mergedRecorderId,
        camera: data[0].camera !== undefined ? data[0].camera.length : 0,
      };
    })
    .filter((recorder) => recorder.camera !== 0)
    .value();

  return groupBySelect !== undefined ? groupBySelect.length : 0;
};

export const getThumbnailErrorString = (error: string) => {
  switch (error) {
    case "CameraInvalid":
      return "Camera is Invalid";
    case "CameraOffline":
      return "Camera is Offline";
    case "CameraUnauthorized":
      return "Camera is Unauthorized";
    case "CameraNotRecording":
      return "Camera not Recording";
    case "RecorderNotResponding":
      return "Recorder Not Responding";
    case "RecorderAuthFail":
      return "Recorder Auth Failure";
    default:
      return "Thumbnail Not Available";
  }
};
