import { useLocation } from "react-router-dom";
import MyProfile from "./myProfile";
import MyAccount from "./myAccount";
import EmailPage from "./email";
import NoticePage from "./siteNotices";
import License from "../license";

export default function SettingPresenter(): JSX.Element {
  const location = useLocation();
  /**
   * @todo 라우팅 수정해야 함
   * 전체 path기준으로 라우팅하면 위함 함.
   * 실제로 정확한 location 기준으로 이동해야하고, 없는 경운 not found page 이동
   */
  if (location.pathname.includes("emails")) {
    return <EmailPage />;
  }
  if (location.pathname.includes("notices")) {
    return <NoticePage />;
  }
  if (location.pathname.includes("adminAccount")) {
    return <MyAccount />;
  }

  if (location.pathname.includes("license")) {
    return <License />;
  }

  return <MyProfile />;
}
