import styled from "styled-components";
import { COLORS } from "styles/colors";
import { FLEX } from "styles/flex";
import { calcRem } from "styles/theme";
import * as mediaQuery from "components/MediaQuery";

export const Container = styled.div`
  ${FLEX.FlexCenterCenter};
  /* padding: 50px 0; */
  height: 100%;
`;
export const Inner = styled.div`
  width: 60vw;

  ${mediaQuery.isMobile} {
    width: 90%;
  }
`;

export const Title = styled.h1`
  color: ${COLORS.WHITE};
  font-weight: 500;
  font-size: ${calcRem(24)};
`;

export const FormWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 30px;

  ${mediaQuery.isMobile} {
    grid-template-columns: repeat(1, 1fr);
  }
`;

export const SubTitle = styled.div`
  padding: 20px 0;
`;

export const FormItem = styled.div`
  padding-bottom: 20px;
`;

export const ButtonWrapper = styled.div`
  width: 100%;
  ${FLEX.FlexCenterCenter};
  padding: 20px 0;
`;

export const UploadWrapper = styled.div`
  width: 100%;
  padding: 20px 0;
  display: inline-flex;
`;
