import { AddEmail, Email } from "api/interfaces/emailInterface.interface";
import { useCallback, useState } from "react";
import { AddModalPresenter, UPDATE_TYPE } from "./AddModalPresenter";
import { SearchUserAllList } from "api/interfaces/userInterface.interface";
import { useQuery } from "react-query";
import { ProfileAccountInfo } from "api/interfaces/accountInterface.interface";
import { useAppSelector } from "redux/hooks";
import { getUserAllList } from "api/userAPI";
import { isEmpty } from "lodash";
import { Validate, ValidateMsg } from "api/interfaces/commonInterface.interface";
import { useIntl } from "react-intl";

interface Props {
  isEdit?: boolean;
  selectedEmail?: Email;
  onModal: () => void;
  onClickSendEmail: (email: AddEmail, emailId?: string) => void;
}

export function AddModalContainer(props: Props): JSX.Element {
  const selectedAccount: ProfileAccountInfo = useAppSelector(
    (state) => state.accountSettings
  );
  const intl = useIntl();
  const { isEdit, onModal, onClickSendEmail, selectedEmail } = props;

  const [state, setState] = useState<AddEmail>(
    isEdit && selectedEmail !== undefined
      ? {
          subject: selectedEmail.subject,
          message: selectedEmail.message,
          recipientType: selectedEmail.recipientType,
          isSchedule: false,
          scheduleTime: selectedEmail.scheduleTime,
          users: selectedEmail.users,
        }
      : {
          subject: "",
          message: "",
          recipientType: "ALL",
          isSchedule: false,
          scheduleTime: new Date().toISOString(),
          users: [],
        }
  );

  const [users, setUsers] = useState<SearchUserAllList[]>([]);

  const [validate, setValidate] = useState<ValidateMsg>({
    user: "",
    title : ""
  });

  const getUserAll = useQuery(
    ["getAllUser"],
    () =>
      getUserAllList({
        accountId: selectedAccount.accountId,
      }),
    {
      retry: 3,
      onSuccess: (res: any) => {
        setUsers(res.result);
      },
      onError: (e: any) => {},
    }
  );

  const onChange = useCallback((type: UPDATE_TYPE, value: any) => {
    if (type === UPDATE_TYPE.SUBJECT) {
      setState((email) => {
        return {
          ...email,
          subject: value,
        };
      });
    } else if (type === UPDATE_TYPE.MESSAGE) {
      setState((email) => {
        return {
          ...email,
          message: value,
        };
      });
    } else if (type === UPDATE_TYPE.EMAIL_TYPE) {
      setState((email) => {
        return {
          ...email,
          recipientType: value,
        };
      });
    } else if (type === UPDATE_TYPE.SCHEDULE_TIME) {
      setState((email) => {
        return {
          ...email,
          scheduleTime: value,
        };
      });
    } else if (type === UPDATE_TYPE.USERS) {
      setState((email) => {
        return {
          ...email,
          users: value.map((val: any) => {
            return {
              name: val.name,
              email: val.value,
            };
          }),
        };
      });
    }
  }, []);

  const onChangeMessage = useCallback((value: string) => {
    onChange(UPDATE_TYPE.MESSAGE, value);
  }, []);

  const onChangeTime = useCallback((value: Date) => {
    onChange(UPDATE_TYPE.SCHEDULE_TIME, value.toISOString());
  }, []);

  const onClickSend = useCallback((isSchedule?: boolean) => {

    let checkError = false;

    setValidate((info) => {
      return { ...info, user: "" , title:""};
    });

    if (state.recipientType === "USER" && state.users?.length === 0) {
      setValidate((info) => {
        return { ...info, user: intl.formatMessage({
          id: "validateMsg.setting.email.user.empty",
          defaultMessage: "Select one or more users or enter email address.",
        }) };
      }); 
      checkError = true;     
    }

    if(isEmpty(state.subject)){
      setValidate((info) => {
        return { ...info, title: intl.formatMessage({
          id: "validateMsg.setting.email.title.empty",
          defaultMessage: "Please enter a email title.",
        }) };
      });
      checkError = true;     
    }

    if(checkError)
      return;


    if (isEdit && selectedEmail) {
      onClickSendEmail(
        {
          ...state,
          isSchedule: isSchedule ? isSchedule : false,
        },
        selectedEmail.emailId
      );
    } else {
      onClickSendEmail({
        ...state,
        isSchedule: isSchedule ? isSchedule : false,
      });
    }
  },[isEdit, onClickSendEmail, selectedEmail, state]);

  return (
    <AddModalPresenter
      state={state}
      isEdit={props.isEdit}
      onModal={onModal}
      onClickSend={onClickSend}
      onChange={onChange}
      onChangeMessage={onChangeMessage}
      onChangeTime={onChangeTime}
      users={users}
      validate={validate}
    />
  );
}
