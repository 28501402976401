import Select, { DropdownIndicatorProps, StylesConfig } from "react-select";
import { COLORS, LIGHT_THEME_COLORS } from "styles/colors";
import { Option } from "../util";
import TRIANGLE_DOWN from "assets/icons/triangle_down.svg";
import styled from "styled-components";
import { useAppSelector } from "redux/hooks";
import { FaCaretDown } from "react-icons/fa6";
type OptionsType = Array<Option>;

type CommonProps = {
  // clearValue: () => void,
  // getStyles: (string, any) => {},
  // getValue: () => ValueType,
  // hasValue: boolean,
  isMulti?: boolean;
  options: OptionsType;
  // selectOption: OptionType => void,
  // selectProps: any,
  // setValue: (ValueType, ActionTypes) => void,
  // emotion: any,
  defaultValue?: Option;
  value?: any;
  isDisabled?: boolean;
  onChange: (value: Option, actionMeta: any) => void;
  isAlertView?: boolean;
  classNamePrefix?: string;
};
const customLightStyles: StylesConfig<Option> = {
  control: (styles, { isDisabled, theme }) => {
    const backgroundColor = isDisabled
      ? LIGHT_THEME_COLORS.BORDER
      : LIGHT_THEME_COLORS.PRIMARY2;
    const borderColor = LIGHT_THEME_COLORS.BORDER;
    // const padding = "6px 8px 6px 0";
    const paddingRight = "6px";
    const minWidth = "80px";
    const maxWidth = "100%";
    const minHeight = "26px";
    const fontSize = "12px";
    const color = COLORS.BLACK;

    return {
      ...styles,
      backgroundColor,
      borderColor,
      paddingRight,
      minWidth,
      maxWidth,
      minHeight,
      fontSize,
      color,
    };
  },
  menuPortal: (provided) => ({
    ...provided,
    zIndex: 9999,

    fontSize: 12,
  }),
  container: (styles) => ({
    ...styles,
    width: "100%",
    color: COLORS.BLACK,
    fontSize: 12,
  }),
  placeholder: (styles) => ({ ...styles, color: "#abb5c5" }),
  // dropdownIndicator: (styles) => ({ ...styles, color: COLORS.BUTTONS1 }),
  indicatorSeparator: () => ({}),
  singleValue: (styles) => ({ ...styles, color: COLORS.BLACK }),
};
const customStyles: StylesConfig<Option> = {
  control: (styles, { isDisabled, theme }) => {
    // console.log(theme, "theme");
    const backgroundColor = isDisabled ? COLORS.BUTTONS1 : COLORS.PRIMARY2;
    const borderColor = "#828c9e";
    // const padding = "6px 8px 6px 0";
    const paddingRight = "6px";
    const minWidth = "80px";
    const maxWidth = "100%";
    const minHeight = "26px";
    const fontSize = "12px";
    const color = COLORS.WHITE;

    return {
      ...styles,
      backgroundColor,
      borderColor,
      paddingRight,
      minWidth,
      maxWidth,
      minHeight,
      fontSize,
      color,
    };
  },
  menuPortal: (provided) => ({
    ...provided,
    zIndex: 9999,

    fontSize: 12,
  }),
  container: (styles) => ({
    ...styles,
    width: "100%",
    color: COLORS.WHITE,
    fontSize: 12,
  }),
  placeholder: (styles) => ({ ...styles, color: "#abb5c5" }),
  // dropdownIndicator: (styles) => ({ ...styles, color: COLORS.BUTTONS1 }),
  indicatorSeparator: () => ({}),
  singleValue: (styles) => ({ ...styles, color: COLORS.WHITE }),
};
const DropdownIndicator = () => {
  return <FaCaretDown />;
};

const AlertTypeSelect = (props: CommonProps) => {
  const theme = useAppSelector((state) => state.theme.theme);

  return (
    <Select
      {...props}
      // classNamePrefix="alert-type-select-box"
      styles={theme === "light" ? customLightStyles : customStyles}
      components={{ DropdownIndicator }}
      placeholder="Select"
      menuPortalTarget={document.querySelector("body")}
      onChange={props.onChange}
      hideSelectedOptions={
        props.options !== undefined && props.options.length === 1
      }
    />
  );
};

export default AlertTypeSelect;

const DropDown = styled.img`
  width: 10px;
`;
