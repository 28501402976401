import { ModalInput } from "components/atoms/input/modal/ModalInput";
import Input from "components/atoms/input/Input";
import TextArea from "components/atoms/input/TextArea";
import SmallPopupTemplate from "../SmallPopupTemplate";
import { useMutation } from "react-query";
import { postAlertSendMessage } from "api/alertAPI";
import { notify } from "components/atoms/notification/Notification";
import { useCallback, useRef } from "react";
import { ProfileAccountInfo } from "api/interfaces/accountInterface.interface";
import { useAppSelector } from "redux/hooks";
import { isEmpty } from "lodash";
import { useIntl } from "react-intl";

type Props = {
  onModal: () => void;
  value: string;
  alertRuleId: string;
  recorderId: string;
};

const SendPhonePopup = ({ onModal, value, alertRuleId, recorderId }: Props) => {
  const intl = useIntl();
  const selectedAccount: ProfileAccountInfo = useAppSelector(
    (state) => state.accountSettings
  );
  const smsTextRef = useRef<HTMLTextAreaElement>(null);
  const mutationSendAlertMessage = useMutation(postAlertSendMessage, {
    onSuccess: () => {
      notify("success", intl.formatMessage({
        id: "label.common.notify.message.success",
        defaultMessage: "Message sent successfully",
      }));
      onModal();
    },
    onError: () => {
      notify("error", intl.formatMessage({
        id: "label.common.notify.message.fail",
        defaultMessage: "Message sending failed.",
      }));
    },
  });

  const onClickSendSms = useCallback(() => {
    if (isEmpty(value)) {
      return;
    }
    if (isEmpty(smsTextRef.current?.value)) {
      return;
    }
    mutationSendAlertMessage.mutate({
      accountId: selectedAccount.accountId,
      alertRuleId: alertRuleId,
      recorderId: recorderId,
      payload: {
        phoneNumber: value as string,
        message: smsTextRef.current?.value as string,
      },
    });
  }, [
    value,
    smsTextRef.current?.value,
    mutationSendAlertMessage,
    recorderId,
    alertRuleId,
    selectedAccount.accountId,
  ]);
  return (
    <SmallPopupTemplate
      cancel
      title="Send a Text Message"
      subTitle="Send a message to your technician"
      onClick={onClickSendSms}
      onModal={onModal}
      confirmLabel="Send"
    >
      <ModalInput label="Phone Number" essential>
        <Input
          placeholder="Enter a phone number"
          disabled={true}
          value={value}
        />
      </ModalInput>
      <ModalInput label="Message" essential>
        <TextArea placeholder="Enter your message here" ref={smsTextRef} />
      </ModalInput>
    </SmallPopupTemplate>
  );
};

export default SendPhonePopup;
