import styled from "styled-components";
import { FLEX } from "styles/flex";
import * as mediaQuery from "components/MediaQuery";
import { COLORS } from "styles/colors";
import { calcRem } from "styles/theme";

export const Container = styled.section`
  width: 100%;
  padding: 20px 0;
  /* border: 1px solid red; */

  .react-grid-item > .react-resizable-handle::after {
    display: none;
  }

  ${mediaQuery.isDefault} {
    /* height: calc(100vh - 60px); */
    /* padding-bottom: 50px; */
    margin: 0 auto;
  }
  ${mediaQuery.isMobile} {
    padding: 30px 10px;
  }

  // drag 시 뒤에 깔리는 배경색
  .react-grid-item.react-grid-placeholder {
    background: ${COLORS.PROGRESSBAR};
    opacity: 0.2;
    transition-duration: 100ms;
    z-index: 2;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    -o-user-select: none;
    user-select: none;
  }

  // resize handle 색상
  .react-grid-item > .react-resizable-handle::after {
    border-color: ${COLORS.WHITE}50;
  }

  ${mediaQuery.isMobile} {
    background-color: ${({ theme }) => theme.value === "light" && "#F1F1F1"};
    min-height: calc(100vh - 50px);
  }
`;

export const SummaryGrid = styled.div`
  margin-bottom: 20px;
  max-height: 200px;
  height: 200px;

  .react-grid-layout {
    max-height: 200px;
  }
`;

export const GridItem = styled.div`
  border-radius: 6px;
  background-color: ${({ theme }) => theme.COLORS.WIDGETCOLOR};

  width: 100%;
  height: 100%;
  position: relative;
  ${FLEX.FlexStartCenter};
  flex-direction: column;
  flex: 1;

  .pagination {
    /* position: absolute;
    bottom: 0;
    z-index: 333; */
    width: 100%;
    background-color: #0c1724;
    padding: 5px 0;
  }

  ${mediaQuery.isDefault} {
    overflow: hidden;
    .content {
      width: 100%;
      overflow-y: auto;
      height: 100%;
    }
  }
  ${mediaQuery.isMobile} {
    text-align: center;
    .content {
      overflow-y: auto;
      max-height: 500px;
    }
  }
`;

export const SummaryGridItem = styled.div`
  ${FLEX.FlexStartCenter};
  flex-direction: column;
  flex: 1;
  background-color: transparent;
  ${({ theme }) =>
    theme.value === "light" &&
    `
    
  box-shadow: 0px 3px 10px ${COLORS.BLACK}16;
    `}
`;

export const WidgetMobileItem = styled.div`
  padding-bottom: 10px;
  z-index: 10;
`;

export const AddButton = styled.button`
  position: fixed;
  bottom: 30px;
  right: 30px;
  color: ${({ theme }) => theme.COLORS.WHITE};
  ${FLEX.FlexCenterCenter};
  gap: 6px;
  border: 1px solid ${({ theme }) => theme.COLORS.SELECTED};
  border-radius: 50px;
  padding: 12px 15px;
  font-size: ${calcRem(14)};
  box-shadow: 0px 3px 10px ${COLORS.BLACK}16;
  background-color: ${({ theme }) => theme.COLORS.PRIMARY2};

  > strong {
    color: ${({ theme }) => theme.COLORS.SELECTED};
    font-size: ${calcRem(26)};
    font-weight: bold;
  }
`;

export const ChartGrid = styled.div`
  width: 100%;
`;

export const AddWrapper = styled.div`
  width: 100%;
  padding: 10px 0;
  ${FLEX.FlexEndCenter};
`;
export const AddButon = styled.div`
  color: ${({ theme }) =>
    theme.value === "light" ? theme.COLORS.SELECTED : COLORS.BUTTONS2};
  ${FLEX.FlexCenterCenter};
  gap: 2px;
  font-weight: 400;
  cursor: pointer;
`;
