import styled from "styled-components";
import { COLORS } from "styles/colors";
import { FLEX } from "styles/flex";
import { calcRem } from "styles/theme";

export const Row = styled.div<{ isAdmin?: boolean }>`
  ${FLEX.FlexStartCenter};
  gap: 6px;
  ${({ isAdmin, theme }) =>
    isAdmin &&
    `
    color: ${theme.COLORS.SELECTED}
  `}
`;

interface Props {
  isDeleted?: boolean;
}

export const Text = styled.div<Props>`
  color: ${(props) =>
    props.isDeleted ? props.theme.COLORS.BUTTONS1 : props.theme.COLORS.WHITE};
`;
// export const Icon = styled.div`
//   width: 20px;
//   img {
//     width: 100%;
//   }
// `;

export const EditUserCompany = styled.div`
  padding: 10px 0;

  .common-modal-input {
    padding: 6px 0;
  }
  .modal-input-label-title {
    font-size: ${calcRem(14)};
    margin-bottom: -5px;
  }
`;

export const ReceiveTitle = styled.div`
  ${FLEX.FlexStartCenter};
  gap: 4px;
  color: ${COLORS.WHITE};
  font-weight: 500;

  button {
    position: relative;
    color: ${({ theme }) => theme.COLORS.WHITE};
    ${FLEX.FlexCenterCenter};

    .bubble-container {
      left: -8px;
      transform: unset;
    }
  }
`;
