import styled from "styled-components";
import { COLORS } from "styles/colors";
import { FLEX } from "styles/flex";
import * as mediaQuery from "components/MediaQuery";

export const Row = styled.div`
  ${FLEX.FlexStartCenter};
  gap: 7px;
`;
export const StorageText = styled.span<{ full?: boolean }>`
  color: ${({ full }) => (full ? COLORS.PROGRESSBAR : "#828B9B")};
`;

export const CameraRow = styled.ul`
  ${FLEX.FlexBetweenCenter};
  flex: 1;
`;

export const CameraItem = styled.li`
  ${FLEX.FlexStartCenter};
  gap: 6px;

  span {
    white-space: nowrap;
    color: #828b9b;
  }
  .loss {
    color: ${COLORS.PROGRESSBAR};
  }
  .title {
    color: ${({ theme }) => theme.COLORS.WHITE};
  }
`;

// Health Detail ----
export const HealthDetailGrid = styled.div`
  width: 100%;
  height: auto;

  ${mediaQuery.isDefault} {
    display: grid;
    padding-bottom: 50px;

    grid-template-columns: 350px 1fr;
    grid-template-rows: auto;
    grid-template-areas:
      "header  header  header"
      "menu  content  content";
    grid-gap: 15px;

    .health-menus {
      margin-right: -15px;
    }

    > section {
      width: 100%;
      height: 70vh;
      overflow-y: auto;
      margin-left: 15px;
    }
  }

  ${mediaQuery.isMobile} {
    ${FLEX.FlexStartStart};
    flex-direction: column;
    gap: 12px;
  }
`;

export const HealthMenuStorage = styled.div`
  ${FLEX.FlexStartCenter};
  gap: 10px;
`;

export const HealthMenuStorageValue = styled.div`
  color: ${COLORS.BUTTONS1};
  margin-left: -6px;
`;

export const StatusWrapper = styled.div`
  ${FLEX.FlexStartEnd};
  gap: 15px;
`;

export const StatusButton = styled.button`
  position: relative;
`;

export const StatusBubble = styled.div`
  position: absolute;
  top: 35px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 99;

  > p {
    :after {
      border-width: 0 8px 20px;
      top: -10px;
    }
  }
`;
