import Payment from "./payment";
import Account from "./account";
import License from "../license";
import { useLocation } from "react-router-dom";

export default function Billing(): JSX.Element {
  const location = useLocation();

  if (location.pathname.includes("payment")) return <Payment />;
  if (location.pathname.includes("license")) return <License />;
  else return <Account />;
}
