import styled from "styled-components";
import SearchInput, { SearchInputProps } from "./SearchInput";
import { calcRem } from "styles/theme";
import { FLEX } from "styles/flex";
import { changeTypeToIcon } from "utils/DashboardUtil";
import { COLORS } from "styles/colors";
import { KeywordSearch } from "api/interfaces/dashboardInterface.interface";
import { useCallback, useEffect, useRef, useState } from "react";

interface MapSearchInputProps extends SearchInputProps {
  keyword: string | undefined;
  onChange: (e: any) => void;
  onClickKeyword: (content: KeywordSearch) => void;
  searchKeywordList: KeywordSearch[];
}

const MapSearchInput = (props: MapSearchInputProps): JSX.Element => {
  const inputRef = useRef<any>(null);

  const [isFocus, setIsFocus] = useState<boolean>(false);

  const highlightWords = useCallback(
    (string: string) => {
      let coloredCode = string ? string : "";

      if (props.keyword !== undefined && coloredCode !== undefined) {
        coloredCode = coloredCode.replaceAll(
          props.keyword,
          `<span class="highlight">${props.keyword}</span>`
        );
      }
      return coloredCode;
    },
    [props.keyword]
  );
  useEffect(() => {
    const onClick = (event: any) => {
      if (inputRef.current && !inputRef.current.contains(event.target)) {
        setIsFocus(false);
      }
    };

    document.body.addEventListener("click", onClick);

    return function cleanup() {
      window.removeEventListener("click", onClick);
    };
  }, [inputRef]);

  return (
    <Wrapper ref={inputRef}>
      <SearchInput
        map
        {...props}
        onChange={(e) => {
          props.onChange(e);
          setIsFocus(true);
        }}
        onFocus={() => setIsFocus(true)}
        onClickSearch={(value: string) => {
          props.onClickSearch && props.onClickSearch(value);
          setIsFocus(false);
        }}
        // onBlur={() => setIsFocus(false)}
      />
      {isFocus && props.searchKeywordList.length !== 0 && (
        <SearchDropdown>
          {props.searchKeywordList.map((data: KeywordSearch, idx: number) => {
            // console.log(data);
            return (
              <li
                key={`keyword-list-${idx}`}
                onClick={() => {
                  props.onClickKeyword(data);
                  setIsFocus(false);
                }}
              >
                <div className="keyword-list-icon">
                  {changeTypeToIcon(data.type)}
                  {/* <img
                  src={changeTypeToIcon(data.type)}
                  alt={`keyword-icon-${idx}`}
                /> */}
                </div>
                <div
                  className="searched-string"
                  dangerouslySetInnerHTML={{
                    __html: highlightWords(data.contents),
                  }}
                ></div>
              </li>
            );
          })}
        </SearchDropdown>
      )}
    </Wrapper>
  );
};

export default MapSearchInput;

const Wrapper = styled.div`
  position: relative;
`;

const SearchDropdown = styled.ul`
  width: 100%;
  max-height: 420px;
  overflow-y: auto;
  padding: 8px;
  background-color: ${({ theme }) => theme.COLORS.FILTERITEM};
  position: absolute;

  border: 1px solid #abb5c5;
  border-top: 0px;
  border-bottom-left-radius: 9px;
  border-bottom-right-radius: 9px;

  li {
    font-size: ${calcRem(13)};
    color: #828b9b;
    ${FLEX.FlexStartCenter};
    /* gap: 4px; */
    padding: 10px 0;
    cursor: pointer;

    :hover {
      background-color: ${({ theme }) =>
        theme.value === "light"
          ? theme.COLORS.LIGHTGRAYBORDER
          : COLORS.PRIMARY2};
    }

    .keyword-list-icon {
      flex: 1;
      ${FLEX.FlexCenterCenter};
      width: 16px;
      height: 16px;

      svg {
        height: 100%;
        color: ${({ theme }) => theme.COLORS.WHITE};
      }
    }

    .searched-string {
      flex: 4;
      white-space: nowrap;
      width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;

      .highlight {
        color: ${COLORS.LIGHTGRAY};
        font-weight: 500;
      }
    }
  }
`;
