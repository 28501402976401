import { HTTP_RESULT } from "./authInterface.interface";
import { SORT_DIRECTION } from "./commonInterface.interface";
import { RecorderPermission, UserPermission } from "./userInterface.interface";

export enum SORT_TYPE {
  ASC = "ASC",
  DESC = "DESC",
}

export interface GetAccountListQuery {
  pageNum: number;
  pageLimit: number;
  keyword: string;
  sortType: string;
  sortDirection: string;
}

export enum LEVEL_TYPE {
  DW = "DW",
  EU = "EU",
  CP = "CP",
  INTL = "",
}
export interface linkAccountInfo {
  accountId: string;
  accountName: string;
}

export interface AccountInfo {
  accountId: string;
  parentId?: string;
  parentLevel: string;
  accountAdditionalNumber: string;
  name: string;
  level: LEVEL_TYPE;
  accountNumber: string;
  timezone: string; // @todo: enum
  dateFormat: string; // @todo: enum
  timeFormat: string; // @todo: enum
  use2faAuth: boolean;
  hasAccounts: number;
  hasRecorders: number;
  hasUsers: number;
  companyName: string;
  companyLocation: string;
  companyLogo: string;
  description: string;
  parentAccount: linkAccountInfo[];
  isFavorite: boolean;
}

/**
 * @todo 응답 형태 정해지면, 유틸리티 타입사용하여 정리 할 것.
 */
export interface GetAccountListResponse {
  error: number;
  errorString: string;
  result: AccountInfo[];
  page: {
    total: number;
    pageNum: number;
    pageLimit: number;
    keyword: string;
    sortType: SORT_TYPE;
    sortDirection: SORT_DIRECTION;
  };
}

export interface CUDAccountResponse {
  error: number;
  errorString: string;
  result: AccountInfo;
}

export interface UpdateAccountRequest {
  accountId?: string;
  parentId: string;
  name: string;
  level: LEVEL_TYPE;
  parentLevel: string;
  accountNumber: string;
  timezone: string;
  dateFormat: string;
  timeFormat: string;
  accountAdditionalNumber: string;
  pricing: string;
  use2faAuth: boolean;
  inviteEmail?: string;
  inviteName?: string;
  description: string;
  // invitePhone ?: string;
}

export type CreateAccountRequest = UpdateAccountRequest;

export interface getAccoountNumberRequest {
  parentId: string;
  parentLevel: string;
  parentAccountNumber: string;
  number: string;
}

export interface getAccoountNumberResponse {
  error: number;
  errorString: string;
  result: {
    parentId: string;
    parentLevel: string;
    parentAccountNumber: string;
    number: string;
  };
}

export interface ProfileAccountInfo {
  accountId: string;
  accountName: string;
  accountLevel: LEVEL_TYPE;
  accountNumber: string;
  accountAdditionalNumber: string;
  parentAccountId: string;
  parentLevel: string;
  userPermission: UserPermission;
  timezone: string;
  dateFormat: string;
  timeFormat: string;
  companyName: string;
  companyLocation: string;
  companyLogo: string;
  isAdmin: boolean;
  isManager: boolean;
  use2faAuth: boolean;
  description: string;
  isTrial?: boolean;
  licenseType?: string;
  checkedUserAdmin?: boolean;
  channelIsPreparing?: boolean;
  storageIsPreparing?: boolean;
  isHidden: boolean;
  isSuspended: boolean;
  trialDays?: number;
  isFavorite: boolean;
}

export interface ProfileAccountInfoFromServer {
  accountId: string;
  accountName: string;
  accountLevel: LEVEL_TYPE;
  accountNumber: string;
  accountAdditionalNumber: string;
  parentAccountId: string;
  parentLevel: string;
  timezone: string;
  dateFormat: string;
  timeFormat: string;
  companyName: string;
  companyLocation: string;
  companyLogo: string;
  use2faAuth: boolean;
  userPermission: UserPermission[];
  recorderPermission: RecorderPermission[];
  description: string;
  isTrial?: boolean;
  isHidden: boolean;
  isSuspended: boolean;
  licenseType?: string;
  trialDays?: number;
  parentAccount?: linkAccountInfo[];
  totalAccount?: number;
  isFavorite: boolean;
}
