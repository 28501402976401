import styled from "styled-components";
import BackgroundImage from "assets/images/backgrounds/login.jpg";
import { FLEX } from "styles/flex";
import { COLORS } from "styles/colors";
import { calcRem } from "styles/theme";
import * as mediaQuery from "components/MediaQuery";
import { commonLoginHeight } from "../SignCommonStyled";

export const Container = styled.div`
  width: 100%;
  height: 100%;
  background: url(${BackgroundImage}) no-repeat fixed center center;
  background-size: cover;
`;

export const LoginIdPWWrapper = styled.div`
  min-height: 380px;
`;

export const InputWrapper = styled.div`
  ${FLEX.FlexStartCenter};
  flex-direction: column;
  gap: 10px;
  margin-bottom: 30px;

  > input {
    padding: 8px 6px;
    min-height: ${commonLoginHeight};
    font-size: ${calcRem(14)};
    margin-bottom: -10px;
    line-height: ${calcRem(20)};
  }
`;

export const CheckboxInput = styled.label`
  padding: 5px 0;
`;

export const SpectrumWrapper = styled.div`
  ${FLEX.FlexStartStart};
  flex-direction: column;
  gap: 10px;
  width: 100%;
  flex: 1;
  margin-top: 20px;
  color: ${COLORS.WHITE};

  .cloud-login-inner {
    text-align: center;
    width: 100%;
    font-size: ${calcRem(15)};
    line-height: ${calcRem(22)};
    font-weight: 500;
  }

  .row {
    width: 100%;
    ${FLEX.FlexBetweenCenter};
    flex: 1;
    gap: 10px;

    > div:first-child {
      flex: 2;
      .label {
        min-width: 70px;
      }
    }

    > div:nth-child(2) {
      flex: 1;
    }
  }
`;

export const SpectrumInputWrapper = styled.div`
  ${FLEX.FlexStartCenter};
  gap: 10px;
  color: ${({ theme }) => theme.COLORS.WHITE};
  width: 100%;

  .label {
    flex: 1;
    font-size: ${calcRem(13)};
    font-weight: 500;

    max-width: 70px;
  }
  .value {
    flex: 4;
    ${FLEX.FlexStartStart};
    gap: 6px;
  }

  input {
    padding: 6px;
  }
  button {
    padding: 5px 7px;
    font-size: ${calcRem(13)};
  }
`;

export const TitleWrapper = styled.div`
  ${FLEX.FlexStartCenter};
  gap: 6px;
  padding-bottom: 4px;
`;

export const Bubble = styled.div`
  position: relative;

  button {
    padding: 0;
    ${FLEX.FlexCenterCenter};
    color: ${COLORS.WHITE};

    > img {
      width: 14px;
    }
  }
`;
export const BubbleWrapper = styled.div`
  position: absolute;
  z-index: 600;
  top: 35px;
  left: 50%;
  transform: translateX(-50%);
`;

export const SpeectrumLogo = styled.div`
  width: 100%;
  ${FLEX.FlexCenterCenter};
  margin-top: -10px;

  > img {
    width: 70%;
  }
`;

export const Version = styled.div`
  color: ${({ theme }) => theme.COLORS.LIGHTGRAY};
  font-size: ${calcRem(13)};
  padding-top: 10px;
`;
