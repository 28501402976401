import { useState, useCallback, useEffect } from "react";
import { useMutation, useQuery } from "react-query";
import * as s from "styles/ModalCommonStyled";
import BigPopupTemplate from "components/templates/modal/big/BigPopupTemplate";
import { ModalInput } from "components/atoms/input/modal/ModalInput";
import Input from "components/atoms/input/Input";
import BigButton from "components/atoms/buttons/BigButton";

import { Validate } from "api/interfaces/commonInterface.interface";
import { videoGuideOptions, type Option } from "utils/options";
import { useAppSelector } from "redux/hooks";
import { ProfileAccountInfo } from "api/interfaces/accountInterface.interface";
import { notify } from "components/atoms/notification/Notification";
import { MultiValue } from "react-select";
import { isEmpty } from "lodash";
import { checkWhiteSpaceRegEx, validateYoutubeUrl } from "utils/regEx";
import { ValidateMsg } from "api/interfaces/commonInterface.interface";
import { ValidateWord } from "components/atoms/text/Text";
import { useIntl } from "react-intl";
import Select from "components/atoms/select/Select";
import { SelectGroup, SelectedCategory } from "../../VideoManagerContainer";
import {
  GetVideoGuideGroupListResponse,
  VideoGuideGroup,
  VideoGuideSetting,
  VideoGuideVideoDto,
} from "api/interfaces/managerInterface.interface";
import { deleteGuideVideo, getVideoGuideCategory } from "api/managerAPI";
import DeleteConfirmModal from "components/templates/modal/small/confirm/delete";
import styled from "styled-components";
import { COLORS } from "styles/colors";
import { MdBrokenImage } from "react-icons/md";
import { FLEX } from "styles/flex";

interface Props {
  isEdit?: boolean; // 편집 모드일 때
  selectedGroup: SelectGroup;
  selectedCategory: SelectedCategory;
  onModal: (type?: string) => void;
  onClickSave: (videoDto: VideoGuideVideoDto, update?: boolean) => void;
  selectedVideo: VideoGuideSetting | undefined;
  onClickDeactive?: (userId: string) => void;
}

export enum UPDATE_TYPE {
  CATEGORY,
  GROUP,
  TITLE,
  VIDEO_URL,
  ORDER,
  DESC,
}

function AddVideo(props: Props): JSX.Element {
  const intl = useIntl();

  const selectedAccount: ProfileAccountInfo = useAppSelector(
    (state) => state.accountSettings
  );
  const [isDeleteConfirm, setIsDeleteConfirm] = useState(false);
  const [isActivate, setIsActivate] = useState<boolean>(true);

  const [validate, setValidate] = useState<Validate>({
    title: false,
    videoUrl: false,
  });
  const [validateMsg, setValidateMsg] = useState<ValidateMsg>({
    title: "",
    videoUrl: "",
  });

  const [selectCategory, setSelectCategory] = useState<string>("");
  const [videoInfo, setVideoInfo] = useState<VideoGuideVideoDto>({
    groupId: props.selectedGroup.groupId,
    title: "",
    videoUrl: "",
    thumbnailUrl: "",
    description: "",
    position: 1,
  });

  useEffect(() => {
    if (props.selectedCategory) {
      setSelectCategory(props.selectedCategory.categoryId);
    }
    if (!isEmpty(props.selectedGroup.groupId)) {
      setVideoInfo((info) => {
        return { ...info, groupId: props.selectedGroup.groupId as string };
      });
    }
  }, [props.selectedGroup, props.selectedCategory]);

  useEffect(() => {
    if (props.isEdit && props.selectedVideo !== undefined) {
      setVideoInfo({
        id: props.selectedVideo.id,
        groupId: props.selectedGroup.groupId,
        title: props.selectedVideo.title,
        videoUrl: props.selectedVideo.videoUrl,
        thumbnailUrl: props.selectedVideo.thumbnailUrl,
        description: props.selectedVideo.description,
        position: props.selectedVideo.position,
      });
    }
  }, [props.isEdit, props.selectedGroup.groupId, props.selectedVideo]);

  useEffect(() => {
    if (props.isEdit && props.selectedVideo !== undefined) {
    }
  }, [props.isEdit, props.selectedVideo]);

  const onChangeUserInfo = useCallback(
    (
      type: UPDATE_TYPE,
      value: string | number | string[] | MultiValue<Option>
    ) => {
      if (type === UPDATE_TYPE.TITLE) {
        setVideoInfo((info) => {
          return { ...info, title: value as string };
        });
      } else if (type === UPDATE_TYPE.VIDEO_URL) {
        // level 설정 client/server 상위 선택못하게 막아야 함
        setVideoInfo((info) => {
          return { ...info, videoUrl: value as string };
        });
      } else if (type === UPDATE_TYPE.ORDER) {
        setVideoInfo((info) => {
          return { ...info, position: value as number };
        });
      } else if (type === UPDATE_TYPE.DESC) {
        setVideoInfo((info) => {
          return { ...info, description: value as string };
        });
      }
    },
    []
  );

  const [groups, setGroups] = useState<Option[]>([]);
  const videoQuery = useQuery(
    ["VideoList", selectCategory],
    () =>
      getVideoGuideCategory({
        category: selectCategory,
      }),
    {
      retry: 0,
      enabled: !isEmpty(selectCategory),
      refetchOnWindowFocus: false,
      onSuccess: (res: GetVideoGuideGroupListResponse) => {
        if (res.error !== 0 || res.result === undefined) {
          setGroups([]);
          return;
        }
        setGroups(
          res.result.map((group: VideoGuideGroup) => {
            return {
              value: group.groupId as string,
              label: group.name,
              order:
                group.videoGuideList !== undefined
                  ? group.videoGuideList.length + 1
                  : 1,
            };
          })
        );

        const findGroup = res.result.find((group: VideoGuideGroup) => {
          return group.groupId === props.selectedGroup.groupId;
        });
        if (findGroup) {
          setVideoInfo((info) => {
            return {
              ...info,
              position: props.isEdit
                ? info.position
                : findGroup.videoGuideList !== undefined
                ? findGroup.videoGuideList.length + 1
                : (1 as number),
            };
          });
        }
      },
      onError: (e: any) => {
        console.info(e);
      },
    }
  );

  const mutationDeleteVideo = useMutation(deleteGuideVideo, {
    onSuccess: () => {
      notify(
        "success",
        intl.formatMessage({
          id: "label.manager.video.delete.success",
          defaultMessage: "Video deleted successfully.",
        })
      );
      props.onModal("edit");
    },
    onError: () => {
      notify(
        "error",
        intl.formatMessage({
          id: "label.manager.video.delete.fail",
          defaultMessage: "Failed to delete video.",
        })
      );
    },
  });

  const onClickDelete = useCallback(() => {
    setIsDeleteConfirm(false);
    mutationDeleteVideo.mutate({
      videoId: props.selectedVideo?.id,
    });
  }, [mutationDeleteVideo, props.selectedVideo?.id]);

  const onSaveBefore = useCallback(() => {
    setValidate((info) => {
      return { ...info, title: false, videoUrl: false };
    });
    setValidateMsg((info) => {
      return { ...info, title: "", videoUrl: "" };
    });

    let checkedFlag = false;
    if (isEmpty(videoInfo.title)) {
      checkedFlag = true;
      setValidate((info) => {
        return { ...info, title: true };
      });
      setValidateMsg((info) => {
        return {
          ...info,
          title: intl.formatMessage({
            id: "validateMsg.manager.video.title",
            defaultMessage: "Please enter a video title.",
          }) as string,
        };
      });
    }

    if (
      checkWhiteSpaceRegEx(videoInfo.videoUrl) ||
      !validateYoutubeUrl(videoInfo.videoUrl)
    ) {
      checkedFlag = true;
      setValidate((info) => {
        return { ...info, videoUrl: true };
      });
      setValidateMsg((info) => {
        return {
          ...info,
          videoUrl: intl.formatMessage({
            id: "validateMsg.manager.video.url",
            defaultMessage: "The URL you entered is in an invalid format.",
          }) as string,
        };
      });
    }

    if (checkedFlag) {
      return;
    }

    props.onClickSave(videoInfo, props.isEdit);
  }, [intl, props, videoInfo]);

  const [thumbnailUrl, setThumbnailUrl] = useState<string>("");

  const convertThumbnailUrl = useCallback(() => {
    if (videoInfo.videoUrl !== "") {
      const thumb = getThumb(videoInfo.videoUrl);
      if (thumb !== "") {
        setVideoInfo((info) => {
          return { ...info, thumbnailUrl: thumb as string };
        });
      } else {
        setVideoInfo((info) => {
          return { ...info, thumbnailUrl: "" };
        });
      }
    }
  }, [videoInfo.videoUrl]);

  const getThumb = (url: string, size?: string) => {
    if (url === null) {
      return "";
    }

    try {
      let video = "";
      size = size === null ? "big" : size;
      const results = url
        .replace(/(>|<)/gi, "")
        .split(/(vi\/|v=|\/v\/|youtu\.be\/|\/embed\/)/);
      if (results[2] !== undefined) {
        const videoUrlArray = results[2].split(/[^0-9a-z_\-]/i);
        video = videoUrlArray[0];
      } else {
        video = results[0];
      }

      if (size === "small") {
        return "http://img.youtube.com/vi/" + video + "/2.jpg";
      }
      return "http://img.youtube.com/vi/" + video + "/0.jpg";
    } catch (error) {
      return "";
    }
  };

  const onDropUrl = useCallback(
    (event: React.DragEvent<HTMLInputElement>) => {
      event.preventDefault();
      const videoHtml = event.dataTransfer.getData("text/html");
      if (videoHtml) {
        let parser = new DOMParser();
        const doc = parser.parseFromString(videoHtml, "text/html");
        const link = doc.getElementsByClassName(
          "ytp-title-link yt-uix-sessionlink"
        );
        if (link !== undefined && link.length !== 0) {
          const title = link[0].innerHTML;
          const url = event.dataTransfer.getData("text/plain");

          if (title && videoInfo.title === "") {
            setVideoInfo((info) => {
              return { ...info, title: title as string };
            });
          }

          if (url) {
            setVideoInfo((info) => {
              return {
                ...info,
                videoUrl: url as string,
                thumbnailUrl: getThumb(url),
              };
            });
          }
        } else {
          const videoUrl = event.dataTransfer.getData("text/plain");
          setVideoInfo((info) => {
            return {
              ...info,
              videoUrl: videoUrl as string,
              thumbnailUrl: getThumb(videoUrl),
            };
          });
        }
      } else {
        const videoUrl = event.dataTransfer.getData("text/plain");
        setVideoInfo((info) => {
          return {
            ...info,
            videoUrl: videoUrl as string,
            thumbnailUrl: getThumb(videoUrl),
          };
        });
      }
    },
    [videoInfo.title]
  );

  const onChangeGroup = useCallback((value: string) => {
    if (value) {
      setSelectCategory(value);
    }
  }, []);

  return (
    <BigPopupTemplate
      title={props.isEdit ? "Edit Video" : "Add Video"}
      onModal={props.onModal}
    >
      <s.Row>
        <ModalInput label="Category" essential half>
          <Select
            options={videoGuideOptions}
            value={videoGuideOptions.filter(
              (option) => option.value === selectCategory
            )}
            isDisabled={true}
            onChange={onChangeGroup}
          />
        </ModalInput>
        <ModalInput label="Group" essential half>
          <Select
            options={groups}
            value={
              videoInfo.groupId === ""
                ? groups[0]
                : groups.filter((option) => option.value === videoInfo.groupId)
            }
            isDisabled={true}
          />
        </ModalInput>
      </s.Row>
      <s.Row>
        <ModalInput label="Title" essential>
          <Input
            value={videoInfo.title}
            placeholder="Enter a name for video."
            disabled={!isActivate}
            onChange={(e) => {
              onChangeUserInfo(UPDATE_TYPE.TITLE, e.target.value);
            }}
            isValidated={validate.title}
          />
          <ValidateWord>{validateMsg.title}</ValidateWord>
        </ModalInput>
      </s.Row>
      <s.Row>
        <ModalInput label="Youtube URL" essential>
          <RowInput>
            <Input
              type="url"
              value={videoInfo.videoUrl}
              placeholder="Enter a name for video."
              onChange={(e) => {
                onChangeUserInfo(UPDATE_TYPE.VIDEO_URL, e.target.value);
              }}
              isValidated={validate.videoUrl}
              onDrop={onDropUrl}
            />
            <BigButton label={"Thumbnail"} onClickBtn={convertThumbnailUrl} />
          </RowInput>
          <ValidateWord>{validateMsg.videoUrl}</ValidateWord>
        </ModalInput>
      </s.Row>
      <s.Row>
        <ModalInput label="Thumbnail" half>
          <PreThumb>
            {
              videoInfo.thumbnailUrl !== "" ? (
                <img src={videoInfo.thumbnailUrl} alt="video_Thumbnail" />
              ) : (
                <>
                  <MdBrokenImage size={100} color="red" />
                  <p>Thumnail not Available</p>
                </>
              )
              // : <img src={VideoShareBroken} alt="video-share-broken" />
            }
          </PreThumb>
        </ModalInput>
        <ModalInput label="Order" half>
          <Input
            type="number"
            min={0}
            value={videoInfo.position}
            placeholder="Enter a name for video."
            disabled={!isActivate}
            onChange={(e) => {
              onChangeUserInfo(UPDATE_TYPE.ORDER, e.target.value);
            }}
          />
        </ModalInput>
      </s.Row>
      <ModalInput label="Description">
        <Input
          value={videoInfo.description}
          placeholder="Enter a name for this user."
          disabled={!isActivate}
          onChange={(e) => {
            onChangeUserInfo(UPDATE_TYPE.DESC, e.target.value);
          }}
        />
      </ModalInput>
      <s.ButtonWrapper>
        {props.isEdit && (
          <BigButton
            buttonType="warning"
            label="Delete"
            onClickBtn={() => setIsDeleteConfirm(true)}
          />
        )}
        <BigButton
          label="Save"
          onClickBtn={onSaveBefore}
          disabled={!isActivate as boolean}
        />
      </s.ButtonWrapper>
      {props.isEdit && isDeleteConfirm && (
        <DeleteConfirmModal
          label="VideoGuide"
          onDelete={() => setIsDeleteConfirm(false)}
          onConfirmDelete={onClickDelete}
        />
      )}
    </BigPopupTemplate>
  );
}

export default AddVideo;

const PreThumb = styled.div`
  width: 100%;
  height: 200px;
  border: 1px solid ${COLORS.BORDER};
  text-align: center;

  > img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
  > svg {
    margin: 20px;
  }
  > p {
    color: red;
  }
`;

const RowInput = styled.div`
  ${FLEX.FlexBetweenCenter};
  gap: 15px;
  width: 100%;

  .account-block {
    flex: 1;
    width: 100%;
    height: 100%;
  }
  > button {
    padding: 10px;
  }
`;
