import { useLocation, useNavigate } from "react-router-dom";

import Dashboard from "./dashboard";
import Widgets from "./widgets";
import Stats from "./stats";
import Map from "./map";
import { useAppSelector } from "redux/hooks";
import {
  LEVEL_TYPE,
  ProfileAccountInfo,
} from "api/interfaces/accountInterface.interface";
import { useEffect } from "react";
import { menuNavigation } from "utils/MenuUtil";

export function DashboardPresenter(): JSX.Element {
  const location = useLocation();
  const navigate = useNavigate();

  const selectedAccount: ProfileAccountInfo = useAppSelector(
    (state) => state.accountSettings
  );

  useEffect(() => {
    if (selectedAccount.accountLevel === LEVEL_TYPE.DW) {
      menuNavigation(selectedAccount, navigate, "/dashboard/admin");
    }
  }, [navigate]);

  if (location.pathname.includes("chart")) {
    return <Dashboard />;
    // return <Widgets />;
  }
  if (location.pathname.includes("admin")) {
    return <Stats />;
  }

  return <Map />;
}
