// import ARROWDOWN from "assets/icons/arrow_down.svg";
import { ReactNode, useState } from "react";
import Select, {
  components,
  GroupBase,
  MultiValue,
  MultiValueGenericProps,
  MultiValueProps,
  OptionProps,
  StylesConfig,
  ValueContainerProps,
} from "react-select";
import Group from "react-select/dist/declarations/src/components/Group";
import styled from "styled-components";
import { COLORS, LIGHT_THEME_COLORS } from "styles/colors";
import { FLEX } from "styles/flex";
import { Option } from "./util";
import { calcRem } from "styles/theme";
import { useAppSelector } from "redux/hooks";

type OptionsType = Array<Option>;

interface CommonProps {
  // clearValue: () => void,
  // getStyles: (string, any) => {},
  // getValue: () => ValueType,
  // hasValue: boolean,
  isMulti?: boolean;
  options: OptionsType;

  //selectOption: OptionType => void;
  // selectProps: any,
  //setValue: (ValueType: any, ActionTypes: any) => void,
  // emotion: any,
  value?: any;
  defaultValue?: any;
  isDisabled?: boolean;
  onChange?: (value: MultiValue<any>) => void;
  placeholder?: string;
  id?: string;
}

const customStyles: StylesConfig<Option> = {
  control: (styles, state) => {
    let isLightTheme =
      state.selectProps.className?.includes("select-light-mode");
    const backgroundColor = state.isDisabled
      ? isLightTheme
        ? LIGHT_THEME_COLORS.LIGHTGRAY
        : COLORS.BUTTONS1
      : COLORS.WHITE;
    const borderColor =
      state.isDisabled && isLightTheme ? LIGHT_THEME_COLORS.BORDER : "#cacaca";
    const maxHeight = "33px";
    const minHeight = "33px";

    return { ...styles, backgroundColor, borderColor, maxHeight, minHeight };
  },
  container: (styles, state) => {
    let isLightTheme =
      state.selectProps.className?.includes("select-light-mode");

    return {
      ...styles,
      width: "100%",
      height: "100%",
      color: state.isDisabled
        ? isLightTheme
          ? `${COLORS.BLACK}60`
          : COLORS.WHITE
        : COLORS.BLACK,
      fontSize: calcRem(14),
      fontWeight: "normal",
    };
  },
  valueContainer: (styles, state) => {
    let isLightTheme =
      state.selectProps.className?.includes("select-light-mode");

    return {
      ...styles,
      color: state.isDisabled
        ? isLightTheme
          ? `${COLORS.BLACK}60`
          : `${COLORS.WHITE}60`
        : COLORS.BLACK,
      fontSize: calcRem(14),
      fontWeight: "normal",
    };
  },
  menu: (provided) => ({ ...provided, zIndex: 9999 }),
  placeholder: (styles, state) => {
    return {
      ...styles,
      color: "#abb5c5",
      fontWeight: "normal",
    };
  },
  indicatorSeparator: () => ({}),
  indicatorsContainer: (styles) => ({ ...styles, padding: 0, height: "33px" }),
  dropdownIndicator: (styles) => ({
    ...styles,
    padding: 0,
    color: COLORS.BUTTONS1,
  }),
  // option: (provided: any) => ({
  //   ...provided,
  //   display: "flex",
  //   alignItems: "center",
  //   width: "100%", // 고정된 너비
  //   whiteSpace: "normal", // 텍스트가 줄바꿈될 수 있도록 설정
  //   wordWrap: "break-word", // 텍스트가 고정 너비를 넘어가면 줄바꿈
  // }),
};

const CustomOption = (props: OptionProps<any, boolean, GroupBase<Option>>) => {
  return (
    <components.Option {...props}>
      <div style={{ display: "flex", alignItems: "flex-start", width: "100%" }}>
        <input
          type="checkbox"
          checked={props.isSelected}
          readOnly={true}
          onChange={() => null}
          style={{ marginRight: 5, flexShrink: 0 }}
        />
        <span
          style={{
            flexGrow: 1,
            whiteSpace: "normal",
            wordWrap: "break-word",
          }}
        >
          {props.label}
        </span>
      </div>
    </components.Option>
  );
};
type SelectedBadgeProps = {
  items: string[];
};

const CustomValueContainer = (
  props: ValueContainerProps<any, boolean, GroupBase<Option>>
) => {
  let [values, input] = props.children as any;

  if (Array.isArray(values)) {
    const plural = values.length === 1 ? "" : "s";
    values =
      values.length === 1
        ? `${values[0].props.data.label}`
        : `${values[0].props.data.label} +${values.length - 1}`;
  }

  return (
    <components.ValueContainer {...props}>
      {values}
      {input}
    </components.ValueContainer>
  );
};

const SelectsCombo = (props: CommonProps) => {
  const theme = useAppSelector((state) => state.theme.theme);

  return (
    <Select
      id={props.id}
      {...props}
      isMulti
      styles={customStyles}
      closeMenuOnSelect={false}
      hideSelectedOptions={false}
      isClearable={false}
      backspaceRemovesValue={false}
      placeholder={props.placeholder ? props.placeholder : "Select"}
      onChange={props.onChange}
      components={{
        Option: CustomOption,
        ValueContainer: CustomValueContainer,
      }}
      className={`select-container select-${theme}-mode`}
    />
  );
};
export default SelectsCombo;

const ValueInner = styled.div`
  width: 100%;
`;
