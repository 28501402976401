/**
 * @author ydoni
 * @description password 입력 시 bubble
 */
import styled from "styled-components";
import { COLORS } from "styles/colors";
import { calcRem } from "styles/theme";
import InfoBubble from "./InfoBubble";
import { FLEX } from "styles/flex";
import { checkPasswordNumberRegEx, checkPasswordRegEx } from "utils/regEx";
import WARNING_ICON from "assets/icons/warning_red.svg";
import ONLINE_ICON from "assets/icons/online.svg";
import { Text } from "components/atoms/text/Text";

type OptionBubbleProps = {
  password: string;
};

function PasswordBubble({ password }: OptionBubbleProps): JSX.Element {
  return (
    <InfoBubble>
      <Text fontSize={15} bold color={COLORS.WHITE}>
        Password must contain:
      </Text>
      <ul>
        <PasswordText>
          <img src={password.length < 8 ? WARNING_ICON : ONLINE_ICON} />8 or
          more characters
        </PasswordText>
        <PasswordText>
          <img
            src={checkPasswordRegEx(password) ? ONLINE_ICON : WARNING_ICON}
          />
          Upper & lowercase letters
        </PasswordText>
        <PasswordText>
          <img
            src={
              checkPasswordNumberRegEx(password) ? ONLINE_ICON : WARNING_ICON
            }
          />
          At least one number and Special Character
        </PasswordText>
      </ul>
    </InfoBubble>
  );
}

export const PasswordText = styled.li`
  ${FLEX.FlexCenterCenter};
  gap: 8px;
  font-size: ${calcRem(15)};

  > img {
    width: 15px;
    height: 15px;
  }
`;

export default PasswordBubble;
