import * as s from "./SystemSpecStyled";
import SystemSpectrumSpec from "./SystemSpectrumSpec";
import SystemVMAXSpec from "./vmax";

type Props = {
  recorderId: string;
  type: string;
};

export default function SystemSpecs(props: Props): JSX.Element {
  return (
    <s.Wrapper>
      {props.type === "Spectrum" ? (
        <SystemSpectrumSpec recorderId={props.recorderId} />
      ) : (
        <SystemVMAXSpec recorderId={props.recorderId} />
      )}
    </s.Wrapper>
  );
}
