import styled from "styled-components";
import VerifyTitle from "components/blocks/verify/VerifyTitle";
import * as s from "../../ModalTemplateStyled";
import VerfiyCode from "components/blocks/verify/VerfiyCode";
import SmallPopupDefaultTemplate from "../SmallPopupDefaultTemplate";
import PrimaryButton from "components/atoms/buttons/PrimaryButton";

export type Props = {
  onModal: () => void;
  onVerify :() => void;
  verifyCode: string;
  target?: string;
  modalType : string;
  onChange: (value: string) => void;
};

export default function VerifyPopup({
  verifyCode,
  onModal,
  onVerify,
  onChange,
  modalType,
  target
}: Props): JSX.Element {
  const buttons = (
    <s.ButtonWrapper>
      <PrimaryButton buttonType="cancel" label="Cancel" onClickBtn={onModal} />
      <PrimaryButton label="Verify" onClickBtn={onVerify} />
    </s.ButtonWrapper>
  );
  return (
    <SmallPopupDefaultTemplate button={buttons} onModal={onModal}>
      <s.SmallContainer>
        <Contents>
          <VerifyTitle
            title={modalType !== undefined && modalType === "email" ?  "Verify New Email Address" : "Verify New Phone Number"}
            desc={target!}
            descTitle="Enter the six-digit code sent to: "
          />
          <VerfiyCode verifyCode={verifyCode} onChange={onChange} onClickOtpEnter={onVerify}/>
        </Contents>
      </s.SmallContainer>
    </SmallPopupDefaultTemplate>
  );
}

const Contents = styled.div`
  margin: 40px 0;
`;
