import * as s from "./ModalHeaderStyled";
import { Text } from "components/atoms/text/Text";
import CLOSE_ICON from "assets/icons/sidenav/x.svg";
import CHEVRON_RIGHT from "assets/icons/chevron_right.svg";
import { COLORS } from "styles/colors";
import { FiChevronLeft, FiChevronRight } from "react-icons/fi";

export interface ModalSubTitleInterface {
  label: string;
}

type ModalHeaderProps = {
  title: string;
  subTitle?: any;
  onModal: () => void;
  disableClose?: boolean;
};

function ModalHeader({
  title,
  subTitle,
  onModal,
  disableClose,
}: ModalHeaderProps): JSX.Element {
  return (
    <s.HeaderContainer>
      <s.TitleContainer>
        <Text fontSize={22} bold>
          {title}
        </Text>
        {subTitle && (
          <s.Row>
            {Array.isArray(subTitle) ? (
              subTitle.map((item: any, index: number) => (
                <>
                  <FiChevronRight className="chevron-right" size={18} />
                  <s.Chevron alt="chevron right" src={CHEVRON_RIGHT} />
                  <Text fontSize={22} color={COLORS.BUTTONS1}>
                    {item}
                  </Text>
                </>
              ))
            ) : (
              <>
                <FiChevronRight className="chevron-right" size={18} />

                <Text fontSize={16} color={COLORS.BUTTONS1}>
                  {subTitle}
                </Text>
              </>
            )}
          </s.Row>
        )}
      </s.TitleContainer>
      {!disableClose && (
        <s.CloseButton onClick={onModal}>
          <img alt="close button" src={CLOSE_ICON} />
        </s.CloseButton>
      )}
    </s.HeaderContainer>
  );
}

export default ModalHeader;
