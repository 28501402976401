import { BasePagination } from "./commonInterface.interface";

export enum ICON_TYPE {
  NONE = "NONE",
  INFO = "INFO",
  WARNING = "WARNING",
  DOCUMENT = "DOCUMENT",
  COOKIE = "COOKIE",
}

export enum NOTICE_COLOR {
  BLUE = "#008AFF",
  GREEN = "#00E286",
  PURPLE = "#8000FF",
  ORANGE = "#D55F1B",
  RED = "#FF0000",
  COOKIE = "#1B2332",
  LIGHTCOOKIE = "#FFF6F0",
}

export type NOTICE_COLOR_TYPE =
  | "BLUE"
  | "GREEN"
  | "PURPLE"
  | "ORANGE"
  | "RED"
  | "COOKIE"
  | "LIGHTCOOKIE";

export type GetNoticeRequest = BasePagination;

export interface Notice {
  subject: string;
  message: string;
  icon: ICON_TYPE;
  color: NOTICE_COLOR;
  period: number;
  isDisplay: boolean;
  noticeId?: string;
}

export type CreateNoticeRequest = Notice;
export type UpdateNoticeRequest = Notice;

export interface NoticeInfo extends Notice {
  noticeId: string;
  enableFromTime: string;
  enableEndTime: string;
  preview?: boolean;
  disabled?: boolean;
}

export interface GetNoticeResponse {
  error: number;
  errorString: string;
  result: NoticeInfo[];
  page: BasePagination;
}
