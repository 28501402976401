import styled from "styled-components";
import WARNING_CIRCLE_ICON from "assets/icons/warning_circle.svg";
import COOKIE_ICON from "assets/icons/marker_cookie.svg";
import {
  ICON_TYPE,
  NOTICE_COLOR,
} from "api/interfaces/noticeInterface.interface";
import { useMemo } from "react";
import TRIANGLE_CIRCLE_ICON from "assets/icons/triangle_warning.svg";
import DOCUMENT_ICON from "assets/icons/document.svg";
import { COLORS } from "styles/colors";
import { FLEX } from "styles/flex";
import { calcRem } from "styles/theme";
import { UnderlineText } from "components/atoms/text/Text";
import { IoMdClose } from "react-icons/io";
import * as mediaQuery from "components/MediaQuery";
import TinyButton from "components/atoms/buttons/TinyButton";
import { useAppDispatch } from "redux/hooks";
import { deleteCookieBar } from "redux/reducers/notices/cookieReducer";

interface Props {
  message: string;
  color: NOTICE_COLOR;
  type: ICON_TYPE;
  onClickClose: () => void;
  isCookie?: boolean;
}

export function NoticeBar(props: Props): JSX.Element {
  const { message, color, type, onClickClose } = props;
  const dispatch = useAppDispatch();

  const icon = useMemo(() => {
    switch (type) {
      case ICON_TYPE.INFO:
        return WARNING_CIRCLE_ICON;
      case ICON_TYPE.WARNING:
        return TRIANGLE_CIRCLE_ICON;
      case ICON_TYPE.DOCUMENT:
        return DOCUMENT_ICON;
      case ICON_TYPE.COOKIE:
        return COOKIE_ICON;
      default:
        return WARNING_CIRCLE_ICON;
    }
  }, [type]);

  return (
    <Container color={color} isCookie={props.isCookie}>
      <Wrapper>
        <img className="marker" src={icon} width="20" alt="Marker Icon" />
        <span className="notice-message">
          {message}
          <mediaQuery.Mobile>
            {props.isCookie && (
              <UnderlineText
                color={COLORS.BUTTONS1}
                fontSize={12}
                className="mobile-more-button"
                onClick={() =>
                  window.open(
                    "https://digital-watchdog.com/page/statement-of-privacy",
                    "_blank"
                  )
                }
              >
                Learn More
              </UnderlineText>
            )}
          </mediaQuery.Mobile>
        </span>
        <mediaQuery.Default>
          {props.isCookie && (
            <UnderlineText
              color={COLORS.BUTTONS1}
              fontSize={12}
              onClick={() =>
                window.open(
                  "https://digital-watchdog.com/page/statement-of-privacy",
                  "_blank"
                )
              }
            >
              Learn More
            </UnderlineText>
          )}
        </mediaQuery.Default>
        {props.isCookie && (
          <span className="accept-button">
            <TinyButton
              label="Accept Cookies"
              onClickBtn={() => dispatch(deleteCookieBar())}
            />
          </span>
        )}
      </Wrapper>

      <CloseButton onClick={onClickClose}>
        <IoMdClose size={12} />
      </CloseButton>
    </Container>
  );
}

export const NOTICE_HEIGHT = 40;
const Container = styled.div<{ color: string; isCookie?: boolean }>`
  width: 100%;
  background-color: ${({ color }) => color};
  padding: 8px;
  ${FLEX.FlexStartCenter};

  ${({ isCookie }) =>
    isCookie &&
    `
     position: fixed;
     bottom: 0;
    z-index: 997;
  `}

  .notice-message {
    .mobile-more-button {
      display: inline;
      margin-left: 10px;
    }
  }

  ${mediaQuery.isDefault} {
    height: ${NOTICE_HEIGHT}px;
  }
`;

const Wrapper = styled.div`
  width: 95%;
  ${FLEX.FlexCenterCenter};
  gap: 5px;
  color: ${({ theme }) => theme.COLORS.WHITE};
  font-size: ${calcRem(12)};

  .marker {
    width: 16px;
  }

  ${mediaQuery.isDefault} {
    .accept-button {
      margin-left: 30px;
    }
  }
`;

const CloseButton = styled.button`
  position: absolute;
  right: 10px;
  color: ${({ theme }) => theme.COLORS.WHITE};
`;

export const NoticeWrapper = styled.div`
  position: sticky;
  top: 0;
`;
