/**
 * @author ydoni
 * @description advanced settings
 *              ex) alert Rule show advanced settings
 */

import styled from "styled-components";
import { COLORS, MAP_STATUS_COLORS } from "styles/colors";
import { FLEX } from "styles/flex";
import INFO_ICON from "assets/icons/info.svg";
import { calcRem } from "styles/theme";
import * as mediaQuery from "components/MediaQuery";
import Toggle from "components/atoms/toggle/Toggle";
import { InputNumber, Switch, Tooltip } from "antd";
import { useState } from "react";

type AdvancedSettingsProps = {
  label: string;
  unit?: any; // 단위
  noCount?: boolean; // no count input
  enabled?: boolean;
  defaultValue?: number;
  defaultChecked?: boolean;
  onChangeValue?: (value: number) => void;
  onChangeToggle?: (checked: boolean) => void;
  min?: number;
  max?: number;
  tooltip?: string;
  checked?: boolean;
};

function AdvancedSettings({
  label,
  unit,
  noCount,
  defaultValue,
  defaultChecked,
  onChangeValue,
  onChangeToggle,
  min,
  max,
  tooltip,
  checked,
}: AdvancedSettingsProps): JSX.Element {
  const [value, setValue] = useState<number>(defaultValue as number);

  return (
    <Container>
      <Title>
        <Label>
          {tooltip !== undefined ? (
            <Tooltip placement="bottomLeft" title={tooltip}>
              <img src={INFO_ICON} alt="rule info"></img>
            </Tooltip>
          ) : (
            <img src={INFO_ICON} alt="rule info"></img>
          )}
          {label}
        </Label>

        <mediaQuery.Mobile>
          <ToggleContainer>
            <Switch
              checkedChildren={"Critical"}
              unCheckedChildren={"Alert "}
              onChange={onChangeToggle}
              defaultChecked={defaultChecked}
              checked={checked}
            />
          </ToggleContainer>
        </mediaQuery.Mobile>
      </Title>

      <Contents>
        {!noCount && (
          <InputNumber
            type="tel"
            min={min}
            max={max}
            value={defaultValue}
            onKeyDown={(event) => {
              var regExp =
                /[ \{\}\[\]\/?.,;:|\)*~`!^\-_+┼<>@\#$%&\'\"\\\(\=]/gi;
              if (regExp.test(event.key)) {
                event.preventDefault();
              }
            }}
            onChange={(value) => {
              if (value !== null) {
                setValue(value);
                if (onChangeValue !== undefined) {
                  onChangeValue(value);
                }
              } else {
                setValue(min as number);
                if (onChangeValue !== undefined) {
                  onChangeValue(min as number);
                }
              }
            }}
            defaultValue={defaultValue}
          />
        )}

        <span>{unit && unit}</span>
      </Contents>

      <mediaQuery.Default>
        <ToggleContainer>
          <Switch
            checkedChildren={"Critical"}
            unCheckedChildren={"Alert "}
            onChange={onChangeToggle}
            defaultChecked={defaultChecked}
            checked={checked}
          />
        </ToggleContainer>
      </mediaQuery.Default>
    </Container>
  );
}

const Container = styled.div`
  ${FLEX.FlexStartCenter};
  padding: 5px 0;

  ${mediaQuery.isMobile} {
    flex-direction: column;
    align-items: flex-start;
  }
`;

const Title = styled.div`
  width: 100%;
  flex: 1;
  ${FLEX.FlexBetweenCenter};
`;

const Label = styled.p`
  flex: 1;
  ${FLEX.FlexStartCenter};
  color: ${({ theme }) => theme.COLORS.WHITE};
  gap: 5px;
  font-size: ${calcRem(14)};
  ${mediaQuery.isDefault} {
    max-width: 290px;
    min-width: 290px;
  }

  img {
    width: 20px;
    height: 20px;
  }

  .tooltiptext {
    visibility: hidden;
    width: 120px;
    background-color: black;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;

    /* Position the tooltip */
    position: absolute;
    z-index: 1;
  }

  :hover .tooltiptext {
    visibility: visible;
  }
`;

const Contents = styled.p`
  flex: 1;
  ${FLEX.FlexStartCenter}
  gap: 8px;

  input {
    border-radius: 5px;
    width: 70px;
    padding: 8px;
  }

  > span {
    color: ${({ theme }) => theme.COLORS.WHITE};
    font-size: ${calcRem(14)};
  }
  ${mediaQuery.isMobile} {
    margin-left: 20px;
    margin-top: 10px;
  }
`;

const ToggleContainer = styled.div`
  > button {
    padding: 0;
  }

  .ant-switch {
    background-color: ${MAP_STATUS_COLORS.ALERT};
  }
  .ant-switch-checked {
    background-color: ${MAP_STATUS_COLORS.CRITICAL};
  }
  .ant-switch-checked:focus {
    box-shadow: unset;
  }
  .ant-switch-inner {
    font-weight: 500;
    font-size: ${calcRem(12)};
  }
`;

export default AdvancedSettings;
