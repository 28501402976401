import { COLORS, DARK_THEME_COLORS, LIGHT_THEME_COLORS } from "./colors";
import * as mediaQuery from "components/MediaQuery";

export const calcRem = (size: number) => `${size / 16}rem`;

const fontSizes = {
  small: calcRem(13),
  base: calcRem(15),
  lg: calcRem(17),
  xl: calcRem(19),
  xxl: calcRem(21),
  xxxl: calcRem(23),
  titleSize: calcRem(50),
};

const paddings = {
  small: calcRem(8),
  base: calcRem(10),
  lg: calcRem(12),
  xl: calcRem(14),
  xxl: calcRem(16),
  xxxl: calcRem(18),
};

const interval = {
  base: calcRem(50),
  lg: calcRem(100),
  xl: calcRem(150),
  xxl: calcRem(200),
};

const verticalInterval = {
  base: `${calcRem(10)} 0 ${calcRem(10)} 0`,
};

const deviceSizes = {
  mobileS: "320px",
  mobileM: "375px",
  mobileL: "450px",
  tablet: `${mediaQuery.tabletMin}px`,
  tabletL: "1024px",
};

const device = {
  mobileS: `only screen and (max-width: ${deviceSizes.mobileS})`,
  mobileM: `only screen and (max-width: ${deviceSizes.mobileM})`,
  mobileL: `only screen and (max-width: ${deviceSizes.mobileL})`,
  tablet: `only screen and (max-width: ${deviceSizes.tablet})`,
  tabletL: `only screen and (max-width: ${deviceSizes.tabletL})`,
};

export const light = {
  value: "light",
  fontSizes,
  COLORS: {
    ...COLORS,
    ...LIGHT_THEME_COLORS,
  },
  deviceSizes,
  device,
  paddings,
  interval,
  verticalInterval,
};

export const dark = {
  value: "dark",
  fontSizes,
  COLORS: {
    ...COLORS,
    ...DARK_THEME_COLORS,
  },
  deviceSizes,
  device,
  paddings,
  interval,
  verticalInterval,
};
