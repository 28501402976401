import { AxiosResponse } from "axios";
import { GetStorageUsageByAccount } from "./interfaces/storageInterface.interface";
import { AxiosInstance } from "./axiosInstance";

const STORAGE_API_PATH = "/storage";
// Get storage status
export const getStorageUsage = async ({
  accountId,
}: {
  accountId: string;
}): Promise<GetStorageUsageByAccount> => {
  try {
    const { data }: AxiosResponse<GetStorageUsageByAccount> =
      await AxiosInstance.get(`${STORAGE_API_PATH}/${accountId}`);
    return data;
  } catch (e) {
    console.error(e);
    throw new Error();
  }
};

export const getRecorderStorageUsage = async ({
  recorderId,
}: {
  recorderId: string;
}): Promise<GetStorageUsageByAccount> => {
  try {
    const { data }: AxiosResponse<GetStorageUsageByAccount> =
      await AxiosInstance.get(`${STORAGE_API_PATH}/recorder/${recorderId}`);
    return data;
  } catch (e) {
    console.error(e);
    throw new Error();
  }
};