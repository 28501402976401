import { AxiosResponse } from "axios";
import { AxiosInstance } from "./axiosInstance";
import qs from "qs";
import {
  ChartLayoutResponse,
  DashboarAlertActiveResponse,
  DashboarAlertActiveTypeResponse,
  DashboarAlertLatestResponse,
  DashboarDeviceResponse,
  DashboarFilterAccountAndUserResponse,
  DashboarUserLoginResponse,
  DashboardAlertFilterRequest,
  DashboardAlertRequest,
  DashboardDeviceRequest,
  DashboardFilterAlertResponse,
  DashboardFilterRecorderGroupResponse,
  DashboardFilterRecorderRequest,
  DashboardFilterRecorderResponse,
  DashboardFilterSearchRecordersRequest,
  KeywordSearchResposne,
} from "./interfaces/dashboardInterface.interface";
import { Layout } from "react-grid-layout";
import {
  BasePagination,
  CustomError,
} from "./interfaces/commonInterface.interface";
import { WidgetStatsPayload } from "./interfaces/widgetInterface.interface";

const API_PATH = "/dashboard";

// dashboard chart page gird layout 조회
export const getChartLayout = async (): Promise<ChartLayoutResponse> => {
  try {
    const { data }: AxiosResponse<ChartLayoutResponse> =
      await AxiosInstance.get(`${API_PATH}/chart/layout`);
    return data;
  } catch (e) {
    console.error(e);
    throw new Error();
  }
};

// dashboard map page gird layout 조회
export const getMapLayout = async (): Promise<ChartLayoutResponse> => {
  try {
    const { data }: AxiosResponse<ChartLayoutResponse> =
      await AxiosInstance.get(`${API_PATH}/map/layout`);
    return data;
  } catch (e) {
    console.error(e);
    throw e;
  }
};

export const getKeywordList = async ({
  accountId,
  payload,
}: {
  accountId: string;
  payload: BasePagination;
}): Promise<KeywordSearchResposne> => {
  try {
    const res: AxiosResponse<KeywordSearchResposne> = await AxiosInstance.get(
      `${API_PATH}/search/list/${accountId}?${qs.stringify(payload)}`
    );

    return res.data;
  } catch (e) {
    console.error(e);
    throw new Error();
  }
};

// dashboard chart page grid layout 저장
export const postChartLayout = async ({
  payload,
}: {
  payload: Layout[];
}): Promise<Layout[]> => {
  // console.log(payload, "payload");
  try {
    const { data }: AxiosResponse<Layout[]> = await AxiosInstance.post(
      `${API_PATH}/chart/layout`,
      payload
    );
    return data;
  } catch (e) {
    console.error(e);
    throw new Error();
  }
};

export const postMapLayout = async ({
  payload,
}: {
  payload: Layout[];
}): Promise<Layout[]> => {
  // console.log(payload, "payload");
  try {
    const { data }: AxiosResponse<Layout[]> = await AxiosInstance.post(
      `${API_PATH}/map/layout`,
      payload
    );
    return data;
  } catch (e) {
    console.error(e);
    throw e;
  }
};

export const getDashboardAlertActive = async ({
  accountId,
}: {
  accountId: string;
}): Promise<DashboarAlertActiveResponse> => {
  try {
    const res: AxiosResponse<DashboarAlertActiveResponse> =
      await AxiosInstance.get(`${API_PATH}/alert/active/${accountId}`);
    return res.data;
  } catch (e) {
    const customErr = e as CustomError;
    throw customErr;
  }
};

export const getDashboardAlertActiveType = async ({
  accountId,
}: {
  accountId: string;
}): Promise<DashboarAlertActiveTypeResponse> => {
  try {
    const res: AxiosResponse<DashboarAlertActiveTypeResponse> =
      await AxiosInstance.get(`${API_PATH}/alert/active/type/${accountId}`);
    return res.data;
  } catch (e) {
    const customErr = e as CustomError;
    throw customErr;
  }
};

export const getDashboardAlertSpeed = async ({
  accountId,
  durationInMonth,
  payload,
}: {
  accountId: string;
  durationInMonth: number;
  payload?: DashboardAlertRequest;
}): Promise<DashboarDeviceResponse> => {
  try {
    const res: AxiosResponse<DashboarDeviceResponse> =
      payload?.alertTypes === null
        ? await AxiosInstance.get(
            `${API_PATH}/alert/speed/${accountId}/${durationInMonth}`
          )
        : await AxiosInstance.get(
            `${API_PATH}/alert/speed/${accountId}/${durationInMonth}`,
            {
              params: payload,
              paramsSerializer: (params) => {
                return qs.stringify(params, { arrayFormat: "repeat" });
              },
            }
          );
    return res.data;
  } catch (e) {
    const customErr = e as CustomError;
    throw customErr;
  }
};

export const getDashboardOverviewStatistic = async ({
  accountId,
}: {
  accountId: string;
}): Promise<DashboarAlertActiveResponse> => {
  try {
    const res: AxiosResponse<DashboarAlertActiveResponse> =
      await AxiosInstance.get(`${API_PATH}/stats/overview/${accountId}`);
    return res.data;
  } catch (e) {
    const customErr = e as CustomError;
    throw customErr;
  }
};

export const getDashboardUserLogin = async ({
  accountId,
  interval,
}: {
  accountId: string;
  interval: string;
}): Promise<DashboarUserLoginResponse> => {
  try {
    const res: AxiosResponse<DashboarUserLoginResponse> =
      await AxiosInstance.get(
        `${API_PATH}/stats/login/${accountId}/${interval}`
      );
    return res.data;
  } catch (e) {
    const customErr = e as CustomError;
    throw customErr;
  }
};

export const getDashboardDevice = async ({
  accountId,
  payload,
}: {
  accountId: string;
  payload: DashboardDeviceRequest;
}): Promise<DashboarDeviceResponse> => {
  try {
    const res: AxiosResponse<DashboarDeviceResponse> =
      payload.deviceTypes === null
        ? await AxiosInstance.get(`${API_PATH}/stats/device/${accountId}`)
        : await AxiosInstance.get(`${API_PATH}/stats/device/${accountId}`, {
            params: payload,
            paramsSerializer: (params) => {
              return qs.stringify(params, { arrayFormat: "repeat" });
            },
          });
    return res.data;
  } catch (e) {
    const customErr = e as CustomError;
    throw customErr;
  }
};

export const postDashboardAlertLatest = async ({
  accountId,
  maxCount,
  payload,
}: {
  accountId: string;
  maxCount: number;
  payload: WidgetStatsPayload;
}): Promise<DashboarAlertLatestResponse> => {
  try {
    const res: AxiosResponse<DashboarAlertLatestResponse> =
      await AxiosInstance.post(
        `${API_PATH}/alert/latest/${accountId}/${maxCount}`,
        payload
      );
    return res.data;
  } catch (e) {
    const customErr = e as CustomError;
    throw customErr;
  }
};

export const getFilterAccountUsers = async ({
  accountId,
}: {
  accountId: string;
}): Promise<DashboarFilterAccountAndUserResponse> => {
  try {
    const res: AxiosResponse<DashboarFilterAccountAndUserResponse> =
      await AxiosInstance.get(
        `${API_PATH}/filter/tree/accountsAndUsers/${accountId}`
      );
    return res.data;
  } catch (e) {
    const customErr = e as CustomError;
    throw customErr;
  }
};

export const getFilterRecorders = async ({
  accountId,
}: {
  accountId: string;
}): Promise<DashboardFilterRecorderResponse> => {
  try {
    const res: AxiosResponse<DashboardFilterRecorderResponse> =
      await AxiosInstance.get(`${API_PATH}/filter/recorders/${accountId}`);
    return res.data;
  } catch (e) {
    const customErr = e as CustomError;
    throw customErr;
  }
};

export const postFilterSearchRecorders = async ({
  accountId,
  payload,
}: {
  accountId: string;
  payload: DashboardFilterSearchRecordersRequest;
}): Promise<DashboardFilterAlertResponse> => {
  try {
    const res: AxiosResponse<DashboardFilterAlertResponse> =
      await AxiosInstance.post(
        `${API_PATH}/filter/recorders/${accountId}`,
        payload
      );
    return res.data;
  } catch (e) {
    const customErr = e as CustomError;
    throw customErr;
  }
};

export const postMapAlertSpeed = async ({
  accountId,
  type,
  durationInMonth,
  payload,
}: {
  accountId: string;
  type: string;
  durationInMonth: number;
  payload: DashboardAlertFilterRequest;
}): Promise<DashboarDeviceResponse> => {
  // console.log(payload, "payload 1");
  try {
    const res: AxiosResponse<DashboarDeviceResponse> = await AxiosInstance.post(
      `${API_PATH}/map/alert/speed/${accountId}/${type}/${durationInMonth}`,
      payload
    );
    return res.data;
  } catch (e) {
    const customErr = e as CustomError;
    throw customErr;
  }
};

export const postMapAlertLatest = async ({
  accountId,
  type,
  maxCount,
  payload,
}: {
  accountId: string;
  type: string;
  maxCount: number;
  payload: DashboardAlertFilterRequest;
}): Promise<DashboarAlertLatestResponse> => {
  // console.log(payload, "payload 2");
  try {
    const res: AxiosResponse<DashboarAlertLatestResponse> =
      await AxiosInstance.post(
        `${API_PATH}/map/alert/latest/${accountId}/${type}/${maxCount}`,
        payload
      );
    return res.data;
  } catch (e) {
    const customErr = e as CustomError;
    throw customErr;
  }
};

export const postMapAlertActive = async ({
  accountId,
  type,
  payload,
}: {
  accountId: string;
  type: string;
  payload: DashboardAlertFilterRequest;
}): Promise<DashboarAlertActiveResponse> => {
  // console.log(payload, "payload 3");
  try {
    const res: AxiosResponse<DashboarAlertActiveResponse> =
      await AxiosInstance.post(
        `${API_PATH}/map/alert/active/${accountId}/${type}`,
        payload
      );
    return res.data;
  } catch (e) {
    const customErr = e as CustomError;
    throw customErr;
  }
};
