import {
  ICON_TYPE,
  NOTICE_COLOR,
} from "api/interfaces/noticeInterface.interface";

import { NoticeBar } from "./NoticeBar";
import { useAppDispatch, useAppSelector } from "redux/hooks";

interface Props {
  onClickClose: () => void;
}

export function CookieBar(props: Props): JSX.Element {
  const dispatch = useAppDispatch();
  const theme = useAppSelector((state) => state.theme.theme);

  return (
    <NoticeBar
      isCookie
      message="myDW uses cookies to monitor site usage for quality assurance purposes. By continuing to use myDW you are giving us consent to do this on your device."
      type={ICON_TYPE.COOKIE}
      color={theme === "light" ? NOTICE_COLOR.LIGHTCOOKIE : NOTICE_COLOR.COOKIE}
      onClickClose={props.onClickClose}
    />
  );
}
