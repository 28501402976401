// 이메일 정규식 check
export const checkEmailRegEx = (email: string) => {
  // let regex = new RegExp(
  //   "([!#-'*+/-9=?A-Z^-~-]+(.[!#-'*+/-9=?A-Z^-~-]+)*|\"([]!#-[^-~ \t]|(\\[\t -~]))+\")@([!#-'*+/-9=?A-Z^-~-]+(.[!#-'*+/-9=?A-Z^-~-]+)*|[[\t -Z^-~]*])"
  // );
  const regex = /[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])+/g
  return regex.test(email);
};

// 비밀번호 정규식 대문자 & 소문자 check
export const checkPasswordRegEx = (password: string) => {
  let upperandLowerLetterRegEx = /(?=.*[a-z])(?=.*[A-Z])/;
  // console.log(password, upperandLowerLetterRegEx.test(password), "letter");
  return upperandLowerLetterRegEx.test(password);
};

// 비밀번호 숫자 || SPECIAL CHARACTER check
export const checkPasswordNumberRegEx = (password: string) => {
  let numberRegEx = /(?=.*?[0-9])(?=.*?[\{\}\[\]\/?.,;:|\)*~`!^\-_+<>@\#$%&\\\=\(\'\"])/;
  // console.log(password, numberRegEx.test(password), "number");
  return numberRegEx.test(password);
};

/**
 *  공백체크 정규식.
 */
export const checkWhiteSpaceRegEx = (str: string) => {
  const regex = /^\s+|\s+$/g;
  if (str.replace(regex, "") === "") return true;
  else return false;
};

export const validateYoutubeUrl = (url :string) => {
  const regex = /^(?:https?:\/\/)?(?:m\.|www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/;
  if(url){
    if(url.match(regex)){
      return true;
    }else{
      return false;
    }
  }else{
    return false;
  }
}