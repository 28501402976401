import * as s from "../SignupStyled";
import * as cs from "../../SignCommonStyled";
import PROFILE_ICON from "assets/icons/account_profile.svg";
import { UserInfo } from "../SignupContainer";
import { useIntl } from "react-intl";
import { Text } from "components/atoms/text/Text";

interface Props {
  userInfo: UserInfo;
  onClickLogin: () => void;
}

export default function Success(props: Props): JSX.Element {
  const { onClickLogin, userInfo } = props;
  const intl = useIntl();

  return (
    <s.SuccessInner>
      <img src={PROFILE_ICON} width="185" alt="Profile Icon" />

      <Text fontSize={18} className="user-name">
        {userInfo.firstName + " " + userInfo.lastName}
      </Text>

      <div className="success-box">
        <div className="title">
          {intl.formatMessage({
            id: "signup_message",
            defaultMessage: "Successfully invited to the organization.",
          })}
        </div>
        <div className="description">
          {intl.formatMessage({
            id: "signup_message2",
            defaultMessage: "You may now login and use myDW.",
          })}
        </div>
      </div>
      <cs.BigButton
        id="dw_signup_button_login"
        type="button"
        onClick={onClickLogin}
      >
        {intl.formatMessage({
          id: "login",
          defaultMessage: "Login",
        })}
      </cs.BigButton>
    </s.SuccessInner>
  );
}
