import React, { forwardRef, HTMLProps, useCallback, useEffect } from "react";
import { useMemo, useState } from "react";
import { useQuery } from "react-query";
import {
  BasePagination,
  SORT_DIRECTION,
} from "api/interfaces/commonInterface.interface";
import { getRecorderList, getRecorderTotalList } from "api/recorderAPI";
import {
  IndeterminateCheckbox,
  IndeterminateRadio,
  VGroupTable,
} from "./comp/VGroupTable";
import {
  Recorder,
  RecorderTotalList,
  RecorderTotalListResposne,
} from "api/interfaces/recorderInterface.interface";
import { chain, extend } from "lodash";
import { ColumnDef, Row, RowSelectionState } from "@tanstack/react-table";
import { convertType, flatten } from "utils/functions";
import { BsCaretDownFill, BsCaretRightFill } from "react-icons/bs";
import { useAppSelector } from "redux/hooks";
import { COLORS } from "styles/colors";

export type SelectedRowWithAddress = {
  id: string;
  address: string;
};

export type SearchRecorderProps = {
  accountId: string;
  isSingleMode?: boolean;
  onSelectedRowsChange?: (selectedRows: string[]) => void;
  onSelectRowChangeAddress?: (row: SelectedRowWithAddress) => void;
  selectedRow?: string[] | string;
  allSelect?: boolean;
  search?: string;
  isEditable?: boolean;
  userId?:string;
};

export interface DataRow {
  id: string;
  name: string;
  cameras: number;
  location: string;
}

export interface ModalRecorder {
  id: string;
  recorderName: string;
  cameraCount: number;
  location: string;
  subRows: ModalRecorder[];
}

const recorderGroupBySystemId = (recorders: Recorder[]) => {
  let result = chain(recorders)
    .groupBy("cloudSystemId")
    .map(function (value, key) {
      return extend(JSON.parse(JSON.stringify(value[0])), {
        subRows: value,
      });
    })
    .value();

  // convert the object into an array of objects
  return Object.values(result);
};

export function SearchRecorderList(props: SearchRecorderProps): JSX.Element {
  const theme = useAppSelector((state) => state.theme.theme);
  const caretColor = theme === "light" ? COLORS.BLACK : COLORS.WHITE;

  const [recorder, setRecorder] = useState<ModalRecorder[]>([]);
  const [selectRecorder, setSelectRecorder] = useState<number>(0);
  const [selectedCombine, setSelectedCombine] = useState<RowSelectionState>({});
  const [recorderQueryInfo, setRecorderQueryInfo] = useState<BasePagination>({
    total: 10,
    pageNum: 0,
    pageLimit: 2000,
    keyword: "",
    sortType: "name",
    sortDirection: SORT_DIRECTION.ASC,
  });

  const columns = React.useMemo<ColumnDef<any>[]>(
    () =>
      props.isSingleMode !== undefined && props.isSingleMode
        ? [
            {
              accessorKey: "recorderName",
              header: ({ table }) => (
                <>
                  {/* <IndeterminateCheckbox
              {...{
                checked: table.getIsAllRowsSelected(),
                indeterminate: table.getIsSomeRowsSelected(),
                onChange: table.getToggleAllRowsSelectedHandler(),
                // onClick: onClickCheckBox
              }}
            /> */}{" "}
                  {/* <button
              {...{
                onClick: table.getToggleAllRowsExpandedHandler(),
              }}
            >
              {table.getIsAllRowsExpanded() ? "👇" : "👉"}
            </button>{" "} */}
                  Name
                </>
              ),
              cell: ({ row, getValue }) => (
                <div
                  style={{
                    // Since rows are flattened by default,
                    // we can use the row.depth property
                    // and paddingLeft to visually indicate the depth
                    // of the row
                    // display: "flex",
                    // alignItems: "center",
                    // gap: "6px",
                    paddingLeft: `${row.depth * 1}rem`,
                  }}
                >
                  <>
                    <IndeterminateRadio
                      style={
                        row.getCanExpand()
                          ? { opacity: 0, display: "none" }
                          : {}
                      }
                      {...{
                        disabled:
                          props.isEditable !== undefined
                            ? props.isEditable
                            : false,
                        checked: row.getIsSelected(),
                        // indeterminate: row.getIsSomeSelected(),
                        // onClick: row.getToggleSelectedHandler(),
                        onChangeIndeterminateRadio:
                          row.getToggleSelectedHandler(),
                        // onClick: onClickCheckBox
                      }}
                    />{" "}
                    {row.getCanExpand() ? (
                      <button
                        {...{
                          onClick: row.getToggleExpandedHandler(),
                          style: { cursor: "pointer" },
                        }}
                      >
                        {row.getIsExpanded() ? (
                          <BsCaretDownFill color={caretColor} size={15} />
                        ) : (
                          <BsCaretRightFill color={caretColor} size={15} />
                        )}
                      </button>
                    ) : (
                      ""
                    )}{" "}
                    {getValue()}
                  </>
                </div>
              ),
              footer: (props) => props.column.id,
            },
            {
              accessorKey: "cameraCount",
              header: () => "Cameras",
              size: 50,
            },
            {
              accessorKey: "location",
              header: "Locations",
              size: 100,
            },
          ]
        : [
            {
              accessorKey: "recorderName",
              header: ({ table }) => (
                <>
                  <IndeterminateCheckbox
                    {...{
                      checked: table.getIsAllRowsSelected(),
                      indeterminate: table.getIsSomeRowsSelected(),
                      onChange: table.getToggleAllRowsSelectedHandler(),
                      // onClick: onClickCheckBox
                    }}
                  />{" "}
                  {/* <button
              {...{
                onClick: table.getToggleAllRowsExpandedHandler(),
              }}
            >
              {table.getIsAllRowsExpanded() ? "👇" : "👉"}
            </button>{" "} */}
                  Name
                </>
              ),
              cell: ({ row, getValue }) => (
                <div
                  style={{
                    // Since rows are flattened by default,
                    // we can use the row.depth property
                    // and paddingLeft to visually indicate the depth
                    // of the row
                    paddingLeft: `${row.depth * 1}rem`,
                  }}
                >
                  <>
                    <IndeterminateCheckbox
                      style={row.depth > 0 ? { opacity: 0 } : {}}
                      {...{
                        checked: row.getIsSelected(),
                        indeterminate: row.getIsSomeSelected(),
                        onChange: row.getToggleSelectedHandler(),
                        // onClick: onClickCheckBox
                      }}
                    />{" "}
                    {row.getCanExpand() ? (
                      <button
                        {...{
                          onClick: row.getToggleExpandedHandler(),
                          style: { cursor: "pointer" },
                        }}
                      >
                        {row.getIsExpanded() ? (
                          <BsCaretDownFill color={caretColor} size={15} />
                        ) : (
                          <BsCaretRightFill color={caretColor} size={15} />
                        )}
                      </button>
                    ) : (
                      ""
                    )}{" "}
                    {getValue()}
                  </>
                </div>
              ),
              footer: (props) => props.column.id,
            },
            {
              accessorKey: "cameraCount",
              header: () => "Cameras",
              size: 50,
            },
            {
              accessorKey: "location",
              header: "Locations",
              size: 100,
            },
          ],
    []
  );

  const { isLoading, isSuccess, error, refetch } = useQuery(
    ["recorder", props.selectedRow],
    () =>
      getRecorderTotalList({        
        accountId: props.accountId,
        userId:  props.userId
      }),
    {
      retry: 0,
      refetchOnWindowFocus: false,
      onSuccess: (res: RecorderTotalListResposne) => {
        if (res.result === undefined) {
          return;
        }
        // console.log("selectRow", props.selectedRow);

        const totalList: ModalRecorder[] = [];
        let tmpCombine = {};

        res.result.forEach((data: RecorderTotalList, index: number) => {
          if (!props.isSingleMode) {
            if (props.selectedRow?.includes(data.mergedSystemId)) {
              Object.assign(tmpCombine, {
                [data.mergedSystemId]: true,
              });
            }
          } else {
            if (props.selectedRow !== undefined) {
              const selectedRecorder = data.recorders.find(
                (rec: Recorder) => rec.recorderId === props.selectedRow
              );
              if (selectedRecorder) {
                Object.assign(tmpCombine, {
                  [selectedRecorder.recorderId]: true,
                });
              }
            }
          }

          if (data.recorders.length === 1) {
            const model = {
              id: props.isSingleMode
                ? data.recorders[0].recorderId
                : data.mergedSystemId,
              recorderName: data.mergedSystemName,
              // data.recorders[0].type === "Spectrum"
              //   ? data.mergedSystemName
              //   : data.recorders[0].name,
              cameraCount: data.cameraTotalCount,
              location: data.location !== undefined ? data.location : "",
            } as ModalRecorder;
            totalList.push(model);
          } else {
            // const checkSystem = data.recorders.find((item: any) =>
            //   props.selectedRow?.includes(item.mergedSystemId)
            // );
            // if (checkSystem && !props.isSingleMode) {
            //   Object.assign(tmpCombine, {
            //     [data.mergedSystemId.concat(".merged")]: true,
            //   });
            // }
            const model = {
              id: data.mergedSystemId,
              recorderName: data.mergedSystemName,
              cameraCount: data.cameraTotalCount,
              // location: data.location !== undefined ? data.location : "",
              location: "",
              subRows: data.recorders.map((item: any, index2: number) => {
                // if(props.isSingleMode){
                //   if(props.selectedRow?.includes(item.recorderId)){
                //     Object.assign(tmpCombine, { [item.recorderId]: true });
                //   }
                // }else{
                //   if (checkSystem && !props.isSingleMode) {
                //     Object.assign(tmpCombine, { [item.recorderId]: true });
                //   }
                // }
                return {
                  id: item.recorderId,
                  recorderName: item.name,
                  cameraCount: item.cameraCount,
                  location:
                    item.location?.location !== undefined
                      ? item.location.location
                      : "",
                };
              }),
            } as ModalRecorder;
            totalList.push(model);
          }
        });

        setRecorder(totalList);

        // res.result.forEach((recorder, index:number) =>{
        //   if(props.selectedRow?.includes(recorder.recorderId)){
        //     Object.assign(tmpCombine,{ [index] : true} )
        //   }
        // });
        setSelectedCombine(tmpCombine);

        // let checkedRecorder = recorder.map((recorders) => recorders.id);
        // if (props.onSelectedRowsChange) {
        //   props.onSelectedRowsChange(checkedRecorder);
        // }
      },
      onError: (e: any) => {},
    }
  );

  useEffect(() => {
    // TODO 전체선택일 경우 체크하는 로직 수정필요.. 트리구조에서 onSelectedRowsChange 이벤트 확인필요.
    // console.log({ props });
    if (props.allSelect) {
      let checkedRecorder: string[] = recorder.map(
        (recorders) => recorders.id as string
      );
      // if (props.onSelectedRowsChange) {
      //   props.onSelectedRowsChange(checkedRecorder);
      // }
    }
  }, [props.allSelect, isLoading, props, recorder]);

  const handleChange = useCallback(
    (row: RowSelectionState) => {
      // You can set state or dispatch with something like Redux so we can use the retrieved data
      console.log(row);
      let checkedRecorder: string[] = [];

      const selectKeys = Object.keys(row);

      if (selectKeys.length > 0) {
        selectKeys.forEach((select) => {
          if (!select.endsWith("merged")) {
            checkedRecorder.push(select);
          }
        });
      }
      if (props.isSingleMode) {
        let temp: any[] = [];
        flatten(temp, recorder);
        const selectedRecorder = temp.find(
          (rec) => rec.id === checkedRecorder[0]
        );
        if (selectedRecorder && props.onSelectRowChangeAddress) {
          props.onSelectRowChangeAddress({
            id: selectedRecorder.id,
            address: selectedRecorder.location,
          });
        }
      } else {
        if (props.onSelectedRowsChange) {
          // console.log({checkedRecorder});
          props.onSelectedRowsChange(checkedRecorder);
        }
      }
    },
    [recorder]
  );

  return (
    <>
      <VGroupTable
        columns={columns}
        data={recorder}
        search={props.search}
        selectedRow={selectedCombine}
        onSelectedRowsChange={handleChange}
        isSingleMode={props.isSingleMode}
      />
    </>
  );
}
