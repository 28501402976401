import styled from "styled-components";
import BackgroundImage from "assets/images/backgrounds/login.jpg";
import SpeechBubble from "assets/icons/speech_bubble.svg";
import PasswordStrenghMarker from "assets/icons/password_strength_marker.svg";
import { COLORS } from "styles/colors";
import { calcRem } from "styles/theme";
import { FLEX } from "styles/flex";
import * as mediaQuery from "components/MediaQuery";
import { commonLoginHeight } from "../SignCommonStyled";

export const TitleWrapper = styled.div`
  ${FLEX.FlexCenterCenter};
  gap: 8px;
  flex-direction: column;

  > .txt {
    color: ${COLORS.WHITE}60;
  }
`;

export const SignupTitle = styled.p`
  font-size: ${calcRem(24)};
  font-weight: 500;
  color: white;
  ${FLEX.FlexCenterEnd};
  gap: 8px;
`;

export const SuccessInner = styled.div`
  margin: 0 auto;
  text-align: center;

  > img {
    width: 35%;
  }

  .user-name {
    padding-top: 20px;
  }

  ${mediaQuery.isDefault} {
    .verify-title {
      padding-bottom: 20px;
    }
    .success-box {
      padding: 20px 0 30px;
    }
  }

  .description {
    line-height: ${calcRem(18)};
  }
  .title {
    margin-bottom: 10px !important;
  }
  ${mediaQuery.isMobile} {
    ${FLEX.FlexBetweenCenter};
    flex-direction: column;
    width: 100%;
    height: 100%;
  }
`;

export const ModalInner = styled.div`
  width: 110%;
  max-height: 80vh;
  overflow-y: auto;
  padding: 10px;
  color: ${COLORS.BUTTONS1};
  font-size: ${calcRem(14)};
  line-height: ${calcRem(18)};

  .button {
    width: 100%;
    text-decoration: underline;
    white-space: pre-wrap;
    word-break: break-word;
    font-size: ${calcRem(14)};
  }
`;

export const InputWrapper = styled.div`
  ${FLEX.FlexStartCenter};
  flex-direction: column;
  gap: 20px;
  width: 100%;

  > input {
    color: ${COLORS.BLACK};
    height: ${commonLoginHeight};
    font-size: ${calcRem(14)};
  }
`;
export const AgreementWrapper = styled.label`
  margin-top: 10px;
  ${FLEX.FlexStartCenter};
  gap: 5px;
  font-size: ${calcRem(14)};
  color: ${COLORS.BUTTONS1};
  margin-bottom: 10px;
`;

export const SubmitWrapper = styled.div`
  margin-top: 10px;
  label {
    color: ${COLORS.BUTTONS1};
    font-size: ${calcRem(14)};
    margin-bottom: 16px;
  }
`;

export const PasswordInputInner = styled.div`
  position: relative;
  width: 100%;

  > input {
    color: ${COLORS.BLACK};
    height: ${commonLoginHeight};
    font-size: ${calcRem(14)};
  }
`;

export const PasswordBubble = styled.p`
  position: absolute;
  /* width: 60%; */
  margin-top: 10px;
  z-index: 5;

  ul {
    width: 100%;
    ${FLEX.FlexStartStart};
    flex-direction: column;
    gap: 8px;
    padding: 10px 0;
  }
`;

export const PasswordText = styled.li`
  ${FLEX.FlexCenterCenter};
  gap: 8px;
  font-size: ${calcRem(15)};

  > img {
    width: 15px;
    height: 15px;
  }
`;

export const PasswordTitle = styled.div`
  ${FLEX.FlexCenterCenter};
  gap: 10px;
  padding-bottom: 8px;

  .title {
    margin-bottom: 0 !important;
  }

  ${mediaQuery.isMobile} {
    > img {
      width: 24px;
    }
  }
`;

export const VerifyWrapper = styled.div`
  padding-bottom: 10px;
  width: 80%;
  margin: 0 auto;
  ${FLEX.FlexCenterCenter};
  flex-direction: column;
`;

export const TFAWrapper = styled.div`
  width: 100%;
  ${FLEX.FlexBetweenCenter};
  color: ${({ theme }) => theme.COLORS.WHITE};
  font-size: ${calcRem(14)};

  .checkbox-wrapper {
    ${FLEX.FlexEndCenter};
    gap: 20px;
    flex: 1;

    label {
      ${FLEX.FlexStartCenter};
      gap: 6px;
      font-size: ${calcRem(13)};

      > input {
        width: 12px;
        height: 12px;
      }
    }
  }
`;

export const PhoneWrapper = styled.div`
  ${FLEX.FlexBetweenCenter};
  flex-direction: column;
  gap: 8px;
  width: 100%;

  .phone-input-wrapper {
    ${FLEX.FlexBetweenCenter};
    gap: 8px;
    width: 100%;

    input {
      height: 40px;
    }

    > button {
      height: 40px;
      min-width: 100px;

      .icon-button {
        ${FLEX.FlexStartCenter};
        color: ${COLORS.WHITE};
        position: relative;
      }
    }
  }

  .validate-word {
    font-size: ${calcRem(13)};
    padding-top: 0;
    width: 100%;
  }

  #dw_signup_button_forward_signup {
    ${FLEX.FlexEndCenter};
  }
`;

export const ValidWrapper = styled.div`
  width: 100%;
`;
