import { useCallback, useEffect, useMemo, useState } from "react";
import { useQuery } from "react-query";
import * as s from "styles/ModalCommonStyled";
import SearchInput from "components/atoms/input/search/SearchInput";
import { useIntl } from "react-intl";
import {
  GetAccountListQuery,
  SORT_TYPE,
} from "api/interfaces/accountInterface.interface";
import { getAccoountList } from "api/accountAPI";
import { Account, getAccountListMappger } from "api/mappers/accountMapper";
import { IndeterminateCheckbox, VGroupTable } from "./comp/VGroupTable";
import { ColumnDef, Row, RowSelectionState } from "@tanstack/react-table";
import DebounceSearchInput from "components/atoms/input/search/DebounceSearchInput";
import React from "react";
import { getAlertRuleAccountList } from "api/alertAPI";
import {
  AlertRuleAccountInfo,
  AlertRuleAccountListResponse,
} from "api/interfaces/alertInterface.interface";
import { BsCaretDownFill, BsCaretRightFill } from "react-icons/bs";
import { useAppSelector } from "redux/hooks";
import { COLORS } from "styles/colors";
import { orderBy } from "lodash";

export type SearchAlertAccountProps = {
  accountId: string;
  placeholder?: string;
  selectedAccountId?: string[];
  disabledAccountId?: string[];
  isSingleMode?: boolean;
  onSelectedRowsChange?: (selectedRows: string[]) => void;
  disabled?: boolean;
  allSelect?: boolean;
};

export interface DataRow {
  id: string;
  name: string;
  level: string;
}

export interface ModalAccount {
  id: string;
  name: string;
  level: string;
  subRows?: ModalAccount[];
}

export function SearchAlertAccount(
  props: SearchAlertAccountProps
): JSX.Element {
  const intl = useIntl();

  const theme = useAppSelector((state) => state.theme.theme);
  const caretColor = theme === "light" ? COLORS.BLACK : COLORS.WHITE;

  const [accounts, setAccounts] = useState<ModalAccount[]>([]);
  const [selectAccount, setSelectAccount] = useState<number>(0);
  const [selectedCombine, setSelectedCombine] = useState<RowSelectionState>({});
  const [searchText, setSearchText] = useState<string>("");

  const columns = React.useMemo<ColumnDef<any>[]>(
    () => [
      {
        accessorKey: "name",
        size: 300,
        header: ({ table }) => (
          <>
            <IndeterminateCheckbox
              {...{
                checked: table.getIsAllRowsSelected(),
                indeterminate: table.getIsSomeRowsSelected(),
                onChange: table.getToggleAllRowsSelectedHandler(),
                disabled: props.disabled,
              }}
            />{" "}
            {/* <button
                {...{
                  onClick: table.getToggleAllRowsExpandedHandler(),
                }}
              >
                {table.getIsAllRowsExpanded() ? "👇" : "👉"}
              </button>{" "} */}
            Name
          </>
        ),
        cell: ({ row, getValue }) => (
          <div
            style={{
              // Since rows are flattened by default,
              // we can use the row.depth property
              // and paddingLeft to visually indicate the depth
              // of the row
              paddingLeft: `${row.depth * 1}rem`,
            }}
          >
            <>
              <IndeterminateCheckbox
                style={row.depth > 0 ? { opacity: 0 } : {}}
                {...{
                  checked: row.getIsSelected(),
                  indeterminate: row.getIsSomeSelected(),
                  onChange: row.getToggleSelectedHandler(),
                  disabled: props.disabled,
                }}
              />{" "}
              {row.getCanExpand() ? (
                <button
                  {...{
                    onClick: row.getToggleExpandedHandler(),
                    style: { cursor: "pointer" },
                  }}
                >
                  {row.getIsExpanded() ? (
                    <BsCaretDownFill color={caretColor} size={15} />
                  ) : (
                    <BsCaretRightFill color={caretColor} size={15} />
                  )}
                </button>
              ) : (
                " "
              )}{" "}
              {getValue()}
            </>
          </div>
        ),
        minSize: 300,
        footer: (props) => props.column.id,
      },
      {
        accessorKey: "level",
        header: () =>
          intl.formatMessage({
            id: "table.label.level",
            defaultMessage: "Level",
          }),
        size: 80,
      },
    ],
    []
  );

  const { isLoading, refetch } = useQuery(
    ["account", props.selectedAccountId],
    () =>
      getAlertRuleAccountList({
        accountId: props.accountId,
      }),
    {
      retry: 0,
      refetchOnWindowFocus: false,
      onSuccess: (res: AlertRuleAccountListResponse) => {
        if (res.result === undefined) {
          setAccounts([]);
          return;
        } else {
          const totalList: ModalAccount[] = [];
          let tmpCombine = {};

          res.result.forEach((data: AlertRuleAccountInfo, index: number) => {
            if (data.subRow === undefined) {
              const model = {
                id: data.accountId.concat(`_`, data.level),
                name: data.name,
                level: data.level,
              } as ModalAccount;
              totalList.push(model);

              if (
                props.selectedAccountId?.includes(
                  data.accountId.concat("_", data.level)
                )
              ) {
                Object.assign(tmpCombine, {
                  [data.accountId.concat("_", data.level)]: true,
                });
              }
            } else {
              const model = {
                id: data.accountId.concat(`_`, data.level),
                name: data.name,
                level: data.level,
                subRows: data.subRow?.map(
                  (item: AlertRuleAccountInfo, index2: number) => {
                    return {
                      id: item.accountId,
                      name: item.name,
                      level: item.level,
                    };
                  }
                ),
              } as ModalAccount;
              totalList.push(model);

              if (
                props.selectedAccountId?.includes(
                  data.accountId.concat("_", data.level)
                )
              ) {
                Object.assign(tmpCombine, {
                  [data.accountId.concat("_", data.level)]: true,
                });
              }
            }
          });
          //TODO Recorder list not sorted at BackEnd..
          //setAccounts(orderBy(totalList, [`id`], [`desc`]));
          setAccounts(totalList);
          setSelectedCombine(tmpCombine);
        }
      },
      onError: (e: any) => {
        setAccounts([]);
      },
    }
  );

  // useEffect(()=>{
  //   if(props.selectedAccountId !== undefined){
  //     let tmpCombine ={};
  //     accounts.forEach((account, index:number) =>{
  //       if (props.selectedAccountId?.includes(account.id.concat("_",account.level))) {
  //         Object.assign(tmpCombine, {
  //           [account.id.concat("_",account.level)]: true,
  //         });
  //       }
  //     });
  //     setSelectedCombine(tmpCombine);
  //   }
  // },[accounts, props.selectedAccountId]);

  // const accountList = useMemo((): DataRow[] => {
  //   return Array.from(accounts).map((account) => {
  //     const {
  //       name,
  //       level,
  //       levelFullName,
  //       hasAccounts,
  //       hasRecorders,
  //       accountId,
  //     } = account;
  //     return {
  //       name,
  //       level,
  //       levelFullName,
  //       accounts: hasAccounts,
  //       recorders: hasRecorders,
  //       id: accountId,
  //     };
  //   });
  // }, [accounts]);

  useEffect(() => {
    if (props.allSelect) {
      let checkedRecorder: string[] = accounts.map(
        (account) => account.id as string
      );
      if (props.onSelectedRowsChange) {
        props.onSelectedRowsChange(checkedRecorder);
      }
    }
  }, [props.allSelect, isLoading]);

  const handleChange = useCallback((row: RowSelectionState) => {
    // You can set state or dispatch with something like Redux so we can use the retrieved data
    let checkedRecorder: string[] = [];
    const selectKeys = Object.keys(row);

    if (selectKeys.length > 0) {
      selectKeys.forEach((select) => {
        if (select.endsWith("_CP") || select.endsWith("_EU")) {
          checkedRecorder.push(select);
        }
      });
    }
    if (props.onSelectedRowsChange) {
      props.onSelectedRowsChange(checkedRecorder);
    }
  }, []);

  const onClickSearch = (keyword: string) => {
    // setAccountQueryInfo((info) => {
    //   return { ...info, keyword: keyword as string };
    // });
    refetch();
  };

  const onChangeSearchText = useCallback((value: string) => {
    setSearchText(value);
  }, []);

  return (
    <>
      <DebounceSearchInput
        placeholder={props.placeholder}
        onClickSearch={onClickSearch}
        onChange={onChangeSearchText}
      />
      <s.ModalTableContainer>
        <VGroupTable
          columns={columns}
          data={accounts}
          selectedRow={selectedCombine}
          search={searchText}
          onSelectedRowsChange={handleChange}
        />
      </s.ModalTableContainer>
    </>
  );
}
