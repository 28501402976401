import { useCallback, useState, useEffect } from "react";
import { AlertPresenter } from "./AlertPresenter";
import * as s from "./AlertStyled";
import { SortOrder, TableColumn } from "react-data-table-component";
import TableButton from "components/atoms/buttons/TableButton";
import { useQueryClient, useQuery } from "react-query";
import { notify } from "components/atoms/notification/Notification";
import { useAppSelector } from "redux/hooks";
import {
  LEVEL_TYPE,
  ProfileAccountInfo,
} from "api/interfaces/accountInterface.interface";
import {
  GetAlertActiveListResponse,
  ActiveAlert,
  AlertActiveSummary,
  SelectedAlertView,
} from "api/interfaces/alertInterface.interface";
import {
  SORT_DIRECTION,
  BasePagination,
} from "api/interfaces/commonInterface.interface";
import { getAlertActiveList } from "api/alertAPI";
import { Location, useLocation, useNavigate } from "react-router-dom";
import { getRecorderTimeFormat } from "utils/timeUtil";
import { useAuth } from "components/Auth";
import { useIntl } from "react-intl";
import { isCheckPermission } from "utils/AccountUtil";
import TableColumnFilterTitle from "components/blocks/table/TableColumnFilterTitle";
import {
  ActiveAlertStatusTypeFilter,
  AlertCategoryTypeFilter,
  displayAlert,
} from "utils/AlertUtil";
import { StatusTxt } from "./AlertStyled";
import { COLORS } from "styles/colors";
import { StatusName, StatusValue } from "components/blocks/status/Status";
import RecorderTitle from "components/blocks/recorder/RecorderTitle";
import { isNull } from "lodash";
import { queryNavigate } from "utils/MenuUtil";
import AccountId from "components/atoms/text/labels/AccountId";

const InitialAlertSummary = {
  total: 0,
  systemCount: 0,
  diskCount: 0,
  videoCount: 0,
};
export function AlertContainer(): JSX.Element {
  const navigate = useNavigate();
  const intl = useIntl();
  const auth = useAuth();
  const location: Location = useLocation();
  const params = new URLSearchParams(location.search);

  const [isAddModal, setIsAddModal] = useState(false);
  const [isEditModal, setIsEditModal] = useState(false);
  const [isViewModal, setIsViewModal] = useState(false);

  const selectedAccount: ProfileAccountInfo = useAppSelector(
    (state) => state.accountSettings
  );

  const queryClient = useQueryClient();
  const [pending, setPending] = useState<boolean>(true);

  const [alertList, setAlertList] = useState<ActiveAlert[]>([]);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [selectedAlert, setSelectedAlert] = useState<
    SelectedAlertView | undefined
  >();
  const [alertSummary, setAlertSummary] =
    useState<AlertActiveSummary>(InitialAlertSummary);

  const [alertActiveQueryInfo, setAlertActiveQueryInfo] =
    useState<BasePagination>({
      total: 0,
      pageNum: 0,
      pageLimit: 20,
      keyword: "",
      sortType: "alert_trigger",
      type: null,
      status: null,
      sortDirection: SORT_DIRECTION.DESC,
    });

  useEffect(() => {
    const changeParam = new URLSearchParams(location.search);
    if (changeParam.size > 0) {
      setAlertActiveQueryInfo((info) => {
        return {
          ...info,
          pageNum: isNull(changeParam.get("pageNum"))
            ? 0
            : Number(changeParam.get("pageNum")),
          pageLimit: isNull(changeParam.get("pageLimit"))
            ? 20
            : Number(changeParam.get("pageLimit")),
          keyword: isNull(changeParam.get("keyword"))
            ? ""
            : (changeParam.get("keyword") as string),
          sortType: isNull(changeParam.get("sortType"))
            ? "alert_trigger"
            : (changeParam.get("sortType") as string),
          sortDirection: isNull(changeParam.get("sortDirection"))
            ? SORT_DIRECTION.DESC
            : (changeParam.get("sortDirection") as SORT_DIRECTION),
          type: isNull(changeParam.get("type"))
            ? null
            : (changeParam.get("type") as string),
          status: isNull(changeParam.get("status"))
            ? null
            : (changeParam.get("status") as string),
        };
      });
    } else {
      setAlertActiveQueryInfo((info) => {
        return {
          ...info,
          pageNum: 0,
          pageLimit: 20,
          keyword: "",
          sortType: "alert_trigger",
          sortDirection: SORT_DIRECTION.DESC,
        };
      });
    }
  }, [location]);
  //status
  const status = {
    totalTitle: "Total",
    totalValue: alertSummary.total,
    items: [
      <>
        <s.Label>
          <StatusName>System</StatusName>
        </s.Label>
        <StatusValue color={COLORS.RED}>{alertSummary.systemCount}</StatusValue>
      </>,
      <>
        <s.Label>
          <StatusName>Disk</StatusName>
        </s.Label>
        <StatusValue color={COLORS.RED}>{alertSummary.diskCount}</StatusValue>
      </>,
      <>
        <s.Label>
          <StatusName>Video</StatusName>
        </s.Label>
        <StatusValue color={COLORS.RED}>{alertSummary.videoCount}</StatusValue>
      </>,
    ],
    isMobileShow: {
      totalTitle: true,
      totalValue: true,
      items: false,
    },
  };

  const [isRowClickEditable, setIsRowClickEditable] = useState<boolean>(
    isCheckPermission("manageAlertRules", selectedAccount)
  );
  useEffect(() => {
    if (selectedAccount) {
      setIsRowClickEditable(
        isCheckPermission("manageAlertRules", selectedAccount)
      );
    }
  }, [selectedAccount]);

  const onClickAlertCategoryType = useCallback(
    (value: string | undefined) => {
      if (value !== undefined) {
        if (value === "all") {
          if (alertActiveQueryInfo.type !== undefined) {
            queryNavigate(
              navigate,
              location.pathname,
              {
                pageLimit: alertActiveQueryInfo.pageLimit,
                pageNum: 0,
                keyword: alertActiveQueryInfo.keyword as string,
                sortType: alertActiveQueryInfo.sortType,
                sortDirection:
                  alertActiveQueryInfo.sortDirection as SORT_DIRECTION,
                type: null,
                status: alertActiveQueryInfo.status,
              },
              selectedAccount
            );
          } else {
            refetch();
          }
          // setAlertActiveQueryInfo((info) => {
          //   return { ...info, type: null, pageNum: 0 };
          // });
        } else {
          if (alertActiveQueryInfo.type !== value) {
            queryNavigate(
              navigate,
              location.pathname,
              {
                pageLimit: alertActiveQueryInfo.pageLimit,
                pageNum: 0,
                keyword: alertActiveQueryInfo.keyword as string,
                sortType: alertActiveQueryInfo.sortType,
                sortDirection:
                  alertActiveQueryInfo.sortDirection as SORT_DIRECTION,
                type: value,
                status: alertActiveQueryInfo.status,
              },
              selectedAccount
            );
          } else {
            refetch();
          }
          // setAlertActiveQueryInfo((info) => {
          //   return { ...info, type: value as string, pageNum: 0 };
          // });
        }
      }
    },
    [
      alertActiveQueryInfo.keyword,
      alertActiveQueryInfo.pageLimit,
      alertActiveQueryInfo.sortDirection,
      alertActiveQueryInfo.sortType,
      alertActiveQueryInfo.status,
      alertActiveQueryInfo.type,
      location.pathname,
      navigate,
      selectedAccount,
    ]
  );

  const onClickAlertStatusType = useCallback(
    (value: string | undefined) => {
      if (value !== undefined) {
        if (value === "all") {
          if (alertActiveQueryInfo.status !== null) {
            queryNavigate(
              navigate,
              location.pathname,
              {
                pageLimit: alertActiveQueryInfo.pageLimit,
                pageNum: 0,
                keyword: alertActiveQueryInfo.keyword as string,
                sortType: alertActiveQueryInfo.sortType,
                sortDirection:
                  alertActiveQueryInfo.sortDirection as SORT_DIRECTION,
                status: null,
                type: alertActiveQueryInfo.type,
              },
              selectedAccount
            );
          } else {
            refetch();
          }
          // setAlertActiveQueryInfo((info) => {
          //   return { ...info, status: null, pageNum: 0 };
          // });
        } else {
          if (alertActiveQueryInfo.status !== value) {
            queryNavigate(
              navigate,
              location.pathname,
              {
                pageLimit: alertActiveQueryInfo.pageLimit,
                pageNum: 0,
                keyword: alertActiveQueryInfo.keyword as string,
                sortType: alertActiveQueryInfo.sortType,
                sortDirection:
                  alertActiveQueryInfo.sortDirection as SORT_DIRECTION,
                status: value,
                type: alertActiveQueryInfo.type,
              },
              selectedAccount
            );
          } else {
            refetch();
          }
          // setAlertActiveQueryInfo((info) => {
          //   return { ...info, status: value as string, pageNum: 0 };
          // });
        }
      }
    },
    [
      alertActiveQueryInfo.keyword,
      alertActiveQueryInfo.pageLimit,
      alertActiveQueryInfo.sortDirection,
      alertActiveQueryInfo.sortType,
      alertActiveQueryInfo.status,
      alertActiveQueryInfo.type,
      location.pathname,
      navigate,
      selectedAccount,
    ]
  );
  // alert rules
  const columns: TableColumn<ActiveAlert>[] =
    selectedAccount.accountLevel !== "EU"
      ? [
          {
            name: (
              <TableColumnFilterTitle
                title="Category"
                filters={AlertCategoryTypeFilter}
                onClick={onClickAlertCategoryType}
              />
            ),
            grow: 1,
            selector: (row) => row.type,
            // sortable: true,
            // sortField: "name",
          },
          {
            name: "Organization",
            grow: 1.5,
            cell: (row) => (
              <s.AccountBox>
                {row.accountName}
                <AccountId
                  accountId={row.accountNumber}
                  level={LEVEL_TYPE.EU}
                />
              </s.AccountBox>
            ),
          },
          {
            name: "Recorder",
            grow: 1.5,
            cell: (row) => (
              <RecorderTitle
                mergedSystemName={row.mergedSystemName}
                recorderName={row.recorderName}
              />
              // <s.AccountBox>
              //   {row.mergedSystemName}
              // </s.AccountBox>
            ),
          },
          {
            name: (
              <TableColumnFilterTitle
                title="Status"
                filters={ActiveAlertStatusTypeFilter}
                onClick={onClickAlertStatusType}
              />
            ),
            grow: 1,
            cell: (row) => (
              <StatusTxt string={row.status.toUpperCase()}>
                {row.status}
              </StatusTxt>
            ),
          },
          {
            name: "Alert",
            grow: 2,
            selector: (row) => displayAlert(row),
          },
          {
            id: "alertTrigger",
            name: "Date",
            grow: 1.5,
            sortable: true,
            sortField: "alert_trigger",
            selector: (row) =>
              row.alertTrigger !== undefined
                ? getRecorderTimeFormat(
                    row.alertTrigger,
                    selectedAccount,
                    row.timezone,
                    true
                  )
                : "",
          },
          {
            name: "Assignee",
            grow: 1,
            selector: (row) => row.assignee,
          },
          {
            name: "",
            minWidth: "175px",
            grow: 2,
            cell: (row) => (
              <s.Row>
                {/* <span className="row-click-button"> */}
                <TableButton
                  buttonType="view"
                  label="View Alerts"
                  onClickBtn={() => onRowClick(row)}
                  // disabled={
                  //   row.systemCount + row.diskCount + row.videoCount === 0
                  //     ? true
                  //     : false
                  // }
                  // onClickBtn={() => onModal("view")}
                />
                {/* </span> */}
              </s.Row>
            ),
            right: true,
          },
        ]
      : [
          {
            name: (
              <TableColumnFilterTitle
                title="Category"
                filters={AlertCategoryTypeFilter}
                onClick={onClickAlertCategoryType}
              />
            ),
            grow: 1,
            selector: (row) => row.type,
            // sortable: true,
            // sortField: "name",
          },
          {
            name: "Recorder",
            grow: 2,
            cell: (row) => (
              <RecorderTitle
                mergedSystemName={row.mergedSystemName}
                recorderName={row.recorderName}
              />
              // <s.AccountBox>
              //   {row.mergedSystemName}
              // </s.AccountBox>
            ),
          },
          {
            name: (
              <TableColumnFilterTitle
                title="Status"
                filters={ActiveAlertStatusTypeFilter}
                onClick={onClickAlertStatusType}
              />
            ),
            grow: 1,
            cell: (row) => (
              <StatusTxt string={row.status.toUpperCase()}>
                {row.status}
              </StatusTxt>
            ),
          },
          {
            name: "Alert",
            grow: 2,
            selector: (row) => displayAlert(row),
          },
          {
            id: "alertTrigger",
            name: "Date",
            grow: 1.5,
            sortable: true,
            sortField: "alert_trigger",
            selector: (row) =>
              row.alertTrigger !== undefined
                ? getRecorderTimeFormat(
                    row.alertTrigger,
                    selectedAccount,
                    row.timezone,
                    true
                  )
                : "",
          },
          {
            name: "Assignee",
            grow: 1,
            selector: (row) => row.assignee,
          },
          {
            name: "",
            minWidth: "175px",
            grow: 2,
            cell: (row) => (
              <s.Row>
                {/* <span className="row-click-button"> */}
                <TableButton
                  buttonType="view"
                  label="View Alerts"
                  onClickBtn={() => onRowClick(row)}
                  // disabled={
                  //   row.systemCount + row.diskCount + row.videoCount === 0
                  //     ? true
                  //     : false
                  // }
                  // onClickBtn={() => onModal("view")}
                />
                {/* </span> */}
              </s.Row>
            ),
            right: true,
          },
        ];

  // disabled
  // const checkDisabled = (row: AlertRuleListResponse) => {
  //   return (row.systemCount !== undefined ? row.systemCount : 0) +
  //     (row.diskCount !== undefined ? row.diskCount : 0) +
  //     (row.videoCount !== undefined ? row.videoCount : 0) ===
  //     0
  //     ? true
  //     : false;
  // };
  const onRowClick = (row: ActiveAlert) => {
    // console.log(row.storageName, row.cameraName, row.cameraId, "rwo ---");
    onModal("view");
    setSelectedAlert({
      type: row.recorderType,
      recorderId: row.recorderId,
      recorderName: row.recorderName,
      mergedSystemName: row.mergedSystemName,
      mergedSystemId: row.mergedSystemId,
      alertType: row.alertTypeString,
      camera: {
        channel: row.cameraChannel,
        cameraName: row.cameraName,
        cameraId: row.cameraId,
      },
      storageName: row.storageName,
      category: row.type,
    });
  };

  const onModal = (type?: string) => {
    if (type === "add") {
      if (isAddModal) {
        queryClient.invalidateQueries("alertRule");
      }
      setIsAddModal(!isAddModal);
    }
    if (type === "edit") {
      if (isEditModal) {
        queryClient.invalidateQueries("alertRule");
      }
      setIsEditModal(!isEditModal);
    }
    if (type === "view") {
      setIsViewModal(!isViewModal);
    }
  };

  const { error, refetch } = useQuery(
    ["activeAlert", alertActiveQueryInfo],
    () =>
      getAlertActiveList({
        accountId: selectedAccount.accountId,
        payload: alertActiveQueryInfo,
      }),
    {
      retry: 0,
      refetchOnWindowFocus: false,
      onSuccess: (res: GetAlertActiveListResponse) => {
        if (res.error !== 0 && res.result?.alerts === undefined) {
          setAlertList([]);
          setTotalCount(0);
          setAlertSummary(InitialAlertSummary);
        } else {
          setAlertSummary(res.result as AlertActiveSummary);
          setAlertList(res.result.alerts);
          setTotalCount(res.page.total);
        }
      },
      onError: (err: any) => {
        setAlertList([]);
        setTotalCount(0);
        setAlertSummary(InitialAlertSummary);
        notify(
          "error",
          intl.formatMessage({
            id: "label.alert.notify.list.error",
            defaultMessage: "Error while searching for alert",
          })
        );
      },
      onSettled: (e: any) => {
        setPending(false);
      },
    }
  );

  const onClickSearch = useCallback(
    (keyword: string) => {
      if (alertActiveQueryInfo.keyword !== keyword) {
        queryNavigate(
          navigate,
          location.pathname,
          {
            pageLimit: alertActiveQueryInfo.pageLimit,
            pageNum: 0,
            keyword: keyword,
            sortType: alertActiveQueryInfo.sortType,
            sortDirection: alertActiveQueryInfo.sortDirection as SORT_DIRECTION,
          },
          selectedAccount
        );
      } else {
        refetch();
      }
      setPending(true);
    },
    [
      alertActiveQueryInfo.keyword,
      alertActiveQueryInfo.pageLimit,
      alertActiveQueryInfo.sortDirection,
      alertActiveQueryInfo.sortType,
      location.pathname,
      navigate,
      refetch,
      selectedAccount,
    ]
  );

  const onChangePage = useCallback(
    (page: number, totalRows: number) => {
      if (page !== alertActiveQueryInfo.pageNum + 1) {
        queryNavigate(
          navigate,
          location.pathname,
          {
            pageLimit: alertActiveQueryInfo.pageLimit,
            pageNum: page - 1,
            keyword: alertActiveQueryInfo.keyword as string,
            sortType: alertActiveQueryInfo.sortType,
            sortDirection: alertActiveQueryInfo.sortDirection as SORT_DIRECTION,
          },
          selectedAccount
        );
        setPending(true);
      }
    },
    [
      alertActiveQueryInfo.keyword,
      alertActiveQueryInfo.pageLimit,
      alertActiveQueryInfo.pageNum,
      alertActiveQueryInfo.sortDirection,
      alertActiveQueryInfo.sortType,
      location.pathname,
      navigate,
      selectedAccount,
    ]
  );

  const handleSort = useCallback(
    (selectedColumn: TableColumn<any>, sortDirection: SortOrder) => {
      if (selectedColumn.sortField !== undefined) {
        queryNavigate(
          navigate,
          location.pathname,
          {
            pageLimit: alertActiveQueryInfo.pageLimit,
            pageNum: alertActiveQueryInfo.pageNum,
            keyword: alertActiveQueryInfo.keyword as string,
            sortType: selectedColumn.sortField as string,
            sortDirection: sortDirection.toUpperCase() as SORT_DIRECTION,
          },
          selectedAccount
        );
        //setPending(true);
      }
    },
    [
      alertActiveQueryInfo.keyword,
      alertActiveQueryInfo.pageLimit,
      alertActiveQueryInfo.pageNum,
      location.pathname,
      navigate,
      selectedAccount,
    ]
  );

  return (
    <AlertPresenter
      columns={columns}
      //mobileColumns={mobileColumns}
      onModal={onModal}
      isAddModal={isAddModal}
      isEditModal={isEditModal}
      isViewModal={isViewModal}
      status={status}
      data={alertList}
      onRowClick={onRowClick}
      paginationPerPage={alertActiveQueryInfo.pageLimit}
      paginationInfo={alertActiveQueryInfo}
      onChangePage={onChangePage}
      handleSort={handleSort}
      paginationTotalRows={totalCount}
      onClickSearch={onClickSearch}
      selectedAccount={selectedAccount}
      isRowClickEditable={isRowClickEditable}
      initialPending={pending}
      selectedAlert={selectedAlert}
    />
  );
}
