import { ACCESS_TOKEN, AxiosInstance } from "api/axiosInstance";
import { ProfileAccountInfo } from "api/interfaces/accountInterface.interface";
import { LEVEL_TYPE } from "api/interfaces/commonInterface.interface";
import { PreferenceStep } from "api/interfaces/reportInterface.interface";
import { UserInfo } from "api/interfaces/userInterface.interface";
import { getRecorder } from "api/recorderAPI";
import { getReportPreferenceInfo } from "api/reportAPI";
import { getItemFromLocalStorage, useLocalStorage } from "hook/useLocalStorage";
import {
  useState,
  createContext,
  useContext,
  useEffect,
  useCallback,
  useLayoutEffect,
} from "react";
import {
  Navigate,
  createSearchParams,
  useLocation,
  useNavigate,
} from "react-router-dom";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import {
  purgeAccount,
  setAccountSetting,
} from "redux/reducers/account/accountSlice";
import { clearPushBreadcrumbRoute } from "redux/reducers/breadcrumb/breadcrumb";
import { setMenu } from "redux/reducers/menu/menuSlice";
import { checkGroup, checkTrialShowMenu } from "utils/AccountUtil";
import { getCookie, removeCookie } from "cookies/cookie";
import { getStartPageUrl } from "utils/functions";
import { getUserDetail, getUserDetailAccount } from "api/userAPI";
import { GoPageNav, menuNavigation } from "utils/MenuUtil";
import { useQueryClient } from "react-query";
import { setMode } from "redux/reducers/theme/themeReducer";
import { setIsLogout } from "redux/reducers/auth/isAuth";
import { notify } from "./atoms/notification/Notification";
import { useIntl } from "react-intl";

type LoginFunc = (user: UserInfo) => void;
type UpdateProfileImageUrl = (url: string) => void;
type UpdateUserInfo = (user: UserInfo) => void;
type LogoutFunc = () => void;
type ProcessLinkNavigate = (
  toPathParam: string,
  toSearchParam: URLSearchParams,
  useInfo: UserInfo
) => void;
type LoginNavigator = (toPathParam: string, userInfo: UserInfo) => void;

const initialUserData: UserInfo = {
  userId: "",
  email: "",
  name: "",
  profileImg: "",
  isAccount: 0,
  totalAccount: 0,
  startPage: 1,
  theme: "dark",
  accounts: {
    accountId: "",
    accountName: "",
    accountLevel: LEVEL_TYPE.INTL,
    accountNumber: "",
    accountAdditionalNumber: "",
    parentAccountId: "",
    parentLevel: "",
    timezone: "",
    dateFormat: "",
    timeFormat: "",
    companyName: "",
    companyLocation: "",
    companyLogo: "",
    use2faAuth: false,
    userPermission: [],
    recorderPermission: [],
    description: "",
    isHidden: false,
    isSuspended: false,
    isFavorite: false,
  },
};

const AuthContext = createContext<{
  user: UserInfo;
  login: LoginFunc;
  logout: LogoutFunc;
  updateUserInfo: UpdateUserInfo;
  updateProfileImageUrl: UpdateProfileImageUrl;
  processLinkNavigate: ProcessLinkNavigate;
  updateTotalAccount: (count: number) => void;
  loginNavigator: LoginNavigator;
}>({
  user: initialUserData,
  login: () => {},
  logout: () => {},
  updateUserInfo: () => {},
  updateProfileImageUrl: () => {},
  processLinkNavigate: () => {},
  updateTotalAccount: () => {},
  loginNavigator: () => {},
});

// TODO 새로고침 후에도 로그인 유지하기 위한 전역관리 필요. Token도 같은 문제.
export const AuthProvider = ({ children }: { children: JSX.Element }) => {
  const [user, setUser] = useState<UserInfo>(initialUserData);
  const intl = useIntl();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const { pathname, search } = useLocation();
  const [userLogin, setUserLogin] = useLocalStorage("users", "");

  const selectedAccount: ProfileAccountInfo = useAppSelector(
    (state) => state.accountSettings
  );

  const toPathParam = pathname;
  const toSearchParam = new URLSearchParams(search);
  const email = decodeURIComponent(search.split("email=")[1] || "");

  useEffect(() => {
    (() => {
      window.addEventListener("storage", changeStorageEvent);
    })();
    return () => {
      window.removeEventListener("storage", changeStorageEvent);
    };
  });

  const changeStorageEvent = useCallback(
    async (ev: StorageEvent) => {
      if (ev.key === "users" && ev.newValue) {
        const change = JSON.parse(JSON.parse(ev.newValue)) as UserInfo;
        if (
          change !== undefined &&
          change.email !== undefined &&
          change.email !== user.email
        ) {
          console.log("persistor purge called");
          await dispatch(purgeAccount("PURGE"));
          navigate("/login", { replace: true });
        }
      }
    },
    [dispatch, navigate, user.email]
  );

  const processLinkNavigate = (
    toPathParam: string,
    toSearchParam: URLSearchParams,
    userInfo: UserInfo
  ) => {
    if (toPathParam.includes("alert")) {
      const accountId = toSearchParam.get("accountId") as string;
      const recorderId = toSearchParam.get("recorderId") as string;
      getRecorder({ accountId, recorderId })
        .then((response) => {
          if (response.error === 0 && response.result !== undefined) {
            dispatch(setMenu("Alert"));
            if (
              response.result.mergedCount === undefined ||
              response.result.mergedCount === 1
            ) {
              navigate(
                {
                  pathname: `/alert/history/detail/${recorderId}`,
                  search: `?${createSearchParams({
                    account: accountId,
                  })}`,
                },
                {
                  replace: true,
                }
              );
            } else {
              navigate(
                {
                  pathname: `/alert/history/detail/${recorderId}`,
                  search: `?${createSearchParams({
                    account: accountId,
                  })}`,
                },
                {
                  replace: true,
                }
              );
            }
          } else {
            dispatch(setMenu("Dashboard"));
            menuNavigation(accountId, navigate, "/dashboard/map", true);
            //navigate("/dashboard/map", { replace: true });
          }
        })
        .catch((e) => {
          dispatch(setMenu("Dashboard"));
          menuNavigation(accountId, navigate, "/dashboard/map", true);
          //navigate("/dashboard/map", { replace: true });
        });
    } else if (toPathParam.includes("report")) {
      const accountId = toSearchParam.get("accountId") as string;
      const reportId = toSearchParam.get("reportId") as string;
      getReportPreferenceInfo({ accountId, reportId })
        .then((referenceResponse) => {
          if (
            referenceResponse.error === 0 &&
            referenceResponse.result !== undefined
          ) {
            const preference = referenceResponse.result as PreferenceStep;
            console.log(preference);
            dispatch(setMenu("Reports"));
            navigate(
              {
                pathname: toPathParam,
                search: `?${createSearchParams({
                  account: accountId,
                })}`,
              },
              {
                replace: true,
              }
            );
          } else {
            menuNavigation(accountId, navigate, "/dashboard/map", true);
            //navigate("/dashboard/map", { replace: true });
          }
        })
        .catch((error) => {
          dispatch(setMenu("Dashboard"));
          menuNavigation(accountId, navigate, "/dashboard/map", true);
          //navigate("/dashboard/map", { replace: true });
        });
    } else if (toPathParam.includes("videoShare")) {
      dispatch(setMenu("Video Share"));
      navigate(toPathParam);
    } else if (toPathParam.includes("billing")) {
      const accountId = toSearchParam.get("accountId") as string;
      const requestAccountId = toSearchParam.get("requestAccountId") as string;
      const requestAccountName = toSearchParam.get(
        "requestAccountName"
      ) as string;
      dispatch(setMenu("Billing"));
      GoPageNav(
        navigate,
        dispatch,
        `/billing/license?${createSearchParams({ account: accountId })}`,
        {
          state: {
            accountId: requestAccountId,
            name: requestAccountName,
          },
          replace: true,
        }
      );
    } else if (toPathParam.includes("license")) {
      let requestAccountId = toSearchParam.get("accountId") as string;
      if (!requestAccountId) {
        const tempAccountId = toSearchParam.get("url") as string;
        if (tempAccountId) {
          const index = tempAccountId.indexOf("account=");
          if (index !== -1) {
            requestAccountId = tempAccountId.substring(
              index + 8,
              tempAccountId.length
            );
          }
        }
      }
      const requestAccountName = toSearchParam.get(
        "requestAccountName"
      ) as string;
      menuNavigation(requestAccountId, navigate, "/settings/license", true, {
        accountId: requestAccountId,
        name: requestAccountName,
      });
      // GoPageNav(
      //   navigate,
      //   dispatch,
      //   `/settings/license?${createSearchParams({
      //     account: requestAccountId,
      //   })}`,
      //   {
      //     state: {
      //       accountId: requestAccountId,
      //       name: requestAccountName,
      //     },
      //     replace: true,
      //   }
      // );
    } else {
      // dispatch(setMenu("Dashboard"));
      // navigate("/dashboard/map", { replace: true });
      loginNavigator("/", userInfo);
    }
  };

  const loginNavigator = (toPathParam: string, userInfo: UserInfo) => {
    if (toPathParam === "/" || toPathParam === "/login") {
      const isAdmin = checkGroup(
        userInfo.accounts.userPermission,
        "administrator"
      );
      if (checkTrialShowMenu(userInfo.accounts)) {
        dispatch(setMenu("Recorders"));
        menuNavigation(
          userInfo.accounts.accountId,
          navigate,
          getStartPageUrl(5),
          true
        );
        // navigate( `${getStartPageUrl(5)}/${userInfo.accounts.accountId}`, {
        //   replace: true,
        // });
      } else if ([1, 2, 3].includes(userInfo.startPage)) {
        if (selectedAccount.accountLevel === LEVEL_TYPE.DW) {
          if (selectedAccount.isAdmin) {
            if (userInfo.startPage === 1) {
              dispatch(setMenu("Dashboard"));
              menuNavigation(
                userInfo.accounts.accountId,
                navigate,
                getStartPageUrl(3),
                true
              );
            } else {
              dispatch(setMenu("Dashboard"));
              menuNavigation(
                userInfo.accounts.accountId,
                navigate,
                getStartPageUrl(userInfo.startPage),
                true
              );
            }
          } else {
            dispatch(setMenu("Dashboard"));
            menuNavigation(
              userInfo.accounts.accountId,
              navigate,
              getStartPageUrl(2),
              true
            );
          }
          // dispatch(setMenu("Dashboard"));
          // menuNavigation(
          //   userInfo.accounts.accountId,
          //   navigate,
          //   getStartPageUrl(3),
          //   true
          // );
          // navigate(getStartPageUrl(3), {
          //   replace: true,
          // });
        } else if (
          (selectedAccount.accountLevel === LEVEL_TYPE.CP ||
            selectedAccount.accountLevel === LEVEL_TYPE.EU) &&
          !isAdmin &&
          userInfo.startPage === 3
        ) {
          dispatch(setMenu("Dashboard"));
          menuNavigation(
            userInfo.accounts.accountId,
            navigate,
            getStartPageUrl(1),
            true
          );
          // navigate(getStartPageUrl(1), {
          //   replace: true,
          // });
        } else {
          dispatch(setMenu("Dashboard"));
          menuNavigation(
            userInfo.accounts.accountId,
            navigate,
            getStartPageUrl(userInfo.startPage),
            true
          );
          // navigate(getStartPageUrl(userInfo.startPage), {
          //   replace: true,
          // });
        }
      } else if (userInfo.startPage === 4) {
        if (userInfo.accounts.accountLevel !== LEVEL_TYPE.EU) {
          dispatch(setMenu("Organization"));
          menuNavigation(
            userInfo.accounts.accountId,
            navigate,
            getStartPageUrl(userInfo.startPage),
            true
          );
          // navigate(
          //   `${getStartPageUrl(userInfo.startPage)}/${
          //     userInfo.accounts.accountId
          //   }`,
          //   {
          //     replace: true,
          //   }
          // );
        } else {
          dispatch(setMenu("Dashboard"));
          menuNavigation(
            userInfo.accounts.accountId,
            navigate,
            "/dashboard/map"
          );
          //navigate("/dashboard/map", { replace: true });
        }
      } else if (userInfo.startPage === 5) {
        if (selectedAccount.accountLevel === LEVEL_TYPE.EU) {
          dispatch(setMenu("Recorders"));
          menuNavigation(
            userInfo.accounts.accountId,
            navigate,
            getStartPageUrl(5),
            true
          );
          // navigate(`${getStartPageUrl(5)}/${userInfo.accounts.accountId}`, {
          //   replace: true,
          // });
        } else {
          dispatch(setMenu("Dashboard"));
          menuNavigation(
            userInfo.accounts.accountId,
            navigate,
            "/dashboard/map"
          );
          //navigate("/dashboard/map", { replace: true });
        }
      }
    }
  };

  useEffect(() => {
    const loadUser = async () => {
      return (await getCookie(ACCESS_TOKEN)) !== undefined
        ? getItemFromLocalStorage("users")
        : undefined;
      //return await localStorage.getItem("users");
    };

    delete AxiosInstance.defaults.headers.common["Authorization"];
    if (
      pathname !== "/reset" &&
      pathname !== "/signup" &&
      pathname !== "/guest/video/player"
    ) {
      loadUser().then((user) => {
        if (user) {
          const loadUser = JSON.parse(user) as UserInfo;
          //console.log("user information load from localStorage");
          const linkEmail = email;
          let accountId = toSearchParam.get("accountId") as string;
          if (!accountId) {
            const tempAccountId = toSearchParam.get("url") as string;
            if (tempAccountId) {
              const index = tempAccountId.indexOf("account=");
              if (index !== -1) {
                accountId = tempAccountId.substring(
                  index + 8,
                  tempAccountId.length
                );
              }
            }
          }
          //console.log(accountId, "accountId");
          if (linkEmail) {
            //링크메일의 이메일이 존재하는 경우
            if (linkEmail === loadUser.email) {
              //링크이메일과 로그인 아이디가 같은 경우
              getUserDetailAccount(accountId)
                .then((response) => {
                  //Account Setting 후 Redirect
                  if (response.accounts !== undefined) {
                    login(response);
                    if (toSearchParam.get("url") !== null) {
                      processLinkNavigate(
                        toSearchParam.get("url") as string,
                        toSearchParam,
                        loadUser
                      );
                    } else {
                      //링크이메일이 아닌경우
                      loginNavigator(toPathParam, response);
                    }
                  } else {
                    // notify(
                    //   "error",
                    //   intl.formatMessage({
                    //     id: "label.auth.link.notAvailable",
                    //     defaultMessage: "The linked information is no longer valid.",
                    //   })
                    // );
                    navigate("/error");
                  }
                })
                .catch((error) => {
                  navigate("/error");
                  // navigate("/login", {
                  //   state: { pathname: pathname, search: search },
                  //   replace: true,
                  // });
                });
            } else {
              //링크이메일과 로그인 아이디가 다른경우 경우
              navigate("/login", {
                state: { pathname: pathname, search: search },
                replace: true,
              });
            }
          } else {
            if (selectedAccount.accountId === "") {
              const accountId = toSearchParam.get("accountId") as string;
              if (toSearchParam.size !== 0) {
                loginNavigator(toPathParam, loadUser);
                // navigate("/login", {
                //   state: { pathname: pathname, search: search },
                //   replace: true,
                // });
              } else {
                getUserDetail()
                  .then((response) => {
                    console.log(response);
                    login(response);
                    loginNavigator(toPathParam, response);
                  })
                  .catch((error) => {
                    navigate("/login", {
                      state: { pathname: pathname, search: search },
                      replace: true,
                    });
                  });
              }
              navigate("/login", {
                state: { pathname: pathname, search: search },
                replace: true,
              });
            } else {
              //nothing (refresh)
              setUser(loadUser);
            }
            // if(selectedAccount.accountId === ""){
            //   const accountId = toSearchParam.get("accountId") as string;
            //   if (toSearchParam.size !== 0) {
            //     getUserDetailAccount(accountId).then((response) => { //Account Setting 후 Redirect
            //       login(response);
            //       if (toSearchParam.size !== 0) {
            //         processLinkNavigate(toPathParam,toSearchParam);
            //       }else{  //링크이메일이 아닌경우

            //         loginNavigator(toPathParam, response);
            //       }
            //     }).catch((error)=>{
            //       navigate("/login", { state :{ pathname :pathname, search:search} , replace: true });
            //     })
            //   }else{
            //     getUserDetail().then((response)=>{
            //       login(response);
            //       loginNavigator(toPathParam, response);
            //     }).catch((error)=>{
            //       navigate("/login", { state :{ pathname :pathname, search:search} , replace: true });
            //     })
            //   }
            // }else{ //리프레쉬 케이스
            //   // getUserDetail().then((response)=>{
            //   //   login(response);
            //   //   loginNavigator(toPathParam, response);
            //   // }).catch((error)=>{
            //   //   navigate("/login", { state :{ pathname :pathname, search:search} , replace: true });
            //   // })
            // }
          }
        } else {
          navigate("/login", {
            state: { pathname: pathname, search: search },
            replace: true,
          });
          // if (toSearchParam.get("url") !== null) {
          //   navigate("/login", {
          //     state: { pathname: toSearchParam.get("url") as string, search: toSearchParam},
          //     replace: true,
          //   });
          // }else{
          //   navigate("/login", {replace: true});
          // }
          // navigate("/login", {
          //   state: { pathname: pathname, search: search },
          //   replace: true,
          // });
          //console.log("redirect /login");
          //navigate("/login", {replace: true});
        }
      });
    }
  }, []);

  const updateProfileImageUrl = (url: string) => {
    const updateObject = Object.assign({}, user);
    updateObject.profileImg = url;

    setUser((user) => {
      return { ...user, profileImg: url as string };
    });
    setUserLogin(JSON.stringify(updateObject));
  };

  const updateUserInfo = (user: UserInfo) => {
    setUser(user);
    setUserLogin(JSON.stringify(user));
    dispatch(setMode(user.theme));
  };

  const updateTotalAccount = (count: number) => {
    setUser((user) => {
      return { ...user, totalAccount: count };
    });
  };

  const login: LoginFunc = async (user: UserInfo) => {
    dispatch(setMode(user.theme));
    setUser(user);
    setUserLogin(JSON.stringify(user));
    //sessionStorage.setItem("users", JSON.stringify(user));
    dispatch(setAccountSetting(user.accounts));
    dispatch(
      clearPushBreadcrumbRoute({
        name: user.accounts.accountName,
        accountId: user.accounts.accountId,
      })
    );
  };

  const delay = (milliseconds: number) => {
    return new Promise((resolve) => {
      setTimeout(resolve, milliseconds);
    });
  };
  const logout: LogoutFunc = async () => {
    dispatch(setIsLogout());
    await delay(400);
    try {
      const resp = await AxiosInstance.get(
        `/auth/logout/${selectedAccount.accountId}`
      );
    } catch (error) {}

    sessionStorage.clear();
    setUser(initialUserData);
    dispatch(purgeAccount("PURGE"));
    removeCookie(ACCESS_TOKEN);
    window.location.replace("/login");
  };

  return (
    <AuthContext.Provider
      value={{
        user,
        login,
        logout,
        updateUserInfo,
        updateProfileImageUrl,
        updateTotalAccount,
        processLinkNavigate,
        loginNavigator,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  return useContext(AuthContext);
};
