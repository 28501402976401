import * as s from "./SystemSpecStyled";
import { TableColumn } from "react-data-table-component";
import { ReportTable } from "components/atoms/table/Table";
import { useState } from "react";
import { useQuery } from "react-query";
import {
  RecorderHealthRow,
  SpectrumLicense,
  SpectrumSystemSpec,
  SpectrumSystemStatus,
  SpectrumSystemStatusRes,
  SystemUsage,
  SystemUsageRes,
} from "api/interfaces/reportInterface.interface";
import {
  getSpectrumStats,
  getSpectrumStatus,
  getSystemUsage,
} from "api/reportAPI";
import moment from "moment";
import { useAppSelector } from "redux/hooks";
import { ProfileAccountInfo } from "api/interfaces/accountInterface.interface";
import { formatBytes, getGBFromByteString } from "utils/functions";
import { useLocation } from "react-router-dom";
import GuageList, { GuageListInterface } from "./GuageList";
import Spinner from "components/atoms/loader/Spinner";
import Storage from "../Storage";
import { COLORS } from "styles/colors";
import NoData from "components/templates/nopage/NoData";

interface ActiveLicense {
  type: string;
  channels: string;
  licenseKey: string;
  expires: string;
  status: string;
  isValid: boolean;
}

type Props = {
  recorderId: string;
};

const initSystemUsage = {
  cpu: 0,
  memory: 0,
  network: 0,
};

const licenseInfoInitial = {
  parameters: {
    cpuArchitecture: "",
    cpuModelName: "",
    networkInterfaces: "",
    physicalMemory: "",
  },
  osInfo: {
    platform: "",
    variant: "",
    variantVersion: "",
  },
};

enum replaceTxtObj {
  iomodule = "I/O Module",
  videowall = "Video Wall",
  digital1 = "Professional",
  digital2 = "Time Limit",
  edge = "Edge",
}

export default function SystemSpectrumSpec(props: Props): JSX.Element {
  const location = useLocation();
  const isMap = location.pathname.includes("map");
  const selectedAccount: ProfileAccountInfo = useAppSelector(
    (state) => state.accountSettings
  );

  // const [licenseDetail, setLicenseDetail] =
  //   useState<SpectrumSystemSpec>(licenseInfoInitial);
  // const [licenseList, setLicenseList] = useState<ActiveLicense[]>([]);

  const licenseColumns: TableColumn<ActiveLicense>[] = [
    {
      name: "Type",
      selector: (row) => row.type,
      grow: 1.5,
    },
    {
      name: "Channels",
      selector: (row) => row.channels,
      grow: 1,
      center: true,
    },
    {
      name: "License Key",
      selector: (row) => row.licenseKey,
      grow: 2,
    },
    {
      name: "Expires",
      selector: (row) => row.expires,
      grow: 3,
    },
    {
      name: "Status",
      selector: (row) => row.status,
      grow: 1,
      conditionalCellStyles: [
        {
          when: (row) => row.status === "OK",
          style: {
            color: COLORS.GREEN,
          },
        },
        {
          when: (row) => row.status === "Expires soon",
          style: {
            color: COLORS.WARNING,
          },
        },
        {
          when: (row) => row.status === "Expired",
          style: {
            color: COLORS.VALIDATERED,
          },
        },
        {
          when: (row) => row.status === "ERROR",
          style: {
            color: COLORS.RED,
          },
        },
      ],
    },
  ];

  // const cameraColumns: TableColumn<CameraStatus>[] = [
  //   {
  //     name: "Camera Name",
  //     selector: (row) => row.cameraName,
  //   },
  //   {
  //     name: "Status",
  //     selector: (row) => row.status,
  //   },
  // ];

  // const [usageData, setUsageData] = useState<SystemUsage>(initSystemUsage);

  const spectrumStatsQuery = useQuery(
    ["spectrumStatQuery", props.recorderId],
    () =>
      getSpectrumStats({
        recorderId: props.recorderId,
      }),
    {
      retry: 0,
      staleTime: 30000, // 30초
      cacheTime: 60000, // 1분
      refetchInterval: 30000, // 30초마다 refetch
      refetchOnWindowFocus: false,
      onSuccess: (res: SpectrumSystemStatusRes) => {},
      onError: (e: any) => {},
    }
  );

  const spectrumStatusQuery = useQuery(
    ["spectrumStatusQuery", props.recorderId],
    () =>
      getSpectrumStatus({
        recorderId: props.recorderId,
      }),
    {
      retry: 0,
      staleTime: 30000, // 30초
      cacheTime: 60000, // 1분
      //refetchInterval: 30000, // 30초마다 refetch
      refetchOnWindowFocus: false,
      onSuccess: (res: SpectrumSystemStatusRes) => {
        // if (res.error !== 0 || res.result === undefined) {
        //   // setLicenseDetail(licenseInfoInitial);
        //   // setLicenseList([]);
        //   return;
        // }
        // // setLicenseDetail(res.result.systemSpec);
        // if (res.result.licenses) {
        //   setLicenseList(
        //     res.result.licenses.map((el) => {
        //       let time = moment.utc(el.expiration).tz(selectedAccount.timezone);
        //       let now = moment.utc().tz(selectedAccount.timezone);
        //       let time2 = isMap
        //         ? time.format("MM/DD/YYYY")
        //         : time.format("MM/DD/YYYY hh:mm:ss A z");
        //       let typeStr: string =
        //         el.class !== "digital"
        //           ? replaceTxtObj[el.class as keyof typeof replaceTxtObj]
        //           : el.expiration === ""
        //           ? replaceTxtObj[
        //               (el.class + "1") as keyof typeof replaceTxtObj
        //             ]
        //           : replaceTxtObj[
        //               (el.class + "2") as keyof typeof replaceTxtObj
        //             ];
        //       if (typeof typeStr === "undefined" || typeStr === undefined) {
        //         typeStr = el.class.charAt(0).toUpperCase() + el.class.slice(1);
        //       }
        //       return {
        //         type: typeStr,
        //         channels: el.count,
        //         licenseKey: el.serial,
        //         expires: time2 === "Invalid date" ? "Never" : time2,
        //         status:
        //           time2 === "Invalid date"
        //             ? "OK"
        //             : time.diff(now) > 0
        //             ? "OK"
        //             : "Expired",
        //       };
        //     })
        //   );
        // }
        // setUsageData({
        //   cpu: res.result.statistics.cpu * 100,
        //   memory: res.result.statistics.ram * 100,
        //   network: res.result.statistics.network * 100,
        // });
      },
      onError: (e: any) => {
        // setLicenseDetail(licenseInfoInitial);
        // setLicenseList([]);
        // setUsageData(initSystemUsage);
      },
    }
  );

  const queryData = spectrumStatusQuery.data?.result;
  const licenseDetail = queryData?.systemSpec
    ? queryData.systemSpec
    : licenseInfoInitial;
  const licenseList = queryData?.licenses
    ? queryData.licenses.map((el: SpectrumLicense) => {
        let time = moment.utc(el.expiration).tz(selectedAccount.timezone);
        let now = moment.utc().tz(selectedAccount.timezone);
        let time2 = isMap
          ? time.format("MM/DD/YYYY")
          : time.format("MM/DD/YYYY hh:mm:ss A z");
        if(el.expiration === undefined || el.expiration === ""){
          time2 ="Invalid date"; //expiration이 존재하지 않는경우
        }
        let typeStr: string =
          el.class !== "digital"
            ? replaceTxtObj[el.class as keyof typeof replaceTxtObj]
            : el.expiration === undefined || el.expiration === ""
            ? replaceTxtObj[(el.class + "1") as keyof typeof replaceTxtObj]
            : replaceTxtObj[(el.class + "2") as keyof typeof replaceTxtObj];
        if (typeof typeStr === "undefined" || typeStr === undefined) {
          typeStr = el.class.charAt(0).toUpperCase() + el.class.slice(1);
        }
        return {
          type: typeStr,
          channels: el.count,
          licenseKey: el.serial,
          expires:
            el.expiration === undefined || el.expiration === ""
              ? "Never"
              : time2,
          status:
            el.expiration === undefined || el.expiration === "" ?
              el.isValid ==="true" ? "OK" : "Error"
            : el.isValid === "true" ? 
              time.diff(now) > 0 ?
                time.diff(now, `days`) > 30 ? "OK" : "Expires soon"
                : "Expired"
              : "Error",

            // el.isValid === true ?
            //   time2 === "Invalid date"
            //     ? "OK"
            //     : time.diff(now) > 0
            //       ? time.diff(now, `days`) > 30
            //         ? "OK"
            //         : "Expires soon"
            //     : "Expired"
            //   : el.isValid === false
            //   ? "Error"
            //   : time2 === "Invalid date"
            //     ? "OK"
            //     : time.diff(now) > 0
            //       ? time.diff(now, `days`) > 30
            //         ? "OK"  : "Expires soon"
            //   : "Expired",
          isValid: el.isValid,
        };
      })
    : [];

  const statData = spectrumStatsQuery.data?.result;

  const usageData = statData?.statistics
    ? {
        cpu: statData.statistics.cpu * 100,
        memory: statData.statistics.ram * 100,
        network: statData.statistics.network * 100,
      }
    : initSystemUsage;

  const guageList: GuageListInterface[] = [
    {
      title: "CPU",
      value: usageData.cpu,
    },
    {
      title: "Memory",
      value: usageData.memory,
    },
    {
      title: "Network",
      value: usageData.network,
    },
  ];

  return (
    <s.SpectrumInner isMap={isMap}>
      {spectrumStatusQuery.isFetching ? (
        <Spinner />
      ) : queryData ? (
        <>
          <div className="left inner-box">
            <s.TopContents>
              <li>
                <span className="title">CPU Type: </span>
                {licenseDetail.parameters.cpuArchitecture}
              </li>
              <li>
                <span className="title">CPU Model:</span>
                {licenseDetail.parameters.cpuModelName}
              </li>
              <li>
                <span className="title">OS: </span>
                {licenseDetail.osInfo.platform} / {licenseDetail.osInfo.variant}{" "}
                / {licenseDetail.osInfo.variantVersion}
              </li>
              <li>
                <span className="title">RAM:</span>
                {(licenseDetail.osInfo.platform as string)?.toLowerCase().includes("linux")  ?  getGBFromByteString(licenseDetail.parameters.physicalMemory): formatBytes(licenseDetail.parameters.physicalMemory, 0)}
              </li>
              <li>
                <span className="title">LAN Card:</span>
                {licenseDetail.parameters.networkInterfaces}
              </li>
            </s.TopContents>
            <s.SystemTable>
              <h5 className="title">Active Licenses</h5>
              {isMap ? (
                <s.ActiveLicenseMap>
                  {licenseList.map((license: ActiveLicense, index: number) => {
                    return (
                      <li key={`active-license-${index}`}>
                        <div className="type">{license.type}</div>
                        <div>
                          <span>{license.channels} Channels</span>
                          <span className="expire">
                            Expires: {license.expires}
                          </span>
                        </div>
                        <div className="licenseKey">
                          <span>{license.licenseKey}</span>
                        </div>
                      </li>
                    );
                  })}
                </s.ActiveLicenseMap>
              ) : (
                <ReportTable
                  data={licenseList}
                  columns={licenseColumns}
                  paginationPerPage={10}
                />
              )}
            </s.SystemTable>
            {/* <s.SystemTable>
          <h5 className="title">Camera Status</h5>
          <ReportTable
            data={cameraData}
            columns={cameraColumns}
            paginationPerPage={10}
          />
        </s.SystemTable> */}
          </div>

          <GuageList guageList={guageList} />
          {isMap && <Storage recorderId={props.recorderId} />}
        </>
      ) : (
        <NoData />
      )}
    </s.SpectrumInner>
  );
}
