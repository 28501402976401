import { IncreaseString } from "pages/dashboard/dashboard/item/card/CardItem";

export type ObjType = {
  [index: string]: string;
};

export const COLORS = {
  PRIMARY1: "#273457",
  PRIMARY2: "#141E2E",
  PRIMARY3: "#252E44",
  BUTTONS1: "#7B88A8",
  BUTTONS2: "#008AFF",
  SELECTED: "#2EE3FF",
  PROGRESSBAR: "#FB471F",
  WHITE: "#FFFFFF",
  BLACK: "#000000",
  RED: "#FF0000",
  SIDEBARBG: "#0A121E",
  COMPANY: "#ED7422",
  BORDER: "#535D76",
  TOGGLE: "#1C75C4",
  ORANGE: "#D55F1B",
  GREEN: "#00E286",
  VALIDATERED: "#FF2E2E",
  LIGHTGRAY: "#abb5c5",
  SIDEBARMENU: "#85898F",
  WARNING: "#FFB013",
};

export const LIGHT_THEME_COLORS = {
  BACKGROUND: COLORS.WHITE,
  SIDEBARBG: COLORS.WHITE,
  HEADERBG: COLORS.WHITE,
  LIGHTGRAYBORDER: "#EDEDED",
  LIGHTGRAY: "#F5F5F5",
  WHITE: "#000000",
  BUTTONS1: "#ff9800",
  BUTTONS2: "#ff9800",
  STATUS: "#44546F",
  FILTERITEM: "#f8f8f8",
  SELECTED: "#ff9800",
  // WIDGETCOLOR: "#FFFFFF",
  WIDGETCOLOR: "#F5F5F5",
  PRIMARY2: COLORS.WHITE,
  PRIMARY3: "#F5F5F5",
  BORDER: "#EDEDED",
};

export const DARK_THEME_COLORS = {
  BACKGROUND: COLORS.PRIMARY2,
  SIDEBARBG: COLORS.SIDEBARBG,
  HEADERBG: COLORS.PRIMARY3,
  STATUS: "#b5c5e1",
  FILTERITEM: "#20283b",
  WIDGETCOLOR: "#0c1724",
};

export const BACKGROUND_COLORS = [
  {
    Blue: COLORS.BUTTONS2,
  },
  { Green: COLORS.GREEN },
  { Red: COLORS.RED },
  { Purple: "#8000FF" },
  { Orange: COLORS.ORANGE },
];

export const NOTICE_COLORS = {
  BLUE: COLORS.BUTTONS2,
  GREEN: COLORS.GREEN,
  PURPLE: "#8000FF",
  ORANGE: COLORS.ORANGE,
  RED: COLORS.RED,
  COOKIE: "#1B2332",
  LIGHTCOOKIE: "#FFF6F0",
};
export const MAP_STATUS_COLORS: ObjType = {
  HEALTHY: "#0BA775",
  WARNING: COLORS.WARNING,
  ALERT: "#ff4800",
  ASSIGNED: COLORS.SELECTED,
  RESOLVED: COLORS.GREEN,
  CRITICAL: "#cc2904",
};

export const ALERT_CHART_COLORS: ObjType = {
  WARNING: COLORS.WARNING,
  ALERT: "#ff4800",
  ASSIGNED: "#0361F8",
  RESOLVED: COLORS.GREEN,
  CRITICAL: MAP_STATUS_COLORS.CRITICAL,
};

export const LEVELS: ObjType = {
  DW: COLORS.COMPANY,
  CP: "#8875FF",
  EU: "#336699",
};

export const INDECREASECOLORS: { [key in IncreaseString]: string } = {
  INCREASE: COLORS.GREEN,
  DECREASE: COLORS.PROGRESSBAR,
  NONE: COLORS.BUTTONS2,
};

export const statusToColor = (status: string) => {
  switch (status) {
    case "resolved":
      return COLORS.GREEN;
    case "RESOLVED":
      return COLORS.GREEN;
    case "alert":
      return COLORS.PROGRESSBAR;
    case "Active":
      return COLORS.PROGRESSBAR;
    case "active":
      return COLORS.PROGRESSBAR;
    case "ALERT":
      return COLORS.PROGRESSBAR;
    case "warning":
      return COLORS.WARNING;
    case "WARNING":
      return COLORS.WARNING;
    case "ASSIGNED":
      return COLORS.SELECTED;
    default:
      return COLORS.BUTTONS1;
  }
};
