import { Text } from "components/atoms/text/Text";
import BigPopupTemplate from "components/templates/modal/big/BigPopupTemplate";
import * as s from "../EmailStyled";
import { Email } from "api/interfaces/emailInterface.interface";

interface Props {
  onModal: (type?: string) => void;
  selectedEmail: Email;
}
export function ViewModal(props: Props): JSX.Element {
  const { selectedEmail } = props;

  return (
    <BigPopupTemplate title="Read Email" onModal={props.onModal}>
      <s.ViewInner>
        <Text fontSize={20} bold>
          {selectedEmail.subject}
        </Text>
        <s.ViewSubWrapper>
          <s.ViewSubTitle>
            <strong>From:</strong> DW Admin
            {/* dw admin ? */}
          </s.ViewSubTitle>
          <s.ViewSubTitle>
            <strong>To:</strong>{" "}
            {selectedEmail.recipientType === "USER"
              ? selectedEmail.users.map((user) => user.name).join(", ")
              : selectedEmail.recipientType}
          </s.ViewSubTitle>
        </s.ViewSubWrapper>
        <s.ViewSubTitle className="message">Message</s.ViewSubTitle>
        <p dangerouslySetInnerHTML={{ __html: selectedEmail.message }}></p>
      </s.ViewInner>
    </BigPopupTemplate>
  );
}
