import styled from "styled-components";
import { COLORS } from "styles/colors";
import { calcRem } from "styles/theme";
import { FLEX } from "styles/flex";
import SmallLabel from "components/atoms/text/labels/SmallLabel";
import TRIANGLE_WARNING_RED from "assets/icons/triangle_warning_red.svg";
import StorageBar from "./StorageBar";
import { calcStoragePercent } from "utils/functions";
import TinyButton from "components/atoms/buttons/TinyButton";

import { FaCartPlus } from "react-icons/fa";
import { useState } from "react";
import { AiOutlineSetting } from "react-icons/ai";
import RBACWrapper from "../function/RBACWrapper";
import { useAppSelector } from "redux/hooks";
import { ProfileAccountInfo } from "api/interfaces/accountInterface.interface";
import { LEVEL_TYPE } from "api/interfaces/commonInterface.interface";
import { useNavigate } from "react-router-dom";

export interface StorageProps {
  totalStorage: number;
  usedStorage: number;
}

const StorageLabel = ({ usedStorage, totalStorage }: StorageProps) => {
  const percentage = calcStoragePercent(usedStorage, totalStorage);
  const navigate = useNavigate();

  const selectedAccount: ProfileAccountInfo = useAppSelector(
    (state) => state.accountSettings
  );

  return (
    <>
      <Container>
        <SmallLabel
          label="STORAGE"
          color={COLORS.PRIMARY1}
          icon={percentage >= 90 ? TRIANGLE_WARNING_RED : null}
        />
        <div>
          <StorageBar totalStorage={totalStorage} usedStorage={usedStorage} />
        </div>

        {selectedAccount.accountLevel === LEVEL_TYPE.EU &&
          selectedAccount.isAdmin &&
          (selectedAccount.storageIsPreparing ? (
            <TinyButton
              label="Preparing"
              buttonType="save"
              icon={<AiOutlineSetting color={COLORS.WHITE} size={10} />}
              onClickBtn={() => navigate("/settings/license")}
            />
          ) : (
            percentage >= 90 && (
              <TinyButton
                label="Buy"
                buttonType="save"
                icon={<AiOutlineSetting color={COLORS.WHITE} size={10} />}
                onClickBtn={() => navigate("/settings/license")}
              />
            )
          ))}
      </Container>
    </>
  );
};

const Container = styled.div`
  ${FLEX.FlexStartCenter};
  gap: 6px;
  width: 100%;
  div {
    width: 100%;
    /* min-width: 250px; */
  }
`;

export default StorageLabel;
