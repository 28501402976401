import { AxiosResponse } from "axios";
import { AxiosInstance } from "./axiosInstance";
import qs from "qs";
import {
  BasePagination,
  CustomError,
} from "./interfaces/commonInterface.interface";
import * as widgetInterface from "./interfaces/widgetInterface.interface";
import { GetAlertActiveListResponse } from "./interfaces/alertInterface.interface";

const API_PATH = "/widget";

export const getWidgetAccountList = async ({
  accountId,
}: {
  accountId: string;
}): Promise<widgetInterface.AccountList> => {
  try {
    const { data }: AxiosResponse<widgetInterface.AccountList> =
      await AxiosInstance.get(`${API_PATH}/account/list/${accountId}`);
    return data;
  } catch (e) {
    console.error(e);
    throw new Error();
  }
};

// ----------- Card API
export const getWidgetCountRecorder = async ({
  accountId,
}: {
  accountId: string;
}): Promise<widgetInterface.GetWidgetRecorderResponse> => {
  try {
    const { data }: AxiosResponse<widgetInterface.GetWidgetRecorderResponse> =
      await AxiosInstance.get(`${API_PATH}/recorder/count/${accountId}`);
    return data;
  } catch (e) {
    console.error(e);
    throw new Error();
  }
};
export const getWidgetCountCamera = async ({
  accountId,
}: {
  accountId: string;
}): Promise<widgetInterface.GetWidgetRecorderResponse> => {
  try {
    const { data }: AxiosResponse<widgetInterface.GetWidgetRecorderResponse> =
      await AxiosInstance.get(`${API_PATH}/camera/count/${accountId}`);
    return data;
  } catch (e) {
    console.error(e);
    throw new Error();
  }
};

export const getWidgetCountAlert = async ({
  accountId,
}: {
  accountId: string;
}): Promise<widgetInterface.GetWidgetRecorderResponse> => {
  try {
    const { data }: AxiosResponse<widgetInterface.GetWidgetRecorderResponse> =
      await AxiosInstance.get(`${API_PATH}/alert/count/${accountId}`);
    return data;
  } catch (e) {
    console.error(e);
    throw new Error();
  }
};

export const getWidgetCountAccount = async ({
  accountId,
}: {
  accountId: string;
}): Promise<widgetInterface.GetWidgetRecorderResponse> => {
  try {
    const { data }: AxiosResponse<widgetInterface.GetWidgetRecorderResponse> =
      await AxiosInstance.get(`${API_PATH}/account/count/${accountId}`);
    return data;
  } catch (e) {
    console.error(e);
    throw new Error();
  }
};

// ------------- List API

export const getWidgetLoginHistory = async ({
  accountId,
  payload,
}: {
  accountId: string;
  payload: BasePagination;
}): Promise<widgetInterface.GetWidgetLoginHistoryResponse> => {
  try {
    const {
      data,
    }: AxiosResponse<widgetInterface.GetWidgetLoginHistoryResponse> = await AxiosInstance.get(
      `${API_PATH}/loginHistory/${accountId}?${qs.stringify(payload)}`
    );
    return data;
  } catch (e) {
    console.error(e);
    throw new Error();
  }
};

export const postWidgetListRecorder = async ({
  accountId,
  payload,
}: {
  accountId: string;
  payload: widgetInterface.WidgetPayloadType;
}): Promise<widgetInterface.PostWidgetRecorderListResponse> => {
  try {
    const {
      data,
    }: AxiosResponse<widgetInterface.PostWidgetRecorderListResponse> = await AxiosInstance.post(
      `${API_PATH}/recorder/list/${accountId}`,
      payload
    );
    return data;
  } catch (e) {
    console.error(e);
    throw new Error();
  }
};

export const postWidgetListAlert = async ({
  accountId,
  payload,
}: {
  accountId: string;
  payload: widgetInterface.WidgetPayloadType;
}): Promise<widgetInterface.PostWidgetAlertListResponse> => {
  try {
    const { data }: AxiosResponse<widgetInterface.PostWidgetAlertListResponse> =
      await AxiosInstance.post(`${API_PATH}/alert/list/${accountId}`, payload);
    return data;
  } catch (e) {
    console.error(e);
    throw new Error();
  }
};

export const postWidgetListAlertHistory = async ({
  accountId,
  payload,
}: {
  accountId: string;
  payload: widgetInterface.WidgetPayloadType;
}): Promise<widgetInterface.PostWidgetAlertHistoryListResponse> => {
  try {
    const {
      data,
    }: AxiosResponse<widgetInterface.PostWidgetAlertHistoryListResponse> = await AxiosInstance.post(
      `${API_PATH}/alert/history/list/${accountId}`,
      payload
    );
    return data;
  } catch (e) {
    console.error(e);
    throw new Error();
  }
};

export const postWidgetInventoryRecorder = async ({
  accountId,
  payload,
}: {
  accountId: string;
  payload: widgetInterface.WidgetPayload;
}): Promise<widgetInterface.PostWidgetAlertListResponse> => {
  try {
    const { data }: AxiosResponse<widgetInterface.PostWidgetAlertListResponse> =
      await AxiosInstance.post(
        `${API_PATH}/inventory/recorder/list/${accountId}`,
        payload
      );
    return data;
  } catch (e) {
    console.error(e);
    throw new Error();
  }
};
export const postWidgetInventoryCamera = async ({
  accountId,
  payload,
}: {
  accountId: string;
  payload: widgetInterface.WidgetPayloadType;
}): Promise<widgetInterface.PostWidgetAlertListResponse> => {
  try {
    const { data }: AxiosResponse<widgetInterface.PostWidgetAlertListResponse> =
      await AxiosInstance.post(
        `${API_PATH}/inventory/camera/list/${accountId}`,
        payload
      );
    return data;
  } catch (e) {
    console.error(e);
    throw new Error();
  }
};

export const postWidgetCvvDetection = async ({
  accountId,
  payload,
}: {
  accountId: string;
  payload: widgetInterface.WidgetPayloadType;
}): Promise<widgetInterface.PostWidgetCvvDetectionResponse> => {
  try {
    const {
      data,
    }: AxiosResponse<widgetInterface.PostWidgetCvvDetectionResponse> = await AxiosInstance.post(
      `${API_PATH}/cvvDetection/${accountId}`,
      payload
    );
    return data;
  } catch (e) {
    console.error(e);
    throw new Error();
  }
};

/**
 *
 * stats API
 *
 */

export const postStatCloudStorage = async ({
  accountId,
  interval,
  payload,
}: {
  accountId: string;
  interval: widgetInterface.GetStatInterval;
  payload: widgetInterface.WidgetStatsPayload;
}): Promise<widgetInterface.GetWidgetCloudStorageResponse> => {
  try {
    const {
      data,
    }: AxiosResponse<widgetInterface.GetWidgetCloudStorageResponse> = await AxiosInstance.post(
      `${API_PATH}/stats/storage/${accountId}/${interval}`,
      payload
    );
    return data;
  } catch (e) {
    console.error(e);
    throw e;
  }
};
export const getStatRecorder = async ({
  accountId,
  interval,
}: {
  accountId: string;
  interval: widgetInterface.GetStatInterval;
}): Promise<widgetInterface.GetWidgetStatsResponse> => {
  try {
    const { data }: AxiosResponse<widgetInterface.GetWidgetStatsResponse> =
      await AxiosInstance.get(
        `${API_PATH}/stats/recorder/${accountId}/${interval}`
      );
    return data;
  } catch (e) {
    console.error(e);
    throw e;
  }
};
export const getStatCamera = async ({
  accountId,
  interval,
}: {
  accountId: string;
  interval: widgetInterface.GetStatInterval;
}): Promise<widgetInterface.GetWidgetStatsResponse> => {
  try {
    const { data }: AxiosResponse<widgetInterface.GetWidgetStatsResponse> =
      await AxiosInstance.get(
        `${API_PATH}/stats/camera/${accountId}/${interval}`
      );
    return data;
  } catch (e) {
    console.error(e);
    throw e;
  }
};

export const getStatAlert = async ({
  accountId,
  interval,
}: {
  accountId: string;
  interval: widgetInterface.GetStatInterval;
}): Promise<widgetInterface.GetWidgetStatsResponse> => {
  try {
    const { data }: AxiosResponse<widgetInterface.GetWidgetStatsResponse> =
      await AxiosInstance.get(
        `${API_PATH}/stats/alert/${accountId}/${interval}`
      );
    return data;
  } catch (e) {
    console.error(e);
    throw e;
  }
};

export const postStatCvv = async ({
  accountId,
  interval,
  payload,
}: {
  accountId: string;
  interval: widgetInterface.GetStatInterval;
  payload: widgetInterface.WidgetStatsPayload;
}): Promise<widgetInterface.GetWidgetCvvStatsResponse> => {
  try {
    const { data }: AxiosResponse<widgetInterface.GetWidgetCvvStatsResponse> =
      await AxiosInstance.post(
        `${API_PATH}/stats/cvv/${accountId}/${interval}`,
        payload
      );
    return data;
  } catch (e) {
    console.error(e);
    throw e;
  }
};

export const postStatInstalledRecorder = async ({
  accountId,
  interval,
  payload,
}: {
  accountId: string;
  interval: widgetInterface.GetStatInterval;
  payload: widgetInterface.WidgetStatsPayload;
}): Promise<widgetInterface.GetWidgetCvvStatsResponse> => {
  try {
    const { data }: AxiosResponse<widgetInterface.GetWidgetCvvStatsResponse> =
      await AxiosInstance.post(
        `${API_PATH}/stats/installed/recorder/${accountId}/${interval}`,
        payload
      );
    return data;
  } catch (e) {
    console.error(e);
    throw e;
  }
};
